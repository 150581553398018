<template>
  <div>
    <div class="m-t-40">
      <span class="f-default-mobile f-gotham-medium f-color-navy"> {{ translations.blockExplanation }} </span>
      <bb-button
        class="m-t-30"
        color="mint"
        :label="translations.uploadDocument"
        display="block"
        @click="onUploadDocumentClick"
      />
    </div>
    <bb-modal
      :visible="showUploadForm"
      @close="closeUploadModal"
      flavor="card"
      size="x2"
      publicSize="m"
      class="document-upload-modal"
      fullMobile
      staticOverlay
    >
      <template slot="heading"><span class="f-color-blue form-heading">{{ translations.uploadFormHeading }}</span>
      </template>
      <bb-upload
        flavor="user-selfcontained"
        :heading="translations.uploadFormDescription"
        :fileCountLimit="10"
        :allowedTypes="allowedFileTypes"
        :sizeLimit="5"
        v-bind="uploadTranslations"
        v-model="files"
      >
        <template v-slot:browseLabel>
          <span v-sanitize.basic="translations.browseLabel" />
        </template>
      </bb-upload>
      <div v-show="showMustHaves" class="must-haves nm-s-30 nm-b-30 p-30 m-t-30">
        <div class="must-haves__title color-gray-50 m-b-10">{{ translations.mustHaves }}</div>
        <bb-list-group>
          <bb-list-item v-for="(item, k) in documentMustHaves" :key="k" condensed>{{ item }}</bb-list-item>
        </bb-list-group>
      </div>
      <bb-button
        slot="footer"
        v-bind="submitButtonProps"
        :disabled="!hasFilesSelected || isLoading"
        :loading="isLoading"
        :label="translations.sendDocument"
        @click="onUploadClick"
      />
    </bb-modal>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { useRootStore } from '../../../store/root'
import api from '../api'
import FileUploadingMixin from '@/mixins/fileUploadingMixin'

export default {
  mixins: [FileUploadingMixin],
  data () {
    return {
      isLoading: false,
      showUploadForm: false,
      files: [],
      allowedFileTypes: ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']
    }
  },
  methods: {
    setLoading (flag) {
      this.isLoading = flag
    },
    onUploadDocumentClick () {
      this.showUploadForm = true
    },
    closeUploadModal () {
      this.showUploadForm = false
    },
    async onUploadClick () {
      this.setLoading(true)

      try {
        const response = await api.uploadPrivateDocuments(this.files)

        if (this.isUploadingFailed(response)) {
          this.showFailureNotification(response)
        } else {
          this.$notify({
            type: 'success',
            text: this.translations.uploadSuccessful
          })
          this.closeUploadModal()
        }
      } finally {
        this.files = []
        this.setLoading(false)
      }
    }
  },
  computed: {
    ...mapState(useRootStore, ['isMobile']),
    translations () {
      return {
        blockExplanation: this.$pgettext('private_documents', 'Please upload all requested documents here below. If something is incorrect with the files, our customer support will contact you.'),
        uploadSuccessful: this.$pgettext('private_documents', 'Documents uploaded successfully'),
        uploadDocument: this.$pgettext('private_documents', 'Upload documents'),
        uploadFormHeading: this.$pgettext('private_documents', 'Upload your document'),
        uploadFormDescription: this.$pgettext('private_documents', 'Upload your documents'),
        mustHaves: this.$pgettext('private_documents', 'Conditions'),
        sendDocument: this.$pgettext('private_documents', 'Upload documents'),
        browseLabel: this.$pgettext('private_documents', 'Drag a file here or <span class="bb-upload__highlight">browse</span> a file to upload')
      }
    },
    uploadTranslations () {
      return {
        allowedTypesLabel: this.$pgettext('upload', 'File type is not allowed'),
        duplicateFileLabel: this.$pgettext('upload', 'File is already uploaded'),
        captureLabel: this.$pgettext('upload', 'Capture'),
        chooseLabel: this.$pgettext('upload', 'Choose'),
        coBorrowerEmptyLabel: this.$pgettext('upload', 'Upload file'),
        emptyDescriptionLabel: this.$pgettext('upload', 'No documents are uploaded.'),
        sizeLimitLabel: this.$pgettext('upload', 'File size is over allowed size'),
        uploadAnotherLabel: this.$pgettext('upload', 'Upload another'),
        fileCountExceededLabel: this.$pgettext('upload', 'File limit exceeded'),
        filenameMaxLengthLabel: this.$pgettext('upload', 'The file name is too long')
      }
    },
    documentMustHaves () {
      return this.$pgettext('document',
        'Accepted formats (.pdf, .docx, .doc, .jpeg, .jpg)')
        .split('\n')
        .map(text => (text.trim()))
    },
    hasFilesSelected () {
      return this.files.filter(f => f.file).length > 0
    },
    showMustHaves () {
      return !this.hasFilesSelected
    },
    submitButtonProps () {
      return {
        display: 'block',
        size: this.isMobile ? 'lg' : undefined,
        class: this.isMobile ? 'm-s-a' : undefined,
        corner: this.isMobile ? 'sharp' : undefined
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.document-upload-modal::v-deep h3 {
  line-height: 18px;
  padding-left: 10px;
  padding-right: 10px;
}

.document-upload-modal {
  .must-haves {
    background: $gray-10;
    border-radius: 5px;

    &__title {
      font-family: $gotham-medium;
      font-size: $font-size-small;
    }
  }
}
</style>
