<template>
  <div class="customer-address-text-label">
    <template v-if="!replacementCardOrdered">
      <p v-sanitize.basic="translations.replaceLine1" />
      <p v-sanitize.basic="translations.replaceLine2" />
    </template>
    <p v-if="isCustomerContactAddressValid">
      <span v-sanitize.basic="translations.replaceLine3" />
      <bb-tooltip class="m-l-5">{{ translations.addressTooltip }}</bb-tooltip>
    </p>
    <p v-else>
      <bb-button @click="onAddressEdit" color="blue" size="sm" inverted>{{ translations.editAddress }}</bb-button>
    </p>
  </div>
</template>

<script>
import CustomerAddressTextLabelMixin from '@/modules/account/components/mixins/CustomerAddressTextLabelMixin'
import { mapActions } from 'pinia'
import { useAccountStore } from '@account/store/accountStore'

export default {
  name: 'credit-card-address-info',
  props: {
    translations: { required: true },
    replacementCardOrdered: { default: false }
  },
  mixins: [CustomerAddressTextLabelMixin],

  methods: {
    ...mapActions(useAccountStore, ['getDetails']),
    onAddressEdit () {
      this.$router.push('/account')
    }
  },
  async mounted () {
    this.getDetails()
  }
}

</script>

<style lang="scss" scoped>
.customer-address-text-label {
  p {
    margin-top: 15px;
  }
}
</style>
