<template>
  <with-horizontal-menu :loading="loading" :onBackButtonClick="onBackButtonClick" :tabRoutes="tabRoutes" :title="translations.pageTitle">
    <div class="details__wrapper">
      <div class="details__content" v-if="contract">
        <h3 class="details__title m-t-20 m-b-0">
          {{translations.title}}: {{contract.contractNumber}}
        </h3>
        <bb-list-group class="details__list m-t-30 m-b-30">
          <bb-list-item v-for="(detail, i) in details"
            :key="i"
            :title-uppercase="false"
            condensed
            :title="detail.title">
            {{detail.value}}
            <bb-tooltip v-if="detail.tooltip">{{detail.tooltip}}</bb-tooltip>
          </bb-list-item>
        </bb-list-group>
        <div class="details__documents" v-if="documents && documents.length > 0">
          <h3 class="details__title m-t-0 m-b-30">
            {{translations.documentsTitle}}
          </h3>
          <document-list :files="documents" :downloadHandler="download" />
        </div>
        <bb-button
          v-if="isStatementOfFeesEnabled"
          :label="translations.sendInquiry"
          @click="openStatementOfFeesModal"
          name="send-inquiry-button"
          class="m-t-20"
          color="blue"
          inverted
        />
        <bb-banner
          class="m-t-30"
          type="info"
          visible
          permanent
        >
          <div v-sanitize.basic="translations.closeBanner"></div>
        </bb-banner>
      </div>
    </div>
    <statement-of-fees-modal
      v-if="isStatementOfFeesEnabled && isStatementOfFeesModalShown"
      visible
      @close="handleStatementOfFeesModalClose"
    />
  </with-horizontal-menu>
</template>

<script>
import '@bigbank/interface-components/dist/svg/ui/arrow-left'
import { CreditCardRouteName } from '@credit-card/views/const'
import WithHorizontalMenu from '@/layouts/WithHorizontalMenu.vue'
import DocumentList from '@/components/DocumentList'
import StatementOfFeesModal from '@loan/components/StatementOfFeesModal'
import api from '@loan/api'
import { formatDate } from '@/plugins/dateFormatters'
import { CardStatus } from '@bigbank/dc-common/clients/http/credit-card/credit-card.enums'
import { mapState, mapActions } from 'pinia'
import { formatMoneyWithCurrency, formatInterest } from '@/plugins/numformat'
import { pick } from 'lodash'
import { useRootStore } from '../../../store/root'
import { useLoanStore } from '@loan/store/loanStore'

export default {
  name: 'credit-card-details-view',
  components: {
    WithHorizontalMenu,
    DocumentList,
    StatementOfFeesModal
  },
  data () {
    return {
      loading: true,
      contractId: null,
      card: null,
      rawDocuments: [],
      isModalVisible: false,
      modalComponent: null,
      isStatementOfFeesModalShown: false
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler (to) {
        this.contractId = to.params.contractId
      }
    },
    contractId: {
      immediate: true,
      handler (newId, oldId) {
        if (!newId || newId === oldId) {
          return
        }
        this.fetchData()
      }
    }
  },
  computed: {
    ...mapState(useRootStore, ['locale', 'currencySymbol']),
    ...mapState(useLoanStore, ['contract', 'contractAdditionalDetails', 'featureFlags']),
    translations () {
      return {
        pageTitle: this.$pgettext('credit_card_contract_title', 'Contract details'),
        title: this.$pgettext('credit_card_contract_title', 'Contract nr'),
        documentsTitle: this.$pgettext('credit_card_contract_title', 'Contract documents'),
        accountNr: this.$pgettext('credit_card_contract', 'Account nr'),
        paymentDay: this.$pgettext('credit_card_contract', 'Payment day'),
        replaceCard: this.$pgettext('credit_card_contract', 'Replace the card'),
        signedOn: this.$pgettext('credit_card_contract', 'Signed on'),
        status: this.$pgettext('credit_card_contract', 'Status'),
        creditLimit: this.$pgettext('credit_card_contract', 'Credit limit'),
        interestRate: this.$pgettext('credit_card_contract', 'Interest rate'),
        monthlyFee: this.$pgettext('credit_card_contract', 'Monthly fee'),
        closeBanner: this.$pgettext('credit_card_contract', 'CLOSE_CONTRACT_BANNER_TEXT'),
        dayOfTheMonth: this.$pgettext('credit_card_contract', 'th day of the month'),
        sendInquiry: this.$pgettext('credit_card_statement_of_fees_inquiry', 'Send inquiry')
      }
    },
    isStatementOfFeesEnabled () {
      return this.featureFlags.enableCreditCardStatementOfFees
    },
    tabRoutes () {
      return null
    },
    currency () {
      return this.contract?.currency || this.currencySymbol
    },
    details () {
      return [{
        title: this.translations.creditLimit,
        value: formatMoneyWithCurrency(this.contract?.limitAmount, this.currency, this.locale),
        visible: true
      }, {
        title: this.translations.interestRate,
        value: formatInterest(this.contract?.interestRate, this.locale, true),
        visible: true
      }, {
        title: this.translations.monthlyFee,
        value: formatMoneyWithCurrency(this.contractAdditionalDetails?.monthlyFee, this.currency, this.locale),
        visible: true
      }, {
        title: this.translations.accountNr,
        value: this.contract?.iban,
        visible: true
      }, {
        title: this.translations.paymentDay,
        value: this.contract?.paymentDay + this.translations.dayOfTheMonth,
        visible: true
      }].filter(({ visible }) => visible)
    },
    buttons () {
      return [{
        label: this.translations.blockClose,
        visible: [CardStatus.ACTIVE].includes(this.card?.status),
        onClick: () => {
          this.showBlockModal()
        }
      }, {
        label: this.translations.changeLimits,
        visible: false
      }, {
        label: this.translations.replaceCard,
        visible: false
      }].filter(({ visible }) => visible)
    },
    documents () {
      return this.rawDocuments.map(document => {
        return {
          ...document,
          dateTitle: this.translations.signedOn + ': ' + formatDate(document.date),
          title: document.fileName
        }
      })
    }
  },
  methods: {
    ...mapActions(useLoanStore, [
      'getContract',
      'getContractAdditionalDetails'
    ]),
    setLoading (flag) { this.loading = flag },
    setRawDocuments (documents) { this.rawDocuments = documents },
    onBackButtonClick (previousRoute) {
      if (!previousRoute?.name && previousRoute?.path.length <= 1) {
        previousRoute = null
      }
      return previousRoute ?? {
        name: CreditCardRouteName.CreditCard
      }
    },
    async download (file) {
      await api.initFileDownload(this.contractId, pick(file, ['fileId', 'fileName', 'contentType']))
    },
    async fetchData () {
      this.setLoading(true)
      try {
        const [documents] = await Promise.all([
          api.getContractDocuments(this.contractId),
          this.getContract(this.contractId),
          this.getContractAdditionalDetails(this.contractId)
        ])
        this.setRawDocuments(documents)
      } finally {
        this.setLoading(false)
      }
    },
    openStatementOfFeesModal () {
      this.isStatementOfFeesModalShown = true
    },
    handleStatementOfFeesModalClose () {
      this.isStatementOfFeesModalShown = false
    }
  }
}
</script>

<style lang="scss" scoped>
.details {
  &__content {
    padding: 12px 15px 126px;
    display: flex;
    flex-direction: column;

    @media (min-width: $desktop-view-breaking-point) {
      display: block;
      max-width: 850px;
      margin: auto;
      width: 100%;
      padding: 20px 15px 96px;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media (min-width: $desktop-view-breaking-point) {
      flex-direction: row;
    }
  }

  &__title {
    color: $navy;
    font-family: $gotham-medium;
  }
}
</style>
