<template>
  <bb-modal visible @close="onClose()" flavor="card" color="white" publicSize="m" fullMobile class="transfer-details">
    <template v-if="loading">
      <div class="transfer-details__loader">
        <bb-spinner/>
      </div>
    </template>
    <template v-else>
      <div slot="heading" class="transfer-details__title">{{ mergedTranslations.title }}</div>
      <div class="transfer-details__content">
        <template v-if="showAmount">
          <div class="transfer-details__amount color-navy">{{ amount }}</div>
          <div class="transfer-details__amount-subtitle">{{ mergedTranslations.sumSubtitle }}</div>
        </template>
        <slot/>
        <div class="transfer-details__subtitle">{{ mergedTranslations.subtitle }}</div>
        <bb-box thin class="transfer-details_details-box">
          <list-item :title="mergedTranslations.recipient" :titleUppercase="false" :titleBold="true" :value="name" name="beneficary-name"/>
          <list-item v-if="!isChannelSE" :title="mergedTranslations.iban" :value="iban" name="iban"/>
          <list-item :title="mergedTranslations.bic" :value="bic" name="bic"/>
          <list-item :title="mergedTranslations.reference" :value="reference" name="reference-number"/>
        </bb-box>
      </div>
    </template>
    <template #footer>
      <bb-ticket-stub class="nm-x-20 nm-y-md-20">
        <bb-button
          data-testid="transfer-details-close"
          display="ticket"
          color="white"
          class="color-green no-padding"
          @click="onClose()">
          <span class="color-green"><translate>Got it</translate></span>
        </bb-button>
      </bb-ticket-stub>
    </template>
  </bb-modal>
</template>

<script>
import { formatMoneyWithCurrency } from '@/plugins/numformat'
import { mapState } from 'pinia'
import currencyDefinitions from '../utils/getCurrency'
import ListItem from '@/components/ListItem.vue'
import { useRootStore } from '../../../store/root'

export default {
  components: { ListItem },
  name: 'transfer-details',
  props: {
    transferDetails: {
      required: true
    },
    translations: {
      required: false,
      default: () => ({})
    },
    loading: {
      required: false,
      default: false
    },
    hideSum: {
      required: false,
      default: false
    }
  },
  computed: {
    ...mapState(useRootStore, ['locale', 'isChannelSE']),
    amount () {
      return formatMoneyWithCurrency(this.transferDetails.amount, this.transferCurrency, this.locale)
    },
    name () {
      return this.transferDetails ? this.transferDetails.receiverName : '-'
    },
    iban () {
      return this.transferDetails ? this.transferDetails.iban : '-'
    },
    reference () {
      return this.transferDetails ? this.transferDetails.referenceNumber : '-'
    },
    bic () {
      return this.transferDetails ? this.transferDetails.bic : '-'
    },
    transferCurrency () {
      return currencyDefinitions(this.transferDetails.currencyCode)
    },
    mergedTranslations () {
      return {
        iban: this.$gettext('IBAN'),
        bic: this.$gettext('BIC'),
        reference: this.$gettext('Ref number'),
        title: this.$gettext('Waiting for your transfer.'),
        subtitle: this.$gettext('Payment details:'),
        sumSubtitle: this.$gettext('Sum to be transferred'),
        recipient: this.$gettext('Recipient'),
        ...this.translations
      }
    },
    showAmount () {
      return !this.hideSum && ![undefined, null, ''].includes(this.transferDetails.amount)
    }
  },
  methods: {
    onClose () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.transfer-details {
  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__title {
    color: $blue;
    font-size: $font-size-small;
    font-family: $gotham-medium;
    text-align: center;
  }

  &__subtitle {
    color: $gray;
    text-align: center;
    font-size: $font-size-small;
    line-height: $font-size-small;
    font-family: $gotham-medium;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  &__details-box {
    margin-top: 30px;
  }

  &__amount {
    margin-top: 15px;
    text-align: center;
    font-family: $gotham-medium;
    font-size: $h3-size;
    line-height: $h3-size;
  }

  &__amount-subtitle {
    color: $gray-70;
    font-family: $gotham-bold;
    text-align: center;
    font-size: $font-size-tiny;
    text-transform: uppercase;
    padding-bottom: 15px;
  }
}
</style>
