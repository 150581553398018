<template>
  <layout-with-progress-v2 :loading="loading" :progress="progress">
    <bb-form
      :submitText="submitText"
      :errorText="translations.errorText"
      :disabled="disableSubmitButton"
      @submit="goToNextStep"
      staticSubmit
      uppercaseButtons
      class="confirm-data__form"
      v-if="!loading && details">
      <p class="instructions">{{ instructions }}</p>
      <bb-separator class="m-t-30" dashed/>
      <div v-if="showEditButton" class="edit-button">
        <bb-button
          display="link"
          noUnderline
          size="sm"
          @click="toggleEditMode"
        >
          <span>{{editText}}</span>
          <bb-icon v-if="!edit" name="ui-pencil" size="18" fill="green" />
          <bb-icon v-else name="ui-close" size="18" fill="green" />
        </bb-button>
      </div>
      <template v-if="[steps.confirmDataStepOne].includes(activeStep)">
        <bb-input
          v-if="identificationCode.isVisible"
          name="identificationCode"
          key="identificationCode"
          type="text"
          :label="translations.labels.idCode"
          :value="form.identificationCode"
          v-model="form.identificationCode"
          v-validate.initial="'required'"
          :data-vv-as="translations.labels.idCode"
          data-vv-validate-on="change|input"
          :readonly="!identificationCode.isEditable"
        ></bb-input>
        <text-display :label="translations.labels.name" :value="customerName" />
        <bb-input name="bornIn" type="text" :label="translations.labels.bornIn" :value="bornIn" readonly></bb-input>
        <bb-input
          v-validate.initial="'required|email'"
          name="email"
          key="email"
          :data-vv-as="translations.labels.email"
          :label="translations.labels.email"
          v-model="form.email"
          :readonly="!edit"
          data-vv-validate-on="input|change|blur"
        />
        <bb-prefixed-input
          v-validate.initial="'required|phoneNumberPrefix|phoneNumberSuffix'"
          :data-vv-as="translations.labels.phone"
          :label="translations.labels.phone"
          :prefixes="phoneAreaCodes"
          :prefix="phoneAreaCode"
          :disableSearch="disablePhoneNumberPrefix"
          separator="|"
          name="fullPhoneNumber"
          key="fullPhoneNumber"
          v-model="form.fullPhoneNumber"
          data-vv-validate-on="input|change|blur"
          v-show="edit"
          type="tel"
        />
        <bb-input
          name="phone"
          v-show="!edit"
          type="text"
          :label="translations.labels.phone"
          :value="(form.fullPhoneNumber || '').replace('|', ' ')"
          readonly></bb-input>
        <bb-select
          name="citizenCountryCode"
          key="citizenCountryCode"
          v-validate.initial="'required'"
          :label="translations.labels.nationality"
          :data-vv-as="translations.labels.nationality"
          v-model="form.citizenCountryCode"
          :options="translatedCountryList"
          data-vv-validate-on="change"
          :readonly="!citizenCountryCodeSettings.isEditable"
          v-show="edit"
        />
        <bb-input
          name="citizenCountryCode"
          v-show="!edit"
          type="text"
          :label="translations.labels.nationality"
          :value="countryName(form.citizenCountryCode)"
          readonly
        ></bb-input>
        <bb-select
            name="taxResidencyCountryCode"
            key="taxResidencyCountryCode"
            v-validate.initial="taxResidencyCountryCode.validationRules"
            v-if="taxResidencyCountryCode.isVisible"
            :label="translations.labels.taxResidency"
            :data-vv-as="translations.labels.taxResidency"
            v-model="form.taxResidencyCountryCode"
            :options="translatedCountryListWithExcludedStateless"
            data-vv-validate-on="change"
            v-show="edit"
            :help-active="true"
            :help-text="translations.taxResidencyTooltip"
        />
        <bb-input
          name="taxResidencyCountryCode"
          v-show="!edit"
          type="text"
          :label="translations.labels.taxResidency"
          :value="countryName(form.taxResidencyCountryCode)"
          change="onTaxResidencyCountryCodeChanged"
          readonly></bb-input>
        <bb-masked-input
          name="taxPayerCode"
          key="taxPayerCode"
          v-validate.initial="TIN.validationRules"
          v-if="TIN.isVisible && TIN.isMaskUsed"
          :data-vv-as="translations.labels.taxPayerCode"
          :label="translations.labels.taxPayerCode"
          v-model="form.taxPayerCode"
          :readonly="!edit"
          :mask="TIN.mask"
        />
        <bb-input
          name="taxPayerCode"
          key="taxPayerCode"
          v-validate.initial="TIN.validationRules"
          v-if="TIN.isVisible && !TIN.isMaskUsed"
          :data-vv-as="translations.labels.taxPayerCode"
          :label="translations.labels.taxPayerCode"
          v-model="form.taxPayerCode"
          :readonly="!edit"
        />
        <bb-select
          v-validate.initial="'required'"
          name="activityCode"
          key="activityCode"
          :data-vv-as="translations.labels.workSituation"
          :label="translations.labels.workSituation"
          v-model="form.activityCode"
          :options="activityCodeOptions"
          data-vv-validate-on="change"
          v-show="edit"
        />
        <bb-input
          name="activityCode"
          v-show="!edit"
          type="text"
          :label="translations.labels.workSituation"
          :value="activityName(form.activityCode)"
          readonly
        />
        <bb-input
          v-if="activityField.isClarificationRequired"
          v-validate.initial="'required'"
          :readonly="!edit"
          name="activitySpecified"
          key="activitySpecified"
          :data-vv-as="translations.labels.activitySpecified"
          :label="translations.labels.activitySpecified"
          v-model="form.activitySpecified"
        />
        <bb-checkbox
          name="hasDualNationality"
          :label="translations.labels.dualNationality"
          v-model="controls.hasDualNationality"
          data-vv-validate-on="change"
          :disabled="!edit"
        />
        <bb-select
          v-if="controls.hasDualNationality && edit"
          name="dualCitizenCountryCode"
          key="dualCitizenCountryCode"
          v-validate.initial="'required'"
          :label="translations.labels.dualCitizenCountryCode"
          :data-vv-as="translations.labels.dualCitizenCountryCode"
          v-model="form.dualCitizenCountryCode"
          :options="translatedCountryList"
          data-vv-validate-on="change"
        />
        <bb-input
          name="dualCitizenCountryCode"
          v-show="controls.hasDualNationality && !edit"
          type="text"
          :label="translations.labels.dualCitizenCountryCode"
          :value="countryName(form.dualCitizenCountryCode)"
          readonly></bb-input>
        <bb-separator class="m-b-30 m-t-25" dashed/>
        <div class="m-b-15">
          <bb-checkbox
            :data-vv-as="translations.labels.isPep"
            background
            name="pepDeclaration"
            key="pepDeclaration"
            v-model="form.isNotPoliticallyExposed"
            data-vv-validate-on="change"
          >
            <bb-tooltip class="pull-right nm-r-10">
              <span v-sanitize.basic="translations.isPepTooltip" />
            </bb-tooltip>
            <p v-sanitize.basic="translations.labels.isPep"/>
          </bb-checkbox>
        </div>
        <div class="m-b-45">
          <bb-checkbox
            :data-vv-as="translations.labels[FormField.TermsAndConditions]"
            background
            :name="FormField.TermsAndConditions"
            :key="FormField.TermsAndConditions"
            v-validate="'required'"
            v-model="form.acceptTerms"
            data-vv-validate-on="change"
          >
            <p v-sanitize.basic="termsTranslations"/>
          </bb-checkbox>
        </div>
        <div class="additional-text" v-if="isConfirmationTextVisible">
          <translate>I confirm that the information provided is true and complete and that I’m aware of and understand the obligations set in the General Terms and Conditions of Bigbank AS, including the obligation to provide the information necessary for establishing a business relationship and the liability for misrepresentation.</translate>
        </div>
      </template>
      <template v-if="[steps.confirmDataStepTwo, steps.confirmPhone, steps.confirmEmail].includes(activeStep)">
        <bb-input
          v-if="showRegistrationAddress"
          v-validate.initial="'required'"
          name="registrationStreetAddress"
          key="registrationStreetAddress"
          :data-vv-as="translations.labels.street"
          :label="translations.labels.street"
          v-model="form.registrationAddress.streetAddress"
          :readonly="!isRegistrationAddressEditable"
          :helpText="translations.registrationAddresNotEditableTootlip"
          :helpActive="!registrationAddressSettings.isEditable"
        />
        <bb-input
          v-if="showRegistrationAddress"
          v-validate.initial="'required'"
          name="registrationPostocde"
          key="registrationPostcode"
          :data-vv-as="translations.labels.postalCode"
          :label="translations.labels.postalCode"
          v-model="form.registrationAddress.postcode"
          :readonly="!isRegistrationAddressEditable"
        />
        <bb-input
          v-if="showRegistrationAddress"
          v-validate.initial="'required'"
          name="registrationCity"
          key="registrationCity"
          :data-vv-as="translations.labels.city"
          :label="translations.labels.city"
          v-model="form.registrationAddress.city"
          :readonly="!isRegistrationAddressEditable"
        />
        <bb-input
          v-if="showRegistrationAddress && registrationCountyFieldSettings.visible"
          v-validate.initial="{required: registrationCountyFieldSettings.required}"
          name="registrationCounty"
          key="registrationCounty"
          :data-vv-as="translations.labels.county"
          :label="translations.labels.county"
          v-model="form.registrationAddress.county"
          :readonly="!isRegistrationAddressEditable"
        />
        <bb-select
          v-if="showRegistrationAddress"
          name="registrationCountryCode"
          key="registrationCountryCode"
          v-validate.initial="'required'"
          :label="translations.labels.country"
          :data-vv-as="translations.labels.country"
          v-model="form.registrationAddress.countryCode"
          :options="translatedCountryListWithExcludedStateless"
          data-vv-validate-on="change"
          v-show="edit"
          :readonly="!isRegistrationAddressEditable"
        />
        <bb-input
          v-if="showRegistrationAddress"
          name="registrationAddressCountryCode"
          v-show="!edit"
          type="text"
          :label="translations.labels.country"
          :value="countryName(form.registrationAddress.countryCode)"
          readonly></bb-input>
        <bb-checkbox
          v-if="showRegistrationAddress && showContactAddress"
          name="postalSameAsContactAddress"
          :label="translations.labels.postalSame"
          v-model="controls.postalSameAsContactAddress"
          :disabled="!isContactAddressEditable"
          data-vv-validate-on="change"
        />
        <template v-if="!controls.postalSameAsContactAddress && showContactAddress">
          <p class="subtitle m-t-30">{{translations.mailingAddress}}</p>
          <bb-separator class="m-b-10 m-t-15" dashed/>
          <bb-input
            v-validate.initial="'required'"
            name="contactStreetAddress"
            key="contractStreetAddress"
            :data-vv-as="translations.labels.mailingStreet"
            :label="translations.labels.mailingStreet"
            v-model="form.contactAddress.streetAddress"
            :readonly="!isContactAddressEditable"
          />
          <bb-input
            v-validate.initial="'required'"
            name="contactPostocde"
            key="contractPostcode"
            :data-vv-as="translations.labels.mailingPostalCode"
            :label="translations.labels.mailingPostalCode"
            v-model="form.contactAddress.postcode"
            :readonly="!isContactAddressEditable"
          />
          <bb-input
            v-validate.initial="'required'"
            name="contactCity"
            key="contractCity"
            :data-vv-as="translations.labels.mailingCity"
            :label="translations.labels.mailingCity"
            v-model="form.contactAddress.city"
            :readonly="!isContactAddressEditable"
          />
          <bb-input
            v-if="contactCountyFieldSettings.visible"
            v-validate.initial="{required: contactCountyFieldSettings.required}"
            name="contactCounty"
            key="contactCounty"
            :data-vv-as="translations.labels.mailingCounty"
            :label="translations.labels.mailingCounty"
            v-model="form.contactAddress.county"
            :readonly="!isContactAddressEditable"
          />
          <bb-select
            name="contactCountryCode"
            key="contactCountryCode"
            v-validate.initial="'required'"
            :label="translations.labels.mailingCountry"
            :data-vv-as="translations.labels.mailingCountry"
            v-model="form.contactAddress.countryCode"
            :options="translatedCountryListWithExcludedStateless"
            data-vv-validate-on="change"
            :readonly="!isContactAddressEditable"
            v-show="edit"
          />
          <bb-input
            v-show="!edit"
            type="text"
            name="contactCountryCode"
            :label="translations.labels.mailingCountry"
            :value="countryName(form.contactAddress.countryCode)"
            readonly></bb-input>
        </template>
      </template>
      <template v-if="activeStep === steps.uploadIdDocuments">
        <document-list :documents="details.idDocuments"></document-list>
        <bb-separator v-if="details.idDocuments.length > 0" class="m-b-10 m-t-15" dashed/>
        <div v-if="!showDocumentForm" class="m-t-15 add-new-document">
          <bb-button @click="addDocument" noUnderline display="link">
            <bb-icon name="ui-plus-circle-outline" size="24" />
            {{translations.addDocument}}
          </bb-button>
        </div>
        <id-document-upload-form
          v-if="showDocumentForm"
          ref="uploadForm"
          :flow="flow"
          :formData.sync="documentForm"
          v-on:upload:finished="onDocumentUploadFinished"
        />
        <div class="p-b-30"></div>
        <p class="confirm-data__skip-document-upload-text" @click="skipDocumentUpload">{{ translations.skipForNow }}</p>
      </template>
    </bb-form>
    <confirm-phone v-if="confirmPhone" v-bind="confirmPhone" v-on="confirmPhoneEvents" />
    <email-change-modal v-if="confirmEmail" :email="newEmail" v-on="confirmEmailEvents" />
  </layout-with-progress-v2>
</template>

<script>
import PersonalData from './PersonalData'
import DocumentList from './../components/DocumentList'
import TextDisplay from '@/components/Input/TextDisplay'
import '@bigbank/interface-components/dist/svg/ui/pencil'
import '@bigbank/interface-components/dist/svg/ui/close'
import '@bigbank/interface-components/dist/svg/ui/plus-circle-outline'
import { mapState, mapActions } from 'pinia'
import { getDateFormat } from '@/plugins/dateFormatters'
import dayjs from 'dayjs'
import TermsLinksMixin from '@/mixins/termLinksMixin'
import FileUploadingMixin from '@/mixins/fileUploadingMixin'
import { getViewFlowTrackerAction, getConfirmFlowTrackerAction } from '@/TrackingActions'
import { FormValidation } from '@bigbank/dc-common/validators/validation.config'
import { CountryChannel } from '@bigbank/dc-common/config'
import { getCountryInfo } from '@/plugins/utils'
import LogoutReason from '@/types/logout-reason'
import IdDocumentUploadForm from '../components/IdDocumentUploadForm.vue'
import { AccountRouteName } from '../const'
import { useRootStore } from '../../../store/root'
import { useAccountStore } from '@account/store/accountStore'

const FormField = Object.freeze({
  TermsAndConditions: 'termsAndConditions'
})

export default {
  name: 'account-confirm-data',
  inject: ['$validator'],
  extends: PersonalData,
  components: { TextDisplay, DocumentList, IdDocumentUploadForm },
  mixins: [TermsLinksMixin, FileUploadingMixin],
  data () {
    return {
      FormField,
      formValidation: FormValidation.SelfServiceConfirmData,
      dateFormat: getDateFormat(),
      notBefore: dayjs().add(1, 'day').toDate(),
      maxExpireDate: dayjs().add(30, 'year').toDate(),
      notAfter: dayjs().toDate(),
      prevRoute: null,
      loading: false,
      manualEditMode: false,
      editingIdentificationCode: false,
      flow: 'confirm-data',
      steps: {
        confirmDataStepOne: 'step-one',
        confirmDataStepTwo: 'step-two',
        confirmPhone: 'confirm-phone',
        confirmEmail: 'confirm-email',
        uploadIdDocuments: 'upload-id-documents'
      },
      translations: {
        ok: this.$pgettext('confirm', 'Ok'),
        addDocument: this.$pgettext('document_upload', 'Add new Document'),
        uploadNewDocument: this.$gettext('Upload new document'),
        uploadFormHeading: this.$pgettext('upload_modal_heading', 'Upload photo of your:'),
        submitText: this.$gettext('Yes, everything is correct'),
        errorText: this.$gettext('Fix errors to continue'),
        contractExpireWarning: this.$gettext('Please provide a document that is valid for more than 30 days'),
        taxResidencyTooltip: this.$gettext('The country in which you pay taxes on'),
        instructions: {
          'step-one': this.$gettext('Please double check all your Personal Information, in case of any corrections, please edit.'),
          'step-two': this.$gettext('Please double check all your Contact Information, in case of any corrections, please edit.'),
          'upload-id-documents': this.$gettext('We noticed that your identification document is about to expire or missing.')
        },
        labels: {
          [FormField.TermsAndConditions]: this.$pgettext('form', 'Terms and conditions'),
          documentTypeCode: this.$pgettext('form', 'Type of document'),
          documentNumber: this.$pgettext('form', 'Document number'),
          documentValidDate: this.$pgettext('form', 'Document valid date'),
          documentExpiryDate: this.$pgettext('form', 'Document expiry date'),
          documentCountryCode: this.$pgettext('form', 'Document country code')
        },
        validator: {
          taxPayerCodeError: this.$pgettext('validation', 'Please enter a valid tax identification number')
        },
        skipForNow: this.$pgettext('form', 'Skip for now'),
        logout: this.$gettext('Logout'),
        cancel: this.$pgettext('close_contract_modal', 'Cancel')
      },
      showDocumentForm: false,
      showUploadForm: false,
      documentForm: {
        documentTypeCode: null,
        documentNumber: null,
        documentValidDate: null,
        documentExpiryDate: null,
        documentCountryCode: null,
        files: {
          frontSide: [],
          backSide: []
        }
      }
    }
  },
  computed: {
    ...mapState(useRootStore, ['isChannelLV', 'permissions', 'channel', 'previousRoute']),
    isConfirmationTextVisible () {
      return this.channel !== CountryChannel.FI
    },
    isLogoutButtonVisible () {
      return this.permissions?.view?.allowed === false
    },
    isCancelButtonVisible () {
      return this.permissions?.transactions?.allowed === false
    },
    submitText () {
      if (this.activeStep === this.steps.uploadIdDocuments) {
        return this.translations.uploadNewDocument
      }
      return this.translations.submitText
    },
    showEditButton () {
      if (this.activeStep === this.steps.confirmDataStepTwo) {
        if (!(this.registrationAddressSettings.isEditable || this.contactAddressSettings.isEditable)) {
          return false
        }
      }
      return !this.hasErrors && this.activeStep !== this.steps.uploadIdDocuments
    },
    idUploadRequired () {
      return this.permissions?.rules.identificationDocumentMissing
    },
    progress () {
      const steps = {
        [this.steps.confirmDataStepOne]: 1,
        [this.steps.confirmDataStepTwo]: 2,
        [this.steps.confirmPhone]: 2,
        [this.steps.confirmEmail]: 2,
        [this.steps.uploadIdDocuments]: 3
      }

      return {
        max: this.idUploadRequired ? 3 : 2,
        value: steps[this.activeStep],
        hideCloseButton: true,
        rightCornerButton: (() => {
          if (this.isLogoutButtonVisible) {
            return {
              label: this.translations.logout,
              icon: 'ui-logout',
              click: () => this.$router.push({ path: '/logout', query: { reason: LogoutReason.CONFIRM_DATA_LOGOUT_CLICK } })
            }
          }
          if (this.isCancelButtonVisible) {
            return {
              label: this.translations.cancel,
              icon: 'ui-close-circle-outline',
              click: () => this.$router.go(-1)
            }
          }

          return null
        })()
      }
    },
    instructions () {
      return this.translations.instructions[this.activeStep]
    },
    editText () {
      return !this.edit ? this.$pgettext('form_button', 'Edit') : this.$pgettext('form_button', 'Back')
    },
    termsTranslations () {
      return this.isChannelLV
        ? this.$gettextInterpolate(this.$pgettext('confirm-data-terms_lv',
          'I hereby confirm the accuracy of the data provided and that I have read and agree with ' +
          'the <a target=\'_blank\' href=\'%{bankTermsLink}\'>Terms and Conditions</a> of the Bank, ' +
          'the <a target=\'_blank\' href=\'%{dataProcessingLink}\'>Principles for the Processing of Customer Data</a> ' +
          'and the <a target=\'_blank\' href=\'%{basicInformationLink}\'>Basic Information on the Protection of Deposits</a>.')
        , this.getTermsLinks())
        : this.$gettextInterpolate(this.$pgettext('confirm-data-terms',
          'I hereby confirm the accuracy of the data provided and that I have read and agree with ' +
          'the <a target=\'_blank\' href=\'%{bankTermsLink}\'>Terms and Conditions</a> of the Bank, ' +
          'the <a target=\'_blank\' href=\'%{dataProcessingLink}\'>Principles for the Processing of Customer Data</a> ' +
          'and the <a target=\'_blank\' href=\'%{basicInformationLink}\'>Basic Information on the Protection of Deposits</a>.')
        , this.getTermsLinks())
    },
    formErrors () {
      return this.$validator.errors.items.filter(item => item.field !== FormField.TermsAndConditions)
    },
    hasErrors () {
      return this.formErrors.length > 0
    },
    edit () {
      return this.hasErrors || this.manualEditMode
    },
    disableSubmitButton () {
      return this.activeStep === this.steps.uploadIdDocuments && !this.showDocumentForm
    },
    progressForTracker () {
      return this.progress.value + '/' + this.progress.max
    },
    disablePhoneNumberPrefix () {
      return [CountryChannel.BG].includes(this.channel)
    },
    phoneAreaCodes () {
      return !this.disablePhoneNumberPrefix
        ? this.translatedCountryList
          .filter(({ areaCode, areaCodeAllowed }) => !!areaCode && areaCodeAllowed)
          .map(({ text, value, areaCode }) => ({
            value: areaCode,
            alpha2: value,
            name: text,
            label: `${areaCode} ${text}`
          }))
        : undefined
    },
    phoneAreaCode () {
      switch (this.channel) {
        case CountryChannel.BG:
          return getCountryInfo(this.channel, this.countryList).areaCode
        default:
          return undefined
      }
    },
    filesSelected () {
      return this.documentForm.files.frontSide.filter(f => f.file).length > 0
    }
  },
  methods: {
    ...mapActions(useRootStore, [
      'checkAuthentication',
      'showTransactionalErrorModal',
      'showContactCustomerSupportErrorModal',
      'setIsDocumentUploadSkippedInConfirmData'
    ]),
    ...mapActions(useAccountStore, ['uploadDocument']),
    async toggleEditMode () {
      if (this.manualEditMode) {
        await this.$validator.validate()
      }
      this.manualEditMode = !this.manualEditMode
    },
    async onDocumentUploadFinished (response) {
      if (this.isUploadingFailed(response)) {
        this.showFailureNotification(response)
      } else {
        this.showSuccessNotification()
        await this.goToNextStep()
      }
    },
    async goToNextStep () {
      if (!this.permissions?.options?.isPersonalDataSaveable ?? true) {
        this.showContactCustomerSupportErrorModal()
        return
      }

      const wasInfoAllowedBefore = this.permissions.view.allowed

      if (!this.showUploadForm) {
        this.$tracker.action(getConfirmFlowTrackerAction(this.flow, this.activeStep), { progress: this.progressForTracker })
      }

      this.manualEditMode = false
      if (this.activeStep === this.steps.confirmDataStepOne) {
        this.setActiveStep(this.steps.confirmDataStepTwo)
        return
      } else if (this.activeStep === this.steps.confirmDataStepTwo) {
        await this.submit()
        if (this.idUploadRequired) {
          this.setActiveStep(this.steps.uploadIdDocuments)
          return
        }
      } else if (this.activeStep === this.steps.uploadIdDocuments) {
        if (!this.filesSelected && this.showDocumentForm) {
          this.$refs.uploadForm.openUploadModal()
          this.$tracker.action(getViewFlowTrackerAction(this.flow, 'upload-form'))
          return
        }
      }

      const nextStep = this.getNextStep()

      if (nextStep) {
        return
      }

      this.loading = true
      await this.checkAuthentication()
      const isInfoAllowedNow = this.permissions.view.allowed
      const isTransactionalAllowedNow = this.permissions.transactions.allowed

      if (!wasInfoAllowedBefore && !isInfoAllowedNow && !this.idUploadRequired) {
        this.$router.push('/oops/no-access')
      } else if (wasInfoAllowedBefore && !isTransactionalAllowedNow) {
        this.showTransactionalErrorModal()
        this.$tracker.action(getViewFlowTrackerAction(this.flow, 'contact-customer-service'))
        this.$router.push('/')
      } else if (this.idUploadRequired) {
        this.setIsDocumentUploadSkippedInConfirmData(true)
        this.$router.push('/')
      } else {
        const route = this.previousRoute || this.prevRoute
        this.$router.push(route?.path ?? '/')
      }
    },
    addDocument () {
      this.$tracker.action(getViewFlowTrackerAction(this.flow, 'add-document'))
      this.showDocumentForm = true
    },
    skipDocumentUpload () {
      this.showDocumentForm = false
      this.goToNextStep()
    },
    setActiveStep (step) {
      this.activeStep = step
      this.$tracker.action(getViewFlowTrackerAction(this.flow, step), { progress: this.progressForTracker })
    }
  },
  async mounted () {
    this.setActiveStep(this.steps.confirmDataStepOne)
    await this.init()
  },
  watch: {
    edit (value) {
      if (value) {
        this.manualEditMode = true
      }
    },
    showUploadForm () {
      this.documentForm.files.frontSide = []
      this.documentForm.files.backSide = []
    },
    form: {
      handler (newVal) {
        if (newVal.fullPhoneNumber === '') newVal.fullPhoneNumber = `${this.phoneAreaCode}|`
      },
      deep: true
    }
  },
  beforeRouteEnter (_to, from, next) {
    next(vm => {
      if (vm.permissions?.options?.isConfirmDataAllowed === false) {
        vm.$router.push({
          name: AccountRouteName.PersonalData
        })
      }
      vm.prevRoute = from
    })
  }
}
</script>

<style lang="scss">
.confirm-data {
  &__skip-document-upload-text {
    text-align: center;
    text-decoration: underline;
    font-family: $gotham-medium;
    font-size: $font-size-small;
    color: $blue;

    &:hover {
      cursor: pointer;
    }
  }

  &__form {
    max-width: 400px;
    margin: 30px 30px 50px;
    position: relative;

    @media (min-width: $desktop-view-breaking-point-wide) {
      margin-top: 50px;
      margin-right: 0;
      margin-left: 0;
    }

    .instructions {
      color: $gray-90;
      font-size: $font-size-small;
      line-height: 21px;
      text-align: center;
      font-family: $gotham-medium;
    }

    .additional-text {
      color: $gray-80;
      font-size: $font-size-smallest;
      line-height: 18px;
      text-align: center;
      margin-bottom: 120px;
      font-family: $gotham-medium;
    }

    .subtitle {
      font-size: $font-size-small;
      line-height: 18px;
      color: $navy;
      font-family: $gotham-medium;
    }

    .edit-button {
      position: relative;
      height: 50px;

      button {
        position: absolute;
        right: 0;
        top: 20px;
        font-size: $font-size-smallest;
        cursor: pointer;
        z-index: 1;
        margin-top: 0;

        span {
          margin-right: 10px;
        }
      }
    }

    .add-new-document {
      text-align: center;
    }

    .tax-residency-container {
      display: flex;
      flex-direction: row;
      align-items: center;

      .bb-tooltip {
        margin-top: 35px;
      }
    }

    &__text-box {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      font-family: $gotham-medium;
      font-size: $default-font-size;
      color: $navy;

      &-label {
        color: $gray-90;
        font-size: $font-size-small;
      }
    }
  }
}

.document-upload-modal {
  .modal__body {
    display: flex;
    flex-flow: column;
  }

  .must-haves {
    background: $gray-10;
    flex: 1;

    &__title {
      font-family: $gotham-medium;
      font-size: $font-size-small;
    }
  }
}
</style>
