import { mapState } from 'vuex'
import api from '../modules/account/api'

export default {
  data () {
    return {
      showCampaignModal: true
    }
  },
  computed: {
    ...mapState('campaign', ['campaignAdTypes', 'campaigns']),
    popupCampaignsVisible () {
      return Boolean(this.showCampaignModal && this.popupCampaigns.length > 0 && !this.isIndebted)
    },
    popupCampaigns () {
      return this.campaigns.filter(campaign => campaign.adType === this.campaignAdTypes.POPUP)
    }
  },
  methods: {
    async closeCampaignModal () {
      if (this.popupCampaigns && this.popupCampaigns.length > 0) {
        await api.markCampaignAsShown(this.popupCampaigns[0].id) // TODO: move this to service worker background process in a separate ticket
      }

      this.showCampaignModal = false
    }
  }
}
