<template>
  <bb-modal
    :visible="isModalVisible"
    flavor="card"
    @close="onClick"
    class="credit-card-holder-block-modal noselect f-align-center"
    centerVertically
    full-mobile
    publicSize="m"
  >
    <template #heading>
      <div class="text-center modal-title" >{{ translations.modalTitle }}</div>
    </template>
    <template #default>
      <div class="text-center m-t-20 modal-description" v-sanitize.basic="translations.description"/>
    </template>
    <template #footer>
      <bb-button
        display="block"
        class="m-s-a"
        name="close-replace"
        corner="sharpMobileOnly"
        @click="onClick"
        :label=translations.buttonText
        :size="computedButtonSize"
      />
    </template>
  </bb-modal>
</template>

<script>
import '@bigbank/interface-components/dist/svg/illustration/cvv'

export default {
  name: 'credit-card-holder-block-modal',
  props: {
    visible: { required: true, type: Boolean }
  },
  data () {
    return {
      isModalVisible: this.visible
    }
  },
  computed: {
    translations () {
      return {
        modalTitle: this.$pgettext('fraud_error', 'Action not possible'),
        buttonText: this.$pgettext('fraud_error', 'Close'),
        description: this.$pgettext('fraud_error', 'Due to reasons, we are unable to allow completion of action, please contact customer service "tel nr", "email"')
      }
    },
    computedButtonSize () {
      return this.$screen.md
        ? 'md'
        : 'lg'
    }
  },
  methods: {
    onClick () {
      this.$emit('onModalClosed')
    }
  }
}
</script>

<style lang="scss">
.credit-card-holder-block-modal {
  .modal-title {
    color: $blue;
    font-weight: 350;
    font-size: $font-size-small;
    font-family: $gotham-medium;
  }

  .modal-description {
    color: $gray;
    font-size: $h4-size;
    display: block;
    min-height: 300px;

    strong,
    b {
      font-family: $gotham-medium !important // Don't blame me, IC has also important, can not override otherwise;
    }
  }
}
</style>
