<template>
  <mobile-id-signing
      visible
      :start-signing="startSigning"
      :complete-signing="completeSigning"
      v-on="$listeners"
  ></mobile-id-signing>
</template>

<script>
import api from '@loan/api'
import MobileIdSigning from '@/components/signing/MobileId'

export default {
  name: 'mobile-id-signing-loan',
  components: { MobileIdSigning },
  props: {
    contract: {
      required: true
    },
    signingRequestId: {
      required: true
    }
  },
  methods: {
    startSigning () {
      return api.startMobileIdSigning(this.contract.id, this.signingRequestId)
    },
    completeSigning () {
      return api.completeMobileIdSigning(this.contract.id, this.signingRequestId)
    }
  }
}
</script>
