<template>
  <div>
    <card-view-item
      v-for="card in cardList"
      :key="card.id"
      :card="card"
      class="credit-card__content__cards__card"
    />
  </div>
</template>

<script>
import CardViewItem from '../components/CardViewItem.vue'

const typePriority = {
  PHYSICAL_AND_VIRTUAL: 0,
  VIRTUAL: 1,
  DEFAULT: Number.MAX_VALUE
}

export default {
  components: {
    CardViewItem
  },
  props: {
    cards: {
      default: []
    }
  },
  computed: {
    cardList () {
      return [...this.cards]
        .sort((a, b) => (typePriority[a.type] ?? typePriority.DEFAULT) - (typePriority[b.type] ?? typePriority.DEFAULT))
    }
  }
}
</script>
<style scoped>
.credit-card__content__cards__card {
  margin-bottom: 44px;
}
</style>
