<template>
      <div>
        <layout-with-menu class="confirm-email" :hideMenu="true">
          <bb-navbar flavor="public" :logo="logo"/>
          <template v-if="loading">
            <div class="loader">
              <bb-spinner />
            </div>
          </template>
          <template v-else>
            <div class="confirm-email__content">
              <div class="confirm-email__icon m-t-35">
                <bb-icon name="illustration-no-account" fill="original" size="fluid" :animated="true"/>
              </div>
              <h4 class="confirm-email__title m-l-10 m-r-10">{{ view.title }}</h4>
              <div class="m-l-25 m-r-25 m-t-35">
                <p v-if="isConfirmed" class="f-small f-color-gray-90">
                  {{ view.description }}
                </p>
                <p v-else class="f-small f-color-gray-90">
                  {{ view.description }}
                  "<span class="f-gotham-medium f-small f-color-navy">{{ view.myAccount }}</span>".
                </p>
              </div>
              <bb-button class="m-t-40" :label="view.buttonText" @click="redirect" />
              <bb-link class="f-small m-t-40" color="mint" to="/help">{{ view.linkText }}</bb-link>
            </div>
          </template>
        </layout-with-menu>
      </div>
</template>

<script>
import accountApi from '../modules/account/api'
import logo from '@bigbank/interface-components/dist/img/bigbank-dark@2x.png'
import { mapActions } from 'pinia'
import { useRootStore } from '../store/root'

export default {
  name: 'confirm-email',
  data () {
    return {
      logo,
      loading: true,
      isConfirmed: false,
      success: {
        title: this.$gettext('Thank you, you have successfully confirmed your e-mail change.'),
        description: this.$gettext('Your new e-mail has been successfully confirmed. You can continue in Self-service.'),
        buttonText: this.$gettext('Back to Self-Service'),
        linkText: this.$gettext('Need help?')
      },
      linkExpired: {
        title: this.$gettext('Link to confirm email change has expired'),
        description: this.$gettext('Since email change was not confirmed, old email address remains valid. You can always change your data in Self-Service under'),
        myAccount: this.$gettext('My account'),
        buttonText: this.$gettext('Back to Self-Service'),
        linkText: this.$gettext('Need help?')
      }
    }
  },
  computed: {
    view () {
      return this.isConfirmed ? this.success : this.linkExpired
    }
  },
  methods: {
    ...mapActions(useRootStore, ['checkAuthentication']),
    async redirect () {
      this.loading = true
      const isLoggedIn = await this.checkAuthentication()
      if (!isLoggedIn) {
        document.location = '/login'
      } else {
        this.$router.push('/')
      }
    }
  },
  async mounted () {
    try {
      this.loading = true
      const { isEmailConfirmed } = await accountApi.confirmEmailChangeRequest(this.$route.params.confirmRequestId)
      this.isConfirmed = isEmailConfirmed === true
    } catch (err) {
      this.isConfirmed = false
    } finally {
      this.loading = false
    }
  }

}
</script>

<style lang="scss">
.confirm-email {
  .bb-navbar__burger-logo-wrapper {
    @media (min-width: $desktop-bp) {
      margin-left: 130px;
    }
  }

  .bb-button {
    border-radius: 6px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  &__icon {
    max-width: 250px;

    @media (min-width: $desktop-bp) {
      max-width: rem(287px);
    }
  }

  &__title {
    font-family: $gotham-medium;
    color: $blue;
    max-width: 348px;
    font-size: 18px;
  }

  .loader {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
