import dayjs from 'dayjs'
import LogoutReason from '@/types/logout-reason'
import CaptureApmErrorMixin from '@/mixins/captureApmErrorMixin'
import { isNil } from 'lodash'
import { mapState } from 'pinia'
import { useRootStore } from '../store/root'
import { Message } from '../service-worker/const'

const UNLOAD_EVENT = 'unload'
const UNLOAD_EVENT_KEY = 'unload'

export default {
  mixins: [CaptureApmErrorMixin],
  data () {
    return {
      numberOfClients: null
    }
  },
  computed: {
    ...mapState(useRootStore, ['sessionId'])
  },
  watch: {
    sessionId: {
      immediate: true,
      handler () {
        this.validateSessionId()
      }
    },
    numberOfClients: {
      immediate: true,
      handler () {
        this.validateSessionId()
      }
    }
  },
  methods: {
    setupServiceWorkerStatusListener () {
      this.$sw
        .on(Message.Status, ({ isSingleAppInstanceOpened, numberOfClients }) => {
          this.numberOfClients = numberOfClients
          isSingleAppInstanceOpened && this.runNavigationSecurityChecks()
        })
        .send(Message.Status)
    },
    validateSessionId () {
      if (!this.sessionId || !this.numberOfClients) {
        return
      }

      const idFromLocalStorage = localStorage.getItem('sessionId')
      const idFromSessionStorage = sessionStorage.getItem('sessionId')
      const isNewTab = !idFromSessionStorage && idFromLocalStorage === this.sessionId

      if (isNewTab && this.numberOfClients === 1) {
        this.captureError()
        this.$router.push({ path: '/logout', query: { reason: LogoutReason.NAVIGATION_SECURITY_FAILED } })
        return
      }

      localStorage.setItem('sessionId', this.sessionId)
      sessionStorage.setItem('sessionId', this.sessionId)
    },
    addUnloadEventListener () {
      window.addEventListener(UNLOAD_EVENT, () => {
        localStorage.setItem(UNLOAD_EVENT_KEY, JSON.stringify({
          sessionId: this.sessionId,
          dateAt: new Date()
        }))
        sessionStorage.setItem(UNLOAD_EVENT_KEY, new Date())
      })
    },
    areNavigationSecurityChecksEnabled () {
      return process.env.NODE_ENV === 'production' && this.featureFlags.enableNavigationSecurityChecks
    },
    isNavigationTypeNavigate () {
      try {
        return performance.getEntriesByType('navigation')[0].type === 'navigate'
      } catch (e) {
        return performance.navigation.type === 0
      }
    },
    hasEnoughTimePassedFromUnload () {
      const { allowedSecondsAfterUnload } = window.NAVIGATION_SECURITY_CHECKS
      const lastUnload = dayjs(sessionStorage.getItem('unload'))
      const secondsAfterUnload = dayjs().diff(lastUnload, 'seconds')

      return (isNaN(secondsAfterUnload) || secondsAfterUnload > allowedSecondsAfterUnload)
    },
    isRequestedRouteUnguarded () {
      const { unguardedRoutes } = window.NAVIGATION_SECURITY_CHECKS

      return unguardedRoutes.some(route => window.location.pathname.includes(route))
    },
    getReferrerHost () {
      return document.referrer && new URL(document.referrer).host
    },
    isReferrerAllowed () {
      const { allowedReferrerDomains, allowedMinutesForEmptyReferrer } = window.NAVIGATION_SECURITY_CHECKS
      const referrerHost = this.getReferrerHost()
      const isReferrerHostEmpty = isNil(referrerHost) || referrerHost === ''
      const lastGlobalUnload = this.getLastGlobalUnload()
      const isEmptyReferrerAllowed = isNil(lastGlobalUnload?.dateAt) || lastGlobalUnload.sessionId !== this.sessionId || dayjs().diff(dayjs(lastGlobalUnload.dateAt), 'minutes') > allowedMinutesForEmptyReferrer
      const isFromAllowedDomain = allowedReferrerDomains.some(domain => referrerHost.endsWith(domain))

      return (isReferrerHostEmpty && isEmptyReferrerAllowed) || isFromAllowedDomain
    },
    getLastGlobalUnload () {
      try {
        return JSON.parse(localStorage.getItem(UNLOAD_EVENT_KEY) ?? '{}')
      } catch (e) {
        return {}
      }
    },
    runNavigationSecurityChecks () {
      if (this.areNavigationSecurityChecksEnabled() &&
        this.isNavigationTypeNavigate() &&
        this.hasEnoughTimePassedFromUnload() &&
        !this.isRequestedRouteUnguarded() &&
        !this.isReferrerAllowed()
      ) {
        this.captureError()
        this.$router.push({ path: '/logout', query: { reason: LogoutReason.NAVIGATION_SECURITY_FAILED } })
      }
    },
    captureError () {
      const debug = this.debug()
      this.captureApmError(`Navigation security check failed. (Referrer: ${encodeURI(this.getReferrerHost())}) \n ${JSON.stringify(debug, null, 2)}`, debug)
    },
    debug () {
      const { allowedReferrerDomains, allowedMinutesForEmptyReferrer } = window.NAVIGATION_SECURITY_CHECKS
      const referrerHost = this.getReferrerHost()
      const isReferrerHostEmpty = isNil(referrerHost) || referrerHost === ''
      const lastGlobalUnload = this.getLastGlobalUnload()
      const isEmptyReferrerAllowed = isNil(lastGlobalUnload?.dateAt) || lastGlobalUnload.sessionId !== this.sessionId || dayjs().diff(dayjs(lastGlobalUnload.dateAt), 'minutes') > allowedMinutesForEmptyReferrer
      const isFromAllowedDomain = allowedReferrerDomains.some(domain => referrerHost.endsWith(domain))

      return {
        isReferrerAllowed: {
          referrerHost,
          isReferrerHostEmpty,
          lastGlobalUnload,
          isEmptyReferrerAllowed,
          isFromAllowedDomain,
          allowedMinutesForEmptyReferrer,
          outcome: (isReferrerHostEmpty && isEmptyReferrerAllowed) || isFromAllowedDomain
        },
        session: {
          sessionId: this.sessionId,
          numberOfClients: this.numberOfClients,
          sessionIdFromLocalStorage: localStorage.getItem('sessionId'),
          sessionIdFromSessionStorage: sessionStorage.getItem('sessionId')
        },
        runNavigationSecurityChecks: {
          areNavigationSecurityChecksEnabled: this.areNavigationSecurityChecksEnabled(),
          isNavigationTypeNavigate: this.isNavigationTypeNavigate(),
          hasEnoughTimePassedFromUnload: this.hasEnoughTimePassedFromUnload(),
          isRequestedRouteUnguarded: this.isRequestedRouteUnguarded(),
          outcome: this.areNavigationSecurityChecksEnabled() &&
            this.isNavigationTypeNavigate() &&
            this.hasEnoughTimePassedFromUnload() &&
            !this.isRequestedRouteUnguarded() &&
            !this.isReferrerAllowed()
        }
      }
    }
  }
}
