<template>
  <div class="loan-deposit-transactions" v-show="isVisible">
    <bb-transactions
      :show-empty-state="showEmptyTransactions"
      :data="filteredTransactionData"
      :filters="areFiltersShown"
      :title-options="transactionStatuses"
      :locale="locale"
      :currency="currencyCode"
      :loading="isLoading"
      icon-color="gray"
      :icon-frame-filled="false"
      :title="translations.transactions"
      :filters-toggle-label="translations.filtersToggleLabel"
      :empty-state-text="emptyTransactionsText"
      @titleClick="onTransactionStatusChange"
    >
      <template v-if="areFiltersShown" #filters>
        <bb-row class="w-100">
          <bb-col col="12">
            <bb-datepicker
                framed
                :placeholder="translations.selectPeriod"
                name="filterByPeriod"
                key="filterByPeriod"
                type="date"
                range
                :confirm-text="translations.ok"
                :shortcuts="false"
                :format="dateFormat"
                :max-date="notAfter"
                :data-vv-as="translations.filterByPeriod"
                :label="translations.filterByPeriod"
                v-model="filter.filterPeriod"
                :lang="datepickerLanguage"
                :custom-floating-ui-options="computePlacement"
            />
          </bb-col>
          <bb-col col="12">
            <bb-select
                framed
                :placeholder="translations.selectType"
                name="filterByType"
                key="filterByType"
                :label="translations.filterByType"
                :data-vv-as="translations.filterByType"
                v-model="filter.filterType"
                :options="filterOptions"
                data-vv-validate-on="change"
            />
          </bb-col>
        </bb-row>
      </template>
      <template #underFilters>
        <bb-account-card
          class="notranslate"
          :loading="isLoading"
          v-if="isAccountCardVisible"
          :title-options="accountCardTitle"
          :title-label="translations.accountCard.titleLabel"
          :copy-button="copyButtonProps"
          :tooltip-content="translations.accountCard.tooltip"
          :sections="accountCardSections"
          @titleClick="selectedAccount"
        />
        <bb-card v-if="showAdditionalInfoCard" size="fluid" class="m-t-15">
          <div class="f-default f-color-gray f-gotham-medium">{{ translations.additionalInfoTitle }}</div>
          <div class="f-smallest f-color-gray f-gotham-book m-t-15" v-sanitize.basic="translations.additionalInfo" />
        </bb-card>
      </template>
      <template #underTransactionsList>
        <div v-if="isShowMoreButtonVisible" class="text-center p-t-25">
          <bb-button
            display="link"
            :label="translations.showMore"
            @click="showMoreTransactions"
          />
        </div>
      </template>
    </bb-transactions>
    <div class="more-button" v-if="showHistoryButton">
      <bb-link class="f-small" :to="showHistoryButton.to">{{ showHistoryButton.label }}</bb-link>
    </div>
    <div v-if="migrationDate && !showHistoryButton" class="m-t-20 f-smallest">
      <bb-separator class="m-b-5" />
      <translate v-if="transactions && transactions.length > 0" :translate-params="{date: migrationDate}">Transactions data is available as of %{date}.</translate>
    </div>
  </div>
</template>

<script>
import { formatMoneyWithCurrency } from '@/plugins/numformat'
import { formatDate } from '@/plugins/dateFormatters'
import dayjs from 'dayjs'
import { mapState } from 'pinia'
import { getTranslation } from '@/plugins/translations'
import { BbAccountCard, BbCard } from '@bigbank/interface-components'
import transactionTypes from '../types/transactions'
import transactionStatuses from '../types/transaction-status'
import accountApi from '@account/api'
import '@bigbank/interface-components/dist/svg/client/empty-cards'
import '@bigbank/interface-components/dist/svg/ui/arrow-bottom-left'
import '@bigbank/interface-components/dist/svg/ui/arrow-top-right'
import '@bigbank/interface-components/dist/svg/ui'
import { isEmpty } from 'lodash'
import { MY_TRANSACTIONS } from '@/const'
import { useRootStore } from '../store/root'

export default {
  name: 'transactions-list',
  components: {
    BbAccountCard,
    BbCard
  },
  props: {
    hideEmptyView: {
      default: false
    },
    visibleTransactionsCount: {
      default: null
    },
    showHistoryButton: {
      default: null
    },
    showReservedTransactions: {
      default: false
    },
    includeReservedTransactions: {
      default: false
    }
  },
  data () {
    return {
      isLoading: true,
      transactions: [],
      // Display the latest 13 contract transactions on the Transactions view.
      requestedTransactionsCount: 13,
      errorWithTransactions: false,
      activeAccount: null,
      accounts: [],
      agreementTypeCodes: [],
      titles: [],
      dateFormat: 'DD.MM.YYYY',
      notBefore: dayjs().add(1, 'day').toDate(),
      maxExpireDate: dayjs().add(30, 'year').toDate(),
      notAfter: dayjs().toDate(),
      filter: {
        filterPeriod: null,
        filterType: null,
        transactionStatus: transactionStatuses.FINISHED
      },
      types: [{
        text: this.$pgettext('money_out', 'Money Out'),
        types: [
          transactionTypes.ACCOUNT_PAYMENT,
          transactionTypes.ACC2SEPA_PAY,
          transactionTypes.DEPO_DEBIT,
          transactionTypes.ACC2INTRA_PAY
        ],
        value: 'MONEY_OUT'
      }, {
        text: this.$pgettext('money_in', 'Money In'),
        types: [
          transactionTypes.DEPO_CREDIT,
          transactionTypes.SEPA2ACC_PAY,
          transactionTypes.INTRA2ACC_PAY
        ],
        value: 'MONEY_IN'
      }, {
        text: this.$pgettext('interest_payment', 'Interest Payment'),
        types: [
          transactionTypes.SAVE_DEPO2ACC3,
          transactionTypes.SAVE_DEPO2ACC3_R,
          transactionTypes.SAVE_DEPO_CREDIT_INT,
          transactionTypes.DEPO_CREDIT_INT
        ],
        value: 'INTEREST_PAYMENT',
        hideWhenNoTransactionsAvailable: true
      }, {
        text: this.$pgettext('income_tax', 'Income Tax'),
        types: [
          transactionTypes.SAVE_DEPO_DEBIT_TAX,
          transactionTypes.SAVE_DEPO_DEBIT_TAX_R,
          transactionTypes.ACC2DEPOTAX
        ],
        value: 'INCOME_TAX',
        hideWhenNoTransactionsAvailable: true
      }, {
        text: this.$pgettext('transactions', 'Fee'),
        types: [
          transactionTypes.ACC2DEPOFEE
        ],
        value: 'FEE',
        hideWhenNoTransactionsAvailable: true
      }]
    }
  },
  computed: {
    ...mapState(useRootStore, [
      'language', 'featureFlags', 'channel', 'isCompany', 'isMobile',
      'locale', 'currency', 'channelConfig', 'datepickerLanguage'
    ]),
    computePlacement () {
      return this.isMobile ? { placement: 'top-start' } : {}
    },
    isVisible () {
      return !this.hideEmptyView || this.transactions.length > 0
    },
    isAccountCardVisible () {
      return this.showAccountInfoCard && !isEmpty(this.activeAccount)
    },
    migrationDate () {
      return false
    },
    copyButtonProps () {
      return {
        successMsg: this.translations.copyButton.successMsg,
        errorMsg: this.translations.copyButton.errorMsg
      }
    },
    accountCardTitle () {
      return this.titles
    },
    accountCardSections () {
      if (!this.activeAccount || !this.accounts) return
      const account = this.accounts.find(acc => this.activeAccount.label === acc.iban)
      if (!account) return

      return [
        {
          icon: 'ui-lock-outline',
          iconColor: 'blue',
          label: this.translations.accountCard.reservations,
          content: formatMoneyWithCurrency(account.reservation ? account.reservation : 0, account.currencyCode, this.locale)
        },
        {
          icon: 'ui-cash',
          iconColor: 'green',
          label: this.translations.accountCard.availableBalance,
          content: formatMoneyWithCurrency(account.availableBalance ? account.availableBalance : 0, account.currencyCode, this.locale)
        }
      ]
    },
    showAccountInfoCard () {
      return false
    },
    showAdditionalInfoCard () {
      return this.channel === 'BG'
    },
    currencyCode () {
      const currencyCode = this.transactions ? this.transactions[0]?.currencyCode : null

      return currencyCode || this.currency
    },
    transactionStatuses () {
      const statuses = [{
        label: this.includeReservedTransactions ? this.$pgettext('transaction_type', 'Transactions') : this.$pgettext('transaction_type', 'Completed Transactions'),
        active: this.filter.transactionStatus === transactionStatuses.FINISHED,
        id: transactionStatuses.FINISHED,
        filters: this.areFiltersShown
      }]

      if (this.showReservedTransactions) {
        statuses.push({
          label: this.$pgettext('transaction_type', 'Reserved Transactions'),
          active: this.filter.transactionStatus === transactionStatuses.RESERVED,
          id: transactionStatuses.RESERVED,
          filters: this.areFiltersShown
        })
      }

      return statuses.length > 1 ? statuses : undefined
    },
    transactionData () {
      if (this.isLoading) {
        const emptyItem = {
          icon: ''
        }

        return [emptyItem, emptyItem, emptyItem]
      }

      const hideIban = this.featureFlags.hideCounterpartyIbanInTransactionsList

      return this.transactions.map(transaction => ({
        icon: transaction.transactionDirectionCode === 'OUT' ? 'ui-arrow-top-right' : 'ui-arrow-bottom-left',
        transactionTypeCode: transaction.transactionTypeCode,
        statusColumn: {
          progress: [
            hideIban || transaction.transactionDirectionCode !== 'OUT' ? false : transaction.counterpartyIban,
            !transaction.isReserved ? false : `${this.translations.reservedUntil} ${formatDate(transaction.date)}`
          ].filter(item => !!item).join(' | '),
          status: this.translations.types[transaction.transactionTypeCode] ||
              getTranslation(transaction.transactionTypeCode, ['TRANSACTION_TYPE', 'TRANSACTION_TYPE_RESERVED'], transaction.transactionTypeCode, this.isCompany)
        },
        middleColumns: [],
        lastColumn: {
          date: new Date(transaction.date).toLocaleDateString(this.locale),
          amount: Math.abs(transaction.amount)
        },
        income: transaction.transactionDirectionCode === 'IN',
        date: transaction.date
      }))
    },
    filteredTransactionData () {
      let transactions = [...this.transactionData]

      if (this.filter.filterType) {
        transactions = this.filterByType(transactions, this.filter.filterType)
      }

      if (this.filter.filterPeriod) {
        transactions = transactions.filter(transaction =>
          dayjs(transaction.date).add(1, 'day').isAfter(this.filter.filterPeriod[0]) &&
            dayjs(transaction.date).subtract(1, 'day').isBefore(this.filter.filterPeriod[1])
        )
      }

      if (this.visibleTransactionsCount !== null) {
        transactions = transactions.slice(0, this.visibleTransactionsCount)
      }

      if (this.requestedTransactionsCount !== null) {
        transactions = transactions.slice(0, this.requestedTransactionsCount)
      }

      return transactions
    },
    showEmptyTransactions () {
      return this.filteredTransactionData.length < 1
    },
    areFiltersShown () {
      return [transactionStatuses.FINISHED].includes(this.filter.transactionStatus) && this.transactions && this.areFiltersShownLocal
    },
    defaultTranslations () {
      return {
        // types block can be deleted after translations for INTRA2ACC_PAY, SEPA2ACC_PAY, ACC2SEPA_PAY with context TRANSACTION_TYPE have been added
        // and new transactions api is used
        types: {
          INCOMING: this.$pgettext('incoming_transaction_description', 'Transfer received'),
          OUTGOING: this.$pgettext('outgoing_transaction_description', 'Money transfered'),
          INTRA2ACC_PAY: this.$pgettext('incoming_transaction_description', 'Transfer received'),
          SEPA2ACC_PAY: this.$pgettext('incoming_transaction_description', 'Transfer received'),
          ACC2SEPA_PAY: this.$pgettext('outgoing_transaction_description', 'Money transfered')
        },
        accountCard: {
          reservations: this.$pgettext('accound_card', 'Reservation'),
          availableBalance: this.$pgettext('accound_card', 'Available Balance'),
          tooltip: this.$pgettext('account_card', 'In case you wish to make any changes or take money on payment date, please proceed to \'manage my loan\''),
          titleLabel: this.$pgettext('account_card', 'Servicing account number')
        },
        copyButton: {
          successMsg: this.$gettext('Copied!'),
          errorMsg: this.$gettext('Copy failed!')
        },
        transactions: this.$gettext('Transactions'),
        filtersToggleLabel: this.$gettext('Filters'),
        filterByPeriod: this.$pgettext('filter_by_period', 'Filter by period'),
        filterByContract: this.$pgettext('offer_card_demand_deposit', 'Filter by contract'),
        filterByType: this.$gettext('Filter by type'),
        selectPeriod: this.$pgettext('filter_by_period', 'Select a period'),
        selectType: this.$pgettext('filter_by_type', 'Select type'),
        reservedUntil: this.$pgettext('transactions', 'Reserved until:'),
        additionalInfoTitle: this.$pgettext('transactions', 'Additional information'),
        additionalInfo: this.$pgettext('transactions', 'In the event that you wish to receive information about the originator or the additional characteristics of a specific incoming transfer on your account, you can contact us by phone %{phone} or %{email}')
          .replace('%{phone}', `<strong class="f-color-mint">${this.channelConfig.phone}</strong>`)
          .replace('%{email}', `<a class="f-color-mint" href="mailto:${this.channelConfig.email}"><strong>${this.channelConfig.email}</strong></a>`),
        showMore: this.$pgettext('transactions', 'Show more transactions')
      }
    },
    translations () {
      return this.defaultTranslations
    },
    emptyTransactionsText () {
      return ''
    },
    filterOptions () {
      return this.types
        .filter(type => !type.hideWhenNoTransactionsAvailable || this.filterByType(this.transactionData, type.value).length > 0)
        .map(({ text, value }) => ({ text, value }))
    },
    visibleAccountIds () {
      return null
    },
    isShowMoreButtonVisible () {
      const isPossibleToShowMore = this.requestedTransactionsCount <= this.transactions.length
      return !this.showHistoryButton && !this.isLoading && isPossibleToShowMore
    }
  },
  methods: {
    setLoading (flag) { this.isLoading = flag },
    selectedAccount (account) {
      this.activeAccount = account
    },
    filterByType (transactions, filterValue) {
      const { types } = this.types.find(({ value }) => value === filterValue)
      return transactions.filter(transaction => types.includes(transaction.transactionTypeCode))
    },
    async loadTransactions () {
      return []
    },
    async setAccounts (accounts) {
      const hasAgreementTypeCodeFiltering = Array.isArray(this.agreementTypeCodes) && this.agreementTypeCodes.length > 0
      const hasAccountIdFiltering = Array.isArray(this.visibleAccountIds)

      if (Array.isArray(accounts) && !isEmpty(accounts)) {
        this.accounts = accounts
        this.titles = accounts
          .filter(acc => !hasAgreementTypeCodeFiltering || this.agreementTypeCodes.includes(acc.agreementTypeCode))
          .filter(acc => !hasAccountIdFiltering || this.visibleAccountIds.includes(acc.id))
          .map(acc => ({ label: acc.iban }))
      }

      if (isEmpty(this.titles)) {
        this.activeAccount = null
      } else {
        this.titles[0].active = true
        this.activeAccount = this.titles[0]
      }
    },
    setTransactions (transactions) { this.transactions = transactions },
    onTransactionStatusChange ({ id }) {
      this.filter.transactionStatus = id
      this.refresh()
    },
    showMoreTransactions () {
      this.requestedTransactionsCount += MY_TRANSACTIONS.VISIBLE_TRANSACTIONS_PER_CLICK
    },
    async refresh () {
      this.filter.filterPeriod = null
      this.filter.filterType = null
      this.errorWithTransactions = false

      this.setLoading(true)

      try {
        const [transactions, accounts] = await Promise.all([
          this.loadTransactions(),
          accountApi.getCustomerAccounts()
        ])

        this.setAccounts(accounts)
        this.setTransactions(Array.isArray(transactions) && !isEmpty(transactions) ? transactions : [])
      } catch (err) {
        this.errorWithTransactions = true
      } finally {
        this.setLoading(false)
      }
    }
  },
  async created () {
    await this.refresh()
  }
}
</script>

<style lang="scss" scoped>
.empty {
  margin-top: 30px;

  @media (min-width: $desktop-view-breaking-point + 1) {
    margin-top: 0;
  }
}

.more-button {
  text-align: right;
}
</style>
