export default Object.freeze({
  CHECK_AUTHENTICATION_FAILED: 'CheckAuthenticationFailed',
  CONFIRM_DATA_LOGOUT_CLICK: 'ConfirmDataLogoutClick',
  INACTIVITY_MODAL_BUTTON_CLICK: 'InactivityModalButtonClick',
  MENU_BUTTON_CLICK: 'MenuButtonClick',
  MENU_BUTTON_CLICK_NOT_CUSTOMER: 'MenuButtonClickNotCustomer',
  NAVIGATION_SECURITY_FAILED: 'NavigationSecurityFailed',
  NO_ACCESS_ERROR_VIEW: 'NoAccessErrorView',
  SESSION_EXPIRED: 'SessionExpired',
  SESSION_EXPIRED_ERROR_VIEW: 'SessionExpiredErrorView',
  TERMS_AND_CONDITIONS_LOGOUT_CLICK: 'TermsAndConditionsLogoutClick',
  TERMS_ACCEPTED_BUT_CHECK_AUTHENTICATION_FAILED: 'TermsAcceptedButCheckAuthenticationFailed',
  OPEN_SAVINGS_DEPOSIT_MODAL: 'OpenSavingsDepositModal',
  OPEN_CURRENT_ACCOUNT_MODAL: 'OpenCurrentAccountModal'
})
