<template>
  <pay-back v-bind="payBackProps">
    <div v-if="hasOutstandingFunds" slot="payment-description" class="m-t-25 product-info color-gray">
      <span class="color-gray-70">{{ contract.contractNumber }}</span>
    </div>
    <div v-else slot="payment-details"/>
  </pay-back>
</template>

<script>
import PayBack from '@loan/components/PayBack'
import { LOAN_DASHBOARD } from '@/TrackingActions'
import payBackModalMixin from '@/mixins/payBackModalMixin'

export default {
  name: 'consumer-loan-pay-back',
  components: {
    PayBack
  },
  mixins: [payBackModalMixin],
  data () {
    return {
      isLoading: false,
      invoiceDetails: null,
      repaymentDetails: null,
      nextPaymentDetails: null,
      hasCopied: false
    }
  },
  props: {
    contract: {
      required: true
    }
  },
  computed: {
    payBackProps () {
      return {
        isLoading: this.isLoading,
        contract: this.contract,
        onClose: this.onClose,
        trackingAction: LOAN_DASHBOARD.VIEW_INVOICE_DETAILS,
        invoiceDetails: this.invoiceDetails,
        repaymentDetails: this.repaymentDetails,
        nextPaymentDetails: this.nextPaymentDetails
      }
    }
  },
  async mounted () {
    await this.loadData()
  }
}
</script>

<style lang="scss" scoped>
.product-info {
  font-family: $gotham-medium;
  text-align: center;
  font-size: $font-size-smallest;
  user-select: none;
  text-transform: uppercase;

  @media (max-width: $mobile-view-breaking-point) {
    font-size: $mobile-small-font-size;
  }
}

</style>
