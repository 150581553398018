<template>
  <div class="sliders">
    <bb-slider
            :label="translations.chooseAmount"
            :unit="currency"
            :min="minAmount"
            :max="maxAmount"
            :decimals=2
            :locale="locale"
            :step="amountStep"
            v-model="amount"
    ></bb-slider>
    <bb-slider
            :key="paymentFrequencyCode"
            :label="translations.choosePeriod"
            :unit="periodUnit"
            :min="minPeriod"
            :max="maxPeriod"
            :decimals=0
            :step="1"
            :options="validPeriods"
            v-model="period"
    ></bb-slider>
    <div class="expected-profit-wrapper">
      <p class="expected-profit-text uppercase">{{ translations.expectedProfit }}</p>
      <div v-if="isLoading || isAccountInterestParametersLoading" class="expected-profit-loading">
        <bb-skeleton width="160" height="22" />
        <bb-skeleton width="160" height="12" class="m-t-5" />
      </div>
      <div v-else>
        <p class="expected-profit-sum">{{ formattedProfit }}</p>
        <p class="expected-profit-text text-margin">
          <span class="interest">={{ formattedInterest }} </span>
          <span class="uppercase">{{ translations.interest }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import { api } from '../../api'
import getCurrency from '../../utils/getCurrency'
import { recalculateAmount, recalculatePeriod } from '../../utils/recalculate'
import { areAccountInterestParametersValid } from '@/plugins/validators'
import { formatMoneyWithCurrency, formatInterest } from '@/plugins/numformat'
import SliderMixin from '../../mixins/sliderMixin'

export default {
  name: 'deposit-sliders',
  mixins: [SliderMixin],
  data () {
    return {
      amount: this.value.amount,
      period: this.value.period,
      expectedProfit: 0,
      interest: 0.1,
      bonusInterest: 0,
      isAccountInterestParametersLoading: false
    }
  },
  props: {
    isLoading: Boolean,
    selectedDepoSum: Number,
    periodCode: {
      type: String,
      default: 'MONTHS',
      validator: (val) => ['DAYS', 'MONTHS', 'YEARS'].includes(val)
    },
    startDate: {
      type: String,
      required: true
    },
    manualProlong: Boolean,
    agreementTypeCode: String,
    value: Object,
    paymentFrequencyCode: String,
    accountType: Object,
    minAmount: Number,
    maxAmount: Number,
    minPeriod: Number,
    maxPeriod: Number,
    validPeriods: Array,
    locale: String
  },
  methods: {
    async getAccountInterestParameters () {
      if (this.isAccountInterestParametersValid()) {
        const response = await api.getAccountInterestParameters(
          this.amount,
          this.period,
          this.periodCode,
          this.paymentFrequencyCode,
          this.startDate,
          this.manualProlong,
          this.agreementTypeCode
        )

        this.interest = response.interest
        this.bonusInterest = response.bonusInterest
      }
    },
    async getDepositInterest () {
      this.expectedProfit = await api.getDepositInterest(this.amount, this.period, this.interest, this.startDate, this.periodCode)
    },
    emitSliderInputValues () {
      const message = { amount: this.amount, period: this.period, annualInterestRate: this.interest, bonusInterest: this.bonusInterest }
      this.$emit('input', message)
    },
    isAccountInterestParametersValid () {
      return areAccountInterestParametersValid({
        amount: this.amount,
        minAmount: this.minAmount,
        maxAmount: this.maxAmount,
        period: this.period,
        minPeriod: this.minPeriod,
        maxPeriod: this.maxPeriod,
        periodCode: this.periodCode,
        paymentFrequencyCode: this.paymentFrequencyCode,
        startDate: this.startDate,
        agreementTypeCode: this.agreementTypeCode
      })
    }
  },
  watch: {
    async amount () {
      this.$emit('DisableContinue')
      await this.debounceWatchAmount()
    },
    async period (newPeriod, oldPeriod) {
      this.$emit('DisableContinue')
      await this.debounceWatchPeriod(newPeriod, oldPeriod)
    },
    async paymentFrequencyCode (val) {
      this.$emit('DisableContinue')
      await this.debounceWatchPaymentFrequencyCode(val)
    }
  },
  async mounted () {
    this.isAccountInterestParametersLoading = true
    this.$emit('DisableContinue')
    await this.getAccountInterestParameters()
    await this.getDepositInterest()
    this.isAccountInterestParametersLoading = false
    this.$emit('CalculateContinue')
  },
  computed: {
    translations () {
      return {
        chooseAmount: this.$gettext('HOW MUCH YOU WANT TO PROLONG'),
        choosePeriod: this.$gettext('WHEN YOU WANT IT BACK?'),
        expectedProfit: this.$gettext('YOUR EXPECTED PROFIT'),
        interest: this.$gettext('INTEREST RATE'),
        periodCodes: {
          MONTHS: this.$pgettext('period_code', 'mo'),
          DAYS: this.$pgettext('period_code', 'd'),
          YEARS: this.$pgettext('period_code', 'y')
        }
      }
    },
    currency () {
      return getCurrency(this.accountType.restrictedCurrencyCode)
    },
    periodUnit () {
      return this.translations.periodCodes[this.periodCode]
    },
    formattedProfit () {
      return formatMoneyWithCurrency(this.expectedProfit, this.currency, this.locale)
    },
    formattedInterest () {
      if (this.bonusInterest > 0) {
        return formatInterest(this.interest - this.bonusInterest) + '% +' + formatInterest(this.bonusInterest) + '%'
      } else {
        return formatInterest(this.interest, this.locale) + '%'
      }
    }
  },
  created () {
    this.debounceWatchAmount = debounce(async () => {
      this.isAccountInterestParametersLoading = true
      this.$emit('DisableContinue')
      this.amount = recalculateAmount(this.amount, this.minAmount, this.maxAmount)
      await this.getDepositInterest()
      this.emitSliderInputValues()
      this.isAccountInterestParametersLoading = false
      this.$emit('CalculateContinue')
    }, 200)

    this.debounceWatchPeriod = debounce(async (newPeriod, oldPeriod) => {
      this.isAccountInterestParametersLoading = true
      this.$emit('DisableContinue')
      this.period = recalculatePeriod(newPeriod, oldPeriod, this.validPeriods)
      if (this.period !== newPeriod) {
        return
      }

      await this.getAccountInterestParameters()
      await this.getDepositInterest()
      this.emitSliderInputValues()
      this.isAccountInterestParametersLoading = false
      this.$emit('CalculateContinue')
    }, 200)

    this.debounceWatchPaymentFrequencyCode = debounce(async () => {
      this.isAccountInterestParametersLoading = true
      this.$emit('DisableContinue')
      this.amount = recalculateAmount(this.amount, this.minAmount, this.maxAmount)
      this.period = recalculatePeriod(this.period, this.period, this.validPeriods)
      await this.getAccountInterestParameters()
      await this.getDepositInterest()
      this.emitSliderInputValues()
      this.isAccountInterestParametersLoading = false
      this.$emit('CalculateContinue')
    }, 200)
  }
}
</script>

<style scoped lang="scss">
  .sliders {
    .slider {
      margin-bottom: 20px;
    }

    .expected-profit-wrapper {
      border: 2px solid $gray-30;
      border-radius: 10px;
      text-align: center;
      padding: 9px 15px 8px;

      .expected-profit-text {
        font-size: $tiniest-font-size;
        color: $gray-90;
        font-weight: bold;

        .interest {
          font-size: $font-size-tiny;
          font-family: $gotham-medium;
          color: $gray;
        }
      }

      .expected-profit-sum {
        font-family: $gotham-bold;
        font-size: $h3-size;
        color: $blue;
      }

      .expected-profit-loading {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 7px;
        padding-bottom: 3px;
      }
    }
  }

  .text-margin {
    margin-top: -3px;
  }
</style>
