<template>
  <div>
    <bb-modal visible @close="onClose()" flavor="card" color="white" publicSize="m" fullMobile class="loan-transfer">
      <template v-if="loading">
        <div class="loan-transfer__loader">
          <bb-spinner />
        </div>
      </template>
      <template v-else>
        <div class="loan-transfer__content">
          <div class="loan-transfer__title"><translate translate-context="transfer_modal_title">Transfer to IBAN</translate></div>
          <bb-form v-if="!showDebtView" @progress="progress">
            <bb-box thin class="loan-transfer__balance">
              <bb-big-input
                name="withdrawalAmount"
                :v-model="form.amount"
                placeholder="0,00"
                :label="withdrawalAmountLabel"
                v-validate="amountValidationRules"
                :suffix="'\xa0' + currencySymbol"
                :data-vv-as="translations.labels.amount"
                @blur="onBlur"
                @input="onAmountInput"
              />
            </bb-box>
            <bank-account-input
              type="select"
              :showAddNewAccount="false"
              :showToAnotherAccount="true"
              @input="onBankAccountInput"
              :validatedAccounts="validatedAccounts"
              :value="form.account"
              :validateIbanCountry="false"
              :accountNumberFormatter="accountNumberFormatter"
              flavor="revolving"
            />
            <template v-if="selectedAccount">
              <bb-input
                name="beneficary"
                type="text"
                :label="translations.labels.beneficary"
                :value="beneficary"
                :readonly="true" />
              <bb-input
                v-if="showBicSwift"
                name="bic"
                type="text"
                :label="translations.labels.bicSwift"
                :value="bic"
                :readonly="true" />
            </template>
            <bb-banner
              class="m-t-40 loan-transfer__notice"
              v-else-if="isOtherAccount"
              :visible="true"
              permanent
            >
              {{transferNotice}}
            </bb-banner>
            <bb-list-group class="m-t-20" v-if="showFees">
              <bb-list-item condensed :title="translations.transferFee" :titleUppercase="false">
                {{format(transferFee)}}
              </bb-list-item>
              <bb-list-item condensed :title="translations.amountToYourAccount" :titleUppercase="false">
                {{format(amountToAccount)}}
              </bb-list-item>
            </bb-list-group>
            <div slot="submit" slot-scope="{}" />
          </bb-form>
          <div class="loan-transfer__debt" v-else>
            <div class="loan-transfer__debt__icon">
              <bb-icon animated fill="original" name="illustration-no-connection" size="fluid" />
            </div>
            <div class="loan-transfer__debt__text">
              <translate>Currently you cannot transfer to your IBAN. One or more contracts might be in debt, please take a look at the details below:</translate>
            </div>
            <bb-list-group>
              <bb-list-item
                v-for="contract in inDebtContracts"
                :key="contract.id"
                extraCondensed
                :title="contract.contractNumber"
              >
                <bb-button
                  display="link"
                  noUnderline
                  iconAfter="ui-chevron-right"
                  iconAfterColor="gray-70"
                  color="red"
                  @click="$router.push({name: 'loan-contract-details', params: { contractId: contract.id }})"
                >
                  {{format(contract.totalDebtAmount, contract.currencyCode)}}
                </bb-button>
              </bb-list-item>
            </bb-list-group>
          </div>
        </div>
      </template>
      <bb-ticket-stub slot="footer" class="m-t-15">
        <bb-button display="ticket" v-bind="button" class="no-padding" @click="submit">
          <span class="color-green" v-if="isOtherAccount || showDebtView"><translate>Got It</translate></span>
          <span v-else><translate>Transfer to my IBAN</translate></span>
        </bb-button>
      </bb-ticket-stub>
    </bb-modal>
    <signing-modal
      ref="signing"
      :contract="revolvingLoan"
      :initMethod="initSigning"
      @signingSuccessful="complete"
      @signingCancelled="cancelled"
    />
  </div>
</template>

<script>
import { mapState } from 'pinia'
import api from '@loan/api'
import { formatMoneyWithCurrency } from '@/plugins/numformat'
import BankAccountInput from '@/components/Input/BankAccountInput'
import SigningModal from '@loan/components/signing/SigningModal'
import { withdrawalAmountValidator } from '@/plugins/validators'
import { REVOLVING_DASHBOARD } from '@/TrackingActions'
import { useRootStore } from '../../../../store/root'
import { useLoanStore } from '@loan/store/loanStore'

import '@bigbank/interface-components/dist/svg/illustration/no-connection'
import '@bigbank/interface-components/dist/svg/ui/chevron-right'

export default {
  name: 'revolving-loan_transfer',
  components: {
    BankAccountInput,
    SigningModal
  },
  data () {
    return {
      loading: true,
      signingLoading: false,
      validatedAccounts: null,
      isValid: false,
      form: {
        account: null,
        amount: ''
      },
      minimalTransferAmount: 0.01,
      chosenSignMethod: null,
      signingRequestId: null
    }
  },
  watch: {
    validatedAccounts () {
      if (this.form.account || this.validatedAccounts.length <= 0) {
        return
      }

      this.form.account = this.validatedAccounts[0].accountNumber
    }
  },
  computed: {
    ...mapState(useRootStore, ['channel', 'locale']),
    ...mapState(useLoanStore, ['contracts', 'revolvingLoan']),
    translations () {
      return {
        labels: {
          amount: this.$pgettext('transfer_form_label', 'Withdrawal amount'),
          beneficary: this.$pgettext('transfer_form_label', 'Beneficary'),
          bicSwift: this.$pgettext('transfer_form_label', 'BIC/SWIFT')
        },
        errors: {
          min: this.$pgettext('transfer_form_error', 'Amount must be at least {amount}'),
          max: this.$pgettext('transfer_form_error', 'Amount exceeds your current available limit: {amount}')
        },
        transferNotice: {
          SE: this.$pgettext('transfer_form', 'In order for the new account to be validated, please send the account statement to us via email or contact our customer service.'),
          DEFAULT: this.$pgettext('transfer_form', 'In order for the new accout to be validated, please transfer 0,01 eur from that account to {iban} or contact our customer service')
        },
        transferFee: this.$pgettext('transfer_form', 'Transfer Fee'),
        amountToYourAccount: this.$pgettext('transfer_form', 'Amount To Your Account'),
        transferSuccessful: this.$pgettext('transfer_form', 'We have recieved your transfer request and are processing it.'),
        available: this.$gettext('Available')
      }
    },
    showDebtView () {
      return this.revolvingLoan.isServicingAccountBlocked
    },
    showBicSwift () {
      return !['SE'].includes(this.channel)
    },
    inDebtContracts () {
      return this.contracts.filter(contract => contract.isInDebt)
    },
    availableToTransfer () {
      return this.revolvingLoan.availableLimit
    },
    isOtherAccount () {
      return this.form.account === 'OTHER'
    },
    button () {
      return {
        color: this.isOtherAccount || this.showDebtView ? 'white' : (this.isValid && !this.signingLoading ? 'green' : 'gray'),
        loading: this.loading || this.signingLoading
      }
    },
    amountValidationRules () {
      return {
        required: true,
        regex: /^(0.\d{1,2}|[1-9]\d*(.\d{1,2})?)$/,
        withdrawal_amount: {
          min: this.minimalTransferAmount,
          max: this.availableToTransfer
        }
      }
    },
    currencySymbol () {
      return this.revolvingLoan.currency
    },
    selectedAccount () {
      if (!this.validatedAccounts || !this.form.account) {
        return null
      }

      return this.validatedAccounts.find(({ accountNumber }) => accountNumber === this.form.account)
    },
    beneficary () {
      return this.selectedAccount && this.selectedAccount.accountOwner
    },
    bic () {
      return this.selectedAccount && this.selectedAccount.bankCode
    },
    transferNotice () {
      const options = this.translations.transferNotice
      return (options[this.channel] || options.DEFAULT).replace('{iban}', this.revolvingLoan.iban)
    },
    showFees () {
      return parseFloat(this.form.amount) > 0 && this.revolvingLoan.disbursmentFeeRate > 0 && this.isValid && !this.isOtherAccount
    },
    transferFee () {
      return parseFloat(this.form.amount) * (this.revolvingLoan.disbursmentFeeRate / 100)
    },
    amountToAccount () {
      return parseFloat(this.form.amount) - this.transferFee
    },
    withdrawalAmountLabel () {
      return `${this.translations.available}: ${this.revolvingLoan.availableLimit} ${this.currencySymbol}`
    }
  },
  methods: {
    onBlur () {
      const value = +this.form.amount ? (+this.form.amount).toFixed(2) : ''
      this.form.amount = value.toString()
    },
    onClose () {
      return this.$router.push({ name: 'revolving-loan' })
    },
    onAmountInput (value) {
      this.form.amount = value
    },
    onBankAccountInput (value) {
      this.form.account = value

      if (this.isOtherAccount) {
        this.$tracker.action(
          REVOLVING_DASHBOARD.REVOLVING_CHOOSE_ANOTHER_IBAN_FOR_TRANSFER,
          {}, { contractId: this.revolvingLoan.id.toString() }
        )
      }
    },
    progress (value) {
      this.isValid = value === 1
    },
    async submit () {
      if (this.isOtherAccount || this.showDebtView) {
        return this.onClose()
      }

      if (this.signingLoading || !await this.$validator.validate()) {
        return
      }

      this.$tracker.action(
        REVOLVING_DASHBOARD.REVOLVING_PROCEED_TO_CONFIRM_TRANSFER,
        {}, { contractId: this.revolvingLoan.id.toString() }
      )

      this.$refs.signing.signButtonClick(new Event('click'))
    },
    async initSigning () {
      this.signingLoading = true
      const initResponse = await api.initTransfer(this.revolvingLoan.id, this.form)
      return initResponse
    },
    complete () {
      this.onClose()
      this.$notify({ text: this.translations.transferSuccessful, duration: 10000 })
      window.eventBus.$emit('revolving-loan__transfer')
    },
    cancelled () {
      this.signingLoading = false
    },
    format (value, currencyCode) {
      return formatMoneyWithCurrency(value, currencyCode || this.revolvingLoan.currencyCode, this.locale)
    },
    accountNumberFormatter (account) {
      if (['SE'].includes(this.channel)) {
        return [account.bankCode, account.accountNumber].join(', ')
      } else {
        return account.accountNumber
      }
    }
  },
  async mounted () {
    if (!this.revolvingLoan) {
      return this.$router.push({ name: 'revolving-loan' })
    }
    this.loading = true
    this.validatedAccounts = await api.getValidatedAccounts()
    this.$tracker.action(
      REVOLVING_DASHBOARD.REVOLVING_VIEW_TRANSFER_TO_IBAN_MODAL,
      {}, { contractId: this.revolvingLoan.id.toString() }
    )
    this.loading = false
  },
  created () {
    this.$validator.extend('withdrawal_amount', withdrawalAmountValidator({
      minError: this.translations.errors.min,
      maxError: this.translations.errors.max,
      formatter: (value) => this.format(value)
    }))
  }
}
</script>

<style lang="scss" scoped>
.loan-transfer {
  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__title {
    color: $blue;
    font-size: $default-font-size;
    font-family: $gotham-bold;
    text-align: center;
  }

  &__balance {
    margin-top: 45px;

    .bb-separator {
      display: none;
    }
  }

  &__close-confirmation {
    &__buttons {
      display: flex;
      justify-content: space-between;
      padding: 0 1.875rem 1.875rem;

      .bb-button {
        min-width: 45%;
      }
    }
  }

  &__transfer-notice {
    .bb-banner__body {
      display: block;
    }
  }

  &__debt {
    &__icon {
      max-width: 200px;
      margin: auto;
      margin-top: 25px;
    }

    &__text {
      font-size: $font-size-small;
      font-family: $gotham-book;
      text-align: center;
      color: $gray;
      margin-top: 50px;
      margin-bottom: 40px;
    }

    &__amount {
      color: $red;
      font-size: $font-size-small;

      &__icon {
        width: 28px;
        height: 24px;
      }
    }
  }
}
</style>
