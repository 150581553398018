<template>
  <id-card-signing
      visible
      :start-signing="startSigning"
      :complete-signing="completeSigning"
      :lang="language"
      v-on="$listeners"
  ></id-card-signing>
</template>
<script>
import { api } from '../../api'
import { mapState } from 'pinia'
import IdCardSigning from '@/components/signing/IdCard'
import { useRootStore } from '../../../../store/root'

export default {
  name: 'id-card-signing-deposit',
  components: {
    IdCardSigning
  },
  props: {
    deposit: {
      required: true
    },
    signingRequestId: {
      required: true
    }
  },
  computed: {
    ...mapState(useRootStore, ['language'])
  },
  methods: {
    startSigning (certificateBase64, supportedSignatureAlgorithms) {
      return api.initSigningIdCard(this.deposit.id, this.signingRequestId, {
        certificateBase64,
        supportedSignatureAlgorithms
      })
    },
    completeSigning (signatureBase64) {
      return api.completeSigningIdCard(this.deposit.id, this.signingRequestId, { signatureBase64 })
    }
  }
}
</script>
