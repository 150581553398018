<template>
  <bb-modal
    flavor="card"
    class="confirm-modal"
    publicSize="m"
    @close="e => $emit('close', e)"
    fullMobile
    :visible="visible"
  >
    <template #default>
      <div class="page-title f-color-blue f-small f-align-center f-medium">
        <translate>Here is your summary</translate>
      </div>

      <div v-if="!isDemandDeposit" class="f-align-center p-20 m-b-10">
        <div class="h3 m-0 f-color-navy-90">{{formattedExpectedBalance}}</div>
        <div class="f-color-gray-70 f-tiny f-medium">
          <translate>Your expected balance</translate>
        </div>
        <div v-if="specialInterestRequest" class="f-tiny f-gotham-book f-color-gray-80">
          <translate>Interest rate is not final.</translate>
        </div>
      </div>

      <bb-list-group v-if="!isDemandDeposit">
        <bb-list-item extraCondensed
          titleUppercase
          :title="translations.depositSumTitle"
        >{{formattedDepositSum}}</bb-list-item>
        <bb-list-item extraCondensed
          titleUppercase
          :title="translations.depositPeriodTitle"
        >{{ depositPeriod }} {{ depositPeriodCode }}</bb-list-item>
        <bb-list-item extraCondensed
          titleUppercase
          :title="translations.annualInterestRateTitle"
        >{{ annualInterestRate }}</bb-list-item>
        <bb-list-item extraCondensed
          titleUppercase
          :title="translations.interestPaymentAtTitle"
        >{{ paymentFrequencyText }}</bb-list-item>
      </bb-list-group>

      <div v-if="!isDemandDeposit && !featureFlags.disableAutomaticProlongation" class="prolongation">
        <bb-toggle-switch
          name="prolongation"
          :label="translations.automaticProlongationTitle"
          showStatus
          v-model="automaticProlongation"
          @input="onProlongToggleChange"
          :onStatusLabel="translations.onLabel"
          :offStatusLabel="translations.offLabel"
          uncheckedColor="gray"
          :disabled="isLoading"
          class="m-t-30 f-small f-gotham-book">
            {{ translations.automaticProlongationTitle }}
            <bb-tooltip>{{ translations.automaticProlongationTooltip }}</bb-tooltip>
        </bb-toggle-switch>
      </div>

      <div v-if="isDemandDeposit">
        <div class="h3 m-0 f-color-navy-90">{{formattedExpectedBalance}}</div>
        <div class="f-color-gray-70 f-tiny f-medium">
          {{ translations.initialDepositAmount }}
        </div>
      </div>

      <bb-list-group v-if="isDemandDeposit">
        <bb-list-item extraCondensed
          :titleUppercase="false"
          :title="translations.annualInterestRateTitle"
        >{{ annualInterestRate }}</bb-list-item>
        <bb-list-item extraCondensed
          :titleUppercase="false"
          :title="translations.interestPaymentAtTitle"
        >{{ paymentFrequencyText }}</bb-list-item>
      </bb-list-group>

      <div v-if="showTransferBox" class="m-t-30 f-align-center">
        <div class="f-tiny f-color-gray">{{ transferText }}:</div>
        <bb-box class="f-align-center m-t-15">
          <div class="f-gotham-bold f-h3 m-y-15 f-color-blue">
            {{formattedTransferAmount}}
            <div v-if="displayTransferDate" class="f-gotham-book f-small f-color-gray-70">
              <translate>on</translate>
              {{ transferDate | formatDate }}
            </div>
          </div>
        </bb-box>
      </div>
      <div v-else class="p-b-25"></div>
    </template>
    <template #footer>
      <bb-button
        @click="goToNextStep"
        :loading="isLoading"
        :disabled="isLoading"
        :size="computedButtonSize"
        corner="sharpMobileOnly"
        display="block">
        <translate>Confirm and sign</translate>
      </bb-button>
    </template>
  </bb-modal>
</template>

<script>
import getCurrency from '../../utils/getCurrency'
import { formatInterest, formatMoneyWithCurrency } from '@/plugins/numformat'
import { mapState } from 'pinia'
import { APPLICATION_CONFIRMATION } from '@/TrackingActions'
import { api } from '../../api'
import { DepositType } from '@deposits/const'
import { useRootStore } from '../../../../store/root'
import { useDepositStore } from '@deposits/store/depositStore'

export default {
  name: 'application-confirmation',
  props: {
    visible: Boolean,
    flowData: Object,
    flowConstants: Object,
    isLoading: Boolean
  },
  data () {
    return {
      automaticProlongation: false,
      interest: null
    }
  },
  methods: {
    goToNextStep () {
      const stepData = {}

      if (!this.isDemandDeposit) {
        stepData.automaticProlongation = this.automaticProlongation
      }

      this.$emit('submit', stepData)
    },
    getPaymentFrequencyText (value) {
      switch (value) {
        case 'MONTH':
          return this.translations.month
        case 'YEAR':
          return this.translations.year
        case 'END':
          return this.translations.end
        default:
          return ''
      }
    },
    onProlongToggleChange (value) {
      this.$tracker.action(APPLICATION_CONFIRMATION.ON_PROLONG_CHANGE, { setTo: value })
    }
  },
  computed: {
    ...mapState(useRootStore, ['locale', 'isCompany']),
    ...mapState(useDepositStore, ['featureFlags']),
    translations () {
      const translations = {
        automaticProlongationTitle: this.$gettext('Automatic prolongation'),
        automaticProlongationTooltip: this.$gettext('By selecting "yes" your contract will automatically renew. Selecting "no" the contract will end after the contract end date'),
        depositSumTitle: this.$gettext('Deposit sum'),
        depositPeriodTitle: this.$gettext('You will save for'),
        annualInterestRateTitle: this.$gettext('Annual interest rate'),
        interestPaymentAtTitle: this.$gettext('Interest payment at'),
        month: this.$gettext('Once a Month'),
        year: this.$gettext('Once a Year'),
        end: this.$gettext('End of Period'),
        onLabel: this.$pgettext('prolong_status', 'Active'),
        offLabel: this.$pgettext('prolong_status', 'Disabled'),
        initialDepositAmount: this.$pgettext('regular', 'Initial deposit amount')
      }

      if (this.isCompany) {
        Object.assign(translations, {
          initialDepositAmount: this.$pgettext('corporate', 'Initial deposit amount')
        })
      }

      return translations
    },
    currency () {
      return getCurrency(this.flowData.accountType.restrictedCurrencyCode)
    },
    expectedBalance () {
      let balance = this.flowData.amount
      if (this.flowData.expectedProfit) {
        balance += this.flowData.expectedProfit
      }

      return balance
    },
    formattedDepositSum () {
      return formatMoneyWithCurrency(this.flowData.amount, this.currency, this.locale)
    },
    depositPeriod () {
      return this.flowData.period
    },
    depositPeriodCode () {
      const code = (this.flowData.periodCode || '').toLowerCase()

      switch (code) {
        case 'days':
          return this.$pgettext('period_code_long', 'days')
        case 'months':
          return this.$pgettext('period_code_long', 'months')
        default:
          return code
      }
    },
    annualInterestRate () {
      if (this.isDemandDeposit) {
        return formatInterest(this.interest, this.locale) + '%'
      } else {
        if (this.flowData.bonusInterest > 0) {
          return (
            formatInterest(
              this.flowData.annualInterestRate - this.flowData.bonusInterest,
              this.locale
            ) +
            '% + ' +
            formatInterest(this.flowData.bonusInterest, this.locale) +
            '%'
          )
        } else {
          return formatInterest(this.flowData.annualInterestRate, this.locale) + '%'
        }
      }
    },
    paymentFrequencyText () {
      return this.getPaymentFrequencyText(this.flowData.paymentFrequencyCode)
    },
    formattedTransferAmount () {
      const transferAmount = Math.abs(this.flowData.paymentInfo.transferAmount)

      return formatMoneyWithCurrency(transferAmount, this.currency, this.locale)
    },
    transferDate () {
      return this.flowData.paymentInfo.dueDate
    },
    displayTransferDate () {
      return this.flowData.paymentInfo.displayDate
    },
    showTransferBox () {
      return !(parseInt(this.flowData.paymentInfo.transferAmount) === 0)
    },
    transferText () {
      return this.flowData.paymentInfo.transferAmount > 0
        ? this.$gettext('You will transfer us')
        : this.$gettext('We will transfer you')
    },
    specialInterestRequest () {
      return this.flowData.specialInterestRequest
    },
    isDemandDeposit () {
      return this.flowData.agreementTypeCode === this.flowConstants.AGREEMENT_TYPE_CODES[DepositType.Demand]
    },
    manualProlong () {
      return this.flowData.activeView === this.flowData.views.prolongationFlow
    },
    formattedExpectedBalance () {
      return formatMoneyWithCurrency(this.expectedBalance, this.currency, this.locale)
    },
    computedButtonSize () {
      return this.$screen.md
        ? 'md'
        : 'lg'
    }
  },
  async mounted () {
    if (this.isDemandDeposit) {
      const response = await api.getAccountInterestParameters(
        this.expectedBalance,
        null,
        null,
        this.flowData.paymentFrequencyCode,
        this.flowData.startDate,
        this.manualProlong,
        this.flowData.agreementTypeCode
      )

      this.interest = response.interest
    }
  }
}
</script>

<style lang="scss">
.prolongation {
  .bb-toggle__label {
    text-transform: none;
  }
}

.confirm-modal {
  .bb-modal {
    @media (min-width: $desktop-view-breaking-point) {
      margin-top: 70px;
    }
  }

  .bb-modal--card-full-mobile .bb-modal__body {
    display: flex;
    flex-direction: column;
  }
}
</style>
