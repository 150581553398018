import { mapState } from 'pinia'
import { api } from '@deposits/api'
import { formatMoneyWithCurrency } from '@/plugins/numformat'
import { DepositRouteName } from '@deposits/const'
import { withdrawalAmountValidator } from '@/plugins/validators'
import { currencyCodeToSymbol } from '@/plugins/currencyFormatters'
import { isNull } from 'lodash'
import { useRootStore } from '../../../store/root'
import { useDepositStore } from '@/modules/deposits/store/depositStore'

export default {
  data () {
    return {
      redirectWithoutDemandDeposit: true,
      showCloseContractModal: false,
      isLoading: true,
      signingLoading: false,
      showSignModal: false,
      chosenSignMethod: null,
      signingRequestId: null,
      validatedAccounts: null,
      internalAccountsList: null,
      withdrawalInfo: null,
      isValid: false,
      form: {
        account: null,
        amount: '',
        closeContract: undefined,
        confirmInternalAccount: undefined
      }
    }
  },
  computed: {
    ...mapState(useRootStore, ['locale']),
    ...mapState(useDepositStore, ['defaultDemandDeposit']),
    internalAccounts () {
      if (!this.internalAccountsList) {
        return []
      }

      return [{
        options: this.internalAccountsList
      }]
    },
    selectedAccount () {
      if (!this.validatedAccounts || !this.internalAccountsList || !this.form.account) {
        return null
      }

      return [
        ...this.validatedAccounts,
        ...this.internalAccountsList
      ].find(({ accountNumber }) => accountNumber === this.form.account)
    },
    beneficary () {
      return this.selectedAccount && this.selectedAccount.accountOwner
    },
    bic () {
      return this.selectedAccount && this.selectedAccount.bankCode
    },
    isOtherAccount () {
      return this.form.account === 'OTHER'
    },
    button () {
      return {
        color: this.isOtherAccount ? 'white' : (this.isValid && !this.signingLoading ? 'green' : 'gray'),
        loading: this.isLoading || this.signingLoading
      }
    },
    errorsTranslations () {
      return {
        min: this.$pgettext('withdraw_form_error', 'Withdrawal amount must be at least {amount}'),
        max: this.$pgettext('withdraw_form_error', 'Withdrawal amount can not be larger than {amount}')
      }
    },
    currencySymbol () {
      return currencyCodeToSymbol(this.defaultDemandDeposit.currencyCode)
    },
    availableBalance () {
      return this.formatMoney(this.withdrawalInfo ? this.withdrawalInfo.availableForWithdrawal : 0)
    },
    amountValidationRules () {
      return {
        required: true,
        withdrawal_amount: {
          min: this.withdrawalInfo && this.withdrawalInfo.withdrawalMinAmount,
          max: this.withdrawalInfo && this.withdrawalInfo.availableForWithdrawal
        }
      }
    },
    hasSeenCloseContractModal () {
      return [true, false].includes(this.form.closeContract)
    },
    modalProps () {
      return {
        flavor: 'card',
        color: 'white',
        publicSize: 'm',
        fullMobile: true
      }
    }
  },
  methods: {
    onBankAccountInput (value) {
      this.form.account = value
    },
    onProgress (value) {
      this.isValid = value === 1
    },
    formatMoney (amount) {
      return formatMoneyWithCurrency(amount, this.defaultDemandDeposit.currencyCode, this.locale)
    },
    afterComplete () {
      this.setSigningRequestId(null)
      window.eventBus.$emit('demand-deposit__withdraw')
    },
    onCloseConfirmCloseContract () {
      this.setShowCloseContractModal(false)
      this.form.closeContract = null
    },
    onCloseSignModal () {
      this.toggleSigningModalVisibility(false)
      this.form.confirmInternalAccount = false
      this.form.closeContract = null
    },
    closeContract (value) {
      this.form.closeContract = value
      this.setShowCloseContractModal(false)
      this.submit()
    },
    setSigningMethod (method) { this.chosenSignMethod = method },
    setSigningRequestId (id) { this.signingRequestId = id },
    toggleSigningModalVisibility (flag) { this.showSignModal = flag },
    setSigningLoading (flag) { this.signingLoading = flag },
    setShowCloseContractModal (flag) { this.showCloseContractModal = flag }
  },
  async mounted () {
    if (!this.defaultDemandDeposit && this.redirectWithoutDemandDeposit) {
      return this.$router.push({ name: DepositRouteName.DemandDeposits })
    } else if (!this.defaultDemandDeposit || this.defaultDemandDeposit.state !== 'ACTIVE') {
      this.isLoading = false
      return
    }

    this.isLoading = true

    const [validatedAccounts, withdrawalInfo] = await Promise.all([
      api.getValidatedAccounts(),
      api.getWithdrawalInfo(this.defaultDemandDeposit.id)
    ])

    this.validatedAccounts = validatedAccounts
    this.withdrawalInfo = withdrawalInfo

    if (isNull(withdrawalInfo)) {
      this.$apm.captureError(new Error('withdrawalInfo is null'))
    }

    if (withdrawalInfo.internalAccounts) {
      this.internalAccountsList = withdrawalInfo.internalAccounts
    }

    this.isLoading = false
  },
  created () {
    this.$validator.extend('withdrawal_amount', withdrawalAmountValidator({
      minError: this.errorsTranslations.min,
      maxError: this.errorsTranslations.max,
      formatter: (value) => this.formatMoney(value)
    }))
  }
}
