<template>
  <component v-if="cardTypeName" :is="cardTypeName" v-bind="cardData" />
</template>

<script>
import ApplicationCard from './cards/ApplicationCard'
import ContractCard from './cards/ContractCard'
import UpsellCard from './cards/UpsellCard'
import PromiseToPayCard from './cards/PromiseToPayCard'
import OfferCard from '@/components/cards/OfferCard'
import CampaignCard from '@/components/cards/CampaignCard'
import CARD_TYPE from '@/components/cards/cardTypes'

const cardTypeMapping = {
  [CARD_TYPE.APPLICATION]: 'application-card',
  [CARD_TYPE.CONTRACT]: 'contract-card',
  [CARD_TYPE.UPSELL]: 'upsell-card',
  [CARD_TYPE.OFFER]: 'offer-card',
  [CARD_TYPE.PROMISE_TO_PAY]: 'promise-to-pay-card',
  [CARD_TYPE.CAMPAIGN]: 'campaign-card'
}

export default {
  name: 'multi-card',
  components: {
    ApplicationCard,
    ContractCard,
    OfferCard,
    UpsellCard,
    PromiseToPayCard,
    CampaignCard
  },
  props: {
    currency: {
      type: String,
      required: false
    },
    card: {
      type: Object,
      required: true
    },
    cardType: {
      type: String,
      required: true
    },
    index: {
      required: true
    },
    count: {
      required: true
    }
  },
  computed: {
    cardTypeName () {
      return cardTypeMapping[this.cardType]
    },
    cardData () {
      const props = {
        currency: this.currency,
        index: this.index,
        count: this.count,
        spacerClass: 'm-b-15'
      }

      switch (this.cardType) {
        case CARD_TYPE.APPLICATION:
          props.application = this.card
          break
        case CARD_TYPE.CONTRACT:
          props.contract = this.card
          break
        case CARD_TYPE.OFFER:
          props.card = this.card
          break
        case CARD_TYPE.UPSELL:
          props.offer = this.card
          break
        case CARD_TYPE.CAMPAIGN:
          props.campaigns = this.card.campaigns
          break
      }

      return props
    }
  }
}
</script>
