<template>
  <div class="layout-with-progress">
    <progress-header v-bind="progress" @close="$emit('close')" />
    <div class="layout-with-progress__content">
      <full-spinner v-if="loading"></full-spinner>
      <slot v-else></slot>
    </div>
  </div>
</template>
<script>
import FullSpinner from './FullSpinner'
import ProgressHeader from './../components/ProgressHeader'

export default {
  name: 'layout-with-progress-v2',
  inject: ['$validator'],
  components: { FullSpinner, ProgressHeader },
  props: {
    loading: {
      type: Boolean
    },
    progress: {
      require: true
    }
  }
}
</script>

<style lang="scss">
.layout-with-progress {
  min-height: 100vh;
  background: $white;

  &__content {
    display: flex;
    justify-content: center;
    overflow-y: scroll;
    padding-top: 50px;

    @media (min-width: $desktop-view-breaking-point-wide) {
      padding: 0 30px;
      padding-top: 63px;
    }
  }
}
</style>
