<script>
import ContractCard from './ContractCard'
import { formatMoneyWithCurrency } from '@/plugins/numformat'
import { formatDate } from '@/plugins/dateFormatters'
import statusTypes from './statusTypes'
import { mapState } from 'pinia'
import { useRootStore } from '../../../../store/root'

export default {
  name: 'revolving-loan-card',
  extends: ContractCard,
  computed: {
    ...mapState(useRootStore, ['locale']),
    totalInterestAmount () {
      return this.contract.interestAmount + this.contract.interestDebtAmount
    },
    interestDueDate () {
      if (this.statusType === statusTypes.CLOSED) {
        return '—'
      }

      return formatDate(this.contract.nextPaymentDate)
    },
    cardSections () {
      return [
        {
          label: this.translations.interestAmount,
          content: formatMoneyWithCurrency(this.totalInterestAmount, this.currency, this.locale),
          statuses: [statusTypes.OK, statusTypes.CLOSED]
        },
        {
          label: this.translations.fullDebtSum,
          content: formatMoneyWithCurrency(this.contract.totalDebtAmount, this.currency, this.locale),
          statuses: [statusTypes.LATE]
        },
        {
          label: this.translations.dueDate,
          content: this.contract.debtDueDate ? formatDate(this.contract.debtDueDate) : '',
          statuses: [statusTypes.LATE]
        },
        {
          label: this.translations.interestDueDate,
          content: this.interestDueDate,
          statuses: [statusTypes.OK, statusTypes.CLOSED]
        },
        {
          label: this.localTranslations.outstandingBalance,
          content: formatMoneyWithCurrency(this.contract.remainingPrincipal, this.currency, this.locale)
        }
      ]
        .filter(item => !item.statuses || item.statuses.includes(this.statusType))
        .map(({ label, content }) => ({ label, content }))
    },
    cardProps () {
      return {
        type: 'loan',
        title: this.$gettext('Revolving loan'),
        secondaryTitle: this.translations.status[this.statusType],
        status: this.statusType,
        sections: this.cardSections,
        primaryButton: this.getPrimaryButton(),
        secondaryButton: this.getSecondaryButton()
      }
    },
    localTranslations () {
      return {
        outstandingBalance: this.$pgettext('revolving_loan', 'OUTSTANDING BALANCE')
      }
    }
  }
}
</script>
