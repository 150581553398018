const MAX_RETRY_ATTEMPTS = 3
const BASE_RETRY_DELAY_MS = 1000

export const retryStrategy = (axios, opts = {}) => {
  opts = Object.assign({
    maxRetryAttempts: MAX_RETRY_ATTEMPTS,
    baseRetryDelayMs: BASE_RETRY_DELAY_MS
  }, opts)

  axios.interceptors.response.use(
    response => response,
    error => {
      const message = error.message

      if (!(message.includes('timeout') || message.includes('Network Error') || message.includes('Request aborted'))) {
        return Promise.reject(error)
      }

      const config = error.config
      config.retryCount = config.retryCount || 0

      if (config.retryCount < opts.maxRetryAttempts) {
        const delay = Math.pow(2, config.retryCount) * opts.baseRetryDelayMs
        config.retryCount += 1
        return new Promise(resolve => setTimeout(() => resolve(axios.request(config)), delay))
      }

      return Promise.reject(error)
    }
  )
}
