<template>
  <bb-modal visible flavor="card" publicSize="m" fullMobile class="password-change-modal" @close="handleClose">
    <div class="password-change-modal__info m-b-30">
      <h3 class="password-change-modal__title m-b-30">
        {{translations.title[type]}}
      </h3>
    </div>
    <div class="password-wrapper">
      <error-message v-if="showError" :code="errorCode" class="color-red f-smallest m-b-5" />
      <password-input
        v-if="type === passwordChangeTypes.CHANGE"
        name="currentPassword"
        v-model="currentPassword"
        :hideBorder="false"
        :label="translations.currentPassword"
      />
      <password-input
        name="newPassword"
        class="p-b-30"
        v-model="password"
        :label="translations.newPassword"
      />
      <password-strength
        v-model="passwordStrength"
        :password="password"
      />
    </div>
    <bb-ticket-stub slot="footer" class="m-t-15">
      <bb-button :loading="isLoading" display="ticket" :color="isValid && !isLoading ? 'green' : 'gray'" @click="confirm">
        <span class="uppercase">
          <translate translate-context="password_change_modal">Confirm</translate>
        </span>
      </bb-button>
    </bb-ticket-stub>
  </bb-modal>
</template>

<script>
import PasswordStrength from './password/PasswordStrength'
import PasswordInput from './password/PasswordInput'
import ErrorMessage from './ErrorMessage'
import { mapActions } from 'pinia'
import { useAccountStore } from '@account/store/accountStore'
import PASSWORD_ACTION_TYPES from './passwordChangeType'

export default {
  name: 'password-change-modal',
  components: { PasswordStrength, PasswordInput, ErrorMessage },
  props: {
    type: {
      required: true
    }
  },
  data () {
    return {
      passwordChangeTypes: PASSWORD_ACTION_TYPES,
      isLoading: false,
      password: '',
      currentPassword: '',
      passwordStrength: 0,
      errorCode: null,
      translations: {
        currentPassword: this.$pgettext('password', 'Current password'),
        newPassword: this.$pgettext('password', 'New password'),
        errors: {
          wrongPassword: this.$pgettext('customer_id_error', 'Current password is not correct'),
          passwordReuse: this.$pgettext('customer_id_error', 'You have previously used this password, please use another password')
        },
        title: {
          [PASSWORD_ACTION_TYPES.SET]: this.$pgettext('password_change_modal', 'Set your password'),
          [PASSWORD_ACTION_TYPES.CHANGE]: this.$pgettext('password_change_modal', 'Change your password')
        }
      }
    }
  },
  computed: {
    isValid () {
      return this.passwordStrength >= 1 && (this.type === PASSWORD_ACTION_TYPES.SET || this.currentPassword)
    },
    showError () {
      return this.errorCode && !['INVALID_CREDENTIALS', 'PASSWORD_REUSE'].includes(this.errorCode)
    }
  },
  watch: {
    errorCode (value) {
      if (value === 'INVALID_CREDENTIALS') {
        this.currentPassword = ''
        this.errors.add({
          field: 'currentPassword',
          msg: this.translations.errors.wrongPassword
        })
      } else if (value === 'PASSWORD_REUSE') {
        this.errors.add({
          field: 'newPassword',
          msg: this.translations.errors.passwordReuse
        })
      }
    }
  },
  methods: {
    ...mapActions(useAccountStore, ['initPasswordChange', 'initPasswordSet']),
    handleClose () {
      this.$emit('close')
    },
    async confirm () {
      if (!this.isValid || this.isLoading) {
        return false
      }

      this.isLoading = true
      this.errorCode = null
      this.errors.clear()

      try {
        let apiCall
        if (this.type === PASSWORD_ACTION_TYPES.CHANGE) {
          apiCall = this.initPasswordChange({
            password: this.currentPassword,
            newPassword: this.password
          })
        } else {
          apiCall = this.initPasswordSet({
            newPassword: this.password
          })
        }
        this.$emit('initSuccessful', await apiCall)
      } catch (err) {
        this.errorCode = err.errorCode || err.err_code
      }

      this.isLoading = false
    }
  }
}
</script>

<style scoped lang="scss">
.password-change-modal {
  &__info {
    text-align: center;
    color: $gray-70;
    font-family: $gotham-medium;
    font-size: $font-size-small;
  }

  &__title {
    font-family: $max-pro-demi-bold;
    font-size: $h4-size;
    color: $navy;
  }

  &__error {
    margin-top: 25px;
    text-align: center;
    font-family: $gotham-medium;
    font-size: $font-size-smallest;
    color: $red;
  }

  .password-wrapper {
    @media (min-width: $desktop-view-breaking-point) {
      margin-bottom: 80px;
    }
  }
}
</style>
