import { mapState, mapActions } from 'pinia'
import { RouteNames } from '../modules/loan/views/const'
import { isObject } from 'lodash'
import { useRootStore } from '../store/root'
import { useLoanStore } from '@loan/store/loanStore'

export default {
  computed: {
    ...mapState(useRootStore, ['contractsCount']),
    ...mapState(useLoanStore, ['featureFlags']),
    localTranslations () {
      return {
        soonExpiringPolicy: this.$pgettext('loan_insurance_policy_notification', 'Your insurance policy expires in less than 14 days, please, renew your insurance at least 5 days before it\'s end date'),
        expiredOrNotFoundPolicy: this.$pgettext('loan_insurance_policy_notification', 'Your insurance policy expired or not formed, please, renew your old, or form new insurance policy.'),
        contractDetailsLink: this.$pgettext('loan_insurance_policy_notification', 'Contract details')
      }
    }
  },
  methods: {
    ...mapActions(useRootStore, ['getContractsCount']),
    createNotifications () {
      if (!isObject(this.contractsCount?.insurancePolicyInfo)) {
        return
      }
      for (const entry of this.contractsCount.insurancePolicyInfo) {
        if (entry.isInsuranceRequired && this.featureFlags?.showInsuranceExpirationNotification) {
          if (entry.soonExpiringInsurances?.length > 0) {
            entry.soonExpiringInsurances.forEach(policy => {
              this.createNotification(entry, this.localTranslations.soonExpiringPolicy, policy.policyNumber)
            })
          } else if (!entry.hasActiveInsurance) {
            this.createNotification(entry, this.localTranslations.expiredOrNotFoundPolicy, entry.contractId)
          }
        }
      }
    },
    createNotification (contractInfo, translation, key) {
      this.$notify({
        text: this.$gettextInterpolate(translation, { contractNumber: contractInfo.contractNumber }),
        type: 'warning',
        link: {
          name: this.localTranslations.contractDetailsLink,
          route: { name: RouteNames.LOAN_CONTRACT_DETAILS, params: { contractId: contractInfo.contractId } },
          visible: true
        },
        update: true,
        key: 'insurance_policy_' + key
      })
    }
  },
  async mounted () {
    await this.getContractsCount()
    this.createNotifications()
  }
}
