import dayjs from 'dayjs'
import { useRootStore } from '../store/root'

function isValidDate (date) {
  return (date && dayjs(date).isValid())
}

function formatDateWithOptions (date, options) {
  const rootStore = useRootStore()
  const locale = rootStore.locale

  // Fix YYYY.MM.DD formatting
  if (date && date.match(/^[\d]{4}\.[\d]{1,2}\.[\d]{1,2}$/)) {
    date = date.replace(/\./g, '-')
  }

  return isValidDate(date) && locale
    ? new Date(date).toLocaleDateString(locale, options)
    : '-'
}

function formatDate (date) {
  return formatDateWithOptions(date, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    timeZone: 'UTC'
  })
}

function formatToCreditCardExpiryDate (date) {
  return isValidDate(date) ? dayjs(date).format('MM/YYYY') : '-'
}

function formatToTwoDigitsOrEmpty (date) {
  return isValidDate(date) ? dayjs(date).format('DD.MM.YY') : ''
}

function getDateFormat () {
  return 'DD.MM.YYYY'
}

function formatDateForRequest (date) {
  return dayjs(date).format('YYYY-MM-DD')
}

function formatMonthYear (date) {
  return formatDateWithOptions(date, { year: 'numeric', month: '2-digit' })
}

export {
  isValidDate,
  formatDate,
  getDateFormat,
  formatDateForRequest,
  formatMonthYear,
  formatToCreditCardExpiryDate,
  formatToTwoDigitsOrEmpty
}
