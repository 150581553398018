<template>
  <div v-if="visible" class="banner">
    Logged in as Customer Support.<br /> Viewing as: <strong>{{username}}</strong>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { useRootStore } from '../store/root'

export default {
  computed: {
    ...mapState(useRootStore, ['accessLevel', 'username']),
    visible () {
      return this.accessLevel === 'CUSTOMER_SUPPORT'
    }
  }
}
</script>

<style lang="scss" scoped>
.banner {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 50%;
  background: $red-70;
  border: 1px solid $red;
  color: $black;
  text-align: center;
  padding: 5px;
  font-size: $font-size-smallest;
  z-index: 10000;
}
</style>
