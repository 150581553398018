<template>
  <fancy-hero-header @scroll="(value) => scrolledPercentage = value">
    <div class="hero-header">
      <div class="align-center p-l-25 p-r-25">
        <no-contracts v-if="state === 'noActiveContracts'" v-bind="noContractsData" class="p-b-20 hero-header__no-contracts-container" />
        <div
          v-else
          class="deposit-header color-white"
        >
          <p class="deposit-header__title">{{ totalSavingsText }}</p>
          <p
            class="deposit-header__sum"
            :style="sumStyleOverrides"
            data-testid="deposit-header-amount">
            {{formattedTotalSavings}}
          </p>
          <div
            class='deposit-header__gains-wrapper'
            :style="accruedInterestStyleOverrides"
          >
            <bb-icon
              name="ui-arrow-down"
              size="18"
              fill="white"
              rotate="down"
            ></bb-icon>
            <p class="deposit-header__gains" v-if="!isAccruedInterestLoading">{{formattedAccruedInterest}}</p>
            <bb-spinner v-else small color="white" class="spinner" />
          </div>
        </div>
      </div>
    </div>
    <sticky-navbar :visible="scrolledPercentage > 55">
      <template v-if="state !== 'noActiveContracts'">
        <p class="uppercase">{{ totalSavingsText }}</p>
        <p class="savings-value">{{formattedTotalSavings}}</p>
      </template>
    </sticky-navbar>
  </fancy-hero-header>
</template>

<script>
import '@bigbank/interface-components/dist/svg/ui/plus-circle'
import '@bigbank/interface-components/dist/svg/illustration/opening-header'
import '@bigbank/interface-components/dist/svg/ui/menu-down'
import '@bigbank/interface-components/dist/svg/ui/plus'
import '@bigbank/interface-components/dist/svg/ui/arrow-down'
import StickyNavbar from '@/components/StickyNavbar.vue'
import FancyHeroHeader from '@/components/FancyHeroHeader.vue'
import NoContracts from '@/components/hero-header/NoContracts.vue'
import { formatMoneyWithCurrency } from '@/plugins/numformat'
import { DepositType } from '@deposits/const'
import { useRootStore } from '../../../store/root'
import { mapState, mapActions } from 'pinia'
import { useDepositStore } from '@deposits/store/depositStore'

export default {
  name: 'bb-overview-state',
  components: { StickyNavbar, FancyHeroHeader, NoContracts },
  data () {
    return {
      scrolledPercentage: 0
    }
  },
  methods: {
    ...mapActions(useDepositStore, ['fetchAccruedInterest'])
  },
  computed: {
    ...mapState(useRootStore, ['isMobile', 'locale']),
    ...mapState(useDepositStore, ['accruedInterest']),
    accruedInterestStyleOverrides () {
      return this.isMobile ? `opacity: ${1 - this.scrolledPercentage / 100}` : ''
    },
    sumStyleOverrides () {
      return this.isMobile ? `font-size: ${16 + 16 * (1 - this.scrolledPercentage / 100)}px;` : ''
    },
    isAccruedInterestLoading () {
      return this.accruedInterest[this.product] === undefined
    },
    formattedAccruedInterest () {
      return formatMoneyWithCurrency(this.accruedInterest[this.product], this.currency, this.locale)
    },
    formattedTotalSavings () {
      return formatMoneyWithCurrency(this.totalSavings, this.currency, this.locale)
    },
    translations () {
      return {
        description: {
          TERM: this.$gettext('A single destination for your loan application statuses and latest transactions.'),
          DEMAND: this.$pgettext('hero_header_demand_deposit', 'A single destination for your loan application statuses and latest transactions.')
        },
        totalSavings: {
          TERM: this.$pgettext('hero_header_term_deposit_total_savings', 'Total TD savings'),
          DEMAND: this.$pgettext('hero_header_demand_deposit_total_savings', 'Total SD savings')
        }
      }
    },
    noContractsData () {
      return {
        username: this.username,
        translations: {
          description: this.translations.description[this.product]
        }
      }
    },
    totalSavingsText () {
      return this.translations.totalSavings[this.product]
    }
  },
  props: {
    state: {
      type: String,
      default: 'noActiveContracts'
    },
    totalSavings: {
      type: Number
    },
    username: {
      type: String
    },
    currency: {
      type: String
    },
    product: {
      type: String,
      default: DepositType.Term
    }
  },
  created () {
    this.fetchAccruedInterest({ productType: this.product })
  }
}
</script>

<style lang="scss">
.savings-value {
  font-size: $default-font-size;
  font-family: $gotham-bold;
}
</style>

<style scoped lang='scss'>
.hero-header {
  display: flex;
  justify-content: center;
  width: 90%;
  max-width: 850px;
  margin: auto;
  position: relative;

  &__no-contracts-container {
    padding-top: 20px;

    @media (min-width: $desktop-view-breaking-point) {
      padding-top: 60px;
    }
  }
}

.deposit-header {
  font-family: $gotham-bold;
  padding-top: 49px;

  @media (min-width: $desktop-view-breaking-point) {
    padding-top: 60px;
  }

  &__title {
    font-size: $mobile-tiny-font-size;
    text-transform: uppercase;

    @media (min-width: $desktop-view-breaking-point-wide) {
      font-size: $font-size-smallest;
    }
  }

  &__sum {
    font-size: $h2-size;
  }

  &__gains {
    font-size: $mobile-h4-size;
    margin-left: 4px;

    @media (min-width: $desktop-view-breaking-point-wide) {
      font-size: $h3-size;
      margin-left: 12px;
    }

    &-wrapper {
      margin-top: 17px;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (min-width: $desktop-view-breaking-point-wide) {
        font-size: $h3-size;
        margin-top: 30px;
      }
    }
  }
}
</style>
