<template>
  <with-horizontal-menu :loading="loading" :onBackButtonClick="onBackButtonClick" :tabRoutes="tabRoutes" :title="translations.pageTitle">
    <div class="details__wrapper">
      <div class="details__content" v-if="card">
        <h3 class="details__title m-t-20">
          {{translations.title}}: <span name="card-masked-pan">{{card.maskedPan}}</span>
        </h3>
        <bb-list-group class="details__list m-t-30 m-b-30">
          <bb-list-item v-for="(detail, i) in details" :key="i" :title="detail.title" condensed :title-uppercase="false" :name="detail.name">
            {{detail.value}}
          </bb-list-item>
        </bb-list-group>
        <div class="details__buttons">
          <bb-button
            v-for="(button, i) in buttons"
            :key="i"
            :name="button.name"
            color="blue"
            inverted
            @click="button.onClick"
          >{{button.label}}</bb-button>
        </div>
        <div class="details__documents" v-if="documents && documents.length > 0">
          <h3 class="details__title m-t-30">
            {{translations.documentsTitle}}
          </h3>
          <document-list :files="documents" :downloadHandler="download" />
        </div>
      </div>
    </div>
    <component
      v-if="modalComponent"
      :is="modalComponent"
      v-bind="modalComponentProps"
      @onModalClosed="onModalClosed"
      @onReplaceClick="showReplaceSigningModal"
    />
  </with-horizontal-menu>
</template>

<script>
import '@bigbank/interface-components/dist/svg/ui/arrow-left'
import { CreditCardRouteName } from '@credit-card/views/const'
import WithHorizontalMenu from '@/layouts/WithHorizontalMenu.vue'
import DocumentList from '@/components/DocumentList'
import api from '@loan/api'
import CreditCardBlockModal from '@credit-card/components/modals/CreditCardBlockModal'
import CardTypeTranslationMixin from '@credit-card/components/mixins/CardTypeTranslationMixin'
import { formatDate } from '@/plugins/dateFormatters'
import { CardStatus, CardType, CardReplacementStatus } from '@bigbank/dc-common/clients/http/credit-card/credit-card.enums'
import CreditCardReplaceModal from '@credit-card/components/modals/CreditCardReplaceModal'
import CreditCardAlreadyOrderedModal from '@credit-card/components/modals/CreditCardAlreadyOrderedModal'
import { CreditCardSigningAction } from '@bigbank/dc-common/enums/credit-card.enums'
import CreditCardSigningMixin from '@credit-card/components/mixins/CreditCardSigningMixin'
import { toNumber } from 'lodash'
import CreditCardReplaceSigningModal from '@credit-card/components/modals/CreditCardReplaceSigningModal.vue'
import { mapState, mapActions } from 'pinia'
import { useLoanStore } from '@loan/store/loanStore'
import { isNumeric } from '@bigbank/dc-common/util'

export default {
  name: 'credit-card-details-view',
  mixins: [CardTypeTranslationMixin, CreditCardSigningMixin],
  components: {
    WithHorizontalMenu,
    DocumentList,
    CreditCardBlockModal,
    CreditCardAlreadyOrderedModal,
    CreditCardReplaceModal,
    CreditCardReplaceSigningModal
  },
  data () {
    return {
      loading: true,
      cardId: null,
      card: null,
      cardInfo: null,
      rawDocuments: [],
      isModalVisible: false,
      modalComponent: null
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler (to) {
        this.cardId = to.params.cardId
      }
    },
    cardId: {
      immediate: true,
      handler (newId, oldId) {
        if (!newId || newId === oldId) {
          return
        }

        if (!this.hasReturnedAfterSigning()) {
          this.fetchData()
        }
      }
    }
  },
  computed: {
    ...mapState(useLoanStore, ['featureFlags', 'contractAdditionalDetails', 'creditCardContract']),
    translations () {
      return {
        pageTitle: this.$pgettext('card_details_view_title', 'Manage card'),
        title: this.$pgettext('card_details_view_title', 'Card'),
        documentsTitle: this.$pgettext('card_details_view_title', 'Card documents'),
        blockClose: this.$pgettext('card_details_view', 'Block/Close the card'),
        changeLimits: this.$pgettext('card_details_view', 'View/change limits'),
        replaceCard: this.$pgettext('card_details_view', 'Replace the card'),
        signedOn: this.$pgettext('card_details_view', 'Signed on'),
        status: this.$pgettext('credit_card_card_view', 'Status'),
        cardType: this.$pgettext('credit_card_card_view', 'Card type'),
        validFrom: this.$pgettext('credit_card_card_view', 'Activation date'),
        validToDate: this.$pgettext('credit_card_card_view', 'Expiry date'),
        cardStatus: {
          ACTIVE: this.$pgettext('CREDIT_CARD_STATUS', 'Active'),
          BLOCKED: this.$pgettext('CREDIT_CARD_STATUS', 'Blocked'),
          CLOSED: this.$pgettext('CREDIT_CARD_STATUS', 'Closed'),
          CREATED: this.$pgettext('CREDIT_CARD_STATUS', 'Not active'),
          NEW: this.$pgettext('CREDIT_CARD_STATUS', 'New')
        }
      }
    },
    tabRoutes () {
      return null
    },
    details () {
      return [{
        title: this.translations.cardType,
        value: this.cardTypeLabel(this.card),
        visible: true,
        name: 'card-type'
      }, {
        title: this.translations.validFrom,
        value: formatDate(this.card?.validFromDate),
        visible: true,
        name: 'valid-from-date'
      }, {
        title: this.translations.validToDate,
        value: formatDate(this.card?.validToDate),
        visible: true,
        name: 'card-valid-to-date'
      }, {
        title: this.translations.status,
        value: this.translations.cardStatus[this.card?.status] || this.card?.status,
        visible: true,
        name: 'card-status'
      }].filter(({ visible }) => visible)
    },
    buttons () {
      const hasActiveBailiffProceeding = this.contractAdditionalDetails?.hasActiveBailiffProceeding

      return [{
        label: this.translations.blockClose,
        visible: [CardStatus.ACTIVE].includes(this.card?.status) && !hasActiveBailiffProceeding,
        onClick: () => {
          this.showBlockModal()
        },
        name: 'block-card-button'
      }, {
        label: this.translations.changeLimits,
        visible: false,
        name: 'change-limits-button'
      }, {
        label: this.translations.replaceCard,
        visible: this.isReplaceModalAllowed,
        onClick: () => {
          if (this.isReplacementCardOrdered) {
            this.showReplacementCardAlreadyOrdered()
          } else if (this.cardInfo.canOrderNewCard === false) {
            return this.$router.push({
              name: 'credit-card-loan_pay-back',
              query: {
                action: 'replace-card'
              }
            })
          } else {
            this.showReplaceModal()
          }
        },
        name: 'replace-card-button'
      }].filter(({ visible }) => visible)
    },
    documents () {
      return this.rawDocuments.map(document => {
        return {
          ...document,
          dateTitle: this.translations.signedOn + ': ' + formatDate(this.card?.validFromDate),
          title: document.fileName
        }
      })
    },
    modalComponentProps () {
      if (this.modalComponent === CreditCardReplaceSigningModal) {
        return {
          cardId: toNumber(this.cardId),
          visible: this.isModalVisible,
          // Only contract id is needed to start signing
          contract: { id: this.card?.contractId }
        }
      }

      if (!isNumeric(this.card?.id)) {
        this.$apm.captureError(`this.card?.id: ${this.card?.id} isn't numeric, overriding with this.cardId: ${this.cardId}`)
      }

      return {
        cardId: this.card?.id ?? this.cardId,
        cardType: this.card.type,
        visible: this.isModalVisible,
        card: this.card
      }
    },
    isReplaceModalAllowed () {
      return [CardType.PHYSICAL_AND_VIRTUAL].includes(this.card?.type) &&
        [CardStatus.ACTIVE].includes(this.card?.status) &&
        this.isReplaceCreditCardFeatureFlagEnabled
    },
    isReplaceCreditCardFeatureFlagEnabled () {
      return this.featureFlags.enableReplaceCreditCard
    },
    isReplacementCardOrdered () {
      return this.card.replacementStatus === CardReplacementStatus.IN_PROGRESS
    }
  },
  methods: {
    ...mapActions(useLoanStore, ['getContract', 'getContractAdditionalDetails']),
    setModalComponent (component) { this.modalComponent = component },
    toggleModalVisibility (flag) { this.isModalVisible = flag },
    setLoading (flag) { this.loading = flag },
    showBlockModal () {
      this.setModalComponent(CreditCardBlockModal)
      this.toggleModalVisibility(true)
    },
    showReplaceModal () {
      this.setModalComponent(CreditCardReplaceModal)
      this.toggleModalVisibility(true)
    },
    showReplacementCardAlreadyOrdered () {
      this.setModalComponent(CreditCardAlreadyOrderedModal)
      this.toggleModalVisibility(true)
    },
    showReplaceSigningModal () {
      this.setModalComponent(CreditCardReplaceSigningModal)
      this.toggleModalVisibility(true)
    },
    onModalClosed (params) {
      this.toggleModalVisibility(false)
      this.setModalComponent(null)
      if (params?.refresh) {
        this.fetchData()
      }
    },
    onBackButtonClick (previousRoute) {
      return previousRoute ?? {
        name: CreditCardRouteName.CreditCard
      }
    },
    async download (file) {
      await api.initCreditCardContractDownload(this.cardId, {
        fileName: file.fileName,
        contentType: file.contentType
      })
    },
    async fetchData () {
      this.setLoading(true)
      try {
        const [card, cardInfo] = await Promise.all([
          api.getCreditCardByCardId(this.cardId),
          api.getCreditCardInfoByCardId(this.cardId)
        ])

        this.card = card
        this.cardInfo = cardInfo
        await Promise.all([
          this.getContract(this.card.contractId),
          this.getContractAdditionalDetails(this.card.contractId)
        ])
        if (this.card.cardholderAgreementFileId) {
          this.rawDocuments = await api.getCreditCardDocuments(this.cardId)
        }
      } finally {
        this.setLoading(false)
      }
    }
  },
  async mounted () {
    if (this.hasReturnedAfterSigningAction(CreditCardSigningAction.Replace)) {
      this.setLoading(true)
      this.card = await api.getCreditCardByCardId(this.cardId)
      this.showReplaceSigningModal()
      this.setLoading(false)
    }
  }
}
</script>

<style lang="scss" scoped>
.details {
  &__content {
    padding: 5px 15px 126px;
    display: flex;
    flex-direction: column;

    @media (min-width: $desktop-view-breaking-point) {
      display: block;
      max-width: 850px;
      margin: auto;
      width: 100%;
      padding: 40px 15px 96px;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    gap: 12px 20px;

    @media (min-width: $desktop-view-breaking-point) {
      flex-direction: row;
    }
  }

  &__title {
    color: $navy;
    font-family: $max-pro-demi-bold;
  }
}
</style>
