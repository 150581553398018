export default {
  OK: 'ok',
  LATE: 'late',
  CLOSED: 'closed',
  PAID_OUT: 'paidOut',
  INACTIVE: 'inactive',
  TERMINATED: 'terminated',
  TERMINATED_CLOSED: 'terminatedClosed',
  TERMINATED_SOLD: 'terminatedSold'
}
