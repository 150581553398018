<template>
  <layout-with-menu :loading="true" :hideMenu="true" />
</template>

<script>
import { logout } from '@/logout'

export default {
  name: 'logout-view',
  mounted () {
    logout(this.$route.query)
  }
}
</script>
