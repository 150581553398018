<template>
  <bb-overlay
    :visible="overlayVisible"
    centerVertically
    color="dark"
    @close="overlayVisible = false"
  >
    <bb-spinner/>
  </bb-overlay>
</template>

<script>
import api from '@loan/api'

export default {
  name: 'sign-with-signicat',
  data () {
    return {
      overlayVisible: true
    }
  },
  props: {
    contract: {
      required: true
    },
    signingRequestId: {
      required: false
    }
  },
  methods: {
    async continueSigning () {
      const { url } = await api.continueSignicatSigning(this.contract.id, this.signingRequestId)

      this.redirectToSignicat(url)
    },
    async initSigning () {
      const { url } = await api.startSignicatSigning(this.contract.id, this.signingRequestId)

      this.redirectToSignicat(url)
    },
    redirectToSignicat (url) {
      this.overlayVisible = false

      if (url) document.location = url
    }
  },
  mounted () {
    switch (this.contract.contractChangeSigning.status) {
      case 'PENDING':
        this.continueSigning()
        break
      default:
        this.initSigning()
    }
  }
}
</script>
