<template>
  <div class="settings-button" v-if="visible">
    <bb-button class="d-flex" display="link" color="gray" :disabled="disabled" @click="onClick" :name="name">
      <bb-icon-frame
        :icon="icon"
        :iconColor="color"
        class="m-r-10"
      />
      {{text}}
    </bb-button>
  </div>
</template>

<script>

export default {
  props: {
    icon: { required: true },
    text: { type: String, required: true },
    disabled: { type: Boolean, default: false },
    visible: { type: Boolean, default: true },
    onClick: { type: Function, required: true },
    name: { type: String, required: false }
  },
  computed: {
    color () {
      return this.disabled ? 'gray-30' : 'gray'
    }
  }
}
</script>

<style lang="scss" scoped>
button.settings-button.bb-button {
  font-size: $font-size-small;
  text-align: left;
  color: $gray;
}
</style>
