<template>
  <bb-modal
    class="increase-limit"
    visible
    flavor="card"
    color="white"
    publicSize="m"
    fullMobile
    @close="onClose()"
  >
    <div class="increase-limit__loader">
      <bb-spinner />
    </div>
  </bb-modal>
</template>

<script>
import { REVOLVING_DASHBOARD } from '@/TrackingActions'
import { mapState } from 'pinia'
import { useLoanStore } from '@loan/store/loanStore'

export default {
  name: 'revolving-loan_increase-limit',
  components: {},
  data () {
    return {
    }
  },
  computed: {
    ...mapState(useLoanStore, ['upsell', 'revolvingLoan']),
    isEnabled () {
      return this.upsell && this.upsell.segment === 4
    }
  },
  methods: {
    onClose () {
      return this.$router.push({ name: 'revolving-loan' })
    }
  },
  mounted () {
    if (!this.revolvingLoan || !this.isEnabled) {
      return this.$router.push({ name: 'revolving-loan' })
    }

    document.location = this.upsell.url

    this.$tracker.action(REVOLVING_DASHBOARD.REVOLVING_VIEW_INCREASE_LIMIT_MODAL, {}, {
      contractId: this.revolvingLoan.id
    })
  }
}
</script>

<style lang="scss" scoped>
.increase-limit {
  &__loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 20vh;
  }
}
</style>
