import Vue from 'vue'

// use scripts/generateListOfUsedICComponents.js to generate this list
import {
  BbAccountCard,
  BbAccordion,
  BbApplicationContractCard,
  BbBanner,
  BbBigInput,
  BbBigInputCurrency,
  BbBox,
  BbButton,
  BbCampaignCards,
  BbCard,
  BbCardButtonGroup,
  BbCheckbox,
  BbCheckboxGroup,
  BbCol,
  BbContainer,
  BbContractPage,
  BbDatepicker,
  BbDetailedRadio,
  BbErrorPage,
  BbFooter,
  BbForm,
  BbFunctionalInputCurrency,
  BbHero,
  BbHorizontalMenu,
  BbIcon,
  BbIconFrame,
  BbInput,
  BbMaskedInput,
  BbLabel,
  BbLink,
  BbListGroup,
  BbListItem,
  BbLoadingOverlay,
  BbLogo,
  BbModal,
  BbNavbar,
  BbOverlay,
  BbPrefixedInput,
  BbProductCard,
  BbProductCards,
  BbProgressBar,
  BbProgressIndicator,
  BbRadio,
  BbRow,
  BbSelect,
  BbSeparator,
  BbSkeleton,
  BbSlider,
  BbSpinner,
  BbSticky,
  BbTable,
  BbTabs,
  BbTicketStub,
  BbToggleSwitch,
  BbTooltip,
  BbTransactions,
  BbUpload,
  BbTextarea,
  BbFieldsetRepeater,
  BbDropdownItem,
  BbDropdown,
  BbHeading
} from '@bigbank/interface-components'

// Register IC components one-by-one
[
  BbAccordion,
  BbAccountCard,
  BbApplicationContractCard,
  BbBanner,
  BbBigInput,
  BbBigInputCurrency,
  BbBox,
  BbButton,
  BbCampaignCards,
  BbFieldsetRepeater,
  BbFunctionalInputCurrency,
  BbCard,
  BbCardButtonGroup,
  BbCheckbox,
  BbCheckboxGroup,
  BbCol,
  BbContainer,
  BbContractPage,
  BbDatepicker,
  BbDetailedRadio,
  BbDropdown,
  BbDropdownItem,
  BbErrorPage,
  BbFooter,
  BbForm,
  BbHero,
  BbHorizontalMenu,
  BbIcon,
  BbIconFrame,
  BbInput,
  BbMaskedInput,
  BbLabel,
  BbLink,
  BbListGroup,
  BbListItem,
  BbLoadingOverlay,
  BbLogo,
  BbModal,
  BbNavbar,
  BbOverlay,
  BbPrefixedInput,
  BbProductCard,
  BbProductCards,
  BbProgressBar,
  BbProgressIndicator,
  BbRadio,
  BbRow,
  BbSelect,
  BbSeparator,
  BbSkeleton,
  BbSlider,
  BbSpinner,
  BbSticky,
  BbTable,
  BbTabs,
  BbTicketStub,
  BbToggleSwitch,
  BbTooltip,
  BbTransactions,
  BbUpload,
  BbTextarea,
  BbHeading
].forEach((component) => Vue.component(component.name, component))
