<template>
  <div class="limit-item" :name="limitSliderItemName">
    <div class="top">
      <div class="title">{{topTitle}}</div>
      <div class="amount">{{translations.limit}}</div>
    </div>
    <bb-slider v-if="enabled" v-bind="slider" @input="onChange" :name="`${limitSliderItemName}-input`" />
  </div>
</template>

<script>
import { BbSlider } from '@bigbank/interface-components'
import { mapState } from 'pinia'
import { formatMoneyWithCurrency } from '@/plugins/numformat'
import { currencyCodeToSymbol } from '@/plugins/currencyFormatters'
import { CardPeriodType } from '@bigbank/dc-common/clients/http/credit-card/credit-card.enums'
import { useRootStore } from '../../../store/root'

export default {
  props: {
    title: {
      type: String
    },
    type: {
      type: String,
      required: true,
      default: CardPeriodType.DAY,
      validator: (type) => Object.values(CardPeriodType).includes(type)
    },
    min: {
      required: true
    },
    max: {
      required: true
    },
    value: {
      required: true
    },
    current: {
      required: true
    },
    enabled: {
      type: Boolean,
      default: true
    },
    sectionName: {
      type: String,
      required: true
    }
  },
  components: {
    BbSlider
  },
  computed: {
    ...mapState(useRootStore, ['currency', 'locale']),
    topTitle () {
      return this.translations.title[this.type]
    },
    slider () {
      return {
        step: 1,
        value: this.value,
        min: Math.ceil(this.min),
        max: Math.floor(this.max),
        minLabel: this.translations.sliderRanges.min,
        maxLabel: this.translations.sliderRanges.max,
        // TODO: remove when Web team implements correct way of displaying
        // in accordance with provided locale
        unitPrefixed: this.isLocaleEn,
        unitSpace: !this.isLocaleEn,
        unit: currencyCodeToSymbol(this.currency),
        label: this.translations.labels[this.type],
        locale: this.locale
      }
    },
    isLocaleEn () {
      return this.locale === 'en'
    },
    translations () {
      return {
        limit: this.$gettextInterpolate(this.$pgettext('credit_card_card_limits', 'Current limit: %{amount}'), {
          amount: formatMoneyWithCurrency(this.current, this.currency, this.locale)
        }),
        labels: {
          [CardPeriodType.DAY]: this.$pgettext('credit_card_card_limits', 'How much do you need daily?'),
          [CardPeriodType.MONTH]: this.$pgettext('credit_card_card_limits', 'How much do you need monthly?')
        },
        sliderRanges: {
          min: this.$pgettext('credit_card_card_limits', 'Min'),
          max: this.$pgettext('credit_card_card_limits', 'Max')
        },
        title: {
          [CardPeriodType.DAY]: this.$pgettext('credit_card_card_limits', 'Daily limits'),
          [CardPeriodType.MONTH]: this.$pgettext('credit_card_card_limits', 'Monthly limits')
        }
      }
    },
    limitSliderItemName () {
      return `${this.sectionName}-${this.type.toLowerCase()}-limit`
    }
  },
  methods: {
    onChange (value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style lang="scss" scoped>
.limit-item {
  background: $gray-10;
  border: 1px solid $gray-20;
  border-radius: 6px;
  padding: 16px 16px 8px;

  .top {
    display: flex;
    justify-content: space-between;
    font-weight: 400;
    font-family: $gotham-medium;
    margin-bottom: 8px;

    .title {
      color: $gray;
      font-size: $font-size-tiny;
      line-height: 16px;

      @media (min-width: $desktop-view-breaking-point) {
        font-size: $font-size-smallest;
      }
    }

    .amount {
      color: $gray-70;
      font-size: $tiniest-font-size;
      line-height: 16px;
      text-align: right;

      @media (min-width: $desktop-view-breaking-point) {
        font-size: $font-size-smallest;
      }
    }
  }

  ::v-deep .bb-slider {
    .bb-slider__fields {
      .field-group {
        label {
          font-size: $tiniest-font-size;
          line-height: 12px;

          @media (min-width: $desktop-view-breaking-point) {
            font-size: $font-size-tiny;
          }
        }
      }
    }

    .bb-slider__ranges {
      span {
        font-size: $tiniest-font-size;
        line-height: 12px;

        @media (min-width: $desktop-view-breaking-point) {
          font-size: $font-size-smallest;
          line-height: 16px;
        }
      }
    }
  }
}
</style>
