<template>
  <layout-with-progress-v2 :loading="isLoading" :progress="{ hideProgressIndicator: true, max: 1, value: 1 }" @close="onClose">
    <div class="content">
      <p class="instructions">{{ translations.instructions }}</p>
      <bb-separator class="m-t-30" dashed />
      <bb-form
        :errorText="translations.errorText"
        :submitText="translations.submitText"
        @submit="() => this.$refs.uploadForm.openUploadModal()"
      >
        <id-document-upload-form
          ref="uploadForm"
          :formData.sync="documentForm"
          v-on:upload:finished="onDocumentUploadFinished"
        />
      </bb-form>
    </div>
  </layout-with-progress-v2>
</template>

<script>
import { mapActions } from 'pinia'
import IdDocumentUploadForm from '../components/IdDocumentUploadForm.vue'
import { formatDateForRequest } from '@/plugins/dateFormatters'
import { AccountRouteName } from '../const'
import { useRootStore } from '../../../store/root'
import { useAccountStore } from '@account/store/accountStore'
import FileUploadingMixin from '@/mixins/fileUploadingMixin'

export default {
  mixins: [FileUploadingMixin],
  components: { IdDocumentUploadForm },
  data () {
    return {
      isLoading: false,
      documentForm: {
        documentTypeCode: null,
        documentNumber: null,
        documentValidDate: null,
        documentExpiryDate: null,
        documentCountryCode: null,
        files: {
          frontSide: [],
          backSide: []
        }
      }
    }
  },
  computed: {
    translations () {
      return {
        instructions: this.$pgettext('upload_id_documents', 'We noticed that your identification document is about to expire or missing.'),
        submitText: this.$pgettext('upload_id_documents', 'Continue'),
        errorText: this.$pgettext('upload_id_documents', 'Fix errors to continue')
      }
    }
  },
  methods: {
    ...mapActions(useRootStore, ['checkAuthentication']),
    ...mapActions(useAccountStore, ['uploadDocument']),
    async submitIdDocuments () {
      this.isLoading = true
      await this.uploadDocument({
        documentTypeCode: this.documentForm.documentTypeCode,
        documentNumber: this.documentForm.documentNumber,
        documentCountryCode: this.documentForm.documentCountryCode,
        documentValidDate: formatDateForRequest(this.documentForm.documentValidDate),
        documentExpiryDate: formatDateForRequest(this.documentForm.documentExpiryDate),
        files: this.documentForm.files.frontSide.concat(this.documentForm.files.backSide)
      })
      await this.checkAuthentication(true)
      this.isLoading = false
      this.onClose()
    },
    async onDocumentUploadFinished (response) {
      if (this.isUploadingFailed(response)) {
        this.showFailureNotification(response)
      } else {
        this.showSuccessNotification()
        await this.checkAuthentication()
        this.onClose()
      }
    },
    onClose () {
      this.$router.push({ name: AccountRouteName.Documents })
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  padding: 20px;
  padding-top: 40px;
  max-width: 406px;

  .instructions {
    color: $gray-90;
    font-size: $font-size-small;
    line-height: 18px;
    text-align: center;
    font-family: $gotham-medium;
  }
}
</style>
