<template>
  <div>
    <signing-modal
      v-if="isIdentityConfirmationStep"
      ref="signing"
      :contract="contract"
      :initMethod="startIdentityConfirmation"
      @signingSuccessful="onIdentityConfirmationSucceed"
      @signingCancelled="onSigningModalClosed"
    />
    <bb-modal
      v-else
      :visible="visible"
      :flavor="modalFlavor"
      @close="onModalClosed"
      class="noselect text-center"
      fullMobile
      centerVertically
    >
      <template v-if="isShowingPINWarningStep">
        <h5 class="text-center modal-title">{{ translations.pin_warning_title_text }}</h5>
        <h4 class="text-center f-gotham-book warning-modal-description">{{ translations.pin_warning_description }}</h4>
        <bb-banner
          class="text-left m-t-30"
          type="info"
          :visible="true"
          permanent
        >
          {{ translations.we_display_this_pin_for_n_seconds }}
        </bb-banner>
        <bb-separator dashed class="m-y-30" />
        <h4 class="text-center f-gotham-book">{{ translations.to_activate_contactless }}</h4>
        <div slot="footer">
          <div class="text-you-can-check-pin-later">
            {{ translations.you_can_check_pin_later }}
          </div>
          <bb-button
            name="show-pin-modal-button"
            @click="onCustomerClickedShowSecretsButton"
            class="w-100"
          >{{ translations.pin_warning_button_text }}</bb-button>
        </div>
      </template>
      <template v-else-if="isShowingPINStep">
        <h5 class="text-center modal-title">{{ pinModalTitleText }}</h5>
        <h4 class="text-this-is-your-pin-code">{{ translations.this_is_your_card_pin }}</h4>
        <h1 class="color-blue m-t-15" name="card-pin">{{ this.revealedPIN }}</h1>
        <div class="text-we-display-this-info-for-n-seconds m-t-60">{{ translations.we_display_this_pin_for_n_seconds }}</div>
        <bb-progress-indicator name="modal-pin-timer" v-model="timer.value" v-bind="timerComponentProps" @input="onTimerTick"/>
        <div slot="footer" class="credit-card-secrets-modal__show-pin__footer">
          <div class="text-you-can-check-pin-later">
            {{ translations.you_can_check_pin_later }}
          </div>
          <bb-button
            name="close-pin-modal-button"
            @click="onCustomerClickedStartUsingYourCard"
            v-bind="submitButtonProps"
          >{{ pinModalCloseButtonText }}</bb-button>
        </div>
      </template>
      <template v-else-if="isShowingVirtualCardDetailsWarningStep">
        <h5 class="text-center modal-title">{{ translations.virtual_card_details_warning_title }}</h5>
        <h4 class="text-center f-gotham-book m-t-25">{{ translations.virtual_card_details_warning_description }}</h4>
        <bb-banner
          class="text-left m-t-30 virtual-card-details-warning-banner"
          type="info"
          :visible="true"
          permanent
        >
          {{ translations.card_details_displayed_for_n_seconds }}
        </bb-banner>
        <div slot="footer" class="credit-card-secrets-modal__show-pin__footer">
          <div class="text-you-can-check-pin-later">
            {{ translations.you_can_restart_timer }}
          </div>
          <bb-button
            name="show-secret-details-button"
            @click="onCustomerClickedShowSecretsButton"
            v-bind="submitButtonProps"
          >{{ translations.show_card_details }}</bb-button>
        </div>
      </template>
      <template v-else-if="isShowingPANAndCVVStep">
        <h5 class="text-center modal-title">{{ translations.card_details }}</h5>
        <bb-credit-card
          :name="'credit-card-logo-' + card.type"
          class="credit-card m-t-25"
          :type="getCardImageType"
          :digits="formattedPan"
          :digitsPrefix="''"
          status="activated"
          size="sm"
        />
        <table class="w-100 align-center m-t-20">
          <tr>
            <td class="table-td-title text-left">{{ translations.card_number }}</td>
            <td class="table-td-value text-right">
              <div class="d-flex align-items-center justify-content-md-end">
                <info-box-copy-button :disabled="!isTimerRunning" :item="pan" :size="'mini'" class="nm-y-5"/>
                <span name="card-pan">{{ formattedPan }}</span>
              </div>
            </td>
          </tr>
          <tr>
            <td class="table-td-title text-left">{{ translations.cvv }}</td>
            <td class="table-td-value text-right" name="card-cvv">{{ formattedCvv }}</td>
          </tr>
          <tr>
            <td class="table-td-title text-left">{{ translations.expiryDate }}</td>
            <td class="table-td-value text-right" name="card-expiry-date">{{ formattedExpiryDate }}</td>
          </tr>
        </table>
        <div class="w-100 text-center text-we-display-this-info-for-n-seconds m-t-30">
          <span v-if="isTimerRunning">{{ translations.we_display_this_information_for_n_seconds }}</span>
          <span v-else>{{translations.time_is_over}}</span>
        </div>
        <bb-progress-indicator name="modal-pan-cvv-timer" v-model="timer.value" v-bind="timerComponentProps" @input="onTimerTick"/>
        <div slot="footer">
          <div class="show-again-wrapper">
            <bb-button
              name="show-again-button"
              @click="onShowCardDetailsAgainClicked"
              class="w-100 m-b-20"
              :disabled="isTimerRunning"
              color="blue"
              inverted
            >
              {{ translations.show_card_details_again }}
            </bb-button>
          </div>
          <bb-button
            name="close-pan-cvv-modal-button"
            @click="onCustomerClickedCloseCardDetails"
            v-bind="buttonProps"
          >
            {{ translations.close_card_details }}
          </bb-button>
        </div>
      </template>
      <template v-else>
            <div class="loader">
              <bb-spinner />
            </div>
      </template>
    </bb-modal>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import SigningModal from '@/modules/loan/components/signing/SigningModal'
import api from '@/modules/loan/api'
import { CREDIT_CARD } from '@/const'
import { BbCreditCard } from '@bigbank/interface-components'
import InfoBoxCopyButton from '@/modules/deposits/components/InfoBoxCopyButton'
import isNull from 'lodash/isNull'
import { formatToCreditCardExpiryDate } from '@/plugins/dateFormatters'
import { CreditCardSigningAction } from '@bigbank/dc-common/enums/credit-card.enums'
import { CardType } from '@bigbank/dc-common/clients/http/credit-card/credit-card.enums'
import CreditCardSigningMixin from '@credit-card/components/mixins/CreditCardSigningMixin'
import { useRootStore } from '../../../../store/root'
import { useLoanStore } from '@loan/store/loanStore'

const SECRET_DISPLAY_TIME_S = CREDIT_CARD.SECRET_DISPLAY_TIME_MS / 1000

export default {
  name: 'credit-card-secrets-modal',
  components: { SigningModal, BbCreditCard, InfoBoxCopyButton },
  mixins: [CreditCardSigningMixin],
  props: {
    visible: { required: true, type: Boolean },
    cardId: { required: true, type: Number },
    type: { required: true, type: Number },
    cardType: { required: true, type: String },
    signingWay: { required: true, type: Number },
    verificationCVV: { required: false, type: String, default: null }
  },
  data () {
    return {
      pin: null,
      pan: null,
      cvv: null,
      card: null,
      step: CREDIT_CARD.SECRETS_MODAL_STEP.IDENTITY_CONFIRMATION,
      timer: {
        isRunning: false,
        max: SECRET_DISPLAY_TIME_S,
        value: SECRET_DISPLAY_TIME_S
      },
      signingRequestId: null
    }
  },
  computed: {
    ...mapState(useRootStore, ['isMobile']),
    ...mapState(useLoanStore, ['creditCardContract']),
    submitButtonProps () {
      return {
        display: 'block',
        size: this.isMobile ? 'lg' : undefined,
        class: this.isMobile ? 'm-s-a' : undefined,
        corner: this.isMobile ? 'sharp' : undefined
      }
    },
    contract () {
      return this.creditCardContract
    },
    pinModalTitleText () {
      return this.translations[this.signingWay === CREDIT_CARD.SECRETS_MODAL_SIGNING_WAY.THROUGH_ACTIVATION ? 'card_is_activated' : 'view_pin_code']
    },
    pinModalCloseButtonText () {
      return this.translations[this.signingWay === CREDIT_CARD.SECRETS_MODAL_SIGNING_WAY.THROUGH_ACTIVATION ? 'start_using_your_card' : 'done']
    },
    revealedPIN () {
      return this.pin
    },
    timerComponentProps () {
      return {
        max: this.timer.max,
        color: 'mint',
        class: 'm-x-a m-y-15',
        flavor: 'timer',
        trackColor: 'gray-30',
        timerRunning: this.timer.isRunning
      }
    },
    isIdentityConfirmationStep () {
      return this.step === CREDIT_CARD.SECRETS_MODAL_STEP.IDENTITY_CONFIRMATION && this.contract
    },
    isShowingPINWarningStep () {
      return this.step === CREDIT_CARD.SECRETS_MODAL_STEP.SHOWING_PIN_WARNING
    },
    isShowingVirtualCardDetailsWarningStep () {
      return this.step === CREDIT_CARD.SECRETS_MODAL_STEP.SHOWING_VIRTUAL_CARD_DETAILS_WARNING
    },
    isShowingPINStep () {
      return this.step === CREDIT_CARD.SECRETS_MODAL_STEP.SHOWING_PIN
    },
    isShowingPANAndCVVStep () {
      return this.step === CREDIT_CARD.SECRETS_MODAL_STEP.SHOWING_PAN_AND_CVV
    },
    isTimerRunning () {
      return this.timer.isRunning
    },
    secretDisplayTimeInSeconds () {
      return SECRET_DISPLAY_TIME_S
    },
    translations () {
      return {
        // PIN
        pin_warning_title_text: this.$pgettext('credit_card_secrets', 'Notification'),
        pin_warning_description: this.$pgettext('credit_card_secrets', 'Before we show the PIN code, please make sure you are in a safe place.'),
        pin_warning_button_text: this.$pgettext('credit_card_secrets', 'Show PIN'),
        view_pin_code: this.$pgettext('credit_card_secrets', 'View PIN code'),
        card_is_activated: this.$pgettext('credit_card_secrets', 'Card is activated!'),
        this_is_your_card_pin: this.$pgettext('credit_card_secrets', 'This is your PIN code'),
        we_display_this_pin_for_n_seconds: this.$gettextInterpolate(
          this.$pgettext('credit_card_secrets', 'We display this PIN for %{secretDisplayTimeInSeconds} seconds, remember it and do not tell anyone'),
          { secretDisplayTimeInSeconds: this.secretDisplayTimeInSeconds }
        ),
        done: this.$pgettext('credit_card_secrets', 'Done'),
        // Virtual card details
        virtual_card_details_warning_title: this.$pgettext('credit_card_secrets', 'Notification'),
        virtual_card_details_warning_description: this.$pgettext('credit_card_secrets', 'Before we show the virtual card details, please make sure you are in a safe place.'),
        show_card_details: this.$pgettext('credit_card_secrets', 'Show card details'),
        you_can_restart_timer: this.$pgettext('credit_card_secrets', 'You can restart the timer to see card details again'),
        card_details_displayed_for_n_seconds: this.$gettextInterpolate(
          this.$pgettext('credit_card_secrets', 'Card details will be displayed for %{secretDisplayTimeInSeconds} seconds'),
          { secretDisplayTimeInSeconds: this.secretDisplayTimeInSeconds }
        ),
        // PAN / CVV
        card_details: this.$pgettext('credit_card_secrets', 'Card details'),
        close_card_details: this.$pgettext('credit_card_secrets', 'Close card details'),
        we_display_this_information_for_n_seconds: this.$gettextInterpolate(
          this.$pgettext('credit_card_secrets', 'We display this information for %{secretDisplayTimeInSeconds} seconds'),
          { secretDisplayTimeInSeconds: this.secretDisplayTimeInSeconds }
        ),
        time_is_over: this.$pgettext('credit_card_secrets', 'Time is over'),
        card_number: this.$pgettext('credit_card_secrets', 'Card number'),
        cvv: this.$pgettext('credit_card_secrets', 'CVV'),
        expiryDate: this.$pgettext('credit_card_secrets', 'Expiry date'),
        start_using_your_card: this.$pgettext('credit_card_secrets', 'Start using your card'),
        show_card_details_again: this.$pgettext('credit_card_secrets', 'Show card details again'),
        // Common
        you_can_check_pin_later: this.$pgettext('credit_card_secrets', 'You can check the PIN later in the card settings'),
        to_activate_contactless: this.$pgettext('credit_card_secrets', 'To activate contactless payment function, first payment with the card has to be done in a terminal using the PIN code')
      }
    },
    getCardImageType () {
      return this.cardType === CardType.VIRTUAL ? 'virtual' : 'physical'
    },
    formattedPan () {
      const regexp = /(.{4})/g
      return this.isTimerRunning ? this.pan?.replace(regexp, '$1 ') : this.card.maskedPan?.replace(regexp, '$1 ')
    },
    formattedCvv () {
      return this.isTimerRunning ? this.cvv : this.cvv.replace(/\d/g, '*')
    },
    formattedExpiryDate () {
      return formatToCreditCardExpiryDate(this.card.validToDate)
    },
    modalFlavor () {
      return this.isMobile ? 'card' : 'user'
    },
    buttonProps () {
      const defaults = {
        display: 'block'
      }

      if (this.isMobile) {
        return {
          ...defaults,
          size: 'lg',
          class: 'm-s-a',
          corner: 'sharp'
        }
      }

      return {
        ...defaults,
        class: 'w-100'
      }
    }
  },
  methods: {
    ...mapActions(useLoanStore, ['getCreditCards']),
    setPIN (pin) { this.pin = pin },
    setCVV (cvv) { this.cvv = cvv },
    setPAN (pan) { this.pan = pan },
    setStep (step) { this.step = step },
    setTimerRunning (flag) { this.timer.isRunning = flag },
    resetTimer () { this.timer.value = SECRET_DISPLAY_TIME_S },
    onShowCardDetailsAgainClicked () {
      this.resetTimer()
      this.setStep(CREDIT_CARD.SECRETS_MODAL_STEP.IDENTITY_CONFIRMATION)

      this.$nextTick(() => {
        this.$refs.signing.signButtonClick(new MouseEvent('click', {}))
      })
    },
    setSigningRequestId (requestId) { this.signingRequestId = requestId },
    async startIdentityConfirmation () {
      const unknownSigningWayErrorMessage = 'Unknown signingWay'
      let srResponse = null
      let activationStatusResponse = {
        isSigningCompleted: false,
        hasCvvCheckFailed: false
      }

      try {
        if (this.signingWay === CREDIT_CARD.SECRETS_MODAL_SIGNING_WAY.DEFAULT) {
          const secretType = {
            [CREDIT_CARD.SECRETS_MODAL_STEP.SHOWING_PIN]: CREDIT_CARD.SECRET_TYPE.PIN,
            [CREDIT_CARD.SECRETS_MODAL_STEP.SHOWING_PAN_AND_CVV]: CREDIT_CARD.SECRET_TYPE.PAN_AND_CVV
          }[this.type]

          const signingAction = {
            [CREDIT_CARD.SECRET_TYPE.PIN]: CreditCardSigningAction.ViewPin,
            [CREDIT_CARD.SECRET_TYPE.PAN_AND_CVV]: CreditCardSigningAction.ViewCvvAndPan
          }[secretType]

          srResponse = await api.initCreditCardSigningRequestByAction(signingAction, this.contract.id, this.cardId)
        } else if (this.signingWay === CREDIT_CARD.SECRETS_MODAL_SIGNING_WAY.THROUGH_ACTIVATION) {
          const cardCvv = this.cardType === CREDIT_CARD.TYPE.VIRTUAL ? undefined : this.verificationCVV
          srResponse = await api.initCreditCardSigningRequestByAction(CreditCardSigningAction.Activate, this.contract.id, this.cardId, { cardCvv })
          activationStatusResponse = await api.getCreditCardSigningStatusByAction(CreditCardSigningAction.Activate, this.cardId, srResponse.signingRequestId)
        } else if (this.signingWay === CREDIT_CARD.SECRETS_MODAL_SIGNING_WAY.THROUGH_ORDERING_VIRTUAL_CARD) {
          srResponse = await api.initCreditCardSigningRequestByAction(CreditCardSigningAction.Activate, this.contract.id, this.cardId)
        } else {
          throw new Error(unknownSigningWayErrorMessage)
        }

        this.setSigningRequestId(srResponse.signingRequestId)

        return {
          method: srResponse.method,
          signingRequestId: srResponse.signingRequestId,
          isSigningCompleted: activationStatusResponse.isSigningCompleted,
          hasCVVCheckFailed: activationStatusResponse.hasCvvCheckFailed
        }
      } catch (err) {
        if (err.message === unknownSigningWayErrorMessage) {
          throw err
        }

        console.error(`Request failed (signingWay: ${this.signingWay})`, err)

        return {
          method: null,
          signingRequestId: null,
          isSigningCompleted: false,
          hasCVVCheckFailed: false
        }
      }
    },
    async onIdentityConfirmationSucceed (params = {}) {
      const unknownSigningWayErrorMessage = 'Unknown modal type!'

      try {
        if (this.signingWay === CREDIT_CARD.SECRETS_MODAL_SIGNING_WAY.THROUGH_ACTIVATION) {
          let hasCVVCheckFailed = params.hasCVVCheckFailed

          if (params.hasCVVCheckFailed === undefined) {
            const activationResponse = await api.getCreditCardSigningStatusByAction(CreditCardSigningAction.Activate, this.cardId, this.signingRequestId)
            hasCVVCheckFailed = activationResponse.hasCvvCheckFailed
          }

          if (hasCVVCheckFailed) {
            this.$emit('onModalClosed', { hasCVVCheckFailed })
            return
          }
        }

        if (![CREDIT_CARD.SECRETS_MODAL_STEP.SHOWING_PIN, CREDIT_CARD.SECRETS_MODAL_STEP.SHOWING_PAN_AND_CVV].includes(this.type)) {
          this.onModalClosed()
          throw new Error(unknownSigningWayErrorMessage)
        }

        const { pin, pan, cvv } = await api.getCreditCardSecrets(this.cardId, this.signingRequestId)

        if (this.type === CREDIT_CARD.SECRETS_MODAL_STEP.SHOWING_PIN) {
          this.setPIN(pin)
          if (CREDIT_CARD.SECRETS_MODAL_SIGNING_WAY.THROUGH_ACTIVATION === this.signingWay) {
            this.setStep(CREDIT_CARD.SECRETS_MODAL_STEP.SHOWING_PIN_WARNING)
          } else {
            this.setStep(this.type)
            this.setTimerRunning(true)
          }
        } else if (this.type === CREDIT_CARD.SECRETS_MODAL_STEP.SHOWING_PAN_AND_CVV) {
          this.setCVV(cvv)
          this.setPAN(pan)

          if (CREDIT_CARD.SECRETS_MODAL_SIGNING_WAY.THROUGH_ORDERING_VIRTUAL_CARD === this.signingWay) {
            this.setStep(CREDIT_CARD.SECRETS_MODAL_STEP.SHOWING_VIRTUAL_CARD_DETAILS_WARNING)
          } else {
            this.setStep(this.type)
            this.setTimerRunning(true)
          }
        } else {
          this.onModalClosed()
          throw new Error(unknownSigningWayErrorMessage)
        }
      } catch (err) {
        this.onModalClosed()
        throw err
      }
    },
    onCustomerClickedShowSecretsButton () {
      this.setStep(this.type)
      this.setTimerRunning(true)
    },
    onCustomerClickedStartUsingYourCard () {
      this.onModalClosed()
    },
    onCustomerClickedCloseCardDetails () {
      this.onModalClosed()
    },
    onSigningModalClosed () {
      this.onModalClosed()
    },
    onTimerTick (value) {
      value === 0 && (this.isShowingPINStep ? this.onModalClosed() : this.setTimerRunning(false))
    },
    onModalClosed () {
      this.$emit('onModalClosed')
    }
  },
  created () {
    if ([CREDIT_CARD.SECRETS_MODAL_SIGNING_WAY.THROUGH_ACTIVATION].includes(this.signingWay) && isNull(this.verificationCVV) && !this.hasReturnedAfterSigning()) {
      throw new Error('verificationCVV prop expected')
    }
    if ([CREDIT_CARD.SECRETS_MODAL_SIGNING_WAY.DEFAULT, CREDIT_CARD.SECRETS_MODAL_SIGNING_WAY.THROUGH_ACTIVATION].includes(this.signingWay) && isNull(this.cardId)) {
      throw new Error('cardId as a prop required if signingWay DEFAULT or THROUGH_ACTIVATION')
    }
  },
  async mounted () {
    if (this.hasReturnedAfterSigning()) {
      this.setSigningRequestId(this.signingRequestIdFromQuery)
    } else {
      this.$refs.signing.signButtonClick(new MouseEvent('click', {}))
    }
    this.card = await api.getCreditCardByCardId(this.cardId)
  }
}
</script>

<style lang="scss" scoped>
/* common */
.noselect { // Note: Maybe move globally?
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently  supported by Chrome, Edge, Opera and Firefox */
}

.credit-card-secrets-modal__show-pin__footer {
  // DCP-4822 there was an issue with modal footer losing z-index on devices that have browser nav menu in the bottom
  position: relative;
  z-index: $z-index-overlay + 1;
}

.modal-title {
  color: $blue;
  font-weight: 500;
  font-size: $font-size-small;
  font-family: $gotham-medium;
}

.warning-modal-description {
  margin-top: 30px;
}

.virtual-card-details-warning-banner {
  margin-bottom: 195px;
}

.text-we-display-this-info-for-n-seconds {
  font-size: $font-size-tiny;
  color: $gray;
}

.text-you-can-check-pin-later {
  font-size: $font-size-tiny;
  color: $gray-70;
  margin-bottom: 16px;
}

/* PIN Secrets */
.text-this-is-your-pin-code {
  color: $gray;
  display: block;
  margin-top: 65px;
}

.credit-card {
  margin: 0 auto;
  max-width: 305px;

  @media (max-width: $mobile-view-breaking-point) {
    max-width: 280px;
  }
}

.table-td-title {
  color: $gray;
  line-height: 30px;
  font-size: $font-size-smallest;
  vertical-align: middle;
  font-family: $gotham-medium;

  @media (max-width: $breakpoint-sm) {
    line-height: 100%;
  }
}

.table-td-value {
  color: $gray;
  font-weight: 700;
  line-height: 30px;
  vertical-align: middle;
  font-size: $font-size-small;
  white-space: nowrap;

  @media (max-width: $breakpoint-sm) {
    font-size: $font-size-smallest;
  }
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
}

.show-again-wrapper {
  @media (max-width: $mobile-view-breaking-point) {
    padding: 0 30px;
  }
}

h4 {
  font-size: 18px;
  line-height: 24px;
}
</style>
