<template>
  <layout-with-progress :loading="true"/>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { DepositRouteName } from '@deposits/const'

export default {
  name: 'confirm-data-landing',
  methods: { ...mapActions(['checkAuthentication']) },
  computed: { ...mapState(['permissions']) },
  async created () {
    await this.checkAuthentication({ forceFetchPermissions: true })
    const sendToOnboardingFlow = this.permissions?.transactions?.sendToOnboardingFlow
    if (!sendToOnboardingFlow) {
      // Adding data in Welcome service was successful
      this.$router.push({ name: DepositRouteName.New })
    } else {
      this.$router.push({ name: DepositRouteName.Deposits })
    }
  }
}
</script>
