<template>
  <div>
    <bb-select
      v-validate="'required'"
      name="documentTypeCode"
      key="documentTypeCode"
      :data-vv-as="translations.labels.documentTypeCode"
      :label="translations.labels.documentTypeCode"
      v-model="documentForm.documentTypeCode"
      :options="documentTypeOptions"
      data-vv-validate-on="change"
    />
    <bb-input
      v-validate="'required'"
      name="documentNumber"
      key="documentNumber"
      :data-vv-as="translations.labels.documentNumber"
      :label="translations.labels.documentNumber"
      v-model="documentForm.documentNumber"
    />
    <bb-datepicker
      name="documentValidDate"
      key="documentValidDate"
      type="date"
      :confirmText="translations.ok"
      :lang="datepickerLanguage"
      :shortcuts="false"
      :format="dateFormat"
      :maxDate="notAfter"
      :data-vv-as="translations.labels.documentValidDate"
      :label="translations.labels.documentValidDate"
      v-model="documentForm.documentValidDate"
      v-validate="dateValidationRules"
      data-vv-validate-on="change"
    />
    <bb-datepicker
      name="documentExpiryDate"
      key="documentExpiryDate"
      type="date"
      :confirmText="translations.ok"
      :lang="datepickerLanguage"
      :shortcuts="false"
      :format="dateFormat"
      :minDate="notBefore"
      :maxDate="maxExpireDate"
      :data-vv-as="translations.labels.documentExpiryDate"
      :label="translations.labels.documentExpiryDate"
      v-model="documentForm.documentExpiryDate"
      v-validate="dateValidationRules"
      data-vv-validate-on="change"
    />
    <bb-select
      name="documentCountryCode"
      key="documentCountryCode"
      v-validate="'required'"
      :label="translations.labels.documentCountryCode"
      :data-vv-as="translations.labels.documentCountryCode"
      v-model="documentForm.documentCountryCode"
      :options="translatedCountryListWithExcludedStateless"
      data-vv-validate-on="change"
    />
    <bb-modal
      :visible="showUploadForm"
      @close="closeUploadModal"
      flavor="card"
      size="x2"
      publicSize="m"
      class="document-upload-modal"
      fullMobile
      staticOverlay
    >
      <template slot="heading"><span class="f-color-blue">{{ documentUploadFormHeading }}</span></template>
      <bb-upload
        flavor="user-selfcontained"
        :heading="documentUploadFrontSideHeading"
        :fileCountLimit="1"
        :allowedTypes="allowedFileTypes"
        :sizeLimit="15"
        v-bind="uploadTranslations"
        v-model="documentForm.files.frontSide"
      >
        <template v-slot:browseLabel>
          <span v-sanitize.basic="translations.browseLabel" />
        </template>
      </bb-upload>
      <bb-upload
        v-show="isBackSideFileFormVisible"
        flavor="user-selfcontained"
        :heading="documentUploadBackSideHeading"
        :fileCountLimit="1"
        :allowedTypes="allowedFileTypes"
        :sizeLimit="15"
        v-bind="uploadTranslations"
        v-model="documentForm.files.backSide"
      >
        <template v-slot:browseLabel>
          <span v-sanitize.basic="translations.browseLabel" />
        </template>
      </bb-upload>
      <div v-show="showMustHaves" class="must-haves nm-s-30 nm-b-30 p-30 m-t-30">
        <div class="must-haves__title color-gray-50 m-b-10">{{ translations.mustHaves }}</div>
        <bb-list-group>
          <bb-list-item v-for="(item, k) in documentMustHaves" :key="k" condensed>{{ item }}</bb-list-item>
        </bb-list-group>
      </div>
      <bb-button
        v-bind="submitButtonProps"
        slot="footer"
        :disabled="!filesSelected || isUploading"
        :label="translations.sendDocument"
        :loading="isUploading"
        @click="onSendDocumentButtonClick"
      />
    </bb-modal>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useAccountStore } from '@account/store/accountStore'
import { formatDateForRequest, getDateFormat } from '@/plugins/dateFormatters'
import { FILE_UPLOADING, STATELESS_COUNTRY_CODE } from '../../../const'
import dayjs from 'dayjs'
import { getTranslatedCountryList } from '@/plugins/translations'
import { useRootStore } from '../../../store/root'
import { IdDocumentTypeCode } from '@bigbank/dc-common/clients/http/crm-service/crm-service.enums'
import { CountryChannel } from '@bigbank/dc-common/config'
import FileUploadingMixin from '@/mixins/fileUploadingMixin'
import { getConfirmFlowTrackerAction } from '@/TrackingActions'
import { isString } from 'lodash'

export default {
  inject: ['$validator'],
  data () {
    return {
      isUploading: false,
      allowedFileTypes: ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf'],
      showUploadForm: false,
      dateFormat: getDateFormat(),
      notBefore: dayjs().add(1, 'day').toDate(),
      maxExpireDate: dayjs().add(30, 'year').toDate(),
      notAfter: dayjs().toDate(),
      documentForm: {
        documentTypeCode: null,
        documentNumber: null,
        documentValidDate: null,
        documentExpiryDate: null,
        documentCountryCode: null,
        files: {
          frontSide: [],
          backSide: []
        }
      }
    }
  },
  mixins: [FileUploadingMixin],
  props: {
    formData: {
      type: Object,
      required: true
    },
    flow: {
      type: String,
      required: false
    }
  },
  watch: {
    documentForm: {
      handler (val) {
        this.$emit('update:formData', val)
      },
      deep: true
    }
  },
  methods: {
    ...mapActions(useAccountStore, ['getCountries', 'uploadDocument']),
    setUploading (flag) {
      this.isUploading = flag
    },
    openUploadModal () {
      this.showUploadForm = true
    },
    getTranslatedCountryList (excludeByCode) {
      return getTranslatedCountryList(this.channel, this.countryList, excludeByCode)
    },
    closeUploadModal () {
      this.showUploadForm = false
    },
    async onSendDocumentButtonClick () {
      const antiBlinkingDelay = FILE_UPLOADING.BUTTON_LOADING_ANTI_BLINKING_DELAY_MS
      const uploadingStartedAt = Date.now()
      let response = null

      try {
        this.$emit('upload:started')
        this.setUploading(true)

        if (isString((this.flow))) {
          this.$tracker.action(getConfirmFlowTrackerAction(this.flow, 'upload-form'))
        }

        response = await this.uploadDocument({
          documentTypeCode: this.documentForm.documentTypeCode,
          documentNumber: this.documentForm.documentNumber,
          documentCountryCode: this.documentForm.documentCountryCode,
          documentValidDate: formatDateForRequest(this.documentForm.documentValidDate),
          documentExpiryDate: formatDateForRequest(this.documentForm.documentExpiryDate),
          files: this.documentForm.files.frontSide.concat(this.documentForm.files.backSide)
        })
      } catch (err) {
        this.$apm.captureError(err)
      } finally {
        const uploadTimeMs = Date.now() - uploadingStartedAt
        const timeoutMs = uploadTimeMs < antiBlinkingDelay ? antiBlinkingDelay - uploadTimeMs : 0

        setTimeout(() => {
          this.$emit('upload:finished', response)
          this.setUploading(false)
        }, timeoutMs)
      }
    }
  },
  computed: {
    ...mapState(useRootStore, ['channel', 'isMobile', 'isCompany', 'datepickerLanguage']),
    ...mapState(useAccountStore, ['countryList']),
    translations () {
      return {
        labels: {
          documentTypeCode: this.$pgettext('form', 'Type of document'),
          documentNumber: this.$pgettext('form', 'Document number'),
          documentValidDate: this.$pgettext('form', 'Document valid date'),
          documentExpiryDate: this.$pgettext('form', 'Document expiry date'),
          documentCountryCode: this.$pgettext('form', 'Document country code')
        },
        documentTypes: {
          [IdDocumentTypeCode.Passport]: this.$pgettext('document_type', 'Passport'),
          [IdDocumentTypeCode.IdCard]: this.$pgettext('document_type', 'ID Card'),
          [IdDocumentTypeCode.ForeignPassport]: this.$pgettext('document_type', 'Foreign citizen passport'),
          [IdDocumentTypeCode.DiplomaticPassport]: this.$pgettext('document_type', 'Diplomatic passport'),
          [IdDocumentTypeCode.DriversLicense]: this.$pgettext('document_type', 'Drivers license'),
          [IdDocumentTypeCode.AliensPassport]: this.$pgettext('document_type', 'Aliens passport'),
          [IdDocumentTypeCode.BirthCertificate]: this.$pgettext('document_type', 'Birth certificate'),
          [IdDocumentTypeCode.PermanentResidencePermitCard]: this.$pgettext('document_type', 'Permanent residence permit card'),
          [IdDocumentTypeCode.TemporaryResidencePermitCard]: this.$pgettext('document_type', 'Temporary residence permit card')
        },
        expireWarning: this.$gettext('Please provide a document that is valid for more than 30 days'),
        uploadFormHeading: this.$pgettext('upload_modal_heading', 'Upload photo of your:'),
        frontSide: this.$pgettext('form_document', 'Front side of:'),
        backSide: this.$pgettext('form_document', 'Back side of:'),
        photoOf: this.$pgettext('form_document', 'Photo of:'),
        sendDocument: this.$pgettext('document_upload', 'Send Document'),
        mustHaves: this.$pgettext('document_upload', 'Must Haves'),
        browseLabel: this.browseLabelText
      }
    },
    uploadTranslations () {
      return {
        allowedTypesLabel: this.$pgettext('upload', 'File type is not allowed'),
        duplicateFileLabel: this.$pgettext('upload', 'File is already uploaded'),
        captureLabel: this.$pgettext('upload', 'Capture'),
        chooseLabel: this.$pgettext('upload', 'Choose'),
        coBorrowerEmptyLabel: this.$pgettext('upload', 'Upload file'),
        emptyDescriptionLabel: this.$pgettext('upload', 'No documents are uploaded.'),
        sizeLimitLabel: this.$pgettext('upload', 'File size is over allowed size'),
        fileCountExceededLabel: this.$pgettext('upload', 'File limit exceeded'),
        uploadAnotherLabel: '',
        filenameMaxLengthLabel: this.filenameMaxLengthLabelText
      }
    },
    browseLabelText () {
      return this.isCompany
        ? this.$pgettext('corporate_documents', 'Drag a file here or <span class="bb-upload__highlight">browse</span> a file to upload')
        : this.$pgettext('document_upload', 'Drag a file here or <span class="bb-upload__highlight">browse</span> a file to upload')
    },
    filenameMaxLengthLabelText () {
      return this.isCompany
        ? this.$pgettext('corporate_documents', 'The file name is too long')
        : this.$pgettext('upload', 'The file name is too long')
    },
    dateValidationRules () {
      return {
        required: true,
        date_format: getDateFormat(),
        documentExpirationSoon: true
      }
    },
    translatedCountryListWithExcludedStateless () {
      return this.getTranslatedCountryList([STATELESS_COUNTRY_CODE])
    },
    documentUploadFormHeading () {
      return this.translations.uploadFormHeading +
        ' ' +
        this.translations.documentTypes[this.documentForm.documentTypeCode] ?? ''
    },
    documentUploadFrontSideHeading () {
      if (!this.showBackSideFileForm) {
        return this.translations.photoOf + ' ' + this.translations.documentTypes[this.documentForm.documentTypeCode] ?? ''
      }
      return this.translations.frontSide + ' ' + this.translations.documentTypes[this.documentForm.documentTypeCode] ?? ''
    },
    documentUploadBackSideHeading () {
      return this.translations.backSide + ' ' + this.translations.documentTypes[this.documentForm.documentTypeCode] ?? ''
    },
    documentMustHaves () {
      return this.$pgettext('document',
        'Clear photo/scan of the document \n' +
        'Name must be visible \n' +
        'Date of birth must be visible \n' +
        'ID number must be clearly visible \n' +
        'Fully in frame, not cut off on any side')
        .split('\n')
        .map(text => (text.trim()))
    },
    filesSelected () {
      return this.documentForm.files.frontSide.filter(f => f.file).length > 0
    },
    showBackSideFileForm () {
      return this.documentForm.documentTypeCode !== IdDocumentTypeCode.Passport
    },
    isBackSideFileFormVisible () {
      return this.showBackSideFileForm && this.filesSelected
    },
    showMustHaves () {
      return !this.filesSelected
    },
    documentTypeOptions () {
      const types = [
        {
          text: this.translations.documentTypes.PASSPORT,
          value: IdDocumentTypeCode.Passport
        },
        {
          text: this.translations.documentTypes.ID_CARD,
          value: IdDocumentTypeCode.IdCard
        }
      ]

      switch (this.channel) {
        case CountryChannel.NL:
          types.push({
            text: this.translations.documentTypes[IdDocumentTypeCode.DriversLicense],
            value: IdDocumentTypeCode.DriversLicense
          })
          break
        case CountryChannel.EE:
          types.push({
            text: this.translations.documentTypes[IdDocumentTypeCode.AliensPassport],
            value: IdDocumentTypeCode.AliensPassport
          },
          {
            text: this.translations.documentTypes[IdDocumentTypeCode.BirthCertificate],
            value: IdDocumentTypeCode.BirthCertificate
          },
          {
            text: this.translations.documentTypes[IdDocumentTypeCode.PermanentResidencePermitCard],
            value: IdDocumentTypeCode.PermanentResidencePermitCard
          },
          {
            text: this.translations.documentTypes[IdDocumentTypeCode.TemporaryResidencePermitCard],
            value: IdDocumentTypeCode.TemporaryResidencePermitCard
          })
          break
      }

      return types
    },
    submitButtonProps () {
      return {
        display: 'block',
        size: this.isMobile ? 'lg' : undefined,
        class: this.isMobile ? 'm-s-a' : undefined,
        corner: this.isMobile ? 'sharp' : undefined
      }
    }
  },
  created () {
    this.getCountries()
    this.$validator.extend('documentExpirationSoon', {
      getMessage: () => {
        return this.translations.expireWarning
      },
      validate: value => {
        const today = new Date().getTime()
        if (today > value.getTime()) return true
        const DAY_IN_MILLISECONDS = 24 * 60 * 60 * 1000
        const remainingDays = Math.round(Math.abs((today - value.getTime()) / DAY_IN_MILLISECONDS))
        return remainingDays > 30
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.document-upload-modal::v-deep h3 {
  line-height: 18px;
  padding-left: 10px;
  padding-right: 10px;
}

.document-upload-modal {
  .must-haves {
    background: $gray-10;
    border-radius: 5px;

    &__title {
      font-family: $gotham-medium;
      font-size: $font-size-small;
    }
  }
}
</style>
