import { defineStore } from 'pinia'
import { getEmptyAccountStatementData } from '@/components/transactions/transactions.helpers'
import { TransactionSettingsItem } from '@bigbank/dc-common/enums/loan.enums'
import { TransactionAdvancedSearchOption, TransactionsPeriodFilter } from '@bigbank/dc-common/enums/credit-card.enums'
import dayjs from 'dayjs'
import { useRootStore } from '../../../store/root'
import CARD_TYPE from '@/components/cards/cardTypes'
import api from '../api'
import { mapContract } from './util'
import { CREDIT_CARD } from '@/const'
import { transformFormDataIntoSearchRequestBody } from '@credit-card/components/util/transactions.util'
import { EMPTY_FILTERS_FORM_DATA } from '@credit-card/components/credit-card.const'
import store from '@/store'

const loader = { count: 0 }

function sortCreditLoans (a, b) {
  return a.statusCode.toUpperCase() === 'ACTIVE' ? -1 : (b.statusCode.toUpperCase() === 'ACTIVE' ? 0 : -1)
}

export const useLoanStore = defineStore('loan', {
  state: () => ({
    contractId: null,
    contracts: [],
    isContractsLoading: false,
    creditCards: [],
    applications: [],
    loading: false,
    areCreditCardButtonsEnabled: true,
    featureFlags: {},
    upsell: null,
    loanTypes: {
      LOAN: 'LOAN',
      REVOLVING: 'REVOLVING',
      HOUSING: 'HOUSING',
      REAL_ESTATE: 'REAL_ESTATE',
      CREDIT_CARD: 'CREDIT_CARD',
      LEASING: 'LEASING'
    },
    promiseToPay: null,
    availableMonthlyLimit: null,
    contractAdditionalDetails: null,
    creditCardAccountStatement: getEmptyAccountStatementData('credit-card'),
    creditCardTransactionsSearchRequest: null,
    creditCardTransactionsSettings: {
      [TransactionSettingsItem.FilteringPeriods]: {},
      [TransactionSettingsItem.FilteringAdvancedSearchOptions]: TransactionAdvancedSearchOption,
      [TransactionSettingsItem.FilteringPeriodByDefault]: TransactionsPeriodFilter.CurrentYear,
      [TransactionSettingsItem.FilteringAdvancedSearchOptionByDefault]: TransactionAdvancedSearchOption.AnyFieldContains,
      [TransactionSettingsItem.FilteringDatePickerLimits]: {
        min: '2022-06-01',
        max: dayjs().format('YYYY-MM-DD')
      }
    },
    creditCardHeroHeaderData: {
      availableLimitAmount: null,
      reservations: null
    },
    creditCardPricing: {
      baseInterest: null,
      maxInterestFreePeriod: null,
      monthlyFee: null,
      cardReplacement: null
    }
  }),
  getters: {
    contract (state) {
      return state.contracts.find(({ id }) => id === state.contractId)
    },
    revolvingLoan (state) {
      return state.contracts
        .filter(({ typeCode }) => typeCode === state.loanTypes.REVOLVING)
        .sort(sortCreditLoans)[0]
    },
    creditCardContract (state) {
      return state.contracts
        .filter(({ typeCode }) => typeCode === state.loanTypes.CREDIT_CARD)
        .sort(sortCreditLoans)[0] ?? null
    },
    loans (state) {
      return state.contracts.filter(({ typeCode }) => [
        state.loanTypes.LOAN,
        state.loanTypes.HOUSING,
        state.loanTypes.REAL_ESTATE,
        state.loanTypes.LEASING
      ].includes(typeCode))
    },
    hasCreditCardContract (state) {
      const rootStore = useRootStore()
      return rootStore.contractsCount?.totalByType?.contract?.all?.[state.loanTypes.CREDIT_CARD] > 0
    },
    creditCardAvailableLimit (state) {
      return state.creditCardHeroHeaderData?.availableLimitAmount
    },
    isPromiseToPayShown (state) {
      const rootStore = useRootStore()
      return (contracts = null) => {
        return state.promiseToPay &&
          !this.hasTerminatedContracts((contracts ?? this.loans)) &&
          rootStore.featureFlags.enablePromiseToPay
      }
    },
    hasTerminatedContracts () {
      return (contracts = null) => {
        return [contracts ?? this.loans].reduce((acc, contract) => (acc || contract.isTerminated), false)
      }
    },
    isInDebt () {
      return (contracts = null) => {
        return (contracts ?? this.loans).reduce((acc, contract) => (acc || ((contract.isInDebt || contract.isTerminated) && !contract.isClosed)), false)
      }
    },
    isClosed () {
      return (contracts = null) => {
        return (contracts ?? this.loans).reduce((acc, contract) => (acc && contract.isClosed), true)
      }
    },
    getApplicationsByType (state) {
      return (applicationType) => {
        return state.applications
          .filter(({ typeCode }) => typeCode === applicationType)
          .reduce((acc, application) => {
            const index = application.status.toLowerCase() === 'closed' ? 'closed' : 'active'
            application.type = CARD_TYPE.APPLICATION
            acc[index].push(application)
            return acc
          }, { active: [], closed: [] })
      }
    }
  },
  actions: {
    async initModule () {
      this.setLoading(true)
      await this.getFeatureFlags()
      this.setLoading(false)
    },
    setLoading (isLoading) {
      if (isLoading) {
        loader.count++
      } else {
        loader.count--
      }

      this.loading = loader.count > 0
    },
    async init () {
      this.setLoading(true)
      this.contractAdditionalDetails = null

      await Promise.all([
        this.getContracts(),
        this.getApplications(),
        this.getUpsellData(),
        this.getPromiseToPay(),
        this.getFeatureFlags()
      ])

      await store.dispatch('campaign/getCampaigns', null, { root: true })
      this.setLoading(false)
    },
    async initCreditCardTransactions (contractId) {
      const settings = await this.fetchCreditCardTransactionsSettings()
      const defaultPeriod = settings[TransactionSettingsItem.FilteringPeriodByDefault]
      const filters = {
        ...EMPTY_FILTERS_FORM_DATA,
        ...settings[TransactionSettingsItem.FilteringPeriods][defaultPeriod],
        period: defaultPeriod
      }

      this.creditCardTransactionsSearchRequest = transformFormDataIntoSearchRequestBody(filters, CREDIT_CARD.TRANSACTIONS_PER_PAGE, 1)
      await this.fetchCreditCardAccountStatement({ contractId })
    },
    async getContracts (opts = {}) {
      if (opts.showLoader) {
        this.setLoading(true)
      }

      try {
        this.isContractsLoading = true
        let contracts = await api.getContracts()
        if (!Array.isArray(contracts)) {
          contracts = contracts ? [contracts] : []
        }
        this.contracts = contracts.map(mapContract)

        if (opts.showLoader) {
          this.setLoading(false)
        }
      } finally {
        this.isContractsLoading = false
      }
    },
    async getApplications () {
      const rootStore = useRootStore()
      const reason = 'get_applications'
      let applications = []

      try {
        applications = await api.getApplications()
        rootStore.reportSuccessfulRequest({ reason })
      } catch (err) {
        rootStore.showPartialFailureNotification({ reason })
      }
      this.applications = applications
    },
    async getUpsellData () {
      try {
        const upsell = await api.getUpsell()
        if (upsell) {
          this.upsell = {
            amount: upsell.upsellAmount,
            segment: upsell.segmentCode,
            currency: upsell.currencyCode,
            url: upsell.upsellStartUrl
          }
        }
      } catch (e) {
        // Error is ignored, as displaying uppsell is not business critical error
      }
    },
    async getAvailableMonthlyLimit () {
      const availableLimit = await api.getAvailableMonthlyLimit()
      if (availableLimit) {
        this.avaliableMontlyLimit = {
          canOrderWithoutAdditionalLimits: availableLimit.canOrderWithoutAdditionalLimits,
          remainingLimit: availableLimit.remainingLimit
        }
      }
      return availableLimit
    },
    async getFeatureFlags () {
      const rootStore = useRootStore()

      if (Object.keys(this.featureFlags).length) {
        return this.featureFlags
      }

      const featureFlags = await api.getFeatureFlags()
      this.featureFlags = Object.assign({}, rootStore.featureFlags, featureFlags)

      return featureFlags
    },
    async getContract (contractId) {
      this.contractId = (contractId || '').toString()
      if (this.contracts.filter(({ id }) => id === contractId).length) {
        return
      }
      await this.getContracts()
    },
    async getPromiseToPay () {
      this.promiseToPay = await api.getPromiseToPay()
    },
    async getCreditCards (contractId) {
      this.setLoading(true)
      this.creditCards = await api.getCreditCardsByContract(contractId)
      this.setLoading(false)
    },
    resetCreditCards () {
      this.creditCards = []
      this.creditCardHeroHeaderData = {
        availableLimitAmount: null,
        reservations: null
      }
    },
    async refreshCreditCard (cardId) {
      const updatedCard = await api.getCreditCardByCardId(cardId)
      const creditCards = this.creditCards.map(card => {
        if (card.id.toString() === updatedCard.id.toString()) {
          return updatedCard
        }
        return card
      })

      this.creditCards = creditCards
    },
    async blockCreditCard ({ contractId, cardId, reason, orderNewCard }) {
      this.areCreditCardButtonsEnabled = false

      await api.blockCreditCard(cardId, {
        reason,
        orderNewCard
      })

      if (orderNewCard) {
        await this.getCreditCards(contractId)
      } else {
        await this.refreshCreditCard(cardId)
      }

      this.areCreditCardButtonsEnabled = true
    },
    async orderCreditCard (contractId, cardType) {
      const { id } = await api.orderCreditCard(contractId, cardType)

      return id
    },
    async getContractAdditionalDetails (contractId) {
      const details = await api.getLoanContractById(contractId)
      this.contractAdditionalDetails = details
      this.creditCardHeroHeaderData = {
        availableLimitAmount: details?.balanceDetails?.availableLimitAmount,
        reservations: details?.balanceDetails?.reservations
      }

      return details
    },
    updateCreditCardTransactionsSearchRequest (filters) {
      this.creditCardTransactionsSearchRequest = filters
    },
    async fetchCreditCardAccountStatement ({ contractId }, append = false) {
      const results = await api.getTransactionsUsingContractId(contractId, this.creditCardTransactionsSearchRequest)
      if (append) {
        this.creditCardAccountStatement.transactions.push(...results.transactions)
      } else {
        this.creditCardAccountStatement = results
      }
    },
    async fetchCreditCardTransactionsSettings () {
      const settings = await api.getTransactionsSettings([
        TransactionSettingsItem.FilteringPeriods,
        TransactionSettingsItem.FilteringAdvancedSearchOptions,
        TransactionSettingsItem.FilteringPeriodByDefault,
        TransactionSettingsItem.FilteringAdvancedSearchOptionByDefault,
        TransactionSettingsItem.FilteringDatePickerLimits
      ])

      this.creditCardTransactionsSettings = settings
      return settings
    },
    async fetchCreditCardPricing () {
      this.creditCardPricing = await api.getCreditCardPricing()
    }
  }
}
)
