/* eslint-disable @typescript-eslint/no-unused-vars */
import { register } from 'register-service-worker'
import { isError, isFunction, isString, noop } from 'lodash'
import { apm } from '@elastic/apm-rum'

const registerBroadcastChannel = () => {
  try {
    const errorBroadcastChannel = new BroadcastChannel('errors')
    errorBroadcastChannel.onmessage = message => {
      if (isError(message.data)) {
        apm.captureError(message.data)
      }
    }
  } catch (err) {
    console.warn('Receiving errors from service worker is not supported in this browser')
  }
}

export default {
  install (Vue) {
    if (!('serviceWorker' in navigator) || !navigator.cookieEnabled) {
      console.warn('Service worker not supported by browser. Please, update your browser or review privacy settings.')

      Object.defineProperty(Vue.prototype, '$sw', {
        get () {
          return {
            on: () => this.$sw,
            off: () => noop(),
            send: () => noop(),
            clean: () => noop()
          }
        }
      })

      return
    }

    registerBroadcastChannel()

    register('/sw.js', {
      offline () {
        console.log('No internet connection found. App is running in offline mode.')
      },
      error (error) {
        console.error('Error during service worker registration:', error)
      }
    })

    const store = {};

    (function installServiceWorkerListener () {
      navigator.serviceWorker.addEventListener('message', (event) => {
        const { message, ...params } = event.data

        for (const componentUid of Object.values(store)) {
          message in componentUid && componentUid[message](params)
        }
      })
    })()

    Vue.mixin({
      beforeDestroy () {
        this.$sw.clean()
      }
    })

    Object.defineProperty(Vue.prototype, '$sw', {
      get () {
        return {
          on: (message, handler) => {
            if (!isString(message) || !isFunction(handler)) {
              throw new Error('Message listener configured improperly')
            }

            if ((store[this._uid] || {})[message]) {
              throw new Error(`Handler for message ${message} listener already configured`)
            }

            store[this._uid] = store[this._uid] || {}
            store[this._uid][message] = handler

            return this.$sw
          },
          off: (message) => {
            (store[this._uid] ?? {})[message] && delete store[this._uid][message]
          },
          clean: () => {
            store[this._uid] && delete store[this._uid]
          },
          send: (message, params = {}) => {
            navigator.serviceWorker.ready.then((registration) => {
              registration.active.postMessage({
                message,
                ...params
              })
            })
          }
        }
      }
    })
  }
}
