<template>
  <div class="credit-card-activation-modal">
    <cvv-input-modal
      v-if="isCVVInputModalVisible"
      v-bind="CVVInputModalProps"
      @submit="onCVVInputModalSubmit"
      @close="onModalClosed"
    />
    <virtual-card-info-modal
      v-else-if="isVirtualCardInfoModalVisible"
      v-bind="virtualCardInfoModalProps"
      @submit="onSignAndShowPinClicked"
      @close="onModalClosed"
    />
    <credit-card-secrets-modal
      v-else-if="secretModalProps.visible"
      v-bind="secretModalProps"
      @onModalClosed="onCardSecretsModalClosed"
    />
  </div>
</template>

<script>
import { isNull } from 'lodash'
import { mapState, mapActions } from 'pinia'
import CreditCardSecretsModal from '../modals/CreditCardSecretsModal'
import VirtualCardInfoModal from '../modals/VirtualCardInfoModal'
import CvvInputModal from '../modals/CVVInputModal'
import '@bigbank/interface-components/dist/svg/illustration/cvv'
import { CREDIT_CARD } from '@/const'
import CreditCardSigningMixin from '@credit-card/components/mixins/CreditCardSigningMixin'
import { useRootStore } from '../../../../store/root'
import { useLoanStore } from '@loan/store/loanStore'

export default {
  name: 'credit-card-activation-modal',
  components: {
    CreditCardSecretsModal,
    VirtualCardInfoModal,
    CvvInputModal
  },
  mixins: [CreditCardSigningMixin],
  props: {
    visible: { required: true, type: Boolean },
    cardId: { required: true, type: Number },
    cardType: { required: true, type: String }
  },
  data () {
    return {
      isSecretsModalVisible: false,
      hasCVVValidationFailed: false,
      isLoading: true
    }
  },
  computed: {
    ...mapState(useRootStore, ['isMobile']),
    ...mapState(useLoanStore, ['creditCardContract']),
    contract () {
      return this.creditCardContract
    },
    isCVVInputModalVisible () {
      return this.visible && !this.isVirtualCard && !this.isSecretsModalVisible
    },
    isVirtualCardInfoModalVisible () {
      return this.visible && this.isVirtualCard && !this.isSecretsModalVisible
    },
    isVirtualCard () {
      return this.cardType === CREDIT_CARD.TYPE.VIRTUAL
    },
    secretModalType () {
      return this.isVirtualCard ? CREDIT_CARD.SECRETS_MODAL_STEP.SHOWING_PAN_AND_CVV : CREDIT_CARD.SECRETS_MODAL_STEP.SHOWING_PIN
    },
    secretModalSigningWay () {
      return this.isVirtualCard ? CREDIT_CARD.SECRETS_MODAL_SIGNING_WAY.THROUGH_ORDERING_VIRTUAL_CARD : CREDIT_CARD.SECRETS_MODAL_SIGNING_WAY.THROUGH_ACTIVATION
    },
    secretModalProps () {
      return {
        type: this.secretModalType,
        signingWay: this.secretModalSigningWay,
        verificationCVV: this.verificationCVV,
        cardId: this.cardId,
        visible: !this.isCVVInputModalVisible && !this.isVirtualCardInfoModalVisible,
        cardType: this.cardType
      }
    },
    virtualCardInfoModalProps () {
      return {
        visible: this.isVirtualCardInfoModalVisible,
        cardId: this.cardId,
        contractId: this.contract.id
      }
    },
    CVVInputModalProps () {
      return {
        visible: this.isCVVInputModalVisible,
        cardId: this.cardId,
        contractId: this.contract.id,
        isCVVErrorShown: this.hasCVVValidationFailed
      }
    }
  },
  methods: {
    ...mapActions(useLoanStore, [
      'getCreditCards',
      'fetchCreditCardAccountStatement'
    ]),
    setSecretsModalVisible (flag) { this.isSecretsModalVisible = flag },
    setHasCVVValidationFailed (hasFailed) { this.hasCVVValidationFailed = hasFailed },
    setLoading (flag) { this.isLoading = flag },
    onCVVInputModalSubmit (verificationCVV = null) {
      this.onSignAndShowPinClicked(verificationCVV)
    },
    onSignAndShowPinClicked (verificationCVV = null) {
      if (
        !isNull(verificationCVV) &&
        !this.isVirtualCard &&
        CREDIT_CARD.CVV_POSSIBLE_LENGTHS.includes(verificationCVV.trim().length)
      ) {
        this.verificationCVV = verificationCVV
        this.setHasCVVValidationFailed(false)
      }
      this.setSecretsModalVisible(true)
    },
    onModalClosed () {
      this.$emit('onModalClosed')
    },
    async onCardSecretsModalClosed (params = {}) {
      if (params.hasCVVCheckFailed) {
        this.setHasCVVValidationFailed(true)
        this.setSecretsModalVisible(false)
      } else {
        this.setLoading(true)

        // update cards and get contract limits and payments summary
        await Promise.all([
          this.getCreditCards(this.contract.id),
          this.fetchCreditCardAccountStatement({
            contractId: this.contract.id
          })
        ])
        this.setSecretsModalVisible(false)

        this.$emit('onModalClosed')
      }
    }
  },
  mounted () {
    if (this.hasReturnedAfterSigning()) {
      this.setSecretsModalVisible(true)
    }
  }
}
</script>

<style lang="scss">
.credit-card-activation-modal {
  .modal-title {
    color: $blue;
    font-weight: 500;
    font-size: $font-size-small;
    font-family: $gotham-medium;
  }

  .illustration-cvv {
    width: 100%;
    max-width: 205px;
    height: 160px;
    margin: 33px auto 28px;
    display: block;
  }

  .input-label {
    color: $gray;
    font-size: $font-size-small;
    font-weight: 400;
  }

  .boxed-tooltip > span {
    color: $gray;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
      cursor: pointer;
    }
  }

  .loader {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
