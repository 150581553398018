export default {
  methods: {
    getTermsLinks () {
      return {
        DE: {
          bankTermsLink: 'https://static.bigbank.de/assets/2021/07/Bigbank_Allgemeine-Vertragsbedingungen.pdf',
          termsLink: 'https://static.bigbank.de/assets/2021/07/Bigbank_DE_-_Allgemeine_geschaftsbedingungen_des_festgeldvertrages.pdf',
          demandDepositTermsLink: 'https://static.bigbank.de/assets/2021/05/ALLGEMEINE-BEDINGUNGEN-DES-TAGESGELDVERTRAGS_DE_13042020.pdf',
          basicInformationLink: 'https://static.bigbank.de/assets/2021/07/Schutz-von-Einlagen.pdf',
          dataProcessingLink: 'https://static.bigbank.de/assets/2021/04/Bigbank_Allgemeine-Verordnung-zur-Kundendatenverarbeitung.pdf'
        },
        AT: {
          bankTermsLink: 'https://static.bigbank.at/assets/2021/10/11062533/Bigbank_Allgemeine-Vertragsbedingungen.pdf',
          termsLink: 'https://static.bigbank.at/assets/2021/10/11062516/Bigbank_AT_-_Allgemeine_geschaftsbedingungen_des_festgeldvertrages.pdf',
          demandDepositTermsLink: 'https://static.bigbank.at/assets/2021/10/11062510/ALLGEMEINE-BEDINGUNGEN-DES-TAGESGELDVERTRAGS_AT_13042020.pdf',
          basicInformationLink: 'https://static.bigbank.at/assets/2021/10/11062322/Schutz-von-Einlagen.pdf',
          dataProcessingLink: 'https://static.bigbank.at/assets/2021/10/11062307/Bigbank_AT_Verordnung-zur-Bearbeitung-von-Kundendaten.pdf'
        },
        NL: {
          bankTermsLink: 'https://static.bigbank.nl/assets/2021/06/15112537/Algemene-Voorwaarden-van-Bigbank-AS.pdf',
          termsLink: 'https://static.bigbank.nl/assets/2021/06/15112537/Algemene-Voorwaarden-van-Bigbank-AS.pdf',
          demandDepositTermsLink: 'https://static.bigbank.nl/assets/2022/08/Algemene-Voorwaarden-van-de-Flexibel-Sparen-Overeenkomst.pdf',
          basicInformationLink: 'https://static.bigbank.nl/assets/2021/06/15140736/Depositogarantiestelsel.pdf',
          dataProcessingLink: 'https://static.bigbank.nl/assets/2021/06/15140450/Beginselen-voor-de-verwerking-van-clientgegevens-van-Bigbank-AS.pdf'
        },
        SE: {
          bankTermsLink: 'https://static.bigbank.se/assets/2021/02/19121445/Allmnna-villkor-for_Bigbank-1.pdf',
          termsLink: 'https://static.bigbank.se/assets/2021/02/19121446/Allmanna-villkor_fastranteplacering_1.7.2018-1.pdf',
          demandDepositTermsLink: '',
          basicInformationLink: '',
          dataProcessingLink: 'https://static.bigbank.se/assets/2021/02/19121448/Principles_of_personal_data_processing.pdf'
        },
        EE: {
          bankTermsLink: 'https://static.bigbank.ee/assets/2022/10/BB_uldtingimused_15_02_2017.pdf',
          termsLink: 'https://static.bigbank.ee/assets/2022/10/EE_tahtajalise_hoiuse_uldtingimused_01052019.pdf',
          demandDepositTermsLink: 'https://static.bigbank.ee/assets/2023/09/14151316/EE_kogumishoiuse_uldtingimused.pdf',
          basicInformationLink: 'https://static.bigbank.ee/assets/2022/10/Hoiustaja-teabeleht.pdf',
          dataProcessingLink: 'https://static.bigbank.ee/assets/2022/10/Bigbank_AS_kliendiandmete_tootlemise_pohimotted_-EE.pdf'
        },
        LV: {
          bankTermsLink: 'https://static.bigbank.lv/assets/2023/03/Bankas-visparejie-noteikumi.pdf',
          termsLink: 'https://static.bigbank.lv/assets/2023/03/Bankas-visparejie-noteikumi.pdf',
          demandDepositTermsLink: '',
          basicInformationLink: '',
          dataProcessingLink: 'https://static.bigbank.lv/assets/2021/06/LV-Principles_of_processing_customer_data_of_BIGBANK_AS_LV_13.11.2020.pdf'
        },
        LT: {
          bankTermsLink: 'https://static.bigbank.lt/assets/2021/04/13133252/BENDROSIOS-BIGBANK-AS-FILIALO-PASLAUGU-TEIKIMO-SALYGOS.pdf',
          termsLink: '',
          demandDepositTermsLink: '',
          basicInformationLink: '',
          dataProcessingLink: 'https://static.bigbank.lt/assets/2021/03/04141823/Klientu-duomenu-tvarkymo-principai-1.pdf'
        },
        FI: {
          bankTermsLink: 'https://bigbank.fi/dokumentit?main=14&sec&doc=1208',
          termsLink: 'https://www.bigbank.fi/dokumentit/?main=14&sec&doc=2708',
          demandDepositTermsLink: '',
          basicInformationLink: 'https://www.bigbank.fi/dokumentit/?main=34&sec&doc=2720',
          dataProcessingLink: 'https://www.bigbank.fi/dokumentit/?main=67&sec&doc=2710'
        },
        BG: {
          bankTermsLink: 'https://www.bigbank.bg/pravni-dokumenti?main=36&sec&doc=2347',
          termsLink: 'https://static.bigbank.bg/assets/2022/02/BG-GT-Term-Deposit-Agreement-01-11-2021.pdf',
          demandDepositTermsLink: '',
          basicInformationLink: 'https://static.bigbank.bg/assets/2022/02/BG-Depositor-Information-Sheet-22-02-2021.pdf',
          dataProcessingLink: 'https://static.bigbank.bg/assets/2021/06/BG_-_Principles_of_Processing_Customer_Data_of_Bigbank_AS_-_Branch_Bulgaria_201130.pdf'
        }
      }[window.CHANNEL] || {}
    }
  }
}
