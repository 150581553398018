<script>
import { api } from '@deposits/api'
import TransactionsList from '../../../components/TransactionsList.vue'
import transactionStatuses from '../../../types/transaction-status'
import transactionTypes from '../../../types/transactions'
import AccountType from '../../../types/account.type'
import { uniqBy } from 'lodash'
import { DepositType, DepositRouteName } from '@deposits/const'

export default {
  name: 'deposit-transactions',
  extends: TransactionsList,
  props: {
    deposit: {
      type: Object,
      required: false
    },
    deposits: {
      type: Array,
      required: false
    }
  },
  data () {
    return {
      agreementTypeCodes: [AccountType.Deposit, AccountType.Current]
    }
  },
  computed: {
    showAccountInfoCard () {
      return true
    },
    emptyTransactionsText () {
      return this.$pgettext('deposit_dashboard', 'As soon as you have transactions, they will be available here.')
    },
    migrationDate () {
      const deposits = []

      if (this.deposits && this.deposits.length > 0) {
        deposits.push(...this.deposits)
      } else if (this.deposit) {
        deposits.push(this.deposit)
      }

      if (deposits.length > 0) {
        return (deposits.find(({ isMigrated }) => isMigrated) || {}).migrationDate
      }

      return false
    },
    visibleAccountIds () {
      return uniqBy(Array.isArray(this.deposits) ? this.deposits : [], deposit => deposit.currentAccountId)
        .map(deposit => deposit.currentAccountId)
    },
    translations () {
      return {
        ...this.defaultTranslations,
        accountCard: {
          ...this.defaultTranslations.accountCard,
          tooltip: this.$pgettext('deposit_account_card', 'This is your deposit servicing account, where you can see all transactions, based on your deposit agreement.')
        }
      }
    },
    areFiltersShownLocal () {
      return !(this.$route.name === DepositRouteName.TermDeposits && this.$route.params.status !== 'transactions')
    }
  },
  methods: {
    async loadTransactions () {
      // for demand or term deposits
      if (this.deposit) {
        return await this.getTransactionsForDeposit(this.deposit)
      }

      // for term deposits
      if (this.deposits) {
        return await this.getTransactionsForTermDeposits()
      }

      return []
    },
    async getTransactionsForTermDeposits () {
      const searchParams = await this.getFilterPerType(this.deposits[0])

      return api.searchTermDepositTransactions(searchParams)
    },
    async getTransactionsForDeposit (deposit) {
      const filter = await this.getFilterPerType(deposit) || this.filter

      return api.searchTransactions(deposit.id, filter)
    },
    async getFilterPerType (deposit) {
      switch (deposit.depositType) {
        case DepositType.Demand:
          return {
            includeReserved: this.includeReservedTransactions,
            reserved: this.filter.transactionStatus === transactionStatuses.RESERVED || undefined
          }
        case DepositType.Term:
        default:
          return {
            transactionTypeCodes: [
              transactionTypes.SEPA2ACC_PAY,
              transactionTypes.ACC2SEPA_PAY,
              transactionTypes.ACC2DEPOTAX,
              transactionTypes.ACC2DEPOFEE
            ]
          }
      }
    }
  },
  watch: {
    deposit (current, previous) {
      if (!previous || current.id !== previous.id) {
        this.refresh()
      }
    }
  },
  created () {
    window.eventBus.$on('demand-deposit__withdraw', this.refresh)
  },
  beforeDestroy () {
    window.eventBus.$off('demand-deposit__withdraw', this.refresh)
  }
}
</script>
