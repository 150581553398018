<template>
  <layout :loading="loading">
    <bb-container class="tax-reports" fluid v-if="!loading">
      <bb-row no-gutters>
        <bb-col md="12" lg="11">
          <section-box :title="translations.documents" icon="client-pdf">
            <translate>Here you can access and download your tax reports.</translate>
          </section-box>
        </bb-col>
        <bb-col md="12" lg="13" class="tax-reports__container">
          <bb-list-group>
            <bb-list-item
              condensed
              class="tax-reports__report-item"
              @click.native="generate(report)"
              v-for="(report, index) in depositTaxReports"
              :noBottomBorder="!index"
              :key="report.year"
              :titleUppercase="false"
              :title="report.title">
              <bb-icon class="m-r-10" v-if="!report.generating" size="24" fill="blue" name="ui-download" />
              <bb-spinner v-else small />
            </bb-list-item>
          </bb-list-group>
        </bb-col>
      </bb-row>
    </bb-container>
  </layout>
</template>

<script>
import Layout from './Layout'
import SectionBox from '@account/components/SectionBox'
import api from '@account/api'
import { SETTINGS_VIEW } from '@/TrackingActions'
import { mapState, mapActions } from 'pinia'
import { useAccountStore } from '@account/store/accountStore'
import '@bigbank/interface-components/dist/svg/client/pdf'
import '@bigbank/interface-components/dist/svg/ui/download'

export default {
  name: 'account-documents',
  components: { Layout, SectionBox },
  data () {
    return {
      loading: true,
      activeReport: null,
      printoutList: null,
      translations: {
        documents: this.$pgettext('section_title', 'Your Reports'),
        downloadLabel: this.$pgettext('tax_report_download_link', '% - Download your report')
      }
    }
  },
  computed: {
    ...mapState(useAccountStore, ['details']),
    depositTaxReports () {
      return this.printoutList.depositInterestCertificates.map(item => {
        item.title = this.translations.downloadLabel.replace('%', item.year)
        item.generating = this.activeReport && this.activeReport.year === item.year
        return item
      })
    }
  },
  methods: {
    ...mapActions(useAccountStore, ['getDetails']),
    async generate (item) {
      if (this.activeReport) {
        return
      }

      this.activeReport = item
      const downloadResponse = await api.generateDepositTaxReport(item.year)
      this.$tracker.action(SETTINGS_VIEW.DOWNLOAD_TAX_REPORT)
      api.downloadTaxReport(downloadResponse)
      this.activeReport = null
    }
  },
  async mounted () {
    this.loading = true
    this.printoutList = await api.getPrintoutList()
    this.loading = false
  }
}
</script>

<style lang="scss">
.tax-reports {
  &__container {
    margin-top: 25px;

    @media (min-width: $desktop-view-breaking-point) {
      margin: 0;
    }
  }

  &__report-item {
    cursor: pointer;

    .bb-spinner {
      height: 21px;
      width: 21px;
    }
  }
}
</style>
