<template>
  <signing-modal-wrapper
    :visible="visible"
    :showLoader="isLoading"
    :hideClose="!canCloseModal"
    @close="onClose"
    class="evrotrust-modal"
  >
    <template v-if="pending">
      <div slot="heading">
        <h3 class="evrotrust-modal__title">{{translations.modalTitle}}</h3>
      </div>
      <div class="evrotrust-modal__content" slot="default">
        <div class="evrotrust-modal__logo">
          <bb-logo :logo="evrotrustLogo" alt="" fluid></bb-logo>
        </div>
        <p class="evrotrust-modal__info">{{translations.modalInfoText}}</p>
        <div class="evrotrust-modal__spinner">
          <bb-spinner :size="45"></bb-spinner>
        </div>
      </div>
      <div slot="footer" class="evrotrust-modal__cancel">
        <bb-button
          v-if="canCloseModal"
          @click="cancel"
          display="link"
          color="gray"
          :label="modalButtonText"
        />
      </div>
    </template>
    <template v-else-if="errorCode">
      <div slot="heading">
        <h3 class="evrotrust-modal__title">{{translations.modalTitle}}</h3>
      </div>
      <div class="evrotrust-modal__content" slot="default">
        <div class="evrotrust-modal__error-icon">
          <bb-icon fill="original" name="illustration-exclamation-mark"></bb-icon>
        </div>
        <div class="evrotrust-modal__error">
          <span v-sanitize.basic="errorMessage" />
        </div>
      </div>
      <div slot="footer">
        <bb-button
          @click="cancel"
          display="block"
          :label="modalButtonText"
        />
      </div>
    </template>
    <template v-else>
      <div class="evrotrust-modal__content" slot="default">
        <div class="evrotrust-modal__success-icon">
          <bb-icon name="ui-check-circle" size="fluid" fill="green"></bb-icon>
        </div>
        <div class="evrotrust-modal__success-title">{{translations.modalTitleSuccess}}</div>
        <div class="evrotrust-modal__success-text">{{translations.modalRedirectToText}}<br><b>{{translations.modalSelfServiceText}}</b></div>
        <div class="evrotrust-modal__success-spinner">
          <bb-spinner />
        </div>
      </div>
    </template>
  </signing-modal-wrapper>
</template>

<script>
import '@bigbank/interface-components/dist/svg/illustration/exclamation-mark'
import '@bigbank/interface-components/dist/svg/ui/check-circle'
import { isMobile } from '@bigbank/dc-common/util'
import SigningModalWrapper from './SigningModalWrapper'
import { SIGNING_VIEW } from '@/TrackingActions'
import evrotrustLogo from '../../images/evrotrust-logo.png'
import { sleep } from '@/plugins/utils'
import { evrotrustErrorCodes } from '../utils/evrotrustErrorCodes.enum'

export default {
  name: 'evrotrust-signing',
  components: { SigningModalWrapper },
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false
    },
    startSigning: {
      required: true,
      type: Function
    },
    completeSigning: {
      required: true,
      type: Function
    }
  },
  data () {
    return {
      translations: {
        modalTitle: this.$pgettext('sign_evrotrust', 'Sign with Evrotrust'),
        modalTitleSuccess: this.$pgettext('sign_evrotrust', 'Successfully Signed'),
        modalInfoText: this.$pgettext('sign_evrotrust', 'To sign, open your Evrotrust application on your mobile and verify that you are you.'),
        modalRedirectToText: this.$pgettext('sign_evrotrust', 'We are now redirecting you to'),
        modalSelfServiceText: this.$pgettext('sign_evrotrust', 'Bigbank Self-Service.'),
        defaultModalErrorText: this.$pgettext('sign_evrotrust', 'Signing with Evrotrust is not possible. Please check that you have Evrotrust app and you have completed necessary actions to use the app.'),
        modalButtonCancelText: this.$pgettext('sign_evrotrust', 'Cancel'),
        modalButtonTryAgainText: this.$pgettext('sign_evrotrust', 'Try Signing Again'),
        errors: {
          [evrotrustErrorCodes.TIMEOUT]: this.$pgettext('error_evrotrust_signing', 'TIMEOUT'),
          [evrotrustErrorCodes.INVALID_CERTIFICATE]: this.$pgettext('error_evrotrust_signing', 'INVALID_CERTIFICATE'),
          [evrotrustErrorCodes.INTERNAL_ERROR]: this.$pgettext('error_evrotrust_signing', 'INTERNAL_ERROR'),
          [evrotrustErrorCodes.USER_CANCELLED]: this.$pgettext('error_evrotrust_signing', 'USER_CANCELLED'),
          [evrotrustErrorCodes.END_PROVIDER_MISCONFIGURATION]: this.$pgettext('error_evrotrust_signing', 'END_PROVIDER_MISCONFIGURATION'),
          [evrotrustErrorCodes.DEVICE_ERROR]: this.$pgettext('error_evrotrust_signing', 'DEVICE_ERROR'),
          [evrotrustErrorCodes.WRONG_VERIFICATION_CODE]: this.$pgettext('error_evrotrust_signing', 'WRONG_VERIFICATION_CODE'),
          [evrotrustErrorCodes.PROVIDER_ERROR]: this.$pgettext('error_evrotrust_signing', 'PROVIDER_ERROR'),
          [evrotrustErrorCodes.SIGNER_CERTIFICATE_REVOKED]: this.$pgettext('error_evrotrust_signing', 'SIGNER_CERTIFICATE_REVOKED'),
          [evrotrustErrorCodes.SIGNER_CERTIFICATE_UNKNOWN]: this.$pgettext('error_evrotrust_signing', 'SIGNER_CERTIFICATE_UNKNOWN'),
          [evrotrustErrorCodes.INVALID_AUTHENTICATION]: this.$pgettext('error_evrotrust_signing', 'INVALID_AUTHENTICATION')
        }
      },
      evrotrustLogo,
      statusPolling: null,
      errorCode: null,
      pending: false,
      isLoading: false,
      isUserSentToTheEvrotrustApp: false
    }
  },
  mounted () {
    this.initSigning()
  },
  beforeDestroy () {
    clearTimeout(this.statusPolling)
  },
  computed: {
    modalButtonText () {
      if (this.pending) {
        return this.translations.modalButtonCancelText
      } else if (this.errorCode) {
        return this.translations.modalButtonTryAgainText
      }

      return ''
    },
    errorMessage () {
      return this.translations.errors[this.errorCode] || this.translations.defaultModalErrorText
    },
    canCloseModal () {
      return !this.pending
    }
  },
  methods: {
    cancel (e) {
      if (e && e.preventDefault) {
        e.preventDefault()
      }
      this.pending = false
      this.errorCode = null
      this.errorCodeId = null
      if (this.statusPolling) {
        clearTimeout(this.statusPolling)
        this.statusPolling = null
      }

      this.$emit('close')
    },
    async initSigning () {
      try {
        this.errorCode = null
        this.isLoading = true
        this.$tracker.action(SIGNING_VIEW.INIT_SIGNING)
        const result = await this.startSigning()
        this.errorHandler(result)
        this.isLoading = false
        this.pending = true
        this.errorCodeId = null
        this.errorCode = null
        await this.pollStatus()
      } catch (err) {
        this.isLoading = false
        this.errorCode = err.err_code
      }
    },
    pollStatus (delay = 3000) {
      this.pending = true

      this.statusPolling = setTimeout(async () => {
        try {
          const result = await this.completeSigning()
          switch (result.status) {
            case 'COMPLETED_OK':
              this.pending = false
              await sleep(2500)
              this.$emit('signingSuccessful')
              break
            case 'RUNNING':
              if (!this.statusPolling) {
                return
              }
              this.openEvrotrustMobileApp()
              await this.pollStatus(delay)
              break
          }
        } catch (err) {
          this.pending = false
          this.isLoading = false
          this.errorCode = err.err_code
          this.$emit('signingError', err)
        }
      }, delay)
    },
    errorHandler (response) {
      if (response && response.err_code) {
        throw new Error(response.err_code)
      } else if (!response) {
        throw new Error('No response')
      }
    },
    onClose (e) {
      this.cancel(e)
    },
    openEvrotrustMobileApp () {
      if (!isMobile()) {
        return
      }

      if (this.isUserSentToTheEvrotrustApp) {
        return
      }

      this.isUserSentToTheEvrotrustApp = true
      document.location = 'evrotrust://'
    }
  }
}
</script>

<style lang="scss" scoped>
.evrotrust-modal {
  &__content {
    @media (min-width: $desktop-view-breaking-point) {
      min-height: 380px;
    }
  }

  &__title {
    text-align: center;
    font-family: $gotham-medium;
    font-size: $font-size-small;
    color: $blue;
    margin-top: 7px;
    margin-bottom: 0;
  }

  &__logo {
    text-align: center;
    height: 50px;
    margin-top: 5px;
  }

  &__info {
    text-align: center;
    margin-top: 35px;
    color: $gray-70;
    font-family: $gotham-medium;
    font-size: $font-size-small;
  }

  &__error {
    &-icon {
      margin: auto;
      margin-top: 0;
      height: 40px;
      width: 40px;
    }

    margin-top: 45px;
    text-align: center;
    font-family: $gotham-medium;
    font-size: $mobile-default-font-size;
    color: $red;

    @media (min-width: $desktop-view-breaking-point) {
      margin-top: 33px;
      font-size: $font-size-small;
    }
  }

  &__success {
    &-icon {
      margin: auto;
      margin-top: 0;
      height: 60px;
      width: 60px;
    }

    &-title {
      text-align: center;
      font-family: $gotham-bold;
      font-size: $h4-size;
      color: $navy;
      margin-top: 30px;
    }

    &-text {
      text-align: center;
      font-family: $gotham-medium;
      font-size: $font-size-small;
      color: $gray-70;
      margin-top: 25px;
    }

    &-spinner {
      text-align: center;
      margin-top: 50px;

      > div {
        margin: auto;
      }
    }
  }

  &__spinner {
    text-align: center;
    margin-top: 60px;

    > div {
      margin: auto;
    }
  }

  &__cancel {
    text-align: center;
    padding-bottom: 27px;
    padding-top: 20px;
  }
}
</style>
