<template>
  <div class="prolongation-radio-card__main-container">
    <div class="prolongation-radio-card__info-container">
      <div class="prolongation-radio-card__button"><div class="prolongation-radio-card__button-dot"></div></div>
      <div class="prolongation-radio-card__info-text" :class="{ 'prolongation-radio-card__info-text__limit-length': hasAmount }">
        {{ infoText + ':' }}
      </div>
    </div>
    <div v-if="hasAmount" class="prolongation-radio-card__amount">
      {{ formattedAmount }}
    </div>
  </div>
</template>

<script>
import { formatMoneyWithCurrency } from '@/plugins/numformat'

export default {
  name: 'prolongation-radio-card',
  props: {
    infoText: {
      type: String,
      required: true
    },
    amount: {
      type: Number,
      required: false
    },
    currencyCode: {
      type: String,
      required: true
    },
    locale: {
      type: String,
      required: true
    }
  },
  computed: {
    hasAmount () {
      return !!this.amount
    },
    formattedAmount () {
      return formatMoneyWithCurrency(this.amount, this.currencyCode, this.locale)
    }
  }
}
</script>

<style lang="scss">
  .prolongation-radio-card {
    &__main-container {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 2px solid $gray-30;
      border-radius: 10px;
      width: 100%;
      height: 60px;
      max-width: 380px;

      &.active {
        border: 2px solid $blue;
      }
    }

    &__info-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-left: 16px;
    }

    &__button {
      display: flex;
      width: 20px;
      height: 20px;
      border: 2px solid $gray-50;
      border-radius: 50%;

      .active &-dot {
        display: block;
        width: 8px;
        height: 8px;
        background-color: $mint;
        border-radius: 50%;
        margin: auto;
      }
    }

    &__info-text {
      font-family: $gotham-medium;
      font-size: $mobile-small-font-size;
      color: $gray-90;
      margin-left: 13px;

      @media (min-width: $desktop-view-breaking-point-wide) {
        font-size: $font-size-small;
      }

      &__limit-length {
        max-width: 100px;

        @media (min-width: $desktop-view-breaking-point-wide) {
          max-width: 160px;
        }
      }
    }

    &__amount {
      font-family: $gotham-bold;
      font-size: $mobile-h3-size;
      color: $blue;
      margin-right: 15px;

      @media (min-width: $desktop-view-breaking-point-wide) {
        font-size: $h4-size;
        margin-left: 20px;
      }
    }
  }
</style>
