<template>
  <div class="document-list">
    <div class="document-container" v-for="(document, key) in documents" :key="key">
      <bb-input name="documentType" type="text" :label="translations.type" :value="documentType(document.type)" readonly />
      <bb-input name="documentNumber" type="text" :label="translations.documentNr" :value="document.number" readonly />
      <bb-input
        name="documentValidity"
        type="text"
        :label="translations.documentValidity"
        :value="formatValidTo(document.validTo)"
        :class="{'expire-warning': showExpireWarning(document)}"
        readonly />
      <bb-input
        name="documentStatus"
        class="document-status"
        type="text"
        :label="translations.documentStatus"
        :value="status(document)"
        readonly />
      <div class="comment" v-if="showComment(document)">
        <bb-icon
          class="comment__icon"
          name="ui-alert-circle-outline"
          size="24"
          fill="yellow"
        />
        <span>{{statusHelp(document)}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate } from '@/plugins/dateFormatters'
import '@bigbank/interface-components/dist/svg/ui/alert-circle-outline'
import {
  IdDocumentStatusCode,
  IdDocumentTypeCode
} from '@bigbank/dc-common/clients/http/crm-service/crm-service.enums'

export default {
  name: 'document-list',
  props: {
    documents: {
      required: true
    }
  },
  data () {
    return {
      translations: {
        documents: this.$pgettext('section_title', 'Documents'),
        type: this.$pgettext('form', 'Type of document'),
        documentNr: this.$pgettext('form', 'Document Number'),
        documentValidity: this.$pgettext('form', 'Document Validity'),
        documentStatus: this.$pgettext('form', 'Document Status'),
        documentTypes: {
          [IdDocumentTypeCode.Passport]: this.$pgettext('document_type', 'Passport'),
          [IdDocumentTypeCode.IdCard]: this.$pgettext('document_type', 'ID Card'),
          [IdDocumentTypeCode.ForeignPassport]: this.$pgettext('document_type', 'Foreign citizen passport'),
          [IdDocumentTypeCode.DiplomaticPassport]: this.$pgettext('document_type', 'Diplomatic passport'),
          [IdDocumentTypeCode.DriversLicense]: this.$pgettext('document_type', 'Drivers license'),
          [IdDocumentTypeCode.AliensPassport]: this.$pgettext('document_type', 'Aliens passport'),
          [IdDocumentTypeCode.BirthCertificate]: this.$pgettext('document_type', 'Birth certificate'),
          [IdDocumentTypeCode.PermanentResidencePermitCard]: this.$pgettext('document_type', 'Permanent residence permit card'),
          [IdDocumentTypeCode.TemporaryResidencePermitCard]: this.$pgettext('document_type', 'Temporary residence permit card')
        },
        statuses: {
          aboutToExpire: this.$pgettext('document_status', 'Document is about to expire'),
          [IdDocumentStatusCode.WaitingAcceptance]: this.$pgettext('document_status', 'Waiting for verification'),
          [IdDocumentStatusCode.Valid]: this.$pgettext('document_status', 'Document is valid')
        },
        statusHelp: {
          pending: this.$pgettext('document_status_help_text', 'Your document is currently being verified'),
          expiring: this.$pgettext('document_status_help_text', 'Please update your document which will be expiring soon')
        }
      }
    }
  },
  methods: {
    documentType (type) {
      return this.translations.documentTypes[type] || type
    },
    formatValidTo (date) {
      return formatDate(date)
    },
    showExpireWarning (document) {
      return document.daysToExpire !== null && document.daysToExpire <= 30
    },
    showComment (document) {
      return document.isPendingAcceptance || this.showExpireWarning(document)
    },
    status (document) {
      if (this.showExpireWarning(document)) {
        return this.translations.statuses.aboutToExpire
      }
      return this.translations.statuses[document.status]
    },
    statusHelp (document) {
      if (document.isPendingAcceptance) {
        return this.translations.statusHelp.pending
      } else if (this.showExpireWarning(document)) {
        return this.translations.statusHelp.expiring
      }
      return ''
    }
  }
}
</script>

<style lang="scss">
.document-list {
  .comment {
    margin-top: 20px;

    &__icon {
      margin-left: -2px;
    }

    span {
      padding-left: 8px;
      font-size: $font-size-smallest;
      color: $gray-70;
    }
  }

  .document-container {
    &:not(:last-child) {
      border-bottom: 1px dashed $gray-50;
      padding-bottom: 25px;
    }

    &:not(:first-child) {
      padding-top: 15px;
    }

    .expire-warning {
      input {
        color: $red;
        padding-left: 26px;
      }

      .input-addon-wrapper {
        left: 0;

        .validation-error-icon {
          display: block;
          color: $red;
        }
      }
    }
  }
}
</style>
