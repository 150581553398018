// eslint-disable-next-line @typescript-eslint/no-var-requires
const isEmpty = require('lodash/isEmpty')

const withdrawalAmountValidator = ({ minError, maxError, formatter }) => ({
  getMessage: (_field, _args, data) => {
    return data.message
  },
  validate: (value, args) => {
    let triggeredRule = null
    let message = null

    if (parseFloat(value) < args.min) {
      triggeredRule = 'min'
      message = minError.replace('{amount}', formatter(args.min))
    } else if (parseFloat(value) > args.max) {
      triggeredRule = 'max'
      message = maxError.replace('{amount}', formatter(args.max))
    }

    return Promise.resolve({
      valid: !triggeredRule,
      data: {
        message
      }
    })
  }
})

function areAccountInterestParametersValid (params) {
  return (params.amount >= params.minAmount && params.amount <= params.maxAmount) &&
    (params.period >= params.minPeriod && params.period <= params.maxPeriod) &&
    ['DAYS', 'MONTHS', 'YEARS'].includes(params.periodCode) &&
    ['END', 'YEAR', 'MONTH'].includes(params.paymentFrequencyCode) &&
    !isEmpty(params.startDate) &&
    ['DC', 'D'].includes(params.agreementTypeCode)
}

module.exports = {
  withdrawalAmountValidator,
  areAccountInterestParametersValid
}
