<template>
    <FullPage :loading="loading">
        <ProgressHeader
            v-bind="progress"
            :statusText="statusText"
            @close="$emit('close')"
        />
        <slot />
    </FullPage>
</template>

<script>
import FullPage from './FullPage'
import ProgressHeader from '@/components/ProgressHeader'

export default {
  name: 'layout-with-progress',
  components: {
    FullPage,
    ProgressHeader
  },
  props: {
    loading: {
      type: Boolean
    },
    progress: {
      type: Object,
      default: () => ({
        max: 3,
        value: 0
      })
    },
    statusText: {
      type: String
    }
  }
}
</script>
