<template>
  <layout :loading="loading">
    <bb-container fluid v-if="!loading">
      <bb-form @submit="goToNextStep">
        <bb-row no-gutters class="m-b-50">
          <bb-col md="12" lg="11">
            <section-box :title="translations.about" icon="client-personal">
              <translate>If you need to update your name, ID number or born information, please contact us.</translate>
            </section-box>
          </bb-col>
          <bb-col md="12" lg="13">
            <text-display :label="translations.labels.name" :value="customerName" />
            <bb-input
              v-if="identificationCode.isVisible"
              name="identificationCode"
              type="text"
              :label="translations.labels.idCode"
              :value="form.identificationCode"
              :readonly="!identificationCode.isEditable"></bb-input>
            <bb-input name="bornIn" type="text" :label="translations.labels.bornIn" :value="bornIn" readonly></bb-input>
            <text-display :label="translations.labels.nationality" :value="citizenCountrycode.value" />
            <text-display :label="translations.labels.dualCitizenCountryCode" :value="dualCitizenCountryCode.value" />
          </bb-col>
        </bb-row>
        <bb-row no-gutters class="m-b-50">
          <bb-col md="12" lg="11">
            <section-box :title="translations.additionalData" icon="client-details">
              <translate>Update your personal data if needed.</translate>
            </section-box>
          </bb-col>
          <bb-col md="12" lg="13">
            <bb-select
                name="taxResidencyCountryCode"
                key="taxResidencyCountryCode"
                v-validate="taxResidencyCountryCode.validationRules"
                v-if="taxResidencyCountryCode.isVisible"
                :label="translations.labels.taxResidency"
                :data-vv-as="translations.labels.taxResidency"
                v-model="form.taxResidencyCountryCode"
                :options="translatedCountryListWithExcludedStateless"
                data-vv-validate-on="change"
                :help-active="true"
                :help-text="translations.taxResidencyTooltip"
                @change="onTaxResidencyCountryCodeChanged"
                :readonly="!taxResidencyCountryCode.isEditable"
            />
            <bb-masked-input
              name="taxPayerCode"
              key="taxPayerCode"
              v-validate="TIN.validationRules"
              v-if="TIN.isVisible && TIN.isMaskUsed"
              :data-vv-as="translations.labels.taxPayerCode"
              :label="translations.labels.taxPayerCode"
              v-model="form.taxPayerCode"
              :mask="TIN.mask"
              :readonly="!TIN.isEditable"
            />
            <bb-input
              name="taxPayerCode"
              key="taxPayerCode"
              v-validate="TIN.validationRules"
              v-if="TIN.isVisible && !TIN.isMaskUsed"
              :data-vv-as="translations.labels.taxPayerCode"
              :label="translations.labels.taxPayerCode"
              v-model="form.taxPayerCode"
              :readonly="!TIN.isEditable"
            />
            <bb-select
              v-validate="'required'"
              name="activityCode"
              key="activityCode"
              :data-vv-as="translations.labels.workSituation"
              :label="translations.labels.workSituation"
              v-model="form.activityCode"
              :options="activityCodeOptions"
              data-vv-validate-on="change"
              :readonly="!activityField.isEditable"
            />
            <bb-input
              v-if="activityField.isClarificationRequired"
              v-validate="'required'"
              name="activitySpecified"
              key="activitySpecified"
              :data-vv-as="translations.labels.activitySpecified"
              :label="translations.labels.activitySpecified"
              v-model="form.activitySpecified"
              :readonly="!isDataEditingAllowed"
            />
            <bb-checkbox
              name="isNotPoliticallyExposed"
              :label="translations.labels.isPep"
              v-model="form.isNotPoliticallyExposed"
              data-vv-validate-on="change"
              :disabled="!isDataEditingAllowed"
            >
              <bb-tooltip class="pull-right nm-r-10">
                <span v-sanitize.basic="translations.isPepTooltip" />
              </bb-tooltip>
              <p v-sanitize.basic="translations.labels.isPep"/>
            </bb-checkbox>
          </bb-col>
        </bb-row>
        <bb-row no-gutters>
          <bb-col md="12" lg="11">
            <section-box :title="translations.contactData" icon="client-contact">
              <translate>Update your contact data if needed.</translate>
            </section-box>
          </bb-col>
          <bb-col md="12" lg="13" class="m-b-40">
            <bb-input
              v-validate="'required|email'"
              name="email"
              key="email"
              :data-vv-as="translations.labels.email"
              :label="translations.labels.email"
              v-model="form.email"
              data-vv-validate-on="input|change|blur"
              :readonly="!isDataEditingAllowed"
            />
            <pending-change-notice v-if="hasPendingEmailConfirmation" />
            <bb-prefixed-input
              v-validate="'required|phoneNumberPrefix|phoneNumberSuffix'"
              :data-vv-as="translations.labels.phone"
              :label="translations.labels.phone"
              :prefixes="phoneAreaCodes"
              :prefix="phoneAreaCode"
              :disableSearch="disablePhoneNumberPrefix"
              separator="|"
              name="fullPhoneNumber"
              key="fullPhoneNumber"
              v-model="form.fullPhoneNumber"
              data-vv-validate-on="input|change|blur"
              type="tel"
              :disabled="!isDataEditingAllowed"
            />
            <bb-input
              v-if="showRegistrationAddress"
              v-validate="'required'"
              name="registrationStreetAddress"
              key="registrationStreetAddress"
              :data-vv-as="translations.labels.street"
              :label="translations.labels.street"
              v-model="form.registrationAddress.streetAddress"
              :readonly="!isRegistrationAddressEditable"
              :helpText="translations.registrationAddresNotEditableTootlip"
              :helpActive="!registrationAddressSettings.isEditable"
            />
            <bb-input
              v-if="showRegistrationAddress"
              v-validate="'required'"
              name="registrationPostocde"
              key="registrationPostcode"
              :data-vv-as="translations.labels.postalCode"
              :label="translations.labels.postalCode"
              v-model="form.registrationAddress.postcode"
              :readonly="!isRegistrationAddressEditable"
            />
            <bb-input
              v-if="showRegistrationAddress"
              v-validate="'required'"
              name="registrationCity"
              key="registrationCity"
              :data-vv-as="translations.labels.city"
              :label="translations.labels.city"
              v-model="form.registrationAddress.city"
              :readonly="!isRegistrationAddressEditable"
            />
            <bb-input
              v-if="showRegistrationAddress && registrationCountyFieldSettings.visible"
              v-validate="{required: registrationCountyFieldSettings.required}"
              name="registrationCounty"
              key="registrationCounty"
              :data-vv-as="translations.labels.county"
              :label="translations.labels.county"
              v-model="form.registrationAddress.county"
              :readonly="!isRegistrationAddressEditable"
            />
            <bb-select
              v-if="showRegistrationAddress"
              name="registrationCountryCode"
              key="registrationCountryCode"
              v-validate="'required'"
              :label="translations.labels.country"
              :data-vv-as="translations.labels.country"
              v-model="form.registrationAddress.countryCode"
              :options="translatedCountryListWithExcludedStateless"
              data-vv-validate-on="change"
              :readonly="!isRegistrationAddressEditable"
            />
            <bb-checkbox
              v-if="showRegistrationAddress && showContactAddress"
              name="postalSameAsContactAddress"
              :label="translations.labels.postalSame"
              v-model="controls.postalSameAsContactAddress"
              data-vv-validate-on="change"
              :disabled="!isContactAddressEditable"
            />
            <template v-if="!controls.postalSameAsContactAddress && showContactAddress">
              <p class="m-t-50 m-b-20">{{translations.mailingAddress}}</p>
              <bb-input
                v-validate="'required'"
                name="contactStreetAddress"
                key="contractStreetAddress"
                :data-vv-as="translations.labels.mailingStreet"
                :label="translations.labels.mailingStreet"
                v-model="form.contactAddress.streetAddress"
                :readonly="!isContactAddressEditable"
              />
              <bb-input
                v-validate="'required'"
                name="contactPostocde"
                key="contractPostcode"
                :data-vv-as="translations.labels.mailingPostalCode"
                :label="translations.labels.mailingPostalCode"
                v-model="form.contactAddress.postcode"
                :readonly="!isContactAddressEditable"
              />
              <bb-input
                v-validate="'required'"
                name="contactCity"
                key="contractCity"
                :data-vv-as="translations.labels.mailingCity"
                :label="translations.labels.mailingCity"
                v-model="form.contactAddress.city"
                :readonly="!isContactAddressEditable"
              />
              <bb-input
                v-if="contactCountyFieldSettings.visible"
                v-validate="{required: contactCountyFieldSettings.required}"
                name="contactCounty"
                key="contractCounty"
                :data-vv-as="translations.labels.mailingCounty"
                :label="translations.labels.mailingCounty"
                v-model="form.contactAddress.county"
                :readonly="!isContactAddressEditable"
              />
              <bb-select
                name="contactCountryCode"
                key="contactCountryCode"
                v-validate="'required'"
                :label="translations.labels.mailingCountry"
                :data-vv-as="translations.labels.mailingCountry"
                v-model="form.contactAddress.countryCode"
                :options="translatedCountryListWithExcludedStateless"
                data-vv-validate-on="change"
                :readonly="!isContactAddressEditable"
              />
            </template>
          </bb-col>
        </bb-row>
        <template #submit="{ disabled, progress }">
          <bb-row no-gutters class="button-area">
            <bb-col md="12" lg="11"></bb-col>
            <bb-col md="12" lg="13">
              <bb-button
                type="submit"
                display="block"
                :disabled="disabled || !isDataEditingAllowed"
                :class="{ 'btn--disabled': progress < 1 }"
                class="m-t-10 m-t-xl-0 button"
              >
                <translate translate-context="button">Save Changes</translate>
              </bb-button>
            </bb-col>
          </bb-row>
        </template>
      </bb-form>
    </bb-container>
    <confirm-phone v-if="confirmPhone" v-bind="confirmPhone" v-on="confirmPhoneEvents" />
    <email-change-modal
      v-if="confirmEmail"
      :email="newEmail"
      v-on="confirmEmailEvents"
    />
  </layout>
</template>

<script>
import Vue from 'vue'
import { mapState, mapActions } from 'pinia'
import { useAccountStore } from '@account/store/accountStore'
import { getCountryInfo, titleCase } from '@/plugins/utils'
import Layout from './Layout'
import SectionBox from '@account/components/SectionBox'
import EmailChangeModal from '@account/components/EmailChangeModal'
import ConfirmPhone from '@account/components/ConfirmPhone'
import PendingChangeNotice from '@account/components/PendingChangeNotice'
import TextDisplay from '@/components/Input/TextDisplay'
import { formatDate } from '@/plugins/dateFormatters'
import '@bigbank/interface-components/dist/svg/client/personal'
import '@bigbank/interface-components/dist/svg/client/details'
import '@bigbank/interface-components/dist/svg/client/contact'
import { SETTINGS_VIEW } from '@/TrackingActions'
import { STATELESS_COUNTRY_CODE } from '../../../const'
import { CBValidator } from '@bigbank/dc-common/validators/cb/CBValidator.class'
import { CountryChannel } from '@bigbank/dc-common/config'
import {
  FormValidation,
  getFieldsOfActivityByChannel,
  isCustomFieldOfActivityRequired,
  isDeepTINValidationRequired,
  getTINInputMask,
  isTINRequired,
  isTaxResidencyFieldShownAndRequired,
  getIdentificationCodeFieldSettings,
  getCitizenCountryCodeFieldSettings,
  getRegistrationAddressFieldsSettings,
  getContactAddressFieldSettings
} from '@bigbank/dc-common/validators/validation.config'
import { getTranslatedCountryList } from '@/plugins/translations'
import { useRootStore } from '../../../store/root'

const cbValidator = new CBValidator()

export default {
  name: 'account-personal-data',
  components: {
    Layout,
    SectionBox,
    EmailChangeModal,
    ConfirmPhone,
    PendingChangeNotice,
    TextDisplay
  },
  data () {
    return {
      formValidation: FormValidation.SelfServicePersonalData,
      loading: true,
      activeStep: null,
      steps: {
        personalData: 'personal-data',
        confirmPhone: 'confirm-phone',
        confirmEmail: 'confirm-email'
      },
      controls: {
        postalSameAsContactAddress: null
      },
      form: {
        identificationCode: undefined,
        citizenCountryCode: undefined,
        taxResidencyCountryCode: undefined,
        taxPayerCode: undefined,
        activityCode: undefined,
        activitySpecified: undefined,
        dualCitizenCountryCode: undefined,
        isNotPoliticallyExposed: undefined,
        fullPhoneNumber: undefined,
        email: undefined,
        registrationAddress: {
          streetAddress: undefined,
          postcode: undefined,
          city: undefined,
          county: undefined,
          countryCode: undefined
        },
        contactAddress: {
          streetAddress: undefined,
          postcode: undefined,
          city: undefined,
          county: undefined,
          countryCode: undefined
        }
      },
      newEmail: null,
      translations: {
        mailingAddress: this.$pgettext('block_title', 'Mailing address'),
        about: this.$pgettext('section_title', 'About you'),
        additionalData: this.$pgettext('section_title', 'Additional Data'),
        contactData: this.$pgettext('section_title', 'Contact Data'),
        taxResidencyTooltip: this.$gettext('The country in which you pay taxes on'),
        registrationAddresNotEditableTootlip: this.$pgettext('form', 'This is your registry address and you cannot edit this'),
        labels: {
          name: this.$pgettext('form', 'Name'),
          idCode: this.$pgettext('form', 'Personal ID Number'),
          bornIn: this.$pgettext('form', 'Born in'),
          nationality: this.$pgettext('form', 'Nationality'),
          taxResidency: this.$pgettext('form', 'Tax Residency'),
          taxPayerCode: this.$pgettext('form', 'Tax Identification Number'),
          workSituation: this.$pgettext('form', 'Work Situation'),
          activitySpecified: this.$pgettext('form', 'Activity Specified'),
          dualNationality: this.$pgettext('form', 'I have dual nationality'),
          dualCitizenCountryCode: this.$pgettext('form', 'Dual Nationality Country'),
          isPep: this.$pgettext('form', 'I confirm, that I am not a politically exposed person'),
          email: this.$pgettext('form', 'E-mail'),
          phone: this.$pgettext('form', 'Phone number'),
          country: this.$pgettext('form', 'Country'),
          mailingCountry: this.$pgettext('form', 'Mailing Country'),
          city: this.$pgettext('form', 'City'),
          mailingCity: this.$pgettext('form', 'Mailing City'),
          postalCode: this.$pgettext('form', 'Postal Code'),
          mailingPostalCode: this.$pgettext('form', 'Mailing Postal Code'),
          street: this.$pgettext('form', 'Street'),
          mailingStreet: this.$pgettext('form', 'Mailing Street'),
          county: this.$pgettext('form', 'County'),
          mailingCounty: this.$pgettext('form', 'Mailing County'),
          postalSame: this.$pgettext('form', 'My postal address is the same as the registered address.')
        },
        workSituations: {
          STUDENT: this.$pgettext('ACTIVITY', 'Student'),
          EMPLOYEE: this.$pgettext('ACTIVITY', 'Employee'),
          UNEMPLOYED: this.$pgettext('ACTIVITY', 'Unemployed'),
          ENTREPRENEUR: this.$pgettext('ACTIVITY', 'Entrepreneur'),
          PENSIONER: this.$pgettext('ACTIVITY', 'Pensioner'),
          CONSCRIPT: this.$pgettext('ACTIVITY', 'Conscript'),
          OTHER_ACTIVITY: this.$pgettext('ACTIVITY', 'Other'),
          OTHER: this.$pgettext('ACTIVITY', 'Other'),
          INDIVIDUAL_ACTIVITY: this.$pgettext('ACTIVITY', 'Individual'),
          PUBLIC_SECTOR: this.$pgettext('ACTIVITY', 'Public servant'),
          PRIVATE_SECTOR: this.$pgettext('ACTIVITY', 'Private sector'),
          PARENTAL_LEAVE: this.$pgettext('ACTIVITY', 'Parental leave')
        },
        validator: {
          phoneNumberPrefixError: this.$pgettext('validation', 'Please select your phone number\'s country code'),
          taxPayerCodeError: this.$pgettext('validation', 'Please enter a valid tax identification number'),
          phoneNumberError: this.$pgettext('validation', 'Please enter a valid phone number')
        },
        updatedSuccessfully: this.$pgettext('personal_data_form', 'Your data was successfully updated!'),
        phoneUpdateUnsuccessful: this.$pgettext('personal_data_form', 'Phone number update was unsuccessful.'),
        isPepTooltip: this.$pgettext('form', 'PEP_CHECKBOX_TOOLTIP_CONTENT')
      },
      confirmPhoneNumberRequestId: null,
      isEmailConfirmationRequired: false,
      hasPendingEmailConfirmation: false,
      hasOnlyPendingChanges: false
    }
  },
  computed: {
    ...mapState(useRootStore, [
      'channel',
      'permissions',
      'featureFlags',
      'isDocumentUploadSkippedInConfirmData'
    ]),
    ...mapState(useAccountStore, ['details', 'countryList']),
    isDataEditingAllowed () {
      return this.permissions?.options?.isPersonalDataEditable !== false
    },
    customerName () {
      const name = titleCase(this.details.fullName)
      const title = titleCase(this.details.titleCode)
      return [title, name].join(' ').trim()
    },
    bornIn () {
      const countryCode = this.details.placeOfBirthCountryCode
      const countryName = getCountryInfo(countryCode, this.countryList).translation || countryCode || ''
      const date = formatDate(this.details.dateOfBirth)

      return (date + ' ' + countryName).trim()
    },
    identificationCode () {
      const settings = getIdentificationCodeFieldSettings(
        this.formValidation,
        this.channel,
        this.channel,
        this.details.identificationCodeCountry
      )

      return {
        isVisible: settings.visible,
        isEditable: settings.editable && this.isDataEditingAllowed,
        isEmpty: settings.empty
      }
    },
    citizenCountryCodeSettings () {
      const settings = getCitizenCountryCodeFieldSettings(this.formValidation, this.channel)

      return {
        isVisible: settings.visible,
        isEditable: settings.editable
      }
    },
    registrationAddressSettings () {
      const settings = getRegistrationAddressFieldsSettings(this.formValidation, this.channel)

      return {
        isVisible: settings.visible,
        isEditable: settings.editable,
        fields: settings.fields
      }
    },
    contactAddressSettings () {
      const settings = getContactAddressFieldSettings(this.formValidation, this.channel)

      return {
        isVisible: settings.visible,
        isEditable: settings.editable,
        fields: settings.fields
      }
    },
    citizenCountrycode () {
      return {
        value: this.getTranslatedCountryList().find((country) => {
          return country.value === this.form.citizenCountryCode
        })?.text ?? '-'
      }
    },
    dualCitizenCountryCode () {
      return {
        value: this.getTranslatedCountryList([STATELESS_COUNTRY_CODE]).find((country) => {
          return country.value === this.form.dualCitizenCountryCode
        })?.text ?? '-'
      }
    },
    translatedCountryList () {
      return this.getTranslatedCountryList()
    },
    translatedCountryListWithExcludedStateless () {
      return this.getTranslatedCountryList([STATELESS_COUNTRY_CODE])
    },
    activityCodeOptions () {
      return getFieldsOfActivityByChannel(this.formValidation, this.channel).map(fieldOfActivity => ({
        text: this.translations.workSituations[fieldOfActivity] ?? '',
        value: fieldOfActivity
      }))
    },
    disablePhoneNumberPrefix () {
      return [CountryChannel.BG].includes(this.channel)
    },
    phoneAreaCodes () {
      return !this.disablePhoneNumberPrefix
        ? this.translatedCountryList
          .filter(({ areaCode, areaCodeAllowed }) => !!areaCode && areaCodeAllowed)
          .map(({ text, value, areaCode }) => ({
            value: areaCode,
            alpha2: value,
            name: text,
            label: `${areaCode} ${text}`
          }))
        : undefined
    },
    phoneAreaCode () {
      switch (this.channel) {
        case CountryChannel.BG:
          return getCountryInfo(this.channel, this.countryList).areaCode
        default:
          return undefined
      }
    },
    TIN () {
      const channel = this.channel
      const isDeepValidationRequired = isDeepTINValidationRequired(this.formValidation, channel, this.form.taxResidencyCountryCode)
      const isRequired = isTINRequired(this.formValidation, channel, this.form.taxResidencyCountryCode)

      return {
        isVisible: isRequired,
        isMaskUsed: isDeepValidationRequired,
        isEditable: this.isDataEditingAllowed,
        mask: isDeepValidationRequired ? getTINInputMask(FormValidation.OnBoardingFlow, this.channel) : null,
        validationRules: {
          required: isDeepValidationRequired || isRequired,
          taxPayerCode: isDeepValidationRequired
        }
      }
    },
    taxResidencyCountryCode () {
      const is = isTaxResidencyFieldShownAndRequired(this.formValidation, this.channel, this.details.taxResidencyCountryCode)

      return {
        isVisible: is.visible,
        validationRules: { required: is.required },
        isEditable: this.isDataEditingAllowed
      }
    },
    activityField () {
      return {
        isClarificationRequired: isCustomFieldOfActivityRequired(this.formValidation, this.channel, this.form.activityCode),
        isEditable: this.isDataEditingAllowed
      }
    },
    confirmPhone () {
      if (!this.confirmPhoneNumberRequestId || this.activeStep !== this.steps.confirmPhone) {
        return null
      }

      return {
        confirmRequestId: this.confirmPhoneNumberRequestId
      }
    },
    confirmEmail () {
      return this.isEmailConfirmationRequired && this.activeStep === this.steps.confirmEmail
    },
    confirmPhoneEvents () {
      return {
        success: () => {
          this.confirmPhoneNumberRequestId = null
          this.successfulUpdateNotice()
          this.goToNextStep()
        },
        close: () => {
          this.confirmPhoneNumberRequestId = null
          this.$notify({ text: this.translations.phoneUpdateUnsuccessful, type: 'error', duration: 5000 })
          this.goToNextStep()
        }
      }
    },
    confirmEmailEvents () {
      return {
        success: () => {
          this.isEmailConfirmationRequired = false
          this.goToNextStep()
        },
        close: () => {
          this.isEmailConfirmationRequired = false
          this.goToNextStep()
        }
      }
    },
    registrationCountyFieldSettings () {
      return this.registrationAddressSettings.fields.county
    },
    contactCountyFieldSettings () {
      return this.contactAddressSettings.fields.county
    },
    showRegistrationAddress () {
      return !this.featureFlags.disableRegistrationAddressEditing
    },
    showContactAddress () {
      return this.contactAddressSettings.isVisible
    },
    isRegistrationAddressEditable () {
      return this.edit && this.registrationAddressSettings.isEditable && this.isDataEditingAllowed
    },
    edit () {
      return true
    },
    isContactAddressEditable () {
      return this.edit && this.contactAddressSettings.isEditable && this.isDataEditingAllowed
    }
  },
  methods: {
    ...mapActions(useRootStore, ['showContactCustomerSupportErrorModal']),
    ...mapActions(useAccountStore, ['getDetails', 'updateDetails', 'getCountries']),
    async submit () {
      if (!this.permissions?.options?.isPersonalDataSaveable ?? true) {
        this.showContactCustomerSupportErrorModal()
        return
      }

      const [phoneAreaCode, phoneNumber] = this.form.fullPhoneNumber.split('|')

      const updateRequest = {
        email: this.form.email,
        identificationCode: this.form.identificationCode,
        citizenCountryCode: this.form.citizenCountryCode,
        taxResidencyCountryCode: this.form.taxResidencyCountryCode,
        taxPayerCode: this.form.taxPayerCode,
        activityCode: this.form.activityCode,
        activitySpecified: this.form.activitySpecified,
        dualCitizenCountryCode: this.form.dualCitizenCountryCode,
        politicallyExposed: !this.form.isNotPoliticallyExposed,
        phoneAreaCode,
        phoneNumber,
        registrationAddress: this.form.registrationAddress,
        contactAddress: this.controls.postalSameAsContactAddress ? this.form.registrationAddress : this.form.contactAddress,
        formValidation: this.formValidation
      }

      this.loading = true
      this.$tracker.action(SETTINGS_VIEW.SAVE_PERSONAL_DETAILS)

      try {
        const {
          isEmailConfirmationRequired,
          isPhoneNumberConfirmationRequired,
          confirmPhoneNumberRequestId,
          hasOnlyPendingChanges
        } = await this.updateDetails(updateRequest)

        if (isEmailConfirmationRequired) {
          this.isEmailConfirmationRequired = true
          this.newEmail = updateRequest.email
        }
        if (isPhoneNumberConfirmationRequired) {
          this.confirmPhoneNumberRequestId = confirmPhoneNumberRequestId
        }
        if (!hasOnlyPendingChanges) {
          this.successfulUpdateNotice()
        }
      } catch (err) {
        Vue.handleError(err)
      } finally {
        this.loading = false
      }
    },
    async goToNextStep () {
      if (this.activeStep === this.steps.personalData) {
        await this.submit()
      }
      if ([this.steps.confirmPhone, this.steps.confirmEmail].includes(this.activeStep)) {
        await this.init()
      }
      if (this.getNextStep()) {
        return
      }
      this.setActiveStep(this.steps.personalData)
    },
    getNextStep () {
      if (this.steps.confirmPhone && this.confirmPhoneNumberRequestId) {
        this.setActiveStep(this.steps.confirmPhone)
        return true
      }
      if (this.steps.confirmEmail && this.isEmailConfirmationRequired) {
        this.setActiveStep(this.steps.confirmEmail)
        return true
      }
      if (
        !this.isDocumentUploadSkippedInConfirmData &&
        this.steps.uploadIdDocuments !== undefined &&
        this.idUploadRequired &&
        this.activeStep !== this.steps.uploadIdDocuments
      ) {
        this.setActiveStep(this.steps.uploadIdDocuments)
        return true
      }
      return false
    },
    getTranslatedCountryList (excludeByCode) {
      return getTranslatedCountryList(this.channel, this.countryList, excludeByCode)
    },
    setActiveStep (step) {
      this.activeStep = step
    },
    countryName (countryCode) {
      return getCountryInfo(countryCode, this.countryList).translation || countryCode
    },
    activityName (activityCode) {
      if (!activityCode || !this.activityCodeOptions) {
        return ''
      }
      return this.activityCodeOptions.find(({ value }) => activityCode === value)?.text || ''
    },
    async init () {
      this.loading = true
      await Promise.all([
        this.getDetails(),
        this.getCountries(true)
      ])
      this.loading = false
    },
    successfulUpdateNotice () {
      this.$notify({ text: this.translations.updatedSuccessfully, duration: 5000 })
    },
    onTaxResidencyCountryCodeChanged () {
      // way to avoid race condition in vee-validate
      this.$validator.pause()
      this.$nextTick(() => {
        this.$validator.resume()
        if (this.TIN.isVisible) {
          this.$validator.validate('taxPayerCode')
        }
      })
    }
  },
  watch: {
    details (values) {
      const identificationCodeSettings = getIdentificationCodeFieldSettings(
        this.formValidation,
        this.channel,
        values.taxResidencyCountryCode,
        values.identificationCodeCountry
      )

      this.hasPendingEmailConfirmation = values.hasPendingEmailConfirmation
      this.form.identificationCode = identificationCodeSettings.empty ? '-' : values.identificationCode
      this.form.citizenCountryCode = values.citizenCountryCode
      this.form.taxResidencyCountryCode = values.taxResidencyCountryCode
      this.form.taxPayerCode = values.taxPayerCode
      this.form.activityCode = values.activityCode
      this.form.activitySpecified = titleCase(values.activitySpecified)
      this.form.dualCitizenCountryCode = values.dualCitizenCountryCode
      this.form.isNotPoliticallyExposed = values.politicallyExposed === false
      this.form.email = values.email
      this.form.fullPhoneNumber = values.phoneNumber ? values.phoneAreaCode + '|' + values.phoneNumber : undefined
      this.form.registrationAddress = Object.assign({}, values.registrationAddress)
      this.form.contactAddress = Object.assign({}, values.contactAddress)
      this.controls.postalSameAsContactAddress = this.showRegistrationAddress && Object.values(values.registrationAddress).join('|') === Object.values(values.contactAddress).join('|')
    }
  },
  mounted () {
    this.init()
    this.setActiveStep(this.steps.personalData)
  },
  created () {
    this.$validator.extend('phoneNumberPrefix', {
      getMessage: () => this.translations.validator.phoneNumberPrefixError,
      validate: value => {
        return typeof value === 'string' && /\+[0-9]{1,3}( [0-9]{1,4})?$/.test(value.split('|')[0])
      }
    })
    this.$validator.extend('phoneNumberSuffix', {
      getMessage: () => this.translations.validator.phoneNumberError,
      validate: value => {
        return typeof value === 'string' && /^([0-9 ]{5,15})$/.test(value.split('|')[1])
      }
    })
    this.$validator.extend('taxPayerCode', {
      getMessage: () => this.translations.validator.taxPayerCodeError,
      validate: value => {
        if (isDeepTINValidationRequired(this.formValidation, this.channel, this.form.taxResidencyCountryCode)) {
          return cbValidator.isValidTaxIdNumber(value, {
            forceChannel: this.form.taxResidencyCountryCode
          })
        }
        if (isTINRequired(this.formValidation, this.channel, this.form.taxResidencyCountryCode)) {
          return (value ?? '').trim().length > 0
        }

        return true
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.button-area {
  margin-top: 80px;

  .button {
    text-transform: uppercase;
  }
}

.tax-residency-container {
  display: flex;
  flex-direction: row;
  align-items: center;

  .bb-tooltip {
    margin-top: 35px;
  }
}
</style>
