<template>
  <div class="different-plan__main-container">
    <div v-if="isLoading" class="different-plan__loader">
      <bb-spinner />
    </div>
    <div v-else>
      <div class="different-plan__slider-container">
        <h4 class="hidden-mobile"><translate>How do you want to continue?</translate></h4>
        <div class="payment-frequency-wrapper">
          <payment-frequency
                  v-model="paymentFrequencyCode"
                  :limits="flowData.limits"
          ></payment-frequency>
        </div>
        <div class="different-plan__sliders">
          <sliders v-if="sliderProps"
                  :value="sliderProps"
                  :is-loading="isContinueDisabled"
                  :paymentFrequencyCode="paymentFrequencyCode"
                  :manual-prolong="manualProlong"
                  :period-code="periodCode"
                  :start-date="startDate"
                  :account-type="flowData.accountType"
                  :min-amount="limits.minAmount"
                  :max-amount="limits.maxAmount"
                  :min-period="limits.minPeriod"
                  :max-period="limits.maxPeriod"
                  :valid-periods="limits.validPeriods"
                  :agreement-type-code="agreementTypeCode"
                  :locale="locale"
                  @input="updateSliderProps"
                  @DisableContinue="continueDisabled = true"
                  @CalculateContinue="calculateContinueEnabled"
          ></sliders>
        </div>
      </div>
      <div class="transfer-details-wrapper">
        <bb-col>
          <div v-if="showTransferAmount" class="transfer">
            <p class="transfer__text">{{ transferText }}:</p>
            <p class="transfer__sum">{{ formattedTransferAmount }}</p>
          </div>
          <div class="back-to-plans">
            <bb-button color="blue" display="link" @click="backToPlan" class="back-to-plans__text" noUnderline>
              <bb-icon fill="gray-40" name="ui-arrow-left-circle-outline" size="24" />
              <translate>Back to Plans</translate>
            </bb-button>
          </div>
        </bb-col>
      </div>
      <continue-button class="different-plan__continue-btn"
              :text="continueText"
              :handleClick="submitDifferentPlan"
              :disabled="isContinueDisabled"
      ></continue-button>
    </div>
  </div>
</template>

<script>
import Sliders from './DepositSliders'
import PaymentFrequency from './PaymentFrequency'
import ContinueButton from './ContinueButton'
import { api } from '../../api'
import getCurrency from '../../utils/getCurrency'
import { recalculatePeriod, recalculateAmount } from '../../utils/recalculate'
import '@bigbank/interface-components/dist/svg/ui/arrow-left-circle-outline'
import { formatMoneyWithCurrency } from '@/plugins/numformat'

export default {
  name: 'DifferentPlan',
  data () {
    return {
      isLoading: false,
      isExpectedProfitLoading: false,
      transferAmount: 0,
      paymentFrequencyCode: this.flowData.paymentFrequencyCode,
      continueDisabled: true,
      nextStep: '',
      sliderProps: false,
      startDate: this.flowData.startDate
    }
  },
  components: {
    Sliders,
    PaymentFrequency,
    ContinueButton
  },
  props: {
    flowData: Object
  },
  async mounted () {
    this.isLoading = true
    await this.getTransferAmount()
    this.calculateContinueEnabled()

    this.paymentFrequencyCode = this.flowData.paymentFrequencyCode ? this.flowData.paymentFrequencyCode : this.flowData.validPaymentFrequencies[0]

    const amount = recalculateAmount(this.flowData.amount, this.limits.minAmount, this.limits.maxAmount)
    const period = recalculatePeriod(this.flowData.period, this.flowData.period, this.limits.validPeriods)

    const response = await api.getAccountInterestParameters(
      amount,
      period,
      this.periodCode,
      this.paymentFrequencyCode,
      this.startDate,
      this.manualProlong,
      this.agreementTypeCode
    )
    const bonusInterest = response.bonusInterest ? response.bonusInterest : 0

    this.sliderProps = {
      amount,
      period,
      annualInterestRate: response.interest,
      bonusInterest
    }
    this.isLoading = false
  },
  methods: {
    backToPlan () {
      this.$emit('backToPlan')
    },
    async submitDifferentPlan () {
      this.$emit('submit', {
        amount: this.sliderProps.amount,
        period: this.sliderProps.period,
        paymentFrequencyCode: this.paymentFrequencyCode,
        annualInterestRate: this.sliderProps.annualInterestRate,
        bonusInterest: this.sliderProps.bonusInterest
      })
    },
    async getTransferAmount (sliderValues) {
      if (this.flowData.deposit) {
        const amount = sliderValues ? sliderValues.amount : this.flowData.amount
        this.isExpectedProfitLoading = true
        const paymentInfo = await api.getDepositPaymentInfo(this.flowData.deposit.id, amount)
        this.transferAmount = paymentInfo.transferAmount
        this.isExpectedProfitLoading = false
      } else {
        this.transferAmount = this.sliderProps.amount
      }
    },
    calculateContinueEnabled () {
      this.continueDisabled = !(this.sliderProps.amount > 0 && this.sliderProps.period > 0 && this.paymentFrequencyCode.length > 0) && !this.isExpectedProfitLoading
    },
    updateSliderProps (message) {
      this.sliderProps = {
        amount: message.amount,
        period: message.period,
        annualInterestRate: message.annualInterestRate,
        bonusInterest: message.bonusInterest
      }
    }
  },
  computed: {
    currency () {
      return getCurrency(this.flowData.accountType.restrictedCurrencyCode)
    },
    continueText () {
      return this.$gettext('I want this plan')
    },
    periodCode () {
      return this.flowData.periodCode
    },
    transferText () {
      return this.transferAmount > 0 ? this.$gettext('You will transfer us') : this.$gettext('We will transfer you')
    },
    transferAmountAbs () {
      return this.transferAmount ? Math.abs(this.transferAmount) : 0
    },
    formattedTransferAmount () {
      return formatMoneyWithCurrency(this.transferAmountAbs, this.currency, this.locale)
    },
    showTransferAmount () {
      return !(this.transferAmountAbs === 0)
    },
    isContinueDisabled () {
      return this.continueDisabled
    },
    limits () {
      return this.flowData.limits[this.paymentFrequencyCode]
    },
    manualProlong () {
      return this.flowData.activeView === this.flowData.views.prolongationFlow
    },
    agreementTypeCode () {
      return this.flowData.agreementTypeCode
    },
    locale () {
      return this.flowData.locale
    }
  },
  watch: {
    async sliderProps (val) {
      await this.getTransferAmount(val)
      this.calculateContinueEnabled()
    },
    async paymentFrequencyCode (val) {
      const limits = this.limits || this.flowData.limits[val]
      const amount = recalculateAmount(this.sliderProps.amount, limits.minAmount, limits.maxAmount)
      const period = recalculatePeriod(this.sliderProps.period, this.sliderProps.period, this.limits.validPeriods)

      this.limits.minPeriod = limits.minPeriod

      const response = await api.getAccountInterestParameters(
        amount,
        period,
        this.periodCode,
        this.paymentFrequencyCode,
        this.startDate,
        this.manualProlong,
        this.agreementTypeCode
      )

      const bonusInterest = response.bonusInterest ? response.bonusInterest : 0

      this.sliderProps = {
        amount,
        period,
        annualInterestRate: response.interest,
        bonusInterest
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .different-plan {
    &__main-container {
      margin: 0 auto;
      padding-top: 27px;
      max-width: 380px;

      @media (min-width: $desktop-view-breaking-point-wide) {
        padding-top: 50px;
      }
    }

    &__loader {
      display: flex;
      justify-content: center;
      padding-top: 100px;
    }

    &__slider-container {
      margin: 0 auto;
      padding: 0;
      max-width: 380px;

      h4 {
        font-weight: bold;
        text-align: center;
        color: $navy;
      }
    }

    &__sliders {
      margin-top: 28px;
    }

    &__continue-btn {
      margin-top: 60px;
    }
  }

  .payment-frequency-wrapper {
    margin-top: 20px;

    @media (max-width: $mobile-view-breaking-point) {
      margin-top: -10px;
    }
  }

  .transfer-details-wrapper {
    margin-top: 45px;

    @media (min-width: $desktop-view-breaking-point-wide) {
      margin-top: 50px;
      background-color: $white;
      padding: 0;
    }

    .col {
      margin: 0 auto;
      max-width: 400px;

      @media (max-width: $desktop-view-breaking-point) {
        padding: 0;
      }

      .dropdown {
        margin-bottom: 25px;
      }

      .transfer {
        margin-top: 26px;
        padding: 8px 0 6px;
        width: 100%;
        display: flex;
        flex-direction: row;
        border-top: rem(1px) dashed $gray-50;
        border-bottom: rem(1px) dashed $gray-50;

        &__text {
          font-family: $gotham-medium;
          font-size: rem($font-size-smallest);
          color: $gray-90;
        }

        &__sum {
          margin-left: auto;
          color: $blue;
          font-family: $gotham-bold;
          font-size: rem($font-size-small);
        }
      }

      .back-to-plans {
        text-align: center;
        display: flex;
        justify-content: center;
        margin-top: 25px;

        @media (min-width: $desktop-view-breaking-point-wide) {
          margin-top: 20px;
        }

        svg {
          margin-right: 13px;
        }

        &__text {
          font-family: $gotham-book;
          font-size: $font-size-small;
        }
      }

      .continue-button {
        margin-top: 60px;
        width: 100%;

        @media (max-width: $desktop-view-breaking-point) {
          margin-top: 0;
        }
      }
    }
  }

</style>
