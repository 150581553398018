import { request } from '@/plugins/utils'

export default {
  resetInactivityTimer () {
    return request('/gw/verifyUser', {
      method: 'post',
      headers: {
        'x-bb-user-active': '1'
      },
      errHandlerOpts: {
        handleOnlyKnownErrors: true
      }
    })
  },
  skipCurrentAccountConversion () {
    return request('/gw/skip-current-account-conversion', {
      method: 'post',
      errHandlerOpts: {
        handleOnlyKnownErrors: true
      }
    })
  },
  increaseHasSeenMandatoryOpenSavingsDepositModal () {
    return request('/gw/increase-has-seen-mandatory-savings-deposit-modal', {
      method: 'post',
      errHandlerOpts: {
        handleOnlyKnownErrors: true
      }
    })
  }
}
