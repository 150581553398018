export const DepositType = Object.freeze({
  Term: 'TERM',
  Demand: 'DEMAND'
})

export const DepositContractStatusCode = Object.freeze({
  MoneyOriginMaxLimitExceeded: 'MONEY_ORIGIN_MAX_LIMIT_EXCEEDED',
  MoneyOriginOther: 'MONEY_ORIGIN_OTHER'
})

export const DepositRouteName = Object.freeze({
  New: 'new-deposit',
  ConfirmDataLanding: 'confirm-data-landing',
  Contract: 'deposit-contract-details',
  Signing: 'deposit-signing',
  Prolongation: 'deposit-prolongation',
  Deposits: 'deposits',
  TermDeposits: 'term-deposits',
  FundingOptions: 'funding-options',
  DemandDeposits: 'demand-deposits',
  DemandDepositsTopUp: 'demand-deposits_top-up',
  DemandDepositsWithdraw: 'demand-deposits_withdraw',
  DemandDepositsCancelOutpayment: 'demand-deposits_cancel-outpayment',
  DemandDepositsStatement: 'demand-deposits_statement',
  MoneyOriginDocuments: 'money-origin-documents',
  SigningProcess: 'signing-process'
})

export const ClosedDepositSortedStatuses = ['CLOSED', 'TERMINATED', 'CANCELED', 'REJECTED']
export const ActiveDepositSortedStatuses = ['ACTIVE', 'WAITING_SIGNING', 'ACTIVE_PROLONG', 'WAITING_FUNDS']
