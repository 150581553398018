<template>
  <div>
    <div class="hero-header__no-contracts__opening-header">
      <bb-icon
        name="illustration-opening-header"
        size="fluid"
        fill="mint-70"
      ></bb-icon>
    </div>
    <div class="m-t-20">
      <p class="hero-header__no-contracts__welcome-message color-white">
        <translate>Welcome to Self-Service</translate>
      </p>
    </div>
    <div class="m-t-25">
      <p class="hero-header__no-contracts__description smallest color-white">
        {{translations.description}}
      </p>
    </div>
  </div>
</template>

<script>
import '@bigbank/interface-components/dist/svg/illustration/opening-header'

export default {
  name: 'hero-header__no-contracts',
  props: {
    username: {
      required: true
    },
    translations: {
      required: true
    }
  }
}
</script>

<style lang="scss">
.hero-header__no-contracts {
  &__opening-header {
    height: rem(52px);
    width: auto;
  }

  &__welcome-message {
    font-family: $max-pro-bold;
    font-weight: 600;
    font-size: $h4-size;

    &__username {
      text-transform: capitalize;
    }
  }

  &__description {
    font-family: $gotham-medium;
    font-weight: 500;
    font-size: $font-size-smallest;
  }
}
</style>
