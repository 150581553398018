import { mapState } from 'pinia'
import { useAccountStore } from '@account/store/accountStore'
import { isEmpty, some } from 'lodash'

export default {
  computed: {
    ...mapState(useAccountStore, ['details']),
    isCustomerContactAddressValid () {
      if (isEmpty(this.details?.contactAddress)) {
        return false
      }
      const { countryCode, streetAddress, postcode, city } = this.details.contactAddress
      return !some([countryCode, streetAddress, postcode, city], customerContact => isEmpty(customerContact))
    },
    customerContactAddress () {
      if (isEmpty(this.details?.contactAddress)) {
        return '-'
      }

      return [
        this.details.contactAddress.countryCode,
        this.details.contactAddress.streetAddress,
        this.details.contactAddress.postcode,
        this.details.contactAddress.city
      ].filter(item => !!item).join(', ')
    }
  }
}
