<template>
  <signing-modal-wrapper :visible="visible" @close="$emit('close', $event)" :showLoader="isLoading">
    <template slot="heading">{{ modalHeading }}</template>
    <div v-if="!isLoading && errorMessage">
      <p class="m-t-15 m-b-15 color-red text-center">{{errorMessage}}</p>
    </div>
    <div v-else-if="!isLoading">
      <p class="info">{{infoText}}</p>
      <bb-input
        :label="translations.label"
        v-validate="'required'"
        :data-vv-as="translations.label"
        pattern="[0-9]*"
        type="number"
        name="otp"
        v-model="otp"
        :maxlength="6"
        :disabled="isValidating"
        ref="otp"
      />
    </div>
    <bb-ticket-stub v-if="showButton" slot="footer" class="m-t-15">
      <bb-button @click="onAction" :color="buttonColor" :loading="isValidating" display="ticket">{{buttonLabel}}</bb-button>
    </bb-ticket-stub>
  </signing-modal-wrapper>
</template>

<script>
import { api } from '../../api'
import '@bigbank/interface-components/dist/svg/ui/alert'
import '@bigbank/interface-components/dist/svg/ui/check'
import { SIGNING_VIEW } from '@/TrackingActions'
import SigningModalWrapper from '@/components/signing/SigningModalWrapper'

export default {
  name: 'sign-modal',
  components: { SigningModalWrapper },
  data () {
    return {
      otp: '',
      isLoading: false,
      isValidating: false,
      otpExpired: false,
      phoneNumber: '',
      errorMessage: undefined,
      translations: {
        label: this.$gettext('Enter code from SMS'),
        otpExpiredError: this.$gettext('The signing flow has expired, please start it again.'),
        otpWrongError: this.$gettext('The SMS code does not match. Please try again.'),
        accountDisabled: this.$gettext('You have entered otp code wrongly too many times and your account is disabled. Please contact customer support.'),
        accountLocked: this.$gettext('Due to several unsuccessful login attempts, your account has been deactivated for 30 minutes due to security concerns.'),
        internalError: this.$gettext('Something appears to have gone wrong. Please try again later or contact our customer support.'),
        restartSigning: this.$gettext('Send new code'),
        loading: this.$gettext('Loading...'),
        confirm: this.$gettext('Confirm'),
        error: this.$gettext('Error')
      }
    }
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false
    },
    deposit: {
      required: true
    },
    signingRequestId: {
      required: false
    }
  },
  computed: {
    infoText () {
      return this.$gettext('We have sent 6-digit verification code to {phoneNumber}.')
        .replace('{phoneNumber}', this.phoneNumber)
    },
    buttonLabel () {
      if (this.otpExpired) {
        return this.translations.restartSigning
      } else {
        return this.translations.loading
      }
    },
    showButton () {
      return this.otpExpired || this.isValidating
    },
    buttonColor () {
      if (this.otpExpired && !this.isLoading) return 'green'
      return 'gray'
    },
    modalHeading () {
      return this.errorMessage ? this.translations.error : this.translations.confirm
    }
  },
  watch: {
    visible (isVisible) {
      if (!isVisible || !this.deposit) {
        return
      }
      this.initSigning()
    },
    otp (value) {
      if (!value || value.length < 6) {
        return
      }

      this.$validator.errors.clear()
      this.isValidating = true

      api.completeCustomerIdSigning(this.deposit.id, this.signingRequestId, { otp: this.otp })
        .then(() => {
          this.$emit('signingSuccessful')
        })
        .catch(data => {
          this.isValidating = false

          if (data && data.err_code === 'OTP_EXPIRED') {
            this.otpExpired = true
          } else {
            this.otpExpired = false
          }

          this.$emit('signingError', data)

          return this.$validator.errors.add({
            field: 'otp',
            msg: this.getErrorMessage(data?.err_code)
          })
        })
    }
  },
  methods: {
    initSigning (retry = false) {
      this.errorMessage = ''
      this.otp = ''
      this.otpExpired = false
      this.isLoading = true
      this.isValidating = false
      this.errors.clear()
      this.$tracker.action(SIGNING_VIEW.INIT_SIGNING)
      api.startCustomerIdSigning(this.deposit.id, this.signingRequestId, retry)
        .then(data => {
          this.isLoading = false
          this.phoneNumber = data.recipient
          this.focusOtp()
        })
        .catch(data => {
          this.isLoading = false
          this.errorMessage = this.getErrorMessage(data?.err_code)
        })
    },
    onAction () {
      if (this.isLoading || this.isValidating) {
        return
      }
      this.initSigning(this.otpExpired)
    },
    focusOtp () {
      this.$nextTick(() => {
        this.$refs.otp?.$refs?.input?.focus()
      })
    },
    getErrorMessage (errorCode) {
      switch (errorCode) {
        case 'ACCOUNT_DISABLED':
          return this.translations.accountDisabled
        case 'INVALID_OTP':
          return this.translations.otpWrongError
        case 'ACCOUNT_LOCKED':
          return this.translations.accountLocked
        case 'OTP_EXPIRED':
          return this.translations.otpExpiredError
        default:
          return this.translations.internalError
      }
    }
  },
  mounted () {
    if (!this.visible || !this.deposit) {
      return
    }
    this.initSigning()
  }
}
</script>

<style lang="scss">
  .sign-modal__wrapper {
    .modal__title {
      color: $navy !important;
      font-size: $h4-size;
    }

    .info {
      color: $gray-70;
      text-align: center;
      font-size: $font-size-small;
      margin-bottom: 30px;
    }
  }
</style>
