<template>
  <div>
    <template v-for="(item, i) in parts">
      <span class="text" :key="'text-' + i" v-if="item.type === 'text'">{{item.content}}</span>
      <bb-link :key="'link-' + i" v-if="item.type === 'link'" :to="item.to" target="_blank">{{item.content}}</bb-link>
      <bb-link :key="'event-' + i" v-if="item.type === 'event'" @click="$emit('click')">{{item.content}}</bb-link>
    </template>
  </div>
</template>
<script>
import { BbLink } from '@bigbank/interface-components'

export default {
  components: {
    BbLink
  },
  props: {
    text: {
      type: String,
      required: true
    },
    links: {
      type: Array,
      required: false,
      default: () => ([])
    }
  },
  computed: {
    parts () {
      const parts = []
      let tmp = this.text
      const links = this.text.match(/\[.*?\]\(.*?\)/g)

      if (!links) {
        parts.push({
          type: 'text',
          content: tmp
        })
      } else {
        links.forEach((link, index) => {
          const [start, end] = tmp.split(link)
          const [, linkText, linkUrl] = link.match(/\[(.*?)\]\((.*?)\)/)
          parts.push({
            type: 'text',
            content: start
          })

          const isEventLink = ['modal'].includes(linkUrl)

          parts.push({
            type: isEventLink ? 'event' : 'link',
            content: linkText,
            to: linkUrl || this.links[index]
          })

          if (index + 1 < links.length) {
            tmp = end
          } else if (end) {
            parts.push({
              type: 'text',
              content: end
            })
          }
        })
      }

      return parts
    }
  }
}
</script>

<style lang="scss" scoped>
span.text {
  white-space: pre-wrap;
}
</style>
