<template>
  <div class="payment-frequency-container m-t-30">
    <bb-radio name="payment-frequency"
      equalWidth
      v-if="isVisible"
      :options="options"
      :label="translations.question"
      :value="value"
      @input="input"/>
  </div>
</template>

<script>
import { BbRadio } from '@bigbank/interface-components'
import { mapState } from 'pinia'
import { isNull, isString, sortBy } from 'lodash'
import { useDepositStore } from '@deposits/store/depositStore'

export default {
  name: 'SavingDepositPaymentFrequency',
  components: {
    BbRadio
  },
  data () {
    return {
      value: null,
      translations: {
        month: this.$gettext('Once a Month'),
        year: this.$gettext('Once a Year'),
        question: this.$gettext('I want my interest payment frequency to be') + ':'
      }
    }
  },
  props: {
    validPaymentFrequencies: {
      type: Array,
      required: false
    },
    accountsDefaults: {
      type: Array,
      required: false
    }
  },
  computed: {
    ...mapState(useDepositStore, ['featureFlags']),
    options () {
      return [
        this.validPaymentFrequencies.includes('YEAR') ? { value: 'YEAR', text: this.translations.year } : null,
        this.validPaymentFrequencies.includes('MONTH') ? { value: 'MONTH', text: this.translations.month } : null
      ].filter((el) => !isNull(el))
    },
    isVisible () {
      return this.validPaymentFrequencies.length > 1
    }
  },
  methods: {
    input (value) {
      this.$emit('input', value)
    },
    setValue (value) {
      this.value = value
      this.input(value)
    },
    setDefaultValueFromAccountDefaultsIfFeatureFlagEnabled () {
      if (this.featureFlags.SavingsDepositInterestPaymentFrequencyBasedOnAccountDefaults) {
        const sortedAccountDefaultsByRank = sortBy(this?.accountsDefaults ?? [], 'rank')
        const highestPriorityFrequency = sortedAccountDefaultsByRank[0]?.interestPaymentFrequencyCode ?? null

        if (!this.validPaymentFrequencies.includes(highestPriorityFrequency)) {
          this.$apm.captureError(`SavingDepositPaymentFrequency misconfiguration | validPaymentFrequencies: ${JSON.stringify(this.validPaymentFrequencies)} | highestPriorityFrequency: ${highestPriorityFrequency}`)
          this.setValue(this.validPaymentFrequencies[0])
        } else {
          isString(highestPriorityFrequency) && this.setValue(highestPriorityFrequency)
        }
      } else {
        isString(this.options[0]?.value) && this.setValue(this.options[0].value)
      }
    }
  },
  created () {
    this.setDefaultValueFromAccountDefaultsIfFeatureFlagEnabled()
  }
}
</script>

<style scoped lang="scss">
.payment-frequency-container {
  max-width: 380px;
}
</style>
