<template>
  <bb-modal
    :visible="isModalVisible"
    flavor="card"
    @close="onModalClosed"
    class="order-virtual-credit-card-debt-modal noselect text-center"
    centerVertically
    full-mobile
    publicSize="m"
  >
    <h5 class="text-center modal-title">{{ translations.modalTitle }}</h5>
    <p class="text-center m-t-20 modal-description" v-sanitize.basic="translations.description" />
    <template #footer>
      <bb-button
        v-if="$screen.md"
        display="block"
        name="close-replace"
        v-bind="submitButtonProps"
        @click="onClick"
      />
      <bb-button
        v-else
        corner="sharp"
        display="block"
        name="close-replace"
        v-bind="submitButtonProps"
        @click="onClick"
        size="lg"
        />
    </template>
  </bb-modal>
</template>

<script>
import '@bigbank/interface-components/dist/svg/illustration/cvv'

export default {
  name: 'credit-card-replace-modal',
  props: {
    visible: { required: true, type: Boolean }
  },
  data () {
    return {
      isModalVisible: this.visible
    }
  },
  computed: {
    translations () {
      return {
        modalTitle: this.$pgettext('activate_virtual_card', 'Activate virtual card'),
        buttonText: this.$pgettext('activate_virtual_card', 'Show payment details'),
        description: this.$pgettext('activate_virtual_card', 'Due to debt, it is not possible to order virtual card. After debt has been repaid, you can proceed with ordering a virtual card.')
      }
    },
    submitButtonText () {
      return this.translations.buttonText
    },
    submitButtonProps () {
      const label = this.submitButtonText

      return {
        label
      }
    }
  },
  methods: {
    onClick () {
      this.$router.push({ name: 'credit-card-loan_pay-back' })
      this.onModalClosed()
    },
    onModalClosed () {
      this.$emit('close', false)
    }
  }
}
</script>

<style lang="scss">
.order-virtual-credit-card-debt-modal {
  .modal-title {
    color: $blue;
    font-weight: 500;
    font-size: $font-size-small;
    font-family: $gotham-medium;
  }

  .modal-description {
    color: $gray;
    font-size: $h4-size;
    display: block;
    min-height: 300px;

    strong,
    b {
      font-family: $gotham-medium !important // Don't blame me, IC has also important, can not override otherwise;
    }
  }
}
</style>
