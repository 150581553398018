import { isNil } from 'lodash'

export default {
  watch: {
    '$route.query.signingRequestId': {
      handler: function (signingRequestId) {
        const signingMethod = this.$route?.query?.signingMethod

        if (signingRequestId && signingMethod) {
          this.signingRequestId = this.$route.query.signingRequestId
          this.chosenSignMethod = this.$route.query.signingMethod
          this.showSignModal = true
          if (isNil(this.$route.query.callback)) {
            this.$router.replace({
              query: {
                signingRequestId: undefined,
                chosenSignMethod: undefined
              }
            })
          }
        }
      },
      deep: true,
      immediate: true
    }
  }
}
