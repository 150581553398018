<template>
  <layout v-bind="layoutProps" v-on="$listeners">
    <bb-textarea
      name="description"
      v-model="form.description"
      :label="translations.description"
      :data-vv-as="translations.description"
      rows="5"
      maxlength="1000"
      v-validate="'required'"
    />
  </layout>
</template>

<script>
import Layout from './Layout.vue'

export default {
  components: { Layout },
  name: 'manage-loan-shortening',
  data () {
    return {
      form: {
        description: undefined
      }
    }
  },
  props: {
    showLoader: {
      required: true
    }
  },
  computed: {
    translations () {
      return {
        title: this.$pgettext('manage_loan_shortening', 'Apply for loan shortening contract change'),
        text: this.$pgettext('manage_loan_shortening', 'In order to apply for shortening the contract please provide us with additional information.'),
        buttonText: this.$pgettext('manage_loan_shortening', 'Continue'),
        description: this.$pgettext('manage_loan_shortening', 'Description')
      }
    },
    layoutProps () {
      return {
        visible: true,
        title: this.translations.title,
        text: this.translations.text,
        buttonText: this.translations.buttonText,
        showLoader: this.showLoader,
        data: {
          ...this.form
        }
      }
    }
  }
}
</script>
