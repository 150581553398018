<template>
  <div>
    <layout-with-progress v-bind="{ loading, progress, statusText }" @close="closeModal">
      <div class="new-app-flow__container">
        <div class="new-app-flow__content">
          <div class="new-app-flow__component">
            <choose-product
              v-if="activePage === flows.chooseProduct"
              :flow-data="flowData"
              :flow-constants="constants"
              @submit="submitChooseProduct"
            />
            <offer-page
              v-if="activePage === flows.offerPage"
              :flowData="flowData"
              @submit="submitOfferPage"
              @goToDifferentPlan="loadDifferentPlan"
            />
            <different-plan
              v-if="activePage === flows.differentPlan"
              :flowData="flowData"
              @submit="submitDifferentPlan"
              @backToPlan="onBackToPlan"
            />
            <confirm-money-details
              v-if="activePage === flows.confirmMoneyDetails"
              :flowData="flowData"
              :flow-constants="constants"
              @submit="submitConfirmMoneyDetails"
            />
            <application-confirmation
              @close="loadDepositsView"
              :visible="activePage === flows.applicationConfirmation"
              :isLoading="applicationCreationLoading"
              :flowData="flowData"
              :flow-constants="constants"
              @submit="submitApplicationConfirmation"
            />
            <create-money-origin-documents
              v-if="activePage === flows.confirmMoneyDetailsAdditionalDocuments"
              :flowData="flowData"
              :flowConstants="constants"
              @submit="submitConfirmMoneyDetailsAdditionalDocuments"
            />
            <contract-conditions
              v-if="activePage === flows.contractConditions"
              :deposit="flowData.newDeposit"
              :transferDetails="{}"
              :is-demand-deposit="isDemandDeposit"
              @close="loadDepositsView"
            />
          </div>
        </div>
      </div>
    </layout-with-progress>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { api } from '../api'
import DifferentPlan from '../components/ApplicationCreation/DifferentPlan'
import ApplicationConfirmation from '../components/ApplicationCreation/ApplicationConfirmation'
import OfferPage from '../components/ApplicationCreation/OfferPage'
import ConfirmMoneyDetails from '../components/ApplicationCreation/ConfirmMoneyDetails'
import CreateMoneyOriginDocuments from '../../deposits/components/MoneyOrigin/CreateMoneyOriginDocuments'
import ContractConditions from '../components/ContractConditions'
import ChooseProduct from '../components/ApplicationCreation/ChooseProduct'
import FlowMixin from '../mixins/flowMixin'
import OffersMixin from '../mixins/offersMixin'
import flows from '../utils/flows'
import { getConfirmFlowTrackerAction } from '@/TrackingActions'
import { DepositType } from '@deposits/const'
import { useRootStore } from '@/store/root'
import { mapState, mapActions, mapWritableState } from 'pinia'
import { useDepositStore } from '@deposits/store/depositStore'
import { DepositStatusCode, InterestPaymentFrequencyCode } from '@bigbank/dc-common/clients/http/account/account.enums'

export default {
  name: 'new-application-flow',
  mixins: [FlowMixin, OffersMixin],
  components: {
    ChooseProduct,
    OfferPage,
    DifferentPlan,
    ApplicationConfirmation,
    ConfirmMoneyDetails,
    ContractConditions,
    CreateMoneyOriginDocuments
  },
  props: {
    activeView: String
  },
  data () {
    return {
      show: true,
      statusText: '',
      newDepositType: null,
      amount: null,
      period: null,
      term: null
    }
  },
  mounted () {
    this.newDepositType = this.$route.params.depositType ?? null
    this.amount = parseFloat(this.$route.query.amount) || null
    this.period = parseFloat(this.$route.query.period) || null
    this.term = this.$route.query.term?.toUpperCase() || null
    this.calculateProgressMax()
    this.loadFirstStep()
    this.getPendingApplications()
  },
  methods: {
    ...mapActions(useDepositStore, ['fetchDeposits', 'getPendingApplications']),
    async submitOfferPage (stepData) {
      this.$tracker.action(
        getConfirmFlowTrackerAction(this.flowData.activeView, this.activePage),
        {
          progress: this.progressForTracker,
          selectedOffer: Object.assign({}, stepData.selectedOffer, { amount: stepData.amount })
        }
      )
      this.flowData = Object.assign({}, this.flowData, stepData)
      await this.loadConfirmMoneyDetails()
    },
    async submitChooseProduct (stepData) {
      const depositType = (stepData.agreementTypeCode === this.constants.AGREEMENT_TYPE_CODES[DepositType.Demand])
        ? DepositType.Demand
        : DepositType.Term

      this.$tracker.action(
        getConfirmFlowTrackerAction(this.flowData.activeView, this.activePage),
        {
          progress: this.progressForTracker,
          selectedProduct: depositType
        }
      )
      this.flowData = Object.assign({}, this.flowData, stepData)

      if (depositType === DepositType.Term) {
        await this.loadOfferPage(true)
      } else if (depositType === DepositType.Demand) {
        await this.loadConfirmMoneyDetails()
      }
    },
    getDefaultDepositType () {
      const enableApplyTermDeposits = this.featureFlags.enableApplyTermDeposits
      const enableApplyDemandDeposits = this.featureFlags.enableApplyDemandDeposits
      const bothDepositTypesEnabled = enableApplyTermDeposits && enableApplyDemandDeposits

      if (bothDepositTypesEnabled && this.hasOpenDemandDeposit) {
        return DepositType.Term
      } else if (bothDepositTypesEnabled && !this.hasOpenDemandDeposit) {
        return this.newDepositType
      } else if (enableApplyTermDeposits) {
        return DepositType.Term
      } else if (enableApplyDemandDeposits) {
        return DepositType.Demand
      } else {
        return null
      }
    },
    async loadFirstStep () {
      await this.fetchDeposits()
      this.loadUniversalFlowData()

      this.newDepositType = this.getDefaultDepositType()

      switch (this.newDepositType) {
        case DepositType.Demand:
          await this.loadFlowDataForAgreementType(this.constants.AGREEMENT_TYPE_CODES[DepositType.Demand])
          await this.loadConfirmMoneyDetails()
          break
        case DepositType.Term:
          await this.loadOfferPage(true)
          break
        default:
          await this.loadChooseProductPage()
          break
      }
    },
    loadUniversalFlowData () {
      this.flowData.views = this.views
      this.flowData.activeView = this.views.newApplicationFlow
      this.flowData.startDate = dayjs().format('YYYY-MM-DD')
      this.flowData.locale = this.locale
      this.flowData.amount = this.amount
      this.flowData.period = this.period
      this.flowData.paymentFrequencyCode = this.term
    },
    async loadChooseProductPage () {
      await this.loadDepositTypeFlowData()
      this.setChooseProductPage()
    },
    async loadDepositTypeFlowData () {
      const accountTypes = await api.getAccountTypes()
      const accountTypeForTermDeposit = accountTypes.filter(accountType => accountType.agreementTypeCode === this.constants.AGREEMENT_TYPE_CODES[DepositType.Term])[0]
      const accountTypeForDemandDeposit = accountTypes.filter(accountType => accountType.agreementTypeCode === this.constants.AGREEMENT_TYPE_CODES[DepositType.Demand])[0]
      const termDepositParams = await this.getProductParameters(accountTypeForTermDeposit)
      const demandDepositParams = await this.getProductParameters(accountTypeForDemandDeposit)

      this.flowData.accountTypes = accountTypes
      this.flowData.product = {
        [this.constants.AGREEMENT_TYPE_CODES[DepositType.Term]]: {
          params: termDepositParams,
          accountType: accountTypeForTermDeposit
        },
        [this.constants.AGREEMENT_TYPE_CODES[DepositType.Demand]]: {
          params: demandDepositParams,
          accountType: accountTypeForDemandDeposit
        }
      }
    },
    setChooseProductPage () {
      this.setActivePage(flows.chooseProduct)
      this.loading = false
    },
    async loadOfferPage (initialLoad = false) {
      await this.loadFlowDataForAgreementType(this.constants.AGREEMENT_TYPE_CODES[DepositType.Term])
      this.setOfferPage(initialLoad)
    },
    async loadFlowDataForAgreementType (agreementTypeCode) {
      const accountTypes = await api.getAccountTypes(agreementTypeCode)
      const accountType = accountTypes[0]
      const { accountDefaults, limits, parameters, validPaymentFrequencies } = await this.getProductParameters(accountType)

      this.flowData.agreementTypeCode = agreementTypeCode
      this.flowData.validPaymentFrequencies = validPaymentFrequencies
      this.flowData.accountType = accountType
      this.flowData.accountDefaults = accountDefaults
      this.flowData.limits = limits
      this.flowData.parameters = parameters

      const isValidFrequencyCode = validPaymentFrequencies.includes(this.flowData.paymentFrequencyCode)
      const isDemandDeposit = agreementTypeCode === this.constants.AGREEMENT_TYPE_CODES[DepositType.Demand]

      if (isDemandDeposit || !isValidFrequencyCode) {
        this.flowData.paymentFrequencyCode = this.isChannelEE ? InterestPaymentFrequencyCode.Month : validPaymentFrequencies[0]
      }

      if (agreementTypeCode === this.constants.AGREEMENT_TYPE_CODES[DepositType.Term]) {
        this.flowData.periodCode = this.defaults.periodCode
      }
    },
    setOfferPage (initialLoad) {
      if (initialLoad && this.flowData.period && !this.findMatchingOffer(this.flowData.accountDefaults, this.flowData.period)) {
        this.loadDifferentPlan({
          amount: this.flowData.amount,
          period: this.flowData.period,
          paymentFrequencyCode: this.flowData.paymentFrequencyCode
        })
      } else {
        this.setActivePage(flows.offerPage)
        this.loading = false
      }
    },
    async getProductParameters (accountType) {
      if (!accountType?.agreementTypeCode) {
        return {
          accountDefaults: {},
          limits: {},
          parameters: {},
          validPaymentFrequencies: []
        }
      }

      const isSavingsDeposit = this.constants.AGREEMENT_TYPE_CODES[DepositType.Demand] === accountType.agreementTypeCode
      const isAccountDefaultsFeatureFlagEnabled = this.depositFeatureFlags.SavingsDepositInterestPaymentFrequencyBasedOnAccountDefaults
      const accountDefaultsPeriodCode = isSavingsDeposit && isAccountDefaultsFeatureFlagEnabled ? undefined : this.defaults.periodCode
      const [accountDefaults, { limits, parameters, validPaymentFrequencies }] = await Promise.all([
        api.getAccountDefaults(accountType.code, accountDefaultsPeriodCode),
        api.getDepositLimits(accountType.agreementTypeCode, accountType.code, this.defaults.periodCode)
      ])

      return { accountDefaults, limits, parameters, validPaymentFrequencies }
    },
    calculateProgressMax (value) {
      const useValue = value || this.stepMapping
      this.progress.max = Object.values(useValue).reduce((memo, val) => val > memo ? val : memo, 0)
    },
    onBackToPlan () {
      this.loadOfferPage(false)
    }
  },
  computed: {
    ...mapState(useRootStore, ['featureFlags', 'locale', 'isChannelEE']),
    ...mapState(useDepositStore, ['views', 'demandDeposit']),
    ...mapState(useDepositStore, { depositFeatureFlags: 'featureFlags' }),
    ...mapWritableState(useDepositStore, ['loading']),
    stepMapping () {
      if (this.featureFlags.enableApplyTermDeposits || this.hasOpenDemandDeposit) {
        return {
          [flows.offerPage]: 1,
          [flows.differentPlan]: 1,
          [flows.confirmMoneyDetails]: 2,
          [flows.contractConditions]: 3
        }
      } else {
        if (this.isTermDeposit || !this.isDemandDeposit) {
          return {
            [flows.chooseProduct]: 1,
            [flows.offerPage]: 2,
            [flows.differentPlan]: 2,
            [flows.confirmMoneyDetails]: 3,
            [flows.contractConditions]: 4
          }
        } else if (this.isDemandDeposit) {
          return {
            [flows.chooseProduct]: 1,
            [flows.confirmMoneyDetails]: 2
          }
        } else {
          return {}
        }
      }
    },
    isTermDeposit () {
      return this.newDepositType === DepositType.Term ||
        this.flowData.agreementTypeCode === this.constants.AGREEMENT_TYPE_CODES[DepositType.Term]
    },
    isDemandDeposit () {
      return this.newDepositType === DepositType.Demand ||
        this.flowData.agreementTypeCode === this.constants.AGREEMENT_TYPE_CODES[DepositType.Demand]
    },
    isDemandDepositClosed () {
      return [
        DepositStatusCode.Terminated,
        DepositStatusCode.Cancelled,
        DepositStatusCode.Rejected,
        DepositStatusCode.Closed
      ].includes(this.demandDeposit?.state)
    },
    hasOpenDemandDeposit () {
      return !!this.demandDeposit && !this.isDemandDepositClosed
    }
  },
  watch: {
    stepMapping (value) {
      this.calculateProgressMax(value)
    }
  }
}
</script>

<style lang="scss">
  .new-app-flow {
    &__container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 100%;
      background-color: $white;
    }

    &__content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }

    &__component {
      margin: 54px 30px 0;

      @media (min-width: $desktop-view-breaking-point-wide) {
        margin: 63px 0 0;
      }

      @media (max-width: 359px) {
        margin: 54px 15px 0;
      }
    }
  }
</style>
