<template>
  <layout-with-menu :hideMenu="true">
    <bb-navbar :login="login" flavor="public">
      <bb-logo to="/" :logo="image" />
    </bb-navbar>
    <slot></slot>
  </layout-with-menu>
</template>

<script>
import image from '@bigbank/interface-components/dist/img/bigbank-dark@2x.png'

export default {
  name: 'layout-with-navbar',
  data () {
    return {
      image
    }
  },
  props: {
    login: {
      required: false,
      default: null
    }
  }
}
</script>
