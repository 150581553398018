<template>
  <nav class="progress-header">
    <div class="progress-header__main_container">
      <div class="progress-header__logo_container">
        <bb-logo to="/" class="progress-header__logo" :logo="logoImg"></bb-logo>
      </div>
      <div class="progress-header__indicator_container">
        <div class="my-hidden-mobile progress-header__status-text">{{ statusText }}</div>
        <bb-progress-indicator
                            v-if="!hideProgressIndicator"
                            class="progress-header__indicator"
                            color="green"
                            :max="max"
                            :value="value"
                            flavor="user"
      ></bb-progress-indicator>
        <div v-if="!hideCloseButton" class="progress-header__divider"></div>
        <div v-if="!hideCloseButton" class="progress-header__close-icon" @click="$emit('close')">
          <bb-icon name="ui-close" size="24" fill="gray-70"/>
        </div>
        <bb-button
          :label="rightCornerButton.label"
          :icon="rightCornerButton.icon"
          class="m-l-15 m-r-10"
          @click="rightCornerButton.click"
          v-if="rightCornerButton != null"
        />
      </div>
    </div>
    <bb-progress-bar class="progress-header__progress-bar" :max="max" :value="value"></bb-progress-bar>
  </nav>
</template>

<script>
import logoImg from '@bigbank/interface-components/dist/img/logo-dark.png'
import '@bigbank/interface-components/dist/svg/ui/close'
import { BbProgressBar } from '@bigbank/interface-components'

export default {
  name: 'progress-header',
  components: {
    BbProgressBar
  },
  props: {
    hideCloseButton: {
      type: Boolean,
      default: false
    },
    rightCornerButton: {
      type: Object,
      default: null
    },
    statusText: {
      type: String
    },
    value: {
      type: Number
    },
    max: {
      type: Number
    },
    hideProgressIndicator: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      logoImg
    }
  }
}
</script>

<style lang="scss">
  .progress-header {
    background: $white;
    width: 100%;
    height: 52px;
    position: fixed;
    z-index: 10;
    top: 0;
    right: 0;
    left: 0;

    @media (min-width: $desktop-view-breaking-point-wide) {
      height: 63px;
    }

    &__progress-bar {
      &.progress-bar {
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    &__main_container {
      display: flex;
      height: 50px;
      width: 100%;
      align-items: center;
      justify-content: space-between;

      @media (min-width: $desktop-view-breaking-point-wide) {
        height: 63px;
      }
    }

    &__logo_container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 50px;
      width: 50%;
      margin-left: 15px;

      @media (min-width: $desktop-view-breaking-point-wide) {
        height: 63px;
        margin-left: 240px;
      }
    }

    &__indicator_container {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 50px;
      width: 50%;

      @media (min-width: $desktop-view-breaking-point-wide) {
        height: 63px;
        margin-right: 20px;
      }
    }

    &__status-text {
      font-family: $gotham-bold;
      font-size: $default-font-size;
      color: $mint;
    }

    &__divider {
      height: 40px;
      width: 1px;
      background-color: $gray-20;
      margin-top: 5px;

      @media (min-width: $desktop-view-breaking-point-wide) {
        margin-top: unset;
        height: 35px;
        margin-left: 25px;
      }
    }

    &__indicator {
      height: 30px !important;
      width: 30px !important;
      margin-right: 18px;

      @media (min-width: $desktop-view-breaking-point-wide) {
        height: 35px !important;
        width: 35px !important;
      }
    }

    &__close-icon {
      margin: 0 17px;

      &:hover {
        cursor: pointer;
      }
    }
  }
</style>
