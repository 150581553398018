<template>
  <div>
    <layout-with-progress @close="close" v-bind="{ progress, isLoading, statusText }">
      <div class="update__container">
        <div class="update__content">
          <div class="update__component">
            <money-origin-documents ref="moneyOriginDocuments" :isSkipButtonVisible="isSkipButtonVisible" />
          </div>
        </div>
    </div>
  </layout-with-progress>
  </div>
</template>

<script>
import MoneyOriginDocuments from './MoneyOriginDocuments.vue'
import LayoutWithProgress from '@/layouts/WithProgress.vue'

export default {
  name: 'update-money-origin-documents',
  components: {
    LayoutWithProgress,
    MoneyOriginDocuments
  },
  data () {
    return {
      progress: {
        hideProgressIndicator: true,
        value: 0,
        max: 0
      },
      statusText: '',
      isLoading: false,
      isSkipButtonVisible: true
    }
  },
  methods: {
    close () {
      this.$refs.moneyOriginDocuments.close()
    }
  }
}
</script>

<style lang="scss">
  .update {
    &__container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 100%;
      background-color: $white;
    }

    &__content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }

    &__component {
      margin: 54px 30px 0;

      @media (min-width: $desktop-view-breaking-point-wide) {
        margin: 63px 0 0;
      }

      @media (max-width: 359px) {
        margin: 54px 15px 0;
      }
    }
  }
</style>
