<template>
  <bb-modal
    v-if="isSigningRequestExpiredModal"
    centerVertically
    fullMobile
    :visible="visible"
    flavor="card"
    publicSize="m"
    class="signing-request-expired-modal"
    @close="close"
   >
    <template #heading>
      <div class="signing-request-expired-modal__title">
        {{ title }}
      </div>
    </template>
    <div class="signing-request-expired-modal__content">
      {{ content }}
    </div>
    <div class="signing-request-expired-modal__contact">
      <bb-link
        :to="`tel:${customerServicePhone}`"
      >
        {{ customerServicePhone }}
      </bb-link>
    </div>
    <template #footer>
      <bb-ticket-stub class="nm-x-20 nm-b-md-20">
        <bb-button
          :label="cta"
          display="ticket"
          color="white"
          @click="close"
        />
      </bb-ticket-stub>
    </template>
  </bb-modal>
  <bb-modal
    v-else
    centerVertically
    :visible="visible"
    @close="close"
    flavor="user"
    publicSize="m"
    color="white"
    class="error-modal"
  >
    <div class="error-modal__wrapper">
      <div class="error-modal__title f-small">{{title}}</div>
      <div class="error-modal__content f-smallest">{{content}}</div>
      <div v-if="showInfoBox" class="info f-smallest">
        <slot name="info-content">
          <div class="icon">
            <bb-icon size="fluid" name="ui-phone-outline" fill="green"></bb-icon>
          </div>
          <div v-if="customerServicePhone">
            <span class="text"><translate>Please contact our Customer Service</translate></span>
            <span class="phone">{{ customerServicePhone }}</span>
          </div>
        </slot>
      </div>
    </div>
  </bb-modal>
</template>

<script>
import { mapState } from 'pinia'
import { useRootStore } from '../store/root'

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    showInfoBox: {
      type: Boolean,
      default: true
    },
    title: String,
    content: String,
    cta: String,
    modalType: String
  },
  computed: {
    ...mapState(useRootStore, ['channelConfig']),
    config () {
      return this.channelConfig
    },
    customerServicePhone () {
      return this.config.phone
    },
    isSigningRequestExpiredModal () {
      return this.modalType === 'signing-request-expired-modal'
    }
  },
  mounted () {
    this.track()
  },
  methods: {
    close () {
      this.$emit('close')
    },
    track () {
      const action = 'error-page-' + this.modalType.replace(/\s|_+/g, '_')
      this.$tracker.action(action, { modalTitle: this.title })
    }
  }
}
</script>

<style lang="scss">
.error-modal {
  .modal {
    overflow: visible;

    .modal__body {
      padding: 30px;
      padding-top: 50px;
    }
  }

  &__title {
    color: $gray-50;
    font-weight: 500;
    margin-bottom: 24px;
  }

  &__content {
    color: $gray-90;
  }

  .info {
    margin-top: 34px;
    background: $gray-20;
    border-radius: 5px;
    padding: 14px;
    color: $gray-90;
    display: flex;

    .phone {
      display: block;
      color: $blue;
      font-weight: bold;
    }

    .icon {
      width: 25px;
      margin-right: 14px;
    }
  }
}

.signing-request-expired-modal {
  text-align: center;
  font-size: 18px;
  line-height: 24px;
  font-family: $gotham-book;

  &__title {
    color: $blue;
  }

  &__content {
    margin-top: 60px;
    color: $gray;

    @media (max-width: $mobile-view-breaking-point) {
      padding: 0 55px;
      font-size: 16px;
      margin-top: 20px;
    }
  }

  &__contact {
    margin: 33px 0 20px;
    color: $mint;
  }
}
</style>
