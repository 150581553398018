<template>
    <layout :loading="isLoading">
      <bb-container fluid v-if="!isLoading">
        <bb-row no-gutters>
          <bb-col cols="24" md="11" class="m-b-30">
            <section-box :title="translations.title" icon="client-verified">
              {{ subtitleTranslation }}
            </section-box>
          </bb-col>
          <bb-col cols="24" md="13" class="f-gotham-book f-color-navy">
            <template v-if="verificationData.isF2fIdentified">
                <div class="m-b-20 f-small" v-sanitize.basic="translations.info_verified" />
                <div class="d-flex f-small f-gotham-medium f-color-mint">
                    <bb-icon name="ui-check-circle-outline" size="24" fill="mint" class="m-r-15" />
                    <div>{{ translations.verified }}</div>
                </div>
            </template>
            <template v-else>
                <div class="f-gotham-medium f-color-gray-90 m-b-20">
                    {{ translations.account_limit }}
                </div>
                <div class="m-b-20 f-small" v-sanitize.basic="translations.info" />
                <limit-item v-bind="limitItemProps" />
                <div class="m-b-20 f-small">
                    {{ translations.to_remove_limit }}
                </div>
                <div class="f-gotham-bold f-color-gray-90 m-b-20">
                    {{ translations.video_verification }}
                </div>
                <div class="m-b-20 f-small">
                    {{ translations.video_verification_info }}
                </div>
                <div class="f-gotham-medium f-smallest m-b-30">
                    <div class="d-flex justify-content-start align-items-center m-b-10">
                        <bb-icon name="ui-phone-outline" size="18" fill="mint" class="m-r-10" />
                        <div class="m-r-10">
                            {{ translations.call_us }}
                        </div>
                        <bb-link :to="`tel:${this.channelConfig.phone}`" no-underline>
                            {{ this.channelConfig.phone }}
                        </bb-link>
                    </div>
                    <div class="d-flex justify-content-start align-items-center m-b-10">
                        <bb-icon name="ui-email-outline" size="18" fill="mint" class="m-r-10" />
                        <div class="m-r-10">
                            {{ translations.write_us }}
                        </div>
                        <bb-link :to="`mailto:${this.channelConfig.email}`" no-underline>
                            {{ this.channelConfig.email }}
                        </bb-link>
                    </div>
                </div>
                <div class="m-b-20 f-small">
                    {{ translations.other_option }}
                </div>
                <div class="f-small" v-sanitize.basic="translations.office_info" />
            </template>
          </bb-col>
        </bb-row>
      </bb-container>
    </layout>
  </template>

<script>
import { mapActions, mapState } from 'pinia'
import { useAccountStore } from '@account/store/accountStore'
import Layout from './Layout'
import SectionBox from '@account/components/SectionBox'
import LimitItem from '@credit-card/components/LimitItem.vue'
import '@bigbank/interface-components/dist/svg/client/personal'
import '@bigbank/interface-components/dist/svg/client/verified'
import { formatMoneyWithCurrency } from '@/plugins/numformat'
import { useRootStore } from '../../../store/root'

export default {
  name: 'account-verification',
  data () {
    return {
      isLoading: true
    }
  },
  components: {
    Layout,
    SectionBox,
    LimitItem
  },
  computed: {
    ...mapState(useRootStore, ['currency', 'locale', 'channelConfig']),
    ...mapState(useAccountStore, ['verificationData']),
    translations () {
      return {
        title: this.$pgettext('account_verification', 'Account verification'),
        subtitle: this.$pgettext('account_verification', 'Verify your account to increase you Account Limit'),
        subtitle_verified: this.$pgettext('account_verification', 'Status of the account verification'),
        account_limit: this.$pgettext('account_verification', 'Account limit'),
        info: this.$pgettext(
          'account_verification',
          'Everyone who has not done Face to Face verification, has a monthly spending limit of {amount}. ' +
            'This includes all the transactions on all the accounts and the credit card(s)’ global limits.'
        )
          .replace(
            '{amount}',
            `<span class="f-nowrap">${formatMoneyWithCurrency(this.verificationData.limitAmount, this.currency, this.locale, { fractionDigits: 0 })}</span>`
          ),
        info_verified: this.$pgettext(
          'account_verification',
          'In order to make transactions for more than {amount} ' +
            'in one calendar month, customer needs to be identified'
        )
          .replace(
            '{amount}',
            `<span class="f-nowrap">${formatMoneyWithCurrency(this.verificationData.limitAmount, this.currency, this.locale, { fractionDigits: 0 })}</span>`
          ),
        verified: this.$pgettext(
          'account_verification', 'You are verified! ' +
          'Your accounts don\'t have transactional limit amount.'
        ),
        monthly_limit: this.$pgettext('account_verification', 'Monthly limit'),
        to_remove_limit: this.$pgettext('account_verification', 'In order to remove the limit, you can do:'),
        video_verification: this.$pgettext('account_verification', 'Video verification'),
        video_verification_info: this.$pgettext(
          'account_verification',
          'We support video-call verification. ' +
          'Make an appointment by giving a call or writing an email.'
        ),
        call_us: this.$pgettext('account_verification', 'Give us a call:'),
        write_us: this.$pgettext('account_verification', 'Write us:'),
        other_option: this.$pgettext('account_verification', 'Other option is to visit our office:'),
        office_info: this.$pgettext(
          'account_verification',
          'Our office is located in {address}. ' +
          'The office is open Monday - Friday 10:00-15:00. Please take valid identification document with you.'
        )
          .replace(
            '{address}',
            `<span class="f-gotham-medium f-nowrap">${this.$pgettext('account_verification', 'Tallinn, Tartu mnt 18 (I floor)')}</span>`
          )
      }
    },
    subtitleTranslation () {
      return this.verificationData.isF2fIdentified
        ? this.translations.subtitle_verified
        : this.translations.subtitle
    },
    limitItemProps () {
      return {
        title: this.translations.monthly_limit,
        name: 'account-monthly-limit',
        class: 'm-b-20',
        flavor: 'account-verification',
        availableAmount: this.verificationData.availableAmount,
        limitAmount: this.verificationData.limitAmount,
        usedAmount: this.verificationData.usedAmount
      }
    }
  },
  methods: {
    ...mapActions(useAccountStore, ['getVerificationData']),
    setLoading (flag) { this.isLoading = flag }
  },
  async mounted () {
    try {
      await this.getVerificationData({ forceReload: true })
    } catch (e) {
      this.$handleError(e)
    } finally {
      this.setLoading(false)
    }
  }
}
</script>
