<template>
  <bb-modal
    visible
    @close="onClose"
    flavor="card"
    color="white"
    publicSize="m"
    fullMobile>
    <template v-if="loading">
      <div class="loader">
        <bb-spinner />
      </div>
    </template>
    <template v-else>
      <div class="content">
        <slot name="content"/>
        <div class="title" v-if="title">{{title}}</div>
        <slot name="after-title" />
        <div class="subtitle" v-if="subtitle">{{subtitle}}</div>
      </div>
    </template>
    <bb-ticket-stub v-if="!loading" slot="footer" class="nm-x-20 nm-b-md-20">
      <bb-button display="ticket" color="white" @click="onClose">
        <span class="color-green">
          <translate>Got it</translate>
        </span></bb-button>
    </bb-ticket-stub>
  </bb-modal>
</template>

<script>
export default {
  name: 'default-modal',
  data () {
    return {
    }
  },
  props: {
    loading: {
      required: false,
      default: false
    },
    title: {
      required: false
    },
    subtitle: {
      required: false
    }
  },
  methods: {
    onClose () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  color: $blue;
  font-size: $default-font-size;
  font-family: $gotham-bold;
  text-align: center;
}

.subtitle {
  color: $gray;
  text-align: center;
  font-size: $font-size-small;
  font-family: $gotham-book;
  margin-top: 35px;
}

.details-box {
  margin-top: 30px;
  display: block;
}
</style>
