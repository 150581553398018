import { featureRouteGuard } from '@/plugins/routeGuards'
import Documents from './Documents.vue'
import UploadIdDocuments from './UploadIdDocuments.vue'
import AccountData from './AccountData.vue'
import AccountVerification from './AccountVerification.vue'
import ConfirmData from './ConfirmData.vue'
import TaxReports from './TaxReports.vue'
import PaymentsView from './payments/PaymentsView.vue'
import AccountStatementView from './payments/MyTransactions.vue'
import OpenCurrentAccountView from './OpenCurrentAccount.vue'
import MyTransactionsDetailsModal from './payments/MyTransactionsDetailsModal.vue'
import AccountsLimits from './AccountsLimits.vue'
import { AccountRouteName } from '../const'
import { useRootStore } from '@/store/root'

export default [
  {
    path: '',
    name: AccountRouteName.PersonalData,
    component: function () {
      const rootStore = useRootStore()

      if (rootStore.isCompany) {
        return import('@/modules/account/views/corporate/PersonalDataCorporate.vue')
      }

      return import('@/modules/account/views/PersonalData.vue')
    },
    beforeEnter: featureRouteGuard('myAccount')
  },
  {
    path: 'limits',
    name: AccountRouteName.AccountsLimits,
    component: AccountsLimits,
    beforeEnter: async function (to, from, next) {
      const rootStore = useRootStore()

      if (!rootStore.hasCurrentAccount) {
        next(from)
        return
      }

      await featureRouteGuard(['enableAccountsLimitsTab'])(to, from, next)
    }
  },
  {
    path: 'documents',
    name: AccountRouteName.Documents,
    component: Documents,
    beforeEnter: featureRouteGuard(['enableDocumentsTab', 'enableAdditionalDocumentUpload'])
  },
  {
    path: 'documents/upload',
    name: AccountRouteName.DocumentsUpload,
    component: UploadIdDocuments
  },
  {
    path: 'data',
    name: AccountRouteName.AccountData,
    component: AccountData,
    beforeEnter: featureRouteGuard('hasUsername')
  },
  {
    path: 'verification',
    name: AccountRouteName.AccountVerification,
    component: AccountVerification,
    beforeEnter: featureRouteGuard('enableUnverifiedCustomerAdditionalLimits')
  },
  {
    path: 'confirm',
    name: AccountRouteName.ConfirmData,
    component: ConfirmData,
    beforeEnter: featureRouteGuard('enableConfirmData')
  },
  {
    path: 'tax-report',
    name: AccountRouteName.TaxReports,
    component: TaxReports,
    beforeEnter: featureRouteGuard('enableTaxReports')
  },
  {
    path: '/dashboard',
    name: AccountRouteName.Dashboard,
    component: function () {
      const rootStore = useRootStore()

      if (rootStore.isCompany) {
        return import('@/modules/account/views/corporate/DashboardView.vue')
      }

      return import('@/modules/account/views/dashboard/DashboardView.vue')
    },
    beforeEnter: featureRouteGuard('enableDashboard')
  },
  {
    path: '/payments',
    name: AccountRouteName.Payments,
    component: PaymentsView,
    beforeEnter: async function (to, from, next) {
      const rootStore = useRootStore()

      if (!rootStore.hasCurrentAccount) {
        next(from)
        return
      }

      await featureRouteGuard('enablePayments')(to, from, next)
    }
  },
  {
    path: '/my-transactions',
    name: AccountRouteName.MyTransactions,
    component: AccountStatementView,
    beforeEnter: featureRouteGuard('enableMyTransactions'),
    children: [
      {
        path: '/transaction/:transactionId',
        name: 'my-transaction-details-modal',
        component: MyTransactionsDetailsModal,
        beforeEnter: featureRouteGuard('enableMyTransactions')
      }
    ]
  },
  {
    path: '/open-account',
    name: AccountRouteName.OpenCurrentAccount,
    component: OpenCurrentAccountView
  }
]
