<template>
  <div class="password-input">
    <bb-input
      :type="revealPassword ? 'text' : 'password'"
      :name="name"
      :label="passwordInputLabel"
      :data-vv-as="translations.password"
      :value="value"
      @input="handleInput"
      @focus="scrollIntoViewOnFocus"
      v-validate="'required'"
      :class="{'hide-border': hideBorder}"
    ></bb-input>
    <bb-icon
      v-show="!errors.has(name)"
      @mousedown.native="revealPassword = true"
      @mouseup.native="revealPassword = false"
      @touchstart.native="revealPassword = true"
      @touchend.native="revealPassword = false"
      class="view-icon"
      :name="revealPassword ? 'ui-eye-outline' : 'ui-eye'" fill="gray-70"></bb-icon>
  </div>
</template>

<script>
import '@bigbank/interface-components/dist/svg/ui/eye'
import '@bigbank/interface-components/dist/svg/ui/eye-outline'

export default {
  inject: ['$validator'],
  data () {
    return {
      revealPassword: false,
      translations: {
        password: this.$gettext('Password')
      }
    }
  },
  props: {
    value: {
      default: ''
    },
    label: {
      default: ''
    },
    name: {
      default: 'password'
    },
    hideBorder: {
      default: true
    }
  },
  methods: {
    handleInput (value) {
      this.$emit('input', value)
    },
    scrollIntoViewOnFocus () {
      // Not using refs here because in shadow mode refs return a VueElement and not a DOMElement
      // Also not straight up queryselectin' here because
      // 1) shadowRoot is hidden from document by default and
      // 2) I want to keep compat with fallback
      const passwordElementLabel =
        (document.querySelector('.password-input') || document.querySelector('bb-login').shadowRoot.querySelector('.password-input'))
          .querySelector('label')
      if (window) window.scrollTo(0, passwordElementLabel.getBoundingClientRect().top)
    }
  },
  computed: {
    passwordInputLabel () {
      return this.label || this.translations.password
    }
  }
}
</script>

<style lang="scss">
.password-input {
  position: relative;

  .hide-border {
    input {
      border: 0 !important;
    }
  }

  .view-icon {
    position: absolute;
    right: 0;
    top: 46px;
  }
}
</style>
