<template>
  <layout-with-progress
    :loading="loading"
    @close="close"
    v-bind="{ progress }">
    <contract-conditions
      class="m-t-60"
      v-if="currentDeposit"
      :deposit="currentDeposit"
      :transferDetails="{}"
      :is-demand-deposit="isDemandDeposit"
    />
  </layout-with-progress>
</template>

<script>
import ContractConditions from '@deposits/components/ContractConditions'
import { DepositType, DepositRouteName } from '@deposits/const'
import { mapState, mapActions } from 'pinia'
import { useDepositStore } from '@deposits/store/depositStore'

export default {
  name: 'signing-view',
  components: {
    ContractConditions
  },
  methods: {
    ...mapActions(useDepositStore, ['initiateSigning']),
    close () {
      if (this.isDemandDeposit) {
        this.$router.push({ name: DepositRouteName.DemandDeposits })
      } else {
        this.$router.push({ name: DepositRouteName.TermDeposits })
      }
    }
  },
  computed: {
    ...mapState(useDepositStore, ['loading', 'progress', 'currentDeposit']),
    isDemandDeposit () {
      if (this.currentDeposit) {
        return this.currentDeposit.depositType === DepositType.Demand
      }

      return false
    }
  },
  created () {
    this.initiateSigning(this.$route.params.contractId)
  }
}
</script>
