import { mapState, mapActions } from 'pinia'
import { CardType } from '@bigbank/dc-common/clients/http/credit-card/credit-card.enums'
import { every, isNull } from 'lodash'
import { useRootStore } from '../../../../store/root'
import { useLoanStore } from '@/modules/loan/store/loanStore'

export default {
  computed: {
    ...mapState(useRootStore, ['isMobile']),
    ...mapState(useLoanStore, ['creditCardPricing', 'creditCardContract']),
    contract () {
      return this.creditCardContract
    },
    isVirtualCard () {
      return this.card.type === CardType.VIRTUAL
    },
    isPhysicalCard () {
      return this.card.type === CardType.PHYSICAL_AND_VIRTUAL
    },
    isValidReasonToReplace () {
      return !!this.reason
    },
    isCloseNotificationVisible () {
      return this.isReplacementCardOrdered || !this.orderNewCardCheckbox
    },
    submitButtonProps () {
      const label = this.submitButtonText
      const needsAddressValidation = this.orderNewCardCheckbox
      const disabled = this.isLoading ||
        !this.isValidReasonToReplace ||
        (needsAddressValidation && !this.isCustomerContactAddressValid)

      return {
        display: 'block',
        label,
        disabled,
        loading: this.isLoading,
        size: this.isMobile ? 'lg' : undefined,
        class: this.isMobile ? 'm-s-a' : undefined,
        corner: this.isMobile ? 'sharp' : undefined
      }
    },
    radioButtonOptions () {
      return Object.entries(this.translations.options).map(([type, label]) => {
        return {
          value: type,
          leftColumn: {
            label
          }
        }
      }).filter(({ value }) => this.isPhysicalCard || this.radioGroupOptions.includes(value))
    },
    modalTitle () {
      return this.isVirtualCard ? this.translations.modalTitleVirtual : this.translations.modalTitle
    },
    replacementFee () {
      return this.creditCardPricing.cardReplacement ?? 0
    }
  },
  methods: {
    ...mapActions(useLoanStore, [
      'refreshCreditCard',
      'blockCreditCard',
      'getCreditCards',
      'fetchCreditCardPricing'
    ]),
    setOrderNewCardCheckbox (flag) { this.orderNewCardCheckbox = flag },
    setLoading (flag) { this.isLoading = flag },
    onModalClosed () {
      this.$emit('onModalClosed')
    }
  },
  async mounted () {
    if (every(this.creditCardPricing, isNull)) {
      await this.fetchCreditCardPricing()
    }
  }
}
