<template>
  <bb-footer
    class="footer"
    condensed
    :links="links"
    :contact-links="contact"
    :social-links="socialLinks"
  />
</template>

<script>
import { mapState } from 'pinia'
import { translationPicker } from '@/plugins/translations'
import { useRootStore } from '../store/root'
import '@bigbank/interface-components/dist/svg/social/facebook'
import '@bigbank/interface-components/dist/svg/social/wordpress'
import '@bigbank/interface-components/dist/svg/social/instagram'
import '@bigbank/interface-components/dist/svg/ui/phone-outline'
import '@bigbank/interface-components/dist/svg/ui/email-outline'
import '@bigbank/interface-components/dist/svg/ui/arrow-up'

export default {
  name: 'selfservice-footer',
  computed: {
    ...mapState(useRootStore, ['language', 'channelConfig']),
    config () {
      return this.channelConfig
    },
    disclaimer () {
      return this.$gettext('Bigbank AS.')
    },
    contact () {
      return [{
        label: this.$gettext('Give us a call:'),
        icon: 'ui-phone-outline',
        link: {
          label: this.config.phone,
          to: `tel:${this.config.phone}`
        }
      }, {
        label: this.$gettext('Write us:'),
        icon: 'ui-email-outline',
        link: {
          label: this.config.email,
          to: `mailto:${this.config.email}`
        }
      }]
    },
    socialLinks () {
      return [{
        icon: 'social-facebook',
        to: this.url(this.config.facebookUrl),
        visible: !!this.config.facebookUrl
      }, {
        icon: 'social-wordpress',
        to: this.url(this.config.blogUrl),
        visible: !!this.config.blogUrl
      }, {
        icon: 'social-instagram',
        to: this.url(this.config.instagramUrl),
        visible: !!this.config.instagramUrl
      }].filter(({ visible }) => visible)
    },
    links () {
      const links = [{
        label: this.$gettext('Price List'),
        to: this.url(this.config.priceListUrl),
        visible: !!this.config.priceListUrl
      }, {
        label: this.$gettext('Terms & Conditions'),
        to: this.url(this.config.termsAndConditionsUrl),
        visible: !!this.config.termsAndConditionsUrl
      }, {
        label: this.$gettext('Principal Terms & Conditions of Deposits'),
        to: this.url(this.config.depositTermsUrl),
        visible: !!this.config.depositTermsUrl
      }, {
        label: this.$gettext('Cookie policy'),
        to: this.url(this.config.cookiePolicyUrl),
        visible: !!this.config.cookiePolicyUrl
      }, {
        label: this.$gettext('FAQ'),
        to: this.url(this.config.faqUrl),
        visible: !!this.config.faqUrl
      }, {
        label: this.$pgettext('link_label', 'Security'),
        to: this.url(this.config.securityUrl),
        visible: !!this.config.securityUrl
      }]

      return links.filter(({ visible }) => visible)
    }
  },
  methods: {
    url (obj) {
      return translationPicker(obj, this.language)
    }
  }
}
</script>

<style lang="scss">
.footer {
  border-top: 1px solid $gray-30;
}
</style>
