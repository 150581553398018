export const evrotrustErrorCodes = Object.freeze({
  TIMEOUT: 'TIMEOUT',
  INVALID_CERTIFICATE: 'INVALID_CERTIFICATE',
  USER_CANCELLED: 'USER_CANCELLED',
  END_PROVIDER_MISCONFIGURATION: 'END_PROVIDER_MISCONFIGURATION',
  DEVICE_ERROR: 'DEVICE_ERROR',
  WRONG_VERIFICATION_CODE: 'WRONG_VERIFICATION_CODE',
  PROVIDER_ERROR: 'PROVIDER_ERROR',
  SIGNER_CERTIFICATE_REVOKED: 'SIGNER_CERTIFICATE_REVOKED',
  SIGNER_CERTIFICATE_UNKNOWN: 'SIGNER_CERTIFICATE_UNKNOWN',
  INVALID_AUTHENTICATION: 'INVALID_AUTHENTICATION',
  INTERNAL_ERROR: 'INTERNAL_ERROR'
})
