<template>
  <div>
    <bb-modal
      visible
      @close="onClose()"
      flavor="card"
      unset-overflow
      full-mobile
      color="white"
      publicSize="m"
      class="funding-options"
    >
      <template v-if="isLoading">
        <div class="funding-options__loader">
          <bb-spinner />
        </div>
      </template>
      <template v-else>
        <div slot="heading" class="funding-options__title">{{ translations.title }}</div>
        <div class="funding-options__content">
          <div class="funding-options__description">{{ translations.description }}</div>
          <bb-detailed-radio
            v-model="fundingOption"
            name="subcomponent"
            :options="options"
          />
          <bb-separator class="m-t-30 m-b-30" dashed />

          <template v-if="fundingOption === FundingOption.External">
            <div class="funding-options__subtitle">{{translations.subtitle}}</div>
            <bb-box thin class="funding-options__details-box">
              <div>
                <list-item :title="translations.recipient" :value="name" :titleUppercase="false" name="beneficary-name" />
                <list-item :title="translations.iban" :value="iban" :titleUppercase="false"  name="iban" />
                <list-item :title="translations.referenceNumber" :value="reference" :titleUppercase="false"  name="reference-number" />
              </div>
            </bb-box>
          </template>
          <template v-if="fundingOption === FundingOption.DemandDeposit">
            <div v-if="showWarning" class="funding-options__warning">{{ translations.warning }}</div>
            <div v-else>
              <bb-form @progress="onProgress">
                <bb-big-input-currency
                  class="m-t-20 m-b-10"
                  background
                  name="withdrawalAmount"
                  v-model="form.amount"
                  placeholder="0,00"
                  :label="translations.availableBalance + ' ' + availableBalance"
                  v-validate="amountValidationRules"
                  :suffix="'\xa0' + currencySymbol"
                  :data-vv-as="translations.labels.amount"
                />
                <div slot="submit" slot-scope="{}" />
              </bb-form>
              <bb-input
                name="iban"
                class="m-t-15"
                :value="demandDeposit.accountIban"
                :readonly="true"
                :label="translations.account"
              />
            </div>
          </template>
          <bb-input
            v-if="!showWarning || fundingOption === FundingOption.External"
            name="summary"
            :value="amount"
            :readonly="true"
            :label="translations.summary"
            :help-active="true"
            :help-text="translations.helpText"
          />
        </div>
      </template>
      <div class="p-x-md-10 p-b-md-5" slot="footer">
        <bb-button
          @click="onButtonClick"
          display="block"
          :size="$screen.md ? 'md': 'lg'"
          class="m-s-a"
          corner="sharpMobileOnly"
          v-bind="button.props">
          {{ button.text }}
        </bb-button>
      </div>
    </bb-modal>
    <close-demand-deposit-modal
      :translations="translations"
      @close="onCloseConfirmCloseContract"
      @closeContract="closeContract"
      v-if="showCloseContractModal"
      :modalProps="modalProps"
    />
    <sign-modal
      v-if="showSignModal"
      :deposit="defaultDemandDeposit"
      :chosen-sign-method="chosenSignMethod"
      :signingRequestId="signingRequestId"
      @signingSuccessful="complete"
      @signingError="onSigningError"
      @close="onCloseSignModal"
    />
    <close-demand-deposit-error-modal
      v-if="showCloseContractErrorModal"
      :modal-props="modalProps"
      @close="showCloseContractErrorModal = false"
    />
  </div>
</template>

<script>
import { formatMoneyWithCurrency } from '@/plugins/numformat'
import currencyDefinitions from '@deposits/utils/getCurrency'
import ListItem from '@/components/ListItem.vue'
import { BbDetailedRadio, BbSeparator } from '@bigbank/interface-components'
import DemandDepositWithdrawMixin from '@deposits/mixins/demandDepositWithdrawMixin'
import SigningMixin from '@/mixins/signingMixin'
import SignModal from '@deposits/components/SignModal'
import CloseDemandDepositModal from '@deposits/components/CloseDemandDepositModal.vue'
import { DepositRouteName } from '@deposits/const'
import { api } from '@deposits/api'
import { useRootStore } from '@/store/root'
import { mapState, mapActions } from 'pinia'
import { useDepositStore } from '@deposits/store/depositStore'
import CloseDemandDepositErrorModal from '@deposits/components/CloseDemandDepositErrorModal'

const FundingOption = Object.freeze({
  External: 1,
  DemandDeposit: 2
})

export default {
  components: { ListItem, BbDetailedRadio, BbSeparator, SignModal, CloseDemandDepositModal, CloseDemandDepositErrorModal },
  mixins: [DemandDepositWithdrawMixin, SigningMixin],
  name: 'funding-options',
  data () {
    return {
      FundingOption,
      redirectWithoutDemandDeposit: false,
      showCloseContractErrorModal: false,
      fundingOption: FundingOption.External
    }
  },
  computed: {
    ...mapState(useRootStore, ['isCompany', 'locale', 'isChannelSE']),
    ...mapState(useDepositStore, ['demandDeposit', 'defaultDemandDeposit', 'deposits']),
    showWarning () {
      return !this.demandDeposit || this.demandDeposit.state !== 'ACTIVE'
    },
    amount () {
      return formatMoneyWithCurrency(this.transferDetails.amount, this.transferCurrency, this.locale)
    },
    name () {
      return this.transferDetails ? this.transferDetails.receiverName : '-'
    },
    iban () {
      return this.transferDetails ? this.transferDetails.iban : '-'
    },
    reference () {
      return this.transferDetails ? this.transferDetails.referenceNumber : '-'
    },
    bic () {
      return this.transferDetails ? this.transferDetails.bic : '-'
    },
    transferCurrency () {
      return currencyDefinitions(this.transferDetails.currencyCode)
    },
    transferDetails () {
      return this.deposits[this.$route.params.depositId]?.transferDetails ?? {}
    },
    translations () {
      const translations = {
        description: this.$pgettext('funding_options', 'Choose a suitable option to deposit funds'),
        title: this.$pgettext('funding_options', 'Waiting for funds'),
        close: this.$pgettext('funding_options', 'Close'),
        transfer: this.$pgettext('funding_options', 'Transfer'),
        subtitle: this.$pgettext('funding_options', 'Here are your payment details'),
        recipient: this.$pgettext('funding_options', 'Recipient'),
        iban: this.$pgettext('funding_options', 'IBAN'),
        referenceNumber: this.$pgettext('funding_options', 'Reference number'),
        summary: this.$pgettext('funding_options', 'Term Deposit summary'),
        helpText: this.$pgettext('funding_options', 'Please be aware that the contract summary will not change if you have already paid (part) of the deposit amount'),
        warning: this.$pgettext('funding_options', 'Unfortunately, you don\'t have a savings deposit to use as a funding option for your term deposit.'),
        transferFrom: this.$pgettext('funding_options', 'Transfer from Saving Deposit'),
        availableBalance: this.$pgettext('funding_options', 'Available balance'),
        transferSuccessful: this.$pgettext('funding_options', 'We have recieved your transfer request and are processing it.'),
        account: this.$pgettext('funding_options', 'Saving Deposit account'),
        labels: {
          amount: this.$pgettext('funding_options', 'Withdrawal amount')
        },
        yes: this.$pgettext('withdraw_form_close_contract', 'YES'),
        no: this.$pgettext('withdraw_form_close_contract', 'NO')
      }

      if (this.isCompany) {
        Object.assign(translations, {
          warning: this.$pgettext('corporate_funding_options', 'Unfortunately, you don\'t have a savings deposit to use as a funding option for your term deposit.'),
          transferFrom: this.$pgettext('corporate_funding_options', 'Transfer from Saving Deposit'),
          account: this.$pgettext('corporate_funding_options', 'Saving Deposit account')
        })
      }

      return translations
    },
    options () {
      return [{
        value: FundingOption.External,
        leftColumn: {
          label: this.$pgettext('funding_options', 'Use external bank account')
        }
      }, {
        value: FundingOption.DemandDeposit,
        leftColumn: {
          label: this.translations.transferFrom
        }
      }]
    },
    button () {
      return {
        text: FundingOption.DemandDeposit === this.fundingOption ? this.translations.transfer : this.translations.close,
        props: {
          disabled: this.isLoading || ((!this.demandDeposit || !this.isValid) && FundingOption.DemandDeposit === this.fundingOption)
        }
      }
    }
  },
  methods: {
    ...mapActions(useDepositStore, ['getDeposits']),
    onClose () {
      this.$router.push({ name: DepositRouteName.TermDeposits })
    },
    onButtonClick () {
      if (FundingOption.DemandDeposit === this.fundingOption) {
        this.submit()
      } else {
        this.onClose()
      }
    },
    async submit () {
      if (this.signingLoading || !await this.$validator.validate()) {
        return
      }

      if (parseFloat(this.withdrawalInfo.availableForWithdrawal) <= this.form.amount && !this.hasSeenCloseContractModal) {
        this.setShowCloseContractModal(true)
        return
      }

      this.setSigningLoading(true)

      try {
        const response = await api.initWithdraw(this.defaultDemandDeposit.id, this.form)

        this.setSigningMethod(response.method)
        this.setSigningRequestId(response.signingRequestId)
        this.toggleSigningModalVisibility(response.isSigningNeeded)

        !response.isSigningNeeded && this.complete()
      } catch (error) {
        this.handleError(error)
      } finally {
        this.setSigningLoading(false)
      }
    },
    handleError (err) {
      if (err.err_code === 'SAVINGS_DEPOSIT_ACCOUNT_IS_LINKED_TO_TERM_DEPOSIT_CONTRACT') {
        this.showCloseContractErrorModal = true
        this.toggleSigningModalVisibility(false)
      }
    },
    onSigningError (err) {
      this.handleError(err)
    },
    async complete () {
      await this.getDeposits()
      this.onClose()
      this.$notify({ text: this.translations.transferSuccessful, duration: 10000 })
      this.afterComplete()
    },
    onCloseSignModal () {
      this.toggleSigningModalVisibility(false)
      this.form.closeContract = null
    }
  },
  mounted () {
    this.form.account = this.transferDetails.iban
  }
}
</script>

<style lang="scss">
.funding-options {
  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__title {
    color: $blue;
    font-size: $font-size-small;
    font-family: $gotham-medium;
    text-align: center;
  }

  &__subtitle {
    color: $gray;
    text-align: center;
    font-size: $font-size-small;
    line-height: $font-size-small;
    font-family: $gotham-medium;
    margin-top: 15px;
    margin-bottom: 16px;
  }

  &__description {
    color: $gray;
    text-align: center;
    font-size: $h4-size-mobile;
    margin-bottom: 30px;

    @media (min-width: $desktop-view-breaking-point) {
      font-size: $h4-size;
    }
  }

   &__warning {
    color: $red;
    text-align: center;
    font-size: $default-font-size;
  }

  &__details-box {
    margin-bottom: 15px;

    > div {
      margin: auto;

      @media (min-width: $desktop-view-breaking-point) {
        width: 260px;
      }
    }
  }

  &__amount {
    margin-top: 15px;
    text-align: center;
    font-family: $gotham-medium;
    font-size: $h3-size;
    line-height: $h3-size;
  }

  &__amount-subtitle {
    color: $gray-70;
    font-family: $gotham-bold;
    text-align: center;
    font-size: $font-size-tiny;
    text-transform: uppercase;
    padding-bottom: 15px;
  }

  .bb-detailed-radio--layout-vertical .bb-detailed-radio__item:not(:last-child) {
    margin-bottom: 8px;
  }
}
</style>
