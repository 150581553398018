import { mapState, mapActions } from 'pinia'
import isNull from 'lodash/isNull'
import { InternalLoanType } from '@bigbank/dc-common/enums/productTypes'
import { useRootStore } from '../store/root'

const RouteNames = {
  MY_CREDIT_CARD: '/my-credit-card',
  MY_ACTIVE_LOAN: '/my-active-loans'
}

export default {
  computed: {
    ...mapState(useRootStore, ['contractsCount', 'isDebtNotificationHidden']),
    localTranslations () {
      return {
        loans_debt_view: this.$pgettext('loans_debt_view', 'Your contract nr %{contractNumber} is in debt. Credit Card transactions are blocked.'),
        loans_debt_view_link: this.$pgettext('loans_debt_view_link', 'My loans'),
        credit_card_debt_view: this.$pgettext('credit_card_debt_view', 'Your contract nr %{contractNumber} is in debt. Credit Card transactions are blocked.'),
        credit_card_debt_view_link: this.$pgettext('credit_card_debt_view_link', 'My credit cards')
      }
    }
  },
  methods: {
    ...mapActions(useRootStore, ['getContractsCount']),
    createNotifications () {
      if (isNull(this.contractsCount)) {
        return
      }
      Object.entries(this.contractsCount.debt.contractsInDebt).forEach(([type, contracts]) => {
        contracts.forEach(contractNumber => {
          if (type === InternalLoanType.CREDIT_CARD) {
            this.$notify({
              text: this.$gettextInterpolate(this.localTranslations.credit_card_debt_view, { contractNumber }),
              type: 'warning',
              link: {
                name: this.localTranslations.credit_card_debt_view_link,
                route: RouteNames.MY_CREDIT_CARD,
                visible: this.isLinkVisible(RouteNames.MY_CREDIT_CARD)
              },
              update: true,
              key: 'debt_' + contractNumber
            })
          } else {
            this.$notify({
              text: this.$gettextInterpolate(this.localTranslations.loans_debt_view, { contractNumber }),
              type: 'warning',
              link: {
                name: this.localTranslations.loans_debt_view_link,
                route: RouteNames.MY_ACTIVE_LOAN,
                visible: this.isLinkVisible(RouteNames.MY_ACTIVE_LOAN)
              },
              update: true,
              key: 'debt_' + contractNumber
            })
          }
        })
      })
    },
    isLinkVisible (route) {
      return route !== this.$route.path
    }
  },
  async mounted () {
    await this.getContractsCount()
    if (!this.isDebtNotificationHidden) {
      this.createNotifications()
    }
  }
}
