<script>
import { mapState } from 'pinia'
import { useRootStore } from '../../../store/root'
import { SIGNING_METHODS } from '@bigbank/dc-common/enums/loginAndSigningMethods'
import BankIdSigning from './Signing/BankIdSigning'
import CustomerIdSigning from './Signing/CustomerIdSigning'
import IdCardSigning from './Signing/IdCardSigning'
import SignicatSigning from './Signing/SignicatSigning'
import SmartIdSigning from './Signing/SmartIdSigning'
import MobileIdSigning from './Signing/MobileIdSigning'
import EvrotrustSigning from './Signing/EvrotrustSigning'
import EparakstsSigning from './Signing/EparakstsSigning'
import SigningErrorMessages from '@/components/signing/SigningErrorMessages'

export default {
  name: 'sign-modal-parent',
  components: {
    BankIdSigning,
    EparakstsSigning,
    CustomerIdSigning,
    EvrotrustSigning,
    MobileIdSigning,
    SmartIdSigning,
    IdCardSigning,
    SignicatSigning,
    SigningErrorMessages
  },
  render: function (createElement) {
    const args = {
      props: {
        ...this.$props,
        deposit: {
          ...this.deposit
        },
        channel: this.channel,
        visible: true
      },
      on: {
        signingSuccessful: () => this.$emit('signingSuccessful'),
        signingError: (error) => this.$emit('signingError', error),
        close: () => this.$emit('close')
      }
    }

    switch (this.chosenSignMethod) {
      case SIGNING_METHODS.BANK_ID:
        return createElement(...(['bank-id-signing', args]))
      case SIGNING_METHODS.EPARAKSTS:
        return createElement(...(['eparaksts-signing', args]))
      case SIGNING_METHODS.CUSTOMER_ID:
        return createElement(...(['customer-id-signing', args]))
      case SIGNING_METHODS.EVROTRUST:
        return createElement(...(['evrotrust-signing', args]))
      case SIGNING_METHODS.SMART_ID:
        return createElement(...(['smart-id-signing', args]))
      case SIGNING_METHODS.MOBILE_ID:
        return createElement(...(['mobile-id-signing', args]))
      case SIGNING_METHODS.ID_CARD:
        return createElement(...(['id-card-signing', args]))
      case SIGNING_METHODS.SIGNICAT:
        return createElement(...(['signicat-signing', args]))
      case 'emu':
        return createElement(...(['signing-error-messages', {
          ...args,
          props: { ...args.props, code: 'EMU_SIGN_METHOD' }
        }]))
      default:
        return createElement(...(['signing-error-messages', {
          ...args,
          props: { ...args.props, code: 'UNKNOWN_SIGN_METHOD' }
        }]))
    }
  },
  computed: {
    ...mapState(useRootStore, ['channel'])
  },
  props: {
    deposit: {
      required: true
    },
    chosenSignMethod: {
      required: true
    },
    initMethod: {
      required: false
    },
    signingRequestId: {
      required: false
    }
  }
}
</script>
