import { isNil } from 'lodash'
import { CreditCardRouteName } from '@/modules/credit-card/views/const'

// Mixin used for credit card signings that redirect customer back to self-service
// Currently this means Eparaksts and potentially Signicat
export default {
  computed: {
    signingRequestIdFromQuery () {
      return this.$route.query.signingRequestId
    }
  },
  methods: {
    hasReturnedAfterSigning () {
      return !isNil(this.$route.query.callback)
    },
    hasReturnedAfterSigningAction (action) {
      // eslint-disable-next-line
      const isThisCard = () => (this.card?.id ?? this.cardId) == this.$route.query.cardId
      const isAction = action => this.$route.query.action === action
      return this.hasReturnedAfterSigning() && isThisCard() && isAction(action)
    }
  },
  beforeDestroy () {
    if (this.hasReturnedAfterSigning()) {
      this.$router.push({ name: CreditCardRouteName.CreditCard })
    }
  }
}
