<template>
  <div class="limit-items">
    <div class="limit-items__heading" :class="{'limit-items__heading--ru' : isLocaleRu }" v-if="global">
      {{ this.translations[this.periodType] }}
    </div>
    <limit-item class="item" v-for="(limit, i) in limitItems" :key="i" v-bind="limit" />
  </div>
</template>

<script>
import LimitItem from './LimitItem.vue'
import { CardTransactionType, CardPeriodType } from '@bigbank/dc-common/clients/http/credit-card/credit-card.enums'
import { SectionNames } from './credit-card.const'
import { mapState } from 'pinia'
import { Language } from '@bigbank/dc-common/config'
import { useRootStore } from '../../../store/root'

export default {
  components: {
    LimitItem
  },
  props: {
    card: {
      required: true
    },
    global: {
      type: Boolean,
      default: false
    },
    month: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState(useRootStore, ['locale']),
    translations () {
      return {
        [CardPeriodType.DAY]: this.$pgettext('credit_card_card_limits', 'Daily limits'),
        [CardPeriodType.MONTH]: this.$pgettext('credit_card_card_limits', 'Monthly limits'),
        [CardTransactionType.CASH]: this.$pgettext('credit_card_card_limits', 'Cash withdrawal'),
        [CardTransactionType.REGULAR]: this.$pgettext('credit_card_card_limits', 'Card Purchases'),
        [CardTransactionType.ONLINE]: this.$pgettext('credit_card_card_limits', 'Online Purchases'),
        [CardTransactionType.CONTACTLESS]: this.$pgettext('credit_card_card_limits', 'Contactless purchases'),
        [CardTransactionType.GLOBAL]: this.$pgettext('credit_card_card_limits', 'Global limit')
      }
    },
    periodType () {
      return this.month ? CardPeriodType.MONTH : CardPeriodType.DAY
    },
    limitItems () {
      const limits = (this.card?.limits ?? [])
      const limitFinder = (transactionType) => limits.find(limit => limit.periodType === this.periodType && limit.transactionType === transactionType)

      const transactionTypes = this.global
        ? [CardTransactionType.GLOBAL]
        : [
            CardTransactionType.REGULAR,
            CardTransactionType.CASH,
            CardTransactionType.ONLINE,
            CardTransactionType.CONTACTLESS
          ]

      return transactionTypes
        .filter(limitFinder)
        .map(transactionType => {
          const limit = limitFinder(transactionType)
          const isEnabled = !!this.card?.enabledLimits?.find(limit => limit === transactionType)
          const name = `${SectionNames[transactionType]}-${this.periodType.toLowerCase()}`

          return {
            title: this.translations[transactionType],
            limitAmount: limit?.limitAmount ?? 0,
            usedAmount: limit?.usedAmount ?? 0,
            availableAmount: limit?.availableAmount ?? 0,
            name,
            isEnabled
          }
        })
    },
    isLocaleRu () {
      return this.locale === Language.ru
    }
  }
}
</script>

<style lang="scss" scoped>
.limit-items {
  width: 100%;
  display: inline-flex;
  flex-flow: column wrap;

  &__heading {
    font-family: $gotham-medium;
    font-size: $font-size-smallest;
    line-height: 16px;
    margin-bottom: 16px;

    @media (max-width: $desktop-view-breaking-point) {
      &--ru {
        width: min-content;
      }
    }
  }

  .item {
    box-sizing: border-box;
    margin-bottom: 12px;

    @media (max-width: $desktop-view-breaking-point) {
      margin-bottom: 16px;
    }
  }
}
</style>
