<template>
  <id-card-signing
      :visible="isReadyToSign"
      :start-signing="startSigning"
      :complete-signing="completeSigning"
      :lang="language"
      v-on="$listeners"
  ></id-card-signing>
</template>
<script>
import api from '@loan/api'
import { mapState } from 'pinia'
import { useRootStore } from '../../../../store/root'
import IdCardSigning from '@/components/signing/IdCard'

export default {
  name: 'id-card-signing-loan',
  components: {
    IdCardSigning
  },
  props: {
    contract: {
      required: true
    },
    signingRequestId: {
      required: true
    }
  },
  computed: {
    ...mapState(useRootStore, ['language']),
    isReadyToSign () {
      return !!this.signingRequestId
    }
  },
  methods: {
    startSigning (certificateBase64, supportedSignatureAlgorithms) {
      return api.startIdCardSigning(this.contract.id, this.signingRequestId, {
        certificateBase64,
        supportedSignatureAlgorithms
      })
    },
    completeSigning (signatureBase64) {
      return api.completeIdCardSigning(this.contract.id, this.signingRequestId, signatureBase64)
    }
  }
}
</script>
