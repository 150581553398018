<template>
  <layout-full-page :loading="appLoading">
    <contract-details v-if="contractDetails" :deposit="contractDetails"></contract-details>
  </layout-full-page>
</template>

<script>
import ContractDetails from '../components/ContractDetails'
import { mapState, mapActions } from 'pinia'
import { useDepositStore } from '@deposits/store/depositStore'

export default {
  name: 'contract-details-view',
  components: { ContractDetails },
  async mounted () {
    await this.fetchContractDetails({ contractId: this.$route.params.contractId })
  },
  methods: {
    ...mapActions(useDepositStore, ['fetchContractDetails'])
  },
  computed: {
    ...mapState(useDepositStore, ['appLoading', 'contractDetails'])
  }
}
</script>
