<template>
    <div>
        <bb-application-contract-card
          v-bind="cardProps"
          @primaryButtonClick="openInvoiceDetails"
          :class="spacerClass"/>
        <promise-to-pay-modal
          :visible="showInvoiceDetailsModal"
          :promiseToPay="promiseToPay"
          @close="showInvoiceDetailsModal = !showInvoiceDetailsModal"
        />
    </div>
</template>

<script>
import PromiseToPayModal from '@/modules/loan/components/PromiseToPayModal'
import { LOAN_DASHBOARD } from '@/TrackingActions'
import { mapState } from 'pinia'
import { useLoanStore } from '@loan/store/loanStore'

export default {
  name: 'promise-to-pay-card',
  data () {
    return {
      showInvoiceDetailsModal: false
    }
  },
  components: {
    PromiseToPayModal
  },
  props: {
    spacerClass: {
      required: false,
      default: 'm-b-15'
    }
  },
  computed: {
    ...mapState(useLoanStore, ['promiseToPay']),
    cardProps () {
      return {
        title: this.$pgettext('promise_to_pay_card', 'Payment agreement'),
        description: this.$pgettext('promise_to_pay_card', 'You have payment agreement with us'),
        type: 'offer',
        icon: { name: 'client-wave', fill: 'default' },
        primaryButton: {
          label: this.$gettext('Payment details')
        }
      }
    }
  },
  methods: {
    openInvoiceDetails () {
      this.showInvoiceDetailsModal = true
    }
  },
  mounted () {
    this.$tracker.action(LOAN_DASHBOARD.VIEW_PTP_CARD)
  }
}
</script>
