<template>
  <modal
    @close="onClose"
    :loading="loading"
    :title="translations.title"
    :subtitle="subtitle"
  >
    <bb-box thin class="details-box" v-if="proceedingDetails">
      <list-item :title="beneficiaryName" :titleUppercase="false" :titleBold="true" />
      <list-item :title="translations.iban" :value="beneficiaryIban" />
      <list-item v-if="bic" :title="translations.bic" :value="bic" />
      <list-item v-if="referenceNumber" :title="translations.referenceNumber" :value="referenceNumber" />
      <list-item v-if="phone" :title="translations.phone" :value="phone" />
      <list-item v-if="email" :title="translations.email" :value="email" />
    </bb-box>
  </modal>
</template>

<script>
import Modal from '@/components/DefaultModal'
import ListItem from '@/components/ListItem'
import api from '@loan/api'

export default {
  name: 'loan-requisites',
  components: {
    ListItem,
    Modal
  },
  data () {
    return {
      loading: true,
      proceedingDetails: null
    }
  },
  props: {
    contract: {
      required: true
    }
  },
  computed: {
    translations () {
      return {
        title: this.$pgettext('requisites', 'Requisites'),
        iban: this.$pgettext('requisites', 'IBAN'),
        referenceNumber: this.$pgettext('requisites', 'Reference number'),
        bic: this.$pgettext('requisites', 'BIC'),
        phone: this.$pgettext('requisites', 'Phone'),
        email: this.$pgettext('requisites', 'Email'),
        subtitle: {
          BAILIFF: this.$pgettext('REQUISITES_BALIFF', 'Description for requisites of baliff'),
          COL_AGENCY: this.$pgettext('REQUISITES_COLLECTION', 'Description for requisites of collection'),
          DEFAULT: this.$pgettext('REQUSITES_DEFAULT', 'Description for requisites of default')
        }
      }
    },
    transferDetails () {
      return this.proceedingDetails.transferDetails
    },
    beneficiaryName () {
      return this.transferDetails.beneficiaryName
    },
    beneficiaryIban () {
      return this.transferDetails.beneficiaryIban
    },
    bic () {
      return this.transferDetails.beneficiaryBic
    },
    referenceNumber () {
      return this.transferDetails.referenceNumber
    },
    subtitle () {
      return this.translations.subtitle[this.contract.proceedingTypeCode] || this.translations.subtitle.DEFAULT
    },
    partnerDetails () {
      return this.proceedingDetails.partnerDetails || {}
    },
    email () {
      return this.partnerDetails.email
    },
    phone () {
      return this.partnerDetails.phoneNumber
    },
    shouldShowProceedingDetails () {
      return !['COL_AGENCY', 'BAILIFF'].includes(this.contract.proceedingTypeCode)
    }
  },
  methods: {
    onClose () {
      this.$emit('close')
    },
    async loadDetails () {
      this.loading = true
      this.proceedingDetails = this.shouldShowProceedingDetails ? await api.getProceedingDetails(this.contract.id) : null
      this.loading = false
    }
  },
  mounted () {
    this.loadDetails()
  }
}
</script>

<style lang="scss" scoped>
.details-box {
  margin-top: 15px;
  display: block;
}
</style>
