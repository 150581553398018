<template>
  <div>
    <bb-modal :visible="!showSignModal" @close="onClose()" flavor="card" color="white" publicSize="m" fullMobile>
      <template #heading>
        <div class="title">{{ title }}</div>
      </template>
      <template v-if="isLoading">
        <div class="loader p-t-40 p-b-50">
          <bb-spinner />
        </div>
      </template>
      <div v-else class="content">
        <template v-if="step === 1">
          <div class="icon-wrapper">
            <bb-icon animated fill="original" name="illustration-session-expired" size="fluid" />
          </div>
          <div class="text">
            <p>{{ translations.closeNotice() }}</p>
            <p class="m-t-20"><translate>Given you have any remaining balance it will be transferred to your account in the next 2-3 working days.</translate></p>
            <p class="m-t-20"><strong><translate>Would you like also to proceed?</translate></strong></p>
          </div>
           <div slot="footer" class="buttons">
            <bb-button inverted color="blue" :label="translations.yes" @click="next()" />
            <bb-button inverted color="blue" :label="translations.no" @click="closeContract(false)" />
          </div>
        </template>
        <bb-form v-else @progress="onProgress">
          <span class="label f-small m-t-10 color-gray">{{ translations.labels.selectTheAccount }}</span>
          <bank-account-input
            class="m-t-10"
            type="select"
            :showAddNewAccount="false"
            :showToAnotherAccount="false"
            @input="onBankAccountInput"
            :validatedAccounts="validatedAccounts"
            :value="form.account"
            :validateIbanCountry="false"
            autoFill
            flavor="deposit"
          />
          <template v-if="selectedAccount">
            <bb-input
              name="beneficary"
              type="text"
              :label="translations.labels.beneficary"
              :value="beneficary"
              :readonly="true"
            />
            <bb-input
              name="bic"
              type="text"
              :label="translations.labels.bicSwift"
              :value="bic"
              :readonly="true"
            />
          </template>
          <bb-banner type="info" :visible="true" permanent class="m-t-20 f-small color-gray">
            {{ translations.infoAboutServicingAccount }}&nbsp;<a href="/my-deposits/term">{{ translations.checkHere }}</a>
          </bb-banner>
          <bb-list-group class="m-t-20">
            <bb-list-item condensed :title="translations.labels.availableAmount" :titleUppercase="false">
              {{availableAmount}}
            </bb-list-item>
            <bb-list-item condensed :title="translations.labels.interestAmount" :titleUppercase="false">
              {{interestAmount}}
            </bb-list-item>
            <bb-list-item
              v-if="withdrawalInfo.accruedInterestTax != 0"
              condensed
              :title="translations.labels.interestTaxAmount"
              :titleUppercase="false">
              {{interestAmount}}
            </bb-list-item>
            <bb-list-item condensed :title="translations.labels.totalAmount" :titleUppercase="false">
              {{totalAmount}}
            </bb-list-item>
          </bb-list-group>
          <div slot="submit" slot-scope="{}" />
        </bb-form>
      </div>
      <template #footer v-if="step === 2">
        <bb-button
          class="no-padding m-s-a w-100"
          corner="sharpMobileOnly"
          @click="closeContract(true)"
          v-bind="button"
          :disabled="!form.account"
        >
          {{translations.confirm}}
        </bb-button>
      </template>
    </bb-modal>
    <sign-modal
      v-if="showSignModal"
      :deposit="deposit"
      :chosen-sign-method="chosenSignMethod"
      :signingRequestId="signingRequestId"
      @signingSuccessful="complete"
      @signingError="onSigningError"
      @close="closeSignModal"
    />
    <close-demand-deposit-error-modal
      v-if="showCloseContractErrorModal"
      :modal-props="modalProps"
      @close="showCloseContractErrorModal = false"
    />
  </div>
</template>

<script>
import { api } from '@deposits/api'
import SignModal from '@deposits/components/SignModal'
import '@bigbank/interface-components/dist/svg/illustration/session-expired'
import BankAccountInput from '@/components/Input/BankAccountInput'
import DemandDepositWithdrawMixin from './../mixins/demandDepositWithdrawMixin'
import SigningMixin from '@/mixins/signingMixin'
import { DepositRouteName } from '@deposits/const'
import { useRootStore } from '../../../store/root'
import { useDepositStore } from '@deposits/store/depositStore'
import { mapState, mapActions } from 'pinia'
import CloseDemandDepositErrorModal from './CloseDemandDepositErrorModal'

export default {
  name: 'close-deposit',
  components: { SignModal, BankAccountInput, CloseDemandDepositErrorModal },
  mixins: [DemandDepositWithdrawMixin, SigningMixin],
  data () {
    return {
      step: 1,
      showCloseContractErrorModal: false,
      form: {
        account: null
      }
    }
  },
  props: {
    deposit: {
      required: true
    }
  },
  computed: {
    ...mapState(useRootStore, ['isCompany']),
    translations () {
      return {
        confirm: this.$pgettext('close_contract_modal', 'Confirm'),
        transfer: this.$pgettext('close_contract_modal', 'Transfer'),
        cancel: this.$pgettext('close_contract_modal', 'Cancel'),
        yes: this.$pgettext('close_contract_modal', 'YES'),
        no: this.$pgettext('close_contract_modal', 'NO'),
        closeSuccessful: this.$pgettext('close_contract_modal', 'We received your account close request and are proccessing it!'),
        step1Title: this.$pgettext('close_contract_modal', 'Close contract'),
        step2Title: this.$pgettext('close_contract_modal', 'Withdraw account selection'),
        labels: {
          selectTheAccount: this.$pgettext('close_contract_modal', 'Select the account for deposit and accumulated amount payout'),
          beneficary: this.$pgettext('close_contract_modal', 'Beneficary'),
          bicSwift: this.$pgettext('close_contract_modal', 'BIC/SWIFT'),
          availableAmount: this.$pgettext('close_contract_modal', 'Balance sum'),
          interestAmount: this.$pgettext('close_contract_modal', 'Accrued interest sum'),
          interestTaxAmount: this.$pgettext('close_contract_modal', 'Accrued interest tax sum'),
          totalAmount: this.$pgettext('close_contract_modal', 'Total sum to account')
        },
        infoAboutServicingAccount: this.$pgettext('close_contract_modal', 'Please make sure your term deposit is not using savings deposit as servicing account'),
        checkHere: this.$pgettext('close_contract_modal', 'Check here'),
        closeNotice: () => {
          if (this.isCompany) {
            return this.$pgettext('corporate_close_contract_modal', 'You are about to close your demand deposit account.')
          }
          return this.$pgettext('close_contract_modal', 'You are about to close your demand deposit account.')
        }
      }
    },
    title () {
      return this.step === 1 ? this.translations.step1Title : this.translations.step2Title
    },
    totalAmount () {
      const interestAmount = (this.withdrawalInfo && this.withdrawalInfo.accruedInterest) || 0
      const interestTaxAmount = (this.withdrawalInfo && this.withdrawalInfo.accruedInterestTax) || 0
      const availableAmount = (this.withdrawalInfo && this.withdrawalInfo.availableForWithdrawal) || 0

      return this.formatMoney(availableAmount + interestAmount - interestTaxAmount)
    },
    interestAmount () {
      return this.formatMoney((this.withdrawalInfo && this.withdrawalInfo.accruedInterest) || 0)
    },
    interestTaxAmount () {
      return this.formatMoney((this.withdrawalInfo && this.withdrawalInfo.accruedInterestTax) || 0)
    },
    availableAmount () {
      return this.formatMoney(this.withdrawalInfo ? this.withdrawalInfo.availableForWithdrawal : 0)
    }
  },
  methods: {
    ...mapActions(useDepositStore, ['getDeposits']),
    onBankAccountInput (value) {
      this.form.account = value
    },
    next () {
      this.step = 2
    },
    async closeContract (decision) {
      if (!decision) {
        return this.onClose()
      }

      if (this.signingLoading) {
        return
      }

      this.signingLoading = true
      try {
        const initResponse = await api.initClose(this.deposit.id, {
          ...this.form
        })
        this.chosenSignMethod = initResponse.method
        this.signingRequestId = initResponse.signingRequestId
        this.showSignModal = true
      } catch (err) {
        this.handleError(err)
        this.onClose()
      } finally {
        this.signingLoading = false
      }
    },
    handleError (err) {
      if (err.err_code === 'SAVINGS_DEPOSIT_ACCOUNT_IS_LINKED_TO_TERM_DEPOSIT_CONTRACT') {
        this.showCloseContractErrorModal = true
        this.toggleSigningModalVisibility(false)
      }
    },
    onClose () {
      this.step = 1
      this.$emit('close')
    },
    async complete () {
      this.$notify({ text: this.translations.closeSuccessful, duration: 10000 })
      await this.getDeposits(true)
      this.$router.push({ name: DepositRouteName.DemandDeposits })
    },
    onSigningError (err) {
      this.handleError(err)
    },
    closeSignModal () {
      this.showSignModal = false
    }
  },
  mounted () {
    this.step = 1
  }
}
</script>

<style lang="scss" scoped>
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  color: $blue;
  font-size: $default-font-size;
  font-family: $gotham-medium;
  text-align: center;
}

.icon-wrapper {
  max-width: 90%;
  margin: auto;
}

.text {
  font-size: $font-size-small;
  font-family: $gotham-book;
  text-align: center;
  color: $gray;
  margin-top: 40px;
  margin-bottom: 40px;
}

.buttons {
  display: flex;
  justify-content: space-between;
  padding: 0 1.875rem 1.875rem;

  .bb-button {
    min-width: 45%;
  }
}
</style>
