<template>
  <with-horizontal-menu :loading="loading" :tabRoutes="tabRoutes" :onBackButtonClick="onBackButtonClick">
    <router-view></router-view>
  </with-horizontal-menu>
</template>

<script>
import { REVOLVING_DASHBOARD } from '@/TrackingActions'
import { RouteNames } from './const'
import WithHorizontalMenu from '@/layouts/WithHorizontalMenu'
import { CreditCardRouteName } from '@credit-card/views/const'
import { mapState, mapActions } from 'pinia'
import { useLoanStore } from '@loan/store/loanStore'

const tabNames = {
  details: 'loan-contract-details',
  schedule: 'contract-schedule',
  invoices: 'loan-contract-invoices'
}

export default {
  name: 'contract-details-view',
  components: { WithHorizontalMenu },
  data () {
    return {
      routes: {
        REVOLVING: { name: RouteNames.REVOLVING_LOAN },
        LOAN: { name: RouteNames.ACTIVE_LOANS },
        CREDIT_CARD: { name: CreditCardRouteName.CreditCard }
      }
    }
  },
  computed: {
    ...mapState(useLoanStore, ['loading', 'loanTypes', 'contract']),
    activeTab () {
      if (this.isRevolvingLoan) {
        let trackerAction = ''
        switch (this.$route.name) {
          case 'loan-contract-invoices':
            trackerAction = REVOLVING_DASHBOARD.REVOLVING_VIEW_INVOICES_HISTORY
            break
          case 'loan-contract-details':
            trackerAction = REVOLVING_DASHBOARD.REVOLVING_VIEW_CONTRACT_DETAILS
        }

        this.$tracker.action(
          trackerAction,
          {}, { contractId: this.contract.id.toString() }
        )
      }

      return this.$route.name
    },
    tabRoutes () {
      return [
        {
          tab: tabNames.details,
          title: this.$pgettext('contract_tabs', 'Contract Details'),
          active: this.activeTab === tabNames.details,
          path: { name: tabNames.details },
          visible: true
        },
        {
          tab: tabNames.schedule,
          title: this.$pgettext('contract_tabs', 'Schedule'),
          active: this.activeTab === tabNames.schedule,
          path: { name: tabNames.schedule },
          visible: [
            this.loanTypes.LOAN,
            this.loanTypes.HOUSING,
            this.loanTypes.REAL_ESTATE,
            this.loanTypes.LEASING
          ].includes(this.contract?.typeCode) && !this.contract?.isTerminated
        },
        {
          tab: tabNames.invoices,
          title: this.$pgettext('contract_tabs', 'Invoices'),
          active: this.activeTab === tabNames.invoices,
          path: { name: tabNames.invoices },
          visible: [this.loanTypes.REVOLVING].includes(this.contract?.typeCode)
        }
      ].filter(({ visible }) => visible)
    },
    isRevolvingLoan () {
      return this.contract?.typeCode === this.loanTypes.REVOLVING
    }
  },
  methods: {
    ...mapActions(useLoanStore, ['getContract']),
    onBackButtonClick (previousRoute) {
      return previousRoute ??
        this.routes[this.contract?.typeCode] ??
        this.routes.LOAN
    }
  },
  async mounted () {
    await this.getContract(this.$route.params.contractId)
  }
}
</script>
