<template>
  <div class="payment-frequency-container">
    <div v-if="hasSingleOption" class="single-option-text">
      {{ singleOptionText }}
    </div>
    <bb-radio name="radio-default" v-else equalWidth :options="options" :label="translations.question" :value="value" :selected="value" @input="input"></bb-radio>
  </div>
</template>

<script>
export default {
  name: 'PaymentFrequency',
  data () {
    return {
      translations: {
        month: this.$gettext('Once a Month'),
        year: this.$gettext('Once a Year'),
        end: this.$gettext('End of Period'),
        question: this.$gettext('I want my interest payment frequency to be') + ':',
        singleFrequency: this.$gettext('Your interest payment frequency to be') + ':'
      }
    }
  },
  props: {
    value: String,
    accountDefaults: {
      type: Array,
      required: false
    },
    limits: {
      type: Object,
      required: true
    }
  },
  computed: {
    options () {
      const paymentOptions = []

      if (this.limits.END.hasValidPeriods && this.hasOffers('END')) {
        paymentOptions.push({
          value: 'END',
          text: this.translations.end
        })
      }

      if (this.limits.YEAR.hasValidPeriods && this.hasOffers('YEAR')) {
        paymentOptions.push({
          value: 'YEAR',
          text: this.translations.year
        })
      }

      if (this.limits.MONTH.hasValidPeriods && this.hasOffers('MONTH')) {
        paymentOptions.push({
          value: 'MONTH',
          text: this.translations.month
        })
      }

      return paymentOptions
    },
    hasSingleOption () {
      return this.options.length <= 1
    },
    singleOptionText () {
      this.$emit('input', this.options[0].value)
      return this.translations.singleFrequency + ' ' + this.options[0].text
    }
  },
  methods: {
    input (value) {
      this.$emit('input', value)
    },
    hasOffers (code) {
      if (!this.accountDefaults) {
        return true
      }
      return this.accountDefaults.filter((offer) => offer.interestPaymentFrequencyCode === code).length > 0
    }
  }
}
</script>

<style lang="scss">
  .payment-frequency-container {
    max-width: 380px;
  }

  .grp-radio-container legend {
    font-size: $mobile-small-font-size;
    text-align: left;
    margin: 0;
  }

  .grp-radio-container {
    margin-top: 15px;

    @media (min-width: $desktop-view-breaking-point + 1) {
      margin-top: 35px;
    }
  }

  .single-option-text {
    font-family: $gotham-medium;
    font-size: rem($font-size-smallest);
    color: $gray-90;
    text-align: center;
    margin-top: 15px;

    @media (min-width: $desktop-view-breaking-point + 1) {
      margin-top: 35px;
    }
  }
</style>
