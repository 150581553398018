import ConsumerLoanPayBack from '@/modules/loan/views/ConsumerLoanPayBack.vue'
import RevolvingLoanPayback from '@/modules/loan/views/revolving/PayBack.vue'
import { mapState } from 'pinia'
import { useLoanStore } from '@/modules/loan/store/loanStore'

export default {
  computed: {
    ...mapState(useLoanStore, ['featureFlags', 'loanTypes']),
    isLoan () {
      return [
        this.loanTypes.LOAN,
        this.loanTypes.HOUSING,
        this.loanTypes.REAL_ESTATE,
        this.loanTypes.LEASING
      ].includes(this.contract.typeCode)
    },
    isRevolvingLoan () {
      return [this.loanTypes.REVOLVING].includes(this.contract.typeCode)
    },
    computedInvoiceDetailsModal () {
      if (this.isLoan) {
        return ConsumerLoanPayBack
      } else {
        if (this.isRevolvingLoan) {
          return RevolvingLoanPayback
        }
      }
    }
  }
}
