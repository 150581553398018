<template>
  <div class="list-item">
    <div class="list-item__title" :class="{'force--uppercase': titleUppercase, 'force--bold': titleBold}">{{title}}</div>
    <div class="list-item__value-container">
      <div class="list-item__value"><slot>{{value}}</slot></div>
      <div class="list-item__button" v-if="showButton">
        <slot name="button">
          <info-box-copy-button size="sm" :item="value" />
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import InfoBoxCopyButton from '@deposits/components/InfoBoxCopyButton.vue'

export default {
  name: 'list-item',
  components: {
    InfoBoxCopyButton
  },
  props: {
    title: {
      type: String,
      required: false
    },
    value: {
      required: false
    },
    titleUppercase: {
      required: false,
      type: Boolean,
      default: true
    },
    titleBold: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  computed: {
    showButton () {
      return this.value || !!this.$slots.button
    }
  }
}
</script>

<style lang="scss">
.list-item {
  margin-bottom: 17px;

  &:last-child {
    margin-bottom: 0;
  }

  &__title {
    color: $gray;
    font-size: rem($font-size-tiny);
    text-align: left;
    font-family: $gotham-medium;

    &.force--uppercase {
      text-transform: uppercase;
    }

    &.force--bold {
      color: $gray;
      font-size: $font-size-small;
    }
  }

  &__value-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__value {
    color: $blue;
    font-family: $gotham-bold;
    font-size: rem($font-size-small);
    white-space: nowrap;
  }

  &__button {
    text-align: right;
    white-space: nowrap;
  }
}
</style>
