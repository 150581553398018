const AccountRouteName = Object.freeze({
  PersonalData: 'account/personal-data',
  Documents: 'account/documents',
  DocumentsUpload: 'account/documents/upload',
  AccountData: 'account/data',
  AccountVerification: 'account/verification',
  ConfirmData: 'account/confirm',
  TaxReports: 'account/tax-report',
  Payments: 'payments',
  Dashboard: 'dashboard',
  MyTransactions: 'my-transactions',
  OpenCurrentAccount: 'open-current-account',
  AccountsLimits: 'limits'
})

export { AccountRouteName }
