<template>
  <div>
    <h4 class='f-gotham-bold f-color-navy align-center'
        :data-testid="isResultSuccess ? 'payment-success': 'payment-failure'"
    >{{ titleText }}</h4>
    <h3 :class="`f-color-${paymentAmountColor} align-center`">{{ formattedPaymentAmount }}</h3>
    <bb-list-item
        class="m-t-50"
        :title="translations.recipientName"
        no-top-border
        condensed
        :titleUppercase="false"
    >
      {{ paymentRequest.recipientName }}
    </bb-list-item>
    <bb-list-item
        :title="translations.recipientIban"
        condensed
        :titleUppercase="false"
    >
      {{ paymentRequest.recipientIban }}
    </bb-list-item>
    <bb-list-item
        :title="translations.time"
        condensed
        :titleUppercase="false"
    >
      {{ formattedTime }}
    </bb-list-item>
    <bb-list-item
      :title="translations.description"
       condensed
      :titleUppercase="false"
      :slot-nowrap="false"
    >
      {{ paymentRequest.description }}
    </bb-list-item>
    <bb-list-item
        :title="translations.referenceNumber"
        condensed
        :titleUppercase="false"
    >
      {{ paymentRequest.referenceNumber }}
    </bb-list-item>
    <bb-button
      v-if="isSaveTransactionAsButtonVisible"
      data-testid="save-transaction-button"
      display="block"
      color="blue"
      inverted
      class="m-t-40"
      @click="handleTransactionSaving"
    >
      {{ translations.saveTransactionAs }}
    </bb-button>
    <bb-button
      v-else-if="!isResultSuccess"
      data-testid="start-new-transaction-button"
      display="block"
      color="blue"
      inverted
      class="m-t-40"
      @click="handleNewTransaction"
    >
      {{ translations.startNewTransaction }}
    </bb-button>
    <bb-button
      v-if="isResultSuccess"
      data-testid="start-new-transaction-button"
      display="block"
      class="m-t-15"
      @click="handleNewTransaction"
    >
      {{ translations.startNewTransaction }}
    </bb-button>
    <bb-button
      v-else
      data-testid="transaction-retry-button"
      display="block"
      class="m-t-15"
      @click="handleTransactionRetry"
    >
      {{ translations.trySendingMoneyAgain }}
    </bb-button>
  </div>
</template>

<script>
import { BbListItem, BbButton } from '@bigbank/interface-components'
import { formatMoneyWithCurrency } from '@/plugins/numformat'
import { mapState, mapActions } from 'pinia'
import dayjs from 'dayjs'
import { PaymentStatus } from './const'
import { useRootStore } from '../../../../store/root'
import { usePaymentStore } from '@account/store/paymentStore'

export default {
  components: {
    BbListItem,
    BbButton
  },
  computed: {
    ...mapState(useRootStore, ['featureFlags', 'currency', 'locale']),
    ...mapState(usePaymentStore, ['paymentDetails', 'paymentStatus']),
    translations () {
      return {
        paymentSuccessful: this.$pgettext('payment_result', 'Payment successful!'),
        paymentFailed: this.$pgettext('payment_result', 'Payment failed!'),
        recipientName: this.$pgettext('payment_result', 'Recipient name'),
        recipientIban: this.$pgettext('payment_result', 'Recipient IBAN'),
        time: this.$pgettext('payment_result', 'Time'),
        description: this.$pgettext('payment_result', 'Description'),
        referenceNumber: this.$pgettext('payment_result', 'Reference number'),
        saveTransactionAs: this.$pgettext('payment_result', 'Save transactions as ...'),
        startNewTransaction: this.$pgettext('payment_result', 'Start new transaction'),
        trySendingMoneyAgain: this.$pgettext('payment_result', 'Try sending money again')
      }
    },
    paymentRequest () {
      return this.paymentDetails
    },
    isResultSuccess () {
      return this.paymentStatus === PaymentStatus.Success
    },
    isSaveTransactionAsButtonVisible () {
      return this.featureFlags.disableSaveTransactionOnAccountStatement !== true && this.isResultSuccess
    },
    titleText () {
      return this.isResultSuccess
        ? this.translations.paymentSuccessful
        : this.translations.paymentFailed
    },
    formattedPaymentAmount () {
      return formatMoneyWithCurrency(this.paymentDetails.amount, this.currency, this.locale)
    },
    formattedTime () {
      return dayjs(this.paymentRequest.createdTime).format('DD.MM.YYYY HH:mm')
    },
    paymentAmountColor () {
      return this.isResultSuccess
        ? 'mint'
        : 'red'
    }
  },
  methods: {
    ...mapActions(usePaymentStore, ['resetPaymentDetails', 'redirectToPaymentForm']),
    handleTransactionSaving () {
      console.log('implement me in some ticket OwO')
    },
    handleNewTransaction () {
      this.resetPaymentDetails()
      this.redirectToPaymentForm()
    },
    handleTransactionRetry () {
      this.redirectToPaymentForm()
    }
  }
}
</script>
