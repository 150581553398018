<template>
  <div>
    <bb-sticky bleedMobileOnly class="custom-hidden-desktop" :contained="false">
      <bb-button display="block" uppercase :class="{'form-correction': onForm}" class="continue-button"
                 @click="handleClickWrapper"
                 :disabled="isDisabled && !useDisabledColors"
                 :loading="loading"
                 :color="isDisabled && useDisabledColors ? 'gray' : 'mint'"
      >{{ text }}</bb-button>
    </bb-sticky>
    <bb-sticky class="my-hidden-mobile">
      <div class="continue-button__wrapper">
        <bb-button display="block" uppercase class="continue-button"
                   @click="handleClickWrapper"
                   :disabled="isDisabled && !useDisabledColors"
                   :loading="loading"
                   :color="isDisabled && useDisabledColors ? 'gray' : 'mint'"
        >{{ text }}</bb-button>
      </div>
    </bb-sticky>
  </div>
</template>

<script>
export default {
  name: 'continue-button',
  data () {
    return {
      loading: false
    }
  },
  props: {
    text: {
      type: String,
      required: true
    },
    handleClick: {
      type: Function,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false
    },
    onForm: {
      type: Boolean,
      required: false
    },
    useDisabledColors: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    isDisabled () {
      return this.disabled
    }
  },
  methods: {
    async handleClickWrapper () {
      this.loading = true
      await this.handleClick()
      this.loading = false
    }
  }
}

</script>

<style scoped lang="scss">
  .continue-button {
    &__wrapper {
      display: flex;
      justify-content: center;
    }

    margin-top: 0;
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 1;

    @media (min-width: $desktop-view-breaking-point + 1) {
      position: unset;
      bottom: unset;
      z-index: unset;
      max-width: 380px;
      width: 100%;
    }
  }

  .form-correction {
    @media (max-width: $desktop-view-breaking-point) {
      margin-left: -30px;
    }

    @media (max-width: 359px) {
      margin-left: -15px;
    }
  }
</style>
