<template>
  <bb-box
    class="corporate-clarification-banner m-b-35 bg-white border-radius-10 border-none f-smallest f-color-gray p-x-30 p-y-20"
  >
    <bb-slotted-paragraph
      :template="bannerText"
      template-marker="##"
    >
      <template #default="{ text }">
        <bb-link :to="channelConfig.corporate.helpUrl" target="_blank">
          {{ text }}
        </bb-link>
      </template>
    </bb-slotted-paragraph>
  </bb-box>
</template>

<script>
import { mapState } from 'pinia'
import { useRootStore } from '../../store/root'
import { BbSlottedParagraph } from '@bigbank/interface-components'

export default {
  name: 'corporate-clarification-banner',
  components: {
    BbSlottedParagraph
  },
  props: {
    product: {
      validator (value) {
        return ['loan', 'deposit'].includes(value)
      }
    }
  },
  computed: {
    ...mapState(useRootStore, ['isCompany', 'channelConfig']),
    translations () {
      return {
        corporateClarificationBannerText: {
          loan: this.$gettextInterpolate(
            this.$pgettext('corporate_clarification_loan',
              "At current phase you can't apply for a new loan contract from self-service." +
              ' If you want to apply for a loan please contact with our customer support. ##Contact page##'
            ),
            { contactPageLink: this.channelConfig.corporate.helpUrl }
          ),
          deposit: this.$gettextInterpolate(
            this.$pgettext('corporate_clarification_deposit',
              "At current phase you can't apply for a new deposit contract from self-service." +
              ' If you want to apply for a deposit please contact with our customer support. ##Contact page##'
            ),
            { contactPageLink: this.channelConfig.corporate.helpUrl }
          )
        }
      }
    },
    bannerText () {
      return this.translations.corporateClarificationBannerText[this.product]
    }
  }
}
</script>

<style scoped lang="scss">
.corporate-clarification-banner {
  box-shadow: 0 -1px 17px 0 rgb(0 0 0 / 7%);
}
</style>
