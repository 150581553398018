<template>
  <div class="confirm-money-details main-container">
    <div class="sub-container">
      <div v-if="isLoading" class="loader">
        <bb-spinner />
      </div>
      <bb-form
        v-show="!isLoading"
        :submitText="continueText"
        :errorText="continueText"
        :disabled="isDisabled"
        @progress="updateProgress"
      >
        <h4>{{ translations.title }}</h4>
        <div v-if="showPaymentFrequencyField" class="money-confirmation-initial-input-group">
          <saving-deposit-payment-frequency
            v-model="paymentFrequencyCode"
            :valid-payment-frequencies="flowData.validPaymentFrequencies"
            :accounts-defaults="flowData.accountDefaults"
          />
        </div>
        <div v-if="showAmount" :class="showPaymentFrequencyField ? 'money-origin-select' : 'money-confirmation-initial-input-group'">
          <bb-input
            :label="translations.amountLabel"
            :data-vv-as="translations.amountLabel"
            :add-on="currency"
            v-model="amount"
            name="amount"
            :formatter="maxTwoDecimals"
            v-validate="amountValidationRules"
            :helpText="translations.amountTooltip"
            helpActive
          />
        </div>
        <div v-if="showAccountAndClearingNum" class="money-confirmation-initial-input-group">
          <bank-account-input
            type="principal"
            v-model="accountAndClearingNumber"
            :validatedAccounts="validatedAccounts"
            :internalAccounts="internalAccounts"
            :permittedIbans="permittedIbans"
            :texts="bankInputTranslations"
            autoFill
            flavor="deposit"
          />
        </div>
        <div v-if="showIbanField" class="money-confirmation-initial-input-group">
          <bank-account-input
            type="iban"
            v-model="iban"
            :validatedAccounts="validatedAccounts"
            :permittedIbans="permittedIbans"
            :internalAccounts="internalAccounts"
            :autoFillHint="bankAccountAutoFillHint"
            :texts="bankInputTranslations"
            autoFill
            flavor="deposit"
          />
        </div>
        <div v-if="showMoneyOriginSelect" class="money-origin-select">
          <label class="label m-t-30">{{translations.moneyOriginLabel}}</label>
          <bb-checkbox-group
            :value="moneyOrigins"
            :errorMessage="translations.checkboxValidationError"
            @input="onMoneyOriginChange"
            single
          />
        </div>
        <div v-if="showMoneyOriginDescription" class="money-origin-description-input">
          <bb-input
            v-validate="'required|max:200'"
            name="moneyOriginDescription"
            :label="translations.moneyOriginDescriptionLabel"
            :data-vv-as="translations.moneyOriginDescriptionLabel"
            v-model="moneyOriginDescription" />
        </div>
        <div v-if="showBeneficiaryConfirmation" class="beneficiary-confirmation">
          <bb-checkbox
            v-validate="'confirm-terms|required'"
            name="beneficiary-confirmation"
            :label="translations.confirmBeneficiaryText"
            :data-vv-as="translations.confirmation"
            v-model="beneficiaryConfirmation"
            background />
        </div>
        <div v-if="showTermsConfirmation" class="terms-confirmation">
          <bb-checkbox
            v-validate="'confirm-terms|required'"
            name="terms-confirmation"
            v-model="termsConfirmation"
            :data-vv-as="translations.confirmation"
            background>
            <span v-sanitize.basic="confirmTermsText"></span>
          </bb-checkbox>
        </div>
        <template #submit="{ disabled, buttonText }">
          <div class="continue-button-container">
            <continue-button
              useDisabledColors
              :disabled="disabled"
              :text="buttonText"
              :onForm=true
              :handleClick="submitConfirmMoneyDetails"
            ></continue-button>
          </div>
        </template>
      </bb-form>
    </div>
  </div>
</template>
<script>
import ContinueButton from './ContinueButton'
import TermLinksMixin from '@/mixins/termLinksMixin'
import BankAccountInput from '@/components/Input/BankAccountInput'
import BankInputMixin from '../../mixins/bankInputMixin'
import { mapState } from 'pinia'
import getCurrency from '../../utils/getCurrency'
import { DepositType } from '@deposits/const'
import { maxTwoDecimals } from '@/plugins/numformat'
import { useRootStore } from '../../../../store/root'
import SavingDepositPaymentFrequency from '@deposits/components/ApplicationCreation/SavingDepositPaymentFrequency.vue'
import { useDepositStore } from '@deposits/store/depositStore'

export default {
  name: 'confirm-money-details',
  components: {
    SavingDepositPaymentFrequency,
    ContinueButton,
    BankAccountInput
  },
  mixins: [
    TermLinksMixin,
    BankInputMixin
  ],
  data () {
    return {
      isLoading: false,
      amount: this.flowData.amount,
      iban: this.flowData.deposit ? this.flowData.deposit.accountIban : '',
      moneyOrigins: [],
      moneyOriginDescription: '',
      beneficiaryConfirmation: null,
      termsConfirmation: null,
      allowedMoneyOriginValueCodes: [],
      progress: 0,
      accountAndClearingNumber: {
        clearingNumber: this.flowData.deposit ? this.flowData.deposit.clearingNumber : '',
        principalAccount: this.flowData.deposit ? this.flowData.deposit.principalAccount : ''
      },
      paymentFrequencyCode: this.flowData.paymentFrequencyCode
    }
  },
  props: {
    flowData: Object,
    flowConstants: Object
  },
  computed: {
    ...mapState(useRootStore, ['isCompany', 'isChannelSE', 'isChannelLV']),
    ...mapState(useDepositStore, ['featureFlags', 'permittedIbans']),
    continueText () {
      if (this.isDemandDeposit) {
        return this.translations.continueTextDemand
      } else {
        return this.translations.continueText
      }
    },
    isProlongationFlow () {
      return this.flowData.activeView === this.flowData.views.prolongationFlow
    },
    isTermDeposit () {
      return this.flowData.agreementTypeCode === this.flowConstants.AGREEMENT_TYPE_CODES[DepositType.Term]
    },
    isDemandDeposit () {
      return this.flowData.agreementTypeCode === this.flowConstants.AGREEMENT_TYPE_CODES[DepositType.Demand]
    },
    showAmount () {
      return this.isDemandDeposit
    },
    showBeneficiaryConfirmation () {
      return true
    },
    showTermsConfirmation () {
      return true
    },
    showMoneyOriginSelect () {
      const prolongationAmount = this.flowData?.deposit?.prolongationAmount ?? 0

      return !this.isProlongationFlow || this.amount > prolongationAmount
    },
    isNewApplicationFlow () {
      return this.flowData.activeView === this.flowData.views.newApplicationFlow
    },
    showIbanField () {
      return !this.isChannelSE && this.isTermDeposit
    },
    showAccountAndClearingNum () {
      return this.isNewApplicationFlow && this.isChannelSE && this.isTermDeposit
    },
    showMoneyOriginDescription () {
      return this.selectedMoneyOrigins.includes('OTHER')
    },
    selectedMoneyOrigins () {
      return this.moneyOrigins.filter(({ checked }) => checked).map(({ name }) => name)
    },
    isDisabled () {
      return this.progress < 1
    },
    currency () {
      return getCurrency(this.flowData.accountType.restrictedCurrencyCode)
    },
    translations () {
      const translations = {
        title: this.$pgettext('confirm_money_details_title', 'Additional Info'),
        confirmation: this.$pgettext('form_confirmation_field_label', 'Confirmation'),
        confirmBeneficiaryText: this.confirmBeneficiaryText,
        confirmTermDepositTermsText: this.confirmTermDepositTermsText,
        confirmDemandDepositTermsText: this.confirmDemandDepositTermsText,
        ibanLabel: this.$gettext('IBAN'),
        ibanFieldLabel: this.$gettext('IBAN'),
        ibanError: this.$gettext('Insert correct IBAN'),
        ibanCountryError: this.$gettext('Please enter a local IBAN'),
        amountLabel: this.$pgettext('regular', 'Initial deposit amount'),
        amountTooltip: this.amountTooltipText,
        moneyOriginLabel: this.$gettext('Money Origin'),
        continueText: this.$gettext('Yes, everything is correct'),
        continueTextDemand: this.continueTextDemand,
        moneyOriginDescriptionLabel: this.$gettext('Money Origin Description'),
        moneyOriginDescriptionError: this.$gettext('Money origin description is not alphanumerical'),
        moneyOriginNotSelectedError: this.$gettext('At least one money origin is required'),
        moneyOrigin: {
          PROPERTY_SALE: this.$pgettext('MONEY_ORIGIN', 'Property sale'),
          RETURN_ON_INVESTMENT: this.$pgettext('MONEY_ORIGIN', 'Return on investment'),
          SAVINGS: this.$pgettext('MONEY_ORIGIN', 'Savings'),
          INCOME_BUSINESS_ACTIVITY: this.$pgettext('MONEY_ORIGIN', 'Income from business activity'),
          GIFT: this.$pgettext('MONEY_ORIGIN', 'Gift'),
          SALARY: this.$pgettext('MONEY_ORIGIN', 'Salary'),
          INHERITED: this.$pgettext('MONEY_ORIGIN', 'Inherited'),
          RETIREMENT_BENEFIT: this.$pgettext('MONEY_ORIGIN', 'Retirement benefit'),
          LOAN_CAPITAL_INSTRUMENT: this.$pgettext('MONEY_ORIGIN', 'Loan capital instrument'),
          DIVIDENDS: this.$pgettext('MONEY_ORIGIN', 'Dividends'),
          DONATION: this.$pgettext('MONEY_ORIGIN', 'Donation'),
          PUBLIC_SUBSIDY: this.$pgettext('MONEY_ORIGIN', 'Public subsidy'),
          REGULAR_BUSINESS_ACTIVITY: this.$pgettext('MONEY_ORIGIN', 'Regular business activity'),
          OTHER: this.$pgettext('MONEY_ORIGIN', 'Other')
        },
        clearingNumberLabel: this.$gettext('Clearing Number'),
        clearingNumberError: this.$gettext('Insert correct clearing number'),
        principalAccountLabel: this.$gettext('Bank Account'),
        principalAccountError: this.$gettext('Insert correct bank account'),
        checkboxValidationError: this.$gettext('Please select at least one origin for the money'),
        confirmTermsError: this.$gettext('Please read the consent and approve it')
      }

      if (this.isCompany) {
        Object.assign(translations, {
          amountLabel: this.$pgettext('corporate', 'Initial deposit amount'),
          title: this.$pgettext('corporate_confirm_money_details_title', 'Additional Info')
        })
      }

      return translations
    },
    amountTooltipText () {
      return this.isCompany
        ? this.$pgettext('corporate_initial_amount_tooltip', 'An estimation of the amount you plan to hold on the account. This is not binding for you and is needed for our internal calculations and checks.')
        : this.$pgettext('initial_amount_tooltip', 'An estimation of the amount you plan to hold on the account. This is not binding for you and is needed for our internal calculations and checks.')
    },
    confirmBeneficiaryTextPrivate () {
      return this.isChannelLV
        ? this.$pgettext('confirm_beneficiary_lv', 'I hereby confirm, that I am the owner of the money and that the aforementioned IBAN belongs to me.')
        : this.$pgettext('confirm_beneficiary', 'I hereby confirm, that I am the owner of the money and that the aforementioned IBAN belongs to me.')
    },
    confirmBeneficiaryText () {
      return this.isCompany
        ? this.$pgettext('corporate_confirm_beneficiary', 'I hereby confirm, that I am the owner of the money and that the aforementioned IBAN belongs to me.')
        : this.confirmBeneficiaryTextPrivate
    },
    confirmTermDepositTermsText () {
      return this.isCompany
        ? this.$gettextInterpolate(this.$pgettext('corporate_confirm_term_deposit_terms', 'I hereby confirm that I have read and agree with the ' +
          '<a target="_blank" href="%{termsLink}">General Terms and Conditions</a> of Term Deposit contract and the <a target="_blank" href="%{basicInformationLink}">' +
          'Basic Information on the Protection of Deposits</a>'), this.getTermsLinks())
        : this.$gettextInterpolate(this.$pgettext('confirm_term_deposit_terms', 'I hereby confirm that I have read and agree with the ' +
          '<a target="_blank" href="%{termsLink}">General Terms and Conditions</a> of Term Deposit contract and the <a target="_blank" href="%{basicInformationLink}">' +
          'Basic Information on the Protection of Deposits</a>'), this.getTermsLinks())
    },
    confirmDemandDepositTermsTextPrivate () {
      return this.isChannelLV
        ? this.$gettextInterpolate(this.$pgettext('confirm_demand_deposit_terms_lv', 'I hereby confirm that I have read and agree with the ' +
          '<a target="_blank" href="%{demandDepositTermsLink}">General Terms and Conditions</a> of Savings Deposit contract and the <a target="_blank" href="%{basicInformationLink}">' +
          'Basic Information on the Protection of Deposits</a>'), this.getTermsLinks())
        : this.$gettextInterpolate(this.$pgettext('confirm_demand_deposit_terms', 'I hereby confirm that I have read and agree with the ' +
        '<a target="_blank" href="%{demandDepositTermsLink}">General Terms and Conditions</a> of Savings Deposit contract and the <a target="_blank" href="%{basicInformationLink}">' +
        'Basic Information on the Protection of Deposits</a>'), this.getTermsLinks())
    },
    confirmDemandDepositTermsText () {
      return this.isCompany
        ? this.$gettextInterpolate(this.$pgettext('corporate_confirm_demand_deposit_terms', 'I hereby confirm that I have read and agree with the ' +
          '<a target="_blank" href="%{demandDepositTermsLink}">General Terms and Conditions</a> of Savings Deposit contract and the <a target="_blank" href="%{basicInformationLink}">' +
          'Basic Information on the Protection of Deposits</a>'), this.getTermsLinks())
        : this.confirmDemandDepositTermsTextPrivate
    },
    continueTextDemand () {
      return this.isCompany ? this.$pgettext('corporate_demand_depo_continue', 'Confirm') : this.$pgettext('demand_depo_continue', 'Confirm')
    },
    amountValidationRules () {
      return {
        required: true,
        between: [this.flowData.limits[this.flowData.paymentFrequencyCode].minAmount, this.flowData.limits[this.flowData.paymentFrequencyCode].maxAmount],
        // This validates that number does not start with 0 (for example 01000), but allows with comma (for example 0.12)
        regex: /^(([1-9]\d*)|([0]{1}))(\.\d+)?$/
      }
    },
    confirmTermsText () {
      if (this.isDemandDeposit) {
        return this.translations.confirmDemandDepositTermsText
      }

      return this.translations.confirmTermDepositTermsText
    },
    bankAccountAutoFillHint () {
      if (this.isProlongationFlow) {
        return this.flowData?.deposit?.accountIban
      }
      return this.savingsDepoIbans?.[0]
    },
    showPaymentFrequencyField () {
      if (this.featureFlags.SavingsDepositInterestPaymentFrequencyBasedOnAccountDefaults) {
        return this.isDemandDeposit && !this.isCompany
      }

      return this.isChannelLV && this.isDemandDeposit && !this.isCompany
    }
  },
  methods: {
    maxTwoDecimals: (inputStr) => maxTwoDecimals(inputStr),
    updateProgress (value) {
      this.progress = value
    },
    submitConfirmMoneyDetails () {
      this.$validator.validateAll().then(isValid => {
        if (!isValid) {
          return
        }
        const stepData = {
          iban: this.showIbanField ? this.iban : undefined,
          moneyOrigins: [...this.selectedMoneyOrigins],
          moneyOriginDescription: this.moneyOriginDescription,
          beneficiaryConfirmation: this.isNewApplicationFlow ? this.beneficiaryConfirmation : undefined,
          termsConfirmation: this.termsConfirmation,
          clearingNumber: this.showAccountAndClearingNum ? this.accountAndClearingNumber.clearingNumber : undefined,
          principalAccount: this.showAccountAndClearingNum ? this.accountAndClearingNumber.principalAccount : undefined,
          paymentFrequencyCode: this.paymentFrequencyCode
        }

        // TODO: Can be removed in the future, as backend will add this value when needed
        if (this.isProlongationFlow && this.selectedMoneyOrigins.length < 1) {
          stepData.moneyOrigins.push('SET_OFF')
        }

        if (this.showAmount) {
          stepData.amount = this.amount
        }

        this.isLoading = true
        this.$emit('submit', stepData)
      })
    },
    onMoneyOriginChange (updated) {
      this.moneyOrigins.forEach(origin => {
        const match = updated.find(tmp => tmp.name === origin.name)
        if (match && match.checked !== origin.checked) {
          origin.checked = match.checked
        }
      })
    },
    getMoneyOriginOptions () {
      if (!Array.isArray(this.flowData?.moneyOriginLookupValues) || this.flowData.moneyOriginLookupValues.length === 0) {
        this.$apm.captureError(new Error(`Money origin lookup values are missing: ${JSON.stringify(this.flowData?.moneyOriginLookupValues)}`))
      }

      return (this.flowData?.moneyOriginLookupValues || []).map(value => {
        const code = value.lookupValueCode
        return {
          label: this.translations.moneyOrigin[code] || code,
          name: value.lookupValueCode,
          checked: null
        }
      })
    }
  },
  async mounted () {
    this.isLoading = true
    this.moneyOrigins = this.getMoneyOriginOptions()
    await this.loadAccounts(this.flowData.accountType.code)
    this.isLoading = false
  },
  created () {
    this.$validator.extend('confirm-terms', {
      getMessage: () => {
        return this.translations.confirmTermsError
      },
      validate: value => {
        return value === true
      }
    })
  }
}
</script>

<style lang="scss">
  .main-container {
    margin: 0 auto;
    padding: 0;
    max-width: 380px;
  }

  .sub-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    margin: 0 auto;
    padding: 0;
    max-width: 380px;

    .loader {
      padding-top: 50px;
      margin: auto;
    }

    h4 {
      font-family: $max-pro-demi-bold;
      text-align: center;
      color: $navy;
      margin-top: 40px;
      margin-bottom: 0;
      width: 100%;
    }
  }

  .money-confirmation-initial-input-group {
    width: 100%;

    .field-group {
      margin-top: 12px;

      @media (min-width: $desktop-view-breaking-point-wide) {
        margin-top: 5px;
      }
    }
  }

  .money-origin-select {
    width: 100%;

    .field-group {
      margin-top: 0;

      @media (min-width: $desktop-view-breaking-point-wide) {
        margin-top: 5px;
      }
    }
  }

  .money-origin-description-input {
    width: 100%;

    .field-group {
      margin-top: 5px;

      @media (min-width: $desktop-view-breaking-point-wide) {
        margin-top: 10px;
      }
    }
  }

  .beneficiary-confirmation,
  .terms-confirmation {
    font-family: $gotham-book;
    font-size: $mobile-default-font-size;
    color: $navy;
    margin-top: 20px;

    a {
      color: $blue;
      text-decoration: none;
    }

    @media (min-width: $desktop-view-breaking-point-wide) {
      font-size: $font-size-small;
    }
  }

  .continue-button-container {
    margin-top: 55px;

    @media (max-width: 359px) {
      margin-top: 100px;
    }
  }

  .confirm-money-details {
    form {
      position: static !important;
      max-width: 100%;

      .bb-button {
        margin-top: 30px;
        text-transform: uppercase;
      }
    }
  }

  .label {
    color: $gray-90;
    font-family: $gotham-medium;
    line-height: $base-line-height;
    display: block;
  }
</style>
