<template>
  <modal
    @close="onClose"
    :loading="loading"
    :title="translations.title"
    :subtitle="translations.subtitle"
  >
    <bb-box thin class="details-box" v-if="proceedingDetails">
      <list-item v-if="name" :title="name" :titleUppercase="false" :titleBold="true" />
      <list-item v-if="phone" :title="translations.phone" :value="phone" />
      <list-item v-if="email" :title="translations.email" :value="email" />
    </bb-box>
  </modal>
</template>

<script>
import Modal from '@/components/DefaultModal'
import ListItem from '@/components/ListItem'
import api from '@loan/api'

export default {
  name: 'sold-loan-additional-details',
  components: {
    ListItem,
    Modal
  },
  data () {
    return {
      loading: true,
      proceedingDetails: null
    }
  },
  props: {
    contract: {
      required: true
    }
  },
  computed: {
    translations () {
      return {
        title: this.$pgettext('sold_additional_details', 'Additional Details'),
        phone: this.$pgettext('sold_additional_details', 'Phone'),
        email: this.$pgettext('sold_additional_details', 'Email'),
        subtitle: this.$pgettext('sold_additional_details', 'Description text of sold loan additional details modal')
      }
    },
    partnerDetails () {
      return this.proceedingDetails.partnerDetails
    },
    name () {
      return this.partnerDetails.fullName
    },
    email () {
      return this.partnerDetails.email
    },
    phone () {
      return this.partnerDetails.phoneNumber
    }
  },
  methods: {
    onClose () {
      this.$emit('close')
    },
    async loadDetails () {
      this.loading = true
      this.proceedingDetails = await api.getProceedingDetails(this.contract.id)
      this.loading = false
    }
  },
  async mounted () {
    await this.loadDetails()
  }
}
</script>

<style lang="scss" scoped>
.details-box {
  margin-top: 15px;
  display: block;
}
</style>
