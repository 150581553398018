<template>
  <bb-application-contract-card
    data-testid="demand-deposit-empty-state-card"
    class="demand-deposit-empty-state-card"
    :icon="{ name: 'client-wave', fill: 'default' }"
    :title="translations.title"
    :description="translations.description"
    :primary-button="{
      label: translations.buttonText,
      color: 'mint'
    }"
    @primaryButtonClick="redirect"
  />
</template>

<script>
import '@bigbank/interface-components/dist/svg/client/wave'
import { DepositRouteName, DepositType } from '../const'

export default {
  name: 'demand-deposit-empty-state-card',
  computed: {
    translations () {
      return {
        description: this.$pgettext(
          'savings_deposit_empty_card',
          'You don\'t have any active Savings deposits now. In order to open new deposit, please, click on "New deposit" button.'
        ),
        title: this.$pgettext('savings_deposit_empty_card', 'Open your new Savings deposit'),
        buttonText: this.$pgettext('savings_deposit_empty_card', 'New deposit')
      }
    }
  },
  methods: {
    redirect () {
      this.$router.push({ name: DepositRouteName.New, params: { depositType: DepositType.Demand } })
    }
  }
}
</script>

<style lang="scss" scoped>
.demand-deposit-empty-state-card {
  &::v-deep .bb-application-contract-card__title {
    color: $blue;
    font-family: $gotham-bold;
    font-size: $font-size-default;
  }
}
</style>
