<template>
  <layout-with-menu :loading="loading">
    <div class="account-view">
      <hero-header :title="translations.title" @goBack="goBack">
        <bb-horizontal-menu
          class="account-view__hero__menu"
          :isStickyOnMobile="true"
          :routes="menuRoutes"
        />
      </hero-header>
      <div class="account-view__main">
        <div class="account-view__main--container">
          <slot></slot>
        </div>
      </div>
    </div>
  </layout-with-menu>
</template>

<script>
import { mapState } from 'pinia'
import '@bigbank/interface-components/dist/svg/ui/arrow-left'
import HeroHeader from '../../../components/hero-header/HeroHeader.vue'
import { useRootStore } from '../../../store/root'

export default {
  components: { HeroHeader },
  name: 'account-layout',
  inject: ['$validator'],
  props: {
    loading: {
      required: false,
      default: false
    }
  },
  computed: {
    ...mapState(useRootStore, ['featureFlags', 'hasCurrentAccount']),
    translations () {
      return {
        title: this.$pgettext('page_title', 'My Account')
      }
    },
    menuRoutes () {
      const current = this.$router.currentRoute
      return [
        {
          path: '/account',
          title: this.$pgettext('menu', 'Personal Data'),
          active: current.path === '/account'
        },
        {
          path: '/account/data',
          title: this.$pgettext('menu', 'Account Data'),
          active: current.path === '/account/data',
          hide: !this.featureFlags.hasUsername
        },
        {
          path: '/account/verification',
          title: this.$pgettext('menu', 'Account verification'),
          active: current.path === '/account/verification',
          hide: !this.featureFlags.enableUnverifiedCustomerAdditionalLimits
        },
        {
          path: '/account/documents',
          title: this.$pgettext('menu', 'Documents'),
          active: current.path === '/account/documents',
          hide: !this.featureFlags.enableDocumentsTab && !this.featureFlags.enableAdditionalDocumentUpload
        },
        {
          path: '/account/limits',
          title: this.$pgettext('menu', 'Accounts & Limits'),
          active: current.path === '/account/limits',
          hide: !this.featureFlags.enableAccountsLimitsTab || !this.hasCurrentAccount
        },
        {
          path: '/account/tax-report',
          title: this.$pgettext('menu', 'Tax Report'),
          active: current.path === '/account/tax-report',
          hide: !this.featureFlags.enableTaxReports
        }
      ].filter(({ hide }) => !hide)
    }
  },
  methods: {
    goBack () {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss">
$container-max-width: 1140px;

.account-view {
  &__main {
    max-width: $container-max-width;
    margin: auto;

    &--container {
      max-width: 840px;
      display: flex;

      @media (min-width: $desktop-view-breaking-point) {
        padding-top: 60px;
      }
    }
  }
}
</style>
