<template>
  <bb-modal :visible="visible" @close="onClose()" flavor="card" color="white" publicSize="m" fullMobile>
    <bb-list-group>
      <bb-list-item v-for="(item, k) in detailsList" :key="k" extraCondensed :title="item.label">{{item.content}}</bb-list-item>
    </bb-list-group>
  </bb-modal>
</template>

<script>
export default {
  name: 'offer-details-modal',
  data () {
    return {}
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    detailsList: {
      required: true
    }
  },
  methods: {
    onClose () {
      this.$emit('close')
    }
  }
}
</script>
