<template>
  <card-layout
    :index="index"
    :count="count"
    class="offer-card"
  >
    <div slot="contents" class="offer-card__contents">
      <div class="offer-card__contents-title">{{ title }}</div>
      <div class="offer-card__contents-description">{{ lead }}</div>
      <bb-list-group class="offer-card__contents-list mt-3">
        <bb-list-item
          v-for="(option, key) in allOptions"
          :key="key"
          class="offer-card__contents-list-item"
          extraCondensed
          :title="option.title"
        >{{option.value}}</bb-list-item>
      </bb-list-group>
      <div v-if="agree" class="offer-card__contents-agree-text">
        {{translations.agreeToContinue}} {{learnAboutCollectingOfInfoNoLinkText}} <a v-bind:href="agree.link">{{learnAboutCollectingOfInfoLinkText}}</a>
      </div>
    </div>
    <div slot="links" class="card-links">
      <bb-button @click="choose" class="m-t-15">{{translations.buttonText}}</bb-button>
    </div>
  </card-layout>
</template>

<script>
import { formatMoneyWithCurrency } from '@/plugins/numformat'
import CardLayout from '@/components/cards/CardLayout'
import { mapState } from 'pinia'
import { useRootStore } from '../../../../store/root'

export default {
  name: 'offer-card',
  components: {
    CardLayout
  },
  props: {
    currency: {
      required: true
    },
    index: {
      required: true
    },
    count: {
      required: true
    },
    offer: {
      required: true
    },
    options: {
      required: true
    },
    overrideAllOptions: {
      required: false,
      default: false
    },
    title: {
      required: true
    },
    lead: {
      required: true
    },
    agree: {
      required: false
    }
  },
  computed: {
    ...mapState(useRootStore, ['locale']),
    translations () {
      return {
        amountAvailableLabel: this.$gettext('Amount available'),
        buttonText: this.$gettext('Check the application'),
        agreeToContinue: this.$pgettext('offer_card', 'By continuing, I agree that Bigbank will collect and process information from other creditors about my previous credits'),
        learnAboutCollectingOfInfo: this.$pgettext('offer_card', 'Learn more here'),
        completeApplicationLabel: this.$gettext('Complete application'),
        payslipsLabel: this.$gettext('Payslips'),
        noNeedText: this.$gettext('No need'),
        noNeedTextPayslip: this.$pgettext('PAYSLIP', 'No need')
      }
    },
    // This is temprary due to some issues with LT not able to use segment 2
    // Should be removed when we start using upsell flow
    segment () {
      return this.offer.segmentCode
    },
    allOptions () {
      const amountAvailableFormatted = formatMoneyWithCurrency(this.amountAvailable, this.currency, this.locale)

      const defaultOptions = [{
        title: this.translations.amountAvailableLabel?.toUpperCase(),
        value: amountAvailableFormatted,
        visible: this.segment === 1
      }, {
        title: this.translations.completeApplicationLabel?.toUpperCase(),
        value: this.completeApplicationText,
        visible: this.segment === 1
      }, {
        title: this.translations.payslipsLabel?.toUpperCase(),
        value: this.payslipsText,
        visible: this.segment === 1
      }]

      const chosenOptions = this.overrideAllOptions ? this.options : defaultOptions.concat(this.options)

      return chosenOptions.filter(({ visible }) => visible)
    },
    amountAvailable () {
      return this.offer.upsellAmount
    },
    interestValue () {
      if ([1, 2].includes(this.segment)) {
        return this.translations.interestValue[this.segment]
      }
      return null
    },
    maturityValue () {
      if ([1, 2].includes(this.segment)) {
        return this.translations.maturityValue[this.segment]
      }
      return null
    },
    learnAboutCollectingOfInfoNoLinkText () {
      const words = this.translations.learnAboutCollectingOfInfo.split(' ')
      words.pop()

      return words.join(' ')
    },
    learnAboutCollectingOfInfoLinkText () {
      const words = this.translations.learnAboutCollectingOfInfo.split(' ')

      return words[words.length - 1]
    },
    completeApplicationText () {
      // This is hardcoded at the moment, more complex flow is being developed
      return this.translations.noNeedText
    },
    payslipsText () {
      // This is hardcoded at the moment, more complex flow is being developed
      return this.translations.noNeedTextPayslip
    }
  },
  methods: {
    choose () {
      this.$emit('apply')
    }
  }
}
</script>

<style lang="scss">
.offer-card {
  &__contents {
    font-family: $gotham-book;
    transition: color 1s linear;
    padding: 15px 0;

    @media (min-width: $desktop-view-breaking-point + 1) {
      padding: unset;
    }

    &-title {
      font-size: $mobile-default-font-size;
      font-family: $gotham-bold;
      color: $blue;
      margin-top: -14px;
      margin-bottom: 8px;

      @media (min-width: $desktop-view-breaking-point) {
        margin-top: -18px;
        font-size: $default-font-size;
      }
    }

    &-description {
      font-size: $font-size-smallest;
      color: $gray-90;
    }

    &-agree-text {
      margin-top: 20px;
      font-size: $font-size-smallest;
      color: $gray-80;

      a {
        color: $blue;
      }
    }

    &-list {
      margin-bottom: -5px;

      @media (min-width: $desktop-view-breaking-point + 1) {
        margin-bottom: -10px;
      }
    }
  }
}

.card-links {
  margin: auto 0;
  text-align: center;
  flex: 1 0 auto;
  transition: color 1s linear;
  padding-bottom: 10px;

  @media (min-width: $desktop-view-breaking-point) {
    padding-left: 25px;
    min-width: 250px;
    max-width: 250px;
  }

  .bb-button--link {
    font-size: $font-size-small;
    margin: 10px auto 0;
    text-decoration: underline;
  }

  .bb-button {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  &--go-to-application {
    transition: border 1s, color 1s, background-color 1s linear;

    @media (max-width: $desktop-view-breaking-point + 1) {
      margin-top: 20px !important;
    }
  }
}
</style>
