<template>
  <bb-box thin class="payment-details-box">
    <list-item v-if="beneficiaryName" :title="translations.recipient" :titleUppercase="false" :value="beneficiaryName" name="beneficiary-name"  />
    <list-item :title="translations.iban" :titleUppercase="false" :value="beneficiaryIban" name="iban" />
    <list-item :title="translations.referenceNumber" :titleUppercase="false" :value="referenceNumber" name="reference-number" />
    <list-item :title="translations.directDebit" :titleUppercase="false" v-if="showDirectDebit">
      {{directDebitValid ? translations.on : translations.off}}
      <template v-slot:button>
        <bb-tooltip v-if="!directDebitValid">
          {{translations.directDebitTooltipText}}
        </bb-tooltip>
      </template>
    </list-item>
  </bb-box>
</template>

<script>
import ListItem from '@/components/ListItem.vue'
import '@bigbank/interface-components/dist/svg/ui/check-circle-outline'

export default {
  name: 'payment-details',
  components: {
    ListItem
  },
  props: {
    beneficiaryName: {
      required: false,
      type: String
    },
    beneficiaryIban: {
      required: true
    },
    referenceNumber: {
      required: true
    },
    directDebitValid: {
      required: false,
      default: null,
      type: Boolean
    }
  },
  computed: {
    translations () {
      return {
        recipient: this.$pgettext('credit_card_hero_header', 'Recipient'),
        iban: this.$gettext('Bank account'),
        referenceNumber: this.$gettext('Reference number'),
        directDebit: this.$gettext('Direct debit'),
        on: this.$gettext('on'),
        off: this.$gettext('off'),
        directDebitTooltipText: this.$gettext('Placeholder for direct debit tooltip text')
      }
    },
    showDirectDebit () {
      return [true, false].includes(this.directDebitValid)
    }
  }
}
</script>

<style lang="scss">
.payment-details-box {
  .bb-tooltip__reference svg {
    width: 18px;

    path {
      fill: $gray-80;
    }
  }
}
</style>
