<template>
  <div class="full-spinner">
    <bb-spinner />
  </div>
</template>

<script>
export default {
  name: 'full-spinner'
}
</script>

<style lang="scss">
  .full-spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 75vh;
  }
</style>
