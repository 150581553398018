const statuses = {
  PAID: 'none',
  PAID_LATE: 'none',
  DEBT: 'error',
  SCHEDULED: 'none',
  CURRENT: 'success'
}

const icons = {
  PAID: 'ui-check-circle-outline',
  PAID_LATE: 'ui-check-circle-outline',
  DEBT: 'ui-check-circle-outline',
  SCHEDULED: 'ui-clock-outline',
  CURRENT: 'ui-clock-outline'
}

const colors = {
  PAID: 'green',
  PAID_LATE: 'green',
  CURRENT: undefined,
  DEBT: 'red',
  SCHEDULED: undefined
}

const calculateValues = (item) => {
  switch (item.statusCode) {
    case 'PAID':
    case 'PAID_LATE':
      return {
        principal: item.actualPayments.principal.amountPaid,
        interest: item.actualPayments.interest.amountPaid,
        fees: item.actualPayments.feesTotal.amountPaid,
        total: item.actualPayments.total.amountPaid,
        date: item.actualPayments.total.lastPaymentDate
      }
    case 'DEBT':
      return {
        principal: item.actualPayments.principal.amountDue,
        interest: item.actualPayments.interest.amountDue,
        fees: item.actualPayments.feesTotal.amountDue,
        total: item.actualPayments.total.amountDue,
        date: item.paymentDate || item.scheduleDate
      }
    case 'SCHEDULED':
    case 'CURRENT':
      return {
        principal: item.actualPayments.principal.amountDue + (item.isProcessed ? 0 : item.scheduledPayments.principal),
        interest: item.actualPayments.interest.amountDue + (item.isProcessed ? 0 : item.scheduledPayments.interest),
        fees: item.actualPayments.feesTotal.amountDue + (item.isProcessed ? 0 : item.scheduledPayments.feesTotal),
        total: item.actualPayments.total.amountDue + (item.isProcessed ? 0 : item.scheduledPayments.total),
        date: item.paymentDate || item.scheduleDate
      }
  }
}

export const mapSingleItem = (item, context) => {
  const calculations = calculateValues(item)
  const { principal, interest, fees, total, date } = calculations
  const button = {
    label: context.translations.buttonLabels[item.statusCode],
    visible: true
  }

  return {
    id: item.id,
    calculations,
    status: statuses[item.statusCode],
    icon: icons[item.statusCode],
    iconColor: colors[item.statusCode],
    currency: item.currencyCode,
    statusColumn: {
      progress: !context.hasProgress ? null : item.scheduleNr,
      status: context.translations.scheduleTypes[item.statusCode]
    },
    middleColumns: [
      principal,
      interest,
      fees
    ],
    lastColumn: {
      date: new Date(date).toLocaleDateString(context.locale),
      amount: total
    },
    button
  }
}

export const mapByYear = (items) => {
  const yearMap = items.reduce((years, item) => {
    const { previous, calculations } = years[item.year] || { calculations: { principal: 0, fees: 0, interest: 0, total: 0 } }
    const { principal, interest, fees, total } = calculateValues(item)

    calculations.total += total
    calculations.principal += principal
    calculations.interest += interest
    calculations.fees += fees

    years[item.year] = {
      previous: item,
      calculations,
      status: statuses[(previous && ['DEBT', 'CURRENT'].includes(previous.statusCode) ? previous : item).statusCode],
      icon: icons[item.statusCode],
      iconColor: colors[item.statusCode],
      currency: item.currencyCode,
      statusColumn: {
        status: item.year
      },
      middleColumns: [
        calculations.principal,
        calculations.interest,
        calculations.fees
      ],
      lastColumn: {
        amount: calculations.total
      }
    }

    return years
  }, {})

  return Object.values(yearMap)
}
