import { CREDIT_CARD } from '@/const'
import { CardTransactionType } from '@bigbank/dc-common/clients/http/credit-card/credit-card.enums'
import { AccountStatementTransactionType } from '@bigbank/dc-common/enums/credit-card.enums'

export const BbCreditCardStatus = Object.freeze({
  ACTIVATED: 'activated',
  BLOCKED: 'blocked',
  DISABLED: 'disabled',
  EMPTY: 'empty',
  CLOSED: 'closed'
})

export const EMPTY_ACCOUNT_STATEMENT_DATA = Object.freeze({
  transactions: [],
  transactionsTotal: CREDIT_CARD.TRANSACTIONS_PER_PAGE,
  finalBalance: { date: null, value: 0 },
  startingBalance: { date: null, value: 0 },
  startingAvailableBalance: 0,
  finalAvailableBalance: 0
})

export const EMPTY_FILTERS_FORM_DATA = Object.freeze({
  startDate: null,
  endDate: null,
  period: null,
  cardId: null,
  advancedSearchFilters: [],
  additionalOptionsCheckboxes: {
    [AccountStatementTransactionType.Credit]: true,
    [AccountStatementTransactionType.Debit]: true,
    [AccountStatementTransactionType.Reservation]: true
  }
})

export const ORDER_NEW_CREDIT_CARD_COST = 5

export const SectionNames = {
  [CardTransactionType.GLOBAL]: 'global-limit',
  [CardTransactionType.CASH]: 'cash-withdrawal',
  [CardTransactionType.REGULAR]: 'card-purchases',
  [CardTransactionType.ONLINE]: 'online-purchases',
  [CardTransactionType.CONTACTLESS]: 'contactless-purchases'
}
