<template>
  <div>
    <div v-if="hasCampaigns" :class="spacerClass">
      <bb-campaign-cards
        :cards="displayCampaigns"
        :card-index="show"
        @click="onClick"
      >
        <template v-for="campaign in campaignContents">
          <span v-if="campaign.content" :slot="campaign.id" :key="campaign.id" v-sanitize.basic="campaign.content"></span>
        </template>
      </bb-campaign-cards>
    </div>
  </div>
</template>

<script>
import { BbCampaignCards } from '@bigbank/interface-components'
import { CAMPAIGNS } from '@/TrackingActions'
import { mapState } from 'vuex'

export default {
  name: 'campaign-card',
  props: {
    campaigns: {
      type: Array,
      required: true
    },
    index: {
      required: false
    },
    count: {
      required: false
    },
    spacerClass: {
      required: false,
      default: 'm-b-15'
    }
  },
  components: {
    BbCampaignCards
  },
  data () {
    return {
      show: 0,
      tracked: false
    }
  },
  computed: {
    ...mapState('campaign', ['campaignTypes']),
    hasCampaigns () {
      return this.campaigns && this.campaigns.length > 0
    },
    displayCampaigns () {
      return this.campaigns.map(campaign => this.getCampaignCardProps(campaign))
    },
    campaignContents () {
      return this.campaigns.map(campaign => this.getContent(campaign))
    },
    campaignIds () {
      return this.hasCampaigns ? this.campaigns.map(campaign => campaign.id) : []
    }
  },
  mounted () {
    if (this.hasCampaigns && !this.tracked) {
      this.$tracker.action(CAMPAIGNS.VIEW, {}, { campaignIds: this.campaignIds })
      this.tracked = true
    }
  },
  methods: {
    getContent (campaign) {
      const allowed = ['id', 'content']

      return this.mapOnlySpecificFields(campaign, allowed)
    },
    getCampaignCardProps (campaign) {
      const allowed = [
        'id', 'button', 'consent', 'footerNote', 'imagePath',
        'labelCampaign', 'labelLoyalty', 'overlay', 'title'
      ]

      return this.mapOnlySpecificFields(campaign, allowed)
    },
    mapOnlySpecificFields (campaign, fields) {
      return Object.keys(campaign)
        .filter(key => fields.includes(key))
        .reduce((obj, key) => {
          if (key === 'button' && (campaign[key].to && campaign[key].to.length > 0)) {
            obj[key] = {
              label: campaign[key].label,
              to: campaign[key].to
            }
          } else {
            obj[key] = campaign[key]
          }

          return obj
        }, {})
    },
    onClick (campaignId) {
      if (campaignId) {
        const campaign = this.campaigns.find(campaign => campaign.id === campaignId)
        const action = campaign?.type === this.campaignTypes.UPSELL ? CAMPAIGNS.CLICK_UPSELL : CAMPAIGNS.CLICK_REGULAR
        this.$tracker.action(action, {}, { campaignIds: [campaignId] })
      }
    }
  }
}
</script>

<style lang="scss">
  .bb-progress-dots.bb-campaign-cards__pagination {
    justify-content: flex-end !important;
    margin-right: 5px !important;
  }

  .bb-card.bb-campaign-card.bb-campaign-cards__card.bb-card--wide {
    padding: unset !important;
    margin: 0 1.25rem 0 0 !important;
  }
</style>
