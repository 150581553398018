<template>
  <div>
    <div class="m-t-40" v-if="showYourUploadedDocuments">
      <div class="f-default f-color-gray-90">{{ translations.yourDocuments }}</div>
      <document-list class="m-t-15" :files="uploadedDocuments" :download-handler="download" flavour="minimal" />
    </div>
    <div class="m-t-40">
      <bb-accordion card :value="accordions" :key="documentRequirements.uuid">
        <template #upload-title>
          <span class="f-gotham-medium f-color-navy">
            {{ translations.uploadDocuments }}
          </span>
        </template>
        <template #upload>
          <div v-for="(step, i) in steps" :key="i">
            <div class="step-title f-gotham-medium f-small">
              <div class="f-color-gray">
                {{ translations.stepTemplate.replace('%', i + 1) }}
              </div>
              <span class="f-color-gray-90">-</span>
              <div class="f-color-gray-90">
                {{ step.title }}
              </div>
            </div>
            <div class="step-content f-gotham-book f-small f-color-navy">
              {{ step.content }}
            </div>
            <div v-if="step.buttons" class="step-buttons">
              <div v-for="(button, j) in step.buttons" :key="j" class="step-button">
                <div class="step-button-description f-gotham-medium f-smallest f-color-navy">{{ button.description }}</div>
                <bb-button
                  :label="translations.downloadTemplate"
                  color="blue"
                  inverted
                  target="_blank"
                  :to="button.downloadLink"
                  size="sm"
                />
              </div>
            </div>
          </div>
          <bb-button
            class="m-t-30"
            color="mint"
            :label="translations.uploadDocument"
            display="block"
            @click="onUploadIdDocumentClick"
          />
        </template>
      </bb-accordion>
    </div>
    <bb-modal
      :visible="showUploadForm"
      @close="closeUploadModal"
      flavor="card"
      size="x2"
      publicSize="m"
      class="document-upload-modal"
      fullMobile
      staticOverlay
    >
      <template slot="heading"><span class="f-color-blue form-heading">{{translations.uploadFormHeading}}</span></template>
      <bb-upload
        flavor="user-selfcontained"
        :heading="translations.uploadFormDescription"
        :fileCountLimit="10"
        :allowedTypes="allowedFileTypes"
        :sizeLimit="15"
        v-bind="uploadTranslations"
        v-model="files"
      >
        <template v-slot:browseLabel>
          <span v-sanitize.basic="translations.browseLabel" />
        </template>
      </bb-upload>
      <div v-show="showMustHaves" class="must-haves nm-s-30 nm-b-30 p-30 m-t-30">
        <div class="must-haves__title color-gray-50 m-b-10">{{ translations.mustHaves }}</div>
        <bb-list-group>
          <bb-list-item v-for="(item, k) in documentMustHaves" :key="k" condensed>{{item}}</bb-list-item>
        </bb-list-group>
      </div>
      <bb-button
        slot="footer"
        v-bind="submitButtonProps"
        :disabled="!hasFilesSelected || isLoading"
        :loading="isLoading"
        :label="translations.sendDocument"
        @click="onUploadClick"
      />
    </bb-modal>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { useRootStore } from '../../../store/root'
import DocumentList from '../../../components/DocumentList.vue'
import api from '../api'
import FileUploadingMixin from '@/mixins/fileUploadingMixin'

export default {
  mixins: [FileUploadingMixin],
  components: { DocumentList },
  data () {
    return {
      isLoading: false,
      showUploadForm: false,
      files: [],
      documentRequirements: {
        uuid: 0,
        requireSourceOfFunds: false,
        requireCustomerDataSheet: false
      },
      allowedFileTypes: [
        'application/vnd.etsi.asic-e+zip',
        'application/x-ddoc',
        'application/x-bdoc',
        'application/x-cdoc',
        'application/vnd.bdoc-1.0',
        'application/vnd.bdoc-1.0; version="1"'
      ]
    }
  },
  methods: {
    setLoading (flag) {
      this.isLoading = flag
    },
    onUploadIdDocumentClick () {
      this.showUploadForm = true
    },
    closeUploadModal () {
      this.showUploadForm = false
    },
    async onUploadClick () {
      this.setLoading(true)

      try {
        const response = await api.uploadCompanyDocuments(this.files)

        if (this.isUploadingFailed(response)) {
          this.showFailureNotification(response)
        } else {
          this.$notify({
            type: 'success',
            text: this.translations.uploadSuccessful
          })
          this.closeUploadModal()
        }
      } finally {
        this.setLoading(false)
        this.files = []
      }
    },
    download () {
      // TODO: implemented in the future
    }
  },
  computed: {
    ...mapState(useRootStore, ['isMobile', 'channelConfig']),
    translations () {
      return {
        downloadTemplate: this.$pgettext('corporate_documents', 'Download template'),
        uploadSuccessful: this.$pgettext('corporate_documents', 'Documents uploaded successfully'),
        yourDocuments: this.$pgettext('corporate_documents', 'Your uploaded documents'),
        uploadDocuments: this.$pgettext('corporate_documents', 'Upload new documents'),
        stepTemplate: this.$pgettext('corporate_documents', 'Step %'),
        uploadDocument: this.$pgettext('corporate_documents', 'Upload documents'),
        uploadFormHeading: this.$pgettext('corporate_documents', 'Upload your customer data sheet or source of fund and wealth'),
        uploadFormDescription: this.$pgettext('corporate_documents', 'Customer data sheet or source of fund and wealth'),
        mustHaves: this.$pgettext('corporate_documents', 'Conditions'),
        sendDocument: this.$pgettext('corporate_documents', 'Upload documents'),
        browseLabel: this.$pgettext('corporate_documents', 'Drag a file here or <span class="bb-upload__highlight">browse</span> a file to upload')
      }
    },
    uploadTranslations () {
      return {
        allowedTypesLabel: this.$pgettext('upload', 'File type is not allowed'),
        duplicateFileLabel: this.$pgettext('upload', 'File is already uploaded'),
        captureLabel: this.$pgettext('upload', 'Capture'),
        chooseLabel: this.$pgettext('upload', 'Choose'),
        coBorrowerEmptyLabel: this.$pgettext('upload', 'Upload file'),
        emptyDescriptionLabel: this.$pgettext('upload', 'No documents are uploaded.'),
        sizeLimitLabel: this.$pgettext('upload', 'File size is over allowed size'),
        uploadAnotherLabel: this.$pgettext('upload', 'Upload another'),
        fileCountExceededLabel: this.$pgettext('upload', 'File limit exceeded'),
        filenameMaxLengthLabel: this.$pgettext('corporate_documents', 'The file name is too long')
      }
    },
    documentMustHaves () {
      return this.$pgettext('document',
        'Accepted formats (.asice, .bdoc, .ddoc .cdoc) \n' +
        'Files must be digitally signed')
        .split('\n')
        .map(text => (text.trim()))
    },
    areAnyDocumentsRequired () {
      return this.documentRequirements.requireSourceOfFunds || this.documentRequirements.requireCustomerDataSheet
    },
    hasFilesSelected () {
      return this.files.filter(f => f.file).length > 0
    },
    showMustHaves () {
      return !this.hasFilesSelected
    },
    accordions () {
      return [{
        id: 'upload',
        open: this.areAnyDocumentsRequired
      }]
    },
    showYourUploadedDocuments () {
      // TODO: implemented in the future
      return false
    },
    uploadedDocuments () {
      // TODO: implemented in the future
      return []
    },
    submitButtonProps () {
      return {
        display: 'block',
        size: this.isMobile ? 'lg' : undefined,
        class: this.isMobile ? 'm-s-a' : undefined,
        corner: this.isMobile ? 'sharp' : undefined
      }
    },
    steps () {
      return [{
        title: this.$pgettext('corporate_documents', 'Download template'),
        content: null,
        buttons: [{
          description: this.$pgettext('corporate_documents', 'Customer data sheet'),
          downloadLink: this.channelConfig.corporate?.customerDataSheetUrl
        }, {
          description: this.$pgettext('corporate_documents', 'Source of fund and wealth'),
          downloadLink: this.channelConfig.corporate?.sourceOfWealthUrl
        }]
      }, {
        title: this.$pgettext('corporate_documents', 'Fill template'),
        content: this.$pgettext('corporate_documents', 'Take your needed template and fill it with updated information to update it in our system.')
      }, {
        title: this.$pgettext('corporate_documents', 'Sign the document'),
        content: this.$pgettext('corporate_documents', 'Sign filled document by any method that is suitable for you.')
      }, {
        title: this.$pgettext('corporate_documents', 'Upload new document'),
        content: this.$pgettext('corporate_documents', 'After filling template upload it into our system by pressing button below and follow instructions.')
      }]
    }
  },
  async created () {
    this.documentRequirements = Object.assign(
      this.documentRequirements,
      await api.getDocumentRequirements(),
      { uuid: Date.now() }
    )
  }
}
</script>

<style lang="scss" scoped>
.step-title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 0;
  gap: 5px;
}

.step-content {
  margin-top: 8px;
  margin-bottom: 24px;
}

.step-buttons {
  display: flex;
  gap: 10px;
  flex-direction: column;
  margin-bottom: 24px;

  .step-button {
    gap: 5px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

    .bb-button {
      white-space: nowrap;
    }
  }
}

.document-upload-modal::v-deep h3 {
  line-height: 18px;
  padding-left: 10px;
  padding-right: 10px;
}

.document-upload-modal {
  .must-haves {
    background: $gray-10;
    border-radius: 5px;

    &__title {
      font-family: $gotham-medium;
      font-size: $font-size-small;
    }
  }
}
</style>
