<template>
  <div>
    <multi-card
      v-for="(card, index) in cardList"
      :key="card.id"
      :currency="card.currency"
      :card="card"
      :cardType="card.type"
      :index="index"
      :count="cardList.length"
    />
  </div>
</template>

<script>
import MultiCard from '@loan/components/MultiCard.vue'
import { mapState, mapActions } from 'pinia'
import { useLoanStore } from '@loan/store/loanStore'

export default {
  components: { MultiCard },
  computed: {
    ...mapState(useLoanStore, [
      'featureFlags',
      'loading',
      'loanTypes',
      'applications'
    ]),

    cardList () {
      return this.loading ? [] : this.applications.active
    },
    applications () {
      return this.getApplicationsByType(this.loanTypes.CREDIT_CARD)
    }
  },
  methods: {
    ...mapActions(useLoanStore, ['getApplicationsByType'])
  }
}
</script>
