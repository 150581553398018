<template>
  <pay-back v-bind="payBackProps">
    <div slot="payment-description" class="m-t-25 text-center f-small color-gray">
      <div class="notice" v-if="notice">{{ notice }}</div>
      <div v-else>{{ translations.invoiceInfo }}</div>
      <div class="spacer-line" />
      {{ translations.paymentDetailsDescription }}
    </div>
    <div slot="full-repayment-details" v-if="showFullPaymentDescription">
      <div class="m-t-15 text-center f-small color-gray">{{ paymentDescription }}</div>
      <bb-list-group class="invoice__details-list m-t-30">
        <bb-list-item extraCondensed :title="translations.amountForFullRepayment">{{ fullRepaymentAmount }}</bb-list-item>
        <bb-list-item extraCondensed :title="translations.accruedInterest">{{ accruedInterest }}
          <bb-tooltip class="m-l-5" v-if="translations.tooltips.accruedInterest">
            {{ translations.tooltips.accruedInterest }}
          </bb-tooltip>
        </bb-list-item>
      </bb-list-group>
    </div>
    <div slot="credit-limit-usage">
      <div class="m-t-20 text-center f-small color-gray">
        {{ translations.creditLimitUsage }}:
      </div>
      <bb-list-group class="invoice__details-list m-t-15">
        <bb-list-item
          v-for="item in creditLimitItems"
          :key="item.key"
          :title="item.title"
          extraCondensed
        >
          <span :name="item.key">{{ item.value }}</span>
          <bb-tooltip class="m-l-5" v-if="item.tooltip">
            {{item.tooltip}}
          </bb-tooltip>
        </bb-list-item>
      </bb-list-group>
    </div>
  </pay-back>
</template>

<script>
import { mapState } from 'pinia'
import PayBack from '@loan/components/PayBack'
import { CREDIT_CARD_DASHBOARD } from '@/TrackingActions'
import { formatMoneyWithCurrency } from '@/plugins/numformat'
import payBackModalMixin from '@/mixins/payBackModalMixin'
import { useRootStore } from '../../../store/root'
import { useLoanStore } from '@loan/store/loanStore'

export default {
  name: 'credit-card-loan_pay-back',
  components: {
    PayBack
  },
  mixins: [payBackModalMixin],
  data () {
    return {
      isLoading: false,
      invoiceDetails: null,
      repaymentDetails: null,
      nextPaymentDetails: null,
      hasCopied: false
    }
  },
  computed: {
    ...mapState(useRootStore, ['locale']),
    ...mapState(useLoanStore, ['creditCardContract']),
    contract () {
      return this.creditCardContract
    },
    payBackProps () {
      return {
        isLoading: this.isLoading,
        contract: this.contract,
        onClose: this.onClose,
        translations: this.translations,
        trackingAction: CREDIT_CARD_DASHBOARD.VIEW_PAY_BACK_MODAL,
        notice: this.notice,
        invoiceDetails: this.invoiceDetails,
        repaymentDetails: this.repaymentDetails,
        nextPaymentDetails: this.nextPaymentDetails
      }
    },
    notice () {
      const shouldShowReplaceCardNotice = this.isContractInDebt && this.$route.query.action === 'replace-card'
      return shouldShowReplaceCardNotice ? this.translations.replaceCardNotice : undefined
    },
    translations () {
      return {
        interestAmount: this.$pgettext('credit_card_pay_back', 'Interest'),
        otherFees: this.$pgettext('credit_card_pay_back', 'Fees'),
        letterFee: this.$pgettext('credit_card_pay_back', 'Letter Fee'),
        scheduleDebtAmount: this.$pgettext('credit_card_pay_back', 'Schedule Debt Amount'),
        interestPaymentDescription: this.$pgettext('credit_card_hero_header', 'Your next interest payment:'),
        interestRepayment: this.$pgettext('credit_card_hero_header', 'Interest Repayment'),
        fullRepaymentDescription: this.$pgettext('credit_card_hero_header', 'If you want to make a full repayment, then the amount is the following.'),
        amountForFullRepayment: this.$pgettext('credit_card_hero_header', 'Amount for full repayment'),
        invoiceInfo: this.$pgettext('credit_card_pay_back', 'The amount is shown when the invoice is presented (7 days before the payment date). Paid invoice amount will be connected with your contract on the payment day.'),
        paymentDetailsDescription: this.$pgettext('credit_card_pay_back', 'Here are your account details, in case you wish to make a repayment.'),
        replaceCardNotice: this.$pgettext('credit_card_replace', 'Due to debt, it\'s not possible to replace the card. After the debt is paid and we received it, you can order a new card'),
        accruedInterest: this.$pgettext('credit_card_pay_back', 'Accrued interest'),
        interestBearingPrincipal: this.$pgettext('credit_card_pay_back', 'Int bearing principal'),
        interestFreePrincipal: this.$pgettext('credit_card_pay_back', 'Int free principal'),
        totalCreditLimitUsed: this.$pgettext('credit_card_pay_back', 'Total credit limit used'),
        creditLimitUsage: this.$pgettext('credit_card_pay_back', 'Credit limit usage'),
        administrationFeeAmount: this.$pgettext('credit_card', 'Account Administration'),
        tooltips: {
          interestBearingPrincipal: this.$pgettext('credit_card_pay_back', 'INT_BEARING_PRINCIPAL_TOOLTIP'),
          interestFreePrincipal: this.$pgettext('credit_card_pay_back', 'INT_REE_PRINCIPAL_TOOLTIP'),
          accruedInterest: this.$pgettext('credit_card_pay_back', 'ACCRUED_INTEREST_TOOLTIP')
        }
      }
    },
    showFullPaymentDescription () {
      return this.featureFlags.enableFullRepaymentAmount
    },
    paymentDescription () {
      if (this.isContractInDebt) {
        return this.translations.interestPaymentDescription
      } else if (this.showFullPaymentDescription) {
        return this.translations.fullRepaymentDescription
      }

      return ''
    },
    interestAmountFromContract () {
      const contractInterestAmount = this.contract.interestAmount
      return formatMoneyWithCurrency(contractInterestAmount, this.contract.currency, this.locale)
    },
    fullRepaymentAmount () {
      const fullRepaymentAmount = this.repaymentDetails?.totalRepaymentAmount ?? null
      return formatMoneyWithCurrency(fullRepaymentAmount ?? 0, this.contract.currency, this.locale)
    },
    accruedInterest () {
      const accruedInterest = this.contract?.accruedInterest ?? null
      return formatMoneyWithCurrency(accruedInterest ?? 0, this.contract.currency, this.locale)
    },
    creditLimitItems () {
      const currency = this.contract.currency
      const locale = this.locale
      const zeroAs = '- ' + currency

      return [
        'interestBearingPrincipal',
        'interestFreePrincipal',
        'totalCreditLimitUsed'
      ].map(key => ({
        key,
        title: this.translations[key],
        tooltip: this.translations.tooltips[key],
        value: formatMoneyWithCurrency(this.contract[key] || 0, currency, locale, {
          zeroAs
        })
      }))
    }
  },
  async mounted () {
    await this.loadData()
  }
}
</script>

<style lang="scss" scoped>
.notice {
  margin-top: 24px;
  margin-bottom: -20px;
  padding-bottom: 16px;
  text-align: center;
  font-size: $font-size-small;
  color: $gray;
  font-family: $gotham-book;
}

.spacer-line {
  border-top: 1px dashed $gray-50;
  margin-top: 16px;
  margin-bottom: 16px;
}
</style>
