<template>
  <div class="text-display">
    <div class="text-display__label m-b-5 p-t-5">{{label}}</div>
    <div class="p-b-5">{{value}}</div>
  </div>
</template>

<script>
export default {
  name: 'text-display',
  props: {
    value: String,
    label: String
  }
}
</script>

<style lang="scss" scoped>
  .text-display {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-family: $gotham-medium;
    font-size: $default-font-size;
    color: $navy;

    &__label {
      color: $gray-90;
      font-size: $font-size-small;
    }
  }
</style>
