import WithMenu from './WithMenu'
import WithNavbar from './WithNavbar'
import FullPage from './FullPage'
import Progress from './Progress'
import WithProgress from './WithProgress'

export default {
  install (Vue) {
    Vue.component(WithMenu.name, WithMenu)
    Vue.component(WithNavbar.name, WithNavbar)
    Vue.component(FullPage.name, FullPage)
    Vue.component(Progress.name, Progress)
    Vue.component(WithProgress.name, WithProgress)
  }
}
