<template>
  <smart-id-signing
      visible
      :start-signing="startSigning"
      :complete-signing="completeSigning"
      v-on="$listeners"
  ></smart-id-signing>
</template>

<script>
import { api } from '../../api'
import SmartIdSigning from '@/components/signing/SmartId'

export default {
  name: 'smart-id-signing-deposit',
  components: { SmartIdSigning },
  props: {
    deposit: {
      required: true
    },
    signingRequestId: {
      required: true
    }
  },
  methods: {
    startSigning () {
      return api.initSigningSmartId(this.deposit.id, this.signingRequestId)
    },
    completeSigning () {
      return api.completeSigningSmartId(this.deposit.id, this.signingRequestId)
    }
  }
}
</script>
