<template>
  <layout-with-menu>
    <open-current-account-modal />
  </layout-with-menu>
</template>

<script>
import OpenCurrentAccountModal from '@/components/OpenCurrentAccountModal.vue'

export default {
  components: {
    OpenCurrentAccountModal
  }
}
</script>
