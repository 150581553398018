<template>
  <div>
    <bb-card class="card-layout"
             size="wide"
             :class="{ 'card-spacer__between': spacerBetween, 'card-spacer__after': spacerAfter }"
    >
      <div class="card-layout__liner">
        <div class="card-details">
          <div>
            <slot name="header"></slot>
          </div>
          <div>
            <slot name="progress"></slot>
          </div>
          <div>
            <slot name="progress-text"></slot>
          </div>
          <div>
            <slot name="contents"></slot>
          </div>
        </div>
        <slot name="links"></slot>
        <slot name="more-links"></slot>
      </div>
    </bb-card>
    <slot name="modal"></slot>
  </div>
</template>

<script>
export default {
  name: 'card-layout',
  props: {
    index: {
      required: true
    },
    count: {
      required: true
    },
    loader: {
      default: true
    }
  },
  data () {
    return {
      displayLoading: true,
      hasLoadingEnded: false
    }
  },
  mounted () {
    this.$nextTick(() => {
      setTimeout(() => {
        this.hasLoadingEnded = true
        setTimeout(() => {
          this.displayLoading = false
        }, 1000)
      }, 1200)
    })
  },
  computed: {
    spacerBetween () {
      return this.count > 1 && this.count - 1 !== this.index
    },
    spacerAfter () {
      return this.count - 1 === this.index
    }
  }
}
</script>

<style lang="scss">
  .bb-card.card-layout {
    max-width: 100%;

    @media (max-width: $mobile-view-breaking-point) {
      flex-direction: column;
      padding: 15px 15px 0;
      margin-right: auto;
      margin-left: auto;
    }
  }

  .card-layout {
    display: flex;
    font-family: $gotham-book;
    margin-right: auto;
    margin-left: auto;
    transition: 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
    z-index: 7;
    position: relative;
    flex-direction: row;
    padding: 24px 30px;

    &:hover {
      box-shadow: 0 1px 25px rgb(0 0 0 / 10%);
      transform: scale(1.01, 1.01);
      transition: 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    &__liner {
      flex-direction: row;
      width: 100%;
      display: flex;

      @media (max-width: $mobile-view-breaking-point) {
        display: inline;
      }
    }
  }

  .card-details {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    border-right: none;
    animation: fadein 2s;
    -moz-animation: fadein 2s;
    -webkit-animation: fadein 2s;
    -o-animation: fadein 2s;

    @media (min-width: $desktop-view-breaking-point + 1) {
      border-right: 1px dashed $gray-50;
      padding-right: 25px;
      flex: 1 0 0;
    }
  }

  .card-spacer {
    &__between {
      margin-bottom: 15px !important;
    }

    &__after {
      margin-bottom: 40px !important;
    }
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @-moz-keyframes fadein { /* Firefox */
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadein { /* Safari and Chrome */
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @-o-keyframes fadein { /* Opera */
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
</style>
