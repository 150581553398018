<template>
  <div>
    <bb-modal
      :visible="visible"
      @close="onClose"
      flavor="card"
      unset-overflow
      full-mobile
      color="white"
      publicSize="m"
      class="contract-review-modal"
    >
      <translate slot="heading">{{translations.title}}</translate>
      <div class="contract-review-modal__icon-container m-t-10">
        <bb-icon name="illustration-contract" size="fluid" fill="green"/>
      </div>
      <div class="contract-review-modal__text m-t-60">{{translations.infoText}}</div>
      <div class="m-t-60 m-b-20">
        <bb-list-group>
          <bb-list-item condensed>
            <div class="contract-review-modal__list-item-container">
              {{translations.downloadDescriptionText}}
              <div class="contract-review-modal__download-button-container">
                <bb-button
                  class="download-button"
                  :label="translations.downloadLinkText"
                  :loading="documentLoading"
                  :disabled="!hasDocument"
                  @click="downloadDocument"
                  color="blue"
                  display="link"
                  no-underline
                  icon-after="ui-file-document-outline"
                  icon-after-color="blue"
                  icon-after-fixed
                />
              </div>
            </div>
          </bb-list-item>
        </bb-list-group>
      </div>
      <template #footer>
        <bb-button
          v-if="$screen.md"
          display="block"
          class="contract-review-modal__button"
          :label="translations.signButtonText"
          @click="handleSignClick"
        />
        <bb-button
          v-else
          display="block"
          class="contract-review-modal__button"
          :label="translations.signButtonText"
          @click="handleSignClick"
          corner="sharp"
          size="lg"
        />
      </template>
    </bb-modal>
    <signing-wrapper ref="signing" :contract="contract" />
  </div>
</template>

<script>
import '@bigbank/interface-components/dist/svg/illustration/contract'
import SigningWrapper from '@/modules/loan/components/signing/ContractChangeSigningWrapper'
import api from '@loan/api'
import { CONTRACT_CHANGE_SIGNING } from '@/TrackingActions'
import { isNil } from 'lodash'
import { mapState } from 'pinia'
import { useLoanStore } from '@loan/store/loanStore'

export default {
  name: 'review-contract-signing-modal',
  components: {
    SigningWrapper
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false
    },
    contract: {
      required: true
    },
    contractId: {
      required: false
    }
  },
  data () {
    return {
      documentLoading: false,
      document: {},
      translations: {
        title: this.$gettext('Sign contract'),
        infoText: this.$pgettext('contract_change_signing', 'Your contract change is ready to be signed.'),
        downloadDescriptionText: this.$pgettext('contract_change_signing', 'Your contract is available'),
        downloadLinkText: this.$pgettext('contract_change_signing', 'Download'),
        signButtonText: this.$pgettext('contract_change_signing', 'Sign contract')
      }
    }
  },
  computed: {
    ...mapState(useLoanStore, ['featureFlags']),
    hasDocument () {
      return this.document && this.document.fileId
    },
    shouldFetchData () {
      return this.contract.contractChangeSigning && !this.contract.contractChangeSigning.userSigned
    }
  },
  watch: {
    visible: {
      immediate: true,
      handler (isVisible) {
        if (isVisible) {
          this.fetchData()
        }
      }
    }
  },
  methods: {
    async handleSignClick (e) {
      this.$emit('close', e)
      await this.$refs.signing.signButtonClick(e)
    },
    onClose () {
      this.$emit('close')
    },
    async fetchData () {
      if (this.featureFlags.enableContractSigning && this.shouldFetchData) {
        this.documentLoading = true
        try {
          this.document = await api.getContractChangeDocument(this.contract.id)
        } catch (err) {
          console.error(err)
        }
        this.documentLoading = false
      }
    },
    async downloadDocument () {
      this.documentLoading = true
      if (this.hasDocument) {
        await api.initFileDownload(this.contract.id, this.document)
      }
      this.documentLoading = false
    }
  },
  mounted () {
    const contractId = (this.contract?.id ?? this.contractId)?.toString()
    if (!isNil(contractId) && contractId !== '') {
      this.$tracker.action(CONTRACT_CHANGE_SIGNING.VIEW, {}, { contractId })
    }
  }
}
</script>

<style lang="scss">
.contract-review-modal {
  h3.bb-modal__title {
    font-family: $gotham-medium;
    color: $blue;
    font-size: $font-size-small;
  }

  &__icon-container {
    margin: 0 auto;
    max-width: 200px;
  }

  &__text {
    font-family: $gotham-book;
    color: $gray;
    font-size: $font-size-small;
    text-align: center;
  }

  &__list-item-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }

  &__download-button-container {
    font-size: $font-size-small;

    .download-button {
      display: flex;
    }
  }

  &__button {
    width: 100%;
    padding: 30px;
  }
}
</style>
