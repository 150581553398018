<template>
  <bb-modal :visible="visible" @close="onClose()" flavor="card" color="white" publicSize="m" fullMobile class="invoice__wrapper">
    <div v-if="loading" class="center-spinner">
      <bb-spinner class="spinner" />
    </div>
    <div v-else class="invoice-content">
      <div class="invoice-title"><translate>Payment details</translate></div>
      <template v-if="nextPayment && nextPayment.dueDate">
        <div class="total-amount m-t-25">{{ formattedPaymentAmount }}</div>
        <div class="due-date"><span><translate>Due Date</translate>: {{ formatDate(nextPayment.dueDate) }}</span></div>
      </template>
      <div class="payment-details-title m-t-25"><translate>Requisites</translate>:</div>
      <payment-details class="m-t-15" v-bind="paymentDetails" />
    </div>
  </bb-modal>
</template>

<script>
import { mapState } from 'pinia'
import PaymentDetails from '@/modules/loan/components/PaymentDetails'
import { isValidDate } from '@/plugins/dateFormatters'
import { formatMoneyWithCurrency } from '@/plugins/numformat'
import { useRootStore } from '../../../store/root'
import { useLoanStore } from '@loan/store/loanStore'

export default {
  name: 'PromiseToPayModal',
  components: { PaymentDetails },
  data () {
    return {
      loading: false
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    promiseToPay: {
      type: Object
    }
  },
  methods: {
    onClose () {
      this.$emit('close')
    },

    formatDate (date) {
      return formatDateWithOptions(date, this.locale, { year: 'numeric', month: '2-digit', day: '2-digit' })
    }
  },
  computed: {
    ...mapState(useRootStore, ['locale']),
    ...mapState(useLoanStore, ['contracts']),
    paymentDetails () {
      return this.promiseToPay.paymentDetails
    },
    nextPayment () {
      return this.promiseToPay.nextPayment
    },
    formattedPaymentAmount () {
      return formatMoneyWithCurrency(this.nextPayment.paymentAmount, this.nextPayment.currencyCode, this.locale)
    }
  }
}

function formatDateWithOptions (date, locale, options) {
  return isValidDate(date) && locale
    ? new Date(date).toLocaleDateString(locale, options)
    : '-'
}
</script>

<style lang="scss" scoped>
.center-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.invoice-content {
  .invoice-title {
    color: $blue;
    font-size: $default-font-size;
    text-align: center;
    font-family: $gotham-medium;
    font-weight: bold;
  }

  .total-amount {
    text-align: center;
    font-family: $gotham-medium;
    font-size: $h3-size;
  }

  .due-date {
    color: $gray-70;
    font-family: $gotham-medium;
    text-align: center;
    font-size: $font-size-smallest;
    font-weight: bold;
  }

  .payment-details-title {
    font-family: $gotham-medium;
    text-transform: uppercase;
    color: $gray;
    font-size: $font-size-smallest;
    text-align: center;
  }
}

</style>
