<template>
  <div class="credit-card-block-modal">
    <bb-modal
      :visible="visible"
      flavor="card"
      @close="onModalClosed"
      class="noselect text-center"
      centerVertically
      fullMobile
    >
      <template v-if="isDataLoading">
        <div class="loader">
          <bb-spinner />
        </div>
      </template>
      <template v-else>
        <h5 class="text-center modal-title">{{ modalTitle }}</h5>
        <h4 class="text-center f-gotham-book m-t-30 modal-description" v-sanitize.basic="translations.description" />
        <h4 class="text-center f-gotham-book m-t-40 f-default f-color-gray-70">{{ translations.selectReason }}</h4>
        <bb-detailed-radio
          class="text-left"
          v-model="reason"
          name="block-reason"
          :options="radioButtonOptions"
        />
        <div v-if="isOrderNewCardBlockVisible" class="bg-gray-10 p-x-15 p-y-15 m-t-15 text-left f-smallest f-color-navy replace-info">
          <template v-if="!isReplacementCardOrdered">
            <bb-checkbox :disabled="isOrderingNewCardDisabled" name="order-new-card-checkbox" v-model="orderNewCardCheckbox" noPadding>
              {{ translations.replace }}
            </bb-checkbox>
            <customer-address-text-label v-if="orderNewCardCheckbox" :translations="translations"/>
            <p v-else-if="isCanNotOrderCardDueToDebtVisible" class="notice" v-sanitize.basic="translations.canNotOrderCardDueToDebt" />
            <p v-else-if="isCanNotOrderCardDueToBalanceVisible" class="notice" v-sanitize.basic="translations.canNotOrderCardDueToBalance" />
          </template>
          <template v-else>
            <p>{{ translations.replacementCardOrdered }}</p>
            <customer-address-text-label :replacement-card-ordered="true" :translations="translations"/>
          </template>
        </div>
        <div v-if="isCloseNotificationVisible" class="f-color-gray f-smallest m-t-15">
          {{ translations.closedAt }}
        </div>
      </template>
      <div slot="footer" class="submit-button">
        <bb-button
          name="submit-block"
          v-bind="submitButtonProps"
          @click="onBlockButtonClick"
        />
      </div>
    </bb-modal>
  </div>
</template>

<script>
import '@bigbank/interface-components/dist/svg/illustration/cvv'
import CardTypeTranslationMixin from '../mixins/CardTypeTranslationMixin'
import { CardBlockReason, CardReplacementStatus } from '@bigbank/dc-common/clients/http/credit-card/credit-card.enums'
import CustomerAddressTextLabel from '@/modules/account/components/CustomerAddressTextLabel'
import { isNull } from 'lodash'
import CreditCardModalContentMixin from '@credit-card/components/mixins/CreditCardModalContentMixin'
import CustomerAddressTextLabelMixin from '@/modules/account/components/mixins/CustomerAddressTextLabelMixin'
import { mapState, mapActions } from 'pinia'
import api from '@loan/api'
import { formatMoneyWithCurrency } from '@/plugins/numformat'
import { ORDER_NEW_CREDIT_CARD_COST } from '../credit-card.const'
import { useRootStore } from '../../../../store/root'
import { useLoanStore } from '@loan/store/loanStore'

export default {
  name: 'credit-card-block-modal',
  components: { CustomerAddressTextLabel },
  mixins: [
    CardTypeTranslationMixin,
    CreditCardModalContentMixin,
    CustomerAddressTextLabelMixin
  ],
  props: {
    visible: { required: true, type: Boolean },
    cardId: { required: true, type: Number },
    card: { required: true, type: Object }
  },
  data () {
    return {
      isLoading: false,
      reason: null,
      orderNewCardCheckbox: null,
      invoiceDetails: null,
      cardInfo: null,
      isCardInfoLoading: false,
      isInvoiceDetailsLoading: false
    }
  },
  watch: {
    isOrderNewCardBlockVisible: {
      immediate: true,
      handler (isVisible) {
        if (this.isOrderingNewCardDisabled) {
          this.setOrderNewCardCheckbox(null)
        } else if (this.isReplacementCardOrdered) {
          this.setOrderNewCardCheckbox(null)
        } else if (isVisible && isNull(this.orderNewCardCheckbox)) {
          this.setOrderNewCardCheckbox(true)
        } else if (!isVisible && this.orderNewCardCheckbox === true) {
          this.setOrderNewCardCheckbox(null)
        }
      }
    },
    cardId: {
      immediate: true,
      handler (cardId) {
        if (!cardId) {
          return
        }
        this.getCardInfo()
      }
    },
    'contract.isInDebt': {
      immediate: true,
      handler (isInDebt) {
        if (isInDebt) {
          this.getLatestInvoice()
        } else {
          this.invoiceDetails = null
        }
      }
    }
  },
  computed: {
    ...mapState(useRootStore, ['locale']),
    translations () {
      return {
        modalTitle: this.$pgettext('credit_card_block_title', 'Block card'),
        modalTitleVirtual: this.$pgettext('credit_card_block_title', 'Block virtual card'),
        blockButton: this.$pgettext('credit_card_block_button', 'Block card'),
        blockAndOrderButton: this.$pgettext('credit_card_block_button', 'Block and order new card'),
        selectReason: this.$pgettext('credit_card_block', 'Select the reason:'),
        addressTooltip: this.$pgettext('credit_card_block', 'You can change the contact address under your data in self-service'),
        replace: this.$pgettext('credit_card_block', 'I want to replace credit card'),
        successNotification: this.$pgettext('credit_card_block', 'You have blocked your card successfully'),
        closedAt: this.$pgettext('credit_card_block', 'Your card will be closed after it has been blocked for 14 days'),
        replaceLine1: this.$gettextInterpolate(this.$pgettext('credit_card_block', 'Card replacement fee is <strong>%{fee} EUR</strong>. Money will be withdrawn from your limit.'), {
          fee: this.replacementFee
        }),
        replaceLine2: this.$pgettext('credit_card_block', 'Card will be blocked and we will send a new card to your:'),
        replaceLine3: this.$gettextInterpolate(this.$pgettext('credit_card_block', 'Contact address - <strong>%{address}</strong>'), {
          address: this.customerContactAddress
        }),
        description: this.$gettextInterpolate(this.$pgettext('credit_card_block', 'You want to block %{cardType} %{maskedPan} because:'), {
          cardType: this.cardTypeLabel(this.card),
          maskedPan: this.card.maskedPan
        }),
        options: {
          [CardBlockReason.LostOrStolen]: this.$pgettext('credit_card_block', 'Card is lost or stolen'),
          [CardBlockReason.Fraud]: this.$pgettext('credit_card_block', 'Suspicion of card fraud'),
          [CardBlockReason.Inactive]: this.$pgettext('credit_card_block', 'Not using the card')
        },
        editAddress: this.$pgettext('credit_card_block', 'Edit contact address'),
        replacementCardOrdered: this.$pgettext('credit_card_block', 'Replacement card ordered. The card will be sent to your:'),
        canNotOrderCardDueToDebt: this.$gettextInterpolate(this.$pgettext('credit_card_block', 'Unfortunately you can not order a new card, because of the <strong>%{debtAmount}</strong> debt sum.'), {
          debtAmount: this.debtAmount
        }),
        canNotOrderCardDueToBalance: this.$gettextInterpolate(this.$pgettext('credit_card_block', 'Unfortunately you can not order a new card, because your available limit is less than <strong>%{amount}</strong>.'), {
          amount: this.cardCostAmount
        })
      }
    },
    isDataLoading () {
      return this.isCardInfoLoading || this.isInvoiceDetailsLoading
    },
    radioGroupOptions () {
      return [CardBlockReason.Fraud, CardBlockReason.Inactive]
    },
    submitButtonText () {
      if (this.isValidReasonToReplace && this.isOrderNewCardBlockVisible && this.orderNewCardCheckbox && !this.isReplacementCardOrdered) {
        return this.translations.blockAndOrderButton
      } else {
        return this.translations.blockButton
      }
    },
    isReplacementCardOrdered () {
      return this.card.replacementStatus === CardReplacementStatus.IN_PROGRESS
    },
    isOrderNewCardBlockVisible () {
      if (this.isReplacementCardOrdered) {
        return this.isPhysicalCard
      } else {
        return this.isPhysicalCard && [
          CardBlockReason.LostOrStolen,
          CardBlockReason.Fraud
        ].includes(this.reason)
      }
    },
    isCanNotOrderCardDueToDebtVisible () {
      return this.cardInfo.isPortfolioInDebt
    },
    isCanNotOrderCardDueToBalanceVisible () {
      return !this.cardInfo.hasBalanceForNewCard
    },
    isOrderingNewCardDisabled () {
      return this.cardInfo && this.cardInfo.canOrderNewCard === false
    },
    debtAmount () {
      const currency = this.contract.currency
      const locale = this.locale
      let amount = 0

      if (this.invoiceDetails) {
        amount = this.invoiceDetails.totalAmount
      } else {
        amount = this.contract.totalDebtAmount
      }

      return formatMoneyWithCurrency(amount || 0, currency, locale, {
        zeroAs: `- ${currency}`
      })
    },
    cardCostAmount () {
      const currency = this.contract.currency
      const locale = this.locale

      return formatMoneyWithCurrency(ORDER_NEW_CREDIT_CARD_COST, currency, locale)
    }
  },
  methods: {
    ...mapActions(useLoanStore, ['blockCreditCard']),
    async onBlockButtonClick () {
      this.setLoading(true)
      try {
        await this.blockCreditCard({
          contractId: this.contract.id,
          cardId: this.cardId,
          reason: this.reason,
          orderNewCard: !!this.orderNewCardCheckbox
        })
        this.$notify({ text: this.translations.successNotification, duration: 10000 })
        this.$emit('onModalClosed', {
          refresh: true
        })
      } finally {
        this.setLoading(false)
      }
    },
    async getLatestInvoice () {
      this.isInvoiceDetailsLoading = true
      this.invoiceDetails = await api.getInvoiceDetails(this.contract.id)
      this.isInvoiceDetailsLoading = false
    },
    async getCardInfo () {
      this.isCardInfoLoading = true
      this.cardInfo = await api.getCreditCardInfoByCardId(this.cardId)
      this.isCardInfoLoading = false
    }
  }
}
</script>

<style lang="scss">
.credit-card-block-modal {
  .modal-title {
    color: $blue;
    font-weight: 500;
    font-size: $font-size-small;
    font-family: $gotham-medium;
  }

  .modal-description {
    color: $black;
    font-size: $default-font-size;
  }

  .submit-button {
    @media (min-width: $desktop-view-breaking-point) {
      margin: 0 10px;
    }
  }

  /* stylelint-disable-next-line */
  ::v-deep .bb-detailed-radio-item:not(.bb-detailed-radio-item--disabled):not(.bb-detailed-radio-item--single-option) .bb-detailed-radio-item__input:checked + .bb-detailed-radio-item__content-wrapper {
    border-color: $mint-70;
    background: rgba($mint-10, 0.4);
  }

  .notice {
    margin-top: 16px;
    font-size: $font-size-small;

    strong {
      color: $red;
    }
  }
}
</style>
