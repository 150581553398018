export default {
  methods: {
    filterAndSortOffers (offers, paymentFrequencyCode) {
      offers = offers.filter(offer => offer.interestPaymentFrequencyCode === paymentFrequencyCode)
      offers = offers.sort((a, b) => a.rank - b.rank)

      return offers
    },
    findMatchingOffer (offers, period) {
      if (!period) {
        return
      }

      return offers.find(offer => offer.period === period)
    }
  }
}
