<template>
  <div class="deposit_withdraw">
    <bb-modal
      :visible="showFormView"
      @close="onClose"
      v-bind="modalProps">
      <template #heading>
        <div class="deposit_withdraw__title">
          <translate translate-context="withdraw_modal_title">Withdraw</translate>
        </div>
      </template>
      <template v-if="isLoading || signingLoading">
        <div class="deposit_withdraw__loader">
          <bb-spinner />
        </div>
      </template>
      <template v-else>
        <div class="deposit_withdraw__content">
          <bb-form @progress="onProgress">
            <bb-big-input-currency
              class="m-b-10"
              background
              name="withdrawalAmount"
              v-model="form.amount"
              placeholder="0,00"
              :label="translations.availableBalance + ' ' + availableBalance"
              v-validate="amountValidationRules"
              :data-vv-as="translations.labels.amount"
            />
            <bank-account-input
              type="select"
              :showAddNewAccount="false"
              :showToAnotherAccount="true"
              @input="onBankAccountInput"
              :validatedAccounts="validatedAccounts"
              :internalAccounts="internalAccountsFiltered"
              :value="form.account"
              :validateIbanCountry="false"
              autoFill
              flavor="deposit"
            />
            <template v-if="selectedAccount && !isInternalAccount">
              <bb-input
                name="beneficary"
                type="text"
                :label="translations.labels.beneficary"
                :value="beneficary"
                :readonly="true" />
              <bb-input
                name="bic"
                type="text"
                :label="translations.labels.bicSwift"
                :value="bic"
                :readonly="true" />
            </template>
            <bb-banner
              class="m-t-30 deposit_withdraw__transfer-notice"
              :visible="banner.visible"
              :type="banner.type"
              permanent
            >
              <div class="deposit_withdraw__transfer-notice__content">
                <p v-for="(text, index) in banner.text" :key="index">{{text}}</p>
                <bb-button
                  v-if="banner.showTransferButton"
                  display="block"
                  class="p-r-0 p-l-0"
                  @click="toTopUp"
                >
                  {{translations.transfer}}
                </bb-button>
              </div>
            </bb-banner>
            <bb-banner
              v-if="isAccountLimitExceededBannerShown"
              class="m-t-30"
              type="warning"
              :title="translations.accountFreeLimit"
              :visible="true"
              permanent
            >
              {{ translations.accountFreeLimitDescription }}
              <bb-link :to="{ name: AccountRouteName.AccountVerification }">
                {{ translations.learnMore }}
              </bb-link>
            </bb-banner>
            <div slot="submit" slot-scope="{}" />
          </bb-form>
        </div>
      </template>
      <div slot="footer" :class="{'p-x-10': $screen.md, 'p-b-5': $screen.md}">
        <bb-button
          data-testid="withdraw-close"
          display="block"
          v-bind="button"
          :size="!$screen.md ? 'lg' : undefined"
          :corner="!$screen.md ? 'sharp' : undefined"
          @click="submit">
          <span class="color-green" v-if="isOtherAccount">
            <translate>Got It</translate>
          </span>
          <span v-else>
            <translate>Withdraw</translate>
          </span>
        </bb-button>
      </div>
    </bb-modal>
    <close-demand-deposit-modal
      :translations="translations"
      @close="onCloseConfirmCloseContract"
      @closeContract="closeContract"
      v-if="showCloseContractModal"
      :modal-props="modalProps"
      class="deposit_withdraw__close-confirmation"
    />
    <close-demand-deposit-error-modal
      v-if="showCloseContractErrorModal"
      :modal-props="modalProps"
      @close="showCloseContractErrorModal = false"
    />
    <bb-modal
      visible
      @close="onCloseConfirmInternalAccount"
      v-if="showConfirmInternalAccountModal"
      v-bind="modalProps">
      <div class="deposit_withdraw__title">
        <translate translate-context="withdraw_modal_title">Withdraw</translate>
      </div>
      <bb-big-input
        class="m-t-20 m-b-30"
        background
        disabled
        name="withdrawalAmount"
        v-model="form.amount"
        :suffix="'\xa0' + currencySymbol"
      />
      <bb-detailed-radio
        data-testid="withdrawal-to-internal-account-options"
        v-model="confirmInternalAccountOption"
        name="internalAccountOption"
        :options="internalAccountOptions"
      />
      <div slot="footer" :class="{'p-x-10': $screen.md, 'p-b-5': $screen.md}">
        <bb-button
          data-testid="withdrawal-to-internal-account-button"
          display="block"
          @click="confirmInternalAccount"
          v-bind="button"
          :disabled="!confirmInternalAccountOption"
          :size="!$screen.md ? 'lg' : undefined"
          :corner="!$screen.md ? 'sharp' : undefined"
        >
          <translate>Confirm Transfer</translate>
        </bb-button>
      </div>
    </bb-modal>
    <sign-modal
      v-if="showSignModal"
      :deposit="defaultDemandDeposit"
      :chosen-sign-method="chosenSignMethod"
      :signingRequestId="signingRequestId"
      @signingSuccessful="complete"
      @signingError="onSigningError"
      @close="onCloseSignModal" />
  </div>
</template>

<script>
import { formatMoneyWithCurrency } from '@/plugins/numformat'
import { api } from '@deposits/api'
import { mapState } from 'pinia'
import BankAccountInput from '@/components/Input/BankAccountInput'
import SignModal from '@deposits/components/SignModal'
import { DEMAND_DEPOSIT_DASHBOARD } from '@/TrackingActions'
import { depositStates } from './../../utils/depositStates'
import DemandDepositWithdrawMixin from './../../mixins/demandDepositWithdrawMixin'
import SigningMixin from '@/mixins/signingMixin'
import { DepositType, DepositRouteName } from '@deposits/const'
import { AccountRouteName } from '@account/const'
import CloseDemandDepositModal from '../../components/CloseDemandDepositModal.vue'
import CloseDemandDepositErrorModal from '../../components/CloseDemandDepositErrorModal.vue'
import { isNull } from 'lodash'
import { useRootStore } from '../../../../store/root'
import { useDepositStore } from '@deposits/store/depositStore'
import { useAccountStore } from '@account/store/accountStore'

const confirmOptions = {
  TO_APPLICATION: 1,
  NEW_APPLICATION: 2
}

export default {
  name: 'demand-deposit_withdraw',
  components: { BankAccountInput, SignModal, CloseDemandDepositModal, CloseDemandDepositErrorModal },
  mixins: [DemandDepositWithdrawMixin, SigningMixin],
  data () {
    return {
      AccountRouteName,
      showConfirmInternalAccountModal: false,
      showCloseContractErrorModal: false,
      confirmInternalAccountOption: null,
      isAccountLimitExceededBannerShown: false,
      form: {
        account: null,
        amount: '',
        closeContract: undefined,
        confirmInternalAccount: undefined
      }
    }
  },
  watch: {
    internalAccountOptions () {
      this.setDefaultValues()
    },
    waitingFundsDeposits () {
      this.setDefaultValues()
    },
    'form.amount' () {
      this.setIsAccountLimitExceededBannerShown(false)
    }
  },
  computed: {
    ...mapState(useRootStore, ['locale', 'currency', 'isCompany', 'channel']),
    ...mapState(useAccountStore, ['verificationData']),
    ...mapState(useDepositStore, ['featureFlags', 'depositsByProductAndCategory']),
    translations () {
      return {
        labels: {
          amount: this.$pgettext('withdraw_form_label', 'Withdrawal amount'),
          beneficary: this.$pgettext('withdraw_form_label', 'Beneficary'),
          bicSwift: this.$pgettext('withdraw_form_label', 'BIC/SWIFT')
        },
        availableBalance: this.$pgettext('withdraw_form', 'Available balance'),
        transferNotice: this.$pgettext('withdraw_form', 'In order for your IBAN to be validated, please transfer 0.01 EUR to your account.'),
        internalAccount: this.$pgettext('withdraw_form', 'You have selected an internal BigBank account number belonging to you'),
        externalAccount: this.$pgettext('withdraw_form_label', 'You are sending money to external account.'),
        transferInDaysPrivate: this.$pgettext('withdraw_form_label', 'Your money will be transferred in 3 business days.'),
        transferInDaysCorporate: this.$pgettext('corporate_withdraw_form_label', 'Your money will be transferred in 3 business days.'),
        accountFreeLimit: this.$pgettext('withdraw_form', 'Account free limit'),
        accountFreeLimitDescription: this.$gettextInterpolate(
          this.$pgettext(
            'withdraw_form',
            'In order to transfer to external account %{amount}, ' +
            'you need to verify your account. ' +
            'Your current account free limit is %{availableLimit}.'
          ),
          {
            amount: formatMoneyWithCurrency(
              this.form.amount,
              this.currency,
              this.locale,
              { fractionDigits: 0 }
            ),
            availableLimit: formatMoneyWithCurrency(
              this.verificationData.availableAmount,
              this.currency,
              this.locale,
              { fractionDigits: 0 }
            )
          }
        ),
        learnMore: this.$pgettext('withdraw_form', 'Learn more'),
        withdrawalSuccessful: this.$pgettext('withdraw_form', 'We have recieved your withdrawal request and are processing it.'),
        transfer: this.$pgettext('withdraw_form', 'Transfer'),
        yes: this.$pgettext('withdraw_form_close_contract', 'YES'),
        no: this.$pgettext('withdraw_form_close_contract', 'NO'),
        closeNotice: () => {
          if (this.isCompany) {
            return this.$pgettext('corporate_withdraw_modal', 'Would you like also to close your demand deposit contract?')
          }
          return this.$pgettext('withdraw_modal', 'Would you like also to close your demand deposit contract?')
        }
      }
    },
    showFormView () {
      return !this.showSignModal && !this.showCloseContractModal && !this.showConfirmInternalAccountModal
    },
    transferNotice () {
      return this.translations.transferNotice.replace('{iban}', '')
    },
    depositTypeCode () {
      return this.defaultDemandDeposit.depositTypeCode
    },
    isInternalAccount () {
      return !!(this.selectedAccount && this.internalAccountsList.find(acc => acc.accountNumber === this.selectedAccount.accountNumber))
    },
    internalAccountOptions () {
      const contractNumbers = this.waitingFundsDeposits
        .map(deposit => deposit.contractNumber)
        .join(', ')

      return [{
        value: confirmOptions.TO_APPLICATION,
        leftColumn: {
          label: this.$pgettext('withdraw_form', 'To Your current term deposit application:') + ' ' + contractNumbers
        },
        extra: {
          description: this.$pgettext('withdraw_form', 'Money will be transferred to Your term deposit account. Given the full amount is received, contract will be activated.')
        },
        visible: this.waitingFundsDeposits.length > 0
      }, {
        value: confirmOptions.NEW_APPLICATION,
        leftColumn: {
          label: this.$pgettext('withdraw_form', 'Make a new term deposit application')
        },
        extra: {
          description: this.$pgettext('withdraw_form', 'Start a new term deposit application, choose Your preferred conditions and sign the contract. Money will be transferred to Your term deposit account.')
        },
        visible: this.featureFlags.enableApplyTermDeposits
      }].filter(({ visible }) => visible)
    },
    waitingFundsDeposits () {
      return this.depositsByProductAndCategory[DepositType.Term].active
        .filter(deposit => deposit.state === depositStates.WAITING_FUNDS)
    },
    hasInternalAccountOptions () {
      return this.internalAccountOptions.length > 0 || this.featureFlags.disableNewTermDepositCreationOnWithdraw
    },
    internalAccountsFiltered () {
      return this.hasInternalAccountOptions ? this.internalAccounts : []
    },
    banner () {
      const hasSelectedAccount = !!this.selectedAccount
      const bannerOptions = {
        visible: false,
        text: [],
        showTransferButton: false,
        type: undefined
      }

      if (hasSelectedAccount && this.isInternalAccount) {
        bannerOptions.visible = true
        bannerOptions.text.push(this.translations.internalAccount)
      } else if (this.isOtherAccount) {
        bannerOptions.visible = true
        bannerOptions.text.push(this.transferNotice)
        bannerOptions.showTransferButton = true
      } else if (hasSelectedAccount && !this.isInternalAccount) {
        bannerOptions.visible = true
        bannerOptions.type = 'info'
        bannerOptions.text.push(this.translations.externalAccount)
        bannerOptions.text.push(this.isCompany
          ? this.translations.transferInDaysCorporate
          : this.translations.transferInDaysPrivate)
      }

      return bannerOptions
    }
  },
  methods: {
    setIsAccountLimitExceededBannerShown (flag) {
      this.isAccountLimitExceededBannerShown = flag
    },
    toTopUp () {
      this.$router.push({ name: DepositRouteName.DemandDepositsTopUp })
    },
    onClose () {
      return this.$router.push({ name: DepositRouteName.DemandDeposits })
    },
    onCloseConfirmInternalAccount () {
      this.showConfirmInternalAccountModal = false
      this.form.confirmInternalAccount = false
    },
    confirmInternalAccount () {
      this.form.confirmInternalAccount = true
      this.showConfirmInternalAccountModal = false
      this.submit()
    },
    async submit () {
      if (this.isOtherAccount) {
        this.$tracker.action(
          DEMAND_DEPOSIT_DASHBOARD.DEMAND_WITHDRAW_TO_ANOTHER_IBAN,
          {}, { contractId: this.defaultDemandDeposit.id.toString() }
        )
        return this.onClose()
      }

      if (this.signingLoading || !await this.$validator.validate()) {
        return
      }

      if (isNull(this.withdrawalInfo)) {
        this.$apm.captureError(new Error(`withdrawalInfo is null | keys of defaultDemandDeposit: ${JSON.stringify(Object.keys(this.defaultDemandDeposit))}`))
      }

      const isWithdrawAll = parseFloat(this.withdrawalInfo.availableForWithdrawal) <= this.form.amount
      const isCloseModalEnabled = this.featureFlags.disableCloseDemandDeposit !== true
      const showCloseModal = isCloseModalEnabled && isWithdrawAll && !this.hasSeenCloseContractModal

      if (this.isInternalAccount && !this.form.confirmInternalAccount && !this.featureFlags.disableNewTermDepositCreationOnWithdraw) {
        this.showConfirmInternalAccountModal = true
        return
      } else if (showCloseModal) {
        this.setShowCloseContractModal(true)
        return
      }

      this.setSigningLoading(true)

      try {
        const response = await api.initWithdraw(this.defaultDemandDeposit.id, this.form)

        this.setSigningMethod(response.method)
        this.setSigningRequestId(response.signingRequestId)
        this.toggleSigningModalVisibility(response.isSigningNeeded)

        !response.isSigningNeeded && this.complete()

        this.$tracker.action(
          DEMAND_DEPOSIT_DASHBOARD.DEMAND_WITHDRAW_CONFIRM,
          {}, { contractId: this.defaultDemandDeposit.id.toString() }
        )
      } catch (err) {
        this.handleError(err)
      } finally {
        this.setSigningLoading(false)
      }
    },
    handleError (err) {
      if (err.err_code === 'MONTHLY_LIMIT_EXCEEDED') {
        this.setIsAccountLimitExceededBannerShown(true)
      } else if (err.err_code === 'SAVINGS_DEPOSIT_ACCOUNT_IS_LINKED_TO_TERM_DEPOSIT_CONTRACT') {
        this.showCloseContractErrorModal = true
        this.toggleSigningModalVisibility(false)
      }
    },
    complete () {
      this.$tracker.action(
        DEMAND_DEPOSIT_DASHBOARD.DEMAND_WITHDRAW_COMPLETE,
        {}, { contractId: this.defaultDemandDeposit.id.toString() }
      )

      this.onClose()
      this.$notify({ text: this.translations.withdrawalSuccessful, duration: 10000 })
      this.afterComplete()

      if (
        this.isInternalAccount &&
        this.confirmInternalAccountOption === confirmOptions.NEW_APPLICATION &&
        !this.featureFlags.disableNewTermDepositCreationOnWithdraw
      ) {
        this.$router.push({
          name: DepositRouteName.New,
          params: {
            depositType: DepositType.Term
          },
          query: {
            amount: this.form.amount
          }
        })
      }
    },
    onSigningError (err) {
      this.handleError(err)
    },
    setDefaultValues () {
      this.confirmInternalAccountOption = this.confirmInternalAccountOption || (this.waitingFundsDeposits.length > 0
        ? confirmOptions.TO_APPLICATION
        : confirmOptions.NEW_APPLICATION)
    }
  },
  mounted () {
    this.$tracker.action(
      DEMAND_DEPOSIT_DASHBOARD.DEMAND_DEPOSIT_WITHDRAW,
      {},
      { contractId: this.defaultDemandDeposit.id.toString() },
      true)

    this.form.confirmInternalAccount = false
    this.setDefaultValues()
  }
}
</script>

<style lang="scss">
.deposit_withdraw {
  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__title {
    color: $blue;
    font-size: $small-font-size;
    font-family: $gotham-medium;
    text-align: center;
  }

  &__close-confirmation {
    &__buttons {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      margin-left: 30px;
      margin-right: 30px;
      margin-bottom: 30px;

      @media screen and (min-width: $desktop-view-breaking-point) {
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 10px;
      }

      .bb-button {
        width: 100%;
      }
    }
  }

  &__transfer-notice {
    .bb-banner__body {
      display: block;
    }

    &__button {
      text-align: center;
    }

    &__content {
      display: flex;
      flex-direction: column;
      gap: 20px;
      font-size: $small-font-size;
      margin-top: -3px;
      margin-bottom: -3px;
      color: $gray;
    }
  }
}
</style>
