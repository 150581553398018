<template>
  <bb-button
    display="square"
    class="nm-y-5"
    icon="ui-content-copy"
    color="gray"
    noBackground
    :size="size"
    :title="translations.copy"
    @click="copyValue(item)"
  >
    <bb-tooltip bubble v-if="showTooltip">{{translations.copied}}</bb-tooltip>
  </bb-button>
</template>

<script>
import copy from '@/plugins/copy'
import '@bigbank/interface-components/dist/svg/ui/content-copy'

const SECOND_IN_MS = 1000

export default {
  name: 'InfoBoxCopyButton',
  props: {
    item: {
      default: ''
    },
    size: {
      default: undefined
    }
  },
  data () {
    return {
      showTooltip: false,
      timeout: null
    }
  },
  methods: {
    copyValue (value) {
      copy(value)
      this.showTooltip = true
      if (this.timeout) {
        clearTimeout(this.timeout)
      }
      this.timeout = setTimeout(() => {
        this.showTooltip = false
      }, SECOND_IN_MS)
    }
  },
  computed: {
    translations () {
      return {
        copied: this.$gettext('Copied!'),
        copy: this.$pgettext('copy_button', 'Click to copy')
      }
    }
  }
}
</script>
