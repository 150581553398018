<template>
  <eparaksts
    :visible="visible"
    :startSigning="startSigning"
    :completeSigning="completeSigning"
    v-on="$listeners"
  />
</template>

<script>
import { api } from '../../api'
import Eparaksts from '../../../../components/signing/EparakstsSigning.vue'
import { DepositRouteName } from '@deposits/const'

export default {
  name: 'deposit-eparaksts-signing',
  components: { Eparaksts },
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false
    },
    deposit: {
      required: true
    },
    signingRequestId: {
      required: true
    }
  },
  methods: {
    startSigning () {
      return api.startEparakstsSigning(this.deposit.id, this.signingRequestId, this.getRedirectUrl())
    },
    completeSigning () {
      return api.completeEparakstsSigning(this.deposit.id, this.signingRequestId)
    },
    getRedirectUrl () {
      let path = this.$router.currentRoute.path

      if (this.$router.currentRoute.name === DepositRouteName.New) {
        path = this.$router.resolve({
          name: DepositRouteName.Signing,
          params: {
            contractId: this.deposit.id
          }
        }).href
      }

      return window.location.origin + path
    }
  }
}
</script>
