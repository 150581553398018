<template>
  <div v-if="processStarted">
    <component v-if="signingComponent" :is="signingComponent" v-bind="signingMethodProps" @signingSuccessful="onSigned" @close="onClose" />
    <signing-error-messages v-else-if="method" v-bind="errorProps" @close="onClose" />
    <signing-modal-wrapper v-else visible showLoader @close="onClose" />
  </div>
</template>

<script>
import { AuthMethod } from '@bigbank/dc-common/config'
import SigningModalWrapper from '@/components/signing/SigningModalWrapper'
import SigningErrorMessages from '@/components/signing/SigningErrorMessages'
import SignWithSmartId from './SignWithSmartId'
import SignWithMobileId from './SignWithMobileId'
import SignWithIdCard from './SignWithIdCard'

export default {
  name: 'signing-modal',
  components: {
    SigningModalWrapper,
    SigningErrorMessages,
    SignWithSmartId,
    SignWithMobileId,
    SignWithIdCard
  },
  props: {
    initMethod: {
      required: true
    }
  },
  data () {
    return {
      processStarted: false,
      initResult: null,
      translations: {
        useAnotherMethod: this.$pgettext('signing_modal', 'Please use another method')
      }
    }
  },
  computed: {
    method () {
      return this.initResult && this.initResult.method
    },
    signingComponent () {
      return this.method && {
        [AuthMethod.SMART_ID]: 'sign-with-smart-id',
        [AuthMethod.MOBILE_ID]: 'sign-with-mobile-id',
        [AuthMethod.ID_CARD]: 'sign-with-id-card'
      }[this.method]
    },
    signingRequestId () {
      return this.initResult && this.initResult.signingRequestId
    },
    signingMethodProps () {
      return {
        signingRequestId: this.signingRequestId
      }
    },
    errorProps () {
      return {
        code: 'UNKNOWN_SIGN_METHOD',
        button: 'logout',
        titleOverride: this.translations.useAnotherMethod,
        showAlertIcon: true,
        visible: true
      }
    }
  },
  methods: {
    async signButtonClick (e) {
      e.preventDefault()
      this.reset()
      this.processStarted = true
      this.initResult = await this.initMethod()

      return false
    },
    onSigned () {
      // assign it to a variable so reset does not delete it
      const signiningRequestId = this.signingRequestId
      this.reset()
      this.$emit('signingSuccessful', signiningRequestId)
    },
    onClose () {
      this.reset()
      this.$emit('signingCancelled')
    },
    reset () {
      this.processStarted = false
      this.initResult = null
    }
  }
}
</script>
