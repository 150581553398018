<template>
  <bb-application-contract-card v-bind="cardProps" v-on="events" :class="spacerClass" />
</template>

<script>
import '@bigbank/interface-components/dist/svg/client/wave'
import isObject from 'lodash/isObject'
import { isAbsoluteUrl } from '@/plugins/urlHelpers'

export default {
  name: 'offer-card',
  props: {
    card: {
      required: true
    },
    index: {
      required: false
    },
    count: {
      required: false
    },
    spacerClass: {
      required: false,
      default: 'm-b-15'
    }
  },
  computed: {
    cardProps () {
      return {
        type: 'offer',
        title: this.card.title,
        icon: { name: 'client-wave', fill: 'default' },
        description: this.card.description,
        primaryButton: {
          label: this.card.buttonText
        }
      }
    },
    events () {
      return {
        primaryButtonClick: this.goToCard
      }
    }
  },
  methods: {
    goToCard () {
      if (this.card.trackAction) {
        this.$tracker.action(this.card.trackAction, undefined, undefined, true)
      }

      if (isObject(this.card.to) || !isAbsoluteUrl(this.card.to)) {
        this.$router.push(this.card.to)
      } else {
        window.open(this.card.to, '_blank')
      }
    }
  }
}
</script>
