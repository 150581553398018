<template>
  <div>
    <multi-cards class="contract__content__cards" status="active" />
    <loan-transactions
      v-if="showTransactions"
      :hideEmptyView="true"
      :visibleTransactionsCount="3"
      :showHistoryButton="{
        to: { name: 'loan-transactions' },
        label: translations.showHistory
      }"
    />
  </div>
</template>

<script>
import MultiCards from './../../components/MultiCards'
import LoanTransactions from './Transactions'
import { mapState } from 'pinia'
import { useLoanStore } from '@loan/store/loanStore'

export default {
  name: 'loan-active',
  components: {
    MultiCards,
    LoanTransactions
  },
  computed: {
    ...mapState(useLoanStore, ['featureFlags']),
    showTransactions () {
      return this.featureFlags.enableTransactionsView
    },
    translations () {
      return {
        showHistory: this.$pgettext('loan_dashboard', 'Show history')
      }
    }
  }
}
</script>
