<template>
  <layout-with-menu :loading="loading" :addClick="newDeposit">
    <div class="demand-deposit">
      <hero-header
        v-if="isHeroHeaderVisible"
        product="DEMAND"
        :state="state"
        :username="username"
        :totalSavings="totalSavingsFloat"
        :currency="currencyCode" />
      <div v-else class="corporate-gap-when-hero-header-is-missing"/>
      <div class="demand-deposit__content">
        <div class="demand-deposit__content__cards"
            :class="{
              company: isCompany,
              empty: showEmptyCards
            }"
        >
          <div v-if="demandDeposit">
            <bb-card-button-group :buttons="getCardButtons" class="demand-deposit__content__buttons" />
          </div>
          <horizontal-actions-menu
            v-if="showActionsMenu"
            class="demand-deposit__content__menu"
            v-bind="{ tabRoutes }"
          />
          <corporate-clarification-banner
            v-if="demandDeposit && isCompany"
            product="deposit"
            class="m-t-35"
          />
          <section class="demand-deposit__withdrawal" v-if="isWithdrawalsInProcessExists" >
            <div class="demand-deposit__title">
              {{ translations.withdrawalsInProcess }}
            </div>
              <outpayment-cancellation-deposit-card
                class="m-t-10"
                v-for="(outpayment) in reservations"
                :key="outpayment.id"
                :outpayment=outpayment />
          </section>
          <div v-if="demandDeposit && !isClosedView" class="demand-deposit__title">
            {{ translations.demandDeposit }}
          </div>
          <deposit-card
            v-for="deposit in visibleDeposits"
            :key="deposit.id"
            :deposit="deposit"
          />
          <offer-card v-if="isOfferCardVisible" :card="offer"/>
          <demand-deposit-empty-state-card v-else-if="isNewDepositOfferCardVisible" class="m-y-15" />
        </div>
        <campaign-card :campaigns="cardList" />
        <box-with-icon
          v-if="activeDemandDeposits.length < 1 && isCompany"
          icon="client-empty-cards">
          <span v-sanitize.basic="translations.emptyViewCorporateMessage" />
          </box-with-icon>
        <transactions
          :deposit="defaultDemandDeposit"
          :show-reserved-transactions="isCompany"
          :include-reserved-transactions="!isCompany"
        />
      </div>
    </div>
    <router-view />
    <campaign-modal
      :visible="popupCampaignsVisible"
      :campaigns="popupCampaigns"
      @close="closeCampaignModal"
    />
  </layout-with-menu>
</template>

<script>
import HeroHeader from '../components/HeroHeader'
import DepositCard from '../components/DepositCard'
import Transactions from '../components/Transactions'
import FlowMixin from '../mixins/flowMixin'
import CampaignModalMixin from '../../../mixins/campaignModalMixin'
import CampaignCardsMixin from '../../../mixins/campaignCardsMixin'
import CampaignModal from '@/components/CampaignModal'
import CampaignCard from '@/components/cards/CampaignCard'
import OfferCard from '@/components/cards/OfferCard'
import { DEMAND_DEPOSIT_DASHBOARD } from '@/TrackingActions'
import DemandDepositEmptyStateCard from '@/modules/deposits/components/DemandDepositEmptyStateCard'
import '@bigbank/interface-components/dist/svg/ui/arrow-up'
import '@bigbank/interface-components/dist/svg/ui/export'
import '@bigbank/interface-components/dist/svg/ui/file-document-box-outline'
import { DepositType, DepositRouteName } from '@deposits/const'
import BoxWithIcon from '@/components/BoxWithIcon.vue'
import CorporateClarificationBanner from '../../../components/corporate/CorporateClarificationBanner'
import { useRootStore } from '../../../store/root'
import { mapState, mapActions } from 'pinia'
import { useDepositStore } from '@deposits/store/depositStore'
import { DepositStatusCode } from '@bigbank/dc-common/clients/http/account/account.enums'
import { api } from '@deposits/api'
import { isEmpty } from 'lodash'
import HorizontalActionsMenu from '../components/HorizontalActionsMenu'
import OutpaymentCancellationDepositCard
  from '@deposits/components/OutpaymentCancellation/OutpaymentCancellationDepositCard.vue'

const TabNames = Object.freeze({
  active: 'active',
  closed: 'closed'
})

export default {
  name: 'deposits-view',
  components: {
    BoxWithIcon,
    HeroHeader,
    DepositCard,
    OfferCard,
    Transactions,
    CampaignModal,
    CampaignCard,
    DemandDepositEmptyStateCard,
    CorporateClarificationBanner,
    HorizontalActionsMenu,
    OutpaymentCancellationDepositCard
  },
  mixins: [
    FlowMixin,
    CampaignModalMixin,
    CampaignCardsMixin
  ],
  async created () {
    await this.fetchDeposits(this.deposits && this.deposits.length > 0)
    await this.fetchReserved()
    window.eventBus.$on('demand-deposit__withdraw', this.refresh)
  },
  beforeDestroy () {
    window.eventBus.$off('demand-deposit__withdraw', this.refresh)
  },
  mounted () {
    this.activeTab = this.$route.params.status || TabNames.active
  },
  data () {
    return {
      reservations: [],
      activeTab: TabNames.active
    }
  },
  watch: {
    $route (to) {
      this.activeTab = to.params.status || TabNames.active
    }
  },
  computed: {
    ...mapState(useRootStore, [
      'username',
      'isCompany',
      'currency',
      'locale'
    ]),
    ...mapState(useRootStore, {
      rootFeatureFlags: 'featureFlags'
    }),
    ...mapState(useDepositStore, [
      'loading',
      'featureFlags',
      'deposits',
      'demandDeposit',
      'defaultDemandDeposit',
      'depositsByProductAndCategory',
      'activeDepositsByProduct',
      'activeDepositCountByProduct'
    ]),
    translations () {
      return {
        emptyViewCorporateMessage: this.$pgettext('deposit_dashboard', 'Message that will tell you that you don\'t have anything to show.'),
        offer: {
          description: this.$pgettext('offer_card_demand_deposit', 'You don\'t have any active demand deposits, but please check out our offers.'),
          corporateDescription: this.$pgettext('corporate_offer_card_demand_deposit', 'You don\'t have any active demand deposits, but please check out our offers.'),
          title: this.$pgettext('offer_card_demand_deposit', 'Great to have you back.'),
          buttonText: this.$pgettext('offer_card_demand_deposit', 'Check Our Offers')
        },
        withdrawalsInProcess: this.isCompany
          ? this.$pgettext('cancel_SD_reservation_corporate', 'Withdrawals in process')
          : this.$pgettext('cancel_SD_reservation_private', 'Withdrawals in process'),
        demandDeposit: this.isCompany
          ? this.$pgettext('cancel_SD_reservation_corporate', 'Saving deposit')
          : this.$pgettext('cancel_SD_reservation_private', 'Saving deposit')
      }
    },
    showEmptyCards () {
      return this.activeDemandDeposits.length <= 0 && !this.isOfferCardVisible && !this.isNewDepositOfferCardVisible && !this.isClosedView
    },
    activeDemandDeposits () {
      return this.depositsByProductAndCategory[DepositType.Demand].active
    },
    totalSavingsFloat () {
      return this.activeDepositsByProduct[DepositType.Demand].reduce((total, deposit) => deposit.availableBalance + total, 0)
    },
    state () {
      return this.activeDepositCountByProduct[DepositType.Demand] > 0 ? 'activeContracts' : 'noActiveContracts'
    },
    depositState () {
      return this.demandDeposit?.state
    },
    currencyCode () {
      return (this.deposits.length && this.deposits[0].currencyCode) || this.currency
    },
    isOfferCardVisible () {
      if (this.isCompany || this.isClosedView) {
        return false
      }

      return this.activeDemandDeposits.length <= 0 && !this.isClosed
    },
    isNewDepositOfferCardVisible () {
      if (this.isClosedView) {
        return false
      }

      const demandDepositsByStatus = this.depositsByProductAndCategory[DepositType.Demand]

      return demandDepositsByStatus.active.length === 0 &&
        demandDepositsByStatus.closed.length > 0
    },
    isHeroHeaderVisible () {
      return !this.isCompany
    },
    offer () {
      let description = this.translations.offer.description
      if (this.isCompany) {
        description = this.translations.offer.corporateDescription
      }

      return {
        description,
        title: this.translations.offer.title,
        buttonText: this.translations.offer.buttonText,
        to: { name: DepositRouteName.New }
      }
    },
    showAddButton () {
      return (!this.loading && (!this.demandDeposit || this.isClosed))
    },
    newDeposit () {
      if (this.showAddButton) {
        return this.onNewDeposit
      }

      return false
    },
    isActiveOrWaitingFunds () {
      return [DepositStatusCode.Active, DepositStatusCode.WaitingFunds].includes(this.depositState)
    },
    showWithdrawButton () {
      return [DepositStatusCode.Active].includes(this.depositState) && this.totalSavingsFloat > 0
    },
    showAccountStatementDownloadButton () {
      return this.featureFlags.canDownloadDepositAccountStatement && [DepositStatusCode.Active].includes(this.depositState)
    },
    isClosed () {
      return [
        DepositStatusCode.Terminated,
        DepositStatusCode.Cancelled,
        DepositStatusCode.Rejected,
        DepositStatusCode.Closed
      ].includes(this.depositState)
    },
    getCardButtons () {
      return [
        {
          id: 'top-up',
          label: this.$pgettext('demand_deposit_button', 'Top Up'),
          icon: 'ui-arrow-up',
          to: '/my-deposits/demand/top-up',
          disabled: !this.isActiveOrWaitingFunds
        },
        {
          id: 'withdraw',
          label: this.$pgettext('demand_deposit_button', 'Withdraw'),
          icon: 'ui-export',
          to: '/my-deposits/demand/withdraw',
          disabled: !this.showWithdrawButton
        },
        {
          id: 'account-statement',
          label: this.$pgettext('demand_deposit_button', 'Account Statement'),
          icon: 'ui-file-document-box-outline',
          to: '/my-deposits/demand/statement',
          disabled: !this.showAccountStatementDownloadButton,
          visible: !this.rootFeatureFlags.enableSavingsDepositProductPageUpdate
        }
      ].filter(({ visible }) => visible !== false)
    },
    cardList () {
      if (this.campaignsForCards[0] && Array.isArray(this.campaignsForCards[0].campaigns)) {
        return [...this.campaignsForCards[0].campaigns]
      }

      return []
    },
    isWithdrawalsInProcessExists () {
      return !isEmpty(this.reservations) && this.activeDemandDeposits.length > 0
    },
    isActiveView () {
      return this.activeTab === TabNames.active
    },
    isClosedView () {
      return this.activeTab === TabNames.closed
    },
    visibleDeposits () {
      return this.depositsByProductAndCategory[DepositType.Demand][this.activeTab]
    },
    tabRoutes () {
      return [
        {
          tab: TabNames.active,
          title: this.$pgettext('saving_deposit_product_page', 'My deposit'),
          active: this.isActiveView,
          path: { name: DepositRouteName.DemandDeposits, params: { status: null } }
        },
        {
          tab: TabNames.closed,
          title: this.$pgettext('saving_deposit_product_page', 'Closed contracts'),
          active: this.isClosedView,
          path: { name: DepositRouteName.DemandDeposits, params: { status: TabNames.closed } }
        }
      ]
    },
    showActionsMenu () {
      return this.rootFeatureFlags.enableSavingsDepositProductPageUpdate
    }
  },
  methods: {
    ...mapActions(useDepositStore, ['fetchDeposits']),
    onNewDeposit () {
      this.startApplicationFlow()
    },
    async refresh () {
      await this.fetchDeposits()
      await this.fetchReserved()
    },
    async fetchReserved () {
      if (this.demandDeposit && this.featureFlags.canCancelReservedDepositOutpayment) {
        this.reservations = await api.getSavingDepoistReservations(this.demandDeposit?.currentAccountId, this.currencyCode)
      }
    },
    trackViewActions () {
      if (!this.hasRevolvingLoan) {
        this.$tracker.action(DEMAND_DEPOSIT_DASHBOARD.DEMAND_EMPTY_VIEW)
      }
    },
    cardButtonClick (event) {
      switch (event) {
        case 'withdraw':
          this.$tracker.action(
            DEMAND_DEPOSIT_DASHBOARD.DEMAND_CLICK_WITHDRAW,
            {}, { contractId: this.demandDeposit?.id.toString() }
          )
          break
        case 'top-up':
          this.$tracker.action(
            DEMAND_DEPOSIT_DASHBOARD.DEMAND_CLICK_TOP_UP,
            {}, { contractId: this.demandDeposit?.id.toString() }
          )
          break
      }
    }
  }
}
</script>

<style lang="scss">
.demand-deposit {
  &__content {
    margin: auto;
    width: 90%;
    max-width: 850px;

    &__menu {
      @media (min-width: $desktop-view-breaking-point) {
        margin-top: 40px;
        margin-bottom: 40px;
      }
    }

    &__cards {
      margin-top: -45px;

      &.empty {
        margin-top: 30px;
      }

      &.company {
        margin-top: 20px;
      }
    }

    &__buttons {
      margin-bottom: 10px;

      @media (min-width: $desktop-view-breaking-point) {
        margin-bottom: 10px;
      }
    }
  }

  &__title {
    color: $navy;
    font-family: $gotham-medium;
    font-size: $h3-size;
    text-align: left;
    margin-bottom: 30px;
    margin-top: 25px;

    @media (max-width: $mobile-view-breaking-point) {
      font-size: $h3-size-mobile;
      margin-bottom: 15px;
      margin-top: 25px;
    }
  }
}
</style>
