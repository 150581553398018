<template>
  <div>
    <bb-input
      v-if="type === BankAccountInputType.PRINCIPAL"
      v-validate="'required|clearingNumber'"
      name="clearingNumber"
      :label="translations.clearingNumberLabel"
      :data-vv-as="translations.clearingNumberLabel"
      v-model="clearingNumber"
      :framed="framed"
      @input="update"
    />
    <bb-select
      v-if="showDropdown && !hasInternalAccounts"
      v-validate="validationRules"
      name="bankAccountNumberDropdown"
      :label="label"
      :data-vv-as="fieldLabel"
      :inputOther="showAddNewAccount"
      :inputOtherLabel="translations.newAccount"
      :inputOtherPlaceholder="translations.newAccountHelp"
      :options="externalAccountsOptions"
      v-model="accountNumber"
      :framed="framed"
      :helpActive="tooltip.enabled"
      :helpText="tooltip.text"
      noempty
    ></bb-select>
    <bb-select
      v-else-if="showDropdown && hasInternalAccounts"
      v-validate="validationRules"
      name="bankAccountNumberDropdown"
      :label="label"
      :data-vv-as="fieldLabel"
      :inputOther="showAddNewAccount"
      :inputOtherLabel="translations.newAccount"
      :inputOtherPlaceholder="translations.newAccountHelp"
      v-model="accountNumber"
      :helpActive="tooltip.enabled"
      :helpText="tooltip.text"
      :framed="framed"
      noempty
    >
      <template v-for="option in internalAccountsOptions">
        <optgroup v-if="option.options" :key="option.text" :label="option.text">
          <option v-for="suboption in option.options" :key="suboption.text" :value="suboption.value">
            {{suboption.text}}
          </option>
        </optgroup>
      </template>
    </bb-select>
    <bb-input
      v-else
      v-validate="validationRules"
      name="bankAccountNumber"
      :label="label"
      :data-vv-as="fieldLabel"
      v-model="accountNumber"
      :framed="framed"
    />
  </div>
</template>

<script>
import iban from 'iban'
import { validateAccount } from '@bigbank/dc-common/validators/se/accountNumber'
import { BankAccountInputType } from '../../types/bankAccountInputType'
import { mapState } from 'pinia'
import { useRootStore } from '../../store/root'
import { isNull } from 'lodash'

export default {
  name: 'bank-account-input',
  inject: ['$validator'],
  props: {
    type: {
      required: true,
      validator: value => {
        return [
          BankAccountInputType.PRINCIPAL,
          BankAccountInputType.IBAN,
          BankAccountInputType.SELECT
        ].includes(value)
      }
    },
    value: {
      required: false
    },
    framed: {
      required: false,
      default: false
    },
    showAddNewAccount: {
      required: false,
      default: true
    },
    showToAnotherAccount: {
      required: false,
      default: false
    },
    validatedAccounts: {
      required: false,
      default: () => []
    },
    internalAccounts: {
      required: false,
      default: () => []
    },
    permittedIbans: {
      required: false,
      default: () => []
    },
    validateIbanCountry: {
      required: false,
      default: true
    },
    accountNumberFormatter: {
      type: Function,
      required: false,
      default: account => account.accountNumber
    },
    autoFill: {
      type: Boolean,
      required: false,
      default: false
    },
    autoFillHint: {
      type: String,
      required: false,
      default: null
    },
    texts: {
      type: Object,
      required: false,
      default: () => ({})
    },
    flavor: {
      type: String,
      required: true,
      validator: value => {
        return [
          'deposit',
          'revolving'
        ].includes(value)
      }
    }
  },
  data () {
    return {
      clearingNumber: '',
      accountNumber: '',
      BankAccountInputType
    }
  },
  watch: {
    value (newValue) {
      this.handleValue(newValue)
    },
    accountNumber (newValue) {
      const account = this.allAccounts.find(({ accountNumber }) => accountNumber === newValue)
      if (this.type === BankAccountInputType.PRINCIPAL && account) {
        this.clearingNumber = account.bankCode
      }
      this.update()
    },
    async allAccounts () {
      this.runAutoFill()
    },
    clearingNumber () {
      const accountNumberField = this.showDropdown ? 'bankAccountNumberDropdown' : 'bankAccountNumber'
      if (this.accountNumber) this.$validator.validate(accountNumberField)
    }
  },
  computed: {
    ...mapState(useRootStore, ['locale', 'isChannelSE']),
    translations () {
      return {
        newAccount: this.$pgettext('accountNumber', 'New account'),
        newAccountHelp: this.$pgettext('accountNumber', 'Please enter new account number'),
        otherAccount: this.$pgettext('accountNumber', 'To another IBAN'),
        ibanLabel: this.$gettext('IBAN'),
        accountLabel: this.$gettext('Bank account'),
        accountAndClearingLabel: this.$gettext('Account and clearing number'),
        ibanFieldLabel: this.$gettext('IBAN'),
        ibanError: this.$gettext('Insert correct IBAN'),
        ibanCountryError: this.$gettext('Please enter a local IBAN'),
        ibanTooltip: this.$pgettext('IBAN_tooltip', 'This is your personal bank account to which you wish to receive deposit outpayments'),
        principalAccountLabel: this.$gettext('Bank Account'),
        principalAccountError: this.$gettext('Insert correct bank account'),
        clearingNumberLabel: this.$gettext('Clearing Number'),
        clearingNumberError: this.$gettext('Insert correct clearing number'),
        internalAccounts: this.$pgettext('account_selection', 'Internal accounts'),
        externalAccounts: this.$pgettext('account_selection', 'External accounts'),
        ...this.texts
      }
    },
    internalAccountsList () {
      if (!this.internalAccounts) {
        return []
      }

      return this.internalAccounts.flatMap(group => group.options)
    },
    allAccounts () {
      return [
        ...(isNull(this.validatedAccounts) ? [] : this.validatedAccounts),
        ...(isNull(this.internalAccountsList) ? [] : this.internalAccountsList)
      ]
    },
    hasInternalAccounts () {
      return this.internalAccountsList.length > 0
    },
    externalAccountsOptions () {
      const options = (isNull(this.validatedAccounts) ? [] : this.validatedAccounts)
        .filter(option => !!option)
        .map(option => this.accountToOptionsMapper(option))

      if (this.showToAnotherAccount) {
        options.push({
          text: this.translations.otherAccount,
          value: 'OTHER'
        })
      }

      return options
    },
    internalAccountsOptions () {
      const accountGroups = []

      if (this.externalAccountsOptions.length) {
        accountGroups.push({
          text: this.translations.externalAccounts,
          options: this.externalAccountsOptions
        })
      }

      if (this.internalAccountsList.length) {
        this.internalAccounts.forEach(group => {
          accountGroups.push({
            text: group.text || this.translations.internalAccounts,
            options: group.options.map(option => this.accountToOptionsMapper(option))
          })
        })
      }

      return accountGroups
    },
    showDropdown () {
      if (this.isChannelSE) {
        return this.flavor === 'revolving'
      }

      return (this.externalAccountsOptions && this.externalAccountsOptions.length > 0) ||
        (this.internalAccountsOptions && this.internalAccountsOptions.length > 0) ||
        this.type === BankAccountInputType.SELECT
    },
    validationRules () {
      return {
        required: true,
        iban: this.type === BankAccountInputType.IBAN,
        ibanCountry: this.type === BankAccountInputType.IBAN,
        principalAccount: this.type === BankAccountInputType.PRINCIPAL
      }
    },
    label () {
      if (this.flavor === 'revolving') return this.translations.accountAndClearingLabel

      switch (this.type) {
        case BankAccountInputType.PRINCIPAL:
          return this.translations.principalAccountLabel
        case BankAccountInputType.SELECT:
          return this.translations.accountLabel
        default:
          return this.translations.ibanLabel
      }
    },
    fieldLabel () {
      switch (this.type) {
        case BankAccountInputType.PRINCIPAL:
          return this.translations.principalAccountLabel
        default:
          return this.translations.ibanFieldLabel
      }
    },
    optionsInputOtherFix () {
      if (!this.showAddNewAccount) {
        return undefined
      } else {
        return [{}]
      }
    },
    tooltip () {
      switch (this.type) {
        case BankAccountInputType.PRINCIPAL:
          return {
            enabled: false,
            text: undefined
          }
        default:
          return {
            enabled: true,
            text: this.translations.ibanTooltip
          }
      }
    }
  },
  methods: {
    accountToOptionsMapper (account) {
      return {
        text: this.accountNumberFormatter(account),
        value: account.accountNumber
      }
    },
    isValid (accountNumber, bankCode) {
      return !!this.allAccounts
        .find(account => account.accountNumber === accountNumber && (!bankCode || bankCode === account.bankCode))
    },
    update () {
      switch (this.type) {
        case BankAccountInputType.PRINCIPAL:
          this.$emit('input', {
            principalAccount: this.accountNumber,
            clearingNumber: this.clearingNumber
          })
          break
        default:
          this.$emit('input', this.accountNumber)
      }
    },
    handleValue (newValue) {
      switch (this.type) {
        case BankAccountInputType.PRINCIPAL:
          this.accountNumber = newValue.principalAccount
          this.clearingNumber = newValue.clearingNumber
          break
        default:
          this.accountNumber = newValue
      }
    },
    async runAutoFill () {
      if (this.autoFill && Array.isArray(this.allAccounts) && this.allAccounts[0] && !this.accountNumber) {
        await this.$nextTick()

        if (this.autoFillHint && this.isValid(this.autoFillHint)) {
          this.accountNumber = this.autoFillHint
        } else {
          this.accountNumber = this.allAccounts[0].accountNumber
        }
      }
    }
  },
  mounted () {
    this.runAutoFill()
  },
  async created () {
    this.handleValue(this.value)
    this.$validator.extend('iban', {
      getMessage: () => this.translations.ibanError,
      validate: value => {
        if (this.showToAnotherAccount && value === 'OTHER') {
          return true
        }
        return this.isValid(value) || (iban.isValid(value) && !!value.match(/^[0-9A-Z ]*$/i))
      }
    })

    this.$validator.extend('ibanCountry', {
      getMessage: () => this.translations.ibanCountryError,
      validate: value => {
        if (this.showToAnotherAccount && value === 'OTHER') {
          return true
        }
        const ibanCountryCode = value.trim().substr(0, 2).toUpperCase()

        return this.isValid(value) || (
          !this.validateIbanCountry ||
          this.permittedIbans.reduce((valid, countryCode) => valid || countryCode === ibanCountryCode, false)
        )
      }
    })

    this.$validator.extend('principalAccount', {
      getMessage: () => this.translations.principalAccountError,
      validate: value => {
        return this.isValid(value, this.clearingNumber) || validateAccount(this.clearingNumber, value)
      }
    })

    this.$validator.extend('clearingNumber', {
      getMessage: () => this.translations.clearingNumberError,
      validate: value => {
        return /^(8?\d{4}|8\d{3}-\d)$/.test(value)
      }
    })
  }
}
</script>
