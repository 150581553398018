function isAbsoluteUrl (url) {
  return typeof url === 'string' && (url.startsWith('http://') || url.startsWith('https://'))
}

function getUrlWithQueryParams (url, params) {
  return url + objectToURLSearchParams(params)
}

function objectToURLSearchParams (params) {
  let searchString = '?'

  for (const [key, value] of Object.entries(params)) {
    searchString += key + '=' + value + '&'
  }

  return searchString
}

export {
  isAbsoluteUrl,
  getUrlWithQueryParams
}
