<template>
  <layout :loading="loading">
    <bb-container fluid v-if="!loading">
      <bb-row no-gutters v-if="featureFlags.enableDocumentsTab">
        <bb-col md="12" lg="11">
          <section-box :title="translations.documents" icon="client-upload">
            <translate>Your valid documents.</translate>
          </section-box>
        </bb-col>
        <bb-col md="12" lg="13" class="documents">
          <div v-if="showIdDocumentMissingBlock">
            <bb-input
              name="documents-missing"
              type="text"
              :label="translations.documentMissing"
              :value="translations.documentMissingText"
              readonly
            />
            <div class="comment m-t-15">
              <bb-icon
                class="comment__icon"
                name="ui-alert-circle-outline"
                size="24"
                fill="yellow"
              />
              <span class="m-l-10">{{ translations.pleaseUpload }}</span>
            </div>
          </div>
          <document-list :documents="documents" />
          <bb-button
            class="m-t-30"
            color="mint"
            :label="translations.uploadIdDocument"
            display="block"
            @click="onUploadIdDocumentClick"
          />
          <company-documents-block v-if="isCompany" />
        </bb-col>
      </bb-row>
      <bb-row no-gutters v-if="featureFlags.enableAdditionalDocumentUpload">
        <bb-col md="12" lg="11">
          <section-box :title="translations.additionalDocuments" icon="client-upload">
            <translate translate-context="private-documents">Additional documents.</translate>
          </section-box>
        </bb-col>
        <bb-col md="12" lg="13" class="documents">
          <private-documents-block />
        </bb-col>
      </bb-row>
    </bb-container>
  </layout>
</template>

<script>
import Layout from './Layout'
import SectionBox from '@account/components/SectionBox'
import DocumentList from '@account/components/DocumentList'

import '@bigbank/interface-components/dist/svg/client/upload'
import api from '../api'
import { AccountRouteName } from '../const'
import CompanyDocumentsBlock from '../components/CompanyDocumentsBlock.vue'
import { mapState } from 'pinia'
import PrivateDocumentsBlock from '@account/components/PrivateDocumentsBlock.vue'
import { useRootStore } from '../../../store/root'

export default {
  name: 'account-documents',
  components: { PrivateDocumentsBlock, Layout, SectionBox, DocumentList, CompanyDocumentsBlock },
  data () {
    return {
      loading: false,
      documents: null,
      translations: {
        documents: this.$pgettext('section_title', 'Documents'),
        documentMissing: this.$pgettext('documents_id_document_missing', 'Document Status'),
        documentMissingText: this.$pgettext('documents_id_document_missing_text', 'Document is missing'),
        pleaseUpload: this.$pgettext('documents_id_document_missing', 'Please upload a missing ID document.'),
        uploadIdDocument: this.$pgettext('documents', 'Upload ID document'),
        additionalDocuments: this.$pgettext('section_title', 'Documents')
      }
    }
  },
  computed: {
    ...mapState(useRootStore, ['isCompany', 'featureFlags']),
    showIdDocumentMissingBlock () {
      return Array.isArray(this.documents) && this.documents.length === 0
    }
  },
  methods: {
    onUploadIdDocumentClick () {
      this.$router.push({ name: AccountRouteName.DocumentsUpload })
    }
  },
  async mounted () {
    if (this.featureFlags.enableDocumentsTab) {
      this.loading = true
      this.documents = await api.getIdentificationDocuments()
      this.loading = false
    }
  }
}
</script>
