<template>
  <layout v-bind="layoutProps" v-on="$listeners">
    <bb-detailed-radio
      v-validate="'required'"
      v-model="selectedOption"
      name="manageMyLoanOption"
      :options="options"
    />
  </layout>
</template>

<script>
import Layout from './Layout.vue'
import { mapState } from 'pinia'
import { useLoanStore } from '@loan/store/loanStore'

export default {
  components: {
    Layout
  },
  name: 'manage-loan-initial-step',
  data () {
    return {
      selectedOption: undefined
    }
  },
  computed: {
    ...mapState(useLoanStore, ['featureFlags']),
    translations () {
      return {
        title: this.$pgettext('manage_loan', 'Manage my Loan'),
        text: this.$pgettext('manage_loan', 'Choose a suitable contract change and continue'),
        buttonText: this.$pgettext('manage_loan', 'Continue')
      }
    },
    options () {
      return [{
        isVisible: this.featureFlags.enableManageMyLoanChangePaymentDay,
        value: 'change-payment-day',
        leftColumn: {
          label: this.$pgettext('manage_loan', 'Change payment day')
        },
        priority: 3
      }, {
        isVisible: this.featureFlags.enableManageMyLoanGracePeriod,
        value: 'grace-period',
        leftColumn: {
          label: this.$pgettext('manage_loan', 'Grace period')
        },
        priority: 7
      }, {
        isVisible: this.featureFlags.enableManageMyLoanPrematureFulfillment,
        value: 'premature-fulfillment',
        leftColumn: {
          label: this.$pgettext('manage_loan', 'Premature fulfillment')
        },
        priority: 2
      }, {
        isVisible: this.featureFlags.enableManageMyLoanProlongation,
        value: 'prolongation',
        leftColumn: {
          label: this.$pgettext('manage_loan', 'Prolongation')
        },
        priority: 4
      }, {
        isVisible: this.featureFlags.enableManageMyLoanShortening,
        value: 'shortening',
        leftColumn: {
          label: this.$pgettext('manage_loan', 'Shortening')
        },
        priority: 5
      }, {
        isVisible: this.featureFlags.enableManageMyLoanRestructuring,
        value: 'restructuring',
        leftColumn: {
          label: this.$pgettext('manage_loan', 'Restructuring')
        },
        priority: 6
      }, {
        isVisible: this.featureFlags.enableManageMyLoanPartialFulfillment,
        value: 'partial-premature-fulfillment',
        leftColumn: {
          label: this.$pgettext('manage_loan', 'Partial premature fulfillment')
        },
        priority: 1
      }]
        .sort((a, b) => a.priority - b.priority)
        .filter(option => option.isVisible)
    },
    layoutProps () {
      return {
        visible: true,
        title: this.translations.title,
        text: this.translations.text,
        buttonText: this.translations.buttonText,
        data: {
          selectedManageLoanOption: this.selectedOption
        }
      }
    }
  }
}
</script>

<style lang="scss">
.manage-loan {
  .bb-detailed-radio-item__main-content {
    min-height: 2.5rem;
  }
}
</style>
