import api from '../../modules/account/api'
import { useRootStore } from '../root'

const campaignAdTypes = {
  INLINE: 'inline',
  POPUP: 'pop_up'
}

const campaignTypes = {
  UPSELL: 'upsell'
}

const state = () => ({
  campaigns: [],
  campaignAdTypes,
  campaignTypes
})

const actions = {
  async getCampaigns ({ commit }) {
    const rootStore = useRootStore()
    if (rootStore.featureFlags.enableCampaigns && !rootStore.isCompany) {
      let campaigns = await api.getCampaigns()
      if (!Array.isArray(campaigns)) {
        campaigns = campaigns ? [campaigns] : []
      }
      commit('setCampaigns', campaigns)
    }
  }
}

const mutations = {
  setCampaigns (state, campaigns) {
    state.campaigns = campaigns
  }
}

export const campaign = {
  namespaced: true,
  state,
  actions,
  mutations
}
