<template>
  <bb-modal visible flavor="card" fullMobile class="otp-modal" publicSize="m" @close="handleClose">
    <div class="otp-modal__info m-b-30">
      <h3 class="otp-modal__title m-b-10">
        <translate translate-context="otp_modal">Confirm</translate>
      </h3>
      <p>{{mobileNumberMessage}}</p>
    </div>
    <bb-input
      name="otp"
      :label="translations.otpCodeLabel"
      :data-vv-as="translations.otpCodeLabel"
      type="number"
      pattern="[0-9]*"
      @input="handleOtpInputChange"
      :value="otp"
      :maxlength="6"/>
    <div class="otp-modal__error" v-if="otpFailed">
      <p><error-message :code="otpErrorCode" type="customer-id"/></p>
    </div>
    <bb-ticket-stub v-if="showButton" slot="footer" class="m-t-15">
      <bb-button :color="buttonColor" :loading="showLoader" display="ticket">{{buttonLabel}}</bb-button>
    </bb-ticket-stub>
  </bb-modal>
</template>

<script>
import ErrorMessage from './ErrorMessage'

export default {
  name: 'otp-modal',
  components: { ErrorMessage },
  data () {
    return {
      otp: null,
      translations: {
        loading: this.$pgettext('otp_modal', 'Loading...'),
        otpCodeLabel: this.$pgettext('otp_modal', 'Enter code from SMS')
      }
    }
  },
  methods: {
    handleOtpInputChange (value) {
      this.otp = value
      if (this.otp.length === 6) {
        this.$emit('otpSubmission', this.otp)
      }
    },
    handleClose ({ eventSource }) {
      if (eventSource !== 'click-outside') {
        this.$emit('close')
      }
    }
  },
  mounted () {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
  },
  computed: {
    mobileNumberMessage () {
      return this.$pgettext('otp_modal', 'We\'ve sent a 6-digit verification code to {maskedMobileNumber}')
        .replace(/{maskedMobileNumber}/, this.maskedRecipient)
    },
    showButton () {
      return this.showLoader
    },
    showLoader () {
      return this.isLoading
    },
    buttonColor () {
      if (!this.isLoading) return 'green'
      return 'gray'
    },
    buttonLabel () {
      if (!this.isLoading) {
        return null
      } else {
        return this.translations.loading
      }
    }
  },
  props: {
    otpFailed: {
      type: Boolean,
      required: true
    },
    otpErrorCode: {
      type: String,
      required: false
    },
    maskedRecipient: {
      type: String,
      default: ''
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
.otp-modal {
  &__info {
    text-align: center;
    color: $gray-70;
    font-family: $gotham-medium;
    font-size: $font-size-small;
  }

  &__title {
    font-family: $max-pro-demi-bold;
    font-size: $h4-size;
    color: $navy;
  }

  &__error {
    margin-top: 25px;
    text-align: center;
    font-family: $gotham-medium;
    font-size: $font-size-smallest;
    color: $red;
  }
}
</style>
