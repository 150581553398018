<template>
  <pay-back v-bind="payBackProps">
    <div slot="payment-description" class="m-t-30 product-info uppercase color-gray">
      {{ translations.paymentDetails }}
    </div>
    <div slot="payment-details" v-if="!hasOutstandingFunds">
      <bb-box thin class="payment__details-box m-t-25">
        <span class="payment__not-generated-text">{{ translations.invoiceNotGeneratedText }}</span>
      </bb-box>
    </div>
  </pay-back>
</template>

<script>
import PayBack from '@loan/components/PayBack'
import { REVOLVING_DASHBOARD } from '@/TrackingActions'
import payBackModalMixin from '@/mixins/payBackModalMixin'
import { mapState } from 'pinia'
import { useLoanStore } from '@loan/store/loanStore'

export default {
  name: 'revolving-loan_pay-back',
  components: {
    PayBack
  },
  mixins: [payBackModalMixin],
  data () {
    return {
      isLoading: false,
      invoiceDetails: null,
      repaymentDetails: null,
      nextPaymentDetails: null,
      hasCopied: false
    }
  },
  computed: {
    ...mapState(useLoanStore, ['revolvingLoan']),
    contract () {
      return this.revolvingLoan
    },
    payBackProps () {
      return {
        isLoading: this.isLoading,
        contract: this.contract,
        onClose: this.onClose,
        translations: this.translations,
        trackingAction: REVOLVING_DASHBOARD.REVOLVING_VIEW_PAY_BACK_MODAL,
        invoiceDetails: this.invoiceDetails,
        repaymentDetails: this.repaymentDetails,
        nextPaymentDetails: this.nextPaymentDetails
      }
    },
    translations () {
      return {
        invoiceNotGeneratedText: this.$gettext('Once Your invoice is generated, payment information will be available here'),
        paymentDetails: this.$gettext('Payment Details:')
      }
    }
  },
  async mounted () {
    await this.loadData()
  }
}
</script>

<style lang="scss">
.product-info {
  font-family: $gotham-medium;
  text-align: center;
  font-size: $font-size-smallest;
  user-select: none;
  text-transform: uppercase;

  @media (max-width: $mobile-view-breaking-point) {
    font-size: $mobile-small-font-size;
  }
}

.payment {
  &__not-generated-text {
    font-size: $mobile-small-font-size;

    @media (min-width: $mobile-view-breaking-point + 1) {
      font-size: $font-size-smallest;
    }
  }

  &__details-box {
    text-align: center;
  }
}
</style>
