import { featureRouteGuard } from '@/plugins/routeGuards'
import CreditCardView from '@credit-card/views/CreditCardView.vue'
import CreditCardPayBack from '@credit-card/views/PayBack.vue'
import CreditCardTransactionDetailsModal from '@credit-card/views/CreditCardTransactionDetailsModal.vue'
import CardDetailsView from '@credit-card/views/CardDetailsView.vue'
import CardContractDetailsView from '@credit-card/views/CardContractDetailsView.vue'
import { CreditCardRouteName } from './const'

export default [
  {
    path: '',
    name: CreditCardRouteName.CreditCard,
    component: CreditCardView,
    children: [
      {
        path: 'pay-back',
        name: 'credit-card-loan_pay-back',
        component: CreditCardPayBack,
        props: { onCloseRedirectUrl: CreditCardRouteName.CreditCard }
      },
      {
        path: 'transactions/:transactionId',
        name: 'credit-card-transaction-details-modal',
        component: CreditCardTransactionDetailsModal,
        beforeEnter: featureRouteGuard('enableCreditCardTransactionDetailsView', undefined, 'loan')
      }
    ]
  },
  {
    path: 'card-details/:cardId',
    name: 'credit-card-details',
    component: CardDetailsView
  },
  {
    path: ':contractId',
    name: 'credit-card-contract-details',
    component: CardContractDetailsView,
    beforeEnter: featureRouteGuard(['enableCreditCardContractDetails'], undefined, 'loan')
  }
]
