<template>
  <bb-banner class="sign-contract-change" permanent visible :type="type">
    <div>{{description}}</div>
    <bb-button
      v-if="showButton"
      display="narrow"
      :label="translations.reviewContract"
      @click="$emit('startSigning', $event)"
    />
  </bb-banner>
</template>

<script>
export default {
  name: 'sign-contract-change',
  data () {
    return {
      NOTIFICATION_STATUS: Object.freeze({
        STARTED: 'STARTED',
        IN_PROGRESS: 'IN_PROGRESS',
        WAITING_CO_BORROWER: 'WAITING_CO_BORROWER',
        COMPLETED: 'COMPLETED',
        FAILED: 'FAILED'
      })
    }
  },
  props: {
    contract: {
      required: true
    },
    notificationStatus: {
      type: String,
      required: false
    }
  },
  computed: {
    type () {
      if (this.notificationStatus === this.NOTIFICATION_STATUS.FAILED) {
        return 'error'
      } else if ([
        this.NOTIFICATION_STATUS.STARTED,
        this.NOTIFICATION_STATUS.IN_PROGRESS,
        this.NOTIFICATION_STATUS.WAITING_CO_BORROWER
      ].includes(this.notificationStatus)) {
        return 'info'
      }
      return 'success'
    },
    signingInfo () {
      return this.contract.contractChangeSigning
    },
    translations () {
      return {
        reviewContract: this.$gettext('Review Contract'),
        signing: this.$pgettext('contract_change_signing', 'Your contract change is ready to be signed.'),
        allSigned: this.$pgettext('contract_change_signing', 'Your contract change is signed successfully. Please wait until we finalyze new contract.'),
        waitingCoborrower: this.$pgettext('contract_change_signing', 'We are waiting for the co-borrower of the agreement to sign the new contract version, and we will activate the new agreement as soon as both parties have signed.'),
        notificationStatusCode: {
          STARTED: this.$pgettext('signing_notification', 'Signing the contract is in progress. Please wait'),
          IN_PROGRESS: this.$pgettext('signing_notification', 'Signing the contract is in progress. Please wait'),
          WAITING_CO_BORROWER: this.$pgettext('signing_notification', 'We are waiting for the co-borrower of the agreement to sign the new contract version, and we will activate the new agreement as soon as both parties have signed.'),
          COMPLETED: this.$pgettext('signing_notification', 'You have signed the contract change successfully!'),
          FAILED: this.$pgettext('signing_notification', 'Contract change signing failed!')
        }
      }
    },
    showButton () {
      return this.signingInfo && !this.signingInfo.userSigned && !this.notificationStatus
    },
    description () {
      if (this.notificationStatus) {
        return this.translations.notificationStatusCode[this.notificationStatus] ?? ''
      }
      if (this.signingInfo.allSigned) {
        return this.translations.allSigned
      } else if (this.signingInfo.userSigned && !this.signingInfo.allSigned) {
        return this.translations.waitingCoborrower
      } else {
        return this.translations.signing
      }
    }
  }
}
</script>

<style lang="scss">
.sign-contract-change {
  .bb-banner__content > div {
    display: flex;
    width: 100%;
  }

  .bb-banner__body {
    width: 100%;
    color: $gray;
    font-size: $font-size-smallest;

    @media (min-width: $desktop-view-breaking-point) {
      display: flex;
      flex-wrap: nowrap;
      align-content: space-between;
      align-items: center;
    }
  }

  .bb-button {
    width: 100%;
    margin-top: 20px;

    @media (min-width: $desktop-view-breaking-point) {
      margin: 0;
      margin-left: auto;
      max-width: 30%;
    }
  }
}
</style>
