<template>
  <div>
    <bb-application-contract-card
      v-bind="cardProps"
      v-on="events"
      :class="spacerClass"
    />
    <offer-details-modal
      :visible="showOfferDetailsModal"
      @close="showOfferDetailsModal = !showOfferDetailsModal"
      :detailsList="offerDetailsList || {}"
    />
  </div>
</template>

<script>
import { LOAN_DASHBOARD } from '@/TrackingActions'
import { mapState } from 'pinia'
import { useRootStore } from '../../../../store/root'
import OfferDetailsModal from '../OfferDetailsModal'
import { formatMoneyWithCurrency, formatInterest } from '@/plugins/numformat'
import { useLoanStore } from '@loan/store/loanStore'

export default {
  name: 'application-card',
  components: { OfferDetailsModal },
  props: {
    application: {
      type: Object,
      required: true
    },
    index: {
      required: false
    },
    count: {
      required: false
    },
    spacerClass: {
      required: false
    }
  },
  data () {
    return {
      showOfferDetailsModal: false
    }
  },
  computed: {
    ...mapState(useRootStore, ['locale']),
    ...mapState(useLoanStore, ['featureFlags']),
    translations () {
      return {
        source: {
          BIGBANK: this.$pgettext('APPLICATION_SOURCE', 'BIGBANK')
        },
        description: {
          DEFAULT: this.$gettext('Click the button to continue with your application'),
          BIGBANK: this.$pgettext('APPLICATION_SOURCE_BIGBANK', 'Click the button to continue with your application')
        }
      }
    },
    hasOffer () {
      return !!this.application.hasOffer
    },
    canContinue () {
      return this.application.canContinue
    },
    cardProps () {
      let description = this.translations.description.DEFAULT

      if (this.featureFlags.showLoanOfferDetails && this.translations.description[this.application.source]) {
        description = this.translations.description[this.application.source]
      }

      return {
        type: 'application',
        title: this.application.applicationNumber,
        amount: (this.featureFlags.showLoanOfferDetails && this.sourceFormatted) || undefined,
        subtitle: this.hasOffer
          ? this.$gettext('You have pending application with us')
          : this.$gettext('Finish your application to get a personal offer'),
        description,
        primaryButton: {
          label: this.$gettext('Continue Application'),
          disabled: !this.canContinue
        },
        secondaryButton: this.featureFlags.showLoanOfferDetails && this.hasOffer ? { label: this.$gettext('Offer Details') } : {},
        primaryButtonTooltip: this.$pgettext('APPLICATION_CARD', 'Currently you can\'t continue with the application as we are processing it.')
      }
    },
    events () {
      return {
        primaryButtonClick: this.toApplication,
        secondaryButtonClick: this.showOfferDetails
      }
    },
    amountFormatted () {
      return formatMoneyWithCurrency(this.application.amount, this.application.currency, this.locale)
    },
    sourceFormatted () {
      return this.translations.source[this.application.source] || this.application.source
    },
    offerDetailsList () {
      return this.hasOffer
        ? [{
            label: this.$gettext('Your loan'),
            content: this.amountFormatted
          }, {
            label: this.$gettext('Monthly payment'),
            content: formatMoneyWithCurrency(this.application.monthlyPaymentFull, this.application.currency, this.locale)
          }, {
            label: this.$gettext('Amount of period'),
            content: this.application.maturity + ' ' + this.$gettext('mo')
          }, {
            label: this.$gettext('Interest (nominal)'),
            content: formatInterest(this.application.interest) + ' %'
          }, {
            label: this.$gettext('Contract fee'),
            content: formatMoneyWithCurrency(this.application.conclusionFee, this.application.currency, this.locale)
          }, {
            label: this.$gettext('APRC'),
            content: formatInterest(this.application.aprc) + ' %'
          }]
        : null
    }
  },
  methods: {
    toApplication () {
      const { continueUrl } = this.application
      if (continueUrl) {
        window.open(continueUrl, '_blank')
        return
      }
      this.$router.push({
        name: 'application-info',
        params: {
          page: 'application'
        }
      })
    },
    showOfferDetails () {
      this.showOfferDetailsModal = true
    }
  },
  mounted () {
    this.$tracker.action(LOAN_DASHBOARD.VIEW_APPLICATION_CARD)
  }
}
</script>
