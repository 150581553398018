export const depositStates = {
  ACTIVE: 'ACTIVE',
  ACTIVE_PROLONG: 'ACTIVE_PROLONG',
  WAITING_FUNDS: 'WAITING_FUNDS',
  WAITING_SIGNING: 'WAITING_SIGNING',
  REJECTED: 'REJECTED',
  TERMINATED: 'TERMINATED',
  CLOSED: 'CLOSED',
  FORWARDED: 'FORWARDED',
  PROCEEDING: 'PROCEEDING',
  UNKNOWN: 'UNKNOWN'
}

export const depositButtons = {
  FUNDING_OPTIONS: 'funding_options',
  TRANSFER_DETAILS: 'transfer_details',
  SIGN_CONTRACT: 'sign_contract',
  PROLONG_CONTRACT: 'prolong_contract',
  CONTRACT_DETAILS: 'contract_details',
  APPLY_AGAIN: 'apply_again',
  UPLOAD_FILES: 'upload_files',
  VIEW_TRANSACTIONS: 'view_transactions'
}

export const depositIconsByState = {
  REJECTED: 'client-face-sad',
  WAITING_FUNDS: 'client-expired',
  PROCEEDING: 'client-expired',
  FORWARDED: 'client-expired',
  CLOSED: false,
  TERMINATED: false,
  WAITING_SIGNING: 'client-sign'
}
