<template>
  <bb-modal visible flavor="card" publicSize="m" fullMobile class="email-change-modal" @close="handleClose">
    <bb-icon name="ui-check-circle" size="64" fill="green" />
    <div class="m-t-30">
      <strong>
        <h4 class="f-gotham-black f-color-navy">
          {{ translations.emailConfirmationLinkSent }}
        </h4>
      </strong>
    </div>
    <div class="m-t-25">
      <span class="f-gotham-book f-small f-color-gray-70">{{ translations.emailHasBeenSentTo + " " }}</span>
      <span class="f-gotham-medium f-small f-color-navy">{{ redactedEmail }}</span>
    </div>
    <p class="f-gotham-book f-small f-color-gray-70 m-t-30">{{ translations.emailAddressWillBeSaved }}</p>
    <p class="f-gotham-book f-small f-color-gray-70 m-t-15">{{ translations.emailWarning }}</p>
    <p class="f-gotham-book f-small f-color-gray-70 m-t-15">
      {{ translations.canAlwaysChange }}
      "<span class="f-gotham-medium f-small f-color-navy">{{ translations.myAccount }}</span>".
    </p>
    <bb-ticket-stub slot="footer" class="m-t-15">
      <bb-button :loading="isLoading" display="ticket" color="white" @click="confirm">
        <span class="f-small f-color-green">
          {{ translations.gotIt }}
        </span>
      </bb-button>
    </bb-ticket-stub>
  </bb-modal>
</template>

<script>
import '@bigbank/interface-components/dist/svg/ui/check-circle'

export default {
  name: 'email-change-modal',
  props: {
    email: String
  },
  data () {
    return {
      isLoading: false,
      translations: {
        emailConfirmationLinkSent: this.$pgettext('email_change_modal', 'E-mail confirmation link sent'),
        emailHasBeenSentTo: this.$pgettext('email_change_modal', 'An e-mail has been sent to'),
        emailAddressWillBeSaved: this.$pgettext('email_change_modal', 'Your new email address will be saved in case you open the link during the next 10 minutes.'),
        emailWarning: this.$pgettext('email_change_modal', "In case you don't open the link, old email address remains valid."),
        canAlwaysChange: this.$pgettext('email_change_modal', 'You can always change your data under'),
        myAccount: this.$gettext('My account'),
        gotIt: this.$gettext('Got it')
      }
    }
  },
  computed: {
    redactedEmail () {
      const atSymbolIndex = this.email.indexOf('@')
      const toReplace = this.email.substring(1, atSymbolIndex)
      let replaceString = ''
      for (let i = 0; i < toReplace.length; i++) {
        replaceString += '•'
      }
      return this.email.replace(toReplace, replaceString)
    }
  },
  methods: {
    handleClose () {
      this.$emit('close')
    },
    async confirm () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="scss">
.email-change-modal {
  text-align: center;
}
</style>
