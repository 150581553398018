import Vue from 'vue'
import Vuex from 'vuex'
import { campaign } from './modules/campaign'
import { ModuleName } from './constants'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    [ModuleName.CAMPAIGN]: campaign
  }
})
