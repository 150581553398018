<template>
  <bb-banner class="euribor-notice" permanent :visible="isVisible">
    <div>{{description}}</div>
  </bb-banner>
</template>

<script>
export default {
  name: 'euribor-notice',
  props: {
    contract: {
      required: true
    }
  },
  computed: {
    translations () {
      return {
        dateClosingIn: this.$pgettext('EURIBOR_CHANGE_MESSAGE', 'Please note that next interest date amendment date is closing and it might affect your schedule.'),
        datePassed: this.$pgettext('EURIBOR_CHANGE_MESSAGE', 'Please note that next interest date amendment date recently passed and it might have affected your schedule.')
      }
    },
    description () {
      const daysToNextChange = this.contract.daysToNextEuriborFixingDate
      const daysToPreviousChange = this.contract.daysToPreviousEuriborFixingDate

      if (Number.isInteger(daysToNextChange) && this.isBetween(daysToNextChange, 1, 14)) {
        return this.translations.dateClosingIn
      } else if (daysToNextChange === 0 || (Number.isInteger(daysToPreviousChange) && this.isBetween(daysToPreviousChange, -13, 0))) {
        return this.translations.datePassed
      } else {
        return undefined
      }
    },
    isVisible () {
      return !!this.description
    }
  },
  methods: {
    isBetween (number, start, end) {
      return number >= start && number <= end
    }
  }
}
</script>
