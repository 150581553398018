<template>
  <bank-id
    visible
    :startSigning="startSigning"
    :completeSigning="completeSigning"
    v-on="$listeners"
  />
</template>

<script>
import BankId from '@/components/signing/BankId'
import api from './../../api'

export default {
  name: 'bankid-signing-loan',
  components: { BankId },
  props: {
    contract: {
      required: true
    },
    signingRequestId: {
      required: true
    }
  },
  methods: {
    startSigning () {
      return api.startBankIDSigning(this.contract.id, this.signingRequestId)
    },
    completeSigning () {
      return api.completeBankIDSigning(this.contract.id, this.signingRequestId)
    }
  }
}
</script>
