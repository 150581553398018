<template>
  <div class="progress-wheel" :class="{'face-visible': isFaceVisible }">
    <div class="progress-indicator__content">
      <smiley :happiness="inDebt ? -10 : 10" :faceAngle="fillEased"
              class="face"></smiley>
      <slot></slot>
    </div>
    <svg viewBox="0 0 200 200">
      <circle class="background"
              :class="'color-' + trackColor"
              fill="none"
              stroke-width="4px"
              stroke="white"
              :r="radius"
      ></circle>

      <circle class="foreground spinner color-white"
              :class="{ 'spinner-visible': isSpinnerVisible }"
              fill="none"
              stroke-width="7px"
              stroke="white"
              :stroke-dasharray="2 * Math.PI * radius"
              :stroke-dashoffset="2 * Math.PI * radius * fillEased"
              :r="radius"
      ></circle>

      <circle :class="{ 'show-ball': isBallShown }"
              class="foreground ball filler color-white"
              fill="none"
              stroke="white"
              stroke-linecap="round"
              :stroke-dasharray="'1 ' + String(2 * Math.PI * radius)"
              :stroke-dashoffset="2 * Math.PI * radius * fillEased"
              :r="radius"
      ></circle>

      <circle v-if="inDebt"
              :class="{ 'show-ball': isBallShown }"
              class="foreground ball marker color-black"
              fill="none"
              stroke="white"
              stroke-linecap="round"
              :stroke-dasharray="'1 ' + String(2 * Math.PI * radius)"
              :stroke-dashoffset="2 * Math.PI * radius * fillEased"
              :r="radius"
      ></circle>
    </svg>

  </div>
</template>

<script>
import gsap from 'gsap'
import Smiley from './SmileyFace'

export default {
  name: 'ProgressWheel',
  components: { Smiley },
  data () {
    return {
      radius: 90,
      trackWidth: 4,
      lineWidth: 7,
      ballWidth: 8,
      heroHeader: {
        isFaceVisible: !this.inDebt,
        isSpinnerVisible: false,
        fillEased: 0,
        isBallShown: false
      }
    }
  },
  props: {
    value: Number,
    trackColor: String,
    initialized: Boolean,
    inDebt: Boolean
  },
  watch: {
    value (newValue) {
      this.animate(Number(newValue) || 0)
    }
  },
  methods: {
    setValue (value) {
      this.heroHeader.isBallShown = false
      setTimeout(() => {
        this.heroHeader.isBallShown = true
        this.heroHeader.isFaceVisible = false
        this.heroHeader.redraw = true
      }, 2000)
      gsap.to(this.heroHeader, { duration: 2, fillEased: value / 100 })
    },
    animate (value) {
      if (!this.isSpinnerVisible) {
        if (value === 0) {
          this.heroHeader.fillEased = 1
          this.heroHeader.isFaceVisible = false
        }
        this.heroHeader.isSpinnerVisible = true
      }
      this.setValue(value)
    }
  },
  computed: {
    isFaceVisible () {
      return this.heroHeader.isFaceVisible
    },
    isSpinnerVisible () {
      return this.heroHeader.isSpinnerVisible
    },
    fillEased () {
      return this.heroHeader.fillEased
    },
    isBallShown () {
      return this.heroHeader.isBallShown
    }
  },
  mounted () {
    if (![null, undefined].includes(this.value)) {
      this.animate(Number(this.value) || 0)
    }
  }
}
</script>

<style lang="scss" scoped>
  .progress-wheel {
    margin: auto;
    position: relative;

    .background,
    .foreground {
      stroke: currentcolor;
      transform: translate(100px, 100px) rotateZ(-90deg);
    }

    .spinner {
      opacity: 0;

      &.spinner-visible {
        opacity: 1;
        transition: opacity 0.5s;
      }
    }

    .ball {
      transition: stroke-width 0.2s ease-in;
      stroke-width: 0;

      &.show-ball {
        &.filler {
          @keyframes ball-wobble {
            0% { stroke-width: 1px; }
            20% { stroke-width: 30px; }
            100% { stroke-width: 20px; }
          }

          stroke-width: 20px;
          animation: ball-wobble;
          animation-duration: 0.2s;
        }

        &.marker {
          stroke-width: 13px;
          animation-duration: 0.2s;
        }
      }
    }
  }

  .progress-indicator__content {
    position: absolute;
    top: 0;
    height: 166px;
    width: 166px;
    display: flex;
    justify-content: center;
    opacity: 1;
    /* stylelint-disable */
    align-content: center;
    align-items: center;
    text-align: center;

    @media (min-width: $desktop-view-breaking-point-wide) {
      width: 205px !important;
      height: 205px !important;
    }

    .indicator-display {
      transition: opacity 0.5s;
      width: 75%;
    }

    .face {
      position: absolute;
      opacity: 0;
      transition: opacity 0.5s;

      &.visible {
        opacity: 1;
        transition: opacity 0.5s;
      }
    }

    .face-visible & {
      .indicator-display {
        opacity: 0;
        transition: opacity 0.5s;
      }

      .face {
        opacity: 1;
        transition: opacity 0.5s;
      }
    }
  }

  svg {
    overflow: visible;
  }

</style>
