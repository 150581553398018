<template>
  <transfer-details
    :translations="translations"
    :transfer-details="transferDetails"
    :loading="loading"
    :hide-sum="true"
    @close="onClose"
  />
</template>

<script>
import TransferDetails from '@deposits/components/TransferDetails.vue'
import { api } from '@deposits/api'
import { DEMAND_DEPOSIT_DASHBOARD } from '@/TrackingActions'
import { DepositRouteName } from '@deposits/const'
import { useDepositStore } from '@deposits/store/depositStore'
import { mapState } from 'pinia'

export default {
  name: 'demand-deposit_top-up',
  components: { TransferDetails },
  data () {
    return {
      loading: true,
      transferDetails: null
    }
  },
  computed: {
    ...mapState(useDepositStore, ['defaultDemandDeposit']),
    translations () {
      return {
        title: this.$pgettext('top-up', 'Top Up'),
        subtitle: this.$pgettext('top-up', 'Here are your account details:')
      }
    }
  },
  methods: {
    onClose () {
      return this.$router.push({ name: DepositRouteName.DemandDeposits })
    },
    async loadTransferDetails () {
      this.loading = true
      this.transferDetails = await api.getTransferDetails(this.defaultDemandDeposit.id)
      this.loading = false
    }
  },
  mounted () {
    this.$tracker.action(
      DEMAND_DEPOSIT_DASHBOARD.DEMAND_DEPOSIT_TOP_UP,
      {},
      { contractId: this.defaultDemandDeposit.id.toString() },
      true
    )
    if (!this.defaultDemandDeposit) {
      return this.$router.push({ name: DepositRouteName.DemandDeposits })
    }

    this.loadTransferDetails()
  }
}
</script>
