export default {
  computed: {
    cardTypeTranslations () {
      return {
        PHYSICAL_AND_VIRTUAL: {
          VISA_CLASSIC: this.$pgettext('CREDIT_CARD_TYPE', 'Visa Classic Credit'),
          VISA_GOLD: this.$pgettext('CREDIT_CARD_TYPE', 'Visa Gold Credit')
        },
        VIRTUAL: {
          VISA_CLASSIC: this.$pgettext('CREDIT_CARD_TYPE', 'Visa Classic Virtual'),
          VISA_GOLD: this.$pgettext('CREDIT_CARD_TYPE', 'Visa Gold Virtual')
        }
      }
    }
  },
  methods: {
    cardTypeLabel (card) {
      return this.cardTypeTranslations[card.type][card.cardClass]
    }
  }
}
