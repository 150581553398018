<template>
  <div class="info-box">
    <div v-for="(item, key) in visibleItems" :key="key" class="item">
      <div class="value">
        <div class="heading">{{ item.label }}</div>
        <slot :name="(item.key || key) + 'Item'" :value="item.value">
          <div>{{ item.value }}</div>
        </slot>
      </div>
      <info-box-copy-button class="copy-button" :item="item.value" v-if="item.showCopyButton" :size="'mini'" />
    </div>
  </div>
</template>

<script>
import InfoBoxCopyButton from './InfoBoxCopyButton'
export default {
  name: 'info-box',
  components: { InfoBoxCopyButton },
  props: {
    data: {
      default: []
    }
  },
  computed: {
    visibleItems () {
      return this.data.filter(({ hide }) => !hide)
    }
  }
}
</script>

<style lang="scss">
  .info-box {
    display: flex;
    flex-flow: column;
    border-top: 1px solid $gray-50;

    @media (min-width: $desktop-view-breaking-point) {
      flex-flow: row nowrap;
      flex-wrap: nowrap;
      justify-content: space-between;
      border-top: 2px solid $gray-20;
      border-bottom: 2px solid $gray-20;
      padding-top: 9px;
      padding-bottom: 9px;
    }

    .item {
      border-bottom: 1px solid $gray-50;
      padding-top: 9px;
      padding-bottom: 9px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      @media (min-width: $desktop-view-breaking-point) {
        border-bottom: 0;
        margin-right: 5px;

        &:last-child {
          margin: 0;
        }
      }

      .heading {
        font-size: $font-size-tiny;
        color: $gray-70;
        text-transform: uppercase;
        font-weight: 500;
        width: 100%;
      }

      .value {
        font-size: $font-size-small;
        color: $gray;
        font-family: $gotham-medium;
        display: flex;
        flex-direction: column;

        @media (min-width: $desktop-view-breaking-point) {
          font-size: $default-font-size;
          font-family: $gotham-bold;
        }
      }

      .copy-button {
        @media (min-width: $desktop-view-breaking-point) {
          margin-left: 12px;
          margin-top: 14px;
        }
      }
    }
  }
</style>
