<template>
  <bb-modal
    visible
    @close="$emit('close')"
    v-bind="modalProps"
    class="deposit_withdraw__close-confirmation">
    <template #heading>
      <div class="deposit_withdraw__title">
        <translate translate-context="withdraw_modal_title">Unsuccessful withdrawal</translate>
      </div>
    </template>
    <div class="content">
      <div class="illustration">
        <bb-icon class="icon" name="illustration-vault" size="fluid" fill="mint-70" />
      </div>
      <div class="m-t-30 text-center">
        <p class="color-red f-default">
          <translate translate-context="withdraw_modal">Unable to close savings deposit as it is linked to a term deposit contract.</translate>
        </p>
      </div>
    </div>
    <div slot="footer">
      <bb-button v-if="$screen.md" display="block" color="mint" :label="allTranslations.goToTermDeposits" :to="toTermDeposits" />
      <bb-button v-else corner="sharp" size="lg" display="block" color="mint" :label="allTranslations.goToTermDeposits" :to="toTermDeposits" />
    </div>
  </bb-modal>
</template>

<script>
import '@bigbank/interface-components/dist/svg/illustration/vault'
import { DepositRouteName } from '@deposits/const'

export default {
  props: {
    modalProps: {
      required: true
    }
  },
  computed: {
    allTranslations () {
      return {
        goToTermDeposits: this.$pgettext('withdraw_form_close_contract', 'Change servicing account'),
        ...this.translations
      }
    },
    toTermDeposits () {
      return {
        name: DepositRouteName.TermDeposits
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.illustration {
  width: 70%;
  margin: auto;
}

.content {
  min-height: 400px;
}
</style>
