<template>
  <signing-modal-wrapper
    :visible="visible"
    @close="$emit('close', $event)"
    :showLoader="isLoading"
  >
    <template slot="heading"><translate>{{translations.title}}</translate></template>
    <template v-if="showReauthView">
      <img class="eparaksts-logo" :src="eparakstsLogo" />
      <p class="f-align-center f-h4 color-gray m-b-50">
        {{ translations.authSessionExpired }}
      </p>
    </template>
    <p v-else-if="showErrorMessage" class="error">
      <signing-error-messages
        :code="errorCode"
        :type="signingMethod"
        :visible="showErrorMessage"
        :data="errorData"
        @close="closeModal"
      />
    </p>
    <template v-if="showReauthView">
      <bb-button
        v-if="$screen.md"
        slot="footer"
        display="block"
        :label="translations.continue"
        @click="triggerReauth"
      />
      <bb-button
        v-else
        slot="footer"
        corner="sharp"
        display="block"
        :label="translations.continue"
        size="lg"
        @click="triggerReauth"
      />
    </template>
  </signing-modal-wrapper>
</template>

<script>
import { SIGNING_METHODS } from '@bigbank/dc-common/enums/loginAndSigningMethods'
import SigningErrorMessages from './SigningErrorMessages'
import SigningModalWrapper from './SigningModalWrapper'
import { isNil, isNull, isString } from 'lodash'
import isUndefined from 'lodash/isUndefined'
import { VERIFICATION_METHODS } from '@/const'
import eparakstsLogo from './../../images/eparaksts.svg'

export default {
  name: 'eparaksts-signing',
  components: { SigningErrorMessages, SigningModalWrapper },
  data () {
    return {
      eparakstsLogo,
      isLoading: true,
      errorCode: null,
      errorData: null,
      signingMethod: SIGNING_METHODS.EPARAKSTS
    }
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false
    },
    startSigning: {
      required: true
    },
    completeSigning: {
      required: true
    }
  },
  computed: {
    translations () {
      return {
        title: this.$gettextInterpolate(
          this.$pgettext('signing_form_header_eparaksts', 'Signature with E-Paraksts')
        ),
        authSessionExpired: this.$pgettext('signing_form_eparaksts', 'To continue with contract signing, you will be logged out of self service to verify yourself with eParaksts app'),
        continue: this.$pgettext('signing_form_eparaksts', 'Continue with eParaksts'),
        loading: this.$pgettext('loading', 'Loading please wait...')
      }
    },
    showErrorMessage () {
      return !isNull(this.errorCode)
    },
    showReauthView () {
      return this.errorCode === 'AUTH_SESSION_EXPIRED'
    }
  },
  methods: {
    setErrorCode (code) { this.errorCode = code },
    setLoading (flag) { this.isLoading = flag },
    async pollStatus (delayMs = VERIFICATION_METHODS.EPARAKSTS.POLL_DELAY_MS) {
      try {
        const response = await this.completeSigning()

        if (response.status === VERIFICATION_METHODS.EPARAKSTS.STATUS.COMPLETED_OK) {
          this.$emit('signingSuccessful')
        } else if (response.status === VERIFICATION_METHODS.EPARAKSTS.STATUS.RUNNING) {
          setTimeout(() => this.pollStatus(), delayMs)
        } else {
          throw response
        }
      } catch (response) {
        this.setLoading(false)
        if (this.isResponseErrored(response)) {
          this.setErrorCode(!response ? 'DEFAULT' : (response.errCode ?? response.err_code))
        }
        this.$emit('signingError', response)
      }
    },
    async startSigningProcess () {
      this.setLoading(true)
      this.setErrorCode(null)

      try {
        const response = await this.startSigning()
        if (isString(response.redirectUrl)) {
          document.location = response.redirectUrl
        } else {
          throw response
        }
      } catch (response) {
        this.setLoading(false)
        if (this.isResponseErrored(response)) {
          this.setErrorCode(!response ? 'DEFAULT' : (response.errCode ?? response.err_code))
        }
      }
    },
    triggerReauth () {
      document.location = this.$router.resolve({
        query: {
          hint: 'reauth'
        }
      }).href
    },
    isResponseErrored (response) {
      return !isUndefined(response.errCode) || !isUndefined(response.err_code)
    },
    closeModal () {
      this.setErrorCode(null)
      this.$emit('close')
    }
  },
  mounted () {
    if (!isNil(this.$route.query.callback)) {
      this.pollStatus()
    } else {
      this.startSigningProcess()
    }
  }
}
</script>

<style lang="scss">
.error {
  color: $red;
  font-family: $gotham-medium;
  margin-top: 20px;
  text-align: center;
  font-size: $smallest-font-size;
}

.eparaksts-logo {
  margin: auto;
  display: block;
  width: 265px;
  margin-top: 30px;
  margin-bottom: 30px;
}
</style>
