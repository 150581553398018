import Notifications from './NotificationsHolder.vue'
import { events } from './events'
import { isObject, isString } from 'lodash'

const Notification = {
  install (Vue, args = {}) {
    if (this.installed) {
      return
    }

    this.installed = true
    this.params = args

    Vue.component(args.componentName || 'notifications', Notifications)

    const notify = (params) => {
      if (isString(params)) {
        params = { text: params, type: 'success' }
      }

      if (isObject(params)) {
        if (params.action === 'remove' && isString(params.key)) {
          events.$emit('remove', params.key)
        } else {
          events.$emit('add', params)
        }
      }
    }

    const name = args.name || 'notify'

    Vue.prototype['$' + name] = notify
    Vue[name] = notify
  }
}

export default Notification
