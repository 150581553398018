const currencies = [
  { code: 'EUR', symbol: '€' },
  { code: 'SEK', symbol: 'kr' },
  { code: 'BGN', symbol: 'Лв.' }
]

function currencyCodeToSymbol (currencyCode, fallback = '') {
  const definition = currencies.find(item => item.code === currencyCode)

  return definition ? definition.symbol : fallback
}

function currencySymbolToCode (currencySymbol, fallback = '') {
  const definition = currencies.find(item => item.symbol === currencySymbol)

  return definition ? definition.code : fallback
}

function isCurrencySymbol (value) {
  return currencies.findIndex(item => item.symbol === value) > -1
}

export {
  currencyCodeToSymbol,
  currencySymbolToCode,
  isCurrencySymbol
}
