<template>
  <bb-modal class="manage-loan" :visible="visible" @close="onClose()" flavor="card" color="white" publicSize="m" fullMobile>
    <slot name="title">
      <div class="title">{{title}}</div>
    </slot>
    <text-with-links v-if="text" :text="text" class="text" :links="[]" />
    <bb-form
      @submit="onSubmit"
      @progress="onProgress"
      :submitText="buttonText"
      :errorText="translations.errorText"
      :loading="showLoader"
      :disabled="showLoader"
    >
      <slot />
      <div slot="submit" slot-scope="{}" />
    </bb-form>
    <template #footer>
      <slot name="footer">
        <bb-button
          size="lg"
          :label="buttonText"
          :disabled="showLoader || !isValid"
          :loading="showLoader"
          display="block"
          corner="sharp"
          class="m-s-a"
          @click="onSubmit"
        />
      </slot>
    </template>
  </bb-modal>
</template>

<script>
import TextWithLinks from '@/components/TextWithLinks'

export default {
  name: 'manage-loan-layout',
  inject: ['$validator'],
  components: {
    TextWithLinks
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    title: {
      required: false
    },
    text: {
      required: false
    },
    buttonText: {
      required: false
    },
    disableSubmitButton: {
      required: false,
      default: false
    },
    showLoader: {
      required: false,
      default: false
    },
    data: {
      required: true
    }
  },
  data () {
    return {
      isValid: false
    }
  },
  computed: {
    translations () {
      return {
        errorText: this.$pgettext('manage_loan', 'Fix errors to continue')
      }
    }
  },
  methods: {
    onClose () {
      this.$emit('close')
    },
    onSubmit () {
      this.$emit('submit', this.data)
    },
    onProgress (progress) {
      this.isValid = progress === 1
    }
  }
}
</script>

<style lang="scss" scoped>
.manage-loan {
  .title {
    color: $blue;
    font-size: $default-font-size;
    font-family: $gotham-bold;
    text-align: center;
  }

  .text {
    font-size: $font-size-small;
    font-family: $gotham-book;
    color: $gray;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
  }
}
</style>
