<template>
  <div>
    <transaction-details-modal
      flavor="credit-card"
      :onCloseRedirectUrl="creditCardRoute"
      :isLoading="isLoading"
      :transaction="transaction"
      :isDownloadRunning="isDownloadButtonDisabled"
      :customerName="customerName"
      :translations="translations"
      @download="onDownloadButtonClicked"
    />
  </div>
</template>
<script>
import '@bigbank/interface-components/dist/svg/illustration/credit-card'
import '@bigbank/interface-components/dist/svg/illustration/virtual-card'
import '@bigbank/interface-components/dist/svg/ui/chevron-down.svg'
import { isEmpty, isNil, isNull } from 'lodash'
import api from '@/modules/loan/api'
import { mapState } from 'pinia'
import { formatDateForRequest } from '@/plugins/dateFormatters'
import { AccountStatementFileType } from '@bigbank/dc-common/enums/credit-card.enums'
import TransactionDetailsModal from '@/components/transactions/TransactionDetailsModal.vue'
import { useRootStore } from '../../../store/root'
import { useLoanStore } from '@loan/store/loanStore'
import { CreditCardRouteName } from '@credit-card/views/const'

export default {
  name: 'credit-card-transaction-details-modal',
  data () {
    return {
      isLoading: true,
      isDownloadButtonDisabled: false,
      transaction: null,
      transactionId: null,
      fileType: AccountStatementFileType.PDF
    }
  },
  components: {
    TransactionDetailsModal
  },
  watch: {
    $route: {
      immediate: true,
      handler (to) {
        this.setTransactionId(to.params.transactionId)
      }
    },
    transactionId: {
      immediate: true,
      handler: async function (newId, oldId) {
        if (!isNil(newId) && newId !== oldId) {
          this.setTransaction(this.getTransactionDetails())
          this.setLoading(false)
        }
      }
    }
  },
  computed: {
    ...mapState(useRootStore, ['locale']),
    ...mapState(useLoanStore, [
      'creditCardTransactionsSearchRequest',
      'creditCardAccountStatement',
      'creditCardContract'
    ]),
    creditCardRoute () {
      return CreditCardRouteName.CreditCard
    },
    transactionsListFilters () {
      return this.creditCardTransactionsSearchRequest ?? {}
    },
    customerName () {
      return this.creditCardContract?.borrowerFullName ?? 'N/A'
    },
    translations () {
      return {
        account: this.$pgettext('credit_card_transactions', 'Account'),
        beneficiary: this.$pgettext('credit_card_transactions', 'Beneficiary'),
        beneficiaryAccount: this.$pgettext('credit_card_transactions', 'Beneficiary\'s account'),
        remitter: this.$pgettext('credit_card_transactions', 'Remitter'),
        remitterAccount: this.$pgettext('credit_card_transactions', 'Remitter\'s account'),
        amount: this.$pgettext('credit_card_transactions', 'Amount'),
        paymentDateAndTime: this.$pgettext('credit_card_transactions', 'Payment date and time'),
        paymentDescription: this.$pgettext('credit_card_transactions', 'Payment description'),
        bankReferenceNo: this.$pgettext('credit_card_transactions', 'Bank reference no'),
        status: this.$pgettext('credit_card_transactions', 'Status'),
        transaction: this.$pgettext('credit_card_transactions', 'Transaction'),
        reservation: this.$pgettext('credit_card_transactions', 'Reservation')
      }
    }
  },
  methods: {
    setLoading (flag) { this.isLoading = flag },
    setTransaction (transaction) { this.transaction = transaction },
    setTransactionId (id) { this.transactionId = Number(id) },
    setDownloadingRunning (flag) { this.isDownloadButtonDisabled = flag },
    getTransactionDetails () {
      const transaction = this.creditCardAccountStatement.transactions.find((transaction) => {
        return transaction.id.toString() === this.transactionId.toString()
      })

      if (isNull(transaction)) {
        console.warn(`Unable to find transaction | transactionId: ${this.transactionId}`)
        return
      }

      return transaction
    },
    async onDownloadButtonClicked (fileType) {
      try {
        this.setDownloadingRunning(true)
        const contractId = this.creditCardContract.id
        const date = formatDateForRequest(this.transaction?.date)

        await api.downloadCreditCardAccountStatement(contractId, {
          ...(isEmpty(this.transactionsListFilters)
            ? {
                startDate: date,
                endDate: date
              }
            : this.transactionsListFilters),
          transactionId: this.transactionId
        }, fileType)
      } finally {
        this.setDownloadingRunning(false)
      }
    }
  }
}
</script>
