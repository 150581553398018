<template>
  <div>
    <money-origin-documents
      :flowData="flowData"
      :flowConstants="flowConstants"
      @submit="submit"
    ></money-origin-documents>
  </div>
</template>

<script>
import MoneyOriginDocuments from './MoneyOriginDocuments.vue'

export default {
  name: 'create-money-origin-documents',
  components: {
    MoneyOriginDocuments
  },
  props: {
    flowData: {
      type: Object,
      required: false,
      default () {
        return {
          moneyOriginLookupValues: [],
          moneyOriginAdditionalDocuments: []
        }
      }
    },
    flowConstants: {
      type: Object,
      required: false,
      default () {
        return {}
      }
    }
  },
  methods: {
    async submit (stepData) {
      this.$emit('submit', stepData)
    }
  }
}
</script>
