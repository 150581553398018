<template>
  <bb-modal v-bind="modalProps">
    <div class="inactivity-modal__header">{{ translations.modalTitle }}</div>
    <div class="inactivity-modal__body">
      <div class="inactivity-modal__body--text">{{ translations.modalText }}</div>
      <bb-progress-indicator v-model="timer" v-bind="timerProps" @input="onTimerTick" />
      <div class="inactivity-modal__body--subtext">{{ translations.modalSubtext }}</div>
    </div>
    <div slot="footer" class="inactivity-modal__footer">
      <div class="inactivity-modal__footer--logout-wrapper">
        <bb-button v-bind="logoutButtonProps" @click="initiateLogout" />
      </div>
      <div class="inactivity-modal__footer--continue-wrapper">
        <bb-button v-bind="continueButtonProps" @click="continueSession" />
      </div>
    </div>
  </bb-modal>
</template>

<script>
import { mapState } from 'pinia'
import { INACTIVITY_NOTIFICATION_TIMER_DURATION_MS } from '@/const'
import LogoutReason from '@/types/logout-reason'
import api from '@/api'
import { useRootStore } from '../store/root'

const TIMER_MAX_DURATION_S = INACTIVITY_NOTIFICATION_TIMER_DURATION_MS / 1000

export default {
  data () {
    return {
      isInactivityModalVisible: false,
      areActionButtonsDisabled: false,
      timer: TIMER_MAX_DURATION_S,
      previousDocumentTitle: ''
    }
  },
  created () {
    this.$watch(
      () => this.inactivityTime,
      (inactivityTime) => {
        const graceTime = window.INACTIVITY_TIMEOUT_MS - INACTIVITY_NOTIFICATION_TIMER_DURATION_MS
        const isInactivityReached = inactivityTime >= window.INACTIVITY_TIMEOUT_MS

        if (isInactivityReached) {
          this.initiateLogout({ expired: true })
        } else if (inactivityTime >= graceTime && !this.isInactivityModalVisible) {
          this.showModal()
        }
      }
    )
  },
  onIdle () {
    if (this.$route.meta.guarded === false) {
      return
    }

    this.showModal()
  },
  computed: {
    ...mapState(useRootStore, ['isMobile', 'inactivityTime']),
    translations () {
      return {
        modalTitle: this.$pgettext('inactivity_modal', 'Your session will be expired soon'),
        modalText: this.$pgettext('inactivity_modal', 'We will automatically log you out of self-service due to inactivity'),
        modalSubtext: this.$pgettext('inactivity_modal', 'Do you want to continue?'),
        logout: this.$pgettext('inactivity_modal', 'Log out'),
        continue: this.$pgettext('inactivity_modal', 'Continue'),
        sessionExpiresInSeconds: this.$gettextInterpolate(this.$pgettext('inactivity_modal', 'Session expires in %{seconds} seconds'), {
          seconds: this.timer
        })
      }
    },
    modalProps () {
      return {
        flavor: this.isMobile ? 'card' : 'user',
        fullMobile: true,
        centerVertically: !this.isMobile,
        hideClose: true,
        staticOverlay: true,
        visible: this.isInactivityModalVisible,
        class: 'inactivity-modal',
        publicSize: 'm'
      }
    },
    timerProps () {
      return {
        max: TIMER_MAX_DURATION_S,
        color: 'mint',
        class: 'm-x-a m-y-15',
        flavor: 'timer',
        trackColor: 'gray-30',
        timerRunning: this.isInactivityModalVisible
      }
    },
    logoutButtonProps () {
      const defaults = {
        color: 'gray',
        inverted: true,
        label: this.translations.logout,
        disabled: this.areActionButtonsDisabled
      }

      if (this.isMobile) {
        return {
          ...defaults,
          class: 'w-100 m-b-20'
        }
      }

      return {
        ...defaults,
        class: 'w-100'
      }
    },
    continueButtonProps () {
      const defaults = {
        class: 'w-100',
        label: this.translations.continue,
        disabled: this.areActionButtonsDisabled
      }

      if (this.isMobile) {
        return {
          ...defaults,
          size: 'lg',
          corner: 'sharp'
        }
      }

      return defaults
    }
  },
  methods: {
    showModal () {
      this.previousDocumentTitle = document.title
      this.isInactivityModalVisible = true
      this.areActionButtonsDisabled = false
    },
    onTimerTick (e) {
      document.title = this.translations.sessionExpiresInSeconds

      e <= 0 && this.initiateLogout({ expired: true })
    },
    initiateLogout ({ expired = false }) {
      this.areActionButtonsDisabled = true

      expired
        ? this.$router.push({ path: '/logout', query: { expired: true, reason: LogoutReason.SESSION_EXPIRED } })
        : this.$router.push({ path: '/logout', query: { reason: LogoutReason.INACTIVITY_MODAL_BUTTON_CLICK } })
    },
    continueSession () {
      document.title = this.previousDocumentTitle
      this.isInactivityModalVisible = false
      this.timer = TIMER_MAX_DURATION_S
      // notify backend to reset activity timer - no need to await
      api.resetInactivityTimer()
    }
  }
}
</script>

<style lang="scss" scoped>
.inactivity-modal {
  &__header {
    color: $blue;
    font-weight: 500;
    font-size: $font-size-small;
    font-family: $gotham-medium;
    line-height: 18px;
    text-align: center;
    margin-bottom: 75px;
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;

    &--text {
      color: $gray;
      text-align: center;
      font-size: $h4-size;
      font-family: $gotham-medium;
      line-height: 24px;
      width: 333px;

      @media (max-width: $mobile-view-breaking-point) {
        width: 275px;
      }

      margin-bottom: 35px;
    }

    &--subtext {
      color: $gray;
      font-size: $font-size-small;
      font-family: $gotham-book;
      line-height: 18px;
      text-align: center;
      margin-top: 75px;
    }
  }

  &__footer {
    padding: 0 30px 30px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 25px;

    div {
      flex: 1 0 0;
    }

    @media (max-width: $mobile-view-breaking-point) {
      padding: 0;
      display: block;
    }

    &--logout-wrapper {
      @media (max-width: $mobile-view-breaking-point) {
        padding: 0 20px;
      }
    }
  }
}
</style>
