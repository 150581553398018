<template>
  <div class="details-box hidden-mobile">
    <div v-for="(item, key) in data" :key="key" class="item">
      <div class="heading">
        {{item.label}}
        <bb-tooltip v-if="item.tooltipText">
          {{item.tooltipText}}
        </bb-tooltip>
      </div>
      <div class="value">
        <div v-if="item.showLoaderOnNull && item.value === null" class="details-box__loader">
          <bb-spinner small />
        </div>
        <template v-else>{{item.value}}</template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'details-box',
  props: {
    data: {
      default: []
    }
  }
}
</script>

<style lang="scss">
  .details-box {
    display: flex;
    flex-flow: row nowrap;
    border-radius: 6px;
    border: 2px solid $gray-30;

    .item {
      text-align: center;
      padding-top: 25px;
      padding-bottom: 19px;
      width: 40%;
      border-right: 2px solid $gray-30;

      &:last-child {
        border-right-width: 0;
      }

      .heading {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $gray-70;
        font-size: $font-size-tiny;
        text-transform: uppercase;
        font-family: $gotham-bold;

        @media (min-width: $desktop-view-breaking-point) {
          font-size: $font-size-tiny;
        }

        .bb-tooltip .bb-icon {
          height: 15px;
          margin-bottom: 2px;
        }
      }

      .value {
        font-size: $h3-size;
        color: $gray;
        font-family: $gotham-bold;
      }
    }

    &__loader {
      display: flex;
      justify-content: center;
      margin-top: 5px;
    }
  }
</style>
