<template>
  <evrotrust-signing
    :visible="visible"
    :startSigning="startSigning"
    :completeSigning="completeSigning"
    v-on="$listeners"
  />
</template>

<script>
import { api } from '../../api'
import EvrotrustSigning from '@/components/signing/Evrotrust'

export default {
  name: 'evrotrust-signing-deposit',
  components: { EvrotrustSigning },
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false
    },
    deposit: {
      required: true
    },
    signingRequestId: {
      required: true
    }
  },
  methods: {
    startSigning () {
      return api.initSigningEvrotrust(this.deposit.id, this.signingRequestId)
    },
    completeSigning () {
      return api.completeSigningEvrotrust(this.deposit.id, this.signingRequestId)
    }
  }
}

</script>
