<template>
  <div class="main-container">
    <div class="sub-container">
      <h4>{{ translations.title }}</h4>
      <div class="balance">
        <div class="balance__label"><translate>Your current balance</translate></div>
        <div class="balance__sum">{{ totalBalance }}</div>
      </div>
      <prolongation-radio-card
        class="m-t-15"
        :class="{ active: option.key === selectedOption.key }"
        v-for="option in optionList"
        @click.native="selectOption(option)"
        :key="option.key"
        :infoText="option.infoText"
        :amount="option.amount"
        :currencyCode="flowData.accountType.restrictedCurrencyCode"
        :locale="flowData.locale"
      />
      <div class="continue-button-container">
        <continue-button
          :text="continueText"
          :handleClick="goToNextStep"
          :disabled="!isFormValid"
        ></continue-button>
      </div>
    </div>
  </div>
</template>

<script>
import { formatMoneyWithCurrency } from '@/plugins/numformat'
import getCurrency from '../../utils/getCurrency'
import ProlongationRadioCard from './ProlongationRadioCard'
import ContinueButton from './ContinueButton'

export default {
  name: 'prolongation-step-one',
  data () {
    return {
      interestAmount: 0,
      translations: {
        title: this.$gettext('How do you want to continue?')
      },
      selectedOption: {},
      selectionKeys: {
        depositMaximum: 'deposit-maximum',
        depositOnlyPrincipal: 'deposit-only-principal',
        differentAmount: 'different-amount'
      }
    }
  },
  components: {
    ProlongationRadioCard,
    ContinueButton
  },
  props: {
    flowData: Object
  },
  computed: {
    isFormValid () {
      return this.selectedOption && this.selectedOption.key
    },
    totalBalance () {
      return formatMoneyWithCurrency(this.flowData.deposit.prolongationAmount, this.currency, this.flowData.locale)
    },
    optionList () {
      const deposit = this.flowData.deposit
      const options = []

      options.push({
        key: this.selectionKeys.depositMaximum,
        infoText: this.$gettext('Deposit + interest'),
        amount: parseFloat(deposit.prolongationAmount)
      })

      options.push({
        key: this.selectionKeys.depositOnlyPrincipal,
        infoText: this.$gettext('Only Deposit'),
        amount: parseFloat(deposit.amount)
      })

      let lastOptionInfoText = this.$gettext('I want to Prolong a different sum')
      if (this.flowData.specialInterestRequest) {
        lastOptionInfoText = this.$pgettext('prolong-step-1', 'I want to reduce my deposit amount')
      }

      options.push({
        key: this.selectionKeys.differentAmount,
        infoText: lastOptionInfoText,
        amount: null
      })

      return options
    },
    continueText () {
      return this.$gettext('Continue')
    },
    currency () {
      return getCurrency(this.flowData.deposit.currencyCode)
    }
  },
  methods: {
    selectOption (option) {
      this.selectedOption = option
    },
    goToNextStep () {
      if (this.isFormValid) {
        this.$emit('submit', this.selectedOption)
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .main-container {
    margin: 0 auto;
    padding: 0;
    max-width: 380px;
  }

  .sub-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    margin: 0 auto;
    padding: 0;
    max-width: 380px;

    h4 {
      font-family: $max-pro-demi-bold;
      text-align: center;
      color: $navy;
      margin-top: 50px;
      width: 100%;
      font-size: $mobile-h3-size;

      @media (min-width: $desktop-view-breaking-point-wide) {
        font-size: $h4-size;
      }
    }
  }

  .continue-button-container {
    margin-top: 60px;
    width: 100%;
  }

  .balance {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: 0;
    max-width: 380px;

    &__label {
      margin-top: 20px;
      font-family: $gotham-book;
      font-size: $mobile-tiny-font-size;
      text-transform: uppercase;
      color: $gray-90;

      @media (min-width: $desktop-view-breaking-point-wide) {
        margin-top: 22px;
        font-size: $font-size-smallest;
      }
    }

    &__sum {
      font-family: $gotham-bold;
      font-size: $mobile-h2-size;
      color: $blue;
      margin-bottom: 10px;

      @media (min-width: $desktop-view-breaking-point-wide) {
        font-size: $h3-size;
        margin-bottom: 20px;
      }
    }
  }
</style>
