import 'whatwg-fetch'
import Vue from 'vue'
import ErrorPage from 'vue-error-page/src'
import VueRouter from 'vue-router'
import PortalVue from 'portal-vue'
import VSanitize from 'v-sanitize'
import VeeValidate from 'vee-validate'
import VeeFi from 'vee-validate/dist/locale/fi'
import VeeLt from 'vee-validate/dist/locale/lt'
import VeeDe from 'vee-validate/dist/locale/de'
import VeeNl from 'vee-validate/dist/locale/nl'
import VeeEt from 'vee-validate/dist/locale/et'
import VeeEn from 'vee-validate/dist/locale/en'
import VeeSv from 'vee-validate/dist/locale/sv'
import VeeLv from 'vee-validate/dist/locale/lv'
import VeeRu from 'vee-validate/dist/locale/ru'
import VeeBg from 'vee-validate/dist/locale/bg'
import Vuex from 'vuex'
import VueEvents from 'vue-events'
import VueClipboard from 'vue-clipboard2'
import GetTextPlugin from 'vue-gettext'
import { TrackingVuePlugin } from '@bigbank/tracker/dist/frontend'
import { ApmVuePlugin } from '@elastic/apm-rum-vue'
import { apm } from '@elastic/apm-rum'
import '@bigbank/interface-components/dist/lib/esm.css'
import VueScreen from 'vue-screen'
import IdleVue from 'idle-vue'
import translations from './../translations/translations.json'
import router from './views'
import App from './App.vue'
import store from './store'
import layouts from './layouts'
import Notifications from './plugins/notification'
import SwVuePlugin from './service-worker/swVuePlugin'
import { INACTIVITY_NOTIFICATION_TIMER_DURATION_MS } from '@/const'
import { PiniaVuePlugin, createPinia } from 'pinia'
import ErrorHandler from './plugins/errorHandler'
import './ic'

window.Vue = Vue
window.eventBus = new Vue()

Vue.use(PiniaVuePlugin)
Vue.use(ErrorPage)

if (window.APM_CONFIG) {
  Vue.use(ApmVuePlugin, {
    router,
    config: {
      serviceName: 'digital-channels-self_service',
      serverUrl: 'https://apm.bigbank.eu:443',
      serviceVersion: VUE_APP_GIT_HASH,
      environment: window.APM_CONFIG.env,
      active: window.APM_CONFIG.enable
    },
    apm
  })
  apm.addLabels(
    {
      team: 'dcp',
      env: window.APM_CONFIG.env,
      // TODO: might not be needed after DCP-5042 is resolved
      resolution: `${window.screen.width} x ${window.screen.height}`,
      viewport: `${window.innerWidth} x ${window.innerHeight}`,
      orientation: window.screen?.orientation?.type,
      pixelRatio: window?.devicePixelRatio,
      pinchZoom: window?.visualViewport?.scale
    }
  )
  apm.addFilter(payload => {
    const errors = payload.errors.filter(error => {
      return !error?.exception?.message?.startsWith('ResizeObserver')
    })
    return { ...payload, errors }
  })
  if (window.APM_CONFIG.enable) {
    Vue.config.errorHandler = function (err) {
      apm.captureError(err)
    }
    Vue.config.silent = window.APM_CONFIG.enable
  }
}

Vue.use(TrackingVuePlugin, { router })
Vue.use(PortalVue)
Vue.use(VSanitize, {
  allowedAttributes: false
})
Vue.use(layouts)
Vue.use(Notifications)
Vue.use(VueScreen, 'bootstrap')

Vue.use(VeeValidate, {
  locale: window.LANG || 'fi',
  dictionary: {
    fi: VeeFi,
    lt: VeeLt,
    de: VeeDe,
    en: VeeEn,
    nl: VeeNl,
    et: VeeEt,
    sv: VeeSv,
    lv: VeeLv,
    ru: VeeRu,
    bg: VeeBg
  },
  events: 'input'
})

Vue.use(GetTextPlugin, {
  defaultLanguage: window.LANG || 'fi',
  languageVmMixin: {
    computed: {
      currentKebabCase: function () {
        return this.current.toLowerCase().replace('_', '-')
      }
    }
  },
  translations
})
Vue.use(Vuex)
Vue.use(VueEvents)
Vue.use(VueClipboard)

const pinia = createPinia()

// Must be used after initiating pinia
Vue.use(VueRouter)
Vue.use(SwVuePlugin)
Vue.use(ErrorHandler, router)
Vue.use(IdleVue, {
  eventEmitter: window.eventBus,
  idleTime: window.INACTIVITY_TIMEOUT_MS - INACTIVITY_NOTIFICATION_TIMER_DURATION_MS,
  events: ['mousemove', 'keydown', 'mousedown', 'touchstart', 'touchmove'],
  startAtIdle: false
})

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
  store,
  pinia
}).$mount('#app')
