<template>
  <div class="credit-card-limits-modal">
    <bb-modal
      :visible="true"
      :flavor="modalFlavor"
      @close="onModalClosed"
      class="noselect text-center"
      id="limits-modal"
      size="x3"
      centerVertically
      fullMobile
    >
      <h5 class="text-center modal-title">{{ modalTitle }}</h5>
      <h4 class="text-center f-gotham-book m-t-20 modal-description">{{translations.description}}</h4>
      <bb-banner class="text-left m-t-30" type="warning" :visible="isMonthlyLimitExceeded" permanent>
        <div class="monthly_limit_warning_title f-strong">{{ translations.monthly_limit_warning_title }}</div>
        <div>{{ translations.monthly_limit_warning_text }}</div>
        <bb-link class="f-small" :to="learnMoreLinkProps.to">{{ learnMoreLinkProps.label }}</bb-link>
      </bb-banner>
      <div class="limit-section m-t-35" v-for="section in sections" :key="section.key">
        <div class="limit-section-top m-b-20">
          <div class="limit-section-title">{{section.title}}</div>
          <bb-toggle-switch
            v-if="isCreditCardSpendingCategoryToggleable(section.key)"
            v-model="section.isEnabled.newValue"
            @change="onLimitSectionToggle($event, section)"
            :name="section.name"
            :on-status-label="translations.activated"
            :off-status-label="translations.deactivated"
            :show-status="isToggleStatusShown"
          />
        </div>
        <div class="limit-items">
          <limit-slider-item
            v-for="period in section.periods" :key="period.periodType"
            class="item"
            :min="period.min"
            :max="period.max"
            v-model="period.value"
            :current="period.current"
            :type="period.periodType"
            :enabled="section.isEnabled.newValue"
            :title="section.title"
            :sectionName="section.name"
            @input="onLimitItemChange(period, section)"
          />
        </div>
        <bb-separator class="m-t-30" dashed v-if="section.key === CardTransactionType.GLOBAL" />
      </div>
      <div slot="footer" :class="{'p-b-30 p-x-30': !isMobile, 'text-right': true}">
        <bb-button
          v-if="isMobile"
          v-bind="buttonProps"
          @click="onSubmit"
          display="block"
          size="lg"
          class="m-s-a"
          corner="sharp"
        />
        <bb-button
          v-else
          v-bind="buttonProps"
          @click="onSubmit"
          size="sm"
        />
      </div>
    </bb-modal>
    <signing-modal
      ref="signing"
      :contract="contract"
      :initMethod="onSignCardLimits"
      @signingSuccessful="onSigningSuccessful"
      @signingCancelled="onSigningModalClosed"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import '@bigbank/interface-components/dist/svg/illustration/cvv'
import CardTypeTranslationMixin from '../mixins/CardTypeTranslationMixin'
import { CardPeriodType, CardType, CardTransactionType } from '@bigbank/dc-common/clients/http/credit-card/credit-card.enums'
import LimitSliderItem from '../LimitSliderItem.vue'
import api from '@loan/api'
import SigningModal from '@/modules/loan/components/signing/SigningModal'
import { debounce, isNull } from 'lodash'
import { SectionNames } from '../credit-card.const'
import { useRootStore } from '../../../../store/root'
import { useLoanStore } from '@loan/store/loanStore'

export default {
  name: 'credit-card-limits-modal',
  mixins: [CardTypeTranslationMixin],
  props: {
    visible: { required: true, type: Boolean },
    cardId: { required: true, type: Number },
    card: { required: true, type: Object }
  },
  components: {
    LimitSliderItem,
    SigningModal
  },
  data () {
    return {
      CardPeriodType,
      CardTransactionType,
      isLoading: false,
      limitsConfiguration: null,
      sections: [],
      signingRequestId: null,
      isMonthlyLimitExceeded: false,
      newGlobalMonthlyLimit: null,
      currentGlobalMonthlyLimit: null,
      monthlyLimits: {
        canUseWithoutAdditionalLimits: true,
        remainingLimit: null
      }
    }
  },
  computed: {
    ...mapState(useRootStore, ['isMobile', 'featureFlags']),
    ...mapState(useLoanStore, ['creditCardContract']),
    contract () {
      return this.creditCardContract
    },
    modalTitle () {
      return this.translations.modalTitle[this.card.type]
    },
    translations () {
      return {
        modalTitle: {
          [CardType.PHYSICAL_AND_VIRTUAL]: this.$pgettext('credit_card_limit_changes', 'Change credit card limits'),
          [CardType.VIRTUAL]: this.$pgettext('credit_card_limit_changes', 'Change virtual card limits')
        },
        description: this.cardTypeLabel(this.card) + ' ' + this.card.maskedPan,
        signChanges: this.$pgettext('credit_card_limit_changes', 'Sign changes'),
        limitsUpdatedNotification: this.$pgettext('credit_card_limit_changes', 'You have signed limits change successfully!'),
        sections: {
          [CardTransactionType.GLOBAL]: this.$pgettext('credit_card_card_limits', 'Global limit'),
          [CardTransactionType.CASH]: this.$pgettext('credit_card_card_limits', 'Cash withdrawal'),
          [CardTransactionType.REGULAR]: this.$pgettext('credit_card_card_limits', 'Card Purchases'),
          [CardTransactionType.ONLINE]: this.$pgettext('credit_card_card_limits', 'Online Purchases'),
          [CardTransactionType.CONTACTLESS]: this.$pgettext('credit_card_card_limits', 'Contactless purchases')
        },
        activated: this.$pgettext('credit_card_limit_changes', 'Activated'),
        deactivated: this.$pgettext('credit_card_limit_changes', 'Deactivated'),
        monthly_limit_learn_more: this.$pgettext('account_verification', 'Learn more'),
        monthly_limit_warning_title: this.$pgettext('credit_card_limit_changes', 'Account monthly limit'),
        monthly_limit_warning_text: this.$gettextInterpolate(this.$pgettext(
          'credit_card_limit_changes',
          'In order to increase the global limit to %{amount}€, you need to verify your account. ' +
          'Your current account free limit is %{remainingLimit}€.'
        ), { amount: this.newGlobalMonthlyLimit ?? '', remainingLimit: this.monthlyLimits.remainingLimit ?? '' })
      }
    },
    learnMoreLinkProps () {
      return {
        to: { name: 'account/verification' },
        label: this.translations.monthly_limit_learn_more
      }
    },
    buttonProps () {
      const label = this.translations.signChanges
      const disabled = !this.isValid || this.isLoading
      const name = 'sign-changes-button'

      return {
        label,
        name,
        disabled,
        loading: this.isLoading
      }
    },
    isValid () {
      return this.isValuesChanged && this.isDailyLimitSameOrLargerThanMonthly
    },
    isValuesChanged () {
      return this.sections.reduce((hasChanged, section) => {
        return hasChanged ||
          section.periods.some(period => period.current !== period.value) ||
          section.isEnabled.oldValue !== section.isEnabled.newValue
      }, false)
    },
    isDailyLimitSameOrLargerThanMonthly () {
      return this.sections.every(section => {
        const daily = section.periods.find(period => period.periodType === CardPeriodType.DAY)
        const monthly = section.periods.find(period => period.periodType === CardPeriodType.MONTH)
        return daily.value <= monthly.value
      })
    },
    modalFlavor () {
      return this.isMobile ? 'card' : 'user'
    },
    isToggleStatusShown () {
      return !this.isMobile
    }
  },
  methods: {
    ...mapActions(useLoanStore, [
      'getAvailableMonthlyLimit',
      'refreshCreditCard'
    ]),
    setLoading (flag) { this.isLoading = flag },
    onModalClosed () {
      this.$emit('onModalClosed')
    },
    setSections () {
      this.sections = [
        CardTransactionType.GLOBAL,
        CardTransactionType.REGULAR,
        CardTransactionType.CASH,
        CardTransactionType.ONLINE,
        CardTransactionType.CONTACTLESS
      ]
        .filter(transactionType => this.card?.limits.find(limit => limit.transactionType === transactionType))
        .map(transactionType => {
          const periods = [
            CardPeriodType.DAY,
            CardPeriodType.MONTH
          ].map(periodType => {
            const configuration = (this.limitsConfiguration || []).find(limit => limit.transactionType === transactionType && limit.periodType === periodType)
            const currentLimit = this.card.limits.find(limit => limit.transactionType === transactionType && limit.periodType === periodType)
            const value = currentLimit?.limitAmount ?? 0

            const isGlobalLimit = transactionType === CardTransactionType.GLOBAL
            const globalLimitAmount = this.card.limits.find(limit => limit.transactionType === CardTransactionType.GLOBAL && limit.periodType === periodType).limitAmount
            const max = Math.min(configuration?.maxAmount ?? 0, globalLimitAmount)

            return {
              value,
              periodType,
              max: isGlobalLimit ? configuration?.maxAmount : max,
              min: configuration?.minAmount ?? 1,
              current: value
            }
          })

          const isEnabled = !!this.card.enabledLimits.find(limit => limit === transactionType)

          return {
            title: this.translations.sections[transactionType],
            name: SectionNames[transactionType],
            key: transactionType,
            periods,
            isEnabled: {
              oldValue: isEnabled,
              newValue: isEnabled
            }
          }
        })
    },
    setGlobalMonthlyLimits () {
      this.currentGlobalMonthlyLimit = this.card.limits.filter(item => {
        return item.transactionType === CardTransactionType.GLOBAL && item.periodType === CardPeriodType.MONTH
      })[0]?.availableAmount ?? null

      const limits = this.sections.filter(item => item.key === CardTransactionType.GLOBAL)
      this.newGlobalMonthlyLimit = limits[0]?.periods.find(limit => limit.periodType === CardPeriodType.MONTH)?.value ?? null
    },
    setMonthlyLimitExceeded () {
      if (!isNull(this.newGlobalMonthlyLimit) && !isNull(this.monthlyLimits.remainingLimit) && !isNull(this.currentGlobalMonthlyLimit)) {
        this.isMonthlyLimitExceeded = (this.newGlobalMonthlyLimit - this.currentGlobalMonthlyLimit) > this.monthlyLimits.remainingLimit
      }
    },
    scrollToModalTop () {
      document.getElementById('limits-modal').scrollTo({ left: 0, top: 0, behavior: 'smooth' })
    },
    async checkAvailableMonthlyLimit () {
      this.monthlyLimits = await this.getAvailableMonthlyLimit()
      if (this.monthlyLimits.canUseWithoutAdditionalLimits) {
        return
      }

      this.setGlobalMonthlyLimits()
      this.setMonthlyLimitExceeded()
    },
    async onSubmit () {
      this.setLoading(true)

      await this.checkAvailableMonthlyLimit()
      if (this.isMonthlyLimitExceeded) {
        this.setLoading(false)
        this.scrollToModalTop()
        return
      }

      try {
        this.$refs.signing.signButtonClick(new MouseEvent('click', {}))
      } catch {
        this.setLoading(false)
      }
    },
    async onSigningSuccessful () {
      this.$notify({ text: this.translations.limitsUpdatedNotification, duration: 10000 })
      await this.refreshCreditCard(this.cardId)
      this.$emit('onModalClosed', {
        refresh: true
      })
    },
    onSigningModalClosed () {
      // Nothing to do here
    },
    onSignCardLimits () {
      return api.initCreditCardLimitUpdates(this.cardId, this.sections.reduce((request, section) => {
        section.periods.forEach(period => {
          request.push({
            amount: period.value,
            transactionType: section.key,
            isEnabled: section.isEnabled.newValue,
            periodType: period.periodType
          })
        })
        return request
      }, []))
    },
    onLimitItemChange (period, section) {
      const isGlobalLimit = section.key === CardTransactionType.GLOBAL

      if (isGlobalLimit) {
        return this.debouncedUpdateLimits(period, section)
      }

      return this.updateLimits(period, section)
    },
    updateLimits (period, section) {
      const isGlobalLimit = section.key === CardTransactionType.GLOBAL

      if (isGlobalLimit) {
        this.sections
          .filter(sectionInner => sectionInner.key !== CardTransactionType.GLOBAL)
          .forEach(sectionInner => {
            const sectionWithSamePeriodType = sectionInner.periods
              .find(sectionPeriod => sectionPeriod.periodType === period.periodType)

            if (sectionWithSamePeriodType.value > period.value) {
              sectionWithSamePeriodType.value = period.value
            }

            this.$nextTick(() => {
              const configuration = (this.limitsConfiguration || [])
                .find(limit => limit.transactionType === sectionInner.key && limit.periodType === sectionWithSamePeriodType.periodType)
              sectionWithSamePeriodType.max = Math.min(period.value, configuration?.maxAmount ?? 0)
            })
          })
      }

      const daily = section.periods.find(period => period.periodType === CardPeriodType.DAY)
      const monthly = section.periods.find(period => period.periodType === CardPeriodType.MONTH)
      const isDailyMoreThanMonthly = daily && monthly && daily.value > monthly.value
      const isMonthlyLessThanDaily = daily && monthly && monthly.value < daily.value

      if (isDailyMoreThanMonthly && period.periodType === CardPeriodType.DAY) {
        monthly.value = daily.value
      } else if (isMonthlyLessThanDaily && period.periodType === CardPeriodType.MONTH) {
        daily.value = monthly.value
      }
    },
    onLimitSectionToggle (isEnabled, section) {
      if (section && !isEnabled) {
        section.periods = (section.periods || []).map(period => {
          return {
            ...period,
            value: period.current
          }
        })
      }
    },
    isCreditCardSpendingCategoryToggleable (sectionKey) {
      return this.featureFlags.enableCreditCardSpendingCategoriesToggling && sectionKey !== CardTransactionType.GLOBAL
    }
  },
  async mounted () {
    this.setLoading(true)
    this.limitsConfiguration = await api.getCreditCardLimitsConfiguration(this.cardId)
    this.setSections()
    this.setLoading(false)
  },
  created () {
    this.debouncedUpdateLimits = debounce((period, section) => {
      this.updateLimits(period, section)
    }, 200)
  }
}
</script>

<style lang="scss" scoped>
.credit-card-limits-modal {
  .modal-title {
    color: $blue;
    font-weight: 500;
    font-size: $font-size-small;
    font-family: $gotham-medium;
  }

  .modal-description {
    font-size: $font-size-small;
    color: $black;
  }

  .monthly_limit_warning_title {
    font-size: $font-size-smallest;
    text-transform: uppercase;
    padding-bottom: 5px;
  }

  .limit-section {
    text-align: left;

    &-title {
      font-family: $gotham-medium;
      font-size: $default-font-size;
      color: $gray;
    }

    &-top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .limit-items {
      display: flex;
      column-gap: 16px;
      flex-wrap: wrap;

      .item {
        box-sizing: border-box;
        flex: 300px;

        @media (max-width: $desktop-view-breaking-point) {
          &:nth-of-type(odd) {
            margin-bottom: 8px;
          }
        }
      }
    }
  }

  ::v-deep .bb-toggle {
    column-gap: 30px;
  }
}
</style>
