import dayjs from 'dayjs'
import { isNull } from 'lodash'
import isNumeric from 'fast-isnumeric'
import { InternalLoanType } from '@bigbank/dc-common/enums/productTypes'
import { TransactionAdvancedSearchOption } from '@bigbank/dc-common/enums/credit-card.enums'

export function transformFormDataIntoSearchRequestBody (filters, pageSize, pageNumber) {
  return {
    pageSize,
    pageNumber,
    reserved: false,
    startDate: dayjs(filters.startDate).format('YYYY-MM-DD'),
    endDate: dayjs(filters.endDate).format('YYYY-MM-DD'),
    type: Object.entries(filters.additionalOptionsCheckboxes).reduce((acc, [key, value]) => {
      return value ? acc.concat(key) : acc
    }, []),
    filters: JSON.parse(JSON.stringify(filters.advancedSearchFilters))
      .map((filter) => {
        // Temporary mapping. Will be removed with bb-fieldset-repeater reactivity fix
        if ([
          TransactionAdvancedSearchOption.Amount,
          TransactionAdvancedSearchOption.AmountGreaterThanEqualTo,
          TransactionAdvancedSearchOption.AmountLessThanEqualTo
        ].includes(filter.filterFunction)) {
          if (isNumeric(filter?.filterValue ?? '')) {
            filter.filterValue = Number(filter.filterValue)
            return filter
          }
          if (isNumeric((filter?.filterValue ?? '').replace(',', '.'))) {
            filter.filterValue = Number(filter.filterValue.replace(',', '.'))
            return filter
          }

          return null
        }

        return (filter?.filterValue ?? '').trim().length > 0 ? filter : null
      })
      .filter((filter) => !isNull(filter)),
    accountId: filters.accountId ?? undefined,
    cardId: filters.cardId || null, // can be empty string as well, hence the || instead of ??
    contractId: filters.contractId || undefined,
    period: filters.period,
    loanTypes: filters.accountId ? undefined : [InternalLoanType.LOAN]
  }
}
