<template>
  <bb-modal
    class="statement-of-fees-modal"
    visible
    @close="onClose"
    flavor="card"
    publicSize="m"
    fullMobile
  >
    <template #heading>
      <p class="color-blue">{{ translations.downloadTransactions }}</p>
    </template>
      <p class="f-align-center f-h4 m-t-50 color-gray">{{ description }}</p>
      <p class="f-align-center f-gotham-medium color-gray m-t-30">{{ subtitle }}</p>
    <template v-if="currentStep === steps.CONFIRM_ACCOUNT">
      <div class="m-t-15 m-b-35 f-align-center">
        <bb-link>{{ accountNumber }}</bb-link>
      </div>
    </template>
    <template v-else-if="currentStep === steps.SELECT_PERIOD">
      <div class="d-flex align-items-center">
        <bb-datepicker
          framed
          name="period-start-date"
          key="periodStartDate"
          type="date"
          :placeholder="translations.startDate"
          :shortcuts="false"
          :format="dateFormat"
          :minDate="systemMinDate"
          :maxDate="periodStartMaxDate"
          v-model="form.periodStartDate"
          :lang="datepickerLanguage"
        />
        <bb-separator width="2" class="m-x-15 m-t-10"/>
        <bb-datepicker
          framed
          name="period-end-date"
          key="periodEndDate"
          type="date"
          :placeholder="translations.endDate"
          :shortcuts="false"
          :format="dateFormat"
          :minDate="periodEndMinDate"
          :maxDate="today"
          v-model="form.periodEndDate"
          :lang="datepickerLanguage"
        />
      </div>
    </template>
    <div v-if="hasError" class="m-t-10 f-gotham-bold f-align-center f-smallest f-color-red">
      {{ translations.errorMessage }}
    </div>
    <template #footer>
      <bb-button
        display="block"
        :disabled="isButtonDisabled"
        @click="goToNextStep"
        corner="sharpMobileOnly"
        :name="footerButtonProps.name"
        :loading="isLoading"
      >{{ footerButtonProps.text }}</bb-button>
    </template>
  </bb-modal>
</template>

<script>
import dayjs from 'dayjs'
import { mapState } from 'pinia'
import { useRootStore } from '../../../store/root'
import api from '../api'
import { useLoanStore } from '@loan/store/loanStore'

export default {
  name: 'statement-of-fees-modal',
  data () {
    return {
      steps: {
        CONFIRM_ACCOUNT: 0,
        SELECT_PERIOD: 1,
        FEEDBACK: 2
      },
      form: {
        periodStartDate: null,
        periodEndDate: null
      },
      currentStep: 0,
      hasError: false,
      isLoading: false
    }
  },
  computed: {
    ...mapState(useRootStore, ['datepickerLanguage']),
    ...mapState(useLoanStore, ['contract']),
    translations () {
      return {
        downloadTransactions: this.$pgettext('credit_card_statement_of_fees_inquiry', 'Download transactions'),
        buttonText: {
          [this.steps.CONFIRM_ACCOUNT]: this.$pgettext('credit_card_statement_of_fees_inquiry', 'Next'),
          [this.steps.SELECT_PERIOD]: this.$pgettext('credit_card_statement_of_fees_inquiry', 'Send'),
          [this.steps.FEEDBACK]: this.$pgettext('credit_card_statement_of_fees_inquiry', 'Return to contract')
        },
        description: {
          [this.steps.CONFIRM_ACCOUNT]: this.$pgettext('credit_card_statement_of_fees_inquiry', 'Document will provide a thorough overview of the fees paid during selected period'),
          [this.steps.SELECT_PERIOD]: this.$pgettext('credit_card_statement_of_fees_inquiry', 'Send inquiry about the fees paid'),
          [this.steps.FEEDBACK]: this.$pgettext('credit_card_statement_of_fees_inquiry', 'We will contact you as soon as possible but no later than in 5 business days')
        },
        subtitle: {
          [this.steps.CONFIRM_ACCOUNT]: this.$pgettext('credit_card_statement_of_fees_inquiry', 'Your account'),
          [this.steps.SELECT_PERIOD]: this.$pgettext('credit_card_statement_of_fees_inquiry', 'Choose the period')
        },
        startDate: this.$pgettext('credit_card_statement_of_fees_inquiry', 'Start date'),
        endDate: this.$pgettext('credit_card_statement_of_fees_inquiry', 'End date'),
        errorMessage: this.$pgettext('error', 'Something appears to have gone wrong. Please try again later.')
      }
    },
    accountNumber () {
      return this.contract?.iban ?? null
    },
    contractId () {
      return this.contract?.id ?? null
    },
    description () {
      return this.translations.description[this.currentStep]
    },
    subtitle () {
      return this.translations.subtitle[this.currentStep]
    },
    footerButtonProps () {
      const buttonNames = {
        [this.steps.CONFIRM_ACCOUNT]: 'next-button',
        [this.steps.SELECT_PERIOD]: 'send-button',
        [this.steps.FEEDBACK]: 'return-to-contract-button'
      }

      return {
        text: this.translations.buttonText[this.currentStep],
        name: buttonNames[this.currentStep]
      }
    },
    dateFormat () {
      return 'DD.MM.YYYY'
    },
    today () {
      return dayjs().toDate()
    },
    systemMinDate () {
      return dayjs('2022-06-01').toDate()
    },
    periodStartMaxDate () {
      return this.form.periodEndDate ? dayjs(this.form.periodEndDate).toDate() : this.today
    },
    periodEndMinDate () {
      return this.form.periodStartDate ? dayjs(this.form.periodStartDate).toDate() : this.systemMinDate
    },
    isButtonDisabled () {
      return (this.currentStep === this.steps.SELECT_PERIOD && [this.form.periodStartDate, this.form.periodEndDate].includes(null)) || this.isLoading
    }
  },
  methods: {
    setHasError (hasError) { this.hasError = hasError },
    setIsLoading (isLoading) { this.isLoading = isLoading },
    setCurrentStep (step) { this.currentStep = step },
    onClose () {
      this.$emit('close')
    },
    async goToNextStep () {
      try {
        this.setHasError(false)
        this.setIsLoading(true)
        if (this.currentStep === this.steps.CONFIRM_ACCOUNT) {
          this.setCurrentStep(this.steps.SELECT_PERIOD)
        } else if (this.currentStep === this.steps.SELECT_PERIOD) {
          await api.sendInquiry(this.contractId, {
            periodStartDate: dayjs(this.form.periodStartDate).format('YYYY-MM-DD'),
            periodEndDate: dayjs(this.form.periodEndDate).format('YYYY-MM-DD'),
            accountNumber: this.accountNumber
          })
          this.setCurrentStep(this.steps.FEEDBACK)
        } else if (this.currentStep === this.steps.FEEDBACK) {
          this.onClose()
        }
      } catch (err) {
        console.error(err)
        this.setHasError(true)
      } finally {
        this.setIsLoading(false)
      }
    }
  }
}
</script>
<style lang="scss">
.statement-of-fees-modal {
  .bb-modal__body {
    @media (min-width: $desktop-view-breaking-point) {
      min-height: rem(330px);
      max-height: rem(330px);
    }
  }

  .bb-separator {
    width: 15px;
  }
}
</style>
