<template>
  <nav
    ref="nav"
    class="custom-hidden-desktop-wide overlaid-navbar"
    :class="'bg-' + color"
    v-show="visible">
      <bb-button display="link" noUnderline class="overlaid-navbar__menu-button" @click="toggleSideMenu">
        <bb-icon name="ui-menu" size="fluid" fill="white"></bb-icon>
      </bb-button>
      <div class="overlaid-navbar__content">
        <slot></slot>
      </div>
    </nav>
</template>

<script>
import '@bigbank/interface-components/dist/svg/ui/menu'
import { mapActions } from 'pinia'
import { useRootStore } from '../store/root'

export default {
  name: 'sticky-navbar',
  data () {
    return {}
  },
  props: {
    visible: {
      default: false
    },
    color: {
      default: 'green-90'
    }
  },
  methods: {
    ...mapActions(useRootStore, ['toggleSideMenu'])
  }
}
</script>

<style lang="scss">
.overlaid-navbar {
  display: flex;
  font-size: $tiniest-font-size;
  font-family: $gotham-medium;
  z-index: 1500;
  width: 100%;
  top: 0;
  color: $white;
  padding: 0 15px;
  max-height: rem(50px);
  position: fixed;
  height: 50px;

  &__menu-button {
    width: 24px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: auto;
  }
}
</style>
