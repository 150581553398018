export const mapContract = (data) => {
  const { statusCode } = data
  delete data.statusCode
  return Object.assign({}, data, {
    id: data?.id?.toString(),
    statusCode,
    contractStatus: convertContractStatus(statusCode)
  })
}

function convertContractStatus (systemStatus) {
  if (!systemStatus) {
    systemStatus = ''
  }

  switch (systemStatus.toLowerCase()) {
    case 'active':
      return 'on track'
    case 'closed':
      return 'closed'
    default:
      return 'on track'
  }
}
