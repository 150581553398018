<template>
  <layout v-bind="layoutProps" v-on="$listeners">
    <div slot="title">
      <bb-icon name="ui-check-circle" size="64" fill="green" />
      <h4 class="f-gotham-black f-color-navy m-t-30">{{translations.title}}</h4>
      <div class="f-gotham-book f-small f-color-gray-70 m-t-30">{{translations.text}}</div>
    </div>
    <bb-ticket-stub slot="footer" class="m-t-15">
      <bb-button display="ticket" color="white" @click="onClick">
        <span class="f-color-green">
          <translate>Got it</translate>
        </span>
      </bb-button>
    </bb-ticket-stub>
  </layout>
</template>

<script>
import Layout from './Layout.vue'
import '@bigbank/interface-components/dist/svg/ui/check-circle'

export default {
  components: {
    Layout
  },
  name: 'manage-loan-thank-you',
  data () {
    return {}
  },
  computed: {
    translations () {
      return {
        title: this.$pgettext('manage_loan', 'Thank You for applying for the contract change!'),
        text: this.$pgettext('manage_loan', 'We will get in touch with you soon!')
      }
    },
    layoutProps () {
      return {
        visible: true,
        disableSubmitButton: true,
        data: {}
      }
    }
  },
  methods: {
    onClick () {
      this.$emit('close')
    }
  }
}
</script>
