<script>
import api from '@account/api'
import SignWithIdCardLoan from '@/modules/loan/components/signing/SignWithIdCard'
import { AuthMethod } from '@bigbank/dc-common/config'

export default {
  name: 'id-card-signing-account',
  extends: SignWithIdCardLoan,
  props: {
    contract: {
      required: false
    },
    signingRequestId: {
      required: true
    }
  },
  methods: {
    startSigning (certificateBase64, supportedSignatureAlgorithms) {
      return api.initSigning(AuthMethod.ID_CARD, this.signingRequestId, { certificateBase64, supportedSignatureAlgorithms })
    },
    completeSigning (signatureBase64) {
      return api.completeSigning(AuthMethod.ID_CARD, this.signingRequestId, { signatureBase64 })
    }
  }
}
</script>
