<template>
  <layout-with-navbar :login="loginButton">
    <bb-error-page v-bind="login" class="p-l-20 p-r-20"></bb-error-page>
  </layout-with-navbar>
</template>

<script>
import '@bigbank/interface-components/dist/svg/illustration/no-account'
import { mapState } from 'pinia'
import { useRootStore } from '../store/root'

export default {
  name: 'landing-page',
  computed: {
    ...mapState(useRootStore, ['loginUrl']),
    login () {
      return {
        icon: 'illustration-no-account',
        title: this.$gettext('Welcome back!'),
        description: this.$gettext('Have an application with us? Take a look at your progress by logging in.'),
        buttonText: this.$gettext('Login'),
        buttonPath: this.loginUrl,
        linkText: this.$gettext('Back to Home'),
        linkPath: '/home'
      }
    },
    loginButton () {
      return {
        label: this.$gettext('Login'),
        to: this.loginUrl
      }
    }
  }
}
</script>
