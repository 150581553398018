import { RouteNames as LoanRouteName } from '@loan/views/const'
import { DepositRouteName } from '@deposits/const'
import { CreditCardRouteName } from '@credit-card/views/const'

export const LandingPageRouteNames = [
  LoanRouteName.ACTIVE_LOANS,
  LoanRouteName.REVOLVING_LOAN,
  CreditCardRouteName.CreditCard,
  DepositRouteName.DemandDeposits,
  DepositRouteName.TermDeposits
]
