<template>
  <div class="limits" :name="isCreditCardVirtual ? 'limits-virtual' : 'limits-physical'">
    <bb-accordion name="card-limits-accordion" :value="accordions" card>
      <template slot="accordion">
        <template v-if="isMobile">
          <bb-button
            v-if="showChangeLimitsButton"
            v-bind="editButtonProps"
            @click="changeLimits"
            class="m-b-20 f-tiny"
          />
        </template>
        <div class="limits-content__container">
          <limits-content :card="card" global />
          <limits-content :card="card" global month />
        </div>
        <bb-separator dashed class="limits-content__container-separator" v-if="!isCreditCardVirtual" />
        <div class="limits-content__container">
          <limits-content :card="card" />
          <limits-content :card="card" month />
        </div>
      </template>
      <template slot="accordion-after" v-if="!isMobile">
        <bb-button
          v-if="showChangeLimitsButton"
          v-bind="editButtonProps"
          @click.stop="changeLimits"
          class="f-tiny"
        />
      </template>
    </bb-accordion>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import LimitsContent from './LimitsContent.vue'
import { CardType, CardPeriodType, CardTransactionType } from '@bigbank/dc-common/clients/http/credit-card/credit-card.enums'
import { useRootStore } from '../../../store/root'
import { useLoanStore } from '@loan/store/loanStore'

export default {
  components: {
    LimitsContent
  },
  data () {
    return {
      CardPeriodType,
      CardTransactionType
    }
  },
  props: {
    card: {
      required: true
    }
  },
  computed: {
    ...mapState(useRootStore, ['isMobile']),
    ...mapState(useLoanStore, ['featureFlags']),
    translations () {
      return {
        title: this.$pgettext('credit_card_card_limits', 'Card limits'),
        changeLimits: this.$pgettext('credit_card_card_limits', 'View/change spending limits')
      }
    },
    accordions () {
      return [{
        id: 'accordion',
        title: this.translations.title
      }]
    },
    showChangeLimitsButton () {
      return this.featureFlags.enableCreditCardEditLimits
    },
    isCreditCardVirtual () {
      return this.card.type === CardType.VIRTUAL
    },
    editButtonProps () {
      return {
        name: 'edit-limits-button',
        inverted: true,
        display: 'block',
        label: this.translations.changeLimits,
        color: 'gray'
      }
    }
  },
  methods: {
    changeLimits () {
      this.$emit('onEditCardLimitsClick')
    }
  }
}
</script>

<style lang="scss">
.limits {
  font-size: $font-size-small;

  .bb-accordion-item--card {
    background: $white;
  }

  .bb-accordion-item__header-title,
  &__title {
    color: $gray;
    font-family: $gotham-medium;
    font-size: $default-font-size;
  }

  &__title {
    margin-bottom: 10px;
  }

  .button-col {
    justify-content: flex-end;
    display: flex;
  }
}

.limits-content__container {
  display: flex;
  column-gap: 32px;

  @media (max-width: $desktop-view-breaking-point) {
    column-gap: 16px;
  }

  &-separator {
    margin: 8px 0 20px;

    @media (max-width: $desktop-view-breaking-point) {
      margin: 0 0 16px;
    }
  }
}
</style>
