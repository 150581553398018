export default {
  prolongationStepOne: 'prolongation-step-one',
  prolongationStepTwo: 'prolongation-step-two',
  differentPlan: 'different-plan',
  offerPage: 'offer-page',
  applicationConfirmation: 'application-confirmation',
  confirmMoneyDetails: 'confirm-money-details',
  confirmMoneyDetailsAdditionalDocuments: 'confirm-money-details-additional-documents',
  contractConditions: 'contract-conditions',
  chooseProduct: 'choose-product'
}
