<template>
  <bb-box thin class="box-with-icon text-center">
    <div v-if="icon" class="box-with-icon__icon">
      <bb-icon fill="original" size="fluid" :name="icon"></bb-icon>
    </div>
    <slot />
  </bb-box>
</template>

<script>
export default {
  name: 'box-with-icon',
  props: {
    icon: {
      default: null
    }
  }
}
</script>

<style lang="scss">
.box-with-icon {
  padding: 25px;
  padding-top: 40px;
  padding-bottom: 40px;
  font-size: $font-size-small;
  border-style: dashed !important;
  border-color: $gray-50 !important;
  color: $gray-80;
  border-radius: 10px !important;

  &__icon {
    margin: auto;
    width: 83px;
    height: 67px;
    margin-bottom: 20px;
  }
}
</style>
