<template>
  <layout-with-menu>
    <bb-loading-overlay color="dark" :is-loading="showLoader" />
    <bb-modal
      :visible="!showLoader"
      @close="showDeclinePrompt"
      flavor="card"
      publicSize="l"
      fullMobile
      centerVertically
      data-testid="terms-modal">
        <template #heading>
          <LanguageSwitcher v-if="featureFlags.enableTCLanguageSwitcher" />
        </template>
        <div class="terms">
          <div class="terms__content">
            <div class="terms__date-container">
              <translate>Last updated:</translate> {{ formattedLastUpdatedDate }}
            </div>
            <div class="icon-container">
              <bb-icon name="illustration-contract" size="fluid" fill="green" />
            </div>
            <div class="contract-title">
              <template v-if="termsAndConditions.title">{{ termsAndConditions.title }}</template>
              <translate v-else>Self Service Platform Contract</translate>
            </div>
            <div class="chapters-container m-b-15 m-r-10 p-t-15">
              <div v-for="(chapter, index) in termsAndConditions.chapters" v-bind:key="index" class="chapter">
                <div class="chapter-flex-container p-t-15">
                  <div @click="expand(index)" class="chapter-title">{{ chapter.title }}</div>
                  <div @click="expand(index)" class="chapter-btn">
                    <bb-icon name="ui-menu-down" :rotate="!isOpen(index) ? 'up' : 'down'" size="fluid" />
                  </div>
                </div>
                <div v-show="isOpen(index)" class="chapter-content hide" :id="`terms-content-chapter-${index}`">
                  <div v-sanitize.basic="addLinkTags(paragraph)" class="chapter-details"
                    v-for="(paragraph, index) in chapter.subtext" v-bind:key="index">
                  </div>
                </div>
              </div>
            </div>
            <div v-if="termsAndConditions.footer" class="contract-footer p-y-20 p-x-20">
              {{ termsAndConditions.footer }}
            </div>
          </div>
        </div>
        <div class="terms__button-container" slot="footer">
          <bb-button @click="showDeclinePrompt" color="gray" uppercase inverted display="block"
            class="terms__button-small-padding my-hidden-mobile">
            <div class="btn-label">
              <translate>Decline</translate>
            </div>
          </bb-button>
          <bb-button
            uppercase
            display="block"
            class="terms__button-small-padding"
            @click="onTermsAccepted"
            data-testid="accept-terms-button">
              <div class="btn-label">
                <translate>Accept and continue</translate>
              </div>
          </bb-button>
          <bb-button
            @click="showDeclinePrompt"
            color="gray"
            display="link"
            noUnderline
            data-testid="decline-terms-button">
              <div class="btn-label terms__button-decline my-hidden-desktop">
                <translate>Decline terms</translate>
              </div>
          </bb-button>
        </div>
        <bb-modal
          :visible="showPrompt"
          @close="showPrompt = false"
          flavor="user"
          publicSize="m"
          color="white"
          centerVertically>
            <div class="terms__decline-modal-wrapper">
              <div class="sad-face-wrapper">
                <div class="sad-face-container">
                  <bb-icon name="client-face-sad" size="fluid" fill="yellow-30" />
                </div>
              </div>
              <p class="decline-modal-title m-t-20">
                <translate>Are you sure you want to decline?</translate>
              </p>
              <p class="decline-modal-description m-t-20">
                <translate>By declining you won't be able to lorem ipsum lorem lorem.</translate>
              </p>
            </div>
            <div class="terms__decline-buttons-container" slot="footer">
              <bb-button @click="showPrompt = false" color="blue" inverted uppercase class="terms__decline-button-padding">
                <div class="btn-label">
                  <translate>Cancel</translate>
                </div>
              </bb-button>
              <bb-button @click="declineTerms" uppercase class="terms__decline-button-padding">
                <div class="btn-label">
                  <translate>Decline</translate>
                </div>
              </bb-button>
            </div>
        </bb-modal>
    </bb-modal>
  </layout-with-menu>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { TERMS_AND_CONDITIONS } from '@/TrackingActions'
import '@bigbank/interface-components/dist/svg/illustration/contract'
import '@bigbank/interface-components/dist/svg/client/face-sad'
import '@bigbank/interface-components/dist/svg/ui/menu-down'
import LogoutReason from '@/types/logout-reason'
import { request } from '@/plugins/utils'
import LanguageSwitcher from './../components/LanguageSwitcher.vue'
import { useRootStore } from '../store/root'

const markdownLinkRegex = /\[(?<text>.*)\]\((?<href>.*)\)/
export default {
  name: 'terms-and-conditions',
  data () {
    return {
      showPrompt: false,
      expanded: false,
      openChapters: [],
      termsAndConditions: {
        lastUpdated: null,
        chapters: [],
        title: null,
        footer: null
      },
      loading: true
    }
  },
  components: {
    LanguageSwitcher
  },
  created () {
    this.getTerms()
      .then(terms => {
        this.setLoading(false)
        this.termsAndConditions = terms
      })
  },
  methods: {
    ...mapActions(useRootStore, ['getTerms', 'updateTermsState']),
    setLoading (flag) { this.loading = flag },
    expand (index) {
      const open = this.isOpen(index)
      if (open) {
        this.openChapters = this.openChapters.filter(c => c !== index)
      } else {
        this.openChapters.push(index)
        const element = document.getElementById(`terms-content-chapter-${index}`)
        setTimeout(() => {
          element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
        }, 50)
      }
    },

    async onTermsAccepted () {
      this.setLoading(true)

      try {
        await request('/account/api/terms/accept', {
          method: 'post',
          errHandlerOpts: {
            handleOnlyKnownErrors: true
          }
        })

        this.updateTermsState(true)
        this.$tracker.action(TERMS_AND_CONDITIONS.ACCEPT_TERMS)
        this.$router.push(this.$router.currentRoute.query?.redirect ?? '/')
      } catch (err) {
        console.error('Unable to accept terms. Error: ', err)
        this.$apm.captureError(err)
        this.$router.push('/oops/500')
      }
    },
    isOpen (index) {
      return this.openChapters.includes(index)
    },
    logout () {
      this.$router.push({ path: '/logout', query: { reason: LogoutReason.TERMS_AND_CONDITIONS_LOGOUT_CLICK } })
    },
    showDeclinePrompt () {
      this.showPrompt = true
    },
    declineTerms () {
      this.$tracker.action(TERMS_AND_CONDITIONS.DECLINE_TERMS)
      this.showPrompt = false
      this.logout()
    },
    addLinkTags (paragraph) {
      let link, href
      const div = document.createElement('div')
      const arr = paragraph.split(' ')
      arr.forEach(word => {
        link = null
        if (word.substring(0, 3) === 'www') {
          link = document.createElement('a')
          href = word.replace(/[^a-zA-Z!.]/g, '')
          if (href.slice(-1) === '.') {
            href = href.replace(/.$/, '')
          }
          link.href = 'https://' + href
          link.target = '_blank'
          link.innerHTML = word
        } else if (word.match(markdownLinkRegex)) {
          link = document.createElement('a')
          const groups = markdownLinkRegex.exec(word).groups
          link.href = 'https://' + groups.href
          link.target = '_blank'
          link.innerHTML = groups.text
        }
        if (link) {
          div.innerHTML += ' '
          div.appendChild(link)
        } else {
          div.innerHTML += ' ' + word
        }
      })
      return div.innerHTML
    }
  },
  computed: {
    ...mapState(useRootStore, ['areTermsAccepted', 'featureFlags']),
    showLoader () {
      return this.loading || this.areTermsAccepted
    },
    formattedLastUpdatedDate () {
      return this.termsAndConditions.lastUpdated
    }
  }
}
</script>
<style lang="scss">
  .center-spinner {
    * {
      margin: auto;
    }

    margin-bottom: 20px;
  }

  .layout-with-menu .terms {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__date-container {
      font-size: $font-size-smallest;
      color: $gray-70;
      text-align: center;
    }

    &__button-container {
      display: flex;
      justify-content: space-between;
      padding: 20px;

      @media (max-width: $mobile-view-breaking-point) {
        flex-direction: column;
        padding: 15px 30px 0;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      -ms-overflow-style: -ms-autohiding-scrollbar;

      .chapter-details {
        color: $gray;
        font-size: $font-size-smallest;
        font-family: $gotham-book;
        overflow: hidden;
      }

      .icon-container {
        margin: 0 auto;
        max-width: 180px;
      }

      .contract-title {
        color: $gray;
        font-size: $h4-size;
        text-align: center;
        font-family: $gotham-book;
      }

      .contract-footer {
        color: $gray;
        background-color: $gray-10;
        font-size: $font-size-default-mobile;
      }

      .chapters-container {
        font-size: $font-size-smallest;
        font-family: $gotham-medium;

        .chapter-flex-container {
          display: flex;
          flex-direction: row;
        }

        .chapter-title {
          color: $gray;
          cursor: pointer;
          flex: 1 0 0;
          text-transform: none;
        }

        .chapter-btn {
          color: $gray-70;
          cursor: pointer;
          flex: 0 0 32px;
          height: 32px;
        }
      }
    }

    &__button-decline {
      text-decoration: underline;

      @media (max-width: $mobile-view-breaking-point) {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 15px;
        padding-bottom: 16px;
      }
    }

    &__button-small-padding {
      padding-right: 5px;
      padding-left: 5px;

      &:first-child {
        margin-right: 20px;
      }
    }

    &__decline-modal-wrapper {
      margin-top: 75px;
      padding-bottom: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      @media (max-height: $mobile-view-breaking-point) {
        padding-right: 0;
        padding-left: 0;
      }

      .sad-face-wrapper {
        min-width: 78px;
        min-height: 78px;
        background: $white;
        margin-top: -69px;
        border-radius: 100%;
        display: flex;
        justify-content: center;

        .sad-face-container {
          max-width: 54px;
          max-height: 54px;
          align-self: center;
        }
      }

      .decline-modal-title {
        color: $blue;
        font-family: $gotham-medium;
        font-size: $font-size-small;
        text-align: center;
      }

      .decline-modal-description {
        color: $gray;
        font-size: $font-size-smallest;
        text-align: center;
      }
    }

    &__decline-buttons-container {
      display: flex;
      justify-content: center;
      padding-right: 30px;
      padding-left: 30px;
      padding-bottom: 30px;

      @media (max-width: $mobile-view-breaking-point) {
        padding-right: 5%;
        padding-left: 5%;
      }
    }

    &__decline-button-padding {
      padding-right: 45px;
      padding-left: 45px;

      &:first-child {
        margin-right: 40px;

        @media (max-width: $mobile-view-breaking-point) {
          margin-right: 1rem;
        }
      }

      @media (max-width: 425px) {
        padding-right: 2rem;
        padding-left: 2rem;
        width: 45%;
      }
    }
  }

  .my-hidden-mobile {
    @media (max-width: $mobile-view-breaking-point) {
      display: none !important;
    }
  }

  .my-hidden-desktop {
    @media (min-width: $mobile-view-breaking-point + 1) {
      display: none !important;
    }
  }
</style>
