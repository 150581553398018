<template>
  <layout-with-menu :loading="loading">
    <div class="revolving-loan">
      <hero-header v-bind="heroHeader" />
      <div class="revolving-loan__content">
        <div class="revolving-loan__content__cards">
          <div>
            <bb-card-button-group :buttons="cardButtons" @click="cardButtonClick" class="revolving-loan__content__buttons" />
          </div>
          <revolving-loan-card
            v-if="hasRevolvingLoan"
            :contract="revolvingLoan"
            :currency="revolvingLoan.currency"
            class="revolving-loan__content__card"
          />
          <multi-card
            v-else
            v-for="(card, index) in cardList"
            :key="card.id"
            :currency="card.currency"
            :card="card"
            :cardType="card.type"
            :index="index"
            :count="cardList.length"
          />
          <campaign-card :campaigns="campaignCardList" />
          <div v-if="hasRevolvingLoan" class="revolving-loan__content__transactions">
            <loan-transactions />
          </div>
        </div>
      </div>
    </div>
    <router-view />
    <campaign-modal
      :visible="popupCampaignsVisible"
      :campaigns="popupCampaigns"
      @close="closeCampaignModal"
    />
  </layout-with-menu>
</template>

<script>
import HeroHeader from '@loan/components/HeroHeader'
import { mapState, mapActions } from 'pinia'
import { formatMoneyWithCurrency } from '@/plugins/numformat'
import RevolvingLoanCard from '../components/cards/RevolvingLoanCard'
import LoanTransactions from './revolving/Transactions'
import { translationPicker } from '@/plugins/translations'
import { REVOLVING_DASHBOARD } from '@/TrackingActions'
import MultiCard from '../components/MultiCard'
import CampaignModal from '@/components/CampaignModal'
import CampaignCard from '@/components/cards/CampaignCard'
import CARD_TYPE from '@/components/cards/cardTypes'
import CampaignModalMixin from '../../../mixins/campaignModalMixin'
import CampaignCardsMixin from '../../../mixins/campaignCardsMixin'
import { useRootStore } from '../../../store/root'

import '@bigbank/interface-components/dist/svg/ui/arrow-top-right'
import '@bigbank/interface-components/dist/svg/ui/export'
import '@bigbank/interface-components/dist/svg/ui/plus'
import isUndefined from 'lodash/isUndefined'
import { InternalLoanType } from '@bigbank/dc-common/enums/productTypes'
import { useLoanStore } from '@loan/store/loanStore'

export default {
  name: 'revolving-loan-view',
  components: {
    LoanTransactions,
    HeroHeader,
    RevolvingLoanCard,
    MultiCard,
    CampaignModal,
    CampaignCard
  },
  mixins: [
    CampaignModalMixin,
    CampaignCardsMixin
  ],
  computed: {
    ...mapState(useRootStore, ['channelConfig', 'locale', 'isMobile', 'language']),
    ...mapState(useRootStore, {
      globalFeatureFlags: 'featureFlags'
    }),
    ...mapState(useLoanStore, [
      'featureFlags',
      'loading',
      'applications',
      'loanTypes',
      'upsell',
      'revolvingLoan'
    ]),
    hasRevolvingLoan () {
      return !!this.revolvingLoan
    },
    contracts () {
      const contracts = []
      if (this.revolvingLoan) {
        contracts.push(this.revolvingLoan)
      }
      return contracts
    },
    offer () {
      return {
        type: CARD_TYPE.OFFER,
        title: this.$pgettext('offer_card_revolving_loan', 'Great to have you back.'),
        description: this.$pgettext('offer_card_revolving_loan', 'You don\'t have revolving loan yet, but please check out our offers.'),
        buttonText: this.$pgettext('offer_card_revolving_loan', 'Apply for revolving loan'),
        to: isUndefined(this.channelConfig.applyLoanUrl) ? '/' : translationPicker(this.channelConfig.applyLoanUrl[InternalLoanType.REVOLVING], this.language),
        trackAction: REVOLVING_DASHBOARD.REVOLVING_PROCEED_TO_APPLICATION
      }
    },
    currency () {
      return (this.revolvingLoan && this.revolvingLoan.currency) || ''
    },
    cardButtons () {
      return [
        {
          id: 'pay-back',
          label: this.$pgettext('revolving_loan_button', 'Pay Back'),
          icon: 'ui-arrow-top-right',
          to: '/my-revolving-loan/pay-back',
          disabled: !this.showPayBack
        },
        {
          id: 'transfer',
          label: this.$pgettext('revolving_loan_button', 'Transfer to IBAN'),
          icon: 'ui-export',
          to: '/my-revolving-loan/transfer',
          disabled: !this.showTransfer
        },
        {
          id: 'increase-limit',
          label: this.$pgettext('revolving_loan_button', 'Increase my Limit'),
          icon: 'ui-plus',
          to: '/my-revolving-loan/increase-limit',
          disabled: !this.isIncreaseMyLimitButtonEnabled
        }
      ]
    },
    showPayBack () {
      return this.revolvingLoan && this.revolvingLoan.outstandingAmount > 0
    },
    showTransfer () {
      return this.revolvingLoan && this.revolvingLoan.availableLimit > 0
    },
    isIncreaseMyLimitButtonEnabled () {
      return this.revolvingLoan && this.upsell && this.upsell.segment === 4
    },
    heroHeader () {
      const availableLimit = (this.revolvingLoan || {}).availableLimit || 0
      const limitAmount = (this.revolvingLoan || {}).limitAmount || 0
      const availableLimitPercent = 100 - Math.min(Math.max(Math.round(availableLimit / limitAmount * 100), 0), 100)

      return {
        contracts: this.contracts,
        currency: this.currency,
        top: {
          value: availableLimit,
          progress: availableLimitPercent,
          label: this.$pgettext('revolving_loan_hero_header', 'Available Limit')
        },
        rightSide: {
          value: formatMoneyWithCurrency((this.revolvingLoan || {}).outstandingAmount, this.currency, this.locale),
          label: this.$pgettext('revolving_loan_hero_header', 'Outstanding Balance')
        }
      }
    },
    revolvingApplications () {
      const applications = Array.isArray(this.applications) ? this.applications : []

      return applications.filter(application => {
        application.type = CARD_TYPE.APPLICATION
        return application.typeCode === this.loanTypes.REVOLVING
      })
    },
    cardList () {
      let list = []

      if (!this.loading) {
        list = [
          ...this.revolvingApplications
        ]
        if (!list.length && !this.globalFeatureFlags.disableApplyForALoan) {
          list.push(this.offer)
        }
      }

      return list
    },
    campaignCardList () {
      if (this.campaignsForCards[0] && Array.isArray(this.campaignsForCards[0].campaigns)) {
        return [...this.campaignsForCards[0].campaigns]
      }

      return []
    }
  },
  methods: {
    ...mapActions(useLoanStore, ['init']),
    trackViewActions () {
      if (!this.hasRevolvingLoan) {
        this.$tracker.action(REVOLVING_DASHBOARD.REVOLVING_EMPTY_VIEW)
      }
    },
    cardButtonClick (event) {
      switch (event) {
        case 'pay-back':
          this.$tracker.action(
            REVOLVING_DASHBOARD.REVOLVING_CLICK_PAY_BACK,
            {}, { contractId: this.revolvingLoan.id.toString() }
          )
          break
        case 'transfer':
          this.$tracker.action(
            REVOLVING_DASHBOARD.REVOLVING_CLICK_TRANSFER_TO_IBAN,
            {}, { contractId: this.revolvingLoan.id.toString() }
          )
          break
        case 'increase-limit':
          this.$tracker.action(
            REVOLVING_DASHBOARD.REVOLVING_CLICK_INCREASE_LIMIT,
            {}, { contractId: this.revolvingLoan.id.toString() }
          )
          break
      }
    }
  },
  async mounted () {
    await this.init()
    this.trackViewActions()
  }
}
</script>

<style lang="scss" scoped>
.revolving-loan {
  &__content {
    margin: auto;
    width: 90%;
    max-width: 850px;

    &__menu {
      @media (min-width: $desktop-view-breaking-point) {
        margin-top: 20px;
        margin-bottom: 35px;
      }
    }

    &__buttons {
      margin-bottom: 10px;

      @media (min-width: $desktop-view-breaking-point + 1) {
        margin-bottom: 10px;
      }
    }

    &__cards {
      margin-top: -45px;

      &.empty {
        margin-top: 30px;
      }
    }

    &__card {
      margin-top: 15px;
    }

    &__transactions {
      margin-top: 35px;

      @media (min-width: $desktop-view-breaking-point + 1) {
        margin-top: 20px;
      }
    }
  }
}
</style>
