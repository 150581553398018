<template>
  <div>
    <div class="password-strength">
      <bb-progress-bar :max="1" :value="passwordStrength" :color="passwordColor"/>
      <div class="password-strength__text" :class="'color-'+passwordColor">{{passwordLevel}}</div>
    </div>
    <ul>
      <li>
        <bb-icon class="checkmark" v-if="hasUpperCase" name="ui-check-circle-outline" fill="green"></bb-icon>
        <div v-else class="button-dot"></div>
        <translate>contain uppercase letter.</translate>
      </li>
      <li>
        <bb-icon class="checkmark" v-if="hasLowerCase" name="ui-check-circle-outline" fill="green"></bb-icon>
        <div v-else class="button-dot"></div>
        <translate>contain lowercase letter.</translate>
      </li>
      <li>
        <bb-icon class="checkmark" v-if="hasSymbols" name="ui-check-circle-outline" fill="green"></bb-icon>
        <div v-else class="button-dot"></div>
        <translate>contains digits and/or symbols.</translate>
      </li>
       <li>
        <bb-icon class="checkmark" v-if="isSufficientLength" name="ui-check-circle-outline" fill="green"></bb-icon>
        <div v-else class="button-dot"></div>
        <translate :translate-params="{length: minPasswordLength}">at least %{length} characters long.</translate>
      </li>
    </ul>
  </div>
</template>

<script>
import '@bigbank/interface-components/dist/svg/ui/check-circle-outline'
import { BbProgressBar } from '@bigbank/interface-components'

const MIN_COMPLEX_PASSWORD_LENGTH = 8
const MIN_LONG_PASSWORD_LENGTH = 16

export default {
  props: {
    password: {
      default: ''
    },
    value: {
      default: 0
    },
    errorCode: {
      default: null
    }
  },
  components: {
    BbProgressBar
  },
  computed: {
    hasUpperCase () {
      return this.password.toLowerCase() !== this.password
    },
    hasLowerCase () {
      return this.password.toUpperCase() !== this.password
    },
    hasSymbols () {
      return !!this.password.match(/[^a-zäöüõ]/i)
    },
    isSufficientLength () {
      return this.password.length >= this.minPasswordLength
    },
    requiresLongPassword () {
      return this.password.length > 0 && (!this.hasUpperCase || !this.hasLowerCase)
    },
    minPasswordLength () {
      return this.requiresLongPassword ? MIN_LONG_PASSWORD_LENGTH : MIN_COMPLEX_PASSWORD_LENGTH
    },
    passwordStrength () {
      const checks = [
        [
          this.hasUpperCase,
          this.hasLowerCase,
          this.hasSymbols,
          this.isSufficientLength
        ],
        [
          this.hasUpperCase || this.hasLowerCase,
          this.hasSymbols,
          this.isSufficientLength
        ]
      ]

      const check = checks[this.requiresLongPassword ? 1 : 0]
      const strength = check.filter(a => a).length / check.length

      this.$emit('input', strength)

      return strength
    },
    passwordLevel () {
      if (this.passwordStrength === 0) {
        return ''
      }

      if (this.passwordStrength <= 0.25) {
        return this.$pgettext('password_strength', 'Atrocious')
      }
      if (this.passwordStrength <= 0.5) {
        return this.$pgettext('password_strength', 'Meager')
      }
      if (this.passwordStrength <= 0.75) {
        return this.$pgettext('password_strength', 'Admissible')
      }
      return this.$pgettext('password_strength', 'Commendable')
    },
    passwordColor () {
      if (this.passwordStrength <= 0.50) {
        return 'red'
      }
      if (this.passwordStrength <= 0.75) {
        return 'yellow'
      }
      return 'green'
    }
  }
}
</script>

<style lang="scss" scoped>
  ul {
    color: $gray-50;
    font-size: $font-size-small;
  }

  .button-dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: $gray-30;
    border-radius: 50%;
    margin: auto;
    margin-right: 15px;
  }

  .checkmark {
    margin: auto;
    margin-right: 11px;
  }

  .password-strength {
    margin-bottom: 15px;
    margin-top: -20px;

    &__text {
      margin-top: 10px;
      text-align: right;
      font-family: $gotham-medium;
      font-size: $font-size-smallest;
    }
  }
</style>
