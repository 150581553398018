<template>
  <div
    id="app"
    class="d-flex layout-with-menu"
    :class="{'menu-visible': !hideMenu}"
  >
    <MainMenu
      v-if="!hideMenu"
      class="menu"
      :addClick="addClick"
    />
    <div id="main" class="layout-with-menu__content">
      <full-spinner v-if="loading"/>
      <slot v-else />
      <div class="layout-with-menu__footer_wrapper">
        <MainFooter v-if="!hideFooter" class="layout-with-menu__footer" />
      </div>
    </div>
  </div>
</template>

<script>
import MainMenu from '../components/Menu'
import MainFooter from '../components/Footer'
import FullSpinner from './FullSpinner'
import InsurancePolicyNotificationMixin from '../mixins/insurancePolicyNotificationMixin'

export default {
  name: 'layout-with-menu',
  inject: ['$validator'],
  components: {
    MainMenu,
    MainFooter,
    FullSpinner
  },
  mixins: [
    InsurancePolicyNotificationMixin
  ],
  props: {
    loading: {
      type: Boolean
    },
    hideMenu: {
      required: false,
      default: false
    },
    addClick: {
      required: false,
      default: null
    },
    hideFooter: {
      required: false,
      default: false
    }
  }
}
</script>

<style lang="scss">
.layout-with-menu {
  align-content: stretch;
  background-color: #f7f7f7;
  min-height: 100vh;
  max-height: 100vh;
  flex-direction: column;

  @media (min-width: $breakpoint-xl) {
    flex-direction: row;

    &.menu-visible {
      padding-left: 18.125rem;

      .menu {
        width: 18.125rem;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
      }
    }
  }

  &__content {
    overflow-y: scroll;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  &__footer_wrapper {
    margin-top: auto;
  }

  &__footer {
    margin-top: 30px;
  }
}
</style>
