<template>
  <layout v-bind="layoutProps" v-on="$listeners">
    <bb-datepicker
      name="date"
      type="date"
      :shortcuts="false"
      v-model="form.date"
      :label="translations.date"
      :data-vv-as="translations.date"
      :format="dateFormat"
      :lang="datepickerLanguage"
      v-validate="'required'"
    />
    <bb-textarea
      name="description"
      v-model="form.description"
      :label="translations.description"
      :data-vv-as="translations.description"
      rows="5"
      maxlength="1000"
      v-validate="'required'"
    />
  </layout>
</template>

<script>
import { mapState } from 'pinia'
import { useRootStore } from '../../../../store/root'
import { getDateFormat, formatDateForRequest } from '@/plugins/dateFormatters'
import Layout from './Layout.vue'
import { CountryChannel } from '@bigbank/dc-common/config'

export default {
  components: { Layout },
  name: 'partial-premature-fulfillment',
  data () {
    return {
      dateFormat: getDateFormat(),
      form: {
        description: undefined,
        date: undefined
      }
    }
  },
  props: {
    showLoader: {
      required: true
    },
    contract: {
      required: true
    }
  },
  computed: {
    ...mapState(useRootStore, ['channel', 'isCompany', 'datepickerLanguage']),
    translations () {
      return {
        title: this.$pgettext('partial-premature-fulfillment', 'Apply for partial premature fulfillment contract change'),
        text: this.isCompany ? this.partialPrematureFulfillmentCorporateText : this.partialPrematureFulfillmentText,
        buttonText: this.$pgettext('partial-premature-fulfillment', 'Continue'),
        date: this.$pgettext('partial-premature-fulfillment', 'Choose the date you wish to pay'),
        description: this.$pgettext('partial-premature-fulfillment', 'Description')
      }
    },
    partialPrematureFulfillmentText () {
      return this.channel === CountryChannel.LV
        ? this.$pgettext('partial-premature-fulfillment_lv',
          'In order to apply for partial premature fulfillment, please provide us with additional information.')
        : this.$pgettext('partial-premature-fulfillment',
          'In order to apply for partial premature fulfillment, please provide us with additional information.')
    },
    partialPrematureFulfillmentCorporateText () {
      return this.channel === CountryChannel.LV
        ? this.$pgettext('corporate-manage-loan_partial-premature-fulfillment_lv',
          'In order to apply for partial premature fulfillment, please provide us with additional information.')
        : this.$pgettext('corporate-manage-loan_partial-premature-fulfillment',
          'In order to apply for partial premature fulfillment, please provide us with additional information.')
    },
    layoutProps () {
      return {
        visible: true,
        title: this.translations.title,
        text: this.translations.text,
        buttonText: this.translations.buttonText,
        showLoader: this.showLoader,
        data: {
          ...this.form,
          date: formatDateForRequest(this.form.date)
        }
      }
    }
  }
}
</script>
