export default Object.freeze({
  HTTP_STATUS_500: 500,
  HTTP_STATUS_404: 404,
  NO_APPLICATIONS: 'no-applications',
  NO_CUSTOMER: 'no-customer',
  NO_CONTRACT: 'no-contract',
  NO_ACCEPT: 'no-accept',
  NO_ACCESS: 'no-access',
  SESSION_EXPIRED: 'session-expired',
  NO_CONNECTION: 'no-connection',
  MAINTENANCE: 'maintenance',
  NO_OFFERS: 'no-offers'
})
