<template>
  <div>
    <div v-if="isLoading" class="loader">
      <bb-spinner/>
    </div>
    <div v-else class="additional-documents">
      <div class="data-input-container">
        <h3 class="additional-documents-title">
          {{ translations.mainPanelTitle }}
        </h3>
        <div class="additional-documents-description">
          {{ translations.mainPanelDescription }}
        </div>
        <form @submit.prevent="validateForm">
          <div class="comment-section">
            <bb-textarea
              framed
              :label="translations.commentLabel"
              :placeholder="translations.commentPlaceholder"
              name="comment"
              maxlength="1000"
              resize="vertical"
              rows="7"
              v-model="documentForm.comment"
            />
          </div>
          <div class="accordions-container">
            <bb-accordion flat :value="accordions">
              <template #PROPERTY_SALE>
                <div class="document-requirements">
                  <div class="document-requirements-column">
                    <div class="document-requirements-column-header">
                      {{ translations.accordion.mustHavesHeader }}
                    </div>
                    <bb-list-item v-for="(item, k) in translations.accordion.mustHaves.propertySale" :key="k">
                      {{ item }}
                    </bb-list-item>
                  </div>
                  <div class="document-requirements-column">
                    <div class="document-requirements-column-header">
                      {{ translations.accordion.acceptedDocumentsHeader }}
                    </div>
                    <bb-list-item v-for="(item, k) in translations.accordion.acceptedDocuments.propertySale" :key="k">
                      {{ item }}
                    </bb-list-item>
                  </div>
                </div>
              </template>
              <template #RETURN_ON_INVESTMENT>
                <div class="document-requirements">
                  <div class="document-requirements-column">
                    <div class="document-requirements-column-header">
                      {{ translations.accordion.mustHavesHeader }}
                    </div>
                    <bb-list-item v-for="(item, k) in translations.accordion.mustHaves.returnOnInvestment" :key="k">
                      {{ item }}
                    </bb-list-item>
                  </div>
                  <div class="document-requirements-column">
                    <div class="document-requirements-column-header">
                      {{ translations.accordion.acceptedDocumentsHeader }}
                    </div>
                    <bb-list-item v-for="(item, k) in translations.accordion.acceptedDocuments.returnOnInvestment" :key="k">
                      {{item}}
                    </bb-list-item>
                  </div>
                </div>
              </template>
              <template #INCOME_BUSINESS_ACTIVITY>
                <div class="document-requirements">
                  <div class="document-requirements-column">
                    <div class="document-requirements-column-header">
                      {{ translations.accordion.mustHavesHeader }}
                    </div>
                    <bb-list-item v-for="(item, k) in translations.accordion.mustHaves.incomeFromBusiness" :key="k">
                      {{ item }}
                    </bb-list-item>
                  </div>
                  <div class="document-requirements-column">
                    <div class="document-requirements-column-header">
                      {{ translations.accordion.acceptedDocumentsHeader }}
                    </div>
                    <bb-list-item v-for="(item, k) in translations.accordion.acceptedDocuments.incomeFromBusiness" :key="k">
                      {{item}}
                    </bb-list-item>
                  </div>
                </div>
              </template>
              <template #SAVINGS>
                <div class="document-requirements">
                  <div class="document-requirements-column">
                    <div class="document-requirements-column-header">
                      {{ translations.accordion.mustHavesHeader }}
                    </div>
                    <bb-list-item v-for="(item, k) in translations.accordion.mustHaves.savings" :key="k">
                      {{ item }}
                    </bb-list-item>
                  </div>
                  <div class="document-requirements-column">
                    <div class="document-requirements-column-header">
                      {{ translations.accordion.acceptedDocumentsHeader }}
                    </div>
                    <bb-list-item v-for="(item, k) in translations.accordion.acceptedDocuments.savings" :key="k">
                      {{ item }}
                    </bb-list-item>
                  </div>
                </div>
              </template>
              <template #GIFT>
                <div class="document-requirements">
                  <div class="document-requirements-column">
                    <div class="document-requirements-column-header">
                      {{ translations.accordion.mustHavesHeader }}
                    </div>
                    <bb-list-item v-for="(item, k) in translations.accordion.mustHaves.gift" :key="k">
                      {{ item }}
                    </bb-list-item>
                  </div>
                  <div class="document-requirements-column">
                    <div class="document-requirements-column-header">
                      {{ translations.accordion.acceptedDocumentsHeader }}
                    </div>
                    <bb-list-item v-for="(item, k) in translations.accordion.acceptedDocuments.gift" :key="k">
                      {{ item }}
                    </bb-list-item>
                  </div>
                </div>
              </template>
              <template #SALARY>
                <div class="document-requirements">
                  <div class="document-requirements-column">
                    <div class="document-requirements-column-header">
                      {{ translations.accordion.mustHavesHeader }}
                    </div>
                    <bb-list-item v-for="(item, k) in translations.accordion.mustHaves.salary" :key="k">
                      {{ item }}
                    </bb-list-item>
                  </div>
                  <div class="document-requirements-column">
                    <div class="document-requirements-column-header">
                      {{ translations.accordion.acceptedDocumentsHeader }}
                    </div>
                    <bb-list-item v-for="(item, k) in translations.accordion.acceptedDocuments.salary" :key="k">
                      {{ item }}
                    </bb-list-item>
                  </div>
                </div>
              </template>
              <template #INHERITED>
                <div class="document-requirements">
                  <div class="document-requirements-column">
                    <div class="document-requirements-column-header">
                      {{ translations.accordion.mustHavesHeader }}
                    </div>
                    <bb-list-item v-for="(item, k) in translations.accordion.mustHaves.inherited" :key="k">
                      {{ item }}
                    </bb-list-item>
                  </div>
                  <div class="document-requirements-column">
                    <div class="document-requirements-column-header">
                      {{ translations.accordion.acceptedDocumentsHeader }}
                    </div>
                    <bb-list-item v-for="(item, k) in translations.accordion.acceptedDocuments.inherited" :key="k">
                      {{ item }}
                    </bb-list-item>
                  </div>
                </div>
              </template>
              <template #RETIREMENT_BENEFIT>
                <div class="document-requirements">
                  <div class="document-requirements-column">
                    <div class="document-requirements-column-header">
                      {{ translations.accordion.mustHavesHeader }}
                    </div>
                    <bb-list-item v-for="(item, k) in translations.accordion.mustHaves.retirementBenefit" :key="k">
                      {{ item }}
                    </bb-list-item>
                  </div>
                  <div class="document-requirements-column">
                    <div class="document-requirements-column-header">
                      {{ translations.accordion.acceptedDocumentsHeader }}
                    </div>
                    <bb-list-item v-for="(item, k) in translations.accordion.acceptedDocuments.retirementBenefit" :key="k">
                      {{item}}
                    </bb-list-item>
                  </div>
                </div>
              </template>
              <template #OTHER>
                <div class="document-requirements">
                  <div class="document-requirements-column">
                    <div class="document-requirements-column-header">
                      {{ translations.accordion.mustHavesHeader }}
                    </div>
                    <bb-list-item v-for="(item, k) in translations.accordion.mustHaves.other" :key="k">
                      {{ item }}
                    </bb-list-item>
                  </div>
                  <div class="document-requirements-column">
                    <div class="document-requirements-column-header">
                      {{ translations.accordion.acceptedDocumentsHeader }}
                    </div>
                    <bb-list-item v-for="(item, k) in translations.accordion.acceptedDocuments.other" :key="k">
                      {{ item }}
                    </bb-list-item>
                  </div>
                </div>
              </template>
            </bb-accordion>
          </div>
          <div v-if="moneyOriginDetails.documents.length > 0" class="file-upload-section">
            <bb-card size="fluid">
              <bb-list-group v-for="document in moneyOriginDetails.documents" :key="document.id">
                <bb-list-item>
                  <a class="file-name" @click="downloadFile(document)">
                    {{ document.fileName }}
                  </a>
                </bb-list-item>
              </bb-list-group>
            </bb-card>
          </div>
          <div class="file-upload-section">
              <bb-upload
                flavor="user-selfcontained"
                :allowedTypes="settings.allowedMimeTypes"
                :fileCountLimit="settings.maxFilesPerUpload"
                :sizeLimit="settings.maxFileSizeMb"
                v-bind="translations.upload"
                name="files"
                v-model="documentForm.files"
              >
              <template v-slot:browseLabel>
                <span v-sanitize.basic="translations.browseLabel"/>
              </template>
            </bb-upload>
            <label v-if="hasFileValidationErrors" class="document-upload-error-message">
              {{ translations.documentsRequiredErrorMessage }}
            </label>
          </div>
          <div class="side-panel" v-if="isMobile">
            <h3 class="side-panel-title">
              {{ translations.sidePanelTitle }}
            </h3>
            <div class="side-panel-description" v-sanitize.basic="translations.sidePanelDescription"/>
          </div>
          <div class="buttons">
            <span>
              <bb-button :disabled="!isFormValid" :label="translations.submitButton" type="submit"/>
            </span>
            <span v-if="isSkipButtonVisible">
              <bb-button color="gray" :label="translations.skipButton" @click="skipConfirmMoneyDetails"/>
            </span>
          </div>
        </form>
      </div>
      <div class="side-panel" v-if="!isMobile">
        <h3 class="side-panel-title">
          {{ translations.sidePanelTitle }}
        </h3>
        <div class="side-panel-description" v-sanitize.basic="translations.sidePanelDescription"/>
      </div>
    </div>
  </div>
</template>

<script>
import { api } from '../../api'
import { MoneyOriginDocumentValidationRules } from '@bigbank/dc-common/config/deposit.config'
import { isNil } from 'lodash'
import { mapState, mapWritableState } from 'pinia'
import { DepositRouteName, DepositType } from '../../const'
import { useRootStore } from '@/store/root'
import { useDepositStore } from '@deposits/store/depositStore'
import FileUploadingMixin from '@/mixins/fileUploadingMixin'

export default {
  name: 'money-origin-documents',
  props: {
    isSkipButtonVisible: {
      type: Boolean,
      required: false,
      default: true
    },
    flowData: {
      type: Object,
      required: false,
      default () {
        return {}
      }
    },
    flowConstants: {
      type: Object,
      required: false,
      default () {
        return {}
      }
    }
  },
  mixins: [FileUploadingMixin],
  data () {
    return {
      translations: {
        upload: {
          heading: this.$pgettext('upload', 'Upload documents (max 10 files)'),
          allowedTypesLabel: this.$pgettext('upload', 'File type is not allowed'),
          duplicateFileLabel: this.$pgettext('upload', 'File is already uploaded'),
          captureLabel: this.$pgettext('upload', 'Capture'),
          chooseLabel: this.$pgettext('upload', 'Choose'),
          coBorrowerEmptyLabel: this.$pgettext('upload', 'Upload file'),
          emptyDescriptionLabel: this.$pgettext('upload', 'No documents are uploaded.'),
          sizeLimitLabel: this.$pgettext('upload', 'File size is over allowed size'),
          uploadAnotherLabel: '',
          fileCountExceededLabel: this.$pgettext('upload', 'File limit exceeded'),
          filenameMaxLengthLabel: this.$pgettext('upload', 'The file name is too long')
        },
        browseLabel: this.$pgettext('money_origin_additional_documents', 'Drag a file here or <span class="bb-upload__highlight">browse</span> a file to upload'),
        updateSuccessNotification: this.$pgettext('money_origin_additional_documents', 'update_success_notification'),
        skipButton: this.$pgettext('money_origin_additional_documents', 'skip_button'),
        submitButton: this.$pgettext('money_origin_additional_documents', 'submit_button'),
        mainPanelTitle: this.$pgettext('money_origin_additional_documents', 'main_panel_title'),
        mainPanelDescription: this.$pgettext('money_origin_additional_documents', 'main_panel_description'),
        sidePanelTitle: this.$pgettext('money_origin_additional_documents', 'side_panel_title'),
        sidePanelDescription: this.$pgettext('money_origin_additional_documents', 'side_panel_description'),
        commentLabel: this.$pgettext('money_origin_additional_documents', 'comment_label'),
        commentPlaceholder: this.$pgettext('money_origin_additional_documents', 'comment_placeholder'),
        documentsRequiredErrorMessage: this.$pgettext('money_origin_additional_documents', 'documents_required'),
        fileCountExceededErrorMessage: this.$pgettext('money_origin_additional_documents', 'Upload limit exceeded. To upload more documents, save the page and reopen it'),
        accordion: {
          mustHavesHeader: this.$pgettext('money_origin_additional_documents', 'accordion_must_haves_header'),
          mustHaves: {
            propertySale: this.$pgettext('money_origin_additional_documents', 'accordion_must_haves_property_sale').split('\n'),
            returnOnInvestment: this.$pgettext('money_origin_additional_documents', 'accordion_must_haves_return_on_investment').split('\n'),
            incomeFromBusiness: this.$pgettext('money_origin_additional_documents', 'accordion_must_haves_income_from_business').split('\n'),
            savings: this.$pgettext('money_origin_additional_documents', 'accordion_must_haves_savings').split('\n'),
            gift: this.$pgettext('money_origin_additional_documents', 'accordion_must_haves_gift').split('\n'),
            salary: this.$pgettext('money_origin_additional_documents', 'accordion_must_haves_salary').split('\n'),
            inherited: this.$pgettext('money_origin_additional_documents', 'accordion_must_haves_inherited').split('\n'),
            retirementBenefit: this.$pgettext('money_origin_additional_documents', 'accordion_must_haves_retirement_benefit').split('\n'),
            other: this.$pgettext('money_origin_additional_documents', 'accordion_must_haves_other').split('\n')
          },
          acceptedDocumentsHeader: this.$pgettext('money_origin_additional_documents', 'accordion_accepted_documents_header'),
          acceptedDocuments: {
            propertySale: this.$pgettext('money_origin_additional_documents', 'accordion_accepted_documents_property_sale').split('\n'),
            returnOnInvestment: this.$pgettext('money_origin_additional_documents', 'accordion_accepted_documents_return_on_investment').split('\n'),
            incomeFromBusiness: this.$pgettext('money_origin_additional_documents', 'accordion_accepted_documents_income_from_business').split('\n'),
            savings: this.$pgettext('money_origin_additional_documents', 'accordion_accepted_documents_savings').split('\n'),
            gift: this.$pgettext('money_origin_additional_documents', 'accordion_accepted_documents_gift').split('\n'),
            salary: this.$pgettext('money_origin_additional_documents', 'accordion_accepted_documents_salary').split('\n'),
            inherited: this.$pgettext('money_origin_additional_documents', 'accordion_accepted_documents_inherited').split('\n'),
            retirementBenefit: this.$pgettext('money_origin_additional_documents', 'accordion_accepted_documents_retirement_benefit').split('\n'),
            other: this.$pgettext('money_origin_additional_documents', 'accordion_accepted_documents_other').split('\n')
          }
        }
      },
      isLoading: true,
      moneyOriginDetails: {
        documents: [],
        typeCodes: [],
        comment: ''
      },
      documentForm: {
        files: [],
        comment: ''
      },
      settings: MoneyOriginDocumentValidationRules,
      accordions: [
        {
          id: 'PROPERTY_SALE',
          title: this.$pgettext('MONEY_ORIGIN', 'Property sale')
        },
        {
          id: 'RETURN_ON_INVESTMENT',
          title: this.$pgettext('MONEY_ORIGIN', 'Return on investment')
        },
        {
          id: 'INCOME_BUSINESS_ACTIVITY',
          title: this.$pgettext('MONEY_ORIGIN', 'Income from business activity')
        },
        {
          id: 'SAVINGS',
          title: this.$pgettext('MONEY_ORIGIN', 'Savings')
        },
        {
          id: 'GIFT',
          title: this.$pgettext('MONEY_ORIGIN', 'Gift')
        },
        {
          id: 'SALARY',
          title: this.$pgettext('MONEY_ORIGIN', 'Salary')
        },
        {
          id: 'INHERITED',
          title: this.$pgettext('MONEY_ORIGIN', 'Inherited')
        },
        {
          id: 'RETIREMENT_BENEFIT',
          title: this.$pgettext('MONEY_ORIGIN', 'Retirement benefit')
        },
        {
          id: 'LOAN_CAPITAL_INSTRUMENT',
          title: this.$pgettext('MONEY_ORIGIN', 'Loan capital instrument')
        },
        {
          id: 'DIVIDENDS',
          title: this.$pgettext('MONEY_ORIGIN', 'Dividends')
        },
        {
          id: 'DONATION',
          title: this.$pgettext('MONEY_ORIGIN', 'Donation')
        },
        {
          id: 'PUBLIC_SUBSIDY',
          title: this.$pgettext('MONEY_ORIGIN', 'Public subsidy')
        },
        {
          id: 'REGULAR_BUSINESS_ACTIVITY',
          title: this.$pgettext('MONEY_ORIGIN', 'Regular business activity')
        },
        {
          id: 'OTHER',
          title: this.$pgettext('MONEY_ORIGIN', 'Other')
        }
      ],
      progress: {
        hideProgressIndicator: true,
        value: 0,
        max: 0
      }
    }
  },
  computed: {
    ...mapState(useRootStore, ['isMobile', 'featureFlags']),
    ...mapState(useDepositStore, ['currentDeposit']),
    ...mapWritableState(useDepositStore, ['currentDepositId']),
    hasFileValidationErrors () {
      return this.documentForm.files.length > 0 && this.documentForm.files.some(item => item.errorMessage)
    },
    hasFileCountExceededErrors () {
      return this.documentForm.files?.some(item => item.errorMessage === this.translations.upload.fileCountExceededLabel)
    },
    isFormValid () {
      const isCommentFieldFilled = !isNil(this.documentForm.comment) && this.documentForm.comment.trim().length > 0
      const hasFiles = this.documentForm.files.length > 0

      return !this.hasFileValidationErrors && (isCommentFieldFilled || hasFiles)
    }
  },
  watch: {
    hasFileCountExceededErrors (newValue) {
      if (newValue) {
        this.$notify({
          text: this.translations.fileCountExceededErrorMessage,
          type: 'error',
          duration: 5000
        })
      }
    }
  },
  methods: {
    async validateForm () {
      if (!this.isFormValid) {
        return
      }

      await this.submitConfirmMoneyDetails()
    },
    skipConfirmMoneyDetails () {
      if (this.$route.params.contractId) {
        this.$router.go(-1)
        return
      }
      this.isLoading = true
      const stepData = {
        moneyOriginDocumentsUuid: null,
        moneyOriginComment: ''
      }
      this.$emit('submit', stepData)
    },
    async submitConfirmMoneyDetails () {
      this.isLoading = true
      let moneyOriginDocumentsUuid = null

      if (this.$route.params.contractId) {
        await api.createMoneyOriginDetails(
          this.$route.params.contractId,
          this.documentForm.comment,
          this.documentForm.files
        )
        this.$notify({ text: this.translations.updateSuccessNotification, duration: 10000 })
        this.close()
      } else if (
        this.featureFlags.enableMoneyOriginDocumentsUploadInSelfService &&
        this.flowData.newDeposit?.id
      ) {
        await api.createMoneyOriginDetails(
          this.flowData.newDeposit.id,
          this.documentForm.comment,
          this.documentForm.files
        )
        this.$notify({ text: this.translations.updateSuccessNotification, duration: 10000 })

        this.$emit('submit', { moneyOriginDocumentsUuid: null, moneyOriginComment: '' })
      } else {
        if (this.documentForm.files.length > 0) {
          const response = await api.uploadMoneyOriginDocuments(this.documentForm.files)

          if (this.isUploadingFailed(response)) {
            this.showFailureNotification(response)
            this.isLoading = false
            return
          }

          moneyOriginDocumentsUuid = response
        } else {
          this.isLoading = false
        }

        const stepData = {
          moneyOriginDocumentsUuid,
          moneyOriginComment: this.documentForm.comment
        }

        this.$emit('submit', stepData)
      }
    },
    close () {
      let routeName = DepositRouteName.Deposits

      switch (this.currentDeposit?.depositType) {
        case DepositType.Demand:
          routeName = DepositRouteName.DemandDeposits
          break
        case DepositType.Term:
          routeName = DepositRouteName.TermDeposits
          break
      }

      this.$router.push({ name: routeName })
    },
    async downloadFile (data) {
      await api.downloadMoneyOriginDocument(this.$route.params.contractId, data.fileId, data.fileName)
    }
  },
  async mounted () {
    this.isLoading = true

    if (Array.isArray(this.flowData.moneyOrigins) && this.flowData.moneyOrigins.length > 0) {
      this.accordions = this.accordions.filter(element => this.flowData.moneyOrigins.indexOf(element.id) >= 0)
      this.accordions[0].open = true
      this.isLoading = false

      return
    }

    this.currentDepositId = this.$route.params.contractId
    this.moneyOriginDetails = await api.getMoneyOriginDetails(this.$route.params.contractId)

    if (this.moneyOriginDetails) {
      if (this.currentDeposit?.depositType === DepositType.Term) {
        this.accordions = this.accordions.filter(element => this.moneyOriginDetails.typeCodes.indexOf(element.id) >= 0)
      }
      this.accordions[0].open = true
      this.documentForm.comment = this.moneyOriginDetails.comment
    }

    this.isLoading = false
  }
}
</script>

<style lang="scss">
.loader {
  display: flex;
  justify-content: center;
  padding-top: 50px;
}

.additional-documents {
  display: flex;
  flex-direction: row;
  flex: 2 1;
  padding-right: 135px;

  @media (max-width: $desktop-view-breaking-point-wide) {
    flex-direction: column;
    width: 100%;
    padding: 0;
  }

  &-title {
    font-family: $gotham-bold;
    font-size: 18px;
    line-height: 24px;
    color: $navy;
    margin-bottom: 40px;

    @media (max-width: $desktop-view-breaking-point-wide) {
      margin-bottom: 32px;
    }
  }

  &-description {
    font-family: $gotham-medium;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 20px;
  }

  .comment-section {
    margin-bottom: 30px;
  }

  .document-upload-error-message {
    font-size: 11px;
    color: $red;
  }

  .accordions-container {
    margin-bottom: 30px;
  }

  .data-input-container {
    flex-direction: column;
    padding: 56px 137px;
    flex: 2 1;

    @media (max-width: $desktop-view-breaking-point-wide) {
      padding: 56px 0;
    }

    .file-name {
      display: inline-block;
      margin-top: 5px;
      color: $mint;
      cursor: pointer;
    }

    .field-group label {
      display: flex;
      overflow-wrap: break-word;
      overflow: visible;
    }

    .buttons {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      margin-top: 77px;

      @media (max-width: $desktop-view-breaking-point-wide) {
        margin-top: 0;
        flex-direction: column;

        span {
          display: flex;
          flex-direction: column;
          width: 100%;
          padding-top: 10px;
        }
      }
    }

    .document-requirements {
      display: flex;
      flex-direction: row;
      font-size: 12px;
      line-height: 16px;
      color: $gray;

      &-column {
        width: 100%;
        word-break: break-word;

        @media (min-width: $desktop-view-breaking-point-wide) {
          &:nth-of-type(1) {
            padding-right: 10px;
          }

          &:nth-of-type(2) {
            padding-left: 10px;
          }
        }

        &-header {
          font-weight: bold;
          padding: 10px 0;
        }
      }
    }

    @media (max-width: $desktop-view-breaking-point-wide) {
      .document-requirements {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .side-panel {
    background-color: $gray-10;
    flex: 1 1;
    padding: 56px 48px;

    @media (max-width: $desktop-view-breaking-point-wide) {
      padding: 56px 0;
      background-color: unset;
    }

    &-title {
      font-family: $gotham-bold;
      font-size: 18px;
      line-height: 24px;
      color: $navy;
      margin-bottom: 40px;
    }

    &-description {
      font-family: $gotham-medium;
      font-size: 14px;
      line-height: 18px;
      word-break: break-word;

      a {
        color: $mint;
      }

      p:not(:last-child) {
        margin-bottom: 14px;
      }
    }
  }

  .bb-list-item__content {
    padding: 10px 0 !important;
  }
}
</style>
