<template>
  <signing-modal-wrapper visible @close="$emit('close', $event)" :showLoader="false">
    <template slot="heading">{{translations.title}}</template>
    <div>
      <p class="info">{{infoText}}</p>
      <bb-input
        :label="translations.label"
        v-validate="'required'"
        :data-vv-as="translations.label"
        pattern="[0-9]*"
        type="number"
        name="otp"
        v-model="otp"
        :maxlength="6"
        :disabled="isValidating"
      />
    </div>
    <bb-ticket-stub v-if="showButton" slot="footer" class="m-t-15">
      <bb-button :color="buttonColor" :loading="isValidating" display="ticket">{{buttonLabel}}</bb-button>
    </bb-ticket-stub>
  </signing-modal-wrapper>
</template>

<script>
import api from '@account/api'
import '@bigbank/interface-components/dist/svg/ui/alert'
import '@bigbank/interface-components/dist/svg/ui/check'
import SigningModalWrapper from '@/components/signing/SigningModalWrapper'

export default {
  name: 'sign-modal',
  components: { SigningModalWrapper },
  data () {
    return {
      otp: '',
      isValidating: false,
      translations: {
        title: this.$pgettext('CONFIRM_PHONE', 'Confirm your mobile number'),
        label: this.$pgettext('CONFIRM_PHONE', 'Enter code from SMS'),
        requestExpiredError: this.$pgettext('CONFIRM_PHONE', 'The signing flow has expired, please start it again.'),
        codeWrongError: this.$pgettext('CONFIRM_PHONE', 'The SMS code does not match. Please try again.'),
        internalError: this.$pgettext('CONFIRM_PHONE', 'Something appears to have gone wrong. Please try again later or contact our customer support.'),
        restartSigning: this.$pgettext('CONFIRM_PHONE', 'Send new code'),
        loading: this.$gettext('Loading...')
      }
    }
  },
  props: {
    confirmRequestId: {
      required: false
    }
  },
  computed: {
    infoText () {
      return this.$pgettext('CONFIRM_PHONE', 'We have sent 6-digit verification code to your new phone number.  In case you don\'t confirm the renewal, old mobile number remains valid. You can always change your data under "My account".')
    },
    buttonLabel () {
      return this.translations.loading
    },
    showButton () {
      return this.isValidating
    },
    buttonColor () {
      return 'gray'
    }
  },
  watch: {
    async otp (value) {
      try {
        if (!value || value.length < 6) {
          return
        }
        this.$validator.errors.clear()
        this.isValidating = true

        await api.confirmPhoneChangeRequest(this.confirmRequestId, this.otp)
        this.$emit('success')
      } catch (error) {
        this.isValidating = false
        let msg = this.translations.internalError

        switch (error.err_code) {
          case 'REQUEST_EXPIRED':
            msg = this.translations.requestExpiredError
            break
          case 'INVALID_CODE':
            msg = this.translations.codeWrongError
        }

        this.$validator.errors.add({
          field: 'otp',
          msg
        })
      }
    }
  }
}
</script>
