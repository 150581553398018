<template>
    <div>
        <span>{{errorMessage}}</span>
    </div>
</template>

<script>
export default {
  props: {
    code: {
      default: null,
      required: false
    }
  },
  computed: {
    errorMessage () {
      if (!this.code) {
        return ''
      }

      const customerIdErrors = {
        INVALID_MFA: this.$pgettext('customer_id_error', 'Your SMS code has expired, please close this window and try again.'),
        INVALID_OTP: this.$pgettext('customer_id_error', 'The SMS code doesn\'t match, please try again.'),
        INVALID_CREDENTIALS: this.$pgettext('customer_id_error', 'This username and password combination is not valid.'),
        ACCOUNT_LOCKED: this.$pgettext('customer_id_error', 'Due to several unsuccessful login attempts, your account has been deactivated for 30 minutes due to security concerns.'),
        ACCOUNT_DISABLED: this.$pgettext('customer_id_error', 'Due to several unsuccessful login attempts, your account has beed disabled due to security conserns. Please contact our customer support.'),
        ACCOUNT_NOT_ACTIVATED: this.$pgettext('customer_id_error', 'Your account is not activated, please check your email for account activation email or contact with our customer support.'),
        ACCOUNT_NOT_FOUND: this.$pgettext('customer_id_error', 'Sorry, we couldn\'t find an account with that username. Please try again or contact us on 887 8765.'),
        INVALID_PASSWORD: this.$pgettext('customer_id_error', 'The entered password does not meet security criteria, please use another password.'),
        PASSWORD_GUESSABLE: this.$pgettext('customer_id_error', 'This password is too weak, easily quessable or includes personal details, please use another password.'),
        PASSWORD_REUSE: this.$pgettext('customer_id_error', 'You have previously used this password, please use another password.'),
        ACCOUNT_DATA_INCOMPLETE: this.$pgettext('customer_id_error', 'Your account data is incomplete, please contact our customer support.')
      }

      const defaultError = this.$pgettext('error', 'Something appears to have gone wrong. Please try again later.')

      return customerIdErrors[this.code] || defaultError
    }
  }
}
</script>
