<template>
  <bb-modal
    :visible="visible"
    flavor="card"
    @close="onModalClosed"
    class="noselect text-center"
    centerVertically
    full-mobile
    publicSize="m"
  >
    <template #heading v-if="!isLoading">
      <p class="text-center modal-title">{{ translations.modal_title }}</p>
    </template>
    <template v-if="isLoading">
      <div class="loader">
        <bb-spinner />
      </div>
    </template>
    <template v-else>
      <div>
        <bb-icon
          name="illustration-cvv"
          class="cvv-input-modal__illustration"
          size="fluid"
          fill="mint-70"
        />
      </div>
      <p v-if="isCvvErrorShown" class="color-red f-gotham-bold f-smallest">{{ translations.cvv_code_validation_failed }}</p>
      <label class="input-label f-gotham-book">{{ translations.please_enter_cvv_code_below }}</label>
      <bb-input
        class="m-t-20 p-t-0"
        framed
        v-model="verificationCVV"
        type="text"
        name="verificationCVV"
        maxLength="4"
        :helpActive="true"
        :placeholder="translations.cvv_placeholder"
      >
        <template #tooltipText>
          <span>{{ translations.cvv_input_tooltip}}</span>
        </template>
      </bb-input>
      <bb-banner
        class="text-left m-t-30"
        name="poof"
        type="info"
        :visible="true"
        permanent
      >
      <div v-sanitize.basic="translations.boxed_tooltip" ref="boxedTooltip" class="boxed-tooltip"></div>
      </bb-banner>
    </template>
    <template #footer>
      <bb-button
        v-if="$screen.md && !isLoading"
        @click="onSubmit"
        v-bind="buttonProps"
      />
      <bb-button
        v-else-if="!isLoading"
        @click="onSubmit"
        v-bind="buttonProps"
        corner="sharp"
        size="lg"
        />
    </template>
  </bb-modal>
</template>

<script>
import api from '@/modules/loan/api'

export default {
  name: 'cvv-input-modal',
  props: {
    visible: { type: Boolean, required: true },
    cardId: { type: Number, required: true },
    contractId: { type: String, required: true },
    isCvvErrorShown: { type: Boolean, default: false }
  },
  data () {
    return {
      isLoading: true,
      contractFileId: null,
      verificationCVV: ''
    }
  },
  computed: {
    translations () {
      return {
        modal_title: this.$pgettext('credit_card_activation', 'Activate your credit card'),
        please_enter_cvv_code_below: this.$pgettext('credit_card_activation', 'Please enter CVV code in the field below'),
        cvv_code_validation_failed: this.$pgettext('credit_card_activation', 'CVV validation failed. Please try again.'),
        boxed_tooltip: this.$pgettext('credit_card_activation', 'Before activating the card, please check the <span>Card Contract Details</span>'),
        cvv_input_tooltip: this.$pgettext('credit_card_activation', 'The CVV Number is a 3 digit number, located on the back side of your credit card.'),
        sign_and_show_pin: this.$pgettext('credit_card_activation', 'Sign and show PIN'),
        cvv_placeholder: this.$pgettext('credit_card_activation', 'Enter the CVV code')
      }
    },
    isButtonDisabled () {
      return !(/^[0-9]{3,4}$/).test(this.verificationCVV)
    },
    modalFlavor () {
      return this.isMobile ? 'card' : 'user'
    },
    buttonProps () {
      const defaults = {
        display: 'block',
        label: this.translations.sign_and_show_pin,
        disabled: this.isButtonDisabled,
        name: 'sign-and-show-pin-button'
      }

      return {
        ...defaults
      }
    }
  },
  methods: {
    setLoading (flag) { this.isLoading = flag },
    setContractFileId (id) { this.contractFileId = id },
    onModalClosed () {
      this.$emit('close')
    },
    onSubmit () {
      this.$emit('submit', this.verificationCVV)
    },
    async onDownloadClick (event) {
      await api.downloadCreditCardContract(this.contractId, this.contractFileId)
      event.preventDefault()
      event.stopPropagation()
      return false
    },
    listenContractLinkClickEvent () {
      this.$nextTick(() => {
        try {
          this.$refs.boxedTooltip.querySelector('span').removeEventListener('click', this.onDownloadClick)
          this.$refs.boxedTooltip.querySelector('span').addEventListener('click', this.onDownloadClick)
        } catch (err) {
          setTimeout(() => this.listenContractLinkClickEvent(), 50)
        }
      })
    }
  },
  async mounted () {
    const response = await api.generateCreditCardContract(this.cardId, this.contractId)

    this.setLoading(false)
    this.setContractFileId(response.contractFileId)
    this.listenContractLinkClickEvent()
  },
  updated () {
    this.listenContractLinkClickEvent()
  }
}
</script>

<style lang="scss">
.cvv-input-modal {
  &__illustration {
    width: 100%;
    max-width: 205px;
    height: 160px;
    margin: 10px auto 28px;
    display: block;
  }
}
</style>
