<template>
  <bb-horizontal-menu
    style="z-index: 8;"
    class="selfservice-actions-menu"
    :routes="tabRoutes"
    hideOnScroll
    :isStickyOnMobile="sticky"
    :centered="centered"
    extraHeight
  />
</template>

<script>
export default {
  name: 'horizontal-actions-menu',
  props: {
    tabRoutes: {
      type: Array,
      required: true
    },
    sticky: {
      type: Boolean,
      default: true
    },
    centered: {
      type: Boolean,
      default: false
    }
  }
}
</script>
