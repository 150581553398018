<template>
  <div class="offers-radio-card">
    <bb-spinner small v-if="showLoader" />
    <bb-detailed-radio
      v-else
      name="offers"
      :options="options"
      :value="selected"
      @change="onSelection"
      class="notranslate"
    />
  </div>
</template>

<script>
import { api } from '../../api'
import { formatInterest, formatMoneyWithCurrency } from '@/plugins/numformat'
import getCurrency from '../../utils/getCurrency'
import debounce from 'lodash/debounce'

export default {
  name: 'OfferRadioCard',
  props: {
    offers: {
      type: Array,
      required: true
    },
    amount: {
      type: Number,
      required: true
    },
    paymentFrequencyCode: {
      type: String,
      required: true
    },
    startDate: {
      type: String,
      required: true
    },
    accountType: Object,
    manualProlong: Boolean,
    agreementTypeCode: String,
    locale: String
  },
  data () {
    return {
      isLoading: false,
      offersData: []
    }
  },
  methods: {
    onSelection (offerIndex) {
      this.$emit('select', this.offers[offerIndex])
    },
    formattedPeriodCode (periodCode) {
      return this.translations.periodCodes[periodCode]
    },
    formattedInterest ({ bonusInterest, annualInterestRate }) {
      if (bonusInterest > 0) {
        return formatInterest(annualInterestRate - bonusInterest, this.locale) + '% +' + formatInterest(bonusInterest, this.locale) + '%'
      } else {
        return formatInterest(annualInterestRate, this.locale) + '%'
      }
    },
    formattedExpectedProfit ({ expectedProfit, offer }) {
      const profit = offer.profit ? offer.profit : expectedProfit || 0

      return formatMoneyWithCurrency(profit, this.currency, this.locale)
    },
    async update () {
      if (!this.offers || !this.offers.length) {
        return
      }

      this.$emit('loading', true)
      this.offersData = []

      const result = await Promise.all(this.offers.map(offer => {
        offer.annualInterestRate = null
        offer.bonusInterest = null

        return api.getAccountInterestParameters(
          this.amount,
          offer.period,
          offer.periodCode,
          this.paymentFrequencyCode,
          this.startDate,
          this.manualProlong,
          this.agreementTypeCode
        )
          .then(response => ({ ...response, offer }))
      }))

      this.offersData = result.map(({ interest, bonusInterest, offer }) => {
        offer.annualInterestRate = interest
        offer.bonusInterest = bonusInterest
        return {
          annualInterestRate: interest,
          bonusInterest,
          expectedProfit: null,
          offer
        }
      })

      await this.updateExpectedProfit()

      this.$emit('loading', false)
    },
    async updateExpectedProfit () {
      await Promise.all(this.offersData.map(offerData => {
        const { offer, annualInterestRate } = offerData

        return api.getDepositInterest(this.amount, offer.period, annualInterestRate, this.startDate, offer.periodCode)
          .then(expectedProfit => {
            offerData.expectedProfit = expectedProfit
          })
      }))
    }
  },
  watch: {
    amount () {
      this.debouncedUpdateExpectedProfit()
    },
    paymentFrequencyCode () {
      this.deboucedUpdate()
    },
    offers () {
      this.deboucedUpdate()
    }
  },
  created () {
    this.debouncedUpdateExpectedProfit = debounce(() => {
      this.updateExpectedProfit()
    }, 200)
    this.deboucedUpdate = debounce(() => {
      this.update()
    }, 50)
    this.deboucedUpdate()
  },
  computed: {
    translations () {
      return {
        periodCodes: {
          DAYS: this.$gettext('days'),
          MONTHS: this.$gettext('months'),
          YEARS: this.$gettext('years')
        },
        expectedProfit: this.$gettext('Expected profit'),
        for: this.$gettext('for'),
        interestRate: this.$gettext('interest rate')
      }
    },
    showLoader () {
      return !this.offersData.length
    },
    currency () {
      return getCurrency(this.accountType.restrictedCurrencyCode)
    },
    options () {
      return this.offersData.map((offerData, index) => {
        const offer = offerData.offer
        return {
          value: index,
          leftColumn: {
            label: `${this.translations.for} ${offer.period} ${this.formattedPeriodCode(offer.periodCode)}`,
            sublabel: `= ${this.formattedInterest(offerData)} ${this.translations.interestRate}`
          },
          rightColumn: {
            label: this.translations.expectedProfit,
            amount: `${this.formattedExpectedProfit(offerData)}`
          }
        }
      })
    },
    selected () {
      for (const i in this.offersData) {
        if (this.offersData[i].offer.selected) {
          return parseInt(i)
        }
      }

      return null
    }
  }
}
</script>

<style lang="scss">
.offers-radio-card {
  width: 100%;
}
</style>
