<template>
  <layout-with-progress v-bind="{progress, statusText, loading}" @close="closeModal">
    <div class="prolongation-flow__container">
      <div class="prolongation-flow__content">
        <div class="prolongation-flow__component">
          <prolongation-step-one
                  v-if="activePage === flows.prolongationStepOne"
                  :flowData="flowData"
                  @submit="submitProlongationStepOne"
          ></prolongation-step-one>
          <prolongation-step-two
                  v-if="activePage === flows.prolongationStepTwo"
                  :flowData="flowData"
                  @goToDifferentPlan="loadDifferentPlan"
                  @submit="submitProlongationStepTwo"
          ></prolongation-step-two>
          <different-plan
                  v-if="activePage === flows.differentPlan"
                  :flowData="flowData"
                  @submit="submitDifferentPlan"
                  @backToPlan="loadProlongationStepOne"
          ></different-plan>
          <confirm-money-details
                  v-if="activePage === flows.confirmMoneyDetails"
                  :flowData="flowData"
                  :flow-constants="constants"
                  @submit="submitConfirmMoneyDetails"
          ></confirm-money-details>
          <application-confirmation
                  @close="loadDepositsView"
                  v-if="activePage === flows.applicationConfirmation"
                  :visible="activePage === flows.applicationConfirmation"
                  :flowData="flowData"
                  :flow-constants="constants"
                  :is-loading="applicationCreationLoading"
                  @submit="submitApplicationConfirmation"
          ></application-confirmation>
          <contract-conditions
                  v-if="activePage === flows.contractConditions"
                  :deposit="flowData.newDeposit"
                  :transferDetails="{}"
                  :is-demand-deposit="false"
                  @close="loadDepositsView"
          >
          </contract-conditions>
        </div>
      </div>
    </div>
  </layout-with-progress>
</template>

<script>
import dayjs from 'dayjs'
import ProlongationStepOne from '../components/ApplicationCreation/ProlongationStepOne'
import ProlongationStepTwo from '../components/ApplicationCreation/ProlongationStepTwo'
import DifferentPlan from '../components/ApplicationCreation/DifferentPlan'
import ApplicationConfirmation from '../components/ApplicationCreation/ApplicationConfirmation'
import ConfirmMoneyDetails from '../components/ApplicationCreation/ConfirmMoneyDetails'
import ContractConditions from '../components/ContractConditions'
import FlowMixin from '../mixins/flowMixin'
import { api } from '../api'
import flows from '../utils/flows'
import { getConfirmFlowTrackerAction } from '@/TrackingActions'
import { DepositType } from '@deposits/const'
import { useRootStore } from '../../../store/root'
import { mapState, mapActions, mapWritableState } from 'pinia'
import { useDepositStore } from '@deposits/store/depositStore'

export default {
  name: 'prolongation-flow',
  mixins: [FlowMixin],
  components: {
    DifferentPlan,
    ApplicationConfirmation,
    ProlongationStepOne,
    ProlongationStepTwo,
    ConfirmMoneyDetails,
    ContractConditions
  },
  mounted () {
    this.progress.max = 4
  },
  async created () {
    await this.fetchContractDetails({ contractId: this.$route.params.contractId })
    await this.loadProlongationStepOne(this.contractDetails)
    this.loading = false
  },
  data () {
    return {
      show: true,
      statusText: '',
      stepMapping: {
        [flows.prolongationStepOne]: 1,
        [flows.prolongationStepTwo]: 2,
        [flows.differentPlan]: 2,
        [flows.confirmMoneyDetails]: 3,
        [flows.contractConditions]: 4
      }
    }
  },
  props: {
    activeView: String
  },
  computed: {
    ...mapState(useRootStore, ['locale']),
    ...mapState(useDepositStore, ['views', 'featureFlags', 'contractDetails']),
    ...mapWritableState(useDepositStore, ['loading'])
  },
  methods: {
    ...mapActions(useDepositStore, ['fetchContractDetails']),
    async loadProlongationStepOne (deposit) {
      if (deposit) {
        const agreementTypeCode = this.constants.AGREEMENT_TYPE_CODES[DepositType.Term]
        const accountTypes = await api.getAccountTypes(agreementTypeCode)
        const accountType = accountTypes[0]
        const [interestResponse, { limits, parameters, validPaymentFrequencies }] = await Promise.all([
          api.getAccruedInterest(deposit.id),
          api.getDepositLimits(agreementTypeCode, accountType.code, this.defaults.periodCode)
        ])

        deposit.interestAmount = interestResponse.accruedInterest
        const paymentFrequencyCode = validPaymentFrequencies.includes(deposit.paymentFrequencyCode)
          ? deposit.paymentFrequencyCode
          : validPaymentFrequencies[0]

        const period = (this.defaults.periodCode === deposit.periodCode) ? deposit.period : limits[paymentFrequencyCode].minPeriod
        const startDate = dayjs(deposit.endDate).format('YYYY-MM-DD')

        const specialInterestRequest = await this.getSpecialInterestRequest(
          deposit.amount, period, this.defaults.periodCode, paymentFrequencyCode, startDate, agreementTypeCode
        )

        const initialFlowData = {
          agreementTypeCode,
          deposit,
          startDate,
          accountType,
          amount: deposit.amount,
          period,
          currency: deposit.currencyCode,
          periodCode: this.defaults.periodCode,
          paymentFrequencyCode,
          limits,
          validPaymentFrequencies,
          features: this.featureFlags,
          parameters,
          views: this.views,
          activeView: this.views.prolongationFlow,
          specialInterestRequest,
          locale: this.locale
        }

        Object.assign(this.flowData, initialFlowData, this.defaults)
      }

      return this.setActivePage(flows.prolongationStepOne)
    },
    async loadProlongationStepTwo () {
      this.progress.value = 1
      this.setActivePage(flows.prolongationStepTwo)
    },
    async submitProlongationStepOne (selection) {
      this.$tracker.action(
        getConfirmFlowTrackerAction(this.flowData.activeView, this.activePage),
        { progress: this.progressForTracker, selection }
      )
      if (selection.key === 'different-amount') {
        this.loadDifferentPlan()
      } else {
        this.flowData.amount = selection.amount
        this.flowData.specialInterestRequest = await this.getSpecialInterestRequest(
          selection.amount,
          this.flowData.period,
          this.flowData.periodCode,
          this.flowData.paymentFrequencyCode,
          this.flowData.startDate,
          this.flowData.agreementTypeCode
        )

        this.loadProlongationStepTwo()
      }
    },
    async submitProlongationStepTwo (stepData) {
      this.$tracker.action(
        getConfirmFlowTrackerAction(this.flowData.activeView, this.activePage),
        { progress: this.progressForTracker, selection: stepData }
      )
      Object.assign(this.flowData, stepData)
      await this.loadConfirmMoneyDetails()
    },
    async getSpecialInterestRequest (amount, period, periodCode, paymentFrequencyCode, startDate, agreementTypeCode) {
      const manualProlong = true
      const interestParameters = await api.getAccountInterestParameters(
        amount, period, periodCode, paymentFrequencyCode, startDate, manualProlong, agreementTypeCode
      )

      return interestParameters.specialInterestRequest
    }
  }
}
</script>

<style lang="scss">
  .prolongation-flow {
    &__container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 100%;
      height: 100%;
    }

    &__content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }

    &__component {
      margin: 54px 30px 0;

      @media (min-width: $desktop-view-breaking-point-wide) {
        margin: 63px 0 0;
      }

      @media (max-width: 359px) {
        margin: 54px 15px 0;
      }
    }
  }
</style>
