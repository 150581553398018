<template>
  <signing-modal
    ref="signing"
    :contract="contract"
    :initMethod="initSigning"
    @signingSuccessful="complete"
    @signingCancelled="() => {}"
  />
</template>

<script>
import SigningModal from './SigningModal'
import api from '@loan/api'
import { mapActions } from 'pinia'
import { CONTRACT_CHANGE_SIGNING } from '@/TrackingActions'
import { useLoanStore } from '@loan/store/loanStore'

export default {
  name: 'contract-change-signing-wrapper',
  components: {
    SigningModal
  },
  props: {
    contract: {
      required: true
    }
  },
  computed: {
    signingInfo () {
      return this.contract.contractChangeSigning
    },
    translations () {
      return {
        contractSigned: this.$pgettext('contract_change_signed_notification', 'You have signed contract change successfully!')
      }
    }
  },
  methods: {
    ...mapActions(useLoanStore, ['getContracts']),
    async signButtonClick (e) {
      e.preventDefault()

      if (!this.contract || !this.signingInfo) {
        return
      }

      return await this.$refs.signing.signButtonClick(e)
    },
    async initSigning () {
      const initResponse = await api.initSigning(this.contract.id)

      return {
        signingRequestId: initResponse.signingRequestId,
        method: initResponse.method
      }
    },
    complete () {
      this.getContracts({ showLoader: true })

      this.$router.push({
        name: 'loan-contract-details',
        params: {
          contractId: this.contract.id,
          signingRequestId: this.signingRequestId
        }
      })
      this.$notify({ text: this.translations.contractSigned, duration: 10000 })
      this.$tracker.action(CONTRACT_CHANGE_SIGNING.COMPLETE, {}, { contractId: this.contract.id.toString() })
    }
  }
}
</script>
