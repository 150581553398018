<template>
  <bb-horizontal-menu
    style="z-index: 8;"
    class="selfservice-actions-menu"
    :routes="actionRoutes"
    hideOnScroll
    @onTabClick="handleTabClick">
  </bb-horizontal-menu>
</template>

<script>
export default {
  name: 'horizontal-actions-menu',
  props: {
    tabRoutes: {
      type: Array,
      required: true
    }
  },
  methods: {
    handleTabClick (tab) {
      this.$emit('activeTabChange', tab)
    }
  },
  computed: {
    actionRoutes () {
      return this.tabRoutes
    }
  }
}
</script>
