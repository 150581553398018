import { mapState } from 'pinia'
import api from '@/modules/loan/api'
import { isNull, isNumber, isObject } from 'lodash'
import { useLoanStore } from '@/modules/loan/store/loanStore'

export default {
  props: {
    onCloseRedirectUrl: {
      default: null,
      require: false
    }
  },
  computed: {
    ...mapState(useLoanStore, ['loanTypes', 'featureFlags']),
    hasOutstandingFunds () {
      return isNumber(this.invoiceDetails?.totalAmount) ||
        isNumber(this.nextPaymentDetails?.totalAmount) ||
        !!this.isContractInDebt
    },
    isCreditProduct () {
      return [this.loanTypes.REVOLVING, this.loanTypes.CREDIT_CARD].includes(this.contract.typeCode)
    },
    isContractInDebt () {
      return this.contract.isInDebt
    },
    isCreditCardLoan () {
      return [this.loanTypes.CREDIT_CARD].includes(this.contract.typeCode)
    },
    isRevolvingLoan () {
      return [this.loanTypes.REVOLVING].includes(this.contract.typeCode)
    }
  },
  methods: {
    setLoading (flag) { this.isLoading = flag },
    onClose () {
      return isNull(this.onCloseRedirectUrl)
        ? this.$emit('close')
        : this.$router.push({ name: this.onCloseRedirectUrl })
    },
    async loadData () {
      this.setLoading(true)

      const [invoiceDetails, repaymentDetails] = await Promise.all([
        api.getInvoiceDetails(this.contract.id),
        api.getRepaymentDetails(this.contract.id)
      ])

      this.invoiceDetails = invoiceDetails
      this.repaymentDetails = repaymentDetails

      if (!isObject(this.invoiceDetails) && !this.isCreditProduct) {
        const nextPaymentDetails = await api.getNextPaymentDetails(this.contract.id)
        this.nextPaymentDetails = {
          ...nextPaymentDetails.nextPayment,
          ...{ referenceNumber: this.repaymentDetails.referenceNumber }
        }
      }

      this.setLoading(false)
    }
  }
}
