<template>
  <div>
    <bb-modal
      flavor="card"
      color="white"
      publicSize="m"
      :visible="visible"
      @close="onClose()"
      fullMobile
    >
      <template slot="heading"><translate>Update details</translate></template>
      <div class="update_details__content">
        <template v-if="canChangeIban">
          <bank-account-input
            v-if="accountType === 'principal'"
            type="principal"
            v-model="accountAndClearingNumber"
            @input="onBankAccountChange"
            :validatedAccounts="validatedAccounts"
            :permittedIbans="permittedIbans"
            :texts="bankInputTranslations"
            framed
            flavor="deposit"
          />
          <bank-account-input
            v-else
            type="iban"
            v-model="bankAccountNumber"
            :account-type-code="deposit.depositTypeCode"
            @input="onBankAccountChange"
            :validatedAccounts="validatedAccounts"
            :permittedIbans="permittedIbans"
            :internalAccounts="internalAccounts"
            :texts="bankInputTranslations"
            framed
            flavor="deposit"
          />
        </template>
        <div v-if="canChangeProlong" class="prolong m-t-30">
          <bb-toggle-switch
            name="prolongation"
            uncheckedColor="gray"
            v-model="selectedProlongStatus"
            @input="onProlongChange"
            :label="translations.prolongLabel"
            showStatus
            v-bind="toggleProps"
          />
        </div>
        <div v-if="showOwnerConfirmation" class="m-t-20 benefition-owner-confirmation">
          <bb-checkbox
            name="benefitionOwner"
            v-model="benefitionOwner"
            v-validate="'terms-validator|required'"
            background
            :label="translations.benefitionOwnerConfirmation"
            @change="setBenefitionOwner"
          />
        </div>
        <div v-if="showTermsConfirmation" class="m-t-20 benefition-terms-confirmation">
          <bb-checkbox
            name="benefitionTerms"
            v-model="benefitionTerms"
            v-validate="'terms-validator|required'"
            background
            @change="setBenefitionTerms"
          >
            <span v-sanitize.basic="translations.benefitionTermsConfirmation" />
          </bb-checkbox>
        </div>
      </div>
      <template #footer>
        <bb-button
          v-if="$screen.md"
          display="block"
          :disabled="!canSave"
          :loading="isLoading"
          type="submit"
          :label="translations.save"
          @click="save"
        />
        <bb-button
          v-else
          corner="sharp"
          display="block"
          size="lg"
          :disabled="!canSave"
          :loading="isLoading"
          type="submit"
          :label="translations.save"
          @click="save"
          />
      </template>
    </bb-modal>
    <sign-modal
      v-if="showSignModal"
      :deposit="deposit"
      :chosen-sign-method="chosenSignMethod"
      :signingRequestId="signingRequestId"
      @signingSuccessful="complete"
      @close="closeSignModal"
    />
    <transfer-details
      v-if="showTransferDetailsModal && transferDetails"
      :transferDetails="transferDetails"
      :translations="translations.transferDetails"
      :hideSum="true"
      @close="onSaved"
    >
      <bb-banner permanent visible class="m-b-25 m-t-15" type="info" v-if="accountValidation">
        {{$gettextInterpolate(translations.transferDetails.ibanChangeValidate, ibanValidationValues)}}
      </bb-banner>
    </transfer-details>
</div>
</template>

<script>
import { api } from '../api'
import SignModal from './SignModal'
import SigningMixin from '@/mixins/signingMixin'
import TermsLinksMixin from '@/mixins/termLinksMixin'
import BankInputMixin from '../mixins/bankInputMixin'
import BankAccountInput from '@/components/Input/BankAccountInput'
import { mapState, mapActions } from 'pinia'
import { UPDATE_CONTRACT_DETAILS } from '@/TrackingActions'
import TransferDetails from './TransferDetails'
import currencyDefinitions from '../utils/getCurrency'
import { useRootStore } from '../../../store/root'
import { useDepositStore } from '@deposits/store/depositStore'

export default {
  name: 'update-details',
  components: { SignModal, BankAccountInput, TransferDetails },
  mixins: [
    TermsLinksMixin,
    BankInputMixin,
    SigningMixin
  ],
  data () {
    return {
      validatedAccounts: [],
      bankAccountNumber: '',
      accountAndClearingNumber: {
        clearingNumber: '',
        principalAccount: ''
      },
      initialProlongStatus: this.deposit.automatedProlongCode === 'YES',
      selectedProlongStatus: this.deposit.automatedProlongCode === 'YES',
      benefitionOwner: false,
      benefitionTerms: false,
      isLoading: false,
      showSignModal: false,
      showTransferDetailsModal: false,
      chosenSignMethod: null,
      signingRequestId: null,
      accountValidation: null,
      transferDetails: null
    }
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false
    },
    deposit: {
      required: true
    },
    fieldType: {
      require: false,
      type: String
    }
  },
  computed: {
    ...mapState(useRootStore, ['isCompany', 'isChannelSE', 'isChannelLV']),
    ...mapState(useDepositStore, ['featureFlags', 'permittedIbans']),
    accountType () {
      return this.isChannelSE ? 'principal' : 'iban'
    },
    canChangeProlong () {
      return !this.featureFlags.disableAutomaticProlongation
    },
    canChangeIban () {
      return !this.featureFlags.disableTermDepositContractIbanChange
    },
    prolongHasChanged () {
      return this.selectedProlongStatus !== this.initialProlongStatus
    },
    accountNumberHasChanged () {
      const { principalAccount } = this.accountAndClearingNumber
      return !!(principalAccount !== this.deposit.principalAccount && principalAccount)
    },
    clearingNumberHasChanged () {
      const { clearingNumber } = this.accountAndClearingNumber
      return !!(clearingNumber !== this.deposit.clearingNumber && clearingNumber)
    },
    ibanHasChanged () {
      return !!(this.bankAccountNumber !== this.deposit.accountIban && this.bankAccountNumber)
    },
    bankAccountHasChanged () {
      return this.ibanHasChanged || this.accountNumberHasChanged || this.clearingNumberHasChanged
    },
    isValid () {
      return Object.keys(this.fields).every(key => this.fields[key].valid !== false)
    },
    canSave () {
      return this.isValid && !this.isLoading && (this.bankAccountHasChanged || this.prolongHasChanged) && this.confirmationsDone
    },
    showOwnerConfirmation () {
      return this.bankAccountHasChanged
    },
    showTermsConfirmation () {
      return this.prolongHasChanged || this.bankAccountHasChanged
    },
    confirmationsDone () {
      return [
        this.showOwnerConfirmation ? this.benefitionOwner : true,
        this.showTermsConfirmation ? this.benefitionTerms : true
      ].every(isDone => isDone)
    },
    translations () {
      return {
        save: this.$gettext('Save'),
        prolongLabel: this.$gettext('Automatic Prolongation:'),
        ibanLabel: this.$gettext('Update your IBAN:'),
        ibanFieldLabel: this.$gettext('IBAN'),
        ibanError: this.$gettext('Insert correct iban'),
        ibanCountryError: this.$gettext('Please enter a local IBAN'),
        confirmTermsError: this.$gettext('Please read the consent and approve it'),
        benefitionOwnerConfirmation: this.benefitionOwnerConfirmationText,
        benefitionTermsConfirmation: this.benefitionTermsConfirmationText,
        statusLabels: {
          CHANGED_CONDITIONS: this.$pgettext('prolong_status', 'You have chosen to prolong your contract with new conditions'),
          YES: this.$pgettext('prolong_status', 'Active'),
          NO: this.$pgettext('prolong_status', 'Disabled')
        },
        transferDetails: {
          title: this.$pgettext('update_details', 'Transfer Details'),
          subtitle: this.$pgettext('update_details', 'Here are your account details:'),
          ibanChangeValidate: this.$gettext('You have changed your %{ accountType }. In order for it to be validated, ' +
                'please transfer 0.01 %{ currency } to %{ servicingAccount } from %{ newAccount } account or' +
                ' contact customer service')
        }
      }
    },
    benefitionOwnerConfirmationTextPrivate () {
      return this.isChannelLV
        ? this.$pgettext('benefition_owner_confirmation_lv', 'I hereby confirm, that I am the owner of the money and that the aforementioned IBAN belongs to me.')
        : this.$pgettext('benefition_owner_confirmation', 'I hereby confirm, that I am the owner of the money and that the aforementioned IBAN belongs to me.')
    },
    benefitionOwnerConfirmationText () {
      return this.isCompany
        ? this.$pgettext('corporate_benefition_owner_confirmation', 'I hereby confirm, that I am the owner of the money and that the aforementioned IBAN belongs to me.')
        : this.benefitionOwnerConfirmationTextPrivate
    },
    benefitionTermsConfirmationTextPrivate () {
      return this.isChannelLV
        ? this.$gettextInterpolate(this.$pgettext('benefition_terms_confirmation_lv', 'I hereby confirm that I have read and agree with the <a target="_blank" href="%{termsLink}">General Terms and Conditions</a> of Deposit contract and the <a target="_blank" href="%{basicInformationLink}">Basic Information on the Protection of Deposits</a>'),
          this.getTermsLinks()
        )
        : this.$gettextInterpolate(this.$pgettext('benefition_terms_confirmation', 'I hereby confirm that I have read and agree with the <a target="_blank" href="%{termsLink}">General Terms and Conditions</a> of Deposit contract and the <a target="_blank" href="%{basicInformationLink}">Basic Information on the Protection of Deposits</a>'),
          this.getTermsLinks()
        )
    },
    benefitionTermsConfirmationText () {
      return this.isCompany
        ? this.$gettextInterpolate(this.$pgettext('corporate_benefition_terms_confirmation', 'I hereby confirm that I have read and agree with the <a target="_blank" href="%{termsLink}">General Terms and Conditions</a> of Deposit contract and the <a target="_blank" href="%{basicInformationLink}">Basic Information on the Protection of Deposits</a>'),
          this.getTermsLinks()
        )
        : this.benefitionTermsConfirmationTextPrivate
    },
    toggleProps () {
      const isDisabled = !['YES', 'NO'].includes(this.deposit.automatedProlongCode)

      return {
        disabled: isDisabled,
        onStatusLabel: this.translations.statusLabels.YES,
        offStatusLabel: isDisabled ? this.translations.statusLabels.CHANGED_CONDITIONS : this.translations.statusLabels.NO
      }
    },
    ibanValidationValues () {
      const { accountNumber, clearingNumber, servicingAccount } = this.accountValidation

      return {
        accountType: this.isChannelSE ? 'Account and Clearing Number' : 'IBAN',
        currency: currencyDefinitions(this.deposit.currencyCode),
        servicingAccount,
        newAccount: this.isChannelSE
          ? accountNumber + '-' + clearingNumber
          : accountNumber
      }
    }
  },
  methods: {
    ...mapActions(useRootStore, ['showSigningNotSupportedErrorModal']),
    ...mapActions(useDepositStore, ['fetchContractDetails', 'getPermittedIbanCountries']),
    onClose () {
      this.showTransferDetailsModal = false
      this.$emit('close')
    },
    async onSaved () {
      try {
        this.showTransferDetailsModal = false
        this.isLoading = true
        await this.fetchContractDetails({ contractId: this.$route.params.contractId })
      } finally {
        this.$emit('saved')
        this.isLoading = false
      }
    },
    setBenefitionTerms (value) {
      this.benefitionTerms = value
    },
    setBenefitionOwner (value) {
      this.benefitionOwner = value
    },
    onProlongChange (value) {
      this.$tracker.action(UPDATE_CONTRACT_DETAILS.TOGGLE_PROLONGATION_SWITCH, { setTo: value })
      this.selectedProlongStatus = value
    },
    onBankAccountChange (value) {
      this.$tracker.action(UPDATE_CONTRACT_DETAILS.CHANGE_IBAN, { setTo: value })
    },
    async save () {
      await this.$validator.validate()

      if (!this.canSave) {
        return
      }

      if (this.prolongHasChanged) {
        this.$tracker.action(UPDATE_CONTRACT_DETAILS.CONFIRM_PROLONGATION_SWITCH_CHANGE)
      }

      if (this.bankAccountHasChanged) {
        this.$tracker.action(UPDATE_CONTRACT_DETAILS.CONFIRM_CHANGE_IBAN)
      }

      this.isLoading = true
      const accountNumber = this.accountNumberHasChanged ? this.accountAndClearingNumber.principalAccount : undefined
      const clearingNumber = this.clearingNumberHasChanged ? this.accountAndClearingNumber.clearingNumber : undefined
      const iban = this.ibanHasChanged ? this.bankAccountNumber : null
      const prolongation = this.prolongHasChanged ? this.selectedProlongStatus : undefined

      try {
        const initResponse = await api.initContractChange(this.deposit.id, {
          iban,
          accountNumber,
          clearingNumber,
          prolongation,
          acceptBenefitionOwner: this.benefitionOwner,
          acceptBenefitionTerms: this.benefitionTerms
        })

        this.chosenSignMethod = initResponse.method
        this.signingRequestId = initResponse.signingRequestId

        this.$tracker.action(UPDATE_CONTRACT_DETAILS.START_SIGNING, {}, {
          contractNumber: this.deposit.contractNumber
        })

        this.showSignModal = true
      } finally {
        this.isLoading = false
      }
    },
    async complete () {
      this.isLoading = true
      this.$tracker.action(UPDATE_CONTRACT_DETAILS.COMPLETE_SIGNING, {}, {
        contractNumber: this.deposit.contractNumber
      })

      try {
        const [accountValidation, transferDetails] = await Promise.all([
          api.getValidIban(this.deposit.id),
          api.getTransferDetails(this.deposit.id)
        ])
        if (accountValidation?.waitingAccountValidation) {
          this.accountValidation = accountValidation
          this.transferDetails = transferDetails
          this.showTransferDetailsModal = true
        } else {
          await this.onSaved()
        }
      } finally {
        this.isLoading = false
        this.showSignModal = false
      }
    },
    closeSignModal () {
      this.showSignModal = false
      this.isLoading = false
    }
  },
  watch: {
    visible (isVisible) {
      if (!isVisible || !this.deposit) {
        return
      }

      this.$validator.reset()
      this.accountAndClearingNumber.principalAccount = this.fieldType === 'IBAN' ? '' : this.deposit.principalAccount
      this.accountAndClearingNumber.clearingNumber = this.fieldType === 'IBAN' ? '' : this.deposit.clearingNumber
      this.bankAccountNumber = this.fieldType === 'IBAN' ? '' : this.deposit.accountIban
      this.selectedProlongStatus = this.initialProlongStatus
      this.setBenefitionOwner(false)
      this.setBenefitionTerms(false)

      this.$tracker.action(UPDATE_CONTRACT_DETAILS.VIEW_MODAL, {}, {
        contractNumber: this.deposit.contractNumber
      })
    }
  },
  async mounted () {
    this.$validator.extend('terms-validator', {
      getMessage: () => this.translations.confirmTermsError,
      validate: value => !!value
    })

    await this.loadAccounts(this.deposit.depositTypeCode)
  }
}
</script>

<style lang="scss">
  .update_details {
    &__content {
      .prolong {
        border-top: 1px dashed $gray-70;
        padding-top: 15px;

        .toggle__label {
          text-transform: none;
        }
      }

      .benefition-owner-confirmation,
      .benefition-terms-confirmation {
        font-size: $font-size-small;

        a {
          color: $blue;
          text-decoration: none;
        }
      }
    }
  }
</style>
