<template>
  <bb-overlay visible centerVertically :constrained="false" color="dark" @close="show = false">
    <bb-spinner/>
  </bb-overlay>
</template>

<script>
import { api } from '@deposits/api'

export default {
  name: 'sign-with-signicat-deosits',
  props: {
    deposit: {
      required: true
    },
    signingRequestId: {
      required: false
    }
  },
  methods: {
    async initSigning () {
      const { url } = await api.startSignicatSigning(this.deposit.id, this.signingRequestId)
      document.location = url
    }
  },
  mounted () {
    this.initSigning()
  }
}
</script>
