<template>
  <layout-with-menu v-bind="layoutProps" class="horizontal-menu_layout">
    <div class="top">
      <div class="close-btn-wrapper">
        <bb-button
          color="gray"
          display="circle"
          inverted
          @click="backButtonClick"
          icon="ui-arrow-left"
          class="close-btn"
        />
        <div v-if="title" class="horizontal-menu_layout__title">{{title}}</div>
      </div>
      <div class="menu-wrapper">
        <horizontal-actions-menu
          v-if="tabRoutes"
          class="menu"
          v-bind="{tabRoutes, sticky: true, centered: true}"
        />
      </div>
    </div>
    <slot></slot>
  </layout-with-menu>
</template>

<script>
import '@bigbank/interface-components/dist/svg/ui/arrow-left'
import HorizontalActionsMenu from '@loan/components/HorizontalActionsMenu'
import { mapState } from 'pinia'
import { useRootStore } from '../store/root'

export default {
  name: 'horizontal-menu_layout',
  components: { HorizontalActionsMenu },
  props: {
    tabRoutes: {
      required: false,
      default: null
    },
    onBackButtonClick: {
      required: false
    },
    loading: {
      required: false,
      default: false
    },
    title: {
      required: false
    }
  },
  computed: {
    ...mapState(useRootStore, ['previousRoute', 'backButtonRouteNameOverride']),
    layoutProps () {
      return {
        hideMenu: true,
        hideFooter: true,
        loading: this.loading
      }
    }
  },
  methods: {
    backButtonClick () {
      const route = this.backButtonRouteNameOverride
        ? { name: this.backButtonRouteNameOverride }
        : this.onBackButtonClick(this.previousRoute ?? undefined)
      if (route) {
        this.$router.push(route)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  div.horizontal-menu_layout {
    background-color: $white;
    padding-top: 50px;

    @media (min-width: $desktop-view-breaking-point) {
      padding-top: 69px;
    }

    &__title {
      text-align: center;
      color: $navy;
      font-size: $mobile-default-font-size;
      font-family: $gotham-bold;
      line-height: 55px;

      @media (min-width: $desktop-view-breaking-point) {
        line-height: 70px;
      }
    }

    .top {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 2;
      background-color: $white;

      @media (min-width: $desktop-view-breaking-point) {
        border-bottom: 2px solid $gray-20;
      }
    }

    .menu-wrapper {
      max-width: 850px;
      margin: auto;
      position: relative;

      @media (min-width: $desktop-view-breaking-point) {
        margin-bottom: -2px;
      }
    }

    ::v-deep .menu {
      margin-top: 30px;
    }

    .close-btn-wrapper {
      width: 100%;
      height: 50px;

      @media (min-width: $desktop-view-breaking-point) {
        width: unset;
        height: unset;
      }

      .close-btn {
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 10;
        width: 30px;
        height: 30px;

        @media (min-width: $desktop-view-breaking-point) {
          top: 15px;
          width: 40px;
          height: 40px;
        }

        @media (min-width: 900px) {
          left: 51px;
        }
      }
    }
  }
</style>
