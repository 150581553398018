import Vue from 'vue'
import { defineStore } from 'pinia'
import { apm } from '@elastic/apm-rum'
import { Validator } from 'vee-validate'
import router from '../views'
import { request, loadScript } from './../plugins/utils'
import {
  PartialFailureNotificationKey,
  PartialFailureNotificationDurationInMs,
  isConfigLoaded
} from './config'
import { AuthMethod, CountryChannel, Language } from '@bigbank/dc-common/config'
import { AccountRouteName } from '../modules/account/const'
import { DepositRouteName, DepositType } from '../modules/deposits/const'
import { ROUTER_PATHS } from '../const'
import ErrorViewName from '@/types/error-view-name'
import LogoutReason from '@/types/logout-reason'
import AccountApi from '../modules/account/api'
import { useAccountStore } from '../modules/account/store/accountStore'
import { isNil, isNull } from 'lodash'
import datepickerLanguages from '@bigbank/interface-components/dist/src/_molecules/Datepicker/languages'
import { translationPicker } from '@/plugins/translations'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const { ErrorTextCode: BackendErrorTextCode } = require('../../server/error-text-code.enum')

export const useRootStore = defineStore('root', {
  state: () => {
    return {
      areTermsAccepted: false,
      isMaintenanceModeEnabled: false,
      isEnvironmentLoaded: isConfigLoaded(),
      isLoading: false,
      isLoggedIn: false,
      isCustomer: false,
      isOnboardingRequired: false,
      canSkipCurrentAccountFlow: true,
      hasCurrentAccount: false,
      isCompany: false,
      isSideMenuDisplayed: false,
      isDocumentUploadSkippedInConfirmData: false,
      isContractsCountLoading: false,
      isDepositsCountLoading: false,
      isInitialAuthCheckMade: false,
      isMobile: false,
      isDebtNotificationShown: false,
      openSavingDepositModal: {
        isVisible: false,
        showConfirmation: false,
        wasTriggered: false
      },
      username: '',
      company: null,
      customerId: null,
      sessionId: null,
      accessLevel: null,
      notificationsLastRequestedAt: null,
      sessionValidator: null,
      contractsCount: null,
      depositsCount: null,
      checkAuthenticationRequest: null,
      errorModal: null,
      backButtonRouteNameOverride: null,
      previousRoute: undefined,
      permissions: {},
      partialFailures: {},
      relatedEntities: [],
      notices: [],
      inactivityTime: 0,
      featureFlags: window.FEATURES ?? {},
      language: window.LANG ?? 'en',
      channel: window.CHANNEL,
      languages: window.LANGS ?? [],
      rawChannelConfig: window.CHANNEL_CONFIG ?? {}
    }
  },
  getters: {
    supportedLanguages (state) {
      return state.languages.map(lang => {
        return {
          label: lang.toUpperCase(),
          key: lang,
          active: state.language === lang
        }
      })
    },
    loginUrl () {
      // To be refactored once we support multiple login methods per country
      return '/'
    },
    locale (state) {
      return state.language
    },
    currency (state) {
      switch (state.channel) {
        case CountryChannel.SE:
          return 'SEK'
        case CountryChannel.BG:
          return 'BGN'
        default:
          return 'EUR'
      }
    },
    currencySymbol (state) {
      switch (state.channel) {
        case CountryChannel.SE:
          return 'kr'
        case CountryChannel.BG:
          return 'лв'
        default:
          return '€'
      }
    },
    datepickerLanguage (state) {
      // This is needed as IC has sv language se that is actually wrong
      return datepickerLanguages[state.language]
        ? state.language
        : (state.language === Language.sv ? 'se' : Language.en)
    },
    isChannelSE (state) {
      return state.channel === CountryChannel.SE
    },
    isChannelLV (state) {
      return state.channel === CountryChannel.LV
    },
    isChannelEE (state) {
      return state.channel === CountryChannel.EE
    },
    isCrossBorderCountry (state) {
      return [CountryChannel.DE, CountryChannel.AT, CountryChannel.NL].includes(state.channel)
    },
    isDebtNotificationHidden (state) {
      return !(state.contractsCount?.debt.showCreditCardInDebtWarning || state.contractsCount?.debt.showLoanInDebtWarning)
    },
    hasCountryStoppedIssuingLoans (state) {
      return [CountryChannel.BG, CountryChannel.SE].includes(state.channel)
    },
    channelConfig (state) {
      const channelConfig = state.rawChannelConfig

      if (state.isCompany && !isNil(channelConfig.corporate)) {
        channelConfig.phone = channelConfig.corporate.phone || channelConfig.phone
        channelConfig.email = channelConfig.corporate.email || channelConfig.email
        channelConfig.priceListUrl = channelConfig.corporate.priceListUrl || channelConfig.priceListUrl
        channelConfig.faqUrl = channelConfig.corporate.faqUrl || channelConfig.faqUrl
        channelConfig.helpUrl = channelConfig.corporate.helpUrl || channelConfig.helpUrl
      }

      return channelConfig
    }
  },
  actions: {
    setIsLoggedIn (isLoggedIn) {
      this.isLoggedIn = isLoggedIn
      if (!isLoggedIn && module?.hot) {
        document.location = '/login'
      }
    },
    setIsMobile (flag) { this.isMobile = flag },
    setContractsCount (count) {
      this.contractsCount = count
    },
    setDepositsCount (count) {
      this.depositsCount = count
    },
    addPartialFailureReason (reason) {
      this.partialFailures[reason] = 1
    },
    setSettings (settings) {
      this.featureFlags = settings.FEATURES
      this.channel = settings.CHANNEL
      this.rawChannelConfig = settings.CHANNEL_CONFIG
      this.language = settings.LANG
      this.languages = settings.LANGS
      this.environmentLoaded = true
      Vue.config.language = settings.LANG
      Validator.localize(settings.LANG)
    },
    setOpenSavingDepositModal (modalProps) {
      this.openSavingDepositModal = Object.assign({}, this.openSavingDepositModal, modalProps)
    },
    async loadEnvironment () {
      if (this.isEnvironmentLoaded) {
        return
      }

      try {
        await this.loadSettings()
        await loadScript(`/scripts.js?retry=${new Date().toISOString()}`)
      } finally {
        if (!isConfigLoaded()) {
          router.push(`/oops/${ErrorViewName.NO_CONNECTION}`)
        }
      }
    },
    async loadSettings () {
      this.isLoading = true
      return request('/settings', {
        method: 'get'
      })
        .then((settings) => {
          Object.entries(settings).forEach(([key, value]) => {
            window[key] = value
          })
          this.setSettings(settings)
        })
        .catch()
        .finally(() => {
          this.isLoading = false
        })
    },
    changeLanguage (lang) {
      this.isLoading = true
      request('/settings/language', {
        method: 'put',
        body: { lang }
      }).catch().then(() => {
        this.language = lang
        if (!lang) {
          console.error('Language is missing!')
        }
        Vue.config.language = lang
        Validator.localize(lang)
        this.isLoading = false
      })
    },
    toggleSideMenu () {
      window.dispatchEvent(new Event('toggleSideMenu', { bubbles: true }))
    },
    getContractsCount () {
      return this.getFeatureCount({
        countState: 'contractsCount',
        loaderState: 'contractsCountLoading',
        url: '/contract/api/contracts/count',
        isEnabled: this.featureFlags.myActiveLoans
      })
    },
    getDepositsCount () {
      return this.getFeatureCount({
        countState: 'depositsCount',
        loaderState: 'depositsCountLoading',
        url: '/deposit/api/deposits/count',
        isEnabled: this.featureFlags.myDeposits
      })
    },
    updateTermsState (accepted) {
      this.areTermsAccepted = accepted
    },
    setInitialAuthCheckMade () {
      this.isInitialAuthCheckMade = true
    },
    setIsDocumentUploadSkippedInConfirmData (isSkipped) {
      this.isDocumentUploadSkippedInConfirmData = isSkipped
    },
    async checkAuthentication (doNotUseState = true, forceFetchPermissions = false) {
      const accountStore = useAccountStore()
      let isUserLoggedIn = this.isLoggedIn

      if (isUserLoggedIn && !doNotUseState) {
        return isUserLoggedIn
      }

      // If there is already pending process, return that promise to avoid
      // Multiple parallel executions
      if (this.checkAuthenticationRequest) {
        return this.checkAuthenticationRequest
      }

      const checkUserStatus = async () => {
        try {
          const response = await request('/gw/verifyUser', {
            method: 'post',
            params: { forceFetchPermissions },
            errHandlerOpts: {
              throwOnAllErrors: true
            }
          })

          const {
            isLoggedIn,
            username,
            permissions,
            termsAccepted,
            customerId,
            accessLevel,
            notices,
            isOnboardingRequired,
            sessionId,
            canSkipCurrentAccountFlow,
            hasCurrentAccount
          } = response

          isUserLoggedIn = isLoggedIn
          this.isLoggedIn = isLoggedIn
          this.username = username
          this.permissions = permissions
          this.areTermsAccepted = termsAccepted
          this.isCustomer = !!customerId
          this.isOnboardingRequired = isOnboardingRequired
          this.customerId = customerId
          this.isCompany = response.isCompany
          this.company = response.company
          this.relatedEntities = response.relatedEntities ?? []
          this.inactivityTime = response.inactivityTimeInMilliseconds
          this.accessLevel = accessLevel
          this.notices = notices
          this.sessionId = sessionId
          this.canSkipCurrentAccountFlow = canSkipCurrentAccountFlow
          this.hasCurrentAccount = hasCurrentAccount

          this.disableMaintenanceMode()
          this.checkNotifications()

          if (this.featureFlags.enableUnverifiedCustomerAdditionalLimits) {
            accountStore.getVerificationData()
          }

          apm.setUserContext({ id: customerId })
        } catch (error) {
          if (error?.err_code === BackendErrorTextCode.MAINTENANCE_ENABLED) {
            this.isMaintenanceModeEnabled = true
          }
        } finally {
          if (!this.isInitialAuthCheckMade) {
            this.isInitialAuthCheckMade = true
          }

          // Remove the process promise from the state
          this.checkAuthenticationRequest = null
        }

        return isUserLoggedIn
      }

      // Store the proccess promise in state so it can be referenced when there are
      // multiple calls to this method at the same time
      this.checkAuthenticationRequest = checkUserStatus()

      return this.checkAuthenticationRequest
    },
    async checkNotifications () {
      if (this.isLoggedIn && isNull(this.notificationsLastRequestedAt)) {
        const accountStore = useAccountStore()
        const notifications = await AccountApi.getNotifications()

        if (Array.isArray(notifications)) {
          accountStore.setNotifications(notifications)
          this.notificationsLastRequestedAt = Date.now()
        }
      }
    },
    checkSession () {
      if (this.sessionValidator) {
        return
      }

      const validator = setInterval(
        async () => {
          if (!this.isLoggedIn) {
            return
          }

          const isLoggedIn = await this.checkAuthentication()

          if (!isLoggedIn) {
            router.push({ path: '/logout', query: { reason: LogoutReason.CHECK_AUTHENTICATION_FAILED } })
          }
        },
        30000
      )

      this.sessionValidator = validator
    },
    getTerms () {
      return request('/account/api/terms')
    },
    hideSideMenu () {
      this.isSideMenuDisplayed = false
    },
    checkTransactionsAllowed () {
      const { allowed, confirmData, sendToOnboardingFlow } = this.permissions?.transactions || {}

      if (!allowed) {
        if (sendToOnboardingFlow) {
          document.location = translationPicker(this.rawChannelConfig.confirmDepositDataUrl, this.language)
        } else if (confirmData) {
          router.push(ROUTER_PATHS.CONFIRM_DATA)
        } else {
          this.showTransactionalErrorModal()
        }
        return false
      }

      return true
    },
    hideErrorModal () {
      this.errorModal = null
    },
    showTransactionalErrorModal () {
      this.errorModal = {
        visible: true,
        title: Vue.prototype.$pgettext('transactional_error_modal', 'We need to review your personal details.'),
        content: Vue.prototype.$pgettext('transactional_error_modal', 'You will be able to perform transactions again in self-service once we confirm your personal details.'),
        modalType: 'transactional'
      }
    },
    showContactCustomerSupportErrorModal () {
      this.errorModal = {
        visible: true,
        title: Vue.prototype.$pgettext('contact_us_error_modal', 'Please contact us'),
        content: Vue.prototype.$pgettext('contact_us_error_modal', 'We require to double check your personal details before we can proceed'),
        modalType: 'contactCustomerSupport'
      }
    },
    showCannotOpenDepositsErrorModal () {
      this.errorModal = {
        visible: true,
        title: Vue.prototype.$pgettext('contact_us_error_modal', 'Please contact us'),
        content: Vue.prototype.$pgettext('contact_us_error_modal', 'You are currently not able to apply for deposit in Self Service, for further information, please contact our customer support.'),
        modalType: 'contactCustomerSupport'
      }
    },
    showSigningNotSupportedErrorModal (error = {}) {
      const texts = {
        [AuthMethod.EVROTRUST]: Vue.prototype.$pgettext('signing_not_supported_content_EVROTRUST', 'Please sign out and log in with Evrotrust to finish signing process.'),
        DEFAULT: Vue.prototype.$pgettext('signing_not_supported_content_DEFAULT', 'Please sign out and log in with other login method to finish signing process.')
      }

      this.errorModal = {
        visible: true,
        title: Vue.prototype.$pgettext('signing_not_supported_error_modal_title', 'You can not use this login method for signing.'),
        content: texts[error.signingMethod] || texts.DEFAULT,
        modalType: 'text'
      }
    },
    showSigningRequestExpiredErrorModal () {
      this.errorModal = {
        visible: true,
        title: Vue.prototype.$pgettext('signing_request_expired_modal', 'Modal title'),
        content: Vue.prototype.$pgettext('signing_request_expired_modal', 'Modal content'),
        cta: Vue.prototype.$pgettext('signing_request_expired_modal', 'Modal call to action button'),
        modalType: 'signing-request-expired-modal'
      }
    },
    showOpenSavingsDepositErrorModal (showConfirmation = false) {
      router.push({
        name: DepositRouteName.New,
        params: {
          depositType: DepositType.Demand
        }
      })
      this.setOpenSavingDepositModal({
        isVisible: true,
        showConfirmation,
        wasTriggered: true
      })
    },
    showOpenCurrentAccountErrorModal () {
      router.push({
        name: AccountRouteName.OpenCurrentAccount
      })
    },
    hideOpenSavingsDepositErrorModal () {
      this.setOpenSavingDepositModal({
        isVisible: false,
        showConfirmation: false
      })
    },
    setPreviousRoute (route) {
      this.previousRoute = route
    },
    setBackButtonRouteNameOverride (routeName) {
      this.backButtonRouteNameOverride = routeName
    },
    disableMaintenanceMode () {
      if (this.isMaintenanceModeEnabled) {
        this.isLoading = true
        this.isMaintenanceModeEnabled = false
        setTimeout(() => { this.isLoading = false }, 0)
      }
    },
    reportSuccessfulRequest (opts = {}) {
      if (this.partialFailures[opts.reason]) {
        delete this.partialFailures[opts.reason]
      }

      if (Object.keys(this.partialFailures).length === 0) {
        Vue.notify({ action: 'remove', key: PartialFailureNotificationKey })
      }
    },
    showPartialFailureNotification (opts = {}) {
      if (opts.reason) {
        this.addPartialFailureReason(opts.reason)
      }
      Vue.notify({
        type: 'error',
        duration: PartialFailureNotificationDurationInMs,
        text: Vue.prototype.$pgettext('partial_failure', 'We are facing some technical issues, some information and/or functions might not be available.'),
        update: true,
        reopen: true,
        key: PartialFailureNotificationKey
      })
    },
    getFeatureCount ({ countState, loaderState, url, isEnabled }) {
      if (this[countState] !== null || !isEnabled) {
        return Promise.resolve(this[countState])
      } else if (this[loaderState]) {
        return this[loaderState]
      }

      const request = window.fetch(window.location.origin + url, {
        credentials: 'include',
        method: 'get'
      })
        .then(response => {
          if (![200, 304].includes(response.status)) {
            return null
          }
          return response.json()
        })
        .then(response => {
          this[loaderState] = false
          this[countState] = response
          return response
        })
        .catch(() => {
          this[loaderState] = false
          return null
        })

      this[loaderState] = request

      return request
    }
  }
})
