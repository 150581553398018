<template>
  <layout v-bind="layoutProps" v-on="$listeners">
    <bb-select
      name="day"
      v-model="form.day"
      :label="translations.day"
      :data-vv-as="translations.day"
      :options="formattedOptions"
      v-validate="'required'"
    />
  </layout>
</template>

<script>
import { mapState } from 'pinia'
import Layout from './Layout.vue'
import { useRootStore } from '../../../../store/root'

const optionConfig = {
  FI: {
    DEFAULT: [1, 5, 10, 15, 20, 25]
  },
  LV: {
    DEFAULT: [1, 5, 10, 15, 20]
  },
  EE: {
    DEFAULT: [5, 10, 15, 25]
  },
  BG: {
    DEFAULT: [1, 5, 10, 15, 20, 25]
  },
  LT: {
    DEFAULT: [1, 5, 10, 15, 17, 20, 25]
  }
}

export default {
  components: { Layout },
  name: 'manage-loan-change-payment-day',
  data () {
    return {
      form: {
        description: undefined
      }
    }
  },
  props: {
    showLoader: {
      required: true
    },
    contract: {
      required: true
    }
  },
  computed: {
    ...mapState(useRootStore, ['channel']),
    translations () {
      return {
        title: this.$pgettext('manage_loan_change_payment_day', 'Apply for payment day change'),
        text: this.$pgettext('manage_loan_change_payment_day', 'Please choose suitable new payment day. Please note that when the date chosen is less than X days before the next payment day, the change will apply for the second payment day.'),
        buttonText: this.$pgettext('manage_loan_change_payment_day', 'Continue'),
        day: this.$pgettext('manage_loan_change_payment_day', 'Day')
      }
    },
    layoutProps () {
      return {
        visible: true,
        title: this.translations.title,
        text: this.translations.text,
        buttonText: this.translations.buttonText,
        showLoader: this.showLoader,
        data: {
          ...this.form
        }
      }
    },
    options () {
      const channelOptions = optionConfig[this.channel]

      return (channelOptions && (channelOptions[this.contract.loanTypeGroupCode] || channelOptions.DEFAULT)) || []
    },
    formattedOptions () {
      return this.options.map(item => ({
        text: item,
        value: item
      }))
    }
  }
}
</script>
