<template>
  <bb-modal v-bind="modalProps">
    <div class="header">{{ modalTitle }}</div>
    <div class="body">
      <bb-icon v-if="!openSavingDepositModal.showConfirmation" class="icon" name="illustration-vault" size="fluid" fill="mint-70" />
      <div class="text">{{ modalText }}</div>
    </div>
    <div slot="footer" class="modal-footer">
      <div class="logout-wrapper">
        <bb-button v-bind="skipButtonProps" @click="initiateSkip" />
      </div>
      <div class="continue-wrapper">
        <bb-button v-bind="continueButtonProps" @click="onContinue" />
      </div>
    </div>
  </bb-modal>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useRootStore } from '../store/root'
import LogoutReason from '@/types/logout-reason'
import '@bigbank/interface-components/dist/svg/illustration/vault'
import api from '@/api'

export default {
  data () {
    return {
      areActionButtonsDisabled: false
    }
  },
  computed: {
    ...mapState(useRootStore, ['isMobile', 'openSavingDepositModal', 'isCompany', 'featureFlags']),
    translations () {
      const translations = {
        modalTitle: this.$pgettext('open_savings_deposit_modal', 'Create your first saving deposit'),
        confirmLogoutModalTitle: this.$pgettext('open_savings_deposit_modal', 'Are you sure you want to close process?'),
        modalText: this.$pgettext('open_savings_deposit_modal', 'In order to continue using corporate Self-service please, proceed with opening of savings deposit account'),
        confirmLogoutModalText: this.$pgettext('open_savings_deposit_modal', 'If you will cancel deposit creation process all you progress will not be saved and we would log out from your account.'),
        createDeposit: this.$pgettext('open_savings_deposit_modal', 'Create deposit'),
        continue: this.$pgettext('open_savings_deposit_modal', 'Continue process'),
        skip: this.$pgettext('open_savings_deposit_modal', 'Skip'),
        logout: this.$pgettext('open_savings_deposit_modal', 'Log out')
      }

      if (this.isCompany) {
        Object.assign(translations, {
          modalTitle: this.$pgettext('corporate_open_savings_deposit_modal', 'Create your first saving deposit'),
          modalText: this.$pgettext('corporate_open_savings_deposit_modal', 'In order to continue using corporate Self-service please, proceed with opening of savings deposit account')
        })
      }

      return translations
    },
    modalTitle () {
      return this.openSavingDepositModal.showConfirmation ? this.translations.confirmLogoutModalTitle : this.translations.modalTitle
    },
    modalText () {
      return this.openSavingDepositModal.showConfirmation ? this.translations.confirmLogoutModalText : this.translations.modalText
    },
    modalProps () {
      return {
        flavor: this.isMobile ? 'card' : 'user',
        fullMobile: true,
        centerVertically: !this.isMobile,
        hideClose: true,
        staticOverlay: true,
        visible: this.openSavingDepositModal.isVisible,
        publicSize: 'l'
      }
    },
    skipButtonProps () {
      const canBeSkipped = this.featureFlags.enableSavingsDepositOptionalOffer

      const defaults = {
        color: 'gray',
        inverted: true,
        label: canBeSkipped ? this.translations.skip : this.translations.logout,
        disabled: this.areActionButtonsDisabled
      }

      if (this.isMobile) {
        return {
          ...defaults,
          class: 'w-100'
        }
      }

      return {
        ...defaults,
        class: 'w-100'
      }
    },
    continueButtonProps () {
      return {
        class: 'w-100',
        label: this.openSavingDepositModal.showConfirmation ? this.translations.continue : this.translations.createDeposit,
        disabled: this.areActionButtonsDisabled
      }
    }
  },
  methods: {
    ...mapActions(useRootStore, ['hideOpenSavingsDepositErrorModal']),
    async initiateSkip () {
      const canBeSkipped = this.featureFlags.enableSavingsDepositOptionalOffer

      if (!canBeSkipped) {
        this.initiateLogout()
        return
      }

      this.areActionButtonsDisabled = true
      await this.onContinue()
      this.$router.push({ path: '/' })
    },
    initiateLogout () {
      this.areActionButtonsDisabled = true
      this.$router.push({ path: '/logout', query: { reason: LogoutReason.OPEN_SAVINGS_DEPOSIT_MODAL } })
    },
    async onContinue () {
      this.hideOpenSavingsDepositErrorModal()
      await api.increaseHasSeenMandatoryOpenSavingsDepositModal()
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  color: $blue;
  font-weight: 500;
  font-size: $font-size-small;
  font-family: $gotham-medium;
  line-height: 18px;
  text-align: center;
  margin-bottom: 30px;
}

.body {
  display: flex;
  flex-direction: column;
  align-items: center;

  .icon {
    max-width: 250px;
  }

  .text {
    color: $gray;
    text-align: center;
    font-size: $font-size-small;
    font-family: $gotham-book;
    line-height: 18px;
    width: 333px;

    @media (max-width: $mobile-view-breaking-point) {
      width: auto;
      max-width: 275px;
    }

    margin-top: 35px;
  }
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  column-gap: 15px;
  padding: 10px;

  @media (max-width: $mobile-view-breaking-point) {
    padding: 10px;
    flex-direction: column;
    row-gap: 10px;
  }

  div {
    flex: 1 0 0;
  }
}
</style>
