function autoLaunchBankID (autostarttoken) {
  const custom = 'bankid:///?autostarttoken=' + autostarttoken + '&redirect=null'

  activate()

  function activate () {
    if (isMobile()) {
      document.location = custom
    } else {
      tryIframeApproach()
    }
  }

  function tryIframeApproach () {
    const iframe = document.createElement('iframe')
    iframe.style.border = 'none'
    iframe.style.width = '1px'
    iframe.style.height = '1px'
    iframe.style.position = 'fixed'
    iframe.src = custom
    document.body.appendChild(iframe)
    setTimeout(() => {
      iframe.remove()
    }, 10000)
  }
}

function isMobile () {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera

  if (/windows phone/i.test(userAgent)) {
    return true
  }

  if (/android/i.test(userAgent)) {
    return true
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return true
  }

  return false
}

export default autoLaunchBankID
