<template>
  <div class="layout-full-page">
    <full-spinner v-if="loading"></full-spinner>
    <slot v-else></slot>
  </div>
</template>

<script>
import FullSpinner from './FullSpinner'

export default {
  name: 'layout-full-page',
  components: { FullSpinner },
  props: {
    loading: {
      type: Boolean
    }
  }
}
</script>

<style lang="scss">
.layout-full-page {
  background-color: $white;
  min-height: 100vh;
}
</style>
