<template>
  <layout-with-menu class="reset-password" :hideMenu="true">
    <bb-navbar flavor="public" :logo="logo"/>
    <div class="reset-password__content">
      <div class="reset-password__icon m-t-35">
        <bb-icon name="illustration-no-account" fill="original" size="fluid" :animated="true"/>
      </div>
      <h4 class="reset-password__title m-l-10 m-r-10">{{ translations.title }}</h4>
      <bb-button class="m-t-40" :label="translations.buttonText" @click="redirect" />
    </div>
  </layout-with-menu>
</template>

<script>
import logo from '@bigbank/interface-components/dist/img/bigbank-dark@2x.png'

export default {
  name: 'reset-password',
  props: {
    token: String
  },
  data () {
    return {
      logo
    }
  },
  computed: {
    translations () {
      return {
        title: this.$pgettext('reset-password', 'Welcome to Self-Service'),
        buttonText: this.$pgettext('reset-password', 'Continue')
      }
    }
  },
  methods: {
    redirect () {
      window.location = `/api/login/reset-password?token=${this.token}`
    }
  }
}
</script>

<style lang="scss">
.reset-password {
  .bb-navbar__burger-logo-wrapper {
    @media (min-width: $desktop-bp) {
      margin-left: 130px;
    }
  }

  .bb-button {
    border-radius: 6px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  &__icon {
    max-width: 250px;

    @media (min-width: $desktop-bp) {
      max-width: rem(287px);
    }
  }

  &__title {
    font-family: $gotham-medium;
    color: $blue;
    max-width: 348px;
    font-size: 18px;
  }
}
</style>
