<template>
  <div class="hero-header" :class="{ narrow }">
    <bb-container class="hero-header__content">
      <div class="hero-header__content__title">
        <div v-if="backButtonVisible" @click="onGoBackClick" class="hero-header__content__back">
          <bb-button
            display="link"
            icon="ui-arrow-left"
            color="gray"
            noUnderline
          />
        </div>
        <h3>{{ title }}</h3>
      </div>
      <slot></slot>
    </bb-container>
  </div>
</template>

<script>
import '@bigbank/interface-components/dist/svg/ui/arrow-left'

export default {
  props: {
    title: {
      type: String
    },
    narrow: {
      type: Boolean,
      default: false
    },
    backButtonVisible: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    onGoBackClick () {
      this.$emit('goBack')
    }
  }
}
</script>

<style lang="scss" scoped>
.hero-header {
  background-color: $gray-20;
  display: flex;
  justify-content: center;
  align-items: center;

  &__content {
    max-width: 1140px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    min-height: 60px;

    @media (min-width: $desktop-view-breaking-point) {
      justify-content: space-between;
      flex-direction: column;
      align-items: flex-start;
    }

    &__title {
      display: flex;

      @media (min-width: $desktop-view-breaking-point) {
        padding-bottom: 50px;
        padding-top: 50px;
      }
    }

    h3 {
      font-family: $max-pro-demi-bold;
      color: $navy;
      margin: 0;
      display: flex;
    }

    &__back {
      display: flex;
      width: 24px;
      margin-right: 12px;
      align-items: center;
      justify-content: center;

      @media (min-width: $desktop-wide-bp) {
        display: none;
      }
    }
  }
}

.hero-header.narrow {
  .hero-header__content {
    max-width: 850px;
    padding-left: 20px;
  }
}
</style>
