<template>
  <div class="loan-schedule">
    <template v-if="!loading && contractInternal">
      <bb-tabs
        class="loan-schedule__tabs bg-mint"
        :key="selectedYear"
        background="mint"
        :hideOnScroll="false"
        :tabs="tabs"
        :value="activeTabId"
        @onTabClick="selectYear"
        centered
      />
      <div class="loan-schedule__content">
        <bb-transactions
          v-if="!showEmptyView"
          class="loan-schedule__transactions"
          :header="header"
          :data="data"
          :footer="footer"
          :currency="currency"
          :locale="locale"
          :debt="debt"
          @debtButtonClick="showInvoiceDetailsModal = true"
          @transactionItemButtonClick="clickButton"
          @transactionItemClick="clickButton"
        />
        <div v-if="showEmptyView" class="p-t-15">
          <box-with-icon icon="client-empty-cards">
            {{ emptyText }}
          </box-with-icon>
        </div>
      </div>
      <bb-list-group class="m-t-30 m-l-20 m-r-20 m-b-20 loan-schedule__totals" v-if="showTotal && contractInternal && !showEmptyView">
        <bb-list-item :title="translations.principal" extraCondensed>{{formatAmount(totals.principal)}}</bb-list-item>
        <bb-list-item :title="translations.interest" extraCondensed>{{formatAmount(totals.interest)}}</bb-list-item>
        <bb-list-item :title="translations.otherFees" extraCondensed>{{formatAmount(totals.fees)}}</bb-list-item>
        <bb-list-item :title="translations.totalAmount" extraCondensed>{{formatAmount(totals.total)}}</bb-list-item>
      </bb-list-group>
    </template>
    <div v-else class="loan-schedule__loader">
      <bb-spinner />
    </div>
    <schedule-modal v-if="this.selectedItem" v-bind="modal" @close="closeModal" />
    <component
      v-if="showInvoiceDetailsModal"
      :is="computedInvoiceDetailsModal"
      :contract="contractInternal"
      @close="showInvoiceDetailsModal = !showInvoiceDetailsModal"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs'
import api from '@loan/api'
import '@bigbank/interface-components/dist/svg/ui/clock-outline'
import '@bigbank/interface-components/dist/svg/ui/check-circle-outline'
import '@bigbank/interface-components/dist/svg/ui/alert-circle-outline'
import '@bigbank/interface-components/dist/svg/client/empty-cards'
import { mapState, mapActions } from 'pinia'
import { mapSingleItem, mapByYear } from '@loan/helpers/scheduleMapper'
import { mergeDeep } from '@/plugins/utils'
import ScheduleModal from '@loan/components/ScheduleModal'
import { formatMoneyWithCurrency } from '@/plugins/numformat'
import BoxWithIcon from '@/components/BoxWithIcon'
import ConsumerLoanPayBack from '@/modules/loan/views/ConsumerLoanPayBack'
import RevolvingLoanPayback from '@/modules/loan/views/revolving/PayBack'
import invoiceDetailsTogglerMixin from '@/mixins/invoiceDetailsTogglerMixin'
import { useRootStore } from '../../../store/root'
import { useLoanStore } from '@loan/store/loanStore'

export default {
  name: 'loan-schedule',
  components: { RevolvingLoanPayback, ScheduleModal, ConsumerLoanPayBack, BoxWithIcon },
  data () {
    return {
      schedule: [],
      selectedYear: null,
      selectedItemIndex: null,
      paymentDetails: null,
      loading: false,
      showInvoiceDetailsModal: false
    }
  },
  mixins: [invoiceDetailsTogglerMixin],
  props: {
    loadData: {
      required: true
    },
    contractId: {
      required: true
    },
    contract: {
      required: false
    },
    hasProgress: {
      default: true,
      required: false
    },
    translationOverrides: {
      default: () => ({}),
      required: false
    }
  },
  computed: {
    ...mapState(useRootStore, ['locale', 'currency']),
    ...mapState(useLoanStore, { contractFromState: 'contract' }),
    translations () {
      return mergeDeep({
        payNow: this.$pgettext('schedule', 'Pay Now'),
        totalDebt: this.$pgettext('schedule', 'TOTAL DEBT'),
        paymentStatus: this.$pgettext('schedule', 'Payment status'),
        principal: this.$pgettext('schedule', 'Principal'),
        interest: this.$pgettext('schedule', 'Interest'),
        obligationInterest: this.$pgettext('schedule', 'Obligation interest'),
        principalVat: this.$pgettext('schedule', 'VAT'),
        admFee: this.$pgettext('schedule', 'Administration fee'),
        conclusionFee: this.$pgettext('schedule', 'Contract conclusion fee'),
        altumFee: this.$pgettext('schedule', 'Altum fee'),
        otherFees: this.$pgettext('schedule', 'Other fees'),
        totalAmount: this.$pgettext('schedule', 'Total amount'),
        total: this.$pgettext('schedule', 'Total:'),
        totals: this.$pgettext('schedule', 'Totals'),
        paymentReceived: this.$pgettext('schedule', 'Payment received'),
        amountInDebt: this.$pgettext('schedule', 'Amount in debt'),
        totalScheduledAmount: this.$pgettext('schedule', 'Total scheduled amount'),
        paymentAmounts: {
          normal: this.$pgettext('schedule', 'Payment amount'),
          total: this.$pgettext('schedule_total', 'Payment amount')
        },
        scheduleTypes: {
          PAID: this.$pgettext('schedule_status', 'Paid'),
          PAID_LATE: this.$pgettext('schedule_status', 'Paid after due date'),
          DEBT: this.$pgettext('schedule_status', 'Payment delayed'),
          SCHEDULED: this.$pgettext('schedule_status', 'Scheduled'),
          CURRENT: this.$pgettext('schedule_status', 'Scheduled')
        },
        buttonLabels: {
          PAID: this.$pgettext('schedule', 'More Details'),
          PAID_LATE: this.$pgettext('schedule', 'More Details'),
          DEBT: this.$pgettext('schedule', 'Pay Now'),
          SCHEDULED: this.$pgettext('schedule', 'Invoice details'),
          CURRENT: this.$pgettext('schedule', 'Invoice details')
        },
        modal: {
          paidStatus: this.$pgettext('payment_status', 'Paid'),
          paidLateStatus: this.$pgettext('payment_status', 'Paid after due date'),
          title: {
            debt: this.$pgettext('modal_title_debt', 'Debt payment details'),
            invoice: this.$pgettext('modal_title_invoice', 'Invoice details'),
            paid: this.$pgettext('modal_title_paid', 'Schedule details'),
            'paid-late': this.$pgettext('modal_title_paid_late', 'Schedule details')
          }
        },
        emptyText: this.$pgettext('schedule', 'There are currently no schedule entries. As soon as you have schedule entries they appear here.')
      }, this.translationOverrides)
    },
    debt () {
      const debtProps = {
        status: 'error',
        icon: 'ui-alert-circle-outline',
        iconColor: 'red',
        statusColumn: {
          progress: this.translations.totalDebt,
          status: this.formatAmount(this.contractInternal.totalDebtAmount)
        },
        button: {
          label: this.translations.payNow,
          visible: true
        }
      }

      return this.contractInternal.isInDebt ? debtProps : undefined
    },
    showTotal () {
      return ['total', null].includes(this.selectedYear) && !this.hasPreMigrationData
    },
    data () {
      if (!this.showTotal) {
        return this.schedule
          .map((value, index) => {
            value.id = index
            return value
          })
          .filter(({ year }) => year === this.selectedYear)
          .map(schedule => mapSingleItem(schedule, this))
      }
      return mapByYear(this.schedule, this)
    },
    header () {
      const viewType = this.showTotal ? 'total' : 'normal'

      return {
        info: this.translations.paymentStatus,
        middleColumns: [
          this.translations.principal,
          this.translations.interest,
          this.translations.otherFees
        ],
        last: this.translations.paymentAmounts[viewType]
      }
    },
    totals () {
      return this.data.reduce((sum, item) => {
        sum.principal += item.calculations.principal
        sum.interest += item.calculations.interest
        sum.fees += item.calculations.fees
        sum.total += item.calculations.total
        return sum
      }, { principal: 0, interest: 0, fees: 0, total: 0 })
    },
    footer () {
      const sum = this.totals

      return {
        info: this.translations.total,
        middleColumns: [
          sum.principal,
          sum.interest,
          sum.fees
        ],
        last: sum.total
      }
    },
    tabs () {
      return [{
        id: 'total',
        title: this.translations.totals,
        active: !this.selectedYear || this.selectedYear === 'total'
      }]
        .concat(Object.values(this.schedule.reduce((years, item) => {
          const year = item.year.toString()

          if (!years[year]) {
            years[year] = {
              id: year,
              title: year,
              active: this.selectedYear === year
            }
          }
          return years
        }, {})))
        .filter(tab => {
          return !this.hasPreMigrationData || tab.id !== 'total'
        })
    },
    activeTabId () {
      return (this.tabs.find(tab => tab.active) || { id: '' }).id.toString()
    },
    currency () {
      if (this.schedule[0]) {
        return this.schedule[0].currencyCode
      }
      if (this.contractInternal) {
        return this.contractInternal.currencyCode
      }
      return null
    },
    selectedItem () {
      if (this.selectedItemIndex === null) {
        return null
      }
      return this.schedule[this.data[this.selectedItemIndex].id]
    },
    modal () {
      const item = this.selectedItem
      let principal = item.isProcessed ? item.actualPayments.principal.amountDue : item.scheduledPayments.principal
      let interest = item.isProcessed ? item.actualPayments.interest.amountDue : item.scheduledPayments.interest
      let feeComponents = Object.entries(item.scheduledPayments.feeComponents).map(([feeType, amount]) => {
        return {
          title: this.translations[feeType],
          amount
        }
      })
      const additionalComponents = []

      const modalConfig = {
        visible: true,
        type: 'invoice',
        totalAmount: item.actualPayments.total.amountToBePaid + (item.isProcessed ? 0 : item.scheduledPayments.total),
        currency: item.currencyCode,
        dueDate: item.paymentDate,
        paidDate: item.actualPayments.total.lastPaymentDate,
        components: [],
        paymentDetailsResolver: this.paymentDetailsResolver,
        received: [{
          title: this.translations.paymentReceived,
          amount: item.actualPayments.total.amountPaid
        }],
        productTypeCode: this.contractInternal.typeCode,
        translations: this.translations.modal
      }

      const calculateAmounts = (key) => {
        modalConfig.totalAmount = item.actualPayments.total[key]
        principal = item.actualPayments.principal[key]
        interest = item.actualPayments.interest[key]
        feeComponents = Object.keys(item.actualPayments.feeComponents).map(feeType => {
          return {
            title: this.translations[feeType],
            amount: item.actualPayments.feeComponents[feeType][key]
          }
        })
      }

      switch (this.selectedItem.statusCode) {
        case 'DEBT':
          modalConfig.type = 'debt'
          calculateAmounts('amountDue')
          if (item.actualPayments.total.amountPaid > 0) {
            modalConfig.totalAmount = item.actualPayments.total.amountToBePaid
            modalConfig.received = [{
              title: this.translations.paymentReceived,
              amount: item.actualPayments.total.amountPaid
            }, {
              title: this.translations.amountInDebt,
              amount: item.actualPayments.total.amountToBePaid,
              debt: true
            }]
            additionalComponents.push({
              title: this.translations.totalScheduledAmount,
              amount: item.actualPayments.total.amountDue
            })
          }
          break
        case 'PAID':
        case 'PAID_LATE':
          modalConfig.type = this.selectedItem.statusCode === 'PAID' ? 'paid' : 'paid-late'
          calculateAmounts('amountPaid')
          break
        case 'SCHEDULED':
        case 'CURRENT':
          modalConfig.totalAmount = item.actualPayments.total.amountToBePaid + (item.isProcessed ? 0 : item.scheduledPayments.total)
          additionalComponents.push({
            title: this.translations.otherFees,
            amount: item.actualPayments.feesTotal.amountDue,
            debt: true
          })
      }

      modalConfig.components = [{
        title: this.translations.principal,
        amount: principal
      }, {
        title: this.translations.interest,
        amount: interest
      }].concat(additionalComponents).concat(feeComponents)

      return modalConfig
    },
    isScheduleAvailable () {
      return this.schedule && this.schedule.length
    },
    hasPreMigrationData () {
      if (!this.isScheduleAvailable) {
        return null
      }
      return this.hasProgress && parseInt(this.schedule[0].scheduleNr.split('/')[0]) !== 1
    },
    firstDebtYear () {
      if (!this.isScheduleAvailable) {
        return null
      }
      return this.schedule
        .filter(({ statusCode }) => statusCode === 'DEBT')
        .map(({ year }) => year)
        .sort()[0]
    },
    emptyText () {
      return this.translations.emptyText
    },
    showEmptyView () {
      return this.data.length <= 0
    },
    contractInternal () {
      return this.contract ?? this.contractFromState
    }
  },
  methods: {
    ...mapActions(useLoanStore, ['getContract']),
    selectYear (event) {
      this.closeModal()
      this.selectedYear = event === 'total' ? null : event
    },
    defaultView () {
      if (!this.isScheduleAvailable) {
        return
      }
      if (this.firstDebtYear) {
        this.selectedYear = this.firstDebtYear.toString()
      }
      if (!this.hasPreMigrationData && !this.showTotal) {
        return
      }
      if (!this.contractInternal) {
        const nextPayment = this.schedule.find(({ statusCode }) => statusCode === 'SCHEDULED')
        if (nextPayment && nextPayment.paymentDate) {
          this.selectedYear = dayjs(nextPayment.paymentDate).year().toString()
        } else {
          this.selectedYear = this.tabs[this.tabs.length - 1].id
        }
        return
      }

      const nextPaymentDateYear = dayjs(this.contractInternal.nextPaymentDate).year().toString()
      const yearExists = !!this.tabs.filter(({ id }) => id === nextPaymentDateYear).length
      const isActive = !(this.contractInternal.isClosed || this.contractInternal.isTerminated)

      this.selectedYear = isActive && yearExists ? nextPaymentDateYear : 'total'
    },
    clickButton (itemIndex) {
      if (this.showTotal) {
        return
      }
      this.selectedItemIndex = itemIndex
    },
    paymentDetailsResolver () {
      return api.getRepaymentDetails(this.contractId)
    },
    formatAmount (amount, currency) {
      return formatMoneyWithCurrency(amount, currency || this.currency, this.locale)
    },
    closeModal () {
      this.selectedItemIndex = null
    }
  },
  watch: {
    schedule () {
      this.defaultView()
    },
    contractInternal () {
      this.defaultView()
    }
  },
  async mounted () {
    this.loading = true
    this.getContract(this.contractId)
    this.schedule = await this.loadData()
    this.loading = false
  }
}
</script>

<style lang="scss">
.loan-schedule {
  position: relative;
  padding-bottom: 30px;

  .bb-tabs__tab-content {
    display: none;
  }

  &__tabs {
    position: fixed;
    width: 100%;
    z-index: 10;

    .bb-tabs__scroll-wrapper {
      max-width: 855px;
      margin: auto;
    }
  }

  &__content {
    max-width: 855px;
    margin: auto;
    margin-top: 65px;
    width: 100%;

    @media (min-width: $desktop-view-breaking-point) {
      margin-top: 65px;
    }
  }

  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50vh;
  }

  &__totals {
    @media (min-width: $desktop-view-breaking-point) {
      display: none !important;
    }
  }
}
</style>>
