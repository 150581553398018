<script>
import api from '@account/api'
import SignWithSmartIdLoan from '@/modules/loan/components/signing/SignWithSmartId'
import { AuthMethod } from '@bigbank/dc-common/config'

export default {
  name: 'smart-id-signing-account',
  extends: SignWithSmartIdLoan,
  props: {
    contract: {
      required: false
    },
    signingRequestId: {
      required: true
    }
  },
  methods: {
    startSigning () {
      return api.initSigning(AuthMethod.SMART_ID, this.signingRequestId)
    },
    completeSigning () {
      return api.completeSigning(AuthMethod.SMART_ID, this.signingRequestId)
    }
  }
}
</script>
