<template>
  <bb-hero
    class="hero-header__wrapper"
    :color="color"
    ref="header"
    :style="styles"
  >
    <slot></slot>
  </bb-hero>
</template>

<script>
export default {
  name: 'fancy-hero-header',
  data () {
    return {
      isMobile: false,
      navbarHeight: 50,
      scrolledHeight: 0
    }
  },
  props: {
    color: {
      default: 'mint'
    }
  },
  created () {
    const mainDiv = document.getElementById('main')
    mainDiv?.addEventListener('scroll', this.trackScroll)
    mainDiv?.addEventListener('bubbleScroll', this.trackScroll)
    window.addEventListener('resize', this.handleWindowResize)
    this.handleWindowResize()
  },
  beforeDestroy () {
    const mainDiv = document.getElementById('main')
    mainDiv?.removeEventListener('scroll', this.trackScroll)
    mainDiv?.removeEventListener('bubbleScroll', this.trackScroll)
    window.removeEventListener('resize', this.handleWindowResize)
  },
  mounted () {
    this.$nextTick(() => {
      this.computeHeaderHeight()
    })
  },
  methods: {
    trackScroll (e) {
      this.scrolledHeight = e.target.scrollingElement ? e.target.scrollingElement.scrollTop : e.target.scrollTop
    },
    handleWindowResize () {
      this.isMobile = window.innerWidth < 768
      this.$nextTick(() => {
        this.computeHeaderHeight()
      })
    },
    computeHeaderHeight () {
      if (this.$refs?.header?.$el) {
        this.headerHeight = this.$refs.header.$el.offsetHeight
      }
    }
  },
  computed: {
    scrolledPercentage () {
      return Math.min(this.scrolledHeight, this.headerHeight) / this.headerHeight * 100
    },
    heroHeight () {
      return Math.max(this.navbarHeight, this.headerHeight - (this.scrolledPercentage / 100 * (this.headerHeight - this.navbarHeight)))
    },
    styles () {
      if (!this.isMobile || !this.scrolledHeight) {
        return {}
      }
      return {
        height: `${this.heroHeight}px`
      }
    }
  },
  watch: {
    scrolledPercentage (value) {
      this.$emit('scroll', value)
    }
  }
}
</script>

<style lang="scss">
.hero-header__wrapper {
  overflow: hidden;
}
</style>
