<template>
  <div class="account-section-box">
    <div class="account-section-box__icon">
      <bb-icon :name="icon" fill="original" size="fluid"></bb-icon>
    </div>
    <div class="account-section-box__title">{{title}}</div>
    <div class="account-section-box__description">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'account-section-box',
  props: {
    title: {
      required: true
    },
    icon: {
      required: true
    }
  }
}
</script>

<style lang="scss">
.account-section-box {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 15px;
  font-family: $gotham-medium;
  padding-top: 20px;

  @media (min-width: $desktop-view-breaking-point) {
    max-width: 225px;
  }

  &__icon {
    height: 40px;
    width: 40px;
    margin-right: 12px;

    circle {
      fill: $white;
    }

    path {
      &:nth-of-type(1) {
        fill: $white;
      }
    }
  }

  &__title {
    color: $gray;
    font-size: $default-font-size;
    font-weight: 500;
    line-height: 40px;
  }

  &__description {
    width: 100%;
    color: $gray-70;
    font-size: $font-size-smallest;
    line-height: 16px;
    margin-top: 14px;
  }
}
</style>
