const dateStringToInteger = date => {
  return (new Date(date)).getTime()
}

export const getActiveStatusPriority = deposit => {
  const status = deposit.showProlongButton && deposit.state === 'ACTIVE' ? 'ACTIVE_NO_PROLONG' : deposit.state

  return ['ACTIVE_NO_PROLONG', 'WAITING_SIGNING', 'WAITING_FUNDS', 'FORWARDED', 'PROCEEDING', 'ACTIVE'].indexOf(status)
}

export const sortActiveDeposits = (a, b) => {
  const aPrio = getActiveStatusPriority(a)
  const bPrio = getActiveStatusPriority(b)
  if (aPrio === bPrio && a.state !== 'ACTIVE') {
    return dateStringToInteger(a.activationDate) - dateStringToInteger(b.activationDate)
  } else if (aPrio === bPrio) {
    return dateStringToInteger(a.endDate) - dateStringToInteger(b.endDate)
  }
  return aPrio - bPrio
}

export const sortClosedDeposits = (a, b) => {
  return dateStringToInteger(b.closedSortingDate) - dateStringToInteger(a.closedSortingDate)
}
