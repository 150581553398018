<template>
  <svg  ref="face" class="smiley" width="100" height="100">
    <g class="face" :transform="'translate(' + (25 + eyeX) + ', ' + (35 + eyeY) + ')'">
      <g class="eyes" :class="{ blink }">
        <circle class="eye" cx="0" cy="0" :r="cuteness" />
        <g transform="translate(50, 0)">
          <circle class="eye" cx="0" cy="0" :r="cuteness" />
        </g>
      </g>
      <g class="mouth" transform="translate(-5, 20)">
        <path :d="'M10 10 C 20 ' + (10 + happiness) + ', 40 ' + (10 + happiness) +', 50 10'" stroke-width="6" fill="transparent"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'smiley-face',
  data () {
    return {
      blink: false,
      angleDegrees: 0,
      eyeOffset: 15
    }
  },
  props: {
    faceAngle: {
      type: Number,
      default: 0
    },
    happiness: {
      type: Number,
      default: 0,
      max: 10,
      min: 10
    },
    cuteness: {
      type: Number,
      default: 6
    }
  },
  mounted () {
    setInterval(() => {
      this.blink = true
      setTimeout(() => { this.blink = false }, 100)
    }, 3000)
  },
  computed: {
    eyeX () {
      return this.eyeOffset * Math.cos(this.angle)
    },
    eyeY () {
      return this.eyeOffset * Math.sin(this.angle)
    },
    angle () {
      return -((this.faceAngle + 0.25) * 2 * Math.PI)
    }
  }
}
</script>

<style lang="scss" scoped>
  .smiley {
    overflow: visible;

    .face {
      stroke: white;

      .eye {
        fill: white;
      }

      .blink .eye {
        transform: scale(1, 0.2);
      }
    }
  }
</style>
