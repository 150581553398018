<template>
  <div v-show="hasMultipleLanguages" class="align-left f-small language-switcher">
    <bb-dropdown no-min-width>
      <template #trigger>
        <bb-button
        :label="activeLanguage && activeLanguage.label"
        color="gray"
        icon-after="ui-menu-down"
        no-background
      />
      </template>
      <bb-dropdown-item
        v-for="lang in inactiveLanguages"
        :key="lang.key" :label="lang.label"
        class="language-switcher__item"
        @click="onLanguageClick(lang)"
      />
    </bb-dropdown>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useRootStore } from '../store/root'

export default {
  name: 'language-switcher',
  computed: {
    ...mapState(useRootStore, ['supportedLanguages']),
    hasMultipleLanguages () {
      return this.supportedLanguages?.length > 1
    },
    activeLanguage () {
      return (this.supportedLanguages ?? []).find(val => val.active) ?? null
    },
    inactiveLanguages () {
      return (this.supportedLanguages ?? []).filter(val => !val.active)
    }
  },
  methods: {
    ...mapActions(useRootStore, ['changeLanguage']),
    onLanguageClick (language) {
      this.changeLanguage(language.key)
    }
  }
}
</script>
<style lang="scss">
.language-switcher {
  line-height: 1;

  &__toggle {
    cursor: pointer;

  }
}
</style>
