<template>
  <eparaksts-signing
    visible
    :start-signing="startSigning"
    :complete-signing="completeSigning"
    v-on="$listeners"
  />
</template>

<script>
import api from '@loan/api'
import EparakstsSigning from '@/components/signing/EparakstsSigning'

export default {
  components: { EparakstsSigning },
  props: {
    contract: {
      required: true
    },
    signingRequestId: {
      required: true
    }
  },
  methods: {
    startSigning () {
      const redirectUrl = window.location.origin + this.$router.currentRoute.path
      return api.startEparakstsSigning(this.contract.id, this.signingRequestId, redirectUrl)
    },
    completeSigning () {
      return api.completeEparakstsSigning(this.contract.id, this.signingRequestId)
    }
  }
}
</script>
