<template>
  <div class="m-t-15 m-b-25">
    <bb-icon name="ui-alert-circle-outline" fill="yellow" size="24"/>
    <span class="m-l-20 f-smallest"><translate>The changes you've made are waiting to be confirmed.</translate></span>
  </div>
</template>

<script>
import '@bigbank/interface-components/dist/svg/ui/alert-circle-outline'

export default {
  name: 'pending-change-notice'
}
</script>
