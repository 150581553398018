import { api } from '../api'
import { DepositType } from '@deposits/const'
import { mapActions } from 'pinia'
import { useDepositStore } from '@deposits/store/depositStore'

export default {
  data () {
    return {
      validatedAccounts: [],
      savingsDepoIbans: []
    }
  },
  computed: {
    internalAccounts () {
      if (!this.savingsDepoIbans || this.savingsDepoIbans.length <= 0) {
        return []
      }

      return [{
        text: this.$pgettext('choosing_iban', 'Savings deposit account'),
        options: this.savingsDepoIbans
      }]
    },
    bankInputTranslations () {
      return {
        externalAccounts: this.$pgettext('choosing_iban', 'External accounts')
      }
    }
  },
  methods: {
    ...mapActions(useDepositStore, ['getPermittedIbanCountries']),
    async loadAccounts (accountTypeCode) {
      const [validatedAccounts] = await Promise.all([
        api.getValidatedAccounts(),
        this.loadSavingsDepoIbans(),
        this.getPermittedIbanCountries({ accountTypeCode })
      ])
      this.validatedAccounts = validatedAccounts
    },
    async loadSavingsDepoIbans () {
      if (this.deposit?.depositType === DepositType.Demand) {
        return null
      }

      this.savingsDepoIbans = (await api.getSavingsDepoIbans()).map(iban => ({
        accountNumber: iban
      }))
    }
  }
}
