import { useRootStore } from '../store/root'

export default (next, urls) => {
  const rootStore = useRootStore()
  const { myActiveLoans, myDeposits, enableDashboard } = rootStore.featureFlags
  const { URL_MY_ACTIVE_LOANS, URL_MY_DEPOSITS, URL_MY_ACCOUNT, URL_DASHBOARD } = urls
  const defaultUrl = myActiveLoans ? URL_MY_ACTIVE_LOANS : (myDeposits ? URL_MY_DEPOSITS : URL_MY_ACCOUNT)

  if (enableDashboard) {
    return next(URL_DASHBOARD)
  } else if (myDeposits && !myActiveLoans) {
    return next(URL_MY_DEPOSITS)
  } else if (myActiveLoans && !myDeposits) {
    return next(URL_MY_ACTIVE_LOANS)
  }

  rootStore.isLoading = true

  Promise.all([
    rootStore.getContractsCount(),
    rootStore.getDepositsCount()
  ])
    .then(([loans, deposits]) => {
      if (rootStore.hasCountryStoppedIssuingLoans && loans?.activeContracts === 0) {
        return next(URL_MY_DEPOSITS)
      } else if (loans?.applications) {
        return next(URL_MY_ACTIVE_LOANS)
      } else if (deposits && (deposits.applications || deposits.hasPendingApplication)) {
        return next(URL_MY_DEPOSITS)
      } else if (loans?.activeContracts) {
        return next(URL_MY_ACTIVE_LOANS)
      } else if (deposits?.activeContracts) {
        return next(URL_MY_DEPOSITS)
      } else if (loans?.total) {
        return next(URL_MY_ACTIVE_LOANS)
      } else if (deposits?.total) {
        return next(URL_MY_DEPOSITS)
      } else {
        return next(defaultUrl)
      }
    })
    .catch(() => {
      return next(defaultUrl)
    })
    .then(() => {
      rootStore.isLoading = false
    })
}
