<template>
  <layout-with-menu>
    <div class="apply-through-partner__header" aria-label="Apply through partner" />
    <div class="apply-through-partner__container">
      <bb-heading
        class="apply-through-partner__container--header"
        :title="translations.title"
        :topic="translations.topic"
      />
      <div class="apply-through-partner__container--explanation" v-sanitize.basic="translations.explanation" />
      <div class="apply-through-partner__container--arguments">
        <div class="apply-through-partner__container--arguments-argument"
          v-for="(argument, index) in filteredArguments"
          :key="index"
        >
          <bb-icon name="ui-check" size="24" fill="mint" />
          <span>{{ argument }}</span>
        </div>
      </div>
      <bb-button
        :to="translations.cta.link"
        target="_blank"
        size="lg"
        :label="translations.cta.text"
        class="apply-through-partner__container--cta"
      />
    </div>
  </layout-with-menu>
</template>

<script>
export default {
  name: 'apply-through-partner-view',
  computed: {
    translations () {
      return {
        topic: this.$pgettext('sales_through_partner', 'Small header'),
        title: this.$pgettext('sales_through_partner', 'Main header'),
        explanation: this.$pgettext('sales_through_partner', '<p>Explanation paragraph 1</p><p>Explanation paragraph 2</p>'),
        arguments: [
          this.$pgettext('sales_through_partner', 'Main sales argument'),
          this.$pgettext('sales_through_partner', 'Second sales argument'),
          this.$pgettext('sales_through_partner', 'Third sales argument'),
          this.$pgettext('sales_through_partner', 'Fourth sales argument'),
          this.$pgettext('sales_through_partner', 'Fifth sales argument')
        ],
        cta: {
          link: this.$pgettext('sales_through_partner', 'https://actionbuttonlink.se'),
          text: this.$pgettext('sales_through_partner', 'Action button text')
        }
      }
    },
    filteredArguments () {
      return this.translations.arguments.filter(argument => argument.trim() !== '')
    }
  }
}
</script>

<style lang="scss" scoped>
  .apply-through-partner {
    &__header {
      background: 50% 70% / cover url("./assets/img/apply-through-partner-header.jpg");
      width: 100%;
      min-height: 352px;

      @media (max-width: $breakpoint-sm) {
        min-height: 275px;
      }
    }

    &__container {
      margin: 0 auto;
      padding: 45px 145px;
      color: $gray;

      > :not(:last-child) {
        margin-bottom: 45px;
      }

      &--header {
        max-width: 415px;
        font-size: $h2-size;
      }

      &--explanation {
        max-width: 850px;

        ::v-deep p:not(:last-child) {
          margin-bottom: 24px;
        }
      }

      &--arguments {
        &-argument {
          font-size: $font-size-small;

          > svg {
            margin-right: 20px;
          }

          &:first-of-type {
            font-family: $gotham-bold;
          }
        }

        > :not(:last-child) {
          margin-bottom: 20px;
        }
      }

      @media (max-width: $breakpoint-md) {
        padding: 45px 20px;
      }

      @media (max-width: $breakpoint-sm) {
        &--header {
          max-width: 330px;
          font-size: $h2-size-mobile;
        }

        &--cta {
          width: 100%;
        }
      }
    }
  }
</style>
