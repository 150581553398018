<template>
  <bb-modal
    visible
    @close="$emit('close')"
    v-bind="modalProps"
    class="deposit_withdraw__close-confirmation">
    <template #heading>
      <div class="deposit_withdraw__title">
        <translate translate-context="withdraw_modal_title">Withdraw</translate>
      </div>
    </template>
    <div class="deposit_withdraw__content">
      <div class="illustration">
        <bb-icon class="icon" name="illustration-vault-open" size="fluid" fill="mint-70" />
      </div>
      <div class="m-t-25 text-center">
        <p class="color-gray f-small">
          <translate translate-context="withdraw_modal">You are about to withdraw all the funds from your acount.</translate>
        </p>
        <bb-banner
          visible
          permanent
          type="info"
          class="color-gray m-t-25 text-left"
        >
          <span v-sanitize.basic="allTranslations.termDepoInfo" />
          <span class="p-l-5">
            <bb-button display="link" class="check-here-link" :to="toTermDeposits">{{ allTranslations.checkHere }}</bb-button>
          </span>
        </bb-banner>
        <p class="m-t-45 f-small color-gray f-gotham-medium">
          <translate translate-context="withdraw_modal">Would you like also to close your demand deposit contract?</translate>
        </p>
      </div>
    </div>
    <div slot="footer" class="deposit_withdraw__close-confirmation__buttons">
      <bb-button data-testid="confirm-close-demand-deposit-no" inverted color="blue" :label="translations.no" @click="$emit('closeContract', false)" />
      <bb-button data-testid="confirm-close-demand-deposit-yes" inverted color="blue" :label="translations.yes" @click="$emit('closeContract', true)" />
    </div>
  </bb-modal>
</template>

<script>
import '@bigbank/interface-components/dist/svg/illustration/vault-open'
import { DepositRouteName } from '@deposits/const'

export default {
  props: {
    translations: {
      required: true
    },
    modalProps: {
      required: true
    }
  },
  computed: {
    allTranslations () {
      return {
        termDepoInfo: this.$pgettext('withdraw_form_close_contract', 'Please make sure, that this Savings deposit is not used as servicing account for term deposits.'),
        checkHere: this.$pgettext('withdraw_form_close_contract', 'Check here'),
        ...this.translations
      }
    },
    toTermDeposits () {
      return {
        name: DepositRouteName.TermDeposits
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.illustration {
  width: 70%;
  margin: auto;
}

.check-here-link {
  text-decoration: underline;
}
</style>
