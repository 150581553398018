<template>
  <div class="notifications">
    <transition v-for="notification in mappedItems" :key="notification.id" name="slide">
      <bb-banner
        class="notification"
        name="notification-banner"
        :type="notification.type"
        :visible="notification.isVisible"
        :title="notification.title"
        @close="remove(notification, true)"
      >
        <component
          v-for="(item, k) in splitStringIntoTextAndLinks(notification.text)"
          :key="k"
          :is="item.component"
          v-bind="item.props">
          {{ item.content }}
        </component>
        <bb-link v-if="notification.link && notification.link.visible" :to="notification.link.route">
          {{notification.link.name}}
        </bb-link>
      </bb-banner>
    </transition>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { events } from './events'
import { AccountRouteName } from '@account/const'
import { splitTextAndLinks } from '../../plugins/utils'
import { CustomerNotificationMessageType } from '@bigbank/dc-common/classes/enums/customer-notification-class.enums'
import api from '@account/api'
import { DepositRouteName } from '@deposits/const'
import { useRootStore } from '@/store/root'
import { useAccountStore } from '@account/store/accountStore'

const Id = (i => () => i++)(0)
const STATE = {
  IDLE: 0,
  REMOVED: 2
}

export default {
  name: 'notifications-holder',
  data () {
    return {
      STATE,
      items: []
    }
  },
  computed: {
    ...mapState(useRootStore, ['isCompany', 'notices', 'language']),
    ...mapState(useAccountStore, ['notifications']),
    translations () {
      return {
        textsByKey: {
          noValidIdDocument: this.$gettextInterpolate(this.$pgettext('notifications_corporate', 'Your ID document is missing. Please upload your document in <a to="%{to}">account settings.</a>'), {
            to: this.$router.resolve({ name: AccountRouteName.Documents }).href
          }),
          idDocumentExpiring: this.$gettextInterpolate(this.$pgettext('notifications_corporate', 'Your ID document is about to expire. Please upload your document in <a to="%{to}">account settings.</a>'), {
            to: this.$router.resolve({ name: AccountRouteName.Documents }).href
          }),
          companyDataSheetExpiring: this.$gettextInterpolate(this.$pgettext('notifications_corporate', 'Your “Customer data sheet” is about to expire. Please upload your document under <a to="%{to}">account settings.</a>'), {
            to: this.$router.resolve({ name: AccountRouteName.Documents }).href
          }),
          companyDocumentsExpiring: this.$gettextInterpolate(this.$pgettext('notifications_corporate', 'Your documents are about to expire. Please upload your documents under <a to="%{to}">account settings.</a>'), {
            to: this.$router.resolve({ name: AccountRouteName.Documents }).href
          }),
          noValidIdDocumentPrivateCustomer: this.$gettextInterpolate(this.$pgettext('notifications_private', 'Your ID document is missing. Please upload your document in <a to="%{to}">account settings.</a>'), {
            to: this.$router.resolve({ name: AccountRouteName.Documents }).href
          }),
          idDocumentExpiringPrivateCustomer: this.$gettextInterpolate(this.$pgettext('notifications_private', 'Your ID document is about to expire. Please upload your document in <a to="%{to}">account settings.</a>'), {
            to: this.$router.resolve({ name: AccountRouteName.Documents }).href
          })
        }
      }
    },
    mappedItems () {
      return this.items.map(notification => {
        const settingsOverride = this.dynamicSettings[notification.key] ?? undefined

        return {
          ...notification,
          // This can be refactored to use the same logic as V2 notifications, after we have migrated all notifications
          // From selfservice backend to account component backend
          isVisible: notification.state === STATE.IDLE && (settingsOverride?.isVisible ?? true)
        }
      })
    },
    dynamicSettings () {
      const isConfirmDataFlow = this.$route.name === AccountRouteName.ConfirmData
      const isDepositApplication = this.$route.name === DepositRouteName.New
      const isVisible = !isConfirmDataFlow && !isDepositApplication

      return {
        noValidIdDocument: {
          isVisible
        },
        idDocumentExpiring: {
          isVisible
        },
        companyDataSheetExpiring: {
          isVisible: isVisible && this.isCompany
        }
      }
    }
  },
  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler () {
        if (this.$route.path.startsWith('/logout') === true) {
          this.removeAll()
        } else {
          this.handleNotices('v2')
        }
      }
    },
    notices () {
      this.handleNotices('v1')
    },
    notifications: function () {
      this.handleNotices('v2')
    },
    language () {
      this.handleNotices('v1')
      this.handleNotices('v2')
    }
  },
  methods: {
    add (event) {
      if (event.clean || event.clear) {
        this.removeAll()
        return
      }

      const { uuid, text, type, duration, link, update = false, key, version, messageType } = event

      const item = {
        id: Id(),
        state: STATE.IDLE,
        update,
        uuid,
        text,
        type,
        link,
        key,
        version,
        messageType
      }

      if (duration >= 1 && duration !== 'forever') {
        item.timer = setTimeout(() => {
          this.remove(item)
        }, duration)
      }

      const position = this.getPositionByKey(key)

      if (update && position >= 0) {
        const currentState = this.items[position].state
        item.state = event.reopen && currentState === STATE.REMOVED ? STATE.IDLE : currentState
        this.items.splice(position, 1, item)
      } else {
        this.items.push(item)
      }
    },
    async remove (item, initiatedByCustomerClick = false) {
      clearTimeout(item.timer)
      this.items = this.items.map((i) => i.key === item.key ? { ...item, state: STATE.REMOVED } : i)
      this.clean()

      if (initiatedByCustomerClick && item.version === 2) {
        try {
          await api.markNotificationAsRead(item.uuid)
        } catch (err) {
          console.error('Unable to mark notification as read', err)
        }
      }
    },
    removeByKey (key) {
      const position = this.getPositionByKey(key)
      if (position >= 0) {
        this.remove(this.items[position])
      }
    },
    removeAll () {
      this.items.forEach(this.remove)
    },
    clean () {
      this.items = this.items.filter(v => v.state !== STATE.REMOVED || v.update === true)
    },
    getPositionByKey (key) {
      if (!key) {
        return -1
      }
      return this.items.findIndex(notification => notification.key === key)
    },
    handleNotices (version = 'v1') {
      if (version === 'v1') {
        this.notices.forEach(notice => {
          const message = notice.messages?.[this.language] ?? notice?.messages?.default

          if (notice?.isEnabled) {
            this.$notify({
              text: message ?? this.translations.textsByKey[notice.key],
              type: notice.type,
              update: true,
              key: notice.key
            })
          } else {
            this.$notify({ action: 'remove', key: notice.key })
          }
        })
      }
      if (version === 'v2') {
        Object.entries(this.notifications).forEach(([uuid, notification]) => {
          if (!notification.isBanner()) {
            return
          }

          const { messageType } = notification.getOptions()
          const { key, type, duration } = notification.getOptions().banner

          if (notification.isVisible(window.location)) {
            this.$notify({
              text: this.notificationsV2TranslationsByMessageType(messageType),
              update: true,
              duration: duration ?? 'forever',
              uuid,
              reopen: true,
              version: 2,
              messageType,
              type,
              key
            })

            return
          }

          this.$notify({ action: 'remove', key })
        })
      }
    },
    splitStringIntoTextAndLinks (message) {
      return splitTextAndLinks(message).map(item => {
        return {
          component: item.type === 'link' ? 'bb-link' : 'span',
          props: item.type === 'link' ? { to: item.attributes.to } : {},
          content: item.content
        }
      })
    },
    notificationsV2TranslationsByMessageType (messageType) {
      if (this.isCompany) {
        return {
          [CustomerNotificationMessageType.YourIdDocumentExpiresSoon]: this.translations.textsByKey.idDocumentExpiring,
          [CustomerNotificationMessageType.YourIdDocumentMissing]: this.translations.textsByKey.noValidIdDocument
        }[messageType] ?? null
      } else {
        return {
          [CustomerNotificationMessageType.YourIdDocumentExpiresSoon]: this.translations.textsByKey.idDocumentExpiringPrivateCustomer,
          [CustomerNotificationMessageType.YourIdDocumentMissing]: this.translations.textsByKey.noValidIdDocumentPrivateCustomer
        }[messageType] ?? null
      }
    }
  },
  mounted () {
    events.$on('add', this.add)
    events.$on('remove', this.removeByKey)
  }
}
</script>

<style lang="scss" scoped>
.notifications {
  position: fixed;
  top: 70px;
  right: 5%;
  max-width: 90%;
  z-index: 1501;

  @media (min-width: $desktop-wide-bp) {
    top: 40px;
    right: 35px;
    max-width: 500px;
  }

  .notification {
    margin-bottom: 10px;
  }
}
</style>

<style lang="scss">
.nofification-text a {
  color: $mint;
  text-decoration: underline;
}

.slide-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
  opacity: 1;
}

.slide-enter,
.slide-leave-to {
  opacity: 0;
}
</style>
