<template>
  <div class="main-container">
    <div class="sub-container">
      <template v-if="hasCards">
        <div class="title">{{ translations.title }}</div>
        <bb-product-cards
            size="justified"
            :cards="cards"
            v-model="product"
        >
          <template #TERM>
            <div class="up-to__container">
              <div class="up-to__label">{{ translations.upTo }}</div>
              <div class="up-to__amount">{{ maxAmount }}</div>
            </div>
            <div class="info__container">
              <bb-list-item
                v-for="termDepositArgument in translations.termDepositArguments"
                :key="termDepositArgument"
                :title="termDepositArgument"
                :title-uppercase="false"
                condensed
                bullet
              />
            </div>
          </template>
          <template #DEMAND>
            <div class="up-to__container">
              <div class="up-to__label">{{ translations.upTo }}</div>
              <div class="up-to__amount">{{ maxAmount }}</div>
            </div>
            <div class="info__container">
              <bb-list-item
                v-for="demandDepositArgument in translations.demandDepositArguments"
                :key="demandDepositArgument"
                :title="demandDepositArgument"
                :title-uppercase="false"
                condensed
                bullet
              />
            </div>
          </template>
        </bb-product-cards>
        <div class="continue-button-container">
          <continue-button
              :text="continueText"
              :handleClick="goToNextStep"
          ></continue-button>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { formatMoneyWithCurrency } from '@/plugins/numformat'
import getCurrency from '../../utils/getCurrency'
import '@bigbank/interface-components/dist/svg/ui/arrow-right'
import '@bigbank/interface-components/dist/svg/ui/cash'
import '@bigbank/interface-components/dist/svg/ui/calendar'
import ContinueButton from './ContinueButton'
import { DepositType } from '@deposits/const'
import { mapActions, mapState } from 'pinia'
import { useRootStore } from '../../../../store/root'
import { InterestPaymentFrequencyCode } from '@bigbank/dc-common/clients/http/account/account.enums'

export default {
  name: 'choose-product',
  data () {
    return {
      product: DepositType.Demand
    }
  },
  components: {
    ContinueButton
  },
  props: {
    flowData: Object,
    flowConstants: Object
  },
  mounted () {
    if (!this.hasCards) {
      this.showCannotOpenDepositsErrorModal()
    }
  },
  computed: {
    ...mapState(useRootStore, ['featureFlags', 'isCompany', 'isChannelLV', 'isChannelEE']),
    translations () {
      const translations = {
        title: this.$pgettext('choose_product', 'Choose product'),
        upTo: this.$pgettext('choose_product', 'up to'),
        demandDepositArguments: this.demandDepositArguments,
        termDepositArguments: this.termDepositArguments,
        demandDeposit: this.$pgettext('choose_product', 'Demand Deposit')
      }

      if (this.isCompany) {
        Object.assign(translations, {
          demandDeposit: this.$pgettext('corporate_choose_product', 'Demand Deposit')
        })
      }

      return translations
    },
    demandDepositArguments () {
      if (this.isCompany) {
        return {
          first: this.$pgettext('corporate_choose_product', 'Demand: 1st product argument'),
          second: this.$pgettext('corporate_choose_product', 'Demand: 2nd product argument'),
          third: this.$pgettext('corporate_choose_product', 'Demand: 3rd product argument')
        }
      }
      return {
        first: this.$pgettext('choose_product', 'Demand: 1st product argument'),
        second: this.$pgettext('choose_product', 'Demand: 2nd product argument'),
        third: this.$pgettext('choose_product', 'Demand: 3rd product argument')
      }
    },
    termDepositArguments () {
      if (this.isCompany) {
        return {
          first: this.$pgettext('corporate_choose_product', 'Term: 1st product argument'),
          second: this.$pgettext('corporate_choose_product', 'Term: 2nd product argument'),
          third: this.$pgettext('corporate_choose_product', 'Term: 3rd product argument')
        }
      }
      return {
        first: this.$pgettext('choose_product', 'Term: 1st product argument'),
        second: this.$pgettext('choose_product', 'Term: 2nd product argument'),
        third: this.$pgettext('choose_product', 'Term: 3rd product argument')
      }
    },
    cards () {
      const cards = []

      if (this.featureFlags.enableApplyTermDeposits) {
        cards.push(
          {
            id: DepositType.Term,
            label: this.$pgettext('choose_product', 'Term Deposit'),
            icon: 'ui-calendar'
          })
      }

      if (this.featureFlags.enableApplyDemandDeposits) {
        cards.push({
          id: DepositType.Demand,
          label: this.translations.demandDeposit,
          icon: 'ui-cash'
        })
      }

      return cards
    },
    hasCards () {
      return this.cards.length > 0
    },
    selectedAgreementTypeCode () {
      switch (this.product) {
        case DepositType.Term:
          return this.flowConstants.AGREEMENT_TYPE_CODES[DepositType.Term]
        case DepositType.Demand:
          return this.flowConstants.AGREEMENT_TYPE_CODES[DepositType.Demand]
        default:
          return ''
      }
    },
    params () {
      return this.flowData.product[this.selectedAgreementTypeCode].params
    },
    accountType () {
      return this.flowData.product[this.selectedAgreementTypeCode].accountType
    },
    paymentFrequencyCode () {
      return this.params.validPaymentFrequencies[0]
    },
    maxAmount () {
      return formatMoneyWithCurrency(this.params.limits[this.paymentFrequencyCode].maxAmount, this.currency, this.locale)
    },
    continueText () {
      if (this.selectedAgreementTypeCode === this.flowConstants.AGREEMENT_TYPE_CODES[DepositType.Term]) {
        return this.$pgettext('choose_product', 'Open term deposit')
      } else {
        if (this.isCompany) {
          return this.$pgettext('corporate_choose_product', 'Open demand deposit')
        } else {
          return this.$pgettext('choose_product', 'Open demand deposit')
        }
      }
    },
    currency () {
      return getCurrency(this.accountType.restrictedCurrencyCode)
    },
    locale () {
      return this.flowData.locale
    }
  },
  methods: {
    ...mapActions(useRootStore, ['showCannotOpenDepositsErrorModal']),
    goToNextStep () {
      const stepData = {
        accountType: this.accountType,
        accountDefaults: this.params.accountDefaults,
        agreementTypeCode: this.selectedAgreementTypeCode,
        limits: this.params.limits,
        parameters: this.params.parameters,
        validPaymentFrequencies: this.params.validPaymentFrequencies,
        moneyOriginLookupValues: [],
        moneyOriginComment: '',
        moneyOriginDocumentsUuid: null
      }

      if (this.selectedAgreementTypeCode === this.flowConstants.AGREEMENT_TYPE_CODES[DepositType.Demand]) {
        if ((!this.isCompany && this.isChannelLV) || this.isChannelEE) {
          stepData.paymentFrequencyCode = this.params.validPaymentFrequencies.includes(InterestPaymentFrequencyCode.Month)
            ? InterestPaymentFrequencyCode.Month
            : this.params.validPaymentFrequencies[0]
        } else {
          stepData.paymentFrequencyCode = this.params.validPaymentFrequencies[0]
        }
      }

      this.$emit('submit', stepData)
    }
  }
}
</script>

<style scoped lang="scss">
  .main-container {
    margin: 0 auto;
    padding: 0;
    max-width: 380px;
  }

  .sub-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    margin: 0 auto;
    padding: 0;
    max-width: 380px;
  }

  .title {
    font-family: $gotham-medium;
    font-size: $mobile-small-font-size;
    color: $gray-90;
    margin-top: 45px;
    width: 100%;
    text-align: left;

    @media (min-width: $desktop-view-breaking-point-wide) {
      font-size: $font-size-smallest;
    }
  }

  .up-to {
    &__container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    &__label {
      font-family: $gotham-medium;
      font-size: $mobile-small-font-size;
      color: $gray;

      @media (min-width: $desktop-view-breaking-point-wide) {
        font-size: $font-size-smallest;
      }
    }

    &__amount {
      margin-left: 14px;
      font-family: $gotham-bold;
      font-size: $mobile-h2-size;
      color: $navy;

      @media (min-width: $desktop-view-breaking-point-wide) {
        font-size: $h3-size;
      }
    }
  }

  .info {
    &__container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 10px;
    }
  }
</style>
