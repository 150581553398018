<template>
  <bb-list-group
    v-if="documents"
    class="documents-list"
    :class="{ [`flavour-${flavour}`]: true }"
  >
    <bb-list-item
      v-for="document in documents"
      :key="document.fileId"
      extraCondensed
    >
      <div class="list-item-title">
        <div
          v-if="document.isLoading"
          class="documents-list__loader"
        >
          <bb-spinner small />
        </div>
        <bb-icon
          v-else-if="showFileIcon"
          name="client-pdf"
          class="file-type-icon"
          fill="original"
        />
        <div class="file-container">
          <a
            class="file-name"
            @click="download(document)"
          >
            {{ document.title }}
          </a>
          <div class="date">
            <span v-if="document.dateTitle">
              {{ document.dateTitle }}
            </span>
            <bb-icon
              name="ui-download"
              class="download"
              fill="blue"
              size="24"
              @click="download(document)"
            />
          </div>
        </div>
      </div>
    </bb-list-item>
  </bb-list-group>
</template>

<script>
import '@bigbank/interface-components/dist/svg/ui/download'

export default {
  props: {
    files: {
      default: null,
      type: Array,
      validator: function (value) {
        if (value === null || (Array.isArray(value) && value.length === 0)) {
          return true
        }
        return value.every(file => {
          return file.fileId && file.title
        })
      }
    },
    downloadHandler: {
      required: true,
      type: Function
    },
    flavour: {
      type: String,
      default: 'default'
    }
  },
  data () {
    return {
      documents: null
    }
  },
  watch: {
    files: {
      deep: true,
      immediate: true,
      handler (files) {
        if (!Array.isArray(files)) {
          this.documents = null
        } else {
          this.documents = files.map(document => ({
            ...document,
            isLoading: false
          }))
        }
      }
    }
  },
  computed: {
    showFileIcon () {
      return this.flavour === 'default'
    }
  },
  methods: {
    async download (document) {
      try {
        document.isLoading = true
        await this.downloadHandler(document)
      } finally {
        document.isLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.documents-list {
  .list-item-title {
    width: 100%;
    line-height: 18px;
    font-size: $font-size-tiny;
    display: flex;
    align-items: center;

    @media (min-width: $desktop-view-breaking-point) {
      line-height: 36px;
    }

    .file-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;

      @media (min-width: $desktop-view-breaking-point) {
        flex-direction: row;
      }
    }

    .file-type-icon {
      width: 45px;
      height: 45px;
      margin-right: 14px;
      flex-shrink: 0;

      @media (min-width: $desktop-view-breaking-point) {
        width: 36px;
        height: 36px;
        margin-right: 12px;
      }
    }

    .download-icon {
      display: none;

      @media (min-width: $desktop-view-breaking-point) {
        display: inline-block;
        height: 16px;
        width: 16px;
        margin-left: 10px;
      }
    }

    .file-name {
      display: inline-block;
      margin-top: 5px;
      color: $mint;
      cursor: pointer;
      font-size: $font-size-small;

      @media (min-width: $desktop-view-breaking-point) {
        margin: 0;
      }
    }

    .download {
      display: none;

      @media (min-width: $desktop-view-breaking-point) {
        display: inline-block;
        cursor: pointer;
      }
    }
  }

  &__loader {
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 14px;
    width: 45px;
    height: 45px;

    @media (min-width: $desktop-view-breaking-point) {
      width: 36px;
      height: 36px;
      margin-right: 12px;
    }
  }

  &.flavour-minimal {
      .list-item-title {
        line-height: 30px;
      }

      .file-name {
        color: $gray;
        font-size: $font-size-smallest;
      }
    }
}
</style>
