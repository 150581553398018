import isUndefined from 'lodash/isUndefined'
import isNull from 'lodash/isNull'

const recalculatePeriod = (newPeriod, oldPeriod, validPeriods) => {
  if (!validPeriods.includes(newPeriod)) {
    const { left, right } = validPeriods.reduce((mem, cur) => {
      if (cur < newPeriod && cur > mem.left) {
        mem.left = cur
      }
      if (cur > newPeriod && cur < mem.right) {
        mem.right = cur
      }

      return mem
    }, {
      left: validPeriods[0],
      right: validPeriods[validPeriods.length - 1]
    })

    return (newPeriod > oldPeriod) ? right : left
  }

  return newPeriod
}

const recalculateAmount = (amount, minAllowedAmount, maxAllowedAmount) => {
  if (!amount || amount < minAllowedAmount) {
    return minAllowedAmount
  }

  if (amount > maxAllowedAmount) {
    return maxAllowedAmount
  }

  if (isNull(amount) || isUndefined(amount)) {
    return 0
  }

  return amount
}

export {
  recalculatePeriod, recalculateAmount
}
