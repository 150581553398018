export default {
  computed: {
    amountStep () {
      if (this.amount >= 10000) {
        return 1000
      }
      return 500
    }
  }
}
