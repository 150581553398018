<template>
  <signing-modal
    :visible="isModalVisible"
    ref="signing"
    :contract="contract"
    :initMethod="startIdentityConfirmation"
    @signingSuccessful="onIdentityConfirmationSucceed"
    @signingCancelled="onSigningModalClosed"
  />
</template>

<script>
import api from '@loan/api'
import { CreditCardSigningAction } from '@bigbank/dc-common/enums/credit-card.enums'
import SigningModal from '@loan/components/signing/SigningModal.vue'
import CreditCardSigningMixin from '@credit-card/components/mixins/CreditCardSigningMixin'

export default {
  name: 'credit-card-replace-signing-modal',
  components: { SigningModal },
  mixins: [CreditCardSigningMixin],
  props: {
    visible: { required: true, type: Boolean },
    cardId: { required: true, type: Number },
    contract: { required: true, type: Object }
  },
  data () {
    return {
      isModalVisible: this.visible,
      signingRequestId: null
    }
  },
  computed: {
    translations () {
      return {
        notification: {
          replacementProcessSucceed: this.$pgettext('credit_card_replace', 'You have started card replacement process successfully')
        }
      }
    }
  },
  methods: {
    setSigningRequestId (requestId) { this.signingRequestId = requestId },
    async startIdentityConfirmation () {
      const unknownReplacementStepErrorMessage = 'Unknown step in replacement card procedure!'
      let srResponse = null

      try {
        srResponse = await api.initCreditCardSigningRequestByAction(CreditCardSigningAction.Replace, this.contract.id, this.cardId)

        this.setSigningRequestId(srResponse.signingRequestId)
        return {
          method: srResponse.method,
          signingRequestId: srResponse.signingRequestId
        }
      } catch (err) {
        console.error(`Request failed (onStep: ${this.step})`, err)
        if (err.message === unknownReplacementStepErrorMessage) {
          await this.onSigningModalClosed()
          throw err
        }

        return {
          method: null,
          signingRequestId: null
        }
      }
    },
    async onIdentityConfirmationSucceed () {
      const signingIsNotCompletedErrorMessage = 'Signing is not completed!'

      try {
        const replacementResponse = await api.getCreditCardSigningStatusByAction(CreditCardSigningAction.Replace, this.cardId, this.signingRequestId)

        if (replacementResponse?.isSigningCompleted) {
          this.$notify({ text: this.translations.notification.replacementProcessSucceed, duration: 5000 })
          await this.onSigningModalClosed()
        } else {
          throw new Error(signingIsNotCompletedErrorMessage)
        }
      } catch (err) {
        await this.onSigningModalClosed()
        throw err
      }
    },
    async onSigningModalClosed () {
      this.$emit('onModalClosed', { refresh: true })
    }
  },
  async mounted () {
    if (this.hasReturnedAfterSigning()) {
      this.setSigningRequestId(this.$route.query.signingRequestId)
    } else {
      await this.$refs.signing.signButtonClick(new MouseEvent('click', {}))
    }
  }
}
</script>
