<template>
  <component
    :is="component"
    v-bind="componentProps"
    @close="onClose"
    @submit="onSubmit"
  />
</template>

<script>
import InitialStep from './manage-my-loan/InitialStep.vue'
import ThankYou from './manage-my-loan/ThankYou.vue'
import PrematureFulfillment from './manage-my-loan/PrematureFulfillment.vue'
import Prolongation from './manage-my-loan/Prolongation.vue'
import ChangePaymentDay from './manage-my-loan/ChangePaymentDay.vue'
import GracePeriod from './manage-my-loan/GracePeriod.vue'
import Shortening from './manage-my-loan/Shortening.vue'
import Restructuring from './manage-my-loan/Restructuring.vue'
import PartialPrematureFulfillment from './manage-my-loan/PartialPrematureFulfillment.vue'
import api from './../api'
import { MANAGE_MY_LOAN } from '@/TrackingActions'
import { mapState } from 'pinia'
import { useLoanStore } from '@loan/store/loanStore'

export default {
  components: {
    InitialStep,
    ThankYou,
    PrematureFulfillment,
    Prolongation,
    ChangePaymentDay,
    GracePeriod,
    Shortening,
    Restructuring,
    PartialPrematureFulfillment
  },
  name: 'manage-loan-modal',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    contract: {
      required: true
    }
  },
  data () {
    return {
      selectedOption: null,
      isDataSubmitted: false,
      isLoading: false
    }
  },
  computed: {
    ...mapState(useLoanStore, ['featureFlags']),
    component () {
      if (this.isDataSubmitted) {
        return 'thank-you'
      } else if (this.selectedOption) {
        return this.selectedOption
      } else {
        return 'initial-step'
      }
    },
    componentProps () {
      return {
        showLoader: this.isLoading,
        contract: this.contract
      }
    }
  },
  methods: {
    onClose () {
      this.$emit('close')
    },
    async onSubmit (data) {
      if (data.selectedManageLoanOption) {
        this.selectedOption = data.selectedManageLoanOption
      } else {
        try {
          this.isLoading = true
          this.$tracker.action(MANAGE_MY_LOAN.SUBMIT, {}, { contractId: this.contract.id.toString() })
          await api.createManageLoanRequest(this.contract.id, this.selectedOption, data)
          this.isDataSubmitted = true
        } finally {
          this.isLoading = false
        }
      }
    }
  },
  mounted () {
    this.selectedOption = undefined
    this.isDataSubmitted = false
    this.$tracker.action(MANAGE_MY_LOAN.VIEW, {}, { contractId: this.contract.id.toString() })
  }
}
</script>
