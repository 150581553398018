<template>
  <bb-modal :visible="true"  @close="onClose" v-bind="modalProps">
    <template slot="heading">
      <span class="f-color-blue">{{translations.title}}</span>
    </template>
    <div class="subtitle">{{translations.subtitle}}</div>
    <bb-form @progress="onProgress">
      <bb-datepicker
        name="periodStartDate"
        key="periodStartDate"
        type="date"
        :disabled="loading"
        :confirmText="translations.ok"
        :lang="datepickerLanguage"
        :shortcuts="false"
        :format="dateFormat"
        :data-vv-as="translations.labels.periodStartDate"
        :label="translations.labels.periodStartDate"
        :maxDate="maxDate"
        v-model="form.periodStartDate"
        v-validate="startDateValidationRules"
      />
      <bb-datepicker
        name="periodEndDate"
        key="periodEndDate"
        type="date"
        :disabled="loading"
        :confirmText="translations.ok"
        :lang="datepickerLanguage"
        :shortcuts="false"
        :format="dateFormat"
        :data-vv-as="translations.labels.periodEndDate"
        :label="translations.labels.periodEndDate"
        :maxDate="maxDate"
        v-model="form.periodEndDate"
        v-validate="endDateValidationRules"
      />
      <div slot="submit" slot-scope="{}" />
    </bb-form>
    <bb-ticket-stub slot="footer" class="m-t-15">
      <bb-button display="ticket" v-bind="button" class="no-padding" @click="submit">
        {{translations.button}}
      </bb-button>
    </bb-ticket-stub>
  </bb-modal>
</template>

<script>
import { api } from '@deposits/api'
import { mapState } from 'pinia'
import { getDateFormat, formatDateForRequest } from '@/plugins/dateFormatters'
import { DEMAND_DEPOSIT_DASHBOARD } from '@/TrackingActions'
import dayjs from 'dayjs'
import { DepositRouteName } from '@deposits/const'
import { useRootStore } from '../../../../store/root'
import { useDepositStore } from '@deposits/store/depositStore'
import { DepositStatusCode } from '@bigbank/dc-common/clients/http/account/account.enums'

export default {
  name: 'demand-deposit_account_statement',
  data () {
    return {
      loading: false,
      isValid: false,
      dateFormat: getDateFormat(),
      maxDate: dayjs().subtract(1, 'day').toDate(),
      form: {
        periodStartDate: null,
        periodEndDate: null
      }
    }
  },
  watch: {
    'form.periodStartDate' () {
      if (this.form.periodEndDate) {
        this.$validator.validate()
      }
    }
  },
  computed: {
    ...mapState(useRootStore, ['datepickerLanguage', 'isCompany']),
    ...mapState(useDepositStore, ['defaultDemandDeposit', 'featureFlags']),
    translations () {
      const translations = {
        title: this.$pgettext('account_statement_title', 'Account Statement'),
        subtitle: this.$pgettext('account_statement_subtitle', 'Please select a period for the statement.'),
        button: this.$pgettext('account_statement_button', 'Get statement'),
        notBeforeStartDate: this.$pgettext('account_statement_error', 'Period end date can not be before start date'),
        fileName: this.$pgettext('account_statement_file_name', 'Account_statement_file_name'),
        labels: {
          periodStartDate: this.$pgettext('account_statement_period_start_label', 'From'),
          periodEndDate: this.$pgettext('account_statement_period_end_label', 'To')
        }
      }

      if (this.isCompany) {
        Object.assign(translations, {
          fileName: this.$pgettext('corporate_account_statement_file_name', 'Account_statement_%{contractNumber}')
            .replace('%{contractNumber}', this.defaultDemandDeposit?.contractNumber)
        })
      }

      return translations
    },
    button () {
      return {
        color: (this.isValid && !this.loading ? 'green' : 'gray'),
        loading: this.loading
      }
    },
    modalProps () {
      return {
        flavor: 'card',
        color: 'white',
        publicSize: 'm',
        fullMobile: true
      }
    },
    startDateValidationRules () {
      return {
        required: true,
        date_format: getDateFormat()
      }
    },
    endDateValidationRules () {
      return {
        required: true,
        date_format: getDateFormat(),
        notBeforeStartDate: true
      }
    }
  },
  methods: {
    onClose () {
      return this.$router.push({ name: DepositRouteName.DemandDeposits })
    },
    onProgress (value) {
      this.isValid = value === 1
    },
    async submit () {
      if (this.loading || !await this.$validator.validate()) {
        return
      }
      this.loading = true
      try {
        await api.downloadAccountStatement(this.defaultDemandDeposit.id, {
          periodStartDate: formatDateForRequest(this.form.periodStartDate),
          periodEndDate: formatDateForRequest(this.form.periodEndDate)
        }, this.translations.fileName)
      } finally {
        this.loading = false
      }
    }
  },
  async mounted () {
    this.$tracker.action(
      DEMAND_DEPOSIT_DASHBOARD.DEMAND_DEPOSIT_ACCOUNT_STATEMENT,
      {},
      { contractId: this.defaultDemandDeposit.id.toString() },
      true
    )

    const hasDeposit = this.defaultDemandDeposit
    const isDepositActive = hasDeposit && [DepositStatusCode.Active].includes(this.defaultDemandDeposit.state)
    const canDownloadStatement = this.featureFlags.canDownloadDepositAccountStatement

    if (!hasDeposit || !isDepositActive || !canDownloadStatement) {
      return this.$router.push({ name: DepositRouteName.DemandDeposits })
    }
  },
  created () {
    this.$validator.extend('notBeforeStartDate', {
      getMessage: () => {
        return this.translations.notBeforeStartDate
      },
      validate: value => {
        return !dayjs(formatDateForRequest(value)).isBefore(dayjs(formatDateForRequest(this.form.periodStartDate)))
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.subtitle {
  color: $gray;
  text-align: center;
  font-size: $font-size-smallest;
  font-family: $gotham-book;
}
</style>
