import { isObject } from 'lodash'

export default {
  computed: {
    localTranslations () {
      return {
        fileTypeNotAllowedUploadingCancelled: this.$pgettext('upload', 'File type not allowed. Uploading cancelled'),
        uploadSucceed: this.$pgettext('upload', 'Upload succeed')
      }
    }
  },
  methods: {
    isUploadingFailed (response) {
      return isObject(response) && response.uploadFailed
    },
    showFailureNotification ({ filename }, durationMs = 10000) {
      this.$notify({
        text: `${filename}: ${this.localTranslations.fileTypeNotAllowedUploadingCancelled}`,
        type: 'warning',
        duration: durationMs
      })
    },
    showSuccessNotification (durationMs = 5000) {
      this.$notify({
        text: this.localTranslations.uploadSucceed,
        type: 'warning',
        duration: durationMs
      })
    }
  }
}
