<template>
    <modal v-if="showModal" @close="onClose" :loading="isLoading">
      <template v-slot:content>
        <invoice-details
          :contract="contract"
          :invoiceDetails="invoiceDetails"
          :repaymentDetails="repaymentDetails"
          :nextPaymentDetails="nextPaymentDetails"
          :override-translations="translations"
         />
          <slot name="payment-description"></slot>
          <slot name="payment-details">
            <payment-details class="m-t-15" v-bind="paymentDetails" />
          </slot>
          <slot name="full-repayment-details"></slot>
          <slot name="credit-limit-usage"></slot>
        </template>
    </modal>
</template>

<script>
import PaymentDetails from '@loan/components/PaymentDetails'
import Modal from '@/components/DefaultModal'
import InvoiceDetails from '@/modules/loan/components/InvoiceDetails'

export default {
  name: 'loan_pay-back',
  components: {
    InvoiceDetails,
    PaymentDetails,
    Modal
  },
  data () {
    return {
      showInvoiceDetailsModal: false
    }
  },
  props: {
    isLoading: {
      required: false,
      default: false
    },
    contract: {
      required: true
    },
    onClose: {
      required: true
    },
    translations: {
      required: false
    },
    trackingAction: {
      required: false
    },
    notice: {
      required: false
    },
    invoiceDetails: {
      required: true
    },
    repaymentDetails: {
      required: true
    },
    nextPaymentDetails: {
      required: true
    }
  },
  computed: {
    showModal () {
      return this.isLoading || !this.showInvoiceDetailsModal
    },
    iban () {
      return (this.invoiceDetails ? this.invoiceDetails : this.contract)?.iban
    },
    referenceNumber () {
      return (this.invoiceDetails ? this.invoiceDetails : this.nextPaymentDetails)?.referenceNumber
    },
    paymentDetails () {
      return {
        beneficiaryName: this.repaymentDetails ? this.repaymentDetails.beneficiaryName : '',
        beneficiaryIban: this.repaymentDetails ? this.repaymentDetails.beneficiaryIban : this.iban,
        referenceNumber: this.repaymentDetails ? this.repaymentDetails.referenceNumber : this.referenceNumber,
        directDebitValid: this.repaymentDetails ? this.repaymentDetails.directDebitValid : null
      }
    }
  },
  mounted () {
    if (!this.contract) {
      return this.onClose()
    }

    if (this.trackingAction) {
      this.$tracker.action(this.trackingAction, {}, { contractId: this.contract.id.toString() })
    }
  }
}
</script>

<style lang="scss" scoped>
  .notice {
    margin-top: 24px;
    margin-bottom: -20px;
    padding-bottom: 16px;
    text-align: center;
    font-size: $font-size-small;
    color: $gray;
    font-family: $gotham-book;
    border-bottom: 1px dashed $gray-50;
  }

  .product-info {
    font-family: $gotham-medium;
    text-align: center;
    font-size: $font-size-smallest;
    user-select: none;
    text-transform: uppercase;

    @media (max-width: $mobile-view-breaking-point) {
      font-size: $mobile-small-font-size;
    }
  }
</style>
