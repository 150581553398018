import { mapState } from 'vuex'
import { formatMoneyWithCurrency } from '@/plugins/numformat'
import CARD_TYPE from '@/components/cards/cardTypes'

export default {
  computed: {
    ...mapState('campaign', ['campaigns', 'campaignAdTypes', 'campaignTypes']),
    campaignsForCards () {
      const campaignCards = []

      if (this.campaigns && this.campaigns.length > 0) {
        const filteredCampaigns = this.campaigns
          .filter(campaign => campaign.adType === this.campaignAdTypes.INLINE)
          .filter(campaign => {
            if (campaign.type === this.campaignTypes.UPSELL) {
              if (this.upsell) {
                return this.upsell.segment === campaign.segmentNumber
              }

              return false
            }

            return true
          })
          .map(campaign => {
            if (campaign.type === this.campaignTypes.UPSELL) {
              const upsellAmount = formatMoneyWithCurrency(this.upsell.amount, this.upsell.currency, this.locale)
              campaign.content = campaign.content ? campaign.content.replace('upsale_amount', upsellAmount) : ''

              if (campaign.button) {
                campaign.button.to = this.upsell.url
              }
            }

            if (campaign.loyaltyLabel && campaign.loyaltyLabel.length > 0) {
              campaign.labelLoyalty = campaign.loyaltyLabel
            } else if (campaign.campaignLabel && campaign.campaignLabel.length > 0) {
              campaign.labelCampaign = campaign.campaignLabel
            }

            return campaign
          })

        if (filteredCampaigns.length === 1 && filteredCampaigns[0].overlay) {
          filteredCampaigns.pop()
        }

        if (filteredCampaigns.length > 0 && !this.isIndebted) {
          campaignCards.push({
            type: CARD_TYPE.CAMPAIGN,
            campaigns: filteredCampaigns
          })
        }
      }

      return campaignCards
    }
  }
}
