<template>
  <div>
    <bb-contract-page
      :condition-lists="conditionLists"
      :download-paragraphs="downloadParagraphs"
      :loan-amount="amount"
      :monthly-payment="interest"
      :download-button="downloadButton"
      :sign-button="signButton"
      :period="period"
      @sign="sign"
      @download="download"
    >
      <template #extraContent>
        <section class="f-small m-t-40">
          <p v-if="transferAmount > 0 && !isDemandDeposit">
            {{ translations.shallTransfer }}
            <span class="color-blue f-strong"> {{ formattedTransferAmount }} </span>
            {{ translations.withinDays }}
          </p>
          <p v-if="transferAmount < 0"><translate>The Bank shall transfer</translate>
            <span class="color-blue f-strong"> {{ formattedTransferAmount }} </span>
            <translate>on the Customer’s request to the Bank’s Account upon a termination of the Term Deposit Agreement No.TD-EEC-19000001 on 20.01.2019.</translate>
          </p>
        </section>
        <section class="m-t-40 m-b-40 f-small">
          <p>{{ translations.confirmation }}</p>
        </section>
      </template>
    </bb-contract-page>
    <sign-modal
      v-if="showSignModal"
      :deposit="deposit"
      :chosenSignMethod="chosenSignMethod"
      :signingRequestId="signingRequestId"
      @signingSuccessful="complete"
      @close="showSignModal = false"
    />
  </div>
</template>

<script>
import isEmpty from 'lodash/isEmpty'
import { api } from '@deposits/api'
import SignModal from './SignModal'
import FlowMixin from '../mixins/flowMixin'
import SigningMixin from '@/mixins/signingMixin'
import currencyDefinitions from '../utils/getCurrency'
import { mapState, mapActions } from 'pinia'
import { SIGNING_VIEW } from '@/TrackingActions'
import { formatMoneyWithCurrency, formatInterest } from '@/plugins/numformat'
import { BbContractPage } from '@bigbank/interface-components'
import { formatDate } from '@/plugins/dateFormatters'
import { depositStates } from '../utils/depositStates'
import { useRootStore } from '../../../store/root'
import { useDepositStore } from '@deposits/store/depositStore'

export default {
  name: 'contract-conditions',
  components: { SignModal, BbContractPage },
  mixins: [FlowMixin, SigningMixin],
  data () {
    return {
      chosenSignMethod: undefined,
      showSignModal: false,
      signingRequestId: null,
      isLoading: false,
      downloadUrl: null,
      loadedTransferDetails: null
    }
  },
  props: {
    deposit: {
      required: true
    },
    transferDetails: {
      required: true
    },
    isDemandDeposit: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapState(useRootStore, ['isCompany', 'locale', 'isChannelSE', 'isCrossBorderCountry', 'isChannelLV']),
    ...mapState(useDepositStore, ['featureFlags']),
    translations () {
      const translations = {
        paymentInfo: this.$pgettext('contract_conditions', 'Payment Info'),
        confirmation: this.$pgettext('contract_conditions', 'I confirm that the information provided is true and complete and that I’m aware of and understand the obligations set in the General Terms and Conditions of Bigbank AS, including the obligation to provide the information necessary for establishing a business relationship and the liability for misrepresentation.'),
        payoutFrequencies: {
          END: this.$pgettext('payment_frequency', 'At the end of deposit period'),
          YEAR: this.$pgettext('payment_frequency', 'Every year'),
          MONTH: this.$pgettext('payment_frequency', 'Every month')
        },
        prolong: {
          YES: this.$pgettext('prolong', 'Yes'),
          NO: this.$pgettext('prolong', 'No')
        },
        automaticProlong: this.$gettext('Automatic prolongation'),
        interestRateTerm: this.$gettext('Interest Rate'),
        interestRateDemand: this.$gettext('Initial Interest Rate'),
        contractSignedNotification: this.$pgettext('initial_contract_signed_notification', 'Your deposit contract was successfully signed!'),
        downloadParagraph1: this.$pgettext('contract_conditions', 'Would you like to download the contract before signing?'),
        downloadParagraph2: this.$pgettext('contract_conditions', 'Full contract document which includes contract main conditions, general terms and conditions of deposit and guarantee fund information is downloadable also later. Here in this view the most essential information is displayed.'),
        shallTransfer: this.$gettext('The Customer shall transfer'),
        withinDays: this.withinDaysTranslation
      }

      if (this.isCompany) {
        Object.assign(translations, {
          paymentInfo: this.$pgettext('corporate_contract_conditions', 'Payment Info'),
          confirmation: this.$pgettext('corporate_contract_conditions', 'I confirm that the information provided is true and complete and that I’m aware of and understand the obligations set in the General Terms and Conditions of Bigbank AS, including the obligation to provide the information necessary for establishing a business relationship and the liability for misrepresentation.'),
          downloadParagraph2: this.$pgettext('corporate_contract_conditions', 'Full contract document which includes contract main conditions, general terms and conditions of deposit and guarantee fund information is downloadable also later. Here in this view the most essential information is displayed.')
        })
      }

      return translations
    },
    withinDaysTranslation () {
      return this.isChannelLV
        ? this.$pgettext('contract_conditions_lv', 'within 10 days after the opening of the Term Deposit')
        : this.$gettext('within 10 days after the opening of the Term Deposit')
    },
    isButtonDisabled () {
      return [depositStates.ACTIVE, depositStates.WAITING_FUNDS].includes(this.deposit?.state) || this.featureFlags.disableDepositSigning
    },
    periodCode () {
      if (!this.isDemandDeposit) {
        if (this.deposit.periodCode) {
          switch (this.deposit.periodCode.toLowerCase()) {
            case 'days':
              return this.$gettext('days')
            case 'months':
              return this.$gettext('months')
            case 'years':
              return this.$gettext('years')
          }
        }
      }

      return ''
    },
    receiverName () {
      return this.getTransferDetails ? this.getTransferDetails.receiverName : '-'
    },
    bic () {
      return this.getTransferDetails ? this.getTransferDetails.bic : '-'
    },
    iban () {
      return this.getTransferDetails ? this.getTransferDetails.iban : '-'
    },
    clearingAndAccountNumber () {
      return this.getTransferDetails.clearingNumber + ' ' + this.getTransferDetails.accountNumber
    },
    transferAmount () {
      return this.getTransferDetails.amount
    },
    formattedTransferAmount () {
      return formatMoneyWithCurrency(this.transferAmount, this.transferCurrency, this.locale)
    },
    referenceNumber () {
      return this.getTransferDetails.referenceNumber
    },
    getTransferDetails () {
      if (!isEmpty(this.transferDetails)) {
        return this.transferDetails
      } else if (!isEmpty(this.loadedTransferDetails)) {
        return this.loadedTransferDetails
      } else {
        return {}
      }
    },
    transferCurrency () {
      return currencyDefinitions(this.deposit.currencyCode)
    },
    interestRateText () {
      return this.isDemandDeposit ? this.translations.interestRateDemand : this.translations.interestRateTerm
    },
    formattedAmount () {
      return formatMoneyWithCurrency(this.deposit.amount, this.transferCurrency, this.locale)
    },
    amount () {
      return (!this.isDemandDeposit && {
        title: this.$gettext('Deposit amount'),
        content: this.formattedAmount
      }) || undefined
    },
    interest () {
      return {
        title: this.$gettext('Interest rate'),
        content: formatInterest(this.deposit.interest, this.locale, true) + ' ' + this.$gettext('per annum')
      }
    },
    period () {
      return (!this.isDemandDeposit && {
        title: this.$gettext('Deposit period'),
        content: this.deposit.period + ' ' + this.periodCode + ' ' + this.$gettext('from opening of the Term Deposit')
      }) || undefined
    },
    conditionLists () {
      return [{
        heading: this.$gettext('Contract conditions'),
        list: [{
          title: this.$gettext('Agreement Number'),
          content: this.deposit.contractNumber,
          visible: true
        }, {
          title: this.interestRateText,
          content: formatInterest(this.deposit.interest, this.locale, true),
          visible: this.isDemandDeposit
        }, {
          title: this.$pgettext('savings_deposit_interest', 'Frequency of Payment'),
          content: this.translations.payoutFrequencies[this.deposit.paymentFrequencyCode],
          visible: !this.isCompany && this.isDemandDeposit
        }, {
          title: this.$gettext('Registration Date'),
          content: formatDate(this.deposit.registrationDate),
          visible: true
        }, {
          title: this.$gettext('End Date'),
          content: formatDate(this.deposit.endDate),
          visible: true
        }, {
          title: this.$gettext('Frequency of Payment'),
          content: this.translations.payoutFrequencies[this.deposit.paymentFrequencyCode],
          visible: !this.isDemandDeposit
        }, {
          title: this.$gettext('Automatic Prolongation'),
          content: this.translations.prolong[this.deposit.isAutomatedProlong ? 'YES' : 'NO'],
          visible: !this.isDemandDeposit
        }, {
          title: this.$gettext('Disbursement Account'),
          content: this.isChannelSE ? this.clearingAndAccountNumber : this.deposit.accountIban,
          visible: !this.isDemandDeposit
        }, {
          title: this.$pgettext('guaranteed_interest_details', 'Interest rate change date'),
          content: formatDate(this.deposit.guaranteedInterestEndDate),
          visible: this.isDemandDeposit && this.isCrossBorderCountry && this.deposit.guaranteedInterestEndDate
        }, {
          title: this.$pgettext('guaranteed_interest_details', 'Regular interest rate'),
          content: formatInterest(this.deposit.regularInterest, this.locale, true),
          visible: this.isDemandDeposit && this.isCrossBorderCountry && this.deposit.regularInterest
        }].filter(({ visible }) => visible)
      }, {
        heading: this.translations.paymentInfo,
        list: [{
          title: this.$gettext('IBAN'),
          content: this.iban,
          visible: !this.isChannelSE
        }, {
          title: this.$gettext('BIC'),
          content: this.bic,
          visible: true
        }, {
          title: this.$gettext('Payee'),
          content: this.receiverName,
          visible: true
        }, {
          title: this.$gettext('Ref number'),
          content: this.referenceNumber,
          visible: true
        }].filter(({ visible }) => visible)
      }]
    },
    downloadParagraphs () {
      return [
        this.translations.downloadParagraph1,
        this.translations.downloadParagraph2
      ]
    },
    signButton () {
      return {
        label: this.$pgettext('contract_conditions', 'Sign contract'),
        loading: this.isLoading,
        disabled: this.isLoading || this.isButtonDisabled
      }
    },
    downloadButton () {
      return {
        label: this.$gettext('Download contract'),
        disabled: this.isButtonDisabled
      }
    }
  },
  methods: {
    ...mapActions(useDepositStore, ['getDeposits']),
    async fetchData () {
      this.isLoading = true

      if (!this.document) {
        const document = await api.getUnsignedAgreement(this.deposit.id)
        try {
          this.downloadUrl = document.downloadUrl
        } catch (_) {
          this.downloadUrl = ''
        }
      }

      this.isLoading = false
    },
    complete () {
      const routerPath = this.isDemandDeposit ? '/my-deposits/demand' : '/my-deposits/term'

      this.$tracker.action(SIGNING_VIEW.COMPLETE_SIGNING, {
        depositType: this.deposit.depositType
      }, {
        contractId: this.deposit.id
      })
      this.$router.push(routerPath)
      this.$notify({ text: this.translations.contractSignedNotification, duration: 10000 })
      this.getDeposits(true)
        .then(() => {
          this.showSignModal = false
          this.$emit('close')
        })
    },
    download () {
      window.open(this.downloadUrl, '_blank')
    },
    async sign () {
      if (this.isLoading) {
        return
      }

      this.isLoading = true

      try {
        const initResponse = await api.getSignMethod(this.deposit.id)
        this.chosenSignMethod = initResponse.method
        this.signingRequestId = initResponse.signingRequestId

        this.showSignModal = true
        this.$tracker.action(SIGNING_VIEW.VIEW_SIGNING_MODAL, {
          depositType: this.deposit.depositType
        }, {
          contractId: this.deposit.id
        })
      } finally {
        this.isLoading = false
      }
    },
    async loadTransferDetails () {
      if (!this.loadedTransferDetails) {
        this.loadedTransferDetails = await api.getTransferDetails(this.deposit.id)
      }
    }
  },
  mounted () {
    if (this.deposit.signingInfo && this.deposit.signingInfo.userSigned) {
      this.$router.push('/my-deposits/' + this.deposit.id + '/details')
      return
    }
    this.fetchData()
    this.$tracker.action(SIGNING_VIEW.VIEW_SIGNING, {
      depositType: this.deposit.depositType
    }, {
      contractId: this.deposit.id
    })
  },
  async beforeUpdate () {
    await this.loadTransferDetails()
  }
}
</script>
