export default {
  DEPO_DEBIT: 'DEPO_DEBIT',
  DEPO_CREDIT: 'DEPO_CREDIT',
  SEPA2ACC_PAY: 'SEPA2ACC_PAY',
  INTRA2ACC_PAY: 'INTRA2ACC_PAY',
  ACC2INTRA_PAY: 'ACC2INTRA_PAY',
  ACC2SEPA_PAY: 'ACC2SEPA_PAY',
  ACC2LOANFEE: 'ACC2LOANFEE',
  ACC2LOANDISBURSEMENT_FEE: 'ACC2LOANDISBURSEMENT_FEE',
  ACC2REPAYMENTKREDEX_FEE: 'ACC2REPAYMENTKREDEX_FEE',
  SAVE_DEPO2ACC3: 'SAVE_DEPO2ACC3',
  SAVE_DEPO_CREDIT_INT: 'SAVE_DEPO_CREDIT_INT',
  SAVE_DEPO2ACC3_R: 'SAVE_DEPO2ACC3_R',
  SAVE_DEPO_DEBIT_TAX: 'SAVE_DEPO_DEBIT_TAX',
  SAVE_DEPO_DEBIT_TAX_R: 'SAVE_DEPO_DEBIT_TAX_R',
  ACC2DEPOTAX: 'ACC2DEPOTAX',
  ACC2DEPOFEE: 'ACC2DEPOFEE',
  DEPO_CREDIT_INT: 'DEPO_CREDIT_INT',
  ACCOUNT_PAYMENT: 'ACCOUNT.PAYMENT'
}
