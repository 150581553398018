import { render, staticRenderFns } from "./DocumentList.vue?vue&type=template&id=54e1dda4&scoped=true&"
import script from "./DocumentList.vue?vue&type=script&lang=js&"
export * from "./DocumentList.vue?vue&type=script&lang=js&"
import style0 from "./DocumentList.vue?vue&type=style&index=0&id=54e1dda4&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54e1dda4",
  null
  
)

export default component.exports