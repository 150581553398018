<template>
  <multi-cards class="contract__content__cards" status="closed" />
</template>

<script>
import MultiCards from './../../components/MultiCards'

export default {
  name: 'loan-closed',
  components: {
    MultiCards
  }
}
</script>
