<template>
  <layout :loading="loading">
    <bb-container fluid v-if="!loading">
      <bb-row no-gutters v-if="accountDetails">
        <bb-col md="12" lg="11">
          <section-box :title="translations.accountData" icon="client-identification">
            {{titleText}}
          </section-box>
        </bb-col>
        <bb-col md="12" lg="13">
          <bb-input name="username" type="text" :label="translations.labels.username" :value="accountDetails.username" readonly></bb-input>
          <div class="password-field">
            <bb-button
              noUnderline
              display="link"
              size="small"
              class="change-password-button"
              @click="onChangePassword"
            >
             <bb-icon
                name="ui-pencil"
                fill="blue"
                size="24"
              />
              <span class="color-blue p-l-5">
                {{buttonText}}
              </span>
            </bb-button>
            <bb-input name="password" type="password" :label="translations.labels.password" value="**********" readonly></bb-input>
          </div>
          <div class="f-smallest" v-if="lastPasswordChange">
            <translate translate-context="password">Last change</translate>: {{lastPasswordChange}}
          </div>
        </bb-col>
      </bb-row>
      <bb-banner type="error" permanent visible v-else>
        <translate>You don't have account.</translate>
      </bb-banner>
    </bb-container>
    <password-change-modal
      @close="showChangePassword = false"
      @initSuccessful="handleSuccessfulPasswordChangeInit"
      v-if="showChangePassword"
      :type="passwordActionType"
    />
    <otp-modal
      v-if="showOtpModal"
      v-bind="otp"
      @otpSubmission="handleOtpSubmission"
      @close="showOtpModal = false"
    />
  </layout>
</template>

<script>
import Layout from './Layout'
import SectionBox from '@account/components/SectionBox'
import PasswordChangeModal from '@account/components/id/PasswordChangeModal'
import OtpModal from '@account/components/id/OTPModal'
import { mapState, mapActions } from 'pinia'
import { useAccountStore } from '@account/store/accountStore'
import { formatDate } from '@/plugins/dateFormatters'
import '@bigbank/interface-components/dist/svg/client/identification'
import { SETTINGS_VIEW } from '@/TrackingActions'
import PASSWORD_ACTION_TYPES from '@account/components/id/passwordChangeType'

export default {
  name: 'account-data',
  components: { Layout, SectionBox, PasswordChangeModal, OtpModal },
  data () {
    return {
      loading: true,
      showChangePassword: false,
      showOtpModal: false,
      passwordChangeInitData: null,
      otp: {
        otpFailed: false,
        otpErrorCode: null,
        maskedRecipient: null,
        isLoading: false
      },
      translations: {
        accountData: this.$pgettext('section_title', 'Account Data'),
        labels: {
          username: this.$pgettext('form', 'Username'),
          password: this.$pgettext('form', 'Password')
        },
        title: {
          [PASSWORD_ACTION_TYPES.SET]: this.$gettext('Choose a password to activate your account.'),
          [PASSWORD_ACTION_TYPES.CHANGE]: this.$gettext('Choose a password to activate your account.')
        },
        button: {
          [PASSWORD_ACTION_TYPES.SET]: this.$pgettext('button', 'Set'),
          [PASSWORD_ACTION_TYPES.CHANGE]: this.$pgettext('button', 'Change')
        },
        notification: {
          [PASSWORD_ACTION_TYPES.SET]: this.$pgettext('account_data_form', 'Your password was successfully created!'),
          [PASSWORD_ACTION_TYPES.CHANGE]: this.$pgettext('account_data_form', 'Your password was successfully changed!')
        }
      }
    }
  },
  computed: {
    ...mapState(useAccountStore, ['accountDetails']),
    lastPasswordChange () {
      if (!this.accountDetails.lastPasswordChange) {
        return false
      }
      return formatDate(this.accountDetails.lastPasswordChange)
    },
    passwordActionType () {
      if (this.accountDetails.accountActivated) {
        return PASSWORD_ACTION_TYPES.CHANGE
      }
      return PASSWORD_ACTION_TYPES.SET
    },
    titleText () {
      return this.translations.title[this.passwordActionType]
    },
    buttonText () {
      return this.translations.button[this.passwordActionType]
    }
  },
  methods: {
    ...mapActions(useAccountStore, ['getAccountDetails', 'finalizePasswordChange', 'finalizePasswordSet']),
    async init (forceReload = false) {
      this.loading = true
      try {
        await this.getAccountDetails(forceReload)
      } finally {
        this.loading = false
      }
    },
    handleSuccessfulPasswordChangeInit (data) {
      this.otp.maskedRecipient = data.recipient
      this.showChangePassword = false
      this.showOtpModal = true
    },
    onChangePassword () {
      this.showChangePassword = true
      this.$tracker.action(SETTINGS_VIEW.INIT_PASSWORD_CHANGE)
    },
    async handleOtpSubmission (otp) {
      this.otp.otpFailed = false
      this.otp.isOtpLoading = true
      try {
        if (this.passwordActionType === PASSWORD_ACTION_TYPES.CHANGE) {
          await this.finalizePasswordChange(otp)
          this.$tracker.action(SETTINGS_VIEW.COMPLETE_PASSWORD_CHANGE)
        } else {
          await this.finalizePasswordSet(otp)
          this.$tracker.action(SETTINGS_VIEW.COMPLETE_PASSWORD_SET)
        }
        this.showOtpModal = false
        this.$notify({ text: this.translations.notification[this.passwordActionType], duration: 5000 })
        this.init(true)
      } catch (err) {
        this.otp.otpFailed = true
        this.otp.otpErrorCode = err.errorCode
      } finally {
        this.otp.isOtpLoading = false
      }
    }
  },
  mounted () {
    this.init(false)
  }
}
</script>

<style lang="scss" scoped>
.password-field {
  position: relative;
}

.change-password-button {
  position: absolute;
  right: 0;
  bottom: 10px;
  z-index: 1;

  span {
    font-size: $font-size-small;
    font-family: $max-pro-demi-bold;
  }
}
</style>
