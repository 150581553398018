<template>
  <div class="limit-item" :name="name" :class="{'status-disabled': !isEnabled, 'white-bg': isAccountVerificationFlavor }">
    <div class="top">
      <div class="title">{{title}}</div>
      <div class="amount" v-if="isAccountVerificationFlavor || !isMobile">{{translations.limit}}</div>
    </div>
    <bb-progress-bar v-bind="progressbar" />
    <div class="bottom">
      <div class="spent" v-if="isAccountVerificationFlavor || !isMobile">{{translations.spent}}</div>
      <div class="left" :class="{'left--ru' : isLocaleRu }">{{translations.left}}</div>
    </div>
  </div>
</template>

<script>
import { BbProgressBar } from '@bigbank/interface-components'
import { mapState } from 'pinia'
import { formatMoneyWithCurrency } from '@/plugins/numformat'
import { useRootStore } from '../../../store/root'

const COMPONENT_FLAVORS = Object.freeze({
  CREDIT_CARD: 'credit-card',
  ACCOUNT_VERIFICATION: 'account-verification'
})

export default {
  props: {
    title: {
      required: true
    },
    name: {
      required: true
    },
    limitAmount: {
      required: true
    },
    usedAmount: {
      required: true
    },
    availableAmount: {
      required: true
    },
    isEnabled: {
      type: Boolean,
      default: true
    },
    flavor: {
      type: String,
      default: COMPONENT_FLAVORS.CREDIT_CARD,
      validator: (val) => Object.values(COMPONENT_FLAVORS).includes(val)
    }
  },
  components: {
    BbProgressBar
  },
  computed: {
    ...mapState(useRootStore, ['isMobile', 'currency', 'locale']),
    progressbar () {
      const isLimitExeeded = this.availableAmount <= 0

      return {
        corner: 'round',
        color: this.isEnabled ? (isLimitExeeded ? 'gray-70' : 'green') : 'gray-30',
        value: Math.min(Math.round(this.usedAmount / this.limitAmount * 100), 100),
        max: 100
      }
    },
    translations () {
      return {
        spent: this.$gettextInterpolate(this.$pgettext('credit_card_card_limits', '%{amount} spent'), {
          amount: formatMoneyWithCurrency(this.usedAmount, this.currency, this.locale),
          currencySymbol: this.currencySymbol
        }),
        left: this.$gettextInterpolate(this.$pgettext('credit_card_card_limits', '%{amount} left'), {
          amount: formatMoneyWithCurrency(this.availableAmount, this.currency, this.locale),
          currencySymbol: this.currencySymbol
        }),
        limit: this.$gettextInterpolate(this.$pgettext('credit_card_card_limits', '%{amount} limit'), {
          amount: formatMoneyWithCurrency(this.limitAmount, this.currency, this.locale),
          currencySymbol: this.currencySymbol
        })
      }
    },
    isAccountVerificationFlavor () {
      return this.flavor === COMPONENT_FLAVORS.ACCOUNT_VERIFICATION
    },
    isLocaleRu () {
      return this.locale === 'ru'
    }
  }
}
</script>

<style lang="scss" scoped>
.limit-item {
  background: $gray-10;
  border: 1px solid $gray-20;
  border-radius: 6px;
  padding: 16px;
  font-size: $font-size-smallest;

  @media (max-width: $desktop-view-breaking-point) {
    padding: 16px 14px;
  }

  .top {
    display: flex;
    justify-content: space-between;
    font-weight: 400;
    font-family: $gotham-medium;
    margin-bottom: 8px;

    .title {
      color: $gray;
      line-height: 16px;
    }

    .amount {
      color: $gray-70;
      line-height: 16px;
      text-align: right;
    }
  }

  .bottom {
    margin-top: 8px;
    display: flex;
    justify-content: space-between;
    color: $gray;
    font-family: $gotham-book;

    .left {
      @media (min-width: $desktop-view-breaking-point) {
        text-align: right;
      }

      &--ru {
        @media (max-width: $desktop-view-breaking-point) {
          width: min-content
        }
      }
    }
  }

  &.status-disabled {
    .top .title,
    .top .amount,
    .bottom {
      color: $gray-70;
    }
  }

  &.white-bg {
    background: $white !important;
  }
}
</style>
